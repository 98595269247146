import { 
  LOADING,
  SET_CURRENT_PAGE,
  LOGGED_IN,
  DASHBOARD_TYPE,
  WIDGET_ALIGNMENT_SIZE,
  WIDGET_ALIGNMENT_ALIGNMENT,
  LOGINDETAILS,
  LANGUAGE_DATA,
  PAGEWISE_LANGUAGE_DATA
} from '../constants';

const initialState = {
  loading: true,
  loggedIn: localStorage.getItem('token') && (localStorage.getItem('is_faculty') 
              || localStorage.getItem('is_admin')) ? true : false,
  dashboard_type:localStorage.getItem('dp'),
  widget_alignment_size:localStorage.getItem('widget_alignment_size'),
  widget_alignment_alignment:localStorage.getItem('widget_alignment_alignment'),
  logindetails:JSON.parse(localStorage.getItem('logindetails')),
  languageData:[],
  pagewiseLanguageData:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case LOGGED_IN:
      return {
        ...state,
        loggedIn: action.loggedIn
      }
    case DASHBOARD_TYPE:
      return {
        ...state,
        dashboard_type: action.dashboard_type
      }
    case WIDGET_ALIGNMENT_SIZE:
      return {
        ...state,
        widget_alignment_size: action.widget_alignment_size
      }
    case WIDGET_ALIGNMENT_ALIGNMENT:
      return {
        ...state,
        widget_alignment_alignment: action.widget_alignment_alignment
      }
    case LOGINDETAILS:
      return {
        ...state,
        logindetails: action.logindetails
      }
      case LANGUAGE_DATA:
      return {
        ...state,
        languageData: action.languageData
      }
      case PAGEWISE_LANGUAGE_DATA:
      return {
        ...state,
        pagewiseLanguageData: action.pagewiseLanguageData
      }
    default:
        return state;
  }
}