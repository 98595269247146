import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import axios from 'axios';
import common from '../../common';
import logo from '../../public/icons/favicon.svg';
import aictelogo from '../../public/images/aicte-logo.png';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      mycoachdata:[],
      profile: {},
      center_id:'',
      type:'',
      password:'',
      isExist:0,
      user_id:'',
      challengesData:[],
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
   }
  }
  
  componentDidMount() {
   const token=this.props.location.search.replace("?secret=", '');
   if(token)
    this.validateToken(token)
  }

  validateToken = async(token) =>{
    const response = await this.props.wpUserLinkTokenValidate(token);

    if(response != undefined){
      console.log(response.data.user_id)
      this.setState({user_id:response.data.user_id})
    }
    console.log(response)
  }

  handleChange = (e) =>{
    this.setState({password:e.target.value})
  }

  setPassword = async() =>{
   const response =  await this.props.resetPassword(this.state.user_id,this.state.password)

  }


	render() {
    return (
      <>
      <ReactNotifications/>
      
        <div className="app-body webview">
          
          <div className="app-div mycoach-div">
            <Helmet>
              <title>Signup - Learner</title>
            </Helmet>
            
              {/*aicteSignup Start*/}
              <div className="aicteResetWrapper">
                  <div className="aicteResetContainer">
                    {this.state.user_id ? <div className="resetBlock">
                        <div className="logo"><span><img src={aictelogo} /></span></div>
                        <h2>{this.props.saveLanguageWiseData.reset_password}</h2>
                        <div className="formblock">
                          <input type="text" value={this.state.password} onChange={this.handleChange} placeholder="enter password"/>
                          {/*<span className="errorMsg">Please enter your correct email id</span>*/}
                          <button onClick={this.setPassword}>{this.props.saveLanguageWiseData.change}</button>
                        </div>
                    </div>:<div className="resetBlock"><div className="logo"><span><img src={aictelogo} /></span></div><h4 style={{"textAlign": "center"}}>{this.props.saveLanguageWiseData.reset_password_link}</h4></div>}
                  </div>
              </div>
              {/*aicteSignup ends*/}

            </div>
          </div>
        
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage,
    saveLanguageWiseData:state.commonReducer.languageData
   
	}
}

const mapDispatchToProps = (dispatch) => {
  const { resetPassword,wpUserLinkTokenValidate} = actions;
  return {
    wpUserLinkTokenValidate:(token) => dispatch(wpUserLinkTokenValidate(token)),
    resetPassword:(user_id,password) => dispatch(resetPassword(user_id,password)),
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);