import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import AssessmentType from '../../components/temp2';
import Add from '../../public/icons/add.svg';
import Edit from '../../public/icons/edit.svg';
import Trash from '../../public/icons/trash.svg';
import Delete from '../../public/icons/delete.svg';
import Expand from '../../public/icons/expand.svg';
import Collapse from '../../public/icons/collapse.svg';
import 'react-notifications-component/dist/theme.css';
import ImgHolder from '../../public/images/img-holder.svg';
import './styles.css';
import common from '../../common';
import download from '../../public/icons/down-arrow.svg';
import { store } from 'react-notifications-component';
import Select from 'react-select-me';
import 'react-select-me/lib/ReactSelectMe.css';

const alphabet = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N',
  15: 'O',
  16: 'P',
  17: 'Q',
  18: 'R',
  19: 'S',
  20: 'T',
  21: 'U',
  22: 'V',
  23: 'W',
}

let difficultyArray = {
  0: 'Easy',
  1: 'Medium',
  2: 'Hard',
  3: 'Very Hard'
}


class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessment_id: this.props.match.params.id,
      id: '',
      editing: false,
      open: false,
      opend: false,
      open2: false,
      open3: false,
      open6:false,
      open7:false,
      editq: false,
      titleq: '',
      d_id: '',
      expand: {},
      sections: [],
      question_ob: {},
      question: {},
      question_ids: {},
      assessment: {},
      section_title: '',
      see_explanation: '',
      imageURL: null,
      image:null,
      imageURL: null,
      image:null,
      open5:false,
      option_image:null,
      question_image:null,
      negative_marking_per_q: '',
      marks: '',
      negative_marks: '',
      difficulty: '',
      duration_seconds: '',
      explanation: '',
      instructions: '',
      quesValue:null,
      selected_image:null,
      sumUpdateButton:0,
      values:[],
      qcount:[],
      options:[],
      deleteQues:false,
      categoryList:[],
      uid:'',
      questionList:null,
      url: this.props.location.state?.url === undefined ? null : this.props.location.state?.url,
      toc_id: this.props.location.state?.toc_id === undefined 
                ? null : this.props.location.state?.toc_id,
      class_id: this.props.location.state?.class_id === undefined 
                  ? null : this.props.location.state?.class_id,
      subject_id: this.props.location.state?.subject_id === undefined 
                    ? null : this.props.location.state.subject_id
    }
  }

  componentDidMount() {
    this.getCategoryList();
    this.getAssessment(this.props.match.params.id);
    this.getSectionsList();

    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }

    this.getUrlfromCurrentPage();
    
  }


  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"menu");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyUR1 = getUrl.split("/");
    let modifyURL = modifyUR1[2];
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      difficultyArray = {
        0: this.props.pagewiseLanguageData.easy,
        1: this.props.pagewiseLanguageData.medium,
        2: this.props.pagewiseLanguageData.hard,
        3: this.props.pagewiseLanguageData.very_hard
      }
      
      
    }
  }

  getCategoryList = async () =>{
    const response = await this.props.categoryList();
    if(response != undefined){
      this.setState({categoryList: response.data})
    }
  }

  show = (val) => this.setState({ open: true, section_title: val.title, negative_marking_per_q: `${val.negative_marking_per_q}`,
                                instructions: val.instructions === null ? '' : val.instructions, 
                                section_id: val.id });
  
  close = () => this.setState({ open: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  show2 = () => this.setState({ open2: true });
  show6 = () => {
    if(this.canCreate() === true){ 
      this.setState({sumUpdateButton:1}); 
      let el= document.getElementById("button-reverse-link");
      if(el) el.click();
      return true;
    }
    else this.setState({ open6: true });
  }

  show7 = (val) => this.setState({ open7: true,quesValue:null,options:[] ,id:val });

  
  close2 = () => this.setState({ open2: false });

  show3 = (val) => this.setState({ open3: true, see_explanation: val });
  
  close3 = () => this.setState({ open3: false });
  close6 = () => this.setState({ open6: false });
  close7 = () => this.setState({ open7: false });

  getAssessment = async (id) => {
    const response = await this.props.getAssessment(id,true);
    this.setState({ assessment: response.data  })
  }

  getSectionsList = async () => {
    const response = await this.props.getAssessmentSectionList(this.props.match.params.id,true);
    this.setState({ sections: response.data, 
      expand: Object.fromEntries(response.data.map(e => [e.id, true])),
      question_ob: Object.fromEntries(response.data.map(e => [e.id, false]))
    });
    this.questionIds(response.data);

    //console.log(this.canCreate())
    if(this.canCreate() === true){ this.setState({sumUpdateButton:1}); return true}
  }

  close5 = () => this.setState({ open5: false ,selected_image:null});

  handleChange = async (event) => {

    if(event.target.files[0].type.split('/')[0]) {
      this.setState({ 
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })

      const response= await this.props.updateImage(event.target.files[0]);
      this.setState({image:response.data.image});
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_images_can_be_uploaded_here}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }
  handleChange1 = async (event,index) => {
    var question = this.state.question;
    //console.log(event.target.files)
    if(event.target.files[0].type.split('/')[0] === 'image') {
      //console.log("445354543")
      this.setState({ 
        image1: event.target.files[0],
        imageURL1: URL.createObjectURL(event.target.files[0])
      })

      const response= await this.props.updateImage(event.target.files[0]);
      
      this.setState({option_image:response.data.image});
      question.options[index].statements[0].image = (response.data.image) 
        ? response.data.image : question.options[index].image;
      this.setState({ question });
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_images_can_be_uploaded_here}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  questionIds = (arr) => {
    var question_ids = {};
    for(var i=0; i < arr.length ; i++) {
      for(var j=0; j < arr[i].questions.length; j++) {
        question_ids[arr[i].questions[j].question] = false;
        this.setState({ question_ids });
      }
    }
  }

  onExpand = (val) => {
   
    var expansion = this.state.expand;
    if(this.state.expand[val] === false) {
      expansion[val] = true;
      this.setState({ expand: expansion });
    }
    else {
      expansion[val] = false;
      this.setState({ expand: expansion });
    }
  }

  onExpandQ = (question,val) => {
    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    var expansion = this.state.question_ids;
    //console.log(question)
    if(this.state.question_ids[val] === false) {
      expansion[val] = true;
      this.setState({ question_ids: expansion });
    }
    else {
      expansion[val] = false;
      this.setState({ question_ids: expansion });
    }

    if(question.question_data.statements[0].image){
          //console.log(currentSection.questions[index].image.split('/').pop())
      if(blockedTile.indexOf(question.question_data.statements[0].image.split('.').pop()) != -1)
      {  
        this.setState({file_extension:1,selected_image_on:question.question_data.statements[0].image.split('/').pop()}); //1 is used for image file
      }else{
        this.setState({file_extension:2,selected_image_on:question.question_data.statements[0].image.split('/').pop()}); //2 is used for content file
      }
    }

  }

  onTitleChange = (e) => {
    this.setState({ section_title: e.target.value });
  }

  onMarkChange = (e) => {
    const negative_marking_per_q = (e.target.validity.valid) ? e.target.value 
                                      : this.state.negative_marking_per_q;
    this.setState({ negative_marking_per_q });
  }

  onInstructionChange = (e) => {
    this.setState({ instructions: e.target.value });
  }

  onExplanationChange = (e) => {
    this.setState({ explanation: e.target.value });
  }

  

  onAddQuestion = (val, index) => {
    //if(this.state.assessment.is_graded === true && this.canCreate(val,2) === true ) return true;

    this.setState({ id: val, editq: false });
    var question_ob = this.state.question_ob;
    var expansion = this.state.expand;
    var question = {};
    if(this.state.assessment.timed_type === 2) {
      question = {
        type: 1,
        statement: '',
        options: []
      }
    }
    else {
      question = {
        type: 1,
        statement: '',
        options: []
      }
    }
    if(this.state.question_ob[val] === false) {
      question_ob[val] = true;
      expansion[val] = true;
      this.setState({ marks: '0', question_image:null,negative_marks: this.state.sections[index]?.negative_marking_per_q + '', 
                      explanation: '', duration_seconds: '300', difficulty: '', uid:this.state.sections[index]?.question_data?.uid})
      this.setState({ expand: expansion, question_ob: question_ob, question: question })
    }
  }

  onEditQuestion = (val, id, index) => {

    this.setState({ id: id, editq: true, titleq: `Question ${index}` });
    var question_ob = this.state.question_ob;
    var expansion = this.state.expand;
    question_ob[id] = true;
    expansion[id] = true;
    this.setState({ editing: true, category:val.question_data.category,question: {
      id: val.question_data.id,
      statement: val.question_data.statements[0].statement,
      options: val.question_data.options,
      type: val.question_data.question_type
    }, question_image:val.question_data.statements[0].image,image:val.question_data.statements[0].image,marks: val.question_data.marks + '', negative_marks: val.question_data.negative_marking + '',
      duration_seconds: val.question_data.duration_seconds + '',
      expand: expansion, question_ob: question_ob, difficulty: val.question_data.difficulty ,uid:val.question_data.uid,explanation:val.question_data.statements[0].explanation})
  }

  onDeleteQuestion = async () => {
    const {question_section_id,delete_question_id} = this.state
    const response = await this.props.assessmentQuestionDelete(question_section_id,delete_question_id)
    
  }

  onDeleteQues = (val, id, index) =>{

    let {question_section_id,delete_question_id} = this.state

    question_section_id = id
    delete_question_id = val.question_data.id
    this.setState({deleteQues:true,question_section_id,delete_question_id})
  }

  deleteQues = () =>{
    this.setState({deleteQues:false})
  }


  closeQuestionBox = (val) => {
    var question_ob = this.state.question_ob;
    if(this.state.question_ob[val] === true) {
      question_ob[val] = false;
      this.setState({ question_ob: question_ob, editing: false })
    }
  }

  addSections = async () => {
    const { assessment_id } = this.state;
    await this.props.addAssessmentSection(assessment_id, `Section ${this.state.sections.length + 1}`);
  }

  removeSection = async () => {
    this.setState({opend:false})
    await this.props.deleteAssessmentSection(this.state.d_id);
  }

  onTypeChange = (e) => {
    var question = this.state.question;
    for(var i=0; i < question.options.length ; i++) {
      question.options[i].is_correct = false;
      question.options[i].statements[0].statement = '';
    }
    question.type = parseInt(e.target.value);
    if(parseInt(e.target.value) === 5) {
      question.options = [
        {
          is_correct: false,
          statements: [{
            language: 1,
            explanation: '',
            statement: 'True',
          }]
        },
        {
          is_correct: false,
          statements: [{
            language: 1,
            explanation: '',
            statement: 'False',
          }]
        }
      ]
    }
    this.setState({ question });
  }

  onStatementChange = (e) => {
    var question = this.state.question;
    question.statement = e.target.value;
    this.setState({ question });
  }

  onChangeTime = (e) => {
    var duration_seconds = this.state.duration_seconds;
    let id = this.state?.id;
    let question = this.state.question?.id;

    duration_seconds = (e.target.validity.valid) ? e.target.value : this.state.duration_seconds;

    let sectionIndex=this.state.sections.findIndex(p => p.id === id)

    this.state.sections[sectionIndex].questions.map((data,index) => {
      if(data.question_data.id === question){
        data.question_data.duration_seconds = parseInt(duration_seconds);
        data.duration_seconds = parseInt(duration_seconds);
      }
        
    })
    this.setState({ duration_seconds });
  }

  positiveChange = (e) => {
    var marks = this.state.marks;
    console.log("e.target")
    console.log(e.target.value);
    console.log(e.target.validity.valid);
    marks = (e.target.validity.valid) ? e.target.value : this.state.marks;
    this.setState({ marks });
  }

  negativeChange = (e) => {
    var negative_marks = this.state.negative_marks;
    console.log("e.target")
    console.log(e.target.value);
    console.log(e.target.validity.valid);
    negative_marks = (e.target.validity.valid) ? e.target.value : this.state.negative_marks;
    this.setState({ negative_marks });
  }

  updateSection = async () => {
    const { section_id, section_title, negative_marking_per_q, instructions } = this.state;
    await this.props.updateAssessmentSection(section_id, section_title, negative_marking_per_q, instructions);
  }

  addMarksPlus =() =>{
    var positive_marks = this.state.marks;
    if(positive_marks == "") positive_marks=0;
    positive_marks = parseInt(positive_marks) + 1;
    this.setState({ marks:positive_marks });
  }

  addMarksMinus =() =>{
    var positive_marks = this.state.marks;
    if(parseInt(positive_marks)>0){
      positive_marks = parseInt(positive_marks) - 1;
      this.setState({ marks:positive_marks });
    }
  }

  addNegativeMarks = () => {
    var negative_marks = this.state.negative_marks;
    if(negative_marks == "") negative_marks=0;
    negative_marks = parseInt(negative_marks) + 1;
    this.setState({ negative_marks});
  }

  addNegativeMarksm =() =>{
    var negative_marks = this.state.negative_marks;
    if(parseInt(negative_marks)>0){
      negative_marks = parseInt(negative_marks) - 1;
      this.setState({ negative_marks });
    }
  }

  minusTime = () => {
    var duration_seconds = this.state.duration_seconds;
    if(parseInt(this.state.duration_seconds) >= 5) {
      duration_seconds = parseInt(duration_seconds) - 5;

      let id = this.state?.id;
      let question = this.state.question?.id;

      let sectionIndex=this.state.sections.findIndex(p => p.id === id)

      this.state.sections[sectionIndex].questions.map((data,index) => {
        if(data.question_data.id === question){
          data.question_data.duration_seconds = parseInt(duration_seconds);
          data.duration_seconds = parseInt(duration_seconds);
        }
      })
      this.setState({ duration_seconds });
    }
  }

  addTime = () => {
    var duration_seconds = this.state.duration_seconds;
    if(duration_seconds == "") duration_seconds=0;
    duration_seconds = parseInt(duration_seconds) + 5;

    let id = this.state?.id;
    let question = this.state.question?.id;

    let sectionIndex=this.state.sections.findIndex(p => p.id === id)

    this.state.sections[sectionIndex].questions.map((data,index) => {
      if(data.question_data.id === question){
        data.question_data.duration_seconds = parseInt(duration_seconds);
        data.duration_seconds = parseInt(duration_seconds);
      }
    })

    this.setState({ duration_seconds });
  }

  addMarks =() =>{
    var positive_marks = this.state.marks;
    positive_marks = parseInt(positive_marks) + 1;
    this.setState({ marks:positive_marks });
  }

  difficultyChange = (e) => {
    this.setState({ difficulty: e.target.value });
  }

  categoryChange = (e) =>{
     this.setState({ category: e.target.value });
  }

  addOption = () => {
    if(this.state.question.options.length < 24) {
      var question = this.state.question;
      if(this.state.question.options.length < 20) {
        var option = {
          is_correct: false,
          statements: [{
            image:'',
            language: 1,
            explanation: '',
            statement: '',
          }],
          image:''
        }
        question.options.push(option);
        this.setState({ question })
      }
    }
  }

  removeOption = (index) => {
    
    var question = this.state.question;
    if(this.state.question.options.length > 0) {
      question.options.splice(index, 1);
      this.setState({ question });
    }
  }

  isCorrect = (index) => {
    var question = this.state.question;
    //console.log(question)
    if(this.state.question.type !== 3) {
      for(var i=0; i < question.options.length ; i++) {
        question.options[i].is_correct = false;
      }
    }
    question.options[index].is_correct = !question.options[index].is_correct;

    //console.log(question)

    this.setState({ question });
  }

  keyDown = (e, index) => {
    var question = this.state.question;
    var key = e.keyCode || e.charCode;
    if(this.state.question.type === 2) {
      if(key === 8 || key === 46) {
        question.options[index].statements[0].statement = '';
        this.setState({ question });
      }
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null ,question_image:null});
    document.getElementById('img-input').value = "";
  }
  removeImage1 = (index) => {
    var question = this.state.question;
    this.setState({ image1: null, imageURL1: null ,option_image:null});
    question.options[index].statements[0].image='';
    this.setState({ question });
    document.getElementById('img-input'+index).value = "";
  }

  optionChange = (e, index) => {
    var question = this.state.question;
    if(this.state.question.type === 5) {
      e.preventDefault();
    }
    else {
      if(this.state.question.type === 2) {
        if(question.options[index].statements[0].statement.length < 2) {
          if(question.options[index].statements[0].statement.length === 1) {
            if(question.options[index].statements[0].statement === '-') {
              if(e.target.value === '--') {
                e.preventDefault();
              } 
              else {
                question.options[index].statements[0].statement = (e.target.validity.valid) 
                  ? e.target.value : question.options[index].statements[0].statement;
                question.options[index].image = (this.state.option_image) 
                  ? this.state.option_image : question.options[index].statements[0].image;
                this.setState({ question });
              }
            }
            else {
              e.preventDefault();
            }
          }
          else {
            question.options[index].statements[0].statement = (e.target.validity.valid) 
              ? e.target.value : question.options[index].statements[0].statement;
            question.options[index].image = (this.state.option_image) 
              ? this.state.option_image : question.options[index].statements[0].image ;
            this.setState({ question });
          }
        }
        else {
          e.preventDefault();
        }
      }
      else {
        question.options[index].statements[0].statement = (e.target.validity.valid) 
          ? e.target.value : question.options[index].statements[0].statement;
        question.options[index].image = (this.state.option_image) 
          ? this.state.option_image : question.options[index].statements[0].image ;
        this.setState({ question });
      }
    }
  }

  checkTimeTyed = async (val,sectionId,question) => {
    //console.log(question) ;

    let duration_seconds = 0;
    let duration_min = 0;

    this.state.sections.map((section,index1) => {
      section.questions.map((data,index) => {
        duration_seconds += data.question_data.duration_seconds;
      })
    })

    if(parseInt(this.state.duration_seconds) && val === 1) duration_seconds +=parseInt(this.state.duration_seconds);

    if(this.state.assessment.timed_type === 2){
      duration_min = Math.floor(duration_seconds / 60);
      await this.props.editAssessmentBasic(this.state.assessment.id,this.state.assessment.uid, this.state.assessment.title, localStorage.getItem("center_id"), this.state.assessment.timed_type, duration_min);
    }
  }


  createQuestion = async () => {

    const { id, question, marks, negative_marks, difficulty, duration_seconds,category} = this.state;
    
    //if(this.state.assessment.is_graded === true ) this.canCreate(parseInt(marks),1)
    this.checkTimeTyed(1,this.state.id,question.id);

    if(question.type === 6) {
      // await this.props.createQuestion(id, question.type, category,[{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}], 
      //                     [], parseInt(marks), parseInt(negative_marks), 
      //                    difficulty, parseInt(duration_seconds));
    await this.props.createQuestion(id, question.type, category,[{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}], 
                          [], parseFloat(marks), parseFloat(negative_marks), 
                         difficulty, parseInt(duration_seconds));                    
    }
    else {
      // await this.props.createQuestion(id, question.type,category, [{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}], 
      //                     question.options, parseInt(marks), parseInt(negative_marks), 
      //                    difficulty, parseInt(duration_seconds));
   await this.props.createQuestion(id, question.type,category, [{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}], 
                          question.options, parseFloat(marks), parseFloat(negative_marks), 
                         difficulty, parseInt(duration_seconds));

    }
  }

  editQuestion = async () => {
    const { id,question, marks, negative_marks, difficulty, duration_seconds,category } = this.state;
    //if(this.state.assessment.is_graded === true ) this.canCreate(parseInt(marks),2)
    this.checkTimeTyed(2,this.state.id,question.id);
    
    if(question.type === 6) {
      // await this.props.editQuestion(id,question.id,category, parseInt(marks), parseInt(negative_marks), 
      //                     difficulty, parseInt(duration_seconds),question.type,question.options,[{id:question.id,language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],true);

                          await this.props.editQuestion(id,question.id,category, parseFloat(marks), parseFloat(negative_marks), 
                          difficulty, parseInt(duration_seconds),question.type,question.options,[{id:question.id,language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],true);                   

    }
    else {
      // await this.props.editQuestion(id,question.id,category, parseInt(marks), parseInt(negative_marks), 
      //                     difficulty, parseInt(duration_seconds),question.type,question.options,[{id:question.id,language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],true);

                          await this.props.editQuestion(id,question.id,category, parseFloat(marks), parseFloat(negative_marks), 
                          difficulty, parseInt(duration_seconds),question.type,question.options,[{id:question.id,language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],true);                     

    }
  }

  checkOptions() {
    if(this.state.question.type === 6) {
      return true;
    }
    else {
      let con1 = false;
      let con2 = true;
      let options = this.state.question.options;
      for(let i = 0; i < options.length; i++) {
        if(options[i].is_correct === true) {
          con1 = true;
        }
        //&& isEmpty(options[i].statements[0].image
        if(isEmpty(options[i].statements[0].statement)) {
          con2 = false;
        }
      }
      return con1 && con2 && options.length > 0;
    }
  }

  checkEnablity = () => {
    let condition1 = this.state.question.statement.length > 0 || this.state.image;

    let condition2;

    if(this.state.marks.length !=undefined && this.state.negative_marks.length != undefined && this.state.duration_seconds.length != undefined){
       //console.log(this.state.duration_seconds + "" + this.state.duration_seconds.length)
       condition2 = (this.state.marks && this.state.marks.length >= 0) && (this.state.negative_marks && this.state.negative_marks.length >= 0)
                      && (this.state.duration_seconds && this.state.duration_seconds.length >= 0) && this.state.difficulty !== '';
    }else{
      if(this.state.marks !=="" && this.state.negative_marks !=="" && this.state.duration_seconds !==""){
        condition2 = true;
      }
    }

    let condition3 = this.checkOptions();

    return condition1 && condition2 && condition3;
  }

  checkEnablity2 = () => {
    let condition1 = this.state.question.statement.length > 0 || this.state.image;
    let condition2;
    if(this.state.marks.length !=undefined && this.state.negative_marks.length != undefined && this.state.duration_seconds.length != undefined){
       condition2 = (this.state.marks && this.state.marks.length >= 0) && (this.state.negative_marks && this.state.negative_marks.length >= 0)
                      && (this.state.duration_seconds && this.state.duration_seconds.length >= 0) && this.state.difficulty !== '';
      
    }else{
      
      if(this.state.marks !=="" && this.state.negative_marks !=="" && this.state.duration_seconds !==""){
        condition2 = true;
      }
    }

    let condition3 = this.checkOptions();

    //console.log(condition1 +"/"+condition2+"/"+condition3)

    return condition1 && condition2 && condition3;
  }

  fileInput2 = (e,index) => {
    document.getElementById("img-input"+index).click();
  }

  zoomOn = (url) => {
    this.setState({open5:true,selected_image:url})
  }

  canCreate = () => {
    let totalMarks =0,key=0;
    var qcount = {};
    this.state.sections.map((section,index1) => {
      section.questions.map((data,index) => {
        totalMarks += data.question_data.marks;
        key += 1;
        qcount[data.question_data.id] = key;
      })
    })

    this.setState({qcount})

    //console.log(totalMarks + "/" +this.state.assessment.total_marks)
    if(parseInt(totalMarks) === parseInt(this.state.assessment.total_marks) ){
      return true;
    }
    return false;
  }

  modifyMarks =async (e,val,id,index) => {
    
    this.setState({
      values: {[index]: e.target.value }
    });
    this.setState({marks:e.target.value})
  }

  modifyMarks1 =async (e,val,id,index) => {
    
    if(val.question_data.question_type === 6) {
      await this.props.editQuestion(id,val.question_data.id, parseInt(this.state.values[index]), parseInt(val.question_data.negative_marking), 
                          val.question_data.difficulty, parseInt(val.question_data.duration_seconds),val.question_data.question_type,val.question_data.options,[{id:val.question_data.id,language: 1, statement: val.question_data.statements[0].statement, explanation:val.question_data.statements[0].explanation,image:val.question_data.statements[0].image}],false);
                          
    }
    else {
      await this.props.editQuestion(id,val.question_data.id, parseInt(this.state.values[index]),parseInt(val.question_data.negative_marking), 
                           val.question_data.difficulty, parseInt(val.question_data.duration_seconds),val.question_data.question_type,val.question_data.options,[{id:val.question_data.id,language: 1, statement: val.question_data.statements[0].statement, explanation: val.question_data.statements[0].explanation,image:val.question_data.statements[0].image}],false);
    }

    const response = await this.props.getAssessmentSectionList(this.props.match.params.id,false);
    this.setState({ sections: response.data})
    const response1 = await this.props.getAssessment(this.props.match.params.id,false);
    this.setState({ assessment: response1.data  })
    
    if(this.canCreate() === true){ this.setState({sumUpdateButton:1}) } else{ this.setState({sumUpdateButton:0})  }
  }

  addNewAssesContent = () => {
    const assessment_id = this.props.location.state?.assessment_id;
    if(assessment_id){
      localStorage.setItem("expandVal",this.props.location.state?.toc_id)
      localStorage.setItem("expand",true)
    }   
  }

  handleUsernameFocus = () => {
    var element = document.getElementById("myDIV");
    element.classList.add("open");
    
    document.getElementsByClassName("tab-content")[0].style.display="block";
    //document.getElementsByClassName('.search-overlay-wrapper')[0].classList.add('open');
  }

  customSearch =(quesValue) => {
    this.setState({quesValue})

    return false;
    //console.log(quesValue);
  }

  onUserInput = async (val) => {
    let {options} = this.state 
    if(val){
     const response = await this.props.questionBankSearch(val);
     options = response.data.map((val,index) =>{
      return { value: val.question_id, label: val.statement}
     })
     //options.push({ value: 1, label: 'Label 1' },)
    }
    this.setState({options})
  }

  mapSection = async (val) => {
    let questions =[] ;
    let {quesValue} = this.state

    for(let i=0 ; i<quesValue.length; i++){
      questions.push({'question_id':quesValue[i].value})
    }

    await this.props.questionSectionMapping(this.state.id,questions)
  }


  decodeHTMLEntities = (questionvalue) =>{
    var textArea = document.createElement('textarea');
      textArea.innerHTML = questionvalue;
      return textArea.value;
  }


  decodeHTMLEntitiesInput = (questionvalue) =>{
    let orignalValue = questionvalue;
    let finalValue  =  orignalValue.replace(/&#44;/g, ",");
    return finalValue;
  }


  render() {
    const { assessment_id,question,questionList,options,quesValue} = this.state;
    const isEnabled = this.state.section_title.length > 0 && this.state.negative_marking_per_q.length > 0
                        && this.state.instructions.length > 0;

    if(this.props.loading) {
      return (
        <div className="admin-div">
          <div className="asm-header">
            <div id="no_save"></div>
            <div>{this.props.pagewiseLanguageData.step3_add_questions}</div>
            <button id="button-reverse">
            {this.props.pagewiseLanguageData.save}
            </button>  
          </div>
        </div>  
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.assessment_questions}</title>
        </Helmet>
        <ReactNotifications/>
        <Popup open={this.state.open7} closeOnDocumentClick onClose={this.close7} className="map-questions">
          <span className="close" onClick={this.close7}>&#x2715;</span>
          <div className="add-question-bank">{this.props.pagewiseLanguageData.add_from_question_bank}</div>
          <div style={{marginTop: '15px'}}>
            <Select options={this.state.options} value={this.state.quesValue} onChange={this.customSearch} multiple={true} searchable={true} onSearch={this.onUserInput} />
          </div>
          <div className="selected-questions">
            <ul>
              {quesValue?.length && quesValue.map((val,index) =>
                <li>{val.label}</li>
              )}
            </ul>
          </div>
          <div className="selected-questions-button">
            <button onClick={this.mapSection}>{this.props.pagewiseLanguageData.add}</button>
          </div>
        </Popup>
        <Popup open={this.state.open6} closeOnDocumentClick onClose={this.close6} className="marks-verification">
          <div className="marksVerification">
            <div className="header-top"><span>{this.props.pagewiseLanguageData.demo_total_marks}</span> : <span>{this.state.assessment.total_marks}</span></div>
            <hr/>
            <div>{this.props.pagewiseLanguageData.enter_question_marks}</div>
            <div className="question-table">
              <div className="firstChild">
                <div>{this.props.pagewiseLanguageData.q_no}</div>
                <div>{this.props.pagewiseLanguageData.section}</div>
                <div>{this.props.pagewiseLanguageData.statement}</div>
                <div>{this.props.pagewiseLanguageData.marks}</div>
              </div>
              
                {this.state?.assessment?.sections?.length && this.state?.assessment?.sections?.map((section,index) =>
                  <> {section.questions.map((question,qindex)=>
                    <div className="secondChild">
                      <div>{this.state.qcount[question?.question_data?.id]}</div>
                      <div>{section.title}</div>
                      <div>{question?.question_data?.statements[0]?.statement}</div>
                      <div><input type="text" name="marks" defaultValue={question?.question_data?.marks} onChange={(e)=>this.modifyMarks(e,question,section.id,question?.question_data?.id)}/><span class='right'><button onClick={(e)=>this.modifyMarks1(e,question,section.id,question?.question_data?.id)}> &#10004; </button></span></div>
                    </div>
                  )}
                  </>
                )}
              
            </div>
            <hr/>
            <div className="footer-bottom newdesign">
              <button onClick={this.close6}>
                {this.props.pagewiseLanguageData.close}
              </button>  
              <Link Style={!this.state.sumUpdateButton? "pointer-events:none;cursor:default;text-decoration:'none'":"pointer-events:auto;cursor:auto;text-decoration: 'none'"} to={this.state.toc_id === null 
              ? {pathname: this.state.url} : {pathname: this.props.location.state?.data?.toc_url,
                search: this.props.location.state?.data?.search,
                state: {
                  classs: this.props.location.state?.data?.breads?.classs,
                  subject: this.props.location.state?.data?.breads?.subject,
                  unit: this.props.location.state?.data?.breads?.unit,
                  chapter: this.props.location.state?.data?.breads?.chapter,
                  topic: this.props.location.state?.data?.breads?.topic,
                  subtopic: this.props.location.state?.data?.breads?.subtopic,
                }}}>
                <button id="button-reverse">
                  {this.props.pagewiseLanguageData.save_marks}
                </button> 
              </Link> 
            </div>
          </div>
        </Popup>
        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="zoom-image-on">
          <div className="zoom-image">
            <img src={this.state.selected_image} />
          </div>
        </Popup>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.update_section}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.section_title} type="text" onChange={this.onTitleChange}
                value={this.state.section_title}/>
              <input placeholder={this.props.pagewiseLanguageData.negative_marks_per_question} type="text" onInput={this.onMarkChange}
                value={this.state.negative_marking_per_q} pattern="[0-9-]*"/>
            </div>
            <textarea placeholder={this.props.pagewiseLanguageData.section_instructions} onChange={this.onInstructionChange}
              className="popup-instructions" value={this.state.instructions}/>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.updateSection}>{this.props.pagewiseLanguageData.update_section}</button>
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.solution_of_question}</span>
            <textarea value={this.state.explanation} onChange={this.onExplanationChange}
              placeholder={this.props.pagewiseLanguageData.enter_solution}/>
            <div>
              <button onClick={this.close2}>
                {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.solution_of_question}</span>
            <div id="see_explanation_ques">
              {this.state.see_explanation}
            </div>
            <div>
              <button onClick={this.close3}>
              {this.props.pagewiseLanguageData.close}
              </button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content6">
          <div className="delete-up">
            <img src={Trash} alt={this.props.pagewiseLanguageData.delete}/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.removeSection}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <Popup open={this.state.deleteQues} closeOnDocumentClick onClose={this.deleteQues}
          className="popup-content6">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message2}
          </div>
          <div className="delete-down">
            <button onClick={this.deleteQues}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.onDeleteQuestion}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="asm-header">
          <div id="no_save"></div>
          <div>{this.props.pagewiseLanguageData.step3_add_questions}</div>
          <button id="button-reverse" onClick={this.show6}>
          {this.props.pagewiseLanguageData.save}
          </button>
          <Link onClick={this.addNewAssesContent} style={{textDecoration: 'none',display: 'none'}} to={this.state.toc_id === null 
            ? {pathname: this.state.url} : {pathname: this.props.location.state?.data?.toc_url,
              search: this.props.location.state?.data?.search,
              state: {
                classs: this.props.location.state?.data?.breads?.classs,
                subject: this.props.location.state?.data?.breads?.subject,
                unit: this.props.location.state?.data?.breads?.unit,
                chapter: this.props.location.state?.data?.breads?.chapter,
                topic: this.props.location.state?.data?.breads?.topic,
                subtopic: this.props.location.state?.data?.breads?.subtopic,
              }}}>
            <button id="button-reverse-link">
            {this.props.pagewiseLanguageData.save}
            </button> 
          </Link> 
        </div>
        <div className="main-part" id="unit-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <h2>{this.props.pagewiseLanguageData.all_sections}</h2>
              </div>
            </div>
            <div className="heading-part2">
              <span onClick={this.addSections}>
                <img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_section}
              </span>
            </div>
          </div>
          <div className="sub-head2">
            <Link to={{pathname: `/admin/assessment-settings/${assessment_id}`,
              state: {toc_id: this.state.toc_id, class_id: this.state.class_id, 
                subject_id: this.state.subject_id, data: this.props.location.state,
                url: this.state.url}}}>
              {this.props.pagewiseLanguageData.go_to_settings}
            </Link>
          </div>
          <div className="unit-data">
            {this.state.sections.map((section, index2) =>
              <div className="single-unit" style={this.state.expand[section.id] 
                ? {maxHeight : 'max-content'} : {maxHeight : '72px'}} key={section.id}>
                <div className="unit-handler">
                  <div className="unit-handler-left" id="sec-expand">
                    <span onClick={() => this.onExpand(section.id)}>
                      {this.state.expand[section.id] ? <img src={Collapse} alt="coll"/> 
                        : <img src={Expand} alt="exp"/>}
                    </span>
                    {section.title}
                  </div>
                  <div className="unit-handler-right">
                    {this.state.question_ob[section.id] !== true 
                      ? <>
                      <span onClick={() => this.show(section)}>
                        <img src={Edit} alt="edit"/>
                      </span>
                      <span onClick={() => this.showd(section.id)}>
                        <img src={Delete} alt="delete"/>
                      </span>
                      <button onClick={() => this.onAddQuestion(section.id, index2)}>
                      {this.props.pagewiseLanguageData.add_question}
                      </button>
                      <Link className="add-from-question-bank" to={{pathname: `/admin/question-bank`,
                        state: {assessment_section_id:section.id,source:"add-from-question-bank"}}}>
                        {this.props.pagewiseLanguageData.add_from_question_bank}
                      </Link>
                    </> : null}
                  </div>
                </div>
                <div className="subunit-data2">
                  {this.state.question_ob[section.id] === true ? <div className="edit-question">
                    {!this.state.editing && <div className="edit-ques-head-on">
                      {this.state.editq ? <span>{this.state.titleq}</span>
                        : <span>{this.props.pagewiseLanguageData.question} {section.questions.length + 1}</span>}
                      <select value={this.state.question.type} 
                        onChange={this.onTypeChange}>
                        <option value={''} disabled>{this.props.pagewiseLanguageData.question_type}</option>
                        <option value={1}>{this.props.pagewiseLanguageData.mcq}</option>
                        <option value={3}>{this.props.pagewiseLanguageData.multiple_response}</option>
                        <option value={2}>{this.props.pagewiseLanguageData.single_integer}</option>
                        <option value={4}>{this.props.pagewiseLanguageData.fill_in_the_blanks}</option>
                        <option value={5}>{this.props.pagewiseLanguageData.true_false}</option>
                        <option value={6}>{this.props.pagewiseLanguageData.subjective}</option>
                        <option value={7}>{this.props.pagewiseLanguageData.matching}</option>
                      </select>
                    </div>}
                    {this.state.editing && <div className="edit-ques-head-on"><span>{this.state.titleq}</span></div>}
                    <div className="ques-statement">
                      <textarea rows={3} placeholder="Question Statement"  value={this.decodeHTMLEntities(this.state.question.statement)}
                        onChange={this.onStatementChange}/>
                    </div>
                    
                    {(this.state.image === '' || this.state.image === null) && this.state.question_image === null && this.state.question.type !== 6 ?<div className="img-prev-statement ques-statement">
                      <span>{this.props.pagewiseLanguageData.upload} {this.state.question.type === 6 ? this.props.pagewiseLanguageData.file:this.props.pagewiseLanguageData.image} : </span><div onClick={() => this.fileInput.click()}><img src={this.state.image?common.addFileUrl(this.state.image) : ImgHolder} alt="Prev"/></div>
                      </div>:
                      <div className="img-prev-statement ques-statement" Style={this.state.question.type === 6 ?"display:none":""}>
                        <span onClick={this.removeImage}>&#x2715;</span>
                        <img src={this.state.question_image ? common.addFileUrl(this.state.question_image) : common.addFileUrl(this.state.imageURL)} alt="Prev"/>
                      </div>
                    }
                    
                    {this.state.question.type === 6 ? <div className="input_field_file" Style="display:block">
                       <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/pdf" type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                    </div>:<div className="input_field" Style="display:none">
                       <input accept="image/*" type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                    </div>}

                    
                    <div className="ques-sett-ops">
                      <div className="asm-ques-settings">
                        <span onClick={this.addMarksMinus}>&#45;</span>
                        <div>
                          {/* <input type="text" pattern="[0-9]*" value={this.state.marks}
                          onInput={this.positiveChange}/> */}
                          <input type="text" pattern="[0-9]*\.?[0-9]*" value={this.state.marks}
                          onInput={this.positiveChange}/>
                          

                        {this.props.pagewiseLanguageData.positive_marks}
                        </div>
                        <span onClick={this.addMarksPlus}>&#43;</span>
                      </div>
                      {this.state.question.type != 6 && <div className="asm-ques-settings">
                        <span onClick={this.addNegativeMarksm}>&#45;</span>
                        <div>
                          {/* <input type="text" pattern="[0-9]*" value={this.state.negative_marks}
                          onInput={this.negativeChange}/> */}
                          <input type="text" pattern="[0-9]*\.?[0-9]*" value={this.state.negative_marks}
                          onInput={this.negativeChange}/>

                        {this.props.pagewiseLanguageData.negative_marks}</div>
                        <span onClick={this.addNegativeMarks}>&#43;</span>
                      </div>}
                      <div className="asm-setting-duration">
                        <span onClick={this.minusTime}>&#45;</span>
                        <div><input type="text" pattern="[0-9]*" value={this.state.duration_seconds}
                          onInput={this.onChangeTime}/> {this.props.pagewiseLanguageData.sec}</div>
                        <span onClick={this.addTime}>&#43;</span>
                      </div>
                      
                    </div>
                    <div className="ques-sett-ops" id="ques-sett-ops2">
                      <select value={this.state.difficulty} onChange={this.difficultyChange} disabled={this.state.uid?"disabled":""}>
                        <option value={''}>
                         {this.props.pagewiseLanguageData.select_difficulty}
                        </option>
                        <option value={0}>
                        {this.props.pagewiseLanguageData.easy}
                        </option>
                        <option value={1}>
                        {this.props.pagewiseLanguageData.medium}
                        </option>
                        <option value={2}>
                        {this.props.pagewiseLanguageData.hard}
                        </option>
                        <option value={3}>
                        {this.props.pagewiseLanguageData.very_hard}
                        </option>
                      </select>
                      

                      {this.state.uid ?<div className="ques-sett-ops-uid">
                        <div><input type="text" value={this.state.uid}
                          disabled="disabled" placeholder={this.props.pagewiseLanguageData.unique_code}/></div>
                      </div>:""}
                      {!this.state.editing && <span id="ques-explanation-add" onClick={this.show2}>
                        {this.props.pagewiseLanguageData.add_solution}
                      </span>}
                    </div>

                    <div className="ques-sett-ops" id="ques-sett-ops2">
                      <select value={this.state.category} onChange={this.categoryChange}>
                        <option value="">--{this.props.pagewiseLanguageData.select_category} --</option>
                        {this.state.categoryList?.length > 0 && this.state.categoryList.map( ctrg =>
                          <option value={ctrg.id}>{ctrg.title}</option>
                        )}
                      </select>
                    </div>
                    
                      {this.state.question.type === 6 ? null 
                      : <div className="ques-options">
                        {this.state.question.type === 5 
                          ? <span>{this.props.pagewiseLanguageData.select_the_correct_answer}</span>
                          : <span>{this.props.pagewiseLanguageData.add_options_and_select_correct_answer}</span>}
                        {this.state.question.options && this.state.question.options.map((option, index) =>
                          <div className="ques-option" key={index}>
                            <span onClick={() => this.isCorrect(index)}
                              id={option.is_correct === true ? this.props.pagewiseLanguageData.is_correct : ""}>
                              {alphabet[index + 1]}
                            </span>
                            <input value={this.decodeHTMLEntitiesInput(option?.statements[0]?.statement)} placeholder={`${this.props.pagewiseLanguageData.option} ${index + 1}`}
                              onInput={(e) => this.optionChange(e, index)} onKeyDown={(e) => this.keyDown(e, index)}
                              pattern={this.state.question.type === 2 ? "[0-9-]*" : null}/>
                            {this.state.question.type !== 5 
                              ? <> {!this.state.editing && <span onClick={() => this.removeOption(index)} id="remove-opt">
                              &#10005;
                            </span>} </>: null}
                            { this.state.question.type !== 5 
                              ? <div className="img-prev-statement">{this.state.question.options[index]?.statements[0]?.image && <div onClick={() => this.removeImage1(index)}>&#x2715;</div>}<div onClick={(e) => this.fileInput2(e,index)}><img src={this.state.question.options[index]?.statements[0]?.image ?common.addFileUrl(this.state.question.options[index]?.statements[0]?.image ) : ImgHolder} alt="Prev"/></div>
                                </div>: null }
                            <div className="input_field" Style="display:none">
                             <input accept="image/*" type="file" id={"img-input"+index} onChange={(e) => this.handleChange1(e, index)} ref={fileInput1 => this.fileInput1 = fileInput1}/>
                            </div>
                          </div>
                        )}
                        {this.state.question.type === 5 ? null 
                          : <> {!this.state.editing && <button onClick={this.addOption}>Add option</button> } </>}
                      </div>}
                    {this.state.editing}
                    {this.state.editing && <div className="ques-save">
                      {this.state.editing && <span id="ques-explanation" onClick={this.show2}>
                        {this.props.pagewiseLanguageData.edit_solution}
                      </span>}
                      <div>
                        <button onClick={() => this.closeQuestionBox(section.id)}
                          id="button-reverse">
                          {this.props.pagewiseLanguageData.cancel}
                        </button>
                        {this.state.editq ? <button onClick={this.editQuestion}
                          disabled={!this.checkEnablity2()}>
                          {this.props.pagewiseLanguageData.save}
                        </button> : <button onClick={this.createQuestion}
                          disabled={!this.checkEnablity()}>
                          {this.props.pagewiseLanguageData.save}
                        </button>}
                      </div>
                    </div>}
                    {!this.state.editing && <div className="ques-save-noedit">
                      <div>
                        <button onClick={() => this.closeQuestionBox(section.id)}
                          id="button-reverse">
                          {this.props.pagewiseLanguageData.cancel}
                        </button>
                        {this.state.editq ? <button onClick={this.editQuestion}
                          disabled={!this.checkEnablity2()}>
                          {this.props.pagewiseLanguageData.save}
                        </button> : <button onClick={this.createQuestion}
                          disabled={!this.checkEnablity()}>
                          {this.props.pagewiseLanguageData.save}
                        </button>}
                      </div>
                    </div>}


                  </div> : <>
                    {section.questions.length === 0 ? <button id="empty-sec" 
                      onClick={() => this.onAddQuestion(section.id,index2)}>
                      {this.props.pagewiseLanguageData.add_question}
                    </button> : <div className="sec-ques-list">
                      {section.questions.length > 0  && section.questions.map((question, index) =>
                        <div className="sec-ques-data" key={index}>
                          <div className="sec-ques-head">
                            <div>
                              <span>{this.props.pagewiseLanguageData.question} {index + 1}</span>

                              <span onClick={() => this.onExpandQ(question,question.question)}>
                                {this.state.question_ids[question.question]  ? <img src={Collapse} alt="coll"/> 
                                  : <img src={Expand} alt="exp"/>}
                              </span>

                              
                              <span><img title={this.props.pagewiseLanguageData.edit_question} src={Edit} alt={this.props.pagewiseLanguageData.edit}
                                onClick={() => this.onEditQuestion(question, section.id, index + 1)}/>
                              </span>

                              <span><img title={this.props.pagewiseLanguageData.delete} src={Delete} alt={this.props.pagewiseLanguageData.delete}
                                onClick={() => this.onDeleteQues(question, section.id, index + 1)}/>
                              </span>
                            </div>
                            <div id="sec-ques-type">
                              {AssessmentType[question.question_data.question_type]}
                            </div>
                          </div>
                          <div className="sec-ques-statement" dangerouslySetInnerHTML={{__html: question.question_data?.statements[0]?.statement}}>
                            
                          </div>

                          {this.state.question_ids[question.question] === true
                            && <>
                            {question.question_data.question_type === 6 && question.question_data.statements[0].image  ? <> { this.state.file_extension ==2 ? <div className="download-file-attempt-question"><a href={common.addFileUrl(question.question_data.statements[0].image)} target="_blank" rel="" download>
                            {/* <i className="fas fa-download"/> */}
                            <img src={download} alt="download"/> {this.props.pagewiseLanguageData.download} {this.state.selected_image_on}
                            </a></div> :<span className="view-image" Style={!question.question_data.statements[0].image ? "display:none":""} onClick={()=>this.zoomOn(common.addFileUrl(question.question_data.statements[0].image))}><img src={common.addFileUrl(question.question_data.statements[0].image)} /></span>} </>:
                            <span className="view-image" Style={!question.question_data.statements[0].image ? "display:none":""} onClick={()=>this.zoomOn(common.addFileUrl(question.question_data.statements[0].image))}><img src={common.addFileUrl(question.question_data.statements[0].image)} /></span>}

                            <div className="sec-ques-options">
                              <div className="sec-ques-details">
                                <div className="single-sec-ques-detail">
                                  <span>
                                  {this.props.pagewiseLanguageData.positive_marks}:
                                  </span>
                                  <p>{question.question_data.marks}</p>
                                </div>
                                {question.question_data.question_type != 6 && <div className="single-sec-ques-detail">
                                  <span>
                                    {this.props.pagewiseLanguageData.negative_marking}:
                                  </span>
                                  <p>{question.question_data.negative_marking < 0 ? question.question_data.negative_marking * -1:question.question_data.negative_marking}</p>
                                </div>}
                                <div className="single-sec-ques-detail">
                                  <span>
                                    {this.props.pagewiseLanguageData.duration}:
                                  </span>
                                  <p>{question.question_data.duration_seconds}</p>
                                </div>
                                <div className="single-sec-ques-detail">
                                  <span>
                                    {this.props.pagewiseLanguageData.unique_code}:
                                  </span>
                                  <p>{question.question_data.uid}</p>
                                </div>
                                <div className="single-sec-ques-detail">
                                  <span>
                                    {this.props.pagewiseLanguageData.difficulty}:
                                  </span>
                                  <p>{difficultyArray[question.question_data.difficulty]}</p>
                                </div>
                              </div>
                              {question.question_data.question_type === 6 ? null : <>
                                <span>{this.props.pagewiseLanguageData.options}</span>
                                {question.question_data.options && question.question_data.options.map((option, index) =>
                                  <div className="ques-option" key={index}>
                                    <span id={option.is_correct === true ? "is_correct" : ""}>
                                      {alphabet[index + 1]}
                                    </span>
                                    <div id="sec-ques-option" dangerouslySetInnerHTML={{__html: option?.statements[0]?.statement}}>
                                      
                                    </div>
                                    <img src={option?.statements[0]?.image ?common.addFileUrl(option?.statements[0]?.image ) : ImgHolder} alt="Prev"/>
                                  </div>
                                )}
                              </>}
                              <div id="single-sec-ques-detail2" 
                                onClick={() => this.show3(question.question_data.statements[0].explanation)}
                                className="single-sec-ques-detail">
                                <p>{this.props.pagewiseLanguageData.see_solution}</p>
                              </div>
                            </div>
                          </>}
                        </div>
                      )}
                    </div>}
                  </>}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessment,assessmentQuestionDelete, categoryList,addAssessmentSection, getAssessmentSectionList, editQuestion, 
            createQuestion,questionBankSearch, deleteAssessmentSection,questionSectionMapping, updateAssessmentSection,updateImage,editAssessmentBasic,getPageLanguageWiseData,getLanguageWiseData } = actions;
  return {
    categoryList : () => dispatch(categoryList()),
    questionSectionMapping:(assessment_section_id,questions) => dispatch(questionSectionMapping(assessment_section_id,questions)),
    questionBankSearch : (key) => dispatch(questionBankSearch(key)),
    getAssessment: (id,ifloading) => dispatch(getAssessment(id,ifloading)),
    addAssessmentSection: (assessment, title) => dispatch(addAssessmentSection(assessment, title)),
    updateAssessmentSection: (id, title, negative_marking_per_q, instructions) => dispatch(updateAssessmentSection(id, title, negative_marking_per_q, instructions)),
    deleteAssessmentSection: (id) => dispatch(deleteAssessmentSection(id)),
    getAssessmentSectionList: (id,ifloading) => dispatch(getAssessmentSectionList(id,ifloading)),
    updateImage : (file) => dispatch(updateImage(file)),
    assessmentQuestionDelete:(assessment_section_id,question_id) => dispatch(assessmentQuestionDelete(assessment_section_id,question_id)),
    createQuestion: (assessment_section_id, question_type,category ,statements, options, marks, negative_marking, difficulty, duration_seconds) => dispatch(createQuestion(assessment_section_id, question_type, category,statements, options, marks, negative_marking, difficulty, duration_seconds)),
    editQuestion: (assessment_section_id,id,category, marks, negative_marking, difficulty, duration_seconds,question_type,options,statements,ifloading) => dispatch(editQuestion(assessment_section_id,id,category, marks, negative_marking, difficulty, duration_seconds,question_type,options,statements,ifloading)),
    editAssessmentBasic: (id, uid, title, center_id, timed_type, timed_duration_mins) => dispatch(editAssessmentBasic(id, uid, title, center_id, timed_type, timed_duration_mins)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions);