import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
// import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
// import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import MultiSelect from "react-multi-select-component";
// import InfiniteScroll from "react-infinite-scroll-component";
//import 'react-calendar/dist/Calendar.css';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import ProjectIcon from '../../public/icons/search-notes-icon.png';
import ProjectLink from '../../public/icons/link.png';
import { filter } from 'jszip';
import common from '../../common';
import Popup from 'reactjs-popup';
class StudioProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected:'',
      loading: true,
      openDialog : false,
      editDialog : false,
      titleSave:'',
      startDateSave:'',
      endDateSave:'',
      classes: [],
      classesDialog: [],
      batches: [],
      batchesFilter: [],
      subjects: [],
      batch:'',
      batchList:[],
      batch_ids:'',
      center_id:'',
      user_id:'',
      faculty_id: [],
      projectList: [],
      editSaveData:[],
      editClassData: '',
      token:'',
      openDeleteProjectDialog:false,
      saveDeleteProjectid:''
      
    }
   
  }
  
  componentDidMount() {
    this.getFacultyProfile();
    let getCenterID = localStorage.getItem("center_id");
    let getUserID = localStorage.getItem("user_id");
    let getToken = localStorage.getItem("token");

    if(getToken){
      this.setState({token : getToken});
    }
    if(getUserID){
      this.setState({user_id : getUserID});
    }
    if(getCenterID){
      this.setState({center_id : getCenterID});
      this.getFacultyDetails(getCenterID,getUserID);
    }

    this.getAllStudioProjectList(getCenterID,getUserID)
    
  }

  componentWillUnmount() {
    
  }

  getAllStudioProjectList= async (getCenterID,getUserID) => {
    const response = await this.props.getAllStudioProject(getCenterID,getUserID);
    if(response && response != undefined){
      console.log("projectList",response);
      this.setState({projectList : response.data.data})
      
    }
    
  }


  getFacultyDetails= async (getCenterID,getUserID) => {
    const {center_id,user_id} = this.state;
    const response = await this.props.getFacultyList(getCenterID,0);
    if(response && response != undefined){
      let saveResponse = response.data;
      let getFacultyData = saveResponse.filter(data => data.user_id == getUserID);
      let getFacultyId = getFacultyData[0].id
      this.setState({faculty_id : getFacultyId});
    }
    
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  batches: response.data.batches });
  }
  openEditProjectDialog = (data) => {
    console.log(data);
    console.log(data.classs.id);
    console.log(data.classs.name);


    let {batchesFilter} = this.state
    this.setState({editSaveData : data});
    this.setState({openDialog : true});
    this.setState({editdata : true});
    
    let batchData = data?.batch_ids;
    
    batchData.map(function(items){
      //console.log("items",items)
      items.label = items.name;
      items.value = items.name;
    }); 
   
    batchesFilter = this.state.batches.filter(batch => batch?.classs?.id === parseInt(data?.classs?.id))

    //console.log("batchData",batchesFilter);

    batchesFilter.map((item) => {
      item.value = item.label
    })
    // batchesFilter = this.state.batches.filter(batch => batch.classs?.id === parseInt(data?.classs?.id)).map(function(items){
    //   items.value = items.label;
    // });  

    this.setState({selected : batchData,batchesFilter});
    this.setState({titleSave :data.title});
    this.setState({startDateSave :data.start_date_time});
    this.setState({endDateSave :data.end_date_time});
    this.setState({editClassData : data.classs.id});
    this.setState({classesDialog : data.classs.id});
  
     

    
    
  }
   
  openProjectDialog = () => {
    this.setState({editSaveData : ''});
    this.setState({openDialog : true});
    this.setState({editdata : false});
    this.setState({titleSave : ''});
    this.setState({startDateSave : ''});
    this.setState({endDateSave : ''});
    this.setState({selected : ''});
   
  }

  closeProjectDialog = () => {
    this.setState({openDialog : false});
    this.setState({editDialog : false});
  }

  changeTitle = (e) => {
    console.log(e.target.value);
    this.setState({titleSave : e.target.value});
  }

  changeStartDate = (e) => {
    console.log(e.target.value);
    this.setState({startDateSave : e.target.value});
  }

  changeEndDate = (e) => {
    console.log(e.target.value);
    this.setState({endDateSave : e.target.value});
  }

  onClassChange = async (e) => {
    this.state.selected = '';
    if((e.target.value !== 0)) {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classs: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })

                        this.state.batches.map(function(items){
                          items.value = items.label;
                        }); 
      const response =  await this.props.getFilteredWiseStudioProject(e.target.value,'');                    
      if(response !== undefined) {
        console.log("response datassss");
        console.log(response.data.data);
        this.setState({projectList:response.data.data})
      }                                    
    }
    else {
      this.setState({ subjectsFilter: [], subject: 0,
                        batchesFilter: [], batch: 0 });
                  
    }

    
  }

  onBatchChange = async (e) => {
    this.state.selected = '';
    let {projectList,classs} = this.state;
    console.log("class",classs)
    console.log(e.target.value);
    console.log(projectList);
    if(e.target.value !== '') {
      this.setState({batch :e.target.value}); 
      const response =  await this.props.getFilteredWiseStudioProject(classs,e.target.value);                    
      if(response !== undefined) {
        console.log("response data");
        console.log(response.data.data);
        this.setState({projectList:response.data.data})
      }     
    }
    else {
      this.setState({ subjectsFilter: [], subject: 0,
                        batchesFilter: [], batch: 0 });
    }

    
  }

  onDialogClassChange = (e) => {
    this.state.selected = '';
    if(e.target.value !== '') {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classesDialog: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })

                        this.state.batches.map(function(items){
                          items.value = items.label;
                        });                    
    }
    else {
      this.setState({ subjectsFilter: [], subject: 0,
                        batchesFilter: [], batch: 0 });
    }

    
  }


  selected = (selected) =>{
    console.log("selected", selected);
    let {batch_ids, batchList,filterBatches} = this.state;
    let selectedOptionsArray = [];
     if(!Array.isArray(selected)){
      selectedOptionsArray.push(selected);
     }else{
      selectedOptionsArray =  selected;
     }
     batch_ids = selectedOptionsArray.map(data => data.id).join(',');
     this.setState({ selected,batch_ids})
  }


  saveProject = async () =>{
    let {titleSave,startDateSave,endDateSave,classesDialog,batch_ids,center_id,faculty_id} = this.state;
    console.log("classesDialog",classesDialog)
    const response =  await this.props.createStudioProject(startDateSave,endDateSave,'',titleSave,'',classesDialog,faculty_id,'','',batch_ids,center_id);
    if(response !== undefined) {
      console.log("response data")
    }
  }

  updateProject = async () =>{
    let {titleSave,startDateSave,endDateSave,classesDialog,batch_ids,center_id,faculty_id, editSaveData} = this.state;
    console.log("editSaveData",editSaveData)
    console.log("project_id",editSaveData.id)
    const response =  await this.props.updateStudioProject(editSaveData.id,startDateSave,endDateSave,'',titleSave,'',classesDialog,faculty_id,'','',batch_ids,center_id);
    if(response !== undefined) {
      console.log("response data")
    }
  }

  getFormattedDocumentDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }

  deleteProject= (projectid) => {
    console.log("projectid",projectid);
    this.setState({openDeleteProjectDialog : true});
    this.setState({saveDeleteProjectid : projectid});
    //this.deleteProjectActions()
    
  }

  cancelProjectActions = () => {
    this.setState({openDeleteProjectDialog : false});
  }

  deleteProjectActions = async () => {
    const {saveDeleteProjectid} = this.state;
    console.log("get Delete projectid",saveDeleteProjectid);
    this.setState({openDeleteProjectDialog : false});
    const response = await this.props.deleteStudioProject(saveDeleteProjectid);
    if(response !== undefined) {
      console.log("response after Delete");
      console.log(response);
    }
  }

  render() {
    const {activeDate,value} = this.state;
    const isEnabled = this.state.classesDialog != '' && this.state.selected != '' && this.state.titleSave != '' && this.state.startDateSave != '' && this.state.endDateSave != "" && this.state.endDateSave != '';
    
    return (
      <>

        <Popup open={this.state.openDeleteProjectDialog} closeOnDocumentClick onClose={this.cancelProjectActions}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            Are you sure you want to<br/>delete this Project?
          </div>
          <div className="delete-down">
            <button onClick={this.cancelProjectActions}>
              Cancel
            </button>
            <button onClick={this.deleteProjectActions}>
              Delete
            </button>
          </div>
        </Popup>


    {/*Add Project Dialog*/} 
      <div className={this.state.openDialog ? 'studioProjectDialog open' : 'studioProjectDialog'}>
              <div className='studioProjectDialogContainer'>
                <h2>{this.state.editdata ? this.props.saveLanguageWiseData.edit_studio_project : this.props.saveLanguageWiseData.add_studio_project} </h2>
                <div className='formWrapper fullWidth'>
                  <div className='formBlock'>
                    <label>{this.props.saveLanguageWiseData.enter_project_title}</label>
                    <input type="text" onChange={this.changeTitle} defaultValue={this.state.editSaveData.title} placeholder="enter project title"/>
                  </div>
                </div>
                <div className='formWrapper'>
                  <div className='formBlock'>
                    <label>{this.props.saveLanguageWiseData.choose_start_date}</label>
                    <input type="datetime-local" defaultValue={this.state.editSaveData.start_date_time} max={this.state.startDateSave}  onChange={this.changeStartDate} placeholder="Choose project start date"/>
                  </div>
                  <div className='formBlock'>
                    <label>{this.props.saveLanguageWiseData.choose_end_date}</label>
                    <input type="datetime-local"  defaultValue={this.state.editSaveData.end_date_time} min={this.state.startDateSave} onChange={this.changeEndDate}  placeholder="Choose project end date"/>
                  </div>
                </div>
                <div className='formWrapper'>
                  <div className='formBlock'>
                    <label>{this.props.saveLanguageWiseData.choose_class}</label>
                    <select onChange={this.onDialogClassChange}>
                      <option value={parseInt(0)}>
                        {this.props.saveLanguageWiseData.select_class}
                      </option>
                      {this.state.classes.map(classs => 
                        <option value={parseInt(classs.class_id)} selected={this.state.editClassData == classs.class_id?"selected":""} key={classs.class_id}>
                          {classs.class_name}
                        </option>
                      )}
                    </select>
                  </div>
                  <div className='formBlock'>
                   <label>{this.props.saveLanguageWiseData.choose_batch}</label>
                   <MultiSelect
                      options={this.state.batchesFilter}
                      value={this.state.selected}
                      onChange={this.selected}
                      disableSearch = "false"
                      labelledBy="Select Batch"
                      ClearIcon="false"
                      closeOnChangedValue= "false"
                    />  
                  </div>
                </div>
                <div className='formActions'>
                    <span onClick={this.closeProjectDialog}>{this.props.saveLanguageWiseData.cancel}</span>
                    {this.state.editdata ? <span onClick={() => this.updateProject()}>{this.props.saveLanguageWiseData.update_project}</span> : <span className={!isEnabled ? 'is_disabled' : ''} onClick={this.saveProject}>{this.props.saveLanguageWiseData.save_project}</span>}
                    
                </div>
              </div>
            </div>
      {/*Add Project Dialog*/}

      <div className="app-body" id="appBody" onScroll={this.handleScroll}>
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>{`${this.props.saveLanguageWiseData.studio_project} - ${this.props.saveLanguageWiseData.faculty}`}</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>

            <div className='studioProjectWrapper'>
              <div className='studioProjectContainer'>

                <div className='filtersBlock'>
                    <h1>{this.props.saveLanguageWiseData.studio_project}</h1>
                    <div>
                    <select onChange={this.onClassChange} value={parseInt(this.state.classs)}>
                      <option value="">
                        {this.props.saveLanguageWiseData.select_class}
                      </option>
                      {this.state.classes.map(classs => 
                        <option value={parseInt(classs.class_id)} key={classs.class_id}>
                          {classs.class_name}
                        </option>
                      )}
                    </select>
                    <select onChange={this.onBatchChange} value={parseInt(this.state.batch)}>
                        <option value={parseInt(0)}>
                          {this.props.saveLanguageWiseData.select_batch}
                        </option>
                        {this.state.batchesFilter.map(batch => 
                          <option value={parseInt(batch.id)} key={batch.id}>
                            {batch.label}
                          </option>
                        )}
                      </select>
                     <span className='addNewBtn' onClick={this.openProjectDialog}>{this.props.saveLanguageWiseData.add_new}</span>
                     
                    </div>

                </div>
                
                <div className='projectsListingWrapper'>
                {this.state.projectList && this.state.projectList.length > 0 &&
                  <ol>
                     {this.state.projectList.map (listData =>
                      <li>
                        <div className='projectContent'>
                            <div className='contentLeft'>
                              <h2>{listData.title}</h2>
                              <div>{this.props.saveLanguageWiseData.start_date} - <span>{this.getFormattedDocumentDate(listData.start_date_time)}</span></div>
                              <div>{this.props.saveLanguageWiseData.ends_date} - <span>{this.getFormattedDocumentDate(listData.end_date_time)}</span></div>
                              {listData.classs &&
                              <div className='batchList'>{this.props.saveLanguageWiseData.class} - <span>{listData.classs.name}</span></div>
                              }
                              {listData.batch_ids.map(batchData => 
                              <div className='batchList'>{this.props.saveLanguageWiseData.batch} - <span>{batchData.name}</span></div>
                              )}
                            </div>
                            <div className='actions'>
                                <span>
                                <Link
                                  to={{ pathname: "/faculty/studio-project-detail/",
                                  state: {projectData: listData} }}
                                  >
                                 <img src={ProjectLink}/>
                                </Link>
                               </span>
                                <span onClick={() => this.openEditProjectDialog(listData)}><img src={Edit}/></span>
                                <span onClick={() => this.deleteProject(listData.id)}><img src={Delete}/></span>
                            </div>
                        </div>
                      </li> 
                    )}
                  </ol>
                    }
                  {this.state.projectList.length == 0 &&
                      <div className='nodatafound'>{this.props.saveLanguageWiseData.no_project_found}</div> 
                    }
                    
                </div>

              </div>  
            </div>
          
           
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,createStudioProject,getFacultyList,getAllStudioProject,deleteStudioProject,updateStudioProject,getFilteredWiseStudioProject} = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    createStudioProject: (start_date_time,end_date_time,subject,title,duration_hrs,classs,faculty,url,mode,batch_ids,Center) => dispatch(createStudioProject(start_date_time,end_date_time,subject,title,duration_hrs,classs,faculty,url,mode,batch_ids,Center)),
    updateStudioProject: (project_id,start_date_time,end_date_time,subject,title,duration_hrs,classs,faculty,url,mode,batch_ids,Center) => dispatch(updateStudioProject(project_id,start_date_time,end_date_time,subject,title,duration_hrs,classs,faculty,url,mode,batch_ids,Center)),
    getFacultyList: (center_id,show_ext_dt) => dispatch(getFacultyList(center_id,show_ext_dt)),
    getAllStudioProject: (center_id,getUserID) => dispatch(getAllStudioProject(center_id,getUserID)),
    getFilteredWiseStudioProject: (class_id,batch) => dispatch(getFilteredWiseStudioProject(class_id,batch)),
    deleteStudioProject: (project_id) => dispatch(deleteStudioProject(project_id)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioProject);