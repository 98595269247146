import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';

import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      opendes: false,
      openi: false,
      next_step: false,
      id: this.props.match.params.id,
      loading: false,
      center_id: localStorage.getItem('center_id'),
      title: '',
      code: '',
      timed_type: '',
      timed_duration_mins: '',
      sections: '',
      isGraded: false,
      attemptsClose: false,
      is_negative:false,
      isTimed :false,
      resultAfter: false,
      solutionAfter: false,
      endDateTime: new Date(),
      resultAfterDate: new Date(),
      solutionAfterDate: new Date(),
      closed_after_passing: null,
      time_bound_type:0,
      result_after: null,
      show_solution_after: null,
      total: '',
      passing: '',
      difficulty: 0,
      instruction: '',
      description: '',
      type: '',
      timeline: 1,
      attempts: 0,
      sectionsList: [],
      url: this.props.location.state?.url === undefined ? null : this.props.location.state?.url,
      breads: this.props.location?.state?.breads,
      toc_id: this.props.location.state?.toc_id === undefined 
                ? null : this.props.location.state?.toc_id,
      class_id: this.props.location.state?.class_id === undefined 
                  ? null : this.props.location.state?.class_id,
      subject_id: this.props.location.state?.subject_id === undefined 
                    ? null : this.props.location.state?.subject_id,

    backTracking: false,           
   
    }
  }

  componentDidMount() {
    this.getAssessment(this.props.match.params.id);
    this.getSectionsList();
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
    this.getUrlfromCurrentPage();
  }


  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"menu");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyUR1 = getUrl.split("/");
    let modifyURL = modifyUR1[2];
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  getSectionsList = async () => {
    const response = await this.props.getAssessmentSectionList(this.props.match.params.id,true);
    this.setState({ sectionsList: response.data });
  }

  getAssessment = async (id) => {
    const response = await this.props.getAssessment(id);
    console.log("response",response.data.back_tracking)
    this.setState({ title: response.data.title, code: response.data.uid, 
                    timed_type: response.data.timed_type, duration: response.data.timed_duration_mins, 
                    sections: response.data.sections.length, type: response.data.type,
                    timed_duration_mins: response.data.timed_duration_mins,
                    instruction: response.data.instructions, description: response.data.description,
                    difficulty: response.data.difficulty, attempts: response.data.attempts_allowed,
                    isGraded: response.data.total_marks === 0 ? false : true,
                    passing: response.data.passing_marks + '', total: response.data.total_marks + '',
                    closed_after_passing: response.data.closed_after_passing === true ? 1 : 0,
                    time_bound_type: response.data?.time_bound_type,
                    attemptsClose: response.data.closed_after_passing == true || response.data.time_bound_type == 2? true :false,
                    resultAfter: response.data.result_after === 0 ? false :true,
                    result_after: response.data.result_after,
                    solutionAfter: response.data.show_solution_after === 0 ? false : true,
                    show_solution_after: response.data.show_solution_after,
                    is_negative: response.data.is_negative,
                    isTimed:response.data.timed_type ? true:false ,
                    backTracking:response.data.back_tracking,
                    
                  })

  }

  show = () => this.setState({ open: true });
  
  close = () => this.setState({ open: false });

  showdes = () => this.setState({ opendes: true });
  
  closedes = () => this.setState({ opendes: false });

  showi = () => this.setState({ openi: true });
  
  closei = () => this.setState({ openi: false });

  onTitleChange = (e) => {
    this.setState({ title: e.target.value })
  }

  onCodeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  onTypeChange = (val) => {
    this.setState({ timed_type: val ,duration : 5})
  }

  changeDuration = (e) => {
    const duration = (e.target.validity.valid) ? e.target.value : this.state.duration;
    this.setState({ duration });
  }

  totalChange = (e) => {
    const total = (e.target.validity.valid) ? e.target.value : this.state.total;
    this.setState({ total })
  }

  passingChange = (e) => {
    const passing = (e.target.validity.valid) ? e.target.value : this.state.passing;
    this.setState({ passing})
  }

  onDifficultChange = (val) => {
    this.setState({ difficulty: val })
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  onInstructionChange = (e) => {
    this.setState({ instruction: e.target.value })
  }
  
  minusAttempt = (e) => {
    if(this.state.attempts !== 0) {
      var attempt = this.state.attempts - 1;
      this.setState({ attempts: attempt })
    }
  }

  addAttempt = (e) => {
    var attempt = this.state.attempts + 1;
    this.setState({ attempts: attempt })
  }

  levelChange = (val) => {
    this.setState({ level: val });
  }

  timelineChange = (val) => {
    this.setState({ timeline: val });
  }

  toggleGraded = () => {
    if(this.state.isGraded === true) {
      this.setState({ passing: '', total: '' })
    }
    this.setState({ isGraded: !this.state.isGraded })
  }

  toggleAttemptClose = () => {

    this.setState({ attemptsClose:!this.state.attemptsClose});
  }

  toggleNegativeClose = () => {

    this.setState({ is_negative:!this.state.is_negative});
  }


  toggleResultAfter = () => {
    if(this.state.resultAfter === true) {
      this.setState({ result_after: 0 })
    }
    this.setState({ resultAfter: !this.state.resultAfter })
  }

  toggleTimedClose = () => {
    this.setState({ isTimed:!this.state.isTimed})
    if(!this.state.isTimed === false){
      this.setState({ timed_duration_mins:0,duration:0,timed_type:0})
    }
    if(this.state.isTimed){
      this.setState({duration:5})
    }
  }

  toggleSolutionAfter = () => {
    if(this.state.solutionAfter === true) {
      this.setState({ show_solution_after: 0 })
    }
    this.setState({ solutionAfter: !this.state.solutionAfter })
  }

  toggleBackTracking = () => {
    console.log("value",);
    this.setState({ backTracking: !this.state.backTracking })
    //console.log("this.state.backTracking",this.state.backTracking);
  }

  changeAttemptAfter = (val) => {
    //this.setState({ closed_after_passing: val })
    if(val == 2){
      this.setState({ time_bound_type: 2 });
      this.setState({ closed_after_passing: 0 });
    }
    if(val == 1){
      this.setState({ closed_after_passing: 1 });
      this.setState({ time_bound_type: 0 });
    }
  }

  changeResultAfter = (val) => {
    this.setState({ result_after: val })
  }

  changeSolutionAfter = (val) => {
    this.setState({ show_solution_after: val })
  }

  changeEndDate = (date) => {
    this.setState({ endDateTime: date })
  }

  changeSolutionDate = (date) => {
    this.setState({ solutionAfterDate: date })
  }

  changeResultDate = (date) => {
    this.setState({ resultAfterDate: date })
  }

 

  editAssessmentBasic = async () => {
    this.setState({open:false})
    let duration_seconds = 0;
    let duration = this.state.duration;
    const { id, code, title, center_id, timed_type} = this.state;

    if(timed_type === 2){
      this.state.sectionsList.map((section,index1) => {
        section.questions.map((data,index) => {
          duration_seconds += data.question_data.duration_seconds;
        })
      })
      duration = Math.floor(duration_seconds / 60);
    }
    if(timed_type === 0){
      duration = 0;
    }

    await this.props.editAssessmentBasic(id, code, title, center_id, timed_type, duration);
  } 

  updateAssessment = async () => {

    const { id, difficulty, attempts, total, passing, result_after, show_solution_after, instruction, description,isGraded,is_negative,backTracking} = this.state;
    console.log("this.state.backTracking in function",this.state.backTracking)
    let closed_after_passing=this.state.closed_after_passing;
    let time_bound_type=this.state.time_bound_type;

    if(this.state.attemptsClose == false){
      closed_after_passing=0;
      time_bound_type=0;
    }

    if(this.state.isGraded === true) {
      console.log("this.state.backTracking isGraded",this.state.backTracking)
      // if(parseInt(total) > parseInt(passing)) {
      //   await this.props.updateAssessment(id, difficulty, attempts, total, passing, closed_after_passing, result_after, show_solution_after, instruction, description,isGraded,time_bound_type,is_negative,backTracking);
      //   this.setState({ next_step: true });
      // }
      if(parseFloat(total) > parseFloat(passing)) {
        await this.props.updateAssessment(id, difficulty, attempts, total, passing, closed_after_passing, result_after, show_solution_after, instruction, description,isGraded,time_bound_type,is_negative,backTracking);
        this.setState({ next_step: true });
      }
      else {
        store.addNotification({
          title: 'Invalid data',
          message: `${this.props.pagewiseLanguageData.passing_marks_should_be_less_than_total_marks}`,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        })
      }
    }
    else {
      console.log("this.state.backTracking Else",this.state.backTracking)
      await this.props.updateAssessment(id, difficulty, attempts, 0, 0, closed_after_passing, result_after, show_solution_after, instruction, description,isGraded,time_bound_type,is_negative,backTracking);
      this.setState({ next_step: true });
    }
  }

	render() {
    const { isGraded, attemptsClose, resultAfter, solutionAfter, difficulty, next_step, 
              id,is_negative,isTimed} = this.state;
    const isEnabled = this.state.title.length > 0 && this.state.code.length > 0
    if(this.props.loading) {
      return (
        <div className="admin-div">
        </div>
      )
    }
    if(next_step) {
      return (
        <Redirect to={{ pathname: `/admin/assessment-questions/${id}`,
                        state: { toc_id: this.state?.toc_id, class_id: this.state?.class_id, 
                          subject_id: this.state?.subject_id, data: this.props.location.state?.data,
                          url: this.state.url } }}/>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.assessment_settings}</title>
        </Helmet>
        <ReactNotifications/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content2">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_basic_details}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data2">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.assessment_title} onChange={this.onTitleChange}
                value={this.state.title}/>
              <input placeholder={this.props.pagewiseLanguageData.test_code} onChange={this.onCodeChange}
                value={this.state.code}/>
            </div>
            <div className="base-settings">
              <div>
                
                <div className="asm-setting-div-popup">
                  <div className="asm-setting-option">
                    <span>{this.props.pagewiseLanguageData.is_timed}</span>
                    <>
                      <input
                        checked={isTimed}
                        onChange={this.toggleTimedClose}
                        className="react-switch-checkbox"
                        id={`react-switch-istimed`}
                        type="checkbox"
                      />
                      <label
                        style={{ background: isTimed && '#489198' }}
                        className="react-switch-label"
                        htmlFor={`react-switch-istimed`}
                      >
                        <span className={`react-switch-button`} />
                      </label>
                    </>
                  </div>
                  {isTimed && <div className="asm-setting-horizon">
                    <div>
                      <input name="time" type="radio" value={1} onClick={() => this.onTypeChange(1)}
                        checked={this.state.timed_type === 1}/>
                      <label htmlFor={1}>{this.props.pagewiseLanguageData.timed_on_total}</label>
                      {this.state.timed_type === 1 ? <div className="asm-setting-duration">
                        <div><input value={this.state.duration} onInput={this.changeDuration}
                          type="text" pattern="[0-9]*"/> {this.props.pagewiseLanguageData.mins}</div>
                      </div> : null}
                    </div> 
                    <div>
                      <input name="time" type="radio"  value={2} onClick={() => this.onTypeChange(2)}
                        checked={this.state.timed_type === 2}/>
                      <label htmlFor={2}>{this.props.pagewiseLanguageData.timed_on_individual_question}</label>
                    </div> 
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="popup-actions-onedit">
            <button disabled={!isEnabled} onClick={this.editAssessmentBasic}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.opendes} closeOnDocumentClick onClose={this.closedes}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.assessment_description}</span>
            <textarea value={this.state.description} onChange={this.onDescriptionChange}
              placeholder={this.props.pagewiseLanguageData.enter_description}/>
            <div>
              <button onClick={this.closedes}>
              {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.openi} closeOnDocumentClick onClose={this.closei}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.assessment_instruction}</span>
            <textarea value={this.state.instruction} onChange={this.onInstructionChange}
              placeholder={this.props.pagewiseLanguageData.enter_instructions}/>
            <div>
              <button onClick={this.closei}>
              {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <div className="asm-header">
          <div id="no_save"></div>
          <div>{this.props.pagewiseLanguageData.step2_assessment_settings}</div>
          <button onClick={this.updateAssessment}>
            {this.props.pagewiseLanguageData.next}
          </button>
        </div>
        <div className="asm-container">
          <div className="asm-ques">
            <div className="asm-info">
              <div className="asm-head-edit">
                <span id="asm-titles2">{this.props.pagewiseLanguageData.base_details}</span>
                <span id="asm-edit-button" onClick={this.show}>
                {this.props.pagewiseLanguageData.edit}
                </span>
              </div>
              <div className="asm-title">
                <span>
                  {this.state.title}
                </span>
                <span>
                  {this.state.code}
                </span>
              </div>
              <div className="base-settings">
                <div>
                  <span id="asm-titles">{this.props.pagewiseLanguageData.assessment_time}</span>
                  <div className="asm-setting-horizon2">
                    {this.state.timed_type === 1 ? `${this.props.pagewiseLanguageData.timed_on_total} - ${this.state.timed_duration_mins} ${this.props.pagewiseLanguageData.mins}`
                      : this.state.timed_type === 2 ? `${this.props.pagewiseLanguageData.timed_on_individual_question}`
                      : `${this.props.pagewiseLanguageData.not_timed}`}
                  </div>
                </div>
                <div>
                  <span id="asm-titles">{this.props.pagewiseLanguageData.sections}</span>
                  <div className="asm-setting-horizon2">
                    {this.state.sections}
                  </div>
                </div>
              </div>
            </div>
            <div className="asm-info" id="asm-info2">
              <span id="asm-titles2">{this.props.pagewiseLanguageData.assessment_settings}</span>
              <div className="asm-setting-div2">
                <div>
                  <span id="asm-titles3">{this.props.pagewiseLanguageData.difficulty}</span>
                  <div className="asm-difficulty">
                    <span onClick={() => this.onDifficultChange(0)}
                      className={difficulty === 0 ? "selected-span" : ""}>{this.props.pagewiseLanguageData.easy}</span>
                    <span onClick={() => this.onDifficultChange(1)}
                      className={difficulty === 1 ? "selected-span" : ""}>{this.props.pagewiseLanguageData.medium}</span>
                    <span onClick={() => this.onDifficultChange(2)}
                      className={difficulty === 2 ? "selected-span" : ""}>{this.props.pagewiseLanguageData.hard}</span>
                    <span onClick={() => this.onDifficultChange(3)}
                      className={difficulty === 3 ? "selected-span" : ""}>{this.props.pagewiseLanguageData.very_hard}</span>
                  </div>
                </div>
                <div className="asm-setting-sections">
                  <span id="asm-titles3">{this.props.pagewiseLanguageData.attempts_instruction}</span>
                  <div className="asm-setting-duration">
                    <span onClick={this.minusAttempt}>&#45;</span>
                    <div>{this.state.attempts}</div>
                    <span onClick={this.addAttempt}>&#43;</span>
                  </div>
                </div>
              </div>
              <div className="asm-setting-div3">
                <span onClick={this.showdes}>{this.props.pagewiseLanguageData.assessment_description}</span>
                <span onClick={this.showi}>{this.props.pagewiseLanguageData.assessment_instruction}</span>
              </div>
              <div className="asm-setting-div">
                <div className="asm-setting-option">
                  <span>
                    {this.props.pagewiseLanguageData.is_graded}
                  </span>
                  <>
                    <input
                      checked={isGraded}
                      onChange={this.toggleGraded}
                      className="react-switch-checkbox"
                      id={`react-switch-new`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: isGraded && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-new`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>
                {isGraded ? <div className="asm-setting-data" id="asm-setting-graded">
                  <div><div>{this.props.pagewiseLanguageData.total_marks} :</div><div><input type="text" pattern="[0-9]*\.?[0-9]*" placeholder={this.props.pagewiseLanguageData.total_marks} 
                    onInput={this.totalChange} value={this.state.total}/></div></div>
                  <div><div>{this.props.pagewiseLanguageData.passing_marks} :</div><div><input type="text" pattern="[0-9]*\.?[0-9]*" placeholder={this.props.pagewiseLanguageData.passing_marks} 
                    onInput={this.passingChange} value={this.state.passing}/></div></div>
                </div> : null}
              </div>

              <div className="asm-setting-div">
                <div className="asm-setting-option">
                  <span>
                  {this.props.pagewiseLanguageData.is_negative}
                  </span>
                  <>
                    <input
                      checked={is_negative}
                      onChange={this.toggleNegativeClose}
                      className="react-switch-checkbox"
                      id={`react-switch-newn`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: is_negative && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-newn`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>
              </div>
              <div className="asm-setting-div">
                <div className="asm-setting-option">
                  <span>
                    {this.props.pagewiseLanguageData.attempts_closed_after}
                  </span>
                  <>
                    <input
                      checked={attemptsClose}
                      onChange={this.toggleAttemptClose}
                      className="react-switch-checkbox"
                      id={`react-switch-new1`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: attemptsClose && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-new1`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>
                {attemptsClose ? <div className="asm-setting-data">
                  <div className="asm-options-layer">
                    {this.state.type === 2 && <div className="asm-single-sett">
                      <input name="attempts" type="radio" value={0} onClick={() => this.changeAttemptAfter(2)}
                        defaultChecked={this.state.time_bound_type === 2}/>
                      <label htmlFor={0}>{this.props.pagewiseLanguageData.scheduled_date_time}</label>
                    </div>}
                    <div className="asm-single-sett">
                      <input name="attempts" type="radio" value={1} onClick={() => this.changeAttemptAfter(1)}
                        defaultChecked={this.state.closed_after_passing === 1}/>
                      <label htmlFor={1}>{this.props.pagewiseLanguageData.assessment_is_passed}</label>
                    </div>
                  </div>
                </div> : null}
              </div>
              <div className="asm-setting-div">
                <div className="asm-setting-option">
                  <span>
                   {this.props.pagewiseLanguageData.result_available_after_attempt}
                  </span>
                  <>
                    <input
                      checked={resultAfter}
                      onChange={this.toggleResultAfter}
                      className="react-switch-checkbox"
                      id={`react-switch-new2`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: resultAfter && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-new2`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>
                {resultAfter ? <div className="asm-setting-data">
                  <div className="asm-options-layer">
                    <div className="asm-single-sett">
                      <input name="results" type="radio" value={2} onClick={() => this.changeResultAfter(2)}
                        defaultChecked={this.state.result_after === 2}/>
                      <label htmlFor={2}>{this.props.pagewiseLanguageData.assessment_passing}</label>
                    </div>
                    <div className="asm-single-sett">
                      <input name="results" type="radio" value={3} onClick={() => this.changeResultAfter(3)}
                        defaultChecked={this.state.result_after === 3}/>
                      <label htmlFor={3}>{this.props.pagewiseLanguageData.all_attempts}</label>
                    </div>
                  </div>
                  <div className="asm-options-layer" id="asm-options-layer1">
                    <div className="asm-single-sett">
                      <input name="results" type="radio" value={4} onClick={() => this.changeResultAfter(4)}
                        defaultChecked={this.state.result_after === 4}/>
                      <label htmlFor={4}>{this.props.pagewiseLanguageData.every_attempt}</label>
                    </div>
                    {this.state.type === 2 &&
                      <div className="asm-single-sett">
                        <input name="results" type="radio" value={6} onClick={() => this.changeResultAfter(6)}
                          defaultChecked={this.state.result_after === 6}/>
                        <label htmlFor={6}>{this.props.pagewiseLanguageData.after_specific_date}</label>
                      </div>
                    }
                  </div>
                </div> : null}
              </div>
              <div className="asm-setting-div">
                <div className="asm-setting-option">
                  <span>
                  {this.props.pagewiseLanguageData.solution_available_after_attempt}
                  </span>
                  <>
                    <input
                      checked={solutionAfter}
                      onChange={this.toggleSolutionAfter}
                      className="react-switch-checkbox"
                      id={`react-switch-new3`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: solutionAfter && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-new3`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>

                

                {solutionAfter ? <div className="asm-setting-data">
                  <div className="asm-options-layer">
                    <div className="asm-single-sett">
                      <input name="solution" type="radio" value={2} onClick={() => this.changeSolutionAfter(2)}
                        defaultChecked={this.state.show_solution_after === 2}/>
                      <label htmlFor={2}>{this.props.pagewiseLanguageData.assessment_passing}</label>
                    </div>
                    <div className="asm-single-sett">
                      <input name="solution" type="radio" value={3} onClick={() => this.changeSolutionAfter(3)}
                        defaultChecked={this.state.show_solution_after === 3}/>
                      <label htmlFor={3}>{this.props.pagewiseLanguageData.all_attempts}</label>
                    </div>
                  </div>
                  <div className="asm-options-layer" id="asm-options-layer1">
                    <div className="asm-single-sett">
                      <input name="solution" type="radio" value={4} onClick={() => this.changeSolutionAfter(4)}
                        defaultChecked={this.state.show_solution_after === 4}/>
                      <label htmlFor={4}>{this.props.pagewiseLanguageData.every_attempt}</label>
                    </div>
                    {this.state.type === 2 &&
                      <div className="asm-single-sett">
                        <input name="solution" type="radio" value={6} onClick={() => this.changeSolutionAfter(6)}
                          defaultChecked={this.state.show_solution_after === 6}/>
                        <label htmlFor={6}>{this.props.pagewiseLanguageData.after_specific_date}</label>
                      </div>
                    }
                  </div>
                </div> : null}
              </div>
              <div className="asm-setting-div">
                 <div className="asm-setting-option">
                  <span>
                    Back Tracking
                  </span>
                  <>
                    <input
                      checked = {this.state.backTracking}
                      onClick={this.toggleBackTracking}
                      className="react-switch-checkbox"
                      id={`react-switch-new10`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: this.state.backTracking && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-new10`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>
                </div>   
            </div>
          </div>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessment, updateAssessment , editAssessmentBasic,getAssessmentSectionList,getPageLanguageWiseData,getLanguageWiseData} = actions;
  return {
    getAssessment: (id) => dispatch(getAssessment(id)),
    getAssessmentSectionList: (id,ifloading) => dispatch(getAssessmentSectionList(id,ifloading)),
    updateAssessment: (id, difficulty, attempts_allowed, total_marks, passing_marks, closed_after_passing, result_after, show_solution_after, instructions, description,isGraded,time_bound_type,is_negative,back_tracking) => dispatch(updateAssessment(id, difficulty, attempts_allowed, total_marks, passing_marks, closed_after_passing, result_after, show_solution_after, instructions, description,isGraded,time_bound_type,is_negative,back_tracking)),
    editAssessmentBasic: (id, uid, title, center_id, timed_type, timed_duration_mins) => dispatch(editAssessmentBasic(id, uid, title, center_id, timed_type, timed_duration_mins)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);