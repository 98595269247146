import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import main from './routes/main';
import learner from './routes/learner';
import faculty from './routes/faculty';
import Sidebar from './components/navigation/Sidebar';
import HeaderLearner from './components/navigation/HeaderLearner';
import HeaderFaculty from './components/navigation/HeaderFaculty';
import SidebarLearner from './components/navigation/SidebarLearner';
import WidgetSidebarLearner from './components/navigation/WidgetSidebarLearner';
import SidebarFaculty from './components/navigation/SidebarFaculty';
import Login from './pages/login/Login';
import Register from './pages/login/Register';
import viewMapping from './pages/mapping/ViewMapping';
import forgotPassword from './pages/login/ForgotPassword';
import changePassword from './pages/login/ChangePassword';
import Signin from './pages/login/Signin';
import ReactSignin from './pages/login/ReactSignin';
import Home from './pages/home/Home';
import AskDoubts from './components/doubts/AskDoubts';
import Dashboard from './pages/dashboard/Dashboard';
import DashboardLearner from './pages/dashboard/DashboardLearner';
import WidgetDashboard from './pages/dashboard/WidgetDashboard';
import DashboardFaculty from './pages/dashboard/DashboardFaculty';
import pdfPlayer from './components/players/pdf/pdfPlayer';
import VideoPlayer from './components/players/video/VideoPlayer';
import ContentView from './pages/study-material/ContentView';
import AttemptPaper from './pages/assessment/AttemptPaper';
import AssessmentPreview from './pages/assessment/AssessmentPreview';
import AssessmentPreview2 from './pages/assessment/AssessmentPreview2';
import AssignmentPreview from './pages/admin/AssignmentPreview';
import AssignmentPreview2 from './pages/admin/AssignmentPreview2';
import LearnerAssignmentReview from './pages/admin/LearnerAssignmentReview';
import LearnerAssignmentReview2 from './pages/admin/LearnerAssignmentReview2';
import FacultyReview from './pages/assessment/FacultyReview';
import FacultyReview2 from './pages/assessment/FacultyReview2';
import FacultyAssignmentReview from './pages/admin/FacultyAssignmentReview';
import FacultyAssignmentReview2 from './pages/admin/FacultyAssignmentReview2';
import LearnerReview from './pages/assessment/LearnerReview';
import LearnerReview2 from './pages/assessment/LearnerReview2';
import StudentScorecard from './pages/scorecard/StudentScorecard';
import FacultyScorecard from './pages/scorecard/FacultyScorecard';
import NotFound from './components/error/NotFound';
import Unsubscribe from './pages/unsubscribe/Unsubscribe';
import LearnerDoubts from './pages/doubts/LearnerDoubts';
import Events from './pages/mycoach/events'
import Challenges from './pages/mycoach/challenges'
import LiveProjects from './pages/mycoach/liveProjects'
import MyPercentage from './pages/mycoach/myPercentage'
import CertificationAcademy from './pages/mycoach/certificationAcademy'
import StationaryShop from './pages/mycoach/stationaryShop'
import MyGoal from './pages/mycoach/myGoal';
import LearnerUGPGProgram from './pages/programs/LearnerUGPGProgram';
import MyCourses from './pages/mycoach/MyCourses';
import AicteSignup from './pages/students/AicteSignup';
import ResetPassword from './pages/students/ResetPassword';
import widgetSettingClass from './pages/widget/widgetSettingClass';
import NoToken from './pages/login/noToken';
import StudentIframeSessions from './pages/sessions/StudentIframeSessions'
import StudentIframeSessionsMobile from './pages/sessions/StudentIframeSessionsMobile'
import ActivateUser from './pages/activate-users/activate-users'
import LearnfestSessions from './pages/learnfest-sessions/learnfest-sessions';
import OOPS from '../src/public/icons/WiredMobile.png';
import Popup from 'reactjs-popup';
import config from '../src/config';
import myCoachPages from './pages/mycoach/myCoachPages';
import LessonPlan from './pages/lessonplan/LessonPlan';
import Terms from './pages/lessonplan/Terms';
import Privacy from './pages/lessonplan/Privacy';




const ProtectedAdminRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
  console.log(loggedIn)
  return (
    <Route path={path} {...rest}
      render={props => {
        return loggedIn ? (
          localStorage.getItem('is_admin') === 'true' || localStorage.getItem('is_faculty') === 'true' ? <Comp {...props} />
            : localStorage.getItem('is_faculty') === 'false'
              ? <Redirect to={{ pathname: "/learner" }} />
              : localStorage.getItem('is_faculty') === 'true'
                ? <Redirect to={{ pathname: "/faculty" }} />
                : <Redirect to={{ pathname: "/institute" }} />
        ) : (
          <Redirect to={{ pathname: "/institute" }} />
        );
      }}
    />
  );
};

const ProtectedFacultyRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
  console.log("ProtectedFacultyRoute tset", path)
  return (
    <Route path={path} {...rest}
      render={props => {
        return loggedIn ? (
          localStorage.getItem('is_faculty') === 'true' ? <Comp {...props} />
            : localStorage.getItem('is_faculty') === 'false'
              ? <Redirect to={{ pathname: "/learner" }} />
              : localStorage.getItem('is_admin') === 'true'
                ? <Redirect to={{ pathname: "/admin" }} />
                : <Redirect to={{ pathname: "/" }} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        );
      }}
    />
  );
};

const ProtectedLearnerRoute = ({ component: Comp, loggedIn, path, ...rest }) => {

  if (path == '/view-mapping') {

    localStorage.setItem("gmail_source", 1)
  }
  return (
    <Route path={path} {...rest}
      render={props => {
        return loggedIn ? (
          localStorage.getItem('is_faculty') === 'false' ? <Comp {...props} />
            : localStorage.getItem('is_faculty') === 'true'
            ? <Redirect to={{ pathname: config?.facultySidebarHidden_ids?.includes(parseInt(localStorage.getItem("center_id")))  ? '/faculty/amplifiu-create' :   "/faculty" }} />
              : localStorage.getItem('is_admin') === 'true'
                ? <Redirect to={{ pathname: "/admin" }} />
                : <Redirect to={{ pathname: "/" }} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        );
      }}
    />
  );
};

const UnprotectedRoute = ({ component: Comp, loggedIn, path, ...rest }) => {
  return (
    <Route path={path} {...rest}
      render={props => {
        return !loggedIn ? (
          <Comp {...props} />
        ) : (
          localStorage.getItem('is_faculty') === 'true'
            ? <Redirect to={{ pathname: config?.facultySidebarHidden_ids?.includes(parseInt(localStorage.getItem("center_id")))  ? '/faculty/amplifiu-create' :   "/faculty" }} />
            : localStorage.getItem('is_faculty') === 'false'
              ? <Redirect to={{ pathname: "/mycoach" }} />
              : <Redirect to={{ pathname: "/admin/institute" }} />
        );
      }}
    />
  );
};

class AssessmentPreviewModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="paper-modal">
        <AssessmentPreview props={this.props} />
      </div>
    )
  }
}

class AssignmentPreviewModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="paper-modal">
        <AssignmentPreview props={this.props} />
      </div>
    )
  }
}


class FacultyAssignmentReviewModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="paper-modal">
        <FacultyAssignmentReview props={this.props} />
      </div>
    )
  }
}

class LearnerAssignmentReviewModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="paper-modal">
        <LearnerAssignmentReview props={this.props} />
      </div>
    )
  }
}

class LearnerReviewModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="paper-modal">
        <LearnerReview props={this.props} />
      </div>
    )
  }
}

class FacultyReviewModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="paper-modal">
        <FacultyReview props={this.props} />
      </div>
    )
  }
}

class AppComponent extends Component {
  constructor(props) {
    super(props);
    this.previousLocation = this.props.location;
    this.state = {
      is_webview: this.props.location.search.split('&')[0].split('=')[1],
      is_error_found: false,
      is_error_found_login: false,
      error_message: '',
      error_code: 200,
      facultySidebarHidden_ids: true
    }
    this.testTimeout = this.testTimeout.bind(this)
  }


  componentDidMount() {
 
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    if (nextProps.history.action !== "POP" && (!location.state || !location.state.modal)) {
      this.previousLocation = this.props.location;
    }
  }

  testTimeout() {
    console.log('-----------------from timeout ---------');
    localStorage.setItem("is_error_found", 1)
    localStorage.setItem("error_message", this.props.saveLanguageWiseData.session_expired_login_again)
    localStorage.setItem("error_code", 403)
    this.setState({ is_error_found: true, error_message: this.props.saveLanguageWiseData.session_expired_login_again, error_code: 403, is_error_found_login: true })
  }

  onResourceLoad = () => {
    if (this.state.is_error_found_login) {
      let getCenterID = localStorage.getItem("center_id");
      let getUserRole = localStorage.getItem("is_admin");
      console.log(getCenterID)
      console.log(getUserRole)
      if (getCenterID == 5 && !getUserRole) {
        localStorage.clear();
        window.location.href = 'https://sia.mic.gov.in';
      } else {
        localStorage.clear();
        window.location.href = '/login';
      }
    } else {
      window.location.reload()
    }
  }

  render() {
    const { location } = this.props;
    console.log("App Component render");
    if ((localStorage.getItem('is_faculty') === 'true' || localStorage.getItem('is_admin') === 'true') && localStorage.getItem('token')) {
      if (typeof window.timeoutLogin == 'undefined') {
        console.log("set timeout function enter");
        //window.timeoutLogin = setTimeout(this.testTimeout, 1800000);
        window.timeoutLogin = setTimeout(this.testTimeout, config.sessionTimeOutTime);
      } else {
        console.log("else");
        clearTimeout(window.timeoutLogin);
        window.timeoutLogin = setTimeout(this.testTimeout, config.sessionTimeOutTime);
      }
    }
    localStorage.setItem("is_webview", this.state.is_webview)
    if (location.pathname == '/view-mapping') {
      localStorage.setItem("pathname", location.pathname)
      localStorage.setItem("search", location.search)
    }
    if (location.pathname == '/unsubscribe') {
      localStorage.setItem("page", "unsubscribe")
    }
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    ); // not initial render

    return (
      <div>
        {console.log("this.state.is_error_found", this.state.is_error_found, "prev ", this.previousLocation, ' now ', location)}

        <Popup children={<>
          <div><img src={OOPS} alt="oops" width="200px" height="124px" /></div>
          {/* <div className="ohno">Oops!</div> */}
          <div className="connection_failed">{this.state.error_message}</div>
          <div style={{ marginTop: "20px" }}>
            <button onClick={this.onResourceLoad}>{this.props.saveLanguageWiseData.try_again_now}</button>
          </div>
        </>} className="iserrorfound" open={this.state.is_error_found} closeOnDocumentClick={false} />



        <Switch location={isModal ? this.previousLocation : location}>

          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/login'}
            component={Login} />

          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/register'}
            component={Register} />

          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/unsubscribe'}
            component={Unsubscribe} />

          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/activate-user/:secret'}
            component={ActivateUser} />

          <ProtectedLearnerRoute exact={true} loggedIn={this.props.loggedIn} path={'/view-mapping'}
            component={viewMapping} />

          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/forgot-password'}
            component={forgotPassword} />

          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/reactsignin'}
            component={ReactSignin} />
          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/signin'}
            component={Signin} />
          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/'}
            component={Home} />
          {/* <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/'} 
            component={Login}/> */}
          <Route exact={true} path='/pdfPlayer' component={pdfPlayer} />
          <Route exact={true} path={`/videoPlayer`} component={VideoPlayer} />

          <UnprotectedRoute exact={true} path={`/mycoach/event`} component={Events} />
          <UnprotectedRoute exact={true} path={`/mycoach/challenge`} component={Challenges} />
          <UnprotectedRoute exact={true} path={`/mycoach/certificationacademys`} component={CertificationAcademy} />
          <UnprotectedRoute exact={true} path={`/mycoach/liveproject`} component={LiveProjects} />
          <UnprotectedRoute exact={true} path={`/mycoach/my-course`} component={MyCourses} />
          <UnprotectedRoute exact={true} path={`/mycoach/stationaryshops`} component={StationaryShop} />
          <UnprotectedRoute exact={true} path={`/mycoach/pages/:banner_type`} component={myCoachPages} />
          <UnprotectedRoute exact={true} path={`/learner/ugpg-programs/:category`} component={LearnerUGPGProgram} />
          <UnprotectedRoute exact={true} path={`/mycoach/mygoals/:id`} component={MyGoal} />
          <UnprotectedRoute exact={true} path={`/learner/assignment-reviews/:id`}
            loggedIn={this.props.loggedIn} component={LearnerAssignmentReview2} />

          <UnprotectedRoute exact={true} path={`/aicte`}
            loggedIn={this.props.loggedIn} component={AicteSignup} />
          <UnprotectedRoute exact={true} path={`/reset`}
            loggedIn={this.props.loggedIn} component={ResetPassword} />
          <UnprotectedRoute exact={true} loggedIn={this.props.loggedIn} path={'/notoken'}
            component={NoToken} />
          <Route exact={true} path={`/app/pdfPlayer`} component={pdfPlayer} />
          <Route exact={true} path={`/app/videoPlayer`} component={VideoPlayer} />
          <ProtectedAdminRoute exact={true} path={`/admin/widget-setting/:id`}
            loggedIn={this.props.loggedIn} component={widgetSettingClass} />

          <ProtectedAdminRoute exact={true} path={`/admin/assessment-preview/:id`}
            loggedIn={this.props.loggedIn} component={AssessmentPreview2} />
          <ProtectedAdminRoute exact={true} path={`/admin/assignment-preview/:id`}
            loggedIn={this.props.loggedIn} component={AssignmentPreview2} />
          <ProtectedAdminRoute exact={true} path={`/admin/widget-setting/:id`}
            loggedIn={this.props.loggedIn} component={widgetSettingClass} />
          <ProtectedFacultyRoute exact={true} path={`/faculty/assessment-review/:id`}
            loggedIn={this.props.loggedIn} component={FacultyReview2} />
          <ProtectedFacultyRoute exact={true} path={`/faculty/assignment-review/:id`}
            loggedIn={this.props.loggedIn} component={FacultyAssignmentReview2} />
          <ProtectedLearnerRoute exact={true} path={`/learner/assessment-review/:id`}
            loggedIn={this.props.loggedIn} component={LearnerReview2} />
          <ProtectedLearnerRoute exact={true} path={`/learner/assignment-review/:id`}
            loggedIn={this.props.loggedIn} component={LearnerAssignmentReview2} />
          <ProtectedLearnerRoute exact={true} path={`/learner/change-password/:name`}
            loggedIn={this.props.loggedIn} component={changePassword} />
          <ProtectedLearnerRoute exact={true} path={`/learner/attempt/:assessmentId`}
            loggedIn={this.props.loggedIn} component={AttemptPaper} />
          <ProtectedFacultyRoute exact={true} path={`/faculty/scorecard/:dpp`}
            loggedIn={this.props.loggedIn} component={FacultyScorecard} />
          <ProtectedLearnerRoute exact={true} path={`/learner/scorecard/:dpp`}
            loggedIn={this.props.loggedIn} component={StudentScorecard} />
          <ProtectedLearnerRoute exact={true} path={`/learner/classroom/:id`}
            loggedIn={this.props.loggedIn} component={ContentView} />
          <ProtectedLearnerRoute exact={true} path={`/learner/classroom/:id/:content_id/:table_id/:content_type`}
            loggedIn={this.props.loggedIn} component={ContentView} />
          <ProtectedFacultyRoute exact={true} path={`/faculty/classroom/:id`}
            loggedIn={this.props.loggedIn} component={ContentView} />
          <ProtectedFacultyRoute exact={true} path={`/faculty/change-password/:name`}
            loggedIn={this.props.loggedIn} component={changePassword} />
          <ProtectedLearnerRoute exact={true} path={`/learner/livesessionss/:token/:id`}
            loggedIn={this.props.loggedIn} component={StudentIframeSessions} />
          <UnprotectedRoute exact={true} path={`/learner/livesessions/:token/:id`}
            loggedIn={this.props.loggedIn} component={StudentIframeSessionsMobile} />
          {/* <UnprotectedRoute exact={true} path={`/learner/learnfest-sessions`} 
            loggedIn={this.props.loggedIn} component={LearnfestSessions}/> */}
          <Route exact={true} path='/learner/learnfest-sessions' component={LearnfestSessions} />
          <UnprotectedRoute exact={true} path={`/terms`} component={Terms} />
          <UnprotectedRoute exact={true} path={`/privacy`} component={Privacy} />
          <>
            {localStorage.getItem('is_admin') === 'true' ? <Sidebar /> : null}
            {this.props.dashboard_type == 'dynamic' && this.state.is_webview != 1 && localStorage.getItem('is_faculty') === 'false' ? <WidgetSidebarLearner /> : localStorage.getItem('is_faculty') === 'false' && localStorage.getItem('page') != "unsubscribe" && this.state.is_webview != 1 ? <SidebarLearner /> : null}

            {this.state.facultySidebarHidden_ids &&
              localStorage.getItem('is_faculty') === 'true' ? localStorage.getItem('is_faculty_admin') === 'false' ? <SidebarFaculty /> : null : null
            }

            {localStorage.getItem('is_faculty') === 'true' ? <HeaderFaculty /> : null}
            {localStorage.getItem('is_faculty') === 'false' && localStorage.getItem('page') != "unsubscribe" && this.state.is_webview != 1 ? <HeaderLearner /> : null}
            {localStorage.getItem('is_faculty') === 'false' && localStorage.getItem('page') != "unsubscribe" ? <AskDoubts /> : null}
            {localStorage.getItem('is_faculty_admin') === 'true' ? <Sidebar /> : null}
            <Switch location={isModal ? this.previousLocation : location}>
              <ProtectedAdminRoute exact={true} loggedIn={this.props.loggedIn} path={'/admin'}
                component={Dashboard} />


{!config?.facultySidebarHidden_ids?.includes(parseInt(localStorage.getItem("center_id"))) &&<ProtectedFacultyRoute exact={true} loggedIn={this.props.loggedIn} path={'/faculty'}
                                    component={DashboardFaculty} /> }


                <ProtectedFacultyRoute exact={true} loggedIn={this.props.loggedIn} path={'/faculty/amplifiu-create'}
                                  component={LessonPlan} />


              <ProtectedLearnerRoute exact={true} loggedIn={this.props.loggedIn} path={'/learner'}
                component={DashboardLearner} />
              {
                main.map((route, index) =>
                  <ProtectedAdminRoute
                    key={index}
                    loggedIn={this.props.loggedIn}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                )
              }
              {
                learner.map((route, index) =>
                  <ProtectedLearnerRoute
                    key={index}
                    loggedIn={this.props.loggedIn}
                    exact={route.exact}
                    path={route.path}
                    component={this.props.dashboard_type == 'dynamic' && route.path == '/mycoach' && this.props.learnerprofile?.institute_settings.is_dashboard == "true" ? WidgetDashboard : this.props.learnerprofile?.institute_settings.is_dashboard == "false" && route.path == '/mycoach' ? DashboardLearner : route.component}
                  />
                )
              }
              {
                faculty.map((route, index) =>
                  <ProtectedFacultyRoute
                    key={index}
                    loggedIn={this.props.loggedIn}
                    exact={route.exact}
                    path={route.path}
                    saveLanguageWiseData={this.props.saveLanguageWiseData}
                    component={this.props.dashboard_type == 'dynamic' && route.path == '/learner/dashboard' && this.props.learnerprofile?.institute_settings.is_dashboard == "true" ? WidgetDashboard : this.props.learnerprofile?.institute_settings.is_dashboard == "false" && route.path == '/learner/dashboard' ? DashboardLearner : route.component}
                  />
                )
              }
              <Route exact={true} path={'/unsubscribe'} component={Unsubscribe} />
              <Route component={NotFound} />

            </Switch>
          </>
        </Switch>
        {isModal ? <ProtectedAdminRoute path={`/admin/assignment-preview/:id`}
          loggedIn={this.props.loggedIn} component={AssignmentPreviewModal} /> : null}
        {isModal ? <ProtectedLearnerRoute path={`/learner/assessment-review/:id`}
          loggedIn={this.props.loggedIn} component={LearnerReviewModal} /> : null}
        {isModal ? <ProtectedLearnerRoute path={`/learner/assignment-review/:id`}
          loggedIn={this.props.loggedIn} component={LearnerAssignmentReviewModal} /> : null}
        {isModal ? <ProtectedLearnerRoute path={`/faculty/assignment-review/:id`}
          loggedIn={this.props.loggedIn} component={FacultyAssignmentReviewModal} /> : null}
        {isModal ? <ProtectedFacultyRoute path={`/faculty/assessment-review/:id`}
          loggedIn={this.props.loggedIn} component={FacultyReviewModal} /> : null}


      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.commonReducer.loggedIn,
    learnerprofile: state.commonReducer.logindetails,
    dashboard_type: state.commonReducer.dashboard_type,
    saveLanguageWiseData: state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
