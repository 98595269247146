import StudyMaterial from '../pages/study-material/StudyMaterial';
import StudyMaterialTopics from '../pages/study-material/StudyMaterialTopics';
import Practice from '../pages/student-assessments/Practice';
import Tests from '../pages/student-assessments/Tests';
import StudentSessions from '../pages/sessions/StudentSessions';
import ViewRecording from '../pages/sessions/ViewRecording';
import LearnerDoubts from '../pages/doubts/LearnerDoubts';
import LearnerQueries from '../pages/doubts/LearnerQueries';
import LearnerDoubtResponses from '../pages/doubts/LearnerDoubtResponses';
import SettingsLearner from '../pages/settings/Learner';
import MyCoachDashboard from '../pages/mycoach/myCoachDashboard';
import Challenges from '../pages/mycoach/challenges'
import LiveProjects from '../pages/mycoach/liveProjects'
import Events from '../pages/mycoach/events'
import MyPercentage from '../pages/mycoach/myPercentage'
import CertificationAcademy from '../pages/mycoach/certificationAcademy'
import StationaryShop from '../pages/mycoach/stationaryShop'
import MyGoal from '../pages/mycoach/myGoal';
import AssignmentMaterial from '../pages/admin/AssignmentMaterial';
import LearnerWebinar from '../pages/sessions/LearnerWebinar';
import LearnerAssignmentList from '../pages/admin/LearnerAssignmentList';
import LearnerQodHistory from '../pages/questions/LearnerQodHistory';
import MyCourses from '../pages/mycoach/MyCourses';
import LearnerNotificationHistory from '../pages/notifications/LearnerNotificationHistory';
import LearnerFreeCourse from '../pages/courses/LearnerFreeCourse';
import LearnerWidgetDashboard from '../pages/dashboard/WidgetDashboard';
import LearnerUGPGProgram from '../pages/programs/LearnerUGPGProgram';
import StudioProjectLearner from '../pages/studio-project/StudioProjectDetailLearner';
import StudioProjectListing from '../pages/studio-project/StudioProjectListing';
import myCoachPages from '../pages/mycoach/myCoachPages';
import support from '../pages/support/support';
import viewAttendanceReport from '../pages/view-attendance-report/view-attendance-report';





export default [
  {
    exact: true,
    component: StudyMaterial,
    path: '/learner/study-material/:id'
  },
  {
    exact: true,
    component: LearnerWebinar,
    path: '/learner/webinar'
  },
  {
    exact: true,
    component: AssignmentMaterial,
    path: '/learner/assignment/:id'
  },
  {
    exact: true,
    component: LearnerAssignmentList,
    path: '/learner/assignment'
  },
  {
    exact: true,
    component: StudyMaterialTopics,
    path: '/learner/study-material-topics/:id'
  },
  {
    exact: true,
    component: Practice,
    path: '/learner/practice'
  },
  {
    exact: true,
    component: Tests,
    path: '/learner/tests'
  },
  {
    exact: true,
    component: StudentSessions,
    path: '/learner/sessions'
  },
  {
    exact: true,
    component: ViewRecording,
    path: '/learner/view-recording/:id'
  },
  {
    exact: true,
    component: SettingsLearner,
    path: '/learner/settings'
  },
  {
    exact: true,
    component: LearnerDoubts,
    path: '/learner/doubts'
  },
  {
    exact: true,
    component: LearnerQueries,
    path: '/learner/queries'
  },
  {
    exact: true,
    component: LearnerDoubtResponses,
    path: '/learner/doubt-responses/:id'
  },
  {
    exact: true,
    component: MyCoachDashboard,
    path: '/mycoach'
  },
  {
    exact: true,
    component: Challenges,
    path: '/mycoach/challenges'
  },
  {
    exact: true,
    component: LiveProjects,
    path: '/mycoach/liveprojects'
  },
  {
    exact: true,
    component: Events,
    path: '/mycoach/events'
  },
  {
    exact: true,
    component: myCoachPages,
    path: '/mycoach/competitions'
  },
  {
    exact: true,
    component: myCoachPages,
    path: '/mycoach/virtualcampus'
  },
  {
    exact: true,
    component: myCoachPages,
    path: '/mycoach/placements'
  },
  {
    exact: true,
    component:myCoachPages,
    path: '/mycoach/myCoachPages'
  },
  {
    exact: true,
    component:myCoachPages,
    path: '/mycoach/internships'
  },
  {
    exact: true,
    component:myCoachPages,
    path: '/mycoach/visits'
  },
  {
    exact: true,
    component: MyPercentage,
    path: '/mycoach/mypercentage'
  },
  
  {
    exact: true,
    component: CertificationAcademy,
    path: '/mycoach/certificationacademy'
  },
  {
    exact: true,
    component: StationaryShop,
    path: '/mycoach/stationaryshop'
  },
  {
    exact: true,
    component: MyGoal,
    path: '/learner/mygoal/:id'
  },
  {
    exact: true,
    component: LearnerQodHistory,
    path: '/learner/qodhistory'
  },
  {
    exact: true,
    component: MyCourses,
    path: '/mycoach/my-courses'
  },
  {
    exact:true,
    component:LearnerNotificationHistory,
    path:'/learner/notifications'
  },
  {
    exact:true,
    component:LearnerFreeCourse,
    path:'/learner/free-courses'
  },
  {
    exact:true,
    component:LearnerWidgetDashboard,
    path:'/learner/dashboard'
  },
  {
    exact:true,
    component:LearnerUGPGProgram,
    path:'/learner/ugpg-program/:category'
  },
  {
    exact:true,
    component:StudioProjectLearner,
    path:'/learner/studio-project/:id'
  },
  {
    exact:true,
    component:StudioProjectListing,
    path:'/learner/project-listing'
  },
  {
    exact:true,
    component:support,
    path:'/learner/support'
  },

  {
    exact:true,
    component:viewAttendanceReport,
    path:'/learner/view-attendance-report'
  },
  

  
]