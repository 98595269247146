import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link,useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import Carousel from "react-multi-carousel";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import UnitsArrow from '../../public/icons/units-right-arrow.png';
import common from '../../common';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import SMLib from '../../public/images/sm-lib.svg';
import ContentLib from '../../public/images/content-lib.png';
import Target from '../../public/icons/sm-assess.svg';
import Empty from '../../components/error/Empty';
import idea from '../../public/icons/idea.svg';
import Notes from '../../public/icons/notes.svg';
import Video from '../../public/icons/video.svg';
import 'react-notifications-component/dist/theme.css';
import Assignment from '../../public/icons/assignment.svg';
import "react-multi-carousel/lib/styles.css";
import './styles.css';
import config from '../../config';

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 590 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

class StudyMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      is_faculty: localStorage.getItem('is_faculty') === 'true' ? true : false,
      subject_id: this.props.match.params.id,
      subject_label: this.props.location?.state?.subject_label,
      units: {},
      class_label:'',
      showModal: false,
      toc_ids: [],
      subjects: [],
      thumbnail:null,
      batch_id:0,
      class_id:0,
      is_archive:0,
      getCurrentClassId:''
    }
  }
  
  componentDidMount() {

    let backLocation = this.props.location;
    console.log("backLocation",backLocation.pathname);

    localStorage.setItem('prevLocation',backLocation.pathname);
    localStorage.setItem('fromtopic',false);

     this.state.is_faculty ? this.getFacultyProfile():this.getLearnerProfile();
     
     console.log("this.props.location?.state.is_archive");
     console.log(this.props.location?.state?.is_archive);
     let getis_archive = this.props.location?.state?.is_archive; 
     this.setState({is_archive:getis_archive});
    this.getStudyMaterial(getis_archive);
  
     
    
    let getCurrentClassId = this.props.location?.state?.currentClassid; 
    console.log("getCurrentClassId",getCurrentClassId);
    this.setState({getCurrentClassId:getCurrentClassId});
    if(getCurrentClassId){
      localStorage.setItem('set_is_archive',true)
    }else{
      localStorage.setItem('set_is_archive',false)
    }
      
  }

  getStudyMaterial = async (getis_archive) => {


    if(getis_archive){
      var response = await this.props.getStudentTOC(this.state.subject_id,getis_archive ? "1" : '');
    }else{
      var response = await this.props.getStudentTOC(this.state.subject_id);
    }

    
    this.setState({ units: response.data.toc_data.units,
            toc_ids: common.getContentIds(response?.data?.toc_data?.units), loading: false });



  }

  getLearnerProfile = async () => {
    
    const response = await this.props.getLearnerProfile();
    const subjectIndex=response.data.all_subjects.findIndex(p => parseInt(p.id) === parseInt(this.state.subject_id))
    //if(!this.state.subject_label) this.setState({subject_label:response.data.subjects[subjectIndex]?.label});
    if(!this.state.subject_label) this.setState({subject_label:response.data.all_subjects[subjectIndex]?.label});
    this.setState({subjects: response.data.subjects});
    this.getBatchName(response.data.batches);
    this.getSubject(response.data);
  }

  getFacultyProfile = async () => {
    
    const response = await this.props.getFacultyProfile();
    const subjectIndex=response.data.subjects.findIndex(p => parseInt(p.id) === parseInt(this.state.subject_id))
    if(!this.state.subject_label) this.setState({subject_label:response.data.subjects[subjectIndex].label});
    this.setState({subjects: response.data.subjects});
    this.getSubject(response.data);
    this.getBatchName(response.data.batches);
  }

  getSubject = (val) => {
    const subjectId=this.props.match.params;
      if(this.state.is_faculty){
        if(val.subjects && val.subjects.length){
          for(let i = 0; i < val.subjects.length; i++) {
            console.log(val.subjects[i].id)
            if(parseInt(subjectId.id) === parseInt(val.subjects[i].id)) {
              
              this.setState({ thumbnail: val.subjects[i].thumbnail });
              this.setState({ class_label: val.subjects[i].class_name });
              this.setState({ class_id: val.subjects[i].class_id });
            }
          }
        }
      }
      if(!this.state.is_faculty){
        if(val.all_subjects && val.all_subjects.length){
          for(let i = 0; i < val.all_subjects.length; i++) {
            console.log(val.all_subjects[i].id)
            if(parseInt(subjectId.id) === parseInt(val.all_subjects[i].id)) {
              
              this.setState({ thumbnail: val.all_subjects[i].thumbnail });
              this.setState({ class_label: val.all_subjects[i].class_name });
              this.setState({ class_id: val.all_subjects[i].class_id });
            }
          }
        }
      }
  }

  getBatchName = (val) => {
    if(val && val.length){  
      for(let i = 0; i < val.length; i++) {
        // if(parseInt(val[i].program.id) === parseInt(this.state.class_id))
        if(parseInt(val[i].classs.id) === parseInt(this.state.class_id))
        this.setState({batch_id:val[i].id});
        console.log("batch id mili");
        console.log(this.state.batch_id);
      }
    }
  }

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if(label.toLowerCase() === 'review' || label.toLowerCase() === 'result') {
      this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        {modal: true}
      )
    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })
    }
  }

  showAttemptModal = () => this.setState({ showModal: true })

  closeAttemptModal = () => this.setState({ showModal: false })

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if(label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        {modal: true}
      )
    }
    else {
      this.setState({showAttemptModal: false})
      this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );
    }
  }

  getColor = (content) => {
    if(content != undefined){
      if(content.content_subtype === 4 || content.content_subtype === 5) {
        return {}
      }
      else {
        if(content.actions.length === 1) {
          if(content.actions[0].label.toLowerCase() === 'attempt') {
            return {backgroundColor: 'config.themePrimaryLightColor'};
          }
          else {
            return {backgroundColor: config.themePrimaryLightColor};
          }
        }
        else {
          return {backgroundColor: config.themePrimaryLightColor};
        }
      }
    }
  }

  contentScore = (val) =>{

    if(isEmpty(val)) {
      return false;
    } else {
        return val;
    }
  }

	render() {

    const thumbNail=this.state.thumbnail;

    if(this.props.loading || this.state.loading) {
      return (
        <div className="app-body">
          <div className="app-div">
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
            <div className="study-material-loader"><div className='admin-loader '></div></div>
          </div>
        </div>
      )
    }
		return (
      <>
      {!isEmpty(this.props.contentPreview) 
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
          onClose={this.closeAttemptModal}>
          <AssessmentPreview 
            contentPreview={this.props.contentPreview}
            attempt={this.attemptPaper}
            showAttemptButton={true}
          />
        </Popup>}
			<div className="app-body">
        <div className={"app-div app-studymaterial widget-app-div " + (this.state.is_faculty ? 'is_faculty' : '')}>
          <Helmet>
            <title>{`${this.props.saveLanguageWiseData.study_material} - ${this.props.saveLanguageWiseData.faculty}`}</title>
          </Helmet>
          <ReactNotifications/>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="study-material-main">
          {this.state.getCurrentClassId  !== undefined &&
              <div className="viewingOldSemesterData">You are viewing old semester data</div>
              }
            <div className="heading alignLeft">
            {this.state.getCurrentClassId !== undefined &&  
            <Link id="trace-back" 
              to={{ pathname: `/learner`,
              state: {currentClassId: this.state.getCurrentClassId}}}>
                <img src={BackArrow} alt="back with class id"/>
            </Link>
              } 
              {this.state.getCurrentClassId === undefined &&        
            //  <div id="trace-back" onClick={() => this.props.history.goBack()}>
            //     <img src={BackArrow} alt="back"/>
            //   </div>
              <Link id="trace-back" 
                to={{ pathname: `/learner`,
                state: {currentClassId: ''}}}>
                  <img src={BackArrow} alt="back"/>
              </Link>
              }
							<h2>{this.state.class_label} | {this.state.subject_label} | {this.props.saveLanguageWiseData.units}</h2>
              <div>
              {/* this.state.is_faculty ? <Link to={{pathname: `/faculty/assignment/${this.state.subject_id}`,
                  state: { subject:this.state.subject_id,subjectName:this.state.subject_label}}}>
                  <button>
                  View Assignment
                  </button>
                </Link>:<Link to={{pathname: `/learner/assignment/${this.state.subject_id}`,
                  state: { subject:this.state.subject_id,subjectName:this.state.subject_label}}}>
                  <button>
                  View Assignment
                  </button>
                </Link>
              */}
              </div>
						</div>
            <div className='subjectBanner'>
                <img src={common.addFileUrl(this.state.thumbnail)} />
              </div> 
            {this.state.units.length === 0 && <Empty
              statement={`No content is created yet in ${this.state.subject_label}`}/>}
            {this.state.units && this.state.units.map(unit =>
              <div className="sm-units newDesign" key={unit.id}>
                <div className="sm-unit-div ">
                  <div className="sm-unit-div1">
                    <span>{unit.label}</span>
                    {/* <img src={SMLib} alt="img"/> */}
                  </div>
                  {unit?.content?.length ?
                  <div className="sm-unit-div2">
                  <Carousel containerClass="sm-chapters" responsive={responsive}
                      keyBoardControl={false} swipeable={true} draggable={false}
                      removeArrowOnDeviceType={["tablet", "mobile"]}>
                    {unit.content.length > 0  ? unit?.content?.map(unit =>
                    <>
                     { unit.content_type == 1 && unit.content_subtype == 4 && 
                      <Link className="sm-unit-content newdessss" to={{
                        pathname: !this.state.is_faculty 
                          ? `/learner/classroom/${this.state.subject_id}`
                          : `/faculty/classroom/${this.state.subject_id}`,
                        state: {table_id: unit.id,subject_label:this.state.subject_label,fromStudyMaterial:true, content_subtype_name:unit.content_subtype, content_id:unit.content_id, content_id:unit.content_id, assignment_id:unit.assignment_id,assignment_id:unit.assignment_id}}} style={this.getColor(unit)}
                        onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                        {isEmpty(unit?.thumbnail)?<div className='sm-unit-content-thumbnail'><img src={ContentLib} alt="img"/></div> :<div className="sm-unit-content-thumbnail"
                            ><img src={common.addFileUrl(unit?.thumbnail)} alt="img"/>
                        </div>}
                        
                        <div>{unit.title}</div>
                        
                      </Link>
                     }

                      {unit.content_type == 1 && unit.content_subtype == 5 && 
                      <Link className="sm-unit-content" to={{
                        pathname: !this.state.is_faculty 
                          ? `/learner/classroom/${this.state.subject_id}`
                          : `/faculty/classroom/${this.state.subject_id}`,
                        state: {table_id: unit.id,subject_label:this.state.subject_label,fromStudyMaterial:true,assignment_id:unit.assignment_id}}} style={this.getColor(unit)}
                        onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                        {isEmpty(unit?.thumbnail)?<div className='sm-unit-content-thumbnail'><img src={ContentLib} alt="img"/></div> :<div className="sm-unit-content-thumbnail"
                            ><img src={common.addFileUrl(unit?.thumbnail)} alt="img"/>
                        </div>}
                        <div>{unit.title}</div>
                      </Link>
                     }


                     {unit?.content_type == 2 && unit.content_subtype == 1 && <div className="sm-item pppppppppp"><Link style={this.getColor(unit)} className="sm-item " to={{
                        pathname: !this.state.is_faculty 
                          ? `/learner/classroom/${this.state.subject_id}`
                          : `/faculty/classroom/${this.state.subject_id}`,
                        state: {table_id: unit.id,subject_label:this.state.subject_label,fromStudyMaterial:true,assignment_id:unit.assignment_id,is_archive:this.state.is_archive}}} style={this.getColor(unit)}
                        onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                         <div className='sm-content-title'>
                            <img src={unit.assignment_id ? Assignment :Target} id="sm-assessm" alt="img"/>
                            <span>{unit.title}</span>
                            
                            {/* {!this.state.is_faculty && unit.is_graded && 
                              <span Style="margin-bottom: 10px;">
                                <b Style={!this.contentScore(unit.score) ? "display:none" : ""}>Score :</b>{this.contentScore(unit.score)}<br/>
                              </span>} */}

                              {unit.actions && unit.actions.map(action =>
                                (action.label == 'Review' || action.label == 'Result') && 
                                !this.state.is_faculty && unit.is_graded  &&
                                <span Style="margin-bottom: 10px;">
                                    <b Style={!this.contentScore(unit.score) ? "display:none" : ""}>Score :</b>{this.contentScore(unit.score)}<br/>
                                  </span>
                                   
                              )}

                        </div>
                       </Link>
                        {unit.actions.length > 0 && !this.state.is_faculty ? 
                        <div className="sm-content-actions tytyty">
                              {unit.actions && unit.actions.map(action =>
                              <div key={action.id} onClick={()=>this.attemptAndReview(action.label, unit.content_id)}>
                                {this.state.is_archive !== undefined && (action.label == "Review" || action.label == "Result") &&
                                  <button>{action.label}</button>
                                  }
                                {this.state.is_archive == undefined &&
                                  <button>{action.label}</button>
                                  }  
                            </div>
                           )}
                          </div> : <div className="sm-content-actions lll">
                              {unit.actions && unit.actions.map(action =>
                             <Link key={unit.content_id} to={{pathname: `/faculty/assessment-review/${unit.content_id}`,
                             state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                             <button>
                               {action.label}
                             </button>
                           </Link>
                           )}
                          </div>} 
                      </div>
                     }

                     {unit?.content_type == 3 && unit.content_subtype == 0 && <div className="sm-item"><Link style={this.getColor(unit)} className="sm-item " to={{
                      pathname: !this.state.is_faculty 
                        ? `/learner/classroom/${this.state.subject_id}`
                        : `/faculty/classroom/${this.state.subject_id}`,
                      state: {table_id: unit.id,subject_label:this.state.subject_label,fromStudyMaterial:true,assignment_id:unit.assignment_id}}} style={this.getColor(unit)}
                      onClick={() => this.props.getContentPreview(unit.content_id, unit.content_subtype)}>
                      <div className="sm-content-title">
                        <img src={unit.assignment_id ? Assignment :Target} id="sm-assessm" alt="img"/>
                        {!this.state.is_faculty && unit.is_graded && <span Style="margin-bottom: 10px;"><b Style={!this.contentScore(unit.score) ? "display:none" : ""}>{this.props.saveLanguageWiseData.score} :</b>{this.contentScore(unit.score)}<br/></span>}
                        <span>{unit.title}</span>
                      </div>
                      </Link>


                      {this.state.is_archive !== '' && unit.actions.length > 0 && !this.state.is_faculty &&
                          <div className="sm-content-actions hellossss">
                             {unit.actions && unit.actions.map(action =>
                              <Link key={unit.assignment_id} to={{pathname: `/learner/assignment-review/${unit.assignment_id}`,
                              state: {action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id, is_archive:true}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                            </div> }
                            {this.state.is_archive === '' && unit.actions.length > 0 && this.state.is_faculty && 
                            <div className="sm-content-action hello">
                              {unit.actions && unit.actions.map(action =>
                              <Link key={unit.assignment_id} to={{pathname: `/faculty/assignment-review/${unit.assignment_id}`,
                              state: {action:action.label,subject:this.state.subject_label,id:this.state.subject_id,class_id:this.state.class_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                            </div>} 



                    </div>
                   }
                   </>

                    ): <div className="sm-unit-content-noexist">
                    {isEmpty(thumbNail)? <img src={ContentLib} alt="img"/>  :
                      <div className="sm-unit-content-thumbnail"
                        style={{backgroundImage: `url(${common.addFileUrl(this.state.thumbnail)})`}}>
                      </div>
                    }
                    <div>{unit.label}</div></div>
                     }
                  </Carousel>
                  
                  </div>
                  : "" }
                </div>
                {unit.chapters.map(chapter =>
                  <div className="sm-layers wid100" key={chapter.id}>
                    <h3>{chapter.label}</h3>
                    <Carousel containerClass="sm-chapters" responsive={responsive}
                      keyBoardControl={false} swipeable={true} draggable={false}
                      removeArrowOnDeviceType={["tablet", "mobile"]}>
                      {chapter.content.map(content => <>
                        {content.content_subtype === 4 || content.content_subtype === 5 
                          ? <Link className="sm-item" to={{
                            pathname: !this.state.is_faculty 
                              ? `/learner/classroom/${this.state.subject_id}`
                              : `/faculty/classroom/${this.state.subject_id}`,
                            state: {table_id: chapter.id,subject_label:this.state.subject_label}}}
                            onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}
                            key={content.content_id} style={this.getColor(content)}>
                            {content.content_subtype === 4 || content.content_subtype === 5 
                              ? <div className="sm-thumbnail">
                              {content?.thumbnail?.length === 0 || content?.thumbnail === null
                                ? <div className="sm-thumbnail-img">
                                <img src={SMLib} alt="thumbnail"/>
                                {content.content_subtype === 4 ? <img src={Notes} id="sm-icon" alt="img"/>
                                  : content.content_subtype === 5 ? <img src={Video} id="sm-icon" alt="img"/> 
                                : null}
                              </div> : <div className="sm-thumbnail-img" style={{backgroundImage: 
                                `url(${common.addFileUrl(content?.thumbnail)})`}}>
                                {content.content_subtype === 4 ? <img src={Notes} id="sm-icon" alt="img"/>
                                  : content.content_subtype === 5 ? <img src={Video} id="sm-icon" alt="img"/> 
                                : null}
                              </div>}
                            </div> : null}
                            <div className="sm-content-title">
                              <span>{content.title}</span>
                            </div>
                            {content.actions.length > 0 && !this.state.is_faculty && <div className="sm-content-actions ddddd">
                              {content.actions && content.actions.map(action =>
                              <div key={action.id} 
                                onClick={()=>this.attemptAndReview(action.label, content.content_id)}>
                                <button>
                                  {action.label}
                                </button>
                              </div>
                            )}
                            </div>} 

                            {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 0 && <div className="sm-content-actions oppopp">
                              {content.actions && content.actions.map(action =>
                              <Link key={content.assignment_id} to={{pathname: `/learner/assignment-review/${content.assignment_id}`,
                                  state: {action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                            </div>}

                            {this.state.is_faculty && <div className="sm-content-actions rrrrr">
                              {content.actions && content.actions.map(action =>
                                <>{content.content_subtype  === 1 ?  <Link key={content.content_id} to={{pathname: `/faculty/assessment-review/${content.content_id}`,
                                state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link> : <Link to={{pathname: `/faculty/assignment-review/${content.assignment_id}` , state:{action:action.label,subject:this.state.subject_label,id:this.state.subject_id,class_id:this.state.class_id}}}>
                                    <button>
                                      {action.label}
                                    </button>
                                </Link> } </>
                            )}
                            </div>
                          }   
                          </Link> : <div className="sm-item" key={content.content_id}>
                          {this.state.is_archive !== undefined &&  
                          <Link className="sm-item" style={this.getColor(content)}
                            to={{ pathname: !this.state.is_faculty 
                                ? `/learner/classroom/${this.state.subject_id}`
                                : `/faculty/classroom/${this.state.subject_id}`,
                              state: {table_id: chapter.id,subject_label:this.state.subject_label, batch_id: this.state.batch_id , is_archive: true} }}
                            onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}>
                            <div className="sm-content-title ghghghgh">
                              <img src={content.assignment_id ? Assignment :Target} id="sm-assessm" alt="img"/>
                              {!this.state.is_faculty && <span Style="margin-bottom: 10px;"><b Style={!this.contentScore(content.score) ? "display:none" : ""}>Score :</b>{this.contentScore(content.score)}<br/></span>}
                              <span>{content.title}</span>
                            </div>
                          </Link>
                            }   
                          {this.state.is_archive == undefined &&
                          <Link className="sm-item" style={this.getColor(content)}
                            to={{ pathname: !this.state.is_faculty 
                                ? `/learner/classroom/${this.state.subject_id}`
                                : `/faculty/classroom/${this.state.subject_id}`,
                              state: {table_id: chapter.id,subject_label:this.state.subject_label, batch_id: this.state.batch_id,} }}
                            onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}>
                            <div className="sm-content-title ppppppp">
                              <img src={content.assignment_id ? Assignment :Target} id="sm-assessm" alt="img"/>
                              {content.actions.length > 0 && content.actions.map(data =>
                                !this.state.is_faculty && <div Style={(data?.label == "Review" || data?.label == "Result") ? '' : "display:none" }><span Style="margin-bottom: 10px;"><b Style={!this.contentScore(content.score) ? "display:none" : ""}>Score :</b>{this.contentScore(content.score)}<br/></span></div>
                              )}

                              {/* {!this.state.is_faculty &&<span Style="margin-bottom: 10px;"><b Style={!this.contentScore(content.score) ? "display:none" : ""}>Score :</b>{this.contentScore(content.score)}<br/></span>
                              } */}
                              <span>{content.title}</span>
                            </div>
                          </Link> 
                          }     

                          {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 1 && <div className="sm-content-actions yyyyy">
                              {content.actions && content.actions.map(action =>
                              <div key={action.id} 
                                onClick={()=>this.attemptAndReview(action.label, content.content_id)}>

                                {this.state.is_archive !== undefined && (action.label == "Review" || action.label == "Result") &&
                                  <button>{action.label}</button>
                                  }
                                {this.state.is_archive == undefined &&
                                  <button>{action.label}</button>
                                  }  



                              </div>
                            )}
                          </div>}

                          {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 0 && <div className="sm-content-actions ttttt">
                              {content.actions && content.actions.map(action =>
                              <Link key={content.assignment_id} to={{pathname: `/learner/assignment-review/${content.assignment_id}`,
                                  state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                          </div>}

                          {this.state.is_faculty && <div className="sm-content-actions qqqqq">
                              {content.actions && content.actions.map(action =>
                                <>{content.content_subtype  === 1 ? <Link key={content.content_id} to={{pathname: `/faculty/assessment-review/${content.content_id}`,
                                  state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link> : <Link to={{pathname: `/faculty/assignment-review/${content.assignment_id}` , state:{action:action.label,subject:this.state.subject_label,id:this.state.subject_id,class_id:this.state.class_id}}}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link>
                                }</>
                                
                            )}
                            </div>
                          }
                        </div>}
                      </>
                      )}
                      {chapter.topics.length > 0 && <Link className="sm-more" to={{
                        pathname: !this.state.is_faculty 
                          ? `/learner/study-material-topics/${this.state.subject_id}`
                          : `/faculty/study-material-topics/${this.state.subject_id}`,
                        state: {
                          topics: chapter.topics,
                          subject_label: this.state.subject_label
                        }}}>
                        {this.props.saveLanguageWiseData.view_topics}
                      </Link>}
                    </Carousel>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
			</div>
    </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentTOC, getContentPreview, getAttemptPreview,getLearnerProfile,getFacultyProfile} = actions;
  return {
    getStudentTOC: (subject_id,is_archive) => dispatch(getStudentTOC(subject_id,is_archive)),
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getContentPreview: (contentId, content_subtype) => dispatch(getContentPreview(contentId, content_subtype))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterial);