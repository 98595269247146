import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import common from '../../common';
import actions from '../../redux/actions';
import Search from '../../public/icons/search.svg';
import {FacebookShareButton,LinkedinShareButton,TwitterShareButton,WhatsappShareButton,EmailShareButton,ViberShareButton} from 'react-share';
import {FacebookIcon,LinkedinIcon,TwitterIcon,WhatsappIcon,EmailIcon,ViberIcon} from 'react-share';
import OOPS from '../../public/icons/WiredMobile.png';
import OOPSIcon from '../../public/icons/oops-image.gif';
import Edit from '../../public/icons/edit.svg';
import Edit1 from '../../public/icons/white-pencil.svg';
import Notification from '../../public/icons/notification.svg';
import Profile from '../../public/icons/profile.svg';
import './styles.css';
import { store } from 'react-notifications-component';
import ImgHolder from '../../public/images/img-holder.svg';
import Setting from '../../public/icons/setting.svg';
import Next from '../../public/icons/next.svg';
import { Link,NavLink,Redirect } from 'react-router-dom';
import ForgotImage from '../../public/icons/Group 270.svg';
// import See from '../../public/icons/see.svg';
// import Hide from '../../public/icons/hide.svg';
import See from '../../public/icons/../../public/icons/amplifiu-preview-icon.svg';
import Hide from '../../public/icons/amplifiu-preview-off-icon.svg';

import Switch from '../../public/icons/switch.svg';
import ReactNotifications from 'react-notifications-component';
import validator from 'validator';
import editIcon from '../../public/icons/amplifiu-edit-icon.svg';
import editPlaceholder from '../../public/icons/user-image-placeholder.svg';
import passwordKey from '../../public/icons/password-key.svg';
import logoutIcon from '../../public/icons/logoutIcon.svg';
import axios from 'axios';
import config from '../../config';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdisplay: false,
      dropdisplay2: false,
      profile: {},
      loading: true,
      username:'',
      email:'',
      mobile:'',
      image: null,
      open: false,
      open1: false,
      open2:false,
      open3:false,
      imageURL: null,
      password: '',
      new_password:'',
      is_password_correct:0,
      confirm_password:'',
      userid: '',
      hide1:true,
      hide:true,
      hide1:true,
      hide2:true,
      message:"",
      message_status:0,
      isValiated:true,
      password:"",
      new_password:"",
      confirm_password:"",
      uname:"",
      profile_picture:"",
      is_faculty_admin:false,
      isRoute:0,
      is_error_found:false,
      is_error_found_login:false,
      error_message:'',
      error_code:200,
      saveInstituteLanguages:localStorage.getItem('institute_languages'),
      facultySidebarHidden_ids:true,
      logo_url:localStorage.getItem('logo_url'),
      redirect:false,
    }
    this.mounted = true;
    this.myRef = React.createRef();

    
  }

  componentDidMount() {

    

    this.validatePage()
    if(localStorage.getItem("is_faculty_admin") == "true"){
      this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin") == "true" ? true:false})
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    this.getFacultyProfile();
    this.setState({comment:this.props.saveLanguageWiseData.hey_checkout_exclusive_online_message});

    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }

    

  
      if (config?.facultySidebarHidden_ids?.includes(parseInt(localStorage.getItem("center_id")))) {
          this.setState({ facultySidebarHidden_ids: false });
          this.setState({redirect : true});
      }
    

  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"faculty");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }


  languageUpdateInUserProfile = async (language) =>{
    const response = await this.props.updateProfile('','','','','',language);
    if(response !== undefined && response.status == 1) {
      console.log("languageUpdateInUserProfile");
      console.log(response?.data);
      window.location.reload();
    }
  }

  languageChange = (option) => {
    localStorage.setItem('lang',option.target.value);
    //this.languageUpdateInUserProfile(option.target.value);
    window.location.reload();
  }

  validatePage = () => {
    if(localStorage.getItem('token')){
      localStorage.setItem("is_error_found",0)
      localStorage.setItem("is_error_found_login",0)
      axios.get(`${common.apiBase}${common.authTokenValidate}`,
      {headers: {
        'Authorization': 'token ' + localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
      }).then(res => {
        if(res.status == 0) window.location.href='/login';
      }).catch(err => {
        // console.log('------Error', JSON.stringify(err));
        // console.log('error', err.status)
        if(err.message == 'Request failed with status code 403'){
          localStorage.setItem("is_error_found",1)
          localStorage.setItem("error_message","Your session expired! Please login again")
          localStorage.setItem("error_code",403)
          this.setState({is_error_found:true ,error_message:"Your session expired! Please login again",error_code:403,is_error_found_login:true})

        }else if(err.status =='Network Error'){
          this.setState({is_error_found:true ,error_message:"Your network connection is lost! Please try after some time",error_code:402})
        }else{
          let getCenterID = localStorage.getItem("center_id");
          let getUserRole = localStorage.getItem("is_admin");
          console.log(getCenterID);
          console.log(getUserRole);
          if(getCenterID == 5 && !getUserRole){
            localStorage.clear()
            window.location.href= 'https://sia.mic.gov.in';
          }else{
            localStorage.clear()
            window.location.href='/login';
          }
          // localStorage.clear()
          // window.location.href='/login';
        }
        localStorage.setItem("is_error_found_login",1)
        clearTimeout(window.timeoutLogin);
      })
    }
  }

  onResourceLoad = () =>{
    if(this.state.is_error_found_login){
      localStorage.clear()
      window.location.href='/login';
    }else{
      window.location.reload()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.mounted = false;
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }
  setMyRef = (node) => {
    this.myRef = node;
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ profile: response?.data, loading: false })

    this.setState({userid:response?.data.email,uname:response?.data.username,username:response?.data.name,email:response?.data.email,mobile:response?.data.mobile,profile_picture:response?.data.profile_picture});
  }
  
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdisplay: false })
    }

    if (this.myRef && !this.myRef.contains(event.target)) {
      this.setState({ dropdisplay2: false })
    }
  }

  onChangeUsername = (e) => this.setState({ username: e.target.value })
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    validator.isEmail(e.target.value)?this.setState({isValiated:true}):this.setState({isValiated:false});
  }
  onChangeMobile = (e) => {
    const mobile = (e.target.validity.valid) ? e.target.value : this.state.mobile;
    this.setState({ mobile })
  }

  dropchange = () => {
		this.setState({
			dropdisplay: !this.state.dropdisplay
		});
  }

  onSubmit = async () => {
    let image;
    if(this.state.profile_picture === "" || this.state.profile_picture === null){
      image=this.state.image;
    }else{
      image=this.state.profile_picture;
    }
    const response=await this.props.updateProfile(this.state.username,this.state.email,this.state.mobile,image,'');
    this.setState({ open: false });
    
  }

  handleChange = (event) => {
    if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({ 
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `Only images can be uploaded here.`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    this.setState({profile_picture:""});
    document.getElementById('img-input').value = "";
  }

  dropchange2 = () => {
    this.setState({
      dropdisplay2: !this.state.dropdisplay2
    });
  }

  addComment = (e) =>{
    this.setState({comment:e.target.value?e.target.value:this.state.comment});
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  hideChange1 = () => this.setState({ hide1: !this.state.hide1 })

  hideChange2 = () => this.setState({ hide2: !this.state.hide2 })

  show2 = () => {
    this.getFacultyProfile();
    this.setState({ open: true });
  }

  show4 = () => {
    this.setState({ open1:false,open2: true,password:'',new_password:'',confirm_password:'',is_password_correct:0});
  }
  show5 = () => this.setState({ open3: true });

  close = () => this.setState({ open: false });
  close2 = () => this.setState({ open2: false });
  close3 = () => this.setState({ open3: false });

  changePassword = async (username,password,new_password) => {
    const response = await this.props.changePassword(username,password,new_password,localStorage.getItem('lang'));
    
    this.setState({open2:false});
  }


  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  handleChangeNewPassword = (e) => {
    //e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
    this.setState({ new_password: e.target.value ,confirm_password:'',is_password_correct:0})
  }

  handleChangeConfirmPassword = (e) => {
    this.setState({confirm_password:e.target.value});

    e.target.value===this.state.new_password?this.setState({is_password_correct:1}):this.setState({is_password_correct:0});
  }

  contactUs = () => {
    window.open("https://schoolsonweb.com/contact/","_blank");
  }

  termsAndCondition = () => {
    window.open("https://schoolsonweb.com/terms/","_blank");
  }

  reportAnIssue = () =>{
    window.open("https://schoolsonweb.com/report-an-issue/","_blank");
  }

  check = () =>{
    var pass1 = document.getElementById('mobile');
    var goodColor = "#0C6";
    var badColor = "#FF9B37";

    var phoneno = /^\d{10}$/;
    if(pass1.value.match(phoneno))
    {
      return true;
    }
    else
    {
      pass1.style.color = badColor
      return false;
    }
  }


  renderRedirect = () => {
    if (this.state.redirect && localStorage.getItem("redirect") != 1) {
      localStorage.setItem("redirect",1);
      return <Redirect  to='/faculty/amplifiu-create' />
    }
  }



  changeUrl = () =>{
    localStorage.setItem('is_faculty_admin',!this.state.is_faculty_admin)
    this.setState({is_faculty_admin:!this.state.is_faculty_admin,isRoute:0});
  }

	render() {
    const dropstyle = this.state.dropdisplay ? {display: 'block'} : {display: 'none'};
    const dropstyle2 = this.state.dropdisplay2 ? {display: 'block'} : {display: 'none'};
    const isEnabled = !this.state.password || !this.state.new_password || !this.state.confirm_password || !this.state.is_password_correct;
    const shareUrl="https://schoolsonweb.com/";
    const isEnabled2 = !this.state.username || !this.state.email  || ((this.state.mobile + '').length != 10 && (this.state.mobile != null) && (this.state.mobile + '').length != 0) || !this.state.isValiated  

    

    if(window.location.pathname == '/faculty' && !this.state.isRoute){
      //window.location.reload();
    }
    //const isEnabled = this.state.username.length > 0 && this.state.email.length > 0 &&(this.state.mobile + '').length === 10;
    if(this.state.loading) {
      return (
        <div className="app-header">
          <div className="app-search-head">
					  {/* <img src={Search} alt="search"/>
					  <input placeholder="Search"/> */}
				  </div>
        </div>
      )
    }

   
		return (
      
      <div className="app-header" Style={this.state.is_faculty_admin ? "top:23px; flex-direction:row; align-items: flex-start;":"top:23px;flex-direction: unset;"}>
        {/* {this.state.facultySidebarHidden_ids ? 'true' : 'false'} */}
        {!this.state.facultySidebarHidden_ids &&  this.state.logo_url !== 'undefined' &&
            <Link to={'/faculty/amplifiu-create'}><span className='logospan'><img src={this.state.logo_url} alt="Brand logo"/></span></Link>
          } 

          {!this.state.facultySidebarHidden_ids && this.state.logo_url == 'undefined' &&
          <div className='logo logospan'><span><img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span></div>
          }
          
          {this.state.redirect && this.renderRedirect()}

          
              {!this.state.facultySidebarHidden_ids &&
               <div className='tabsWrapper'>
                 <NavLink to={'/faculty/amplifiu-create'} activeClassName="active">
                        <span>Create</span> 
                  </NavLink>
                  <NavLink to={'/faculty/amplifiu-learn'} activeClassName="active">
                        <span>Learn</span> 
                  </NavLink>
                  <NavLink to={'/faculty/amplifiu-collaborate'} activeClassName="active">
                        <span>Collaborate</span> 
                  </NavLink>
                
                  {/* <Link to={'/faculty/learn'}>
                      <span>Learn</span> 
                  </Link>
                  <Link to={'/faculty/collaborate'}>
                      <span>Collaborate</span> 
                  </Link> */}
                </div>
              }
        
        <div className="app-search-head" >
					{/*<img src={Search} alt="search"/>
					<input placeholder="Search"/>*/}
        </div>

        <Popup children={<>
          <div><img src={OOPSIcon} alt="oops" width="200px" height="124px"/></div>
          {/* <div className="ohno">Oops!</div> */}
          <div className="connection_failed">{this.state.error_message}</div>
          <div style={{marginTop:"20px"}}>
            <button onClick={this.onResourceLoad}>{this.props.saveLanguageWiseData.try_again_now}</button>
          </div>
        </>} className="iserrorfound" open={this.state.is_error_found} closeOnDocumentClick={false}/>
          
      


         <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="shareapp">
            
            <div className="share-app-div"> {this.props.saveLanguageWiseData.share_app}</div>
            <div className="social-share">
              <div>
                <textarea onKeyUp={this.addComment}>{this.props.saveLanguageWiseData.hey_checkout_exclusive_online_message}</textarea>
              </div>
              <div className="social-buttons">
                <FacebookShareButton
                  url={shareUrl}
                  quote={this.state.comment}
                  hashtag="#SchoolOnWeb">
                  <FacebookIcon logoFillColor="white" />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={shareUrl}
                  title={"Schoool On Web"}
                  description={this.state.comment}>
                  <LinkedinIcon logoFillColor="white" />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  title={this.state.comment}
                  hashtag="#programing joke">
                  <TwitterIcon logoFillColor="white" />
                </TwitterShareButton>
                <WhatsappShareButton  
                  url={shareUrl}
                  title={this.state.comment}
                  >
                  <WhatsappIcon logoFillColor="white" />
                </WhatsappShareButton >
                
                <EmailShareButton  
                  url={shareUrl}
                  subject={"School On Web"}
                  body={this.state.comment}>
                  <EmailIcon logoFillColor="white" />
                </EmailShareButton >
              </div>
            </div>
        </Popup>

        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content-edit-pofile">
          <div className="popup-header">
            {this.props.saveLanguageWiseData.edit_profile}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
          {this.state.image === '' || this.state.image === null 
                ? <div className="img-prev ProfileImageWrapper" >
                {this.state.profile_picture && <span onClick={this.removeImage}>&#x2715;</span> }
                <div onClick={() => this.fileInput.click()}><img src={this.state.profile_picture?common.addFileUrl(this.state.profile_picture) : editPlaceholder} alt="Prev"/></div>
              </div> : <div className="img-prev">
                <span onClick={this.removeImage}>&#x2715;</span>
                <img src={common.addFileUrl(this.state.imageURL)} alt="Prev"/>
              </div>}
              <div className="input_field" Style="display:none">
                 <input accept="image/*" type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
              </div>
            <div className="popup-inputs-edit-profile">
              <input type="text" placeholder={this.props.saveLanguageWiseData.name} value={this.state.username} 
                onKeyPress={this.handleKeyPress} onChange={this.onChangeUsername}/>
              <input type="email" placeholder={this.props.saveLanguageWiseData.email_address} value={this.state.email}  
                onKeyPress={this.handleKeyPress} onChange={this.onChangeEmail}/>
            </div>
            <div className="popup-inputs-edit-profile">
              <input placeholder={this.props.saveLanguageWiseData.mobile_no} type="text" onInput={this.onChangeMobile}
                maxLength={10} value={this.state.mobile} pattern="[0-9]*"/>
              
            </div>
            <div className="popup-inputs-edit-profile">
              
            </div>
          </div>
          <div className="popup-actions">
            <button onClick={this.onSubmit} disabled={ isEnabled2 ? "disabled":""}>{this.props.saveLanguageWiseData.update}</button>
          </div>
        </Popup>

        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="change-password" ref={this.myRef}>
           <div className="changePasswordWrapper">
           <div className="popup-header">
            Change Password
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
            {/* <div Style="width: 60%" className="leftImage"> 
              <img src={ForgotImage} alt="change-password" Style="width: 106%;height: 100%;"/>
            </div> */}
            <div Style="" className="RightCnt">
              {<div className="fw_input fw_input2">
                
                <div className="input_field">
                   <input type={this.state.hide ? "password" : "text"}  placeholder={this.props.saveLanguageWiseData.current_password} name='password'
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword}/>
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                <div className="input_field">
                   <input type={this.state.hide1 ? "password" : "text"} placeholder={this.props.saveLanguageWiseData.new_password} name='new_password'
                    value={this.state.new_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeNewPassword}/>
                    <div className="togglePassword" onClick={this.hideChange1}>
                    {this.state.hide1 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>

                <div className="input_field" Style={this.state.is_password_correct?"border:1px solid green":""}>
                   <input type={this.state.hide2 ? "password" : "text"}  placeholder={this.props.saveLanguageWiseData.confirm_password} name='confirm_password'
                    value={this.state.confirm_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeConfirmPassword}/>
                    <div className="togglePassword" onClick={this.hideChange2}>
                    {this.state.hide2 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                
                <div className="button-class">
                  <button onClick={() => this.changePassword(this.state.uname,this.state.password,this.state.new_password)} disabled={isEnabled ? "disabled":""}>
                  {this.props.saveLanguageWiseData.change_password}
                  </button>
                </div>
              </div>}
            </div>
           </div>
        </Popup>

				<div className="profile" Style="align-self:flex-start">
         {localStorage.getItem("is_faculty") &&  localStorage.getItem("is_faculty_admin") &&

          this.state.saveInstituteLanguages && this.state.saveInstituteLanguages !== 'undefined' && this.state.saveInstituteLanguages && JSON.parse(this.state.saveInstituteLanguages).length > 1 &&
          <div className="languageTranslator admin">
            <div className='block'>
            <label>{this.props.saveLanguageWiseData.choose_language}</label>
            <select onChange={this.languageChange} value={localStorage.getItem('lang')}>
              {this.state.saveInstituteLanguages.length && JSON.parse(this.state.saveInstituteLanguages).map((languageOption) => ( 
                <option value={languageOption.code} key={languageOption.code}>{languageOption.label}</option>
              ))}
              </select>
            </div>
          </div>


        // <div className="languageTranslator faculty">
        //     <div className='block'>
        //     <label>{this.props.saveLanguageWiseData.choose_language}</label>
        //       <select onChange={this.languageChange} value={localStorage.getItem('lang')}>
        //         <option value="en">English</option>
        //         <option value="ar">عربي</option>
        //         <option value="gu">ગુજરાતી</option>
        //         <option value="hi">हिंदी</option>
        //       </select>
        //     </div>
        //   </div>
            }
          <div ref={this.setMyRef}>
          {this.state.facultySidebarHidden_ids && 
            <div className="profile-option" onClick={this.dropchange2}>
              <span className='colorbg'><img src={Setting} alt="Setting"/></span>
            </div>
            }

            <div className="dropdown-content2-setting" style={dropstyle2}>
            <div className="drop-profile-setting">
              {/* <div className="settings" onClick={this.reportAnIssue}>
                <div Style="width:252px">Report a Problem</div><div><img src={Next} alt="Next"/></div>
              </div> */}
              <div className="settings">
                <div style={{width:'252px'}} onClick={this.show2}>{this.props.saveLanguageWiseData.edit_profile}</div><div><img src={Next} alt="Next"/></div>
              </div>
              <div className="settings" onClick={this.show4}>
                <div style={{width:'252px'}}>{this.props.saveLanguageWiseData.change_password}</div><div><img src={Next} alt="Next"/></div>
              </div>
              <div className="settings" onClick={this.show5}>
                <div style={{width:'252px'}}>{this.props.saveLanguageWiseData.share_app}</div><div><img src={Next} alt="Next"/></div>
              </div>
              {/* <div className="settings" onClick={this.termsAndCondition}>
                <div Style="width:252px">Terms and Condiion</div><div><img src={Next} alt="Next"/></div>
              </div> */}
              {/* <div className="settings" onClick={this.contactUs}>
                <div Style="width:252px">Contact Us</div><div><img src={Next} alt="Next"/></div>
              </div> */}
              <div className="settings">
                <div style={{width:'252px'}}onClick={this.props.userLogout}>{this.props.saveLanguageWiseData.logout}</div><div><img src={Next} alt="Next"/></div>
              </div>
            </div>
          </div>

          </div>
					{/*<div className="profile-option">
						<img src={Notification} alt="img"/>
					</div>*/}
          {!this.state.is_faculty_admin && this.state.facultySidebarHidden_ids && <div className="profile-option"><Link to={'/admin/study-material'} onClick={this.changeUrl} id={this.props.currentPage === '/admin/study-material' 
            || this.props.currentPage === '/admin/study-material' ? 'selected-sl': ''}>
            {this.props.saveLanguageWiseData.creation_workspace}
          </Link></div>}
          
          {this.state.is_faculty_admin && <div className="profile-option"><Link to={'/faculty'} onClick={this.changeUrl} id={this.props.currentPage === '/admin/study-material' 
            || this.props.currentPage === '/admin/study-material' ? 'selected-sl': ''}>
            {this.props.saveLanguageWiseData.analytics_workspace}
          </Link></div>}

          <div id="dropper" ref={this.setWrapperRef}>
            <div className="profile-option" onClick={this.dropchange}>
              {this.state.profile?.profile_picture === '' 
                || this.state.profile?.profile_picture === null
              ? <img src={Profile} alt="img"/>
              : <div style={{backgroundImage: `url(${common.addFileUrl(this.state.profile?.profile_picture)})`}}></div>}
              {this.state.facultySidebarHidden_ids && <span>{this.state?.profile?.name}</span> }
            </div>
            <div className="dropdown-content2" style={dropstyle}>
              <div className="drop-profile">
                {this.state.profile?.profile_picture === '' 
                  || this.state.profile?.profile_picture === null
                ? <img src={Profile} alt="img"/>
                : <div className="dpp-prof-img"
                  style={{backgroundImage: `url(${common.addFileUrl(this.state.profile?.profile_picture)})`}}></div>}
                <div className="drop-profile-creds">
                  <span>{this.state?.profile?.name}</span>
                  <div>
                    {this.state.profile?.subjects[0]?.label}
                  </div>
                 
                </div>
                <span id="edit-prof" className='teacherLogin' title="Edit profile" onClick={this.show2}>
                  <img src={editIcon} alt="edit" title='edit' />
                </span>
              </div>
              {(this.state.profile?.mobile?.length !== 0
                && this.state.profile?.mobile !== null) && <div className="prof-options-div">
                <span>{this.props.saveLanguageWiseData.mobile_no}</span>
                {this.state.profile?.mobile}
              </div>}
              {(this.state.profile?.email?.length !== 0
                && this.state.profile?.email !== null) && <div className="prof-options-div">
                <span>{this.props.saveLanguageWiseData.email_address}</span>
                {this.state.profile?.email}
              </div>}

              {(this.state.profile?.center?.length !== 0
                && this.state.profile?.center !== null) && <div className="prof-options-div">
                <span>{this.props.saveLanguageWiseData.center}</span>
                {this.state.profile?.center}
              </div>}
              <div className="click-prof" onClick={this.show4}>
              <i><img src={passwordKey}/></i>{this.props.saveLanguageWiseData.change_password}
              </div><br/>
              <div className="click-prof" onClick={this.props.userLogout}>
              <i><img src={logoutIcon}/></i>{this.props.saveLanguageWiseData.logout}
              </div>
            </div>
          </div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const { userLogout, getFacultyProfile,updateProfile ,changePassword,getLanguageWiseData} = actions;
  return {
    userLogout: () => dispatch(userLogout()),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    changePassword: (username,otp,password,language) => dispatch(changePassword(username,otp,password,language)),
    updateProfile: (username,email,mobile,photo,locale) => dispatch(updateProfile(username,email,mobile,photo,locale)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);