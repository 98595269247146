import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import Calendar2 from '../../public/icons/calendar.svg';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Listing from '../../public/icons/listing.svg';
import Calendar from '../../public/icons/calendar2.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import ImgHolder from '../../public/images/img-holder.svg';
import More from '../../public/icons/more.png';
import common from '../../common';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Pagination from "react-js-pagination";
import MultiSelect from "react-multi-select-component";


const alphabet = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N',
  15: 'O',
  16: 'P',
  17: 'Q',
  18: 'R',
  19: 'S',
  20: 'T',
  21: 'U',
  22: 'V',
  23: 'W',
}

const difficultyArray = {
  0: 'Easy',
  1: 'Medium',
  2: 'Hard',
  3: 'Very Hard'
}

class AdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      title:'',
      scheduleimage: null,
      scheduleimageURL:null,
      mode:'',
      question_schedule_date:'',
      question_due_date:'',
      type:'',
      showResult:false,
      classs:'',
      section:'',
      center:'',
      open: false,
      open2: false,
      open3: false,
      opend: false,
      class_id: null,
      question_id:true,
      filterClass: null,
      name: '',
      code: '',
      order: '',
      image: null,
      question_image:null,
      description: '',
      current_page:1,
      totalItems:0,
      branch: parseInt(localStorage.getItem('center_id')),
      branches: [],
      question_ids:[],
      questionsIds:[],
      center_id: localStorage.getItem('center_id'),
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      classListItems:[],
      centerListItems:[],
      sectionListItems:[],
      questions:[],
      classes2: [],
      subjects: [],
      sections: [],
      is_search:false,
      source: this.props.location.state?.source,
      assessment_section_id:this.props.location.state?.assessment_section_id,
      question:{widgetform_id:"",question_type:"",statement:"",explanation:"",options:[]},
      classesFilter: [],
      widget_form_id:'',
      sectionClass:null,
      errors:{},
      fields:{},
      formStruct:{
        form_type:"",
        form_description:"",
        form_title:"",
        success_message:""
      },
    }
  }
    
  componentDidMount() {
    this.formList();
    this.getUrlfromCurrentPage();     
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
  }

  formList = async(e) =>{
    const response = await this.props.formList();
    if(response !== undefined) {
      this.setState({ formList: response.data});
    }
  }

  show = () => {
    this.setState({ open: true, editq:false,center_id: localStorage.getItem('center_id'),
                              name: '', code: '', order: '', description: '', 
                              branch: parseInt(this.state.center_id) });

    /*this.setState({ question: {
      id: "",
      statement: "",
      options: "",
      type: ""
    }, question_image:"",image:"",marks: '', negative_marks:'',
       difficulty: "",duration_seconds:""})*/
  }
  
  close = () => this.setState({ open: false });

  show2 = () => this.setState({ open2: true });
  
  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  /*getQuestionsList = async (page) => {
    const response = await this.props.getQuestionsList(page);
    if(response !== undefined) {
      this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20});
    }
  }*/

  removeImage = () => {
    this.setState({ image: null, imageURL: null ,question_image:null});
    document.getElementById('img-input').value = "";
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }  

  checkEnablity = () => {
    let condition1 = this.state.question.statement?.length > 0 || this.state.image;

    let condition2;

    if(this.state.question.marks?.length !=undefined && this.state.question.duration_seconds?.length != undefined){
       condition2 = (this.state.marks && this.state.marks.length >= 0) && (this.state.duration_seconds && this.state.duration_seconds.length >= 0) && this.state.difficulty !== '';
    }else{
      if(this.state.marks !=="" && this.state.duration_seconds !==""){
        condition2 = true;
      }
    }

    //let condition3 = this.checkOptions();

    return condition1 && condition2;
  }

  checkEnablity2 = () => {
    let condition1 = this.state.question.statemen?.length > 0 || this.state.image;
    let condition2;
    if(this.state.marks?.length !=undefined){
       condition2 = (this.state.marks && this.state.marks?.length >= 0) && this.state.difficulty !== '';
      
    }else{
      
      if(this.state.marks !=="" && this.state.negative_marks !==""){
        condition2 = true;
      }
    }

    //let condition3 = this.checkOptions();
    return condition1 && condition2;
  }

  /*checkOptions() {
    if(this.state.question.type === 6) {
      return true;
    }
    else {
      let con1 = false;
      let con2 = true;
      let options = this.state.question?.options;
      for(let i = 0; i < options?.length; i++) {
        if(options[i].is_correct === true) {
          con1 = true;
        }
        if(isEmpty(options[i].statements[0].statement)) {
          con2 = false;
        }
      }
      return con1 && con2 && options.length > 0;
    }
  }  */

  onTypeChange = (e) => {
    var question = this.state.question;
    for(var i=0; i < question.options?.length ; i++) {
      question.options[i].is_correct = false;
      question.options[i].statement = '';
    }
    question.type = parseInt(e.target.value);
    if(parseInt(e.target.value) === 5) {
      question.options = [
        {
          is_correct: false,
          statements: [{
            language: 1,
            explanation: '',
            statement: 'True',
          }]
        },
        {
          is_correct: false,
          statements: [{
            language: 1,
            explanation: '',
            statement: 'False',
          }]
        }
      ]
    }else{
      question = {
        question_type: parseInt(e.target.value),
        statement: '',
        options: [],
        explanation:""
      }
    }
    this.setState({ question });
  }

  

  onChangeTime = (e) => {
    var duration_seconds = this.state.duration_seconds;

    duration_seconds = (e.target.validity.valid) ? e.target.value : this.state.duration_seconds;

    this.setState({ duration_seconds });
  }

  positiveChange = (e) => {
    var marks = this.state.marks;
    marks = (e.target.validity.valid) ? e.target.value : this.state.marks;
    this.setState({ marks });
  }

  negativeChange = (e) => {
    var negative_marks = this.state.negative_marks;
    negative_marks = (e.target.validity.valid) ? e.target.value : this.state.negative_marks;
    this.setState({ negative_marks });
  }

  updateSection = async () => {
    const { section_id, section_title, negative_marking_per_q, instructions } = this.state;
    await this.props.updateAssessmentSection(section_id, section_title, negative_marking_per_q, instructions);
  }

  addMarksPlus =() =>{
    var positive_marks = this.state.marks;
    if(positive_marks == "") positive_marks=0;
    positive_marks = parseInt(positive_marks) + 1;
    this.setState({ marks:positive_marks });
  }

  addMarksMinus =() =>{
    var positive_marks = this.state.marks;
    if(parseInt(positive_marks)>0){
      positive_marks = parseInt(positive_marks) - 1;
      this.setState({ marks:positive_marks });
    }
  }

  addNegativeMarks = () => {
    var negative_marks = this.state.negative_marks;
    if(negative_marks == "") negative_marks=0;
    negative_marks = parseInt(negative_marks) + 1;
    this.setState({ negative_marks});
  }

  addNegativeMarksm =() =>{
    var negative_marks = this.state.negative_marks;
    if(parseInt(negative_marks)>0){
      negative_marks = parseInt(negative_marks) - 1;
      this.setState({ negative_marks });
    }
  }

  minusTime = () => {
    var duration_seconds = this.state.duration_seconds;
    if(parseInt(this.state.duration_seconds) >= 5) {
      duration_seconds = parseInt(duration_seconds) - 5;
      this.setState({ duration_seconds });
    }
  }

  addTime = () => {
    var duration_seconds = this.state.duration_seconds;
    if(duration_seconds == "") duration_seconds=0;
    duration_seconds = parseInt(duration_seconds) + 5;
    this.setState({ duration_seconds });
  }

  addMarks =() =>{
    var positive_marks = this.state.marks;
    positive_marks = parseInt(positive_marks) + 1;
    this.setState({ marks:positive_marks });
  }

  difficultyChange = (e) => {
    this.setState({ difficulty: e.target.value });
  }

  

  checkTimeTyed = async (val,sectionId,question) => {
    console.log(question) ;

    let duration_seconds = 0;
    let duration_min = 0;

    this.state.sections.map((section,index1) => {
      section.questions.map((data,index) => {
        duration_seconds += data.question_data.duration_seconds;
      })
    })

    if(parseInt(this.state.duration_seconds) && val === 1) duration_seconds +=parseInt(this.state.duration_seconds);

    if(this.state.assessment.timed_type === 2){
      duration_min = Math.floor(duration_seconds / 60);
      //await this.props.editAssessmentBasic(this.state.assessment.id,this.state.assessment.uid, this.state.assessment.title, localStorage.getItem("center_id"), this.state.assessment.timed_type, duration_min);
    }
  }

  changeFormField = (e) =>{

    let {formStruct} = this.state

    let name = e.target.name
    let value = e.target.value

    formStruct[name] = value

    this.setState({formStruct})
  }


  createQuestion = async () => {

    const { id, question, marks, negative_marks, difficulty, duration_seconds } = this.state;
    
    //if(this.state.assessment.is_graded === true ) this.canCreate(parseInt(marks),1)
    //this.checkTimeTyed(1,this.state.id,question.id);

    if(question.type === 6) {
      await this.props.createQuestion(id, question.type, [{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}], 
                          [], parseInt(marks), negative_marks ? parseInt(negative_marks) :0, 
                         difficulty, parseInt(duration_seconds));
    }
    else {
      await this.props.createQuestion(id, question.type, [{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}], 
                          question.options, parseInt(marks), negative_marks ? parseInt(negative_marks) :0, 
                         difficulty, parseInt(duration_seconds));
    }
  }

  keyDown = (e, index) => {
    var question = this.state.question;
    var key = e.keyCode || e.charCode;
    if(this.state.question.type === 2) {
      if(key === 8 || key === 46) {
        question.options[index].statements[0].statement = '';
        this.setState({ question });
      }
    }
  }

  removeOption = (index) => {
    
    var question = this.state.question;
    if(this.state.question.options.length > 0) {
      question.options.splice(index, 1);
      this.setState({ question });
    }
  }

  show6 = async (e) => {
    //if(!e.target.value) document.getElementsByClassName("tab-content")[0].style.display="none";
    this.setState({text:e.target.value})
    if(e.target.value){
      const response = await this.props.questionSearch(e.target.value,1);
      if(response !== undefined) {
        this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,is_search:true});
      }
    }else{
      const response = await this.props.getQuestionsList(1);
      if(response !== undefined) {
        this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,is_search:false});
      }
    }
  }


  isCorrect = (index) => {
    var question = this.state.question;
    if(this.state.question.question_type !== 3) {
      for(var i=0; i < question.options.length ; i++) {
        question.options[i].is_correct = false;
      }
    }
    question.options[index].is_correct = !question.options[index].is_correct;

    console.log(question)

    this.setState({ question });
  }

  handleChange = async (event) => {

    if(event.target.files[0].type.split('/')[0]) {
      this.setState({ 
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })

      const response= await this.props.updateImage(event.target.files[0]);
      this.setState({image:response.data.image});
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_images_can_be_uploaded_here}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }
  handleChange1 = async (event,index) => {
    var question = this.state.question;
    console.log(event.target.files)
    if(event.target.files[0].type.split('/')[0] === 'image') {
      console.log("445354543")
      this.setState({ 
        image1: event.target.files[0],
        imageURL1: URL.createObjectURL(event.target.files[0])
      })

      const response= await this.props.updateImage(event.target.files[0]);
      
      this.setState({option_image:response.data.image});
      question.options[index].statements[0].image = (response.data.image) 
        ? response.data.image : question.options[index].statements[0].image;
      this.setState({ question });
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_images_can_be_uploaded_here}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  show4 = async(page) => {
    this.setState({ values:[] });
   
    if(this.state.is_search){
      const response = await this.props.questionSearch(this.state.text,page);
      if(response !== undefined) {
        this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,is_search:true});
      }
    }else{
      const response = await this.props.getQuestionsList(page);
      if(response !== undefined) {
        this.setState({ questions: response.data });
      }
    }
    
    this.setState({current_page:page});
  }

  moveOn = (page) =>{
    this.show4(page);
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.show4(pageNumber);
    this.setState({current_page: pageNumber});
  }

  editQuestion = async () => {
    let { id,question, marks,widget_form_id, negative_marks, difficulty, duration_seconds } = this.state;
    //if(this.state.assessment.is_graded === true ) this.canCreate(parseInt(marks),2)
    //this.checkTimeTyed(2,this.state.id,question.id);

    console.log(question)
    const response = this.props.widgetQuestionCreate(widget_form_id,question.question_type,question.statement,question.explanation,question.options,null)
    
    this.setState({open:false})
  }

  onStatementChange = (e) => {
    var question = this.state.question;
    question.statement = e.target.value;
    this.setState({ question });
  }

  onEditQuestion = async (val,index) => {
    let {question} = this.state
    question.question_type=''
    question.statement=''
    question.explanation=''
    question.options=[]
    
    this.setState({editq: true,open: true, titleq: `${this.props.pagewiseLanguageData.question} ${index}` ,widget_form_id:val.id});

  }

  addOption = () => {
    if(this.state.formList.length < 24) {
      var question = this.state.question;

      console.log(question)
      if(this.state.formList.length < 20) {
        var option = {
          is_correct: false,
          statement: '',
          image:null
        }
        question.options.push(option);
        this.setState({ question })
      }
    }
  }  

  closeQuestionBox = (val) => {
    /*var question_ob = this.state.question_ob;
    if(this.state.question_ob[val] === true) {
      question_ob[val] = false;
      this.setState({ question_ob: question_ob, editing: false })
    }*/

    this.setState({open : false})
  }

  schedule =(id) =>{
    this.setState({open3 : true,question_id:id})
  }

  close3 =() =>{
    this.setState({open3 : false })
  }

  getClassList = async () => {
    const {classListItems} = this.state;
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ),
        classes2: response.data.filter(classs => classs.center === parseInt(this.state.center_id)),
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });

      for(let i =0; i<response.data.length; i++){
        classListItems.push({value:response.data[i].id, checked:false,label:response.data[i].label})
      }
    }
  }

  getSectionList = async (classs) => {
    const {sectionListItems} =this.state
    if(classs === '') {
      this.setState({ sections: [] });
    }
    else {
      const response = await this.props.getSectionList2(this.state.branch, classs);
      if(response !== undefined) {
        this.setState({ sections: response.data });
      }

      for(let i =0; i<response.data.length; i++){
        sectionListItems.push({value:response.data[i].id, checked:false,label:response.data[i].label})
      }
    }
  }

  getCenterList = async () => {
    const {centerListItems} = this.state;
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
      for(let i =0; i<response.data.length; i++){
        centerListItems.push({value:response.data[i].id, checked:false,label:response.data[i].name})
      }
    }
  }

  titleChange = (e) => {
    this.setState({title: e.target.value})
  }

  changeScheduleImage = async (event) => {

    if(event.target.files[0].type.split('/')[0]) {
      this.setState({ 
        scheduleimage: event.target.files[0],
        scheduleimageURL: URL.createObjectURL(event.target.files[0])
      })

      //const response= await this.props.updateImage(event.target.files[0]);
      //this.setState({image:response.data.image});
    }
    else {
      this.setState({ scheduleimage: null, scheduleimageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_images_can_be_uploaded_here}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  reset = () =>{
    let {question_ids} = this.state

    question_ids.length = 0
    this.setState({question_ids})

    console.log(question_ids)
  }

  typeChange =(e) =>{
    this.setState({type:e.target.value})
  }

  modeChange = (e) =>{
    //console.log(this.state.class_centers)
    this.setState({mode:e.target.value})
  }

  showResult = (e) => {
    this.setState({showResult: !this.state.showResult})
  }

  onCenterChange =(e) =>{
    const {branches} = this.state
    const findIndex = branches.findIndex( p => parseInt(p.id) === parseInt(e.target.value) )
    
    if(branches[findIndex]?.checked === true){
      branches[findIndex].checked = false;
    }else{
      branches[findIndex].checked = true;
    }
    this.setState({branches,center:e.target.value})
  }

  scheduleQuestion = async () => {
    let dateObj= new Date();
    let selected;
    let selectedCenter=[];

    const {question_id,question_schedule_date,question_due_date,title,mode,type,showResult,scheduleimage,center,classs,section,branch} = this.state
    

    selected = mode == "institute" ? branch : mode == "class" ? classs.map(val => val.value) : mode == "section" ? section.map(val => val.value) :"";
 
    const response = await this.props.qodCreate(question_id,question_schedule_date,question_due_date,title,mode,type,showResult,dateObj.toISOString(),scheduleimage,selected)

    this.setState({open3: false})
  }

  onChangeTime1 = (e) => {
    this.setState({question_schedule_date : e.target.value})
  }
  onChangeTime2 = (e) => {
    this.setState({question_due_date : e.target.value})
  }

  onClassChange = (e) => {
    this.setState({sectionClass :e.target.value })
    this.getSectionList(e.target.value);
  }

  optionChange = (e, index) => {
    var question = this.state.question;
    if(this.state.question.type === 5) {
      e.preventDefault();
    }
    else {
      if(this.state.question.type === 2) {
        if(question.options[index].statements[0].statement.length < 2) {
          if(question.options[index].statements[0].statement.length === 1) {
            if(question.options[index].statements[0].statement === '-') {
              if(e.target.value === '--') {
                e.preventDefault();
              } 
              else {
                question.options[index].statements[0].statement = (e.target.validity.valid) 
                  ? e.target.value : question.options[index].statements[0].statement;
                question.options[index].statements[0].image = (this.state.option_image) 
                  ? this.state.option_image : question.options[index].statements[0].image;
                this.setState({ question });
              }
            }
            else {
              e.preventDefault();
            }
          }
          else {
            question.options[index].statements[0].statement = (e.target.validity.valid) 
              ? e.target.value : question.options[index].statements[0].statement;
            question.options[index].statements[0].image = (this.state.option_image) 
              ? this.state.option_image : question.options[index].statements[0].image ;
            this.setState({ question });
          }
        }
        else {
          e.preventDefault();
        }
      }
      else {
        question.options[index].statement = (e.target.validity.valid) 
          ? e.target.value : question.options[index].statement;
        /*question.options[index].image = (this.state.option_image) 
          ? this.state.option_image : question.options[index].statements[0].image ;*/
        this.setState({ question });
      }
    }
  }

  fileInput2 = (e,index) => {
    document.getElementById("img-input"+index).click();
  }

  changeIds = (val) => {

    let {question_ids} = this.state

    if(question_ids.indexOf(val) !== -1) {
      const index = question_ids.indexOf(val);
      question_ids.splice(index, 1);
      this.setState({question_ids})
    }
    else {
      question_ids.push(val);
      this.setState({question_ids})
    }

    console.log(question_ids)
  }


  mapSection = async () => {
    let questionsIds =[] ;
    let {question_ids} = this.state

    console.log(question_ids)
    for(let i=0 ; i<question_ids.length; i++){
      questionsIds.push({'question_id':question_ids[i]})
    }

    await this.props.questionSectionMapping(this.state.assessment_section_id,questionsIds)

    this.props.history.goBack()
  }

  removeImage1 = (index) => {
    var question = this.state.question;
    this.setState({ image1: null, imageURL1: null ,option_image:null});
    question.options[index].statements[0].image='';
    this.setState({ question });
    document.getElementById('img-input'+index).value = "";
  }

  on_refer_click = async (listid) =>{

    const {formList} = this.state 

    let widget_response = formList.filter(p => p.id == listid);
    console.log(widget_response)
    console.log(widget_response[0].widget_questions)
    this.setState({widget_form_data:widget_response[0]})

    this.setState({open_refer :true,onsave:false,selected_list_id:listid,fields:{}})

    /*let newdashboardnavigation = document.getElementsByClassName("new-dashboard-navigation")
    if(newdashboardnavigation) newdashboardnavigation[0].style.opacity =0.1

    let appheader = document.getElementsByClassName("app-header")
    if(appheader) appheader[0].style.opacity= 0.1*/
  }

  close_refer = () => {
    this.setState({open_refer : false})
  }

  addForm = () =>{
    this.setState({addForm:true,show:false})
  }

  closeAddForm = () =>{
    this.setState({addForm:false})
  }

  addNewForm = async() =>{
    const {formStruct} = this.state
    const response = await this.props.formCreate(formStruct['form_title'],formStruct['form_description'],formStruct['success_message'],formStruct['form_type'])
    
    if(response != undefined){
      this.setState({addForm:false,show:true})
    }
    window.location.reload()
  }

  render() {

    const {center,classs,section,fields,errors,widget_form_data} =this.state

    const isEnabled = this.state.title.length > 0 && this.state.question_schedule_date && this.state.question_due_date && this.state.mode;

    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder={this.props.pagewiseLanguageData.search_classes}/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.questions}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div widget-dashboard1">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.questions}</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder="Search classes"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="question-bank-dialog popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_question}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="dialog-content">
            {/* Creating question */}
            <div className="subunit-data2">
                  <div className="edit-question">
                    <div className="edit-ques-head">
                      <select value={this.state.question?.question_type} 
                        onChange={this.onTypeChange}>
                        <option value={''} disabled>{this.props.pagewiseLanguageData.question_type}</option>
                        <option value={1}>{this.props.pagewiseLanguageData.mcq}</option>
                        <option value={6}>{this.props.pagewiseLanguageData.subjective}</option>
                        {/*<option value={5}>True or False</option>
                        <option value={3}>Multiple Response</option>
                        <option value={2}>Single Integer</option>
                        <option value={4}>Fill in the blanks</option>
                        <option value={6}>Subjective</option>
                        <option value={7}>Matching</option>*/}
                      </select>
                    </div>
                    
                    <div className="ques-statement">
                      <textarea rows={3} placeholder={this.props.pagewiseLanguageData.question_statement} value={this.state.question?.statement}
                        onChange={this.onStatementChange}/>
                    </div>
                    
                    {this.state.question?.options && this.state.question?.options.map((option, index) =>
                          <div className="ques-option" key={index}>
                            <span onClick={() => this.isCorrect(index)}
                              id={option.is_correct === true ? this.props.pagewiseLanguageData.is_correct : ""}>
                              {alphabet[index + 1]}
                            </span>
                            <input value={option?.statement} placeholder={`${this.props.pagewiseLanguageData.option} ${index + 1}`}
                              onInput={(e) => this.optionChange(e, index)} onKeyDown={(e) => this.keyDown(e, index)}
                              pattern={this.state.question?.type === 2 ? "[0-9-]*" : null}/>
                            {this.state.question?.type !== 5 
                              ? <> {!this.state.editing && <span onClick={() => this.removeOption(index)} id="remove-opt">
                              &#10005;
                            </span>} </>: null}
                          </div>
                        )}
                    {this.state.question?.question_type !=6 && <div className="ques-options">
                      <button onClick={this.addOption}>{this.props.pagewiseLanguageData.add_option}</button>
                    </div>}
                    {/*<div className="ques-save">
                      <div>
                        <button onClick={() => this.closeQuestionBox(1)}
                          id="button-reverse">
                          Cancel
                        </button>
                        <button onClick={this.createQuestion}
                          disabled={!this.checkEnablity()}>
                          Save
                        </button>
                      </div>
                    </div>*/}
                  </div>
                </div>            
            {/* question creation ends */}
          </div>
          <div className="popup-actions">
              <button onClick={() => this.closeQuestionBox(1)}
                id="button-reverse" Style="margin-right: 9px;">
                {this.props.pagewiseLanguageData.cancel}
              </button>
              <button onClick={this.editQuestion}>
                {this.props.pagewiseLanguageData.add_new}
              </button>
            {/*<button disabled={!isEnabled} onClick={this.newClass}>Add new</button>*/}
          </div>
        </Popup>

        <Popup open={this.state.open_refer} closeOnDocumentClick onClose={this.close_refer} className="referralDialog">
          <div className="design-block referralDialogWrapper">
            <span className="close" onClick={this.close_refer}>&#x2715;</span>
            <div className="shape1"></div>
            <div className="shape2"></div>
            <div className="leftsection">
            <div className="headingtitle">{this.props.pagewiseLanguageData.edit_form} </div>
            <ol>
              {widget_form_data?.widget_questions?.length >0 && widget_form_data.widget_questions.map((item,id) => 
                <li key={id}>
                    <div className="question-title">{item.statement}</div>
                    <div className="labelblock">
                    {item.options.length ? item.options.map((option,index) => 
                       <label> <input
                        name={option.id}
                        type="checkbox"
                        onChange={(e) =>this.handleInputChange(e,item.id,index)} /><span>{option.statement}</span></label>
                    ) : <div className="labelinputblock"></div>}
                       </div> 
                </li>
              )}   
            </ol>
            <div className="question-action">
              <button  disabled={this.state.isEnabled == false}  onClick={()=>this.postAnswers()}>{this.props.pagewiseLanguageData.save}</button>
            </div>
            </div>
            <div className="rightsection"></div>
          </div>
        </Popup>

        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.solution_of_question}</span>
            <textarea value={this.state.explanation} onChange={this.onExplanationChange}
              placeholder={this.props.pagewiseLanguageData.enter_solution}/>
            <div>
              <button onClick={this.close2}>
                {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.addForm} closeOnDocumentClick onClose={this.closeAddForm} className="row-type">
          <div className="add-row">
            <span className="close" onClick={this.closeAddForm}>&#x2715;</span>
            <div className="sub-div-01">
              <span>{this.props.pagewiseLanguageData.add_form}</span>
              <hr/>
              <div className="row">
                <div className="col-25">
                  <label for="fname">{this.props.pagewiseLanguageData.title}</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['form_title']} onChange={this.changeFormField} name="form_title"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">{this.props.pagewiseLanguageData.description}</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['form_description']} onChange={this.changeFormField} name="form_description"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">{this.props.pagewiseLanguageData.type}</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['form_type']} onChange={this.changeFormField} name="form_type"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">{this.props.pagewiseLanguageData.message}</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['success_message']} onChange={this.changeFormField} name="success_message"/>
                </div>
              </div>
            </div>
            <div id="type-button" className="dialogactions">
              <button className="widget-button" onClick={this.addNewForm}>{this.props.pagewiseLanguageData.add}</button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteClass}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1-ques">
              <div className="search-head">
                {/*<img src={Search} alt="search"/>
                <input placeholder={this.props.placeholder}/>*/}
                <div className="search-wrapper-admin">
                  {/*<div className="search-container">
                    <input type="search" placeholder="Search Questions.." onChange = {this.show6} onFocus={this.handleUsernameFocus}/>
                  </div>*/}
                </div>
              </div>
            </div>
            <div className="heading-part2">
              {!this.state.question_ids?.length ? <><span onClick={this.addForm}>
                <img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
              </span>
              {/*<Link to={{ pathname: '/admin/scheduled-questions' }}>
                <img src={Calendar2} alt="img"/>Scheduled Questions
              </Link>*/}</>:""}

              {this.state.question_ids?.length ? <><span onClick={this.mapSection}>
                {this.props.pagewiseLanguageData.map_questions}
              </span>
              <span onClick={this.reset}>
                {this.props.pagewiseLanguageData.reset}
              </span></> :""}

              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt={this.props.pagewiseLanguageData.list_view}/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt={this.props.pagewiseLanguageData.grid_view}/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
            </div>
          </div>
          
          {this.state.formList?.length === 0 ? <Empty/> : <>
            {this.state.list ? <table className="questionbank-table">
              <tr>
                <th>{this.props.pagewiseLanguageData.title}</th>
                <th>{this.props.pagewiseLanguageData.description}</th>
                <th>{this.props.pagewiseLanguageData.type}</th>
              </tr>
              {this.state.formList?.map((question, index) =>
                <tr key={question.id}>

                  
                  <td>
                    {question.title}
                  </td>
                  
                  <td>
                    {question.description}
                  </td>
                  <td>
                    {question.type}
                  </td>                  
                  <td>
                    <span title="Edit" onClick={() => this.onEditQuestion(question, index + 1)}>
                      <img src={Edit} height="20" alt={this.props.pagewiseLanguageData.edit}/>
                    </span>
                    {/*<span title="Delete" onClick={() => this.showd(question.id)}>
                      <img src={Delete} height="20" alt="delete"/>
                    </span>*/}
                    {/*<span title="Schedule" onClick={() => this.schedule(question.question_id)}>
                      <img src={Calendar} height="20" alt="schedule"/>
                    </span>*/}
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) !== -1 && <input type="checkbox" defaultChecked={true} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) === -1 && <input type="checkbox" defaultChecked={false} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}
                  </td>
                </tr>
              )}
            </table> : <div className="the-grid">
              {this.state.formList?.length > 0 && this.state.formList.map((question, index) =>
                <div className="grid-item" key={question.question_id}>
                  <div className="grid-up">
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) !== -1 && <input type="checkbox" defaultChecked={true} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) === -1 && <input type="checkbox" defaultChecked={false} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}
                    
                    <div>
                      <div>{question.title}</div>
                    </div>
                    <div className="grid-actions">
                      <span title={this.props.pagewiseLanguageData.add_question} onClick={() => this.onEditQuestion(question, index + 1)}>
                        <img src={More} alt={this.props.pagewiseLanguageData.add_question}/>
                      </span>
                      <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.on_refer_click(question.id)}>
                        <img src={Edit} height="20" alt={this.props.pagewiseLanguageData.edit}/>
                      </span>
                      {/*<span title="Delete" onClick={() => this.showd(question.question_id)}>
                        <img src={Delete} alt="delete"/>
                      </span>*/}
                      {/*<span title="Schedule" onClick={() => this.schedule(question.question_id)}>
                        <img src={Calendar} alt="schedule"/>
                      </span>*/}
                    </div>
                  </div>
                  <div className="grid-up">
                    <div className="grid-sec">
                    {this.props.pagewiseLanguageData.type}
                      <span>{question.type}</span>
                    </div>
                    <div className="grid-sec2">
                      
                    </div>
                  </div>
                </div>
              )}
            </div>}  
          </>}
        </div>
        <Pagination
          activePage={this.state.current_page}
          itemsCountPerPage={20}
          totalItemsCount={this.state.totalItems}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { formList,formCreate,widgetQuestionCreate,getWidgetDataById,getPageLanguageWiseData} = actions;
  return {
    formCreate: (title,description,success_message,type) =>dispatch(formCreate(title,description,success_message,type)),
    formList : () => dispatch(formList()),
    getWidgetDataById : (id) => dispatch(getWidgetDataById(id)),
    widgetQuestionCreate:(widgetform_id,question_type,statement,explanation,options,image) =>dispatch(widgetQuestionCreate(widgetform_id,question_type,statement,explanation,options,image)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm);