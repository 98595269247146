import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import Edit from '../../public/icons/edit.svg';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import DifficultyMeter from '../../public/icons/difficulty-meter.svg';
import Correct from '../../public/icons/correct.svg';
import Attempted from '../../public/icons/attempted.svg';
import AvgTime from '../../public/icons/avg-time.svg';
import Score from '../../public/icons/score.svg';
import Pin from '../../public/icons/pin.svg';
import VideoPlayer2 from '../../components/players/video/VideoPlayer2';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import Collapse from '../../public/icons/collapse.svg';
import Zoom from '../../public/icons/full-screen.svg';
import download from '../../public/icons/down-arrow.svg';
import './styles.css';

const ques_types = {
  1: 'MCQ',
  2: 'Single Integer',
  3: 'Multiple Response',
  4: 'Fill in the blanks',
  5: 'True or False',
  6: 'Subjective',
  7: 'Matching'
}

// const difficultyLevel = {
//   0: 'Easy',
//   1: 'Medium',
//   2: 'Hard',
//   3: 'Very Hard'
// }

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 10,
    slidesToSlide: 10
  },
  tablet: {
    breakpoint: { max: 720, min: 590 },
    items: 10,
    slidesToSlide: 10
  },
};

const SlideButtons = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const { carouselState: { totalItems } } = rest;
  const lastSlide = Math.floor(totalItems / 4) + (totalItems % 4) - 1;
  if(totalItems <= 10) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="slide-buttons-xy">
      <img src={Left} className='slide-button-xy' onClick={() => previous()} alt="img"/>
      <img src={Right} className='slide-button-xy' onClick={() => next()} alt="img"/>
    </div>
  );
};

class FacultyReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      batchId: this.props.location?.state?.batch,
      is_subject: this.props.location?.state?.is_subject,
      class_id: this.props.location?.state?.class_id,
      id: this.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      no_ques: false,
      showSolution: false,
      sections: [],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      options: [],
      batches:[],
      batch:"",
      open2: false,
      is_subjective:0,
      content_id:0,
      question_id:0,
      section_id:0,
      student_list:[],
      total_page:0,
      current_page:1,
      editing:false,
      open5:false,
      open6:false,
      selected_image:null,
      subjective_answer:'',
      status:"",
      values:[],
      disable:[],
      selected_image_on:null,
      file_extension:"",
      difficultyLevel:[this.props.saveLanguageWiseData.easy,this.props.saveLanguageWiseData.medium,this.props.saveLanguageWiseData.hard,this.props.saveLanguageWiseData.very_hard]
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    // this.renderMath()
    this.getFacultyProfile();
    this.getReviewData();

    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"faculty");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      this.setState({difficultyLevel:[this.props.saveLanguageWiseData.easy, this.props.saveLanguageWiseData.medium,this.props.saveLanguageWiseData.hard, this.props.saveLanguageWiseData.very_hard]});
      
    }
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({batches: response.data.batches.filter((item) => { return item.classs.id == this.state.class_id}),batchId:this.state.batchId});
    console.log("batches");
    console.log(this.state.batches);
    if(localStorage.getItem("batchId")){
      this.setState({batchId:localStorage.getItem("batchId")})
    }
    //this.getBatchName(response.data.batches);
  }
  // componentDidUpdate() {
  //   this.renderMath()
  // }

  // renderMath() {
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node2.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node3.current
  //   ])
  // }

  show4 = async(page) => {
    this.setState({ values:[] });
    let {id}= this.props.match.params;
    const {content_id,question_id,section_id,currentQuestion} = this.state;
    const response= await this.props.studentQuestionResponse(parseInt(id),question_id?question_id:currentQuestion.id,this.state.batchId,page);
    //const page=1
    this.setState({student_list:response?.data?.data,total_page:response?.data?.pagination.total_page})
    console.log(response.data.data.students);
    this.setState({ open2: true,current_page:page});
  }

  show6 = (answer) =>{
    if(answer)
      this.setState({open6:true,open2:false,subjective_answer:answer});
  }

  close6 =() =>{
    this.setState({ open6: false,open2:true});
  }

  close2 = () => this.setState({ open2: false ,editing:false});
  close5 = () => this.setState({ open5: false ,selected_image:null});

  getReviewData = async () => {
    const { id } = this.props.match.params;
    const response =  await this.props.getAssessmentFacultyReviewData(id, localStorage.getItem("batchId") ?localStorage.getItem("batchId"):this.state.batchId)
    if(response !== undefined){
      response.data.sections.map(section => {
        section.questions.map((question, index) => {
          question.question_number = "Q"+(index+1);
        })
      })
      this.setState({ title: response.data.title, code: response.data.uid, 
        sections: response.data.sections, 
        question: response.data.sections[0]?.questions,
        options: response.data.sections[0]?.questions[0]?.options,
        section_title: response.data.sections[0]?.title, exerciseAttempt: response.data,
        question_title: response.data.sections[0]?.questions[0]?.title });
      this.setCurrentSectionQ(response.data.sections);
    }
    else {
      this.setState({ no_ques: true, loading: false })
    }
  }

  setCurrentSectionQ = (arr) => {
    var cng = 0;
    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    for(let i = 0; i < arr.length; i ++) {
      if(arr[i].questions.length > 0) {
        this.setState({ currentSection: arr[i], currentQuestion: arr[i]?.questions[0] });
        cng = 1;
        if(arr[i]?.questions[0].image){
          
          if(blockedTile.indexOf(arr[i]?.questions[0].image.split('.').pop()) != -1)
          {  
            this.setState({file_extension:1,selected_image_on:arr[i]?.questions[0].image.split('/').pop()}); //1 is used for image file
          }else{
            this.setState({file_extension:2,selected_image_on:arr[i]?.questions[0].image.split('/').pop()}); //2 is used for content file
          }
        }

        this.setState({is_subjective:arr[i]?.questions[0].question_type_id});
        break;
      }
    }
    if(cng === 0) {
      this.setState({ no_ques: true })
    }
    this.setState({ loading: false })
  }

  changeCurrentQuestion = async (sectionId, quesionId) => {
    this.moveToNext(sectionId, quesionId);
  }

  onBatchChange = (e) =>{
    //const contentId=this.props.match.params;
    this.setState({ loading: true })
    localStorage.setItem("batchId",e.target.value);
    window.location.reload();
  }

  moveToNext = (sectionId, questionId) => {
    let {id}= this.props.match.params;
    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    let { currentQuestion, currentSection, exerciseAttempt } = this.state;
    
    console.log(currentQuestion)
    if(currentSection.id === sectionId){
      const questionIndex = currentSection.questions.findIndex(p => p.question === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    } 
    else {
      const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === sectionId)
      currentSection = exerciseAttempt.sections[sectionIndex]
      const questionIndex = currentSection.questions.findIndex(p => p.question === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    }

    let index=currentSection.questions.findIndex(p => p.question === questionId);

    if(currentSection?.questions[index]?.image){
      //console.log(currentSection.questions[index].image.split('/').pop())
      if(blockedTile.indexOf(currentSection.questions[index].image.split('.').pop()) != -1)
      {  
        this.setState({file_extension:1,selected_image_on:currentSection.questions[index].image.split('/').pop()}); //1 is used for image file
      }else{
        this.setState({file_extension:2,selected_image_on:currentSection.questions[index].image.split('/').pop()}); //2 is used for content file
      }
      console.log(currentSection.questions[index].image.split('.').pop())
    }

    this.setState({
      currentQuestion,
      currentSection,
      showSolution: false,
      is_subjective:currentSection?.questions[index]?.question_type_id,
      content_id:id,
      question_id:currentSection?.questions[index]?.id,
      section_id:sectionId
    })
  }

  previousSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== 0) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex - 1].id, exerciseAttempt.sections[sectionIndex - 1]?.questions[0]?.question);
    }
  }

  nextSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== exerciseAttempt.sections.length-1) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1]?.questions[0]?.question);
    }
  }

  previous = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions.findIndex(p => p.question === currentQuestion.question)
    if(questionIndex === 0) {
      if(sectionIndex !== 0) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex-1].id, exerciseAttempt.sections[sectionIndex-1].questions[exerciseAttempt.sections[sectionIndex-1].questions.length-1].question)
      }
    } 
    else {
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex - 1].question)
    }
  }

  next = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions
                          .findIndex(p => p.question === currentQuestion.question)
    if(questionIndex === currentSection.questions.length - 1) {
      if(sectionIndex !== exerciseAttempt.sections.length-1) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1].questions[0].question)
      }
    } else{
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex+1].question)
    }
  }

  addMarks = async (e,attempt_id,index) => {
    const {currentQuestion} = this.state;

    if(isNaN(e.target.value)) return true;

    
    this.setState({
      values: {[index]: e.target.value },
      disable:{[index]: false }
    });

    if(e.target.value > this.state.student_list.marks){
      store.addNotification({
        title: 'Error',
        message: `${this.props.saveLanguageWiseData.question_marks_is} ${this.state.student_list.marks}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000,
        }
      })

      this.setState({
        values: {[index]: e.target.value },
        disable:{[index]: true }
      });
      return true;
    }
  }

  addMarks1 = async (e,attempt_id,index) => {
    const {currentQuestion} = this.state;
    const marks = this.state.values[index];

    console.log(currentQuestion)
    if(marks){
      console.log(currentQuestion.id);
      
      let question =[{question_id:this.state.question_id?this.state.question_id:currentQuestion.id,marks:marks}];
      const response1 = await this.props.submitMarks(attempt_id,question);

      let {id}= this.props.match.params;
      const {content_id,question_id,section_id} = this.state;
      const response= await this.props.studentQuestionResponse(parseInt(id),question_id?question_id:currentQuestion.id,this.state.batchId,this.state.current_page);
      this.setState({student_list:response?.data?.data,total_page:response?.data?.pagination.total_page})
      //console.log(response.data.data.students);
      this.setState({status:response1.status});
    }
  }

  moveOn = (page) =>{
    this.show4(page);
  }

  zoomOn = (url) => {
    this.setState({open5:true,selected_image:url})
  }


  paginationLink =() =>{
    const total_page = this.state.total_page;
    let currentPage=this.state.current_page;
    
    console.log(this.state.current_page)
    console.log(this.state.total_page)

    if(total_page > 1){
      let pages =[];

      pages.push(<a onClick={() => this.moveOn(currentPage-1)} Style={this.state.current_page <= 1 ? "pointer-events: none;cursor: default;":""}>&laquo;</a>);

      for(let i=0 ; i < total_page; i++){
        pages.push(<a onClick={() => this.show4(i+1)} class={this.state.current_page == i+1?"active":""}>{i+1}</a>);
      }

      pages.push(<a onClick={() => this.moveOn(currentPage+1)} Style={this.state.current_page >= total_page ?"pointer-events: none;cursor: default;":""}>&raquo;</a>);
      return pages;
    }
  }

  show = () => {
    this.setState({editing:true});
  }

  render() {
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      showSolution,
      loading
    } = this.state;


    if(this.props.loading || loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    else if(exerciseAttempt === '' || exerciseAttempt === null || exerciseAttempt === undefined
        || this.state.no_ques === true || exerciseAttempt.sections.length === 0) {
      return (
        <div className="assessment-div">
          <span id="no-questions">{this.props.saveLanguageWiseData.no_questions}</span>
        </div>
      )
    }
    else {
      return (
        
        <div>
          <Helmet>
            <title>{`${this.props.saveLanguageWiseData.review} - ${this.state.title}`}</title>
          </Helmet>
          <Popup open={this.state.open6} closeOnDocumentClick onClose={this.close6} className="full-answer-content">
            <div className="zoom-image">
              {this.state.subjective_answer}
            </div>
          </Popup>
          <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="zoom-image-on">
            <div className="zoom-image">
              <img src={this.state.selected_image} />
            </div>
          </Popup>
          <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2} className="change-password-list">
         
            <ReactNotifications/>
            <div className="assessment-bottom-on">
              <div className="assessment-bottom-left">
                {!isEmpty(this.state.currentQuestion.analytics) && <div className="dpp-details">
                  <div className="dpp-difficulty">
                    <span>
                      <img src={DifficultyMeter} alt="img"/>
                      <img className={`${this.state.currentQuestion.analytics.difficulty_level}-pin`} src={Pin} alt="img"/>
                    </span>
                    <div className="dpp-difficult">
                      {this.props.saveLanguageWiseData.difficulty}:<p>{this.state.difficultyLevel[this.state?.student_list?.difficulty]}</p>
                    </div>
                  </div>
                  {this.state.currentQuestion.question_type_id != 6 && <div className="dpp_average" Style={this.state.is_subjective == 6?"display:none":""}>
                    <span className="dpp_avg_icon">
                      <img src={Correct} alt="img"/>
                    </span>
                    <div className="dpp_avg_numbers">
                      <span>{this.state.student_list.answered_correctly}</span>
                      {this.props.saveLanguageWiseData.answered_this_correctly}
                    </div>
                  </div>}
                  <div className="dpp_average">
                    <span className="dpp_avg_icon">
                      <img src={Attempted} alt="img"/>
                    </span>
                    <div className="dpp_avg_numbers">
                      <span>{Math.round(this.state.student_list.avg_attempts * 10) / 10}</span>
                      {this.props.saveLanguageWiseData.avg_attempts}
                    </div>
                  </div>
                  <div className="dpp_average">
                    <span className="dpp_avg_icon">
                      <img src={AvgTime} alt="img"/>
                    </span>
                    <div className="dpp_avg_numbers">
                      <span>{this.state.student_list.avg_time}s</span>
                      {this.props.saveLanguageWiseData.avg_time_taken}
                    </div>
                  </div>
                  <div className="dpp_average">
                    <span className="dpp_avg_icon">
                      <img src={Score} alt="img"/>
                    </span>
                    <div className="dpp_avg_numbers">
                      <span>{Math.round(this.state.student_list.avg_score * 10) / 10}</span>
                      {this.props.saveLanguageWiseData.avg_score}
                    </div>
                  </div>
                </div>}
              </div>
              <div className="assessment-bottom-right">
                <span className="close" onClick={this.close2}>&#x2715;</span>
              </div>
            </div>
            
            <div Style="margin: 10px; margin-top:30px">
              <div className="header-view">
                <div>{this.props.saveLanguageWiseData.sr_no}</div>
                <div>{this.props.saveLanguageWiseData.name}</div>
                <div>{this.props.saveLanguageWiseData.answer}</div>
                <div>{this.props.saveLanguageWiseData.result}</div>
                <div>{this.props.saveLanguageWiseData.attempts}</div>
                <div>{this.props.saveLanguageWiseData.file}</div>
                <div>{this.props.saveLanguageWiseData.time}</div>
                <div>{this.props.saveLanguageWiseData.rank}</div>
                <div>{this.props.saveLanguageWiseData.marks}</div>
              </div>
             
              <div className="row-div">
                {this.state.student_list.students && this.state.student_list.students.map((list,index)=>{
                  return (<div className="row-view" Style={!list.attempts ? "display:none":""}>
                    <div>{index}</div>
                    <div>{list.name}</div>
                    <div onClick={()=>this.show6(list.Answer)} Style={"overflow:hidden"}>
                     {list.Answer}
                    {/*<span class="tooltiptext">{list.Answer}</span>*/}
                    </div>
                   
                    <div>{list.result}</div>
                    <div>{list.attempts}</div>
                    <div>
                      {list.users_file && <a href={common.addFileUrl(list.users_file)} target="_blank" rel="" download>
                        <i className="fas fa-download"/>
                        {this.props.saveLanguageWiseData.download_file}
                      </a>}
                    </div>
                    <div>{list.Time}s</div>
                    <div>{this.rank}</div>
                    {(!list.Score || this.state.editing || list.Score == null) && this.state.is_subjective == 6 && list.result !='Skipped' && list.result !='Not visited' ? <div Style="display:flex"><input type="text" pattern="[0-9]*" name="marks" value={this.state.values[index]} onChange={(e) => this.addMarks(e,list.attempt_id,index)}/><span class='right' Style={this.state.disable[index]?"pointer-events:none":""}><button onClick={(e) => this.addMarks1(e,list.attempt_id,index)}> &#10004; </button></span></div> :
                    <div><input disabled type="text" name="marks" value={list.Score} onChange={(e) => this.addMarks(e,list.attempt_id)} /><span onClick={() => this.show()} Style={this.state.is_subjective != 6 ?"display:none":(list.result =='Skipped' || list.result =='Not visited')?"display:none":""}>
                        <img src={Edit} alt="edit"/>
                      </span></div>}
                  </div>)}
                 )}
              </div>
              <div class="pagination">
                {this.paginationLink()}
              </div>
            </div>
          </Popup>

          <div className="assessment-head">
            <div className="assessment-head1">
              <span>{this.state.title}</span>
            </div>
            <div className="assessment-head2">
              <div className="assessment-section-buttons">
                <img src={MoveLeft} alt="img" onClick={this.previousSection}/>
                {currentSection.title}
                <img src={MoveRight} alt="img" onClick={this.nextSection}/>
              </div>
              <Carousel containerClass="section-layer-tray" responsive={responsive}
                keyBoardControl={false} arrows={false} swipeable={false} draggable={false}
                customButtonGroup={<SlideButtons/>} renderButtonGroupOutside={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}>
                {currentSection.questions !== undefined 
                  && currentSection.questions.map((question, index) => 
                  <div className="sec-ques-div" id={currentQuestion.question === question.question
                    ? 'current-ques' : ''} key={question.question}
                    onClick={()=>this.changeCurrentQuestion(currentSection.id, question.question)}>
                    {index !== 0 && <hr className="sec-line-one"/>}
                    {index + 1}
                    {(index !== (currentSection.questions.length - 1)) && <hr className="sec-line-two"/>}
                  </div>
                )}
              </Carousel>
            </div>
            <div className="assessment-head4">
              {this.state.is_subject && <select value={this.state.batchId} onChange={this.onBatchChange}>
                <option value={''}>
                  {this.props.saveLanguageWiseData.select_batch}
                </option>
                {this.state.batches.map((batch, index) =>
                  <option value={batch.id} selected={batch.id == this.state.batchId?"selected":""} key={index}>
                    {batch.label}
                  </option>
                )}
              </select>}
              <Link to={{pathname: `/faculty/scorecard/${this.state.id}`,
                state: {batchId: this.state.batchId, contentId: this.state.id}}}>
                <button>
                  {this.props.saveLanguageWiseData.scorecard}
                </button>
              </Link>
              <button id="button-reverse" onClick={() => {
                localStorage.setItem("batchId","");
                this.props.history.goBack()
              }
              }>
                {this.props.saveLanguageWiseData.exit_review}
              </button>

            </div>
          </div>
          {currentSection.questions.length > 0 ? <div className="assessment-main">
            <div className="assessment-ques">
              <div className="subject">{currentSection.title}</div>
              <div className="time-remaining">
                <span className="question_no">{currentQuestion?.question_number}</span>
                <div className="ques_type_prev">
                  {ques_types[currentQuestion?.question_type]}
                </div>
              </div>
              <div className="questionImage">
                <p ref={this.node} dangerouslySetInnerHTML={{__html: currentQuestion.statement}} />
                
                {currentQuestion.question_type_id == 6 ? <> { this.state.file_extension ==2 ? <div className="download-file-attempt" Style={!currentQuestion.image?"display:none":""}><a href={common.addFileUrl(currentQuestion.image)} target="_blank" rel="" download>
                          {/* <i className="fas fa-download"/> */}
                          <img src={download} alt="download"/> {this.props.saveLanguageWiseData.download} {this.state.selected_image_on}
                          </a></div> :<span onClick={()=>this.zoomOn(common.addFileUrl(currentQuestion.image))} Style={!currentQuestion.image?"display:none":""}><img src={common.addFileUrl(currentQuestion.image)} /></span>} </>:
                <span onClick={()=>this.zoomOn(common.addFileUrl(currentQuestion.image))} Style={!currentQuestion.image?"display:none":""}><img src={common.addFileUrl(currentQuestion.image)} /></span>}
              </div>
              
            </div>
            <div className="assessment-options">
              {currentQuestion?.question_type_id == 6 ? <div><div className="subjective">
                {this.props.saveLanguageWiseData.solution} :
              </div><div className="subjective_sol">
                {currentQuestion?.explanation}
               
              </div></div> : <>
                {currentQuestion?.options &&
                  currentQuestion?.options.map((option, index) =>
                  <div className="option-container" key={`option_${index}`}>
                    <div ref={this.node2} key={`option_${index}`}
                      className={ option.is_selected  ? "incorrect-option select-option" : "select-option"}
                      style={option.is_correct ? {borderColor: 'var(--primary)'} : {}}
                      dangerouslySetInnerHTML={{__html: option?.statement}}
                    />
                    
                    {option.image && <><span className="option-image" onClick={()=>this.zoomOn(common.addFileUrl(option.image))}><img Style={option.is_correct ? "border: 1px solid #66bb6a" : ""}  src={common.addFileUrl(option.image)}/><span>{this.props.saveLanguageWiseData.click_image_zoom}</span></span>
                                </>}     
                  </div>
                )}
              </>}
              {currentQuestion.solution && currentQuestion.solution.video_file !== undefined
                && !showSolution && <button onClick={()=>this.setState({showSolution: true})} 
                className="see-solution">{this.props.saveLanguageWiseData.see_solution}</button>
              }
              {showSolution &&
                <div className="solution-content">
                  {currentQuestion.solution.explanation &&
                    <>
                      <span>{this.props.saveLanguageWiseData.solution}: </span><span ref={this.node3} dangerouslySetInnerHTML={{__html: currentQuestion.solution.explanation}} />
                    </>
                  }
                  {currentQuestion.solution.video_file &&
                    <VideoPlayer2 url={common.addFileUrl(currentQuestion?.solution?.video_file)}/>
                  }
                </div>
              }
            </div>
          </div> : <span id="no-questions">{this.props.saveLanguageWiseData.no_questions}</span>}
          
          <div className="toggleImage" onClick={() => this.show4(1)}><img src={Collapse} alt="exp"/></div>
          <div className="assessment-bottom2">
            <div className="assessment-bottom-left">
              {!isEmpty(this.state.currentQuestion.analytics) && <div className="dpp-details">
                <div className="dpp-difficulty">
                  <span>
                    <img src={DifficultyMeter} alt="img"/>
                    <img className={`${this.state.currentQuestion.analytics.difficulty_level}-pin`} src={Pin} alt="img"/>
                  </span>
                  <div className="dpp-difficult">
                    {this.props.saveLanguageWiseData.difficulty}:<p>{this.state.currentQuestion.analytics.difficulty_level}</p>
                  </div>
                </div>
                {this.state.currentQuestion.question_type_id != 6 && <div className="dpp_average" Style={this.state.is_subjective == 6?"display:none":""}>
                  <span className="dpp_avg_icon">
                    <img src={Correct} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{this.state.currentQuestion.analytics.batch_correct_percentage}%</span>
                    {this.props.saveLanguageWiseData.answered_this_correctly}
                  </div>
                </div>}
                <div className="dpp_average">
                  <span className="dpp_avg_icon">
                    <img src={Attempted} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{Math.round(this.state.currentQuestion.analytics.avg_attempts * 10) / 10}</span>
                    {this.props.saveLanguageWiseData.avg_attempts}
                  </div>
                </div>
                <div className="dpp_average">
                  <span className="dpp_avg_icon">
                    <img src={AvgTime} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{this.state.currentQuestion.analytics.batch_avg_time}s</span>
                    {this.props.saveLanguageWiseData.avg_time_taken}
                  </div>
                </div>
                <div className="dpp_average">
                  <span className="dpp_avg_icon">
                    <img src={Score} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{Math.round(this.state.currentQuestion.analytics.batch_avg_score * 10) / 10}</span>
                    {this.props.saveLanguageWiseData.avg_score}
                  </div>
                </div>
              </div>}
            </div>
            <div className="assessment-bottom-right">
              <span onClick={this.previous}>
                {this.props.saveLanguageWiseData.previous}
              </span>
              <span onClick={this.next}>
              {this.props.saveLanguageWiseData.next}
              </span>
              
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentFacultyReviewData,getFacultyProfile,studentQuestionResponse,submitMarks,getLanguageWiseData} = actions;
  return {
    getAssessmentFacultyReviewData: (assessmentId, batchId) => dispatch(getAssessmentFacultyReviewData(assessmentId, batchId)),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    studentQuestionResponse : (content_id,question_id,section_id,page) => dispatch(studentQuestionResponse(content_id,question_id,section_id,page)),
    submitMarks : (attempt_id,question) => dispatch(submitMarks(attempt_id,question)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyReview);