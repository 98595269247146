import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import actions from '../../redux/actions';
import Dashboard from '../../public/icons/dashboard.svg';
import Practice from '../../public/icons/practice.svg';
import Tests from '../../public/icons/tests.svg';
import Calendar from '../../public/icons/calendar-icon.svg';
import Sessions from '../../public/icons/sessions.svg';
import Doubts from '../../public/icons/doubts.svg';
import Logo from '../../public/icons/favicon.svg';
import LogoSchoolOnWeb from '../../public/icons/schoolsonweb-logo-only.png';
import common from '../../common';
// import Settings from '../../public/icons/settings.svg';
import Random from '../../public/icons/random.svg';
import './styles.css';


import xpSidebarMyCoachIcon from '../../public/icons/xpsidebar-mycoach-icon.png';
import xpSidebarChallengesIcon from '../../public/icons/xpsidebar-challenges-icon.png';
import xpSidebarEventsIcon from '../../public/icons/xpsidebar-events-icon.png';
import xpSidebarLiveProjectsIcon from '../../public/icons/xpsidebar-liveprojects-icon.png';
import xpSidebarIdeasSpaceIcon from '../../public/icons/xpsidebar-ideasspace-icon.png';
import xpSidebarStationaryShopIcon from '../../public/icons/xpsidebar-stationaryshop-icon.png';
import xpSidebarMyPercentageIcon from '../../public/icons/xpsidebar-mypercentage-icon.png';
import xpSidebarTVIcon from '../../public/icons/xpsidebar-tv-icon.png';
import xpSidebarQueryIcon from '../../public/icons/xpsidebar-query-icon.png';
import xpSidebarAcademyicon from '../../public/icons/mycoach-academy-icon.png';



import ForgotImage from '../../public/icons/Group2701.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import config from '../../config.js';
import Assignment from '../../public/icons/assignment.svg';
import MyCourse from '../../public/icons/subtopic.svg';
import Library from '../../public/icons/books.png';
import Webinar from '../../public/icons/nav-webinaricon.png';
import Workshop from '../../public/icons/nav-eventsicon.png';
import IdeaSpace from '../../public/icons/nav-ideaspaceicon.png';
import Queries from '../../public/icons/nav-queryicon.png';
import vguLogo from '../../public/images/vgu-logo1.jpg';
var widgetCustomList = {
    "data": [
        {
            "id": 1,
            "added_on": "2021-05-03T00:00:00",
            "updated_on": "2021-05-03T00:00:00",
            "object_status": 0,
            "updated_by": null,
            "type": "popup",
            "size": "1x3",
            "position": "top-left",
            "request_type": "post",
            "image": null,
            "settings": "{\"settings\":[{\"access_type\":\"global\"}, {\"page_url\":[]},{\"new_login\":true}]}"
        },
    ],
    "error": [],
    "status": 1,
    "currentDateTime": "2021-05-04T10:33:47+05:30"
}

class WidgetSidebarLearner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      no_data: false,
      image: '',
      center_id: localStorage.getItem('center_id'),
      token:null,
      open1: false,
      hide:true,
      hide1:true,
      hide2:true,
      userid:"",
      uname:"",
      username:"",
      email:"",
      mobile:"",
      profile_picture:"",
      redirectURL:"",
      course_id:null,
      mobilenavigation:false,
      open5:false,
      visible:false,
      widget_visible_list:{},
      widgetList:[],
      widgetIds :[],
      widgetdata:{},
      widgetSettingsCss:{},
      widgetSettings:[],
      settingsList:[],
      user_name:localStorage.getItem("name"),
      getCenterId : ''
    }
  }

  componentDidMount() {
    let centerid = localStorage.getItem("center_id");
    console.log("getcenterid",centerid);
    this.setState({getCenterId:centerid})
    this.redirectURL();
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0)

    console.log(this.props.learnerProfile.institute_settings.menu)

    if(window.location.pathname === '/mycoach' || window.location.pathname === '/mycoach/challenges' || window.location.pathname === '/mycoach/liveprojects' || window.location.pathname === '/learner/doubts'){
      var element = document.getElementById("bodyParam");
      element.classList.add('mycoachpageadded');
    }

    this.instituteWidgetList();
    
  }

  instituteWidgetList = async() =>{

    let {widgetList,widgetdata,widgetIds,widgetSettings,settingsList,widgetSettingsCss} = this.state

    const response = await this.props.instituteWidgetList()
    if(response != undefined){
      widgetList = response.data?.sort((a,b) => parseInt(a.position) - parseInt(b.position)).filter(p => p.type == "popup")

      if(widgetList){
        widgetIds = widgetList.map(list => list.id)

        //widgetSettings = widgetList.map( cont => JSON.parse(cont.settings)?.settings);

        widgetSettings = widgetList.map(list1 => list1.settings.settings?.config)
        widgetSettingsCss = widgetList.map(list1 => list1.settings.settings?.css)

      }

      /*for(let i=0; i<widgetSettings.length; i++){
        let settings ={}
        for( let j =0 ; j<widgetSettings[i].length; j++){

          settings[Object.keys(widgetSettings[i][j])[0]] = Object.values(widgetSettings[i][j])[0];
        }

        settingsList.push(settings)
      }*/
    }

    if(widgetIds && widgetIds.length){

      for(let i=0; i<widgetIds.length ; i++){
        let widget_response = await this.props.getWidgetDataById(widgetIds[i]);
        if(widget_response != undefined){
          widgetdata[widgetIds[i]] = widget_response.data
        }
      }
    }

    console.log(widgetSettings)

    this.setPopupConfig(widgetSettings)
    this.setState({widgetdata,widgetList,widgetSettings,widgetSettingsCss,loading:false})

  }

  setPopupConfig = (setting) => {

    let {widget_visible_list} = this.state
    let access_type =0;
    let new_login = 0

    for(let i=0 ; i <setting.length;i++){

      if(setting[i]?.access_type == "global") access_type =1
      else{
        if(setting[i]?.page_url?.indexOf(window.location.pathname)){
          access_type =1
        }
      }
      widget_visible_list[i] = false;

      if(setting[i]?.is_new_login == true)
        this.state.profile.new_login === true ? new_login = 1 : new_login =0;
      else
        new_login =1;

      if(access_type && new_login){
        if(!localStorage.getItem("is_delayed_on")){
          setTimeout(() => {
            localStorage.setItem("is_delayed_on","1")
            widget_visible_list[i] = true;
            this.setState({widget_visible_list})
          },setting[i]?.display_in_seconds * 1000)
        }
        for(let r=0; r<setting[i]?.repeat?.length;r++){
          setTimeout(() => {
            widget_visible_list[i] = true;
            this.setState({widget_visible_list})
          },setting[i].repeat[r].delay * 1000)
        }
      }
    }
  }

  setBgColor = (setting) =>{
    let style
    if(setting){
      if(setting["background-color"]){
        style ={
          backgroundColor:setting["background-color"]
        }
      }
    }else{
      style ={
          backgroundColor:"#9010"
        }
    }
    return style
  }

  close = () =>this.setState({ open1: false });
  close6 = () => this.setState({ open5: false });
  close2 = (index) => {
    let {widget_visible_list} = this.state
    widget_visible_list[index] = false

    this.setState({widget_visible_list})
  }
  redirectURL = () => {
    this.setState({redirectURL:`${config.homePage}`});
  }

  getProfile = async (center) => {
    if(localStorage.getItem('token') && center !== undefined && center !== '') {
      const response = await this.props.getProfile(center);
      if(response !== undefined) {
        this.setState({ image: response.data.logo });
      }
    }
  }

  changePassword = async (username,password,new_password) => {
    const response = await this.props.changePassword(username,password,new_password,localStorage.getItem('lang'));
    if(response !=undefined){
      this.setState({open1:false});
      this.props.userLogin(username,new_password,0);
    }
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  handleChangeNewPassword = (e) => {
    //e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
    this.setState({ new_password: e.target.value ,confirm_password:'',is_password_correct:0})
  }

  handleChangeConfirmPassword = (e) => {
    this.setState({confirm_password:e.target.value});

    e.target.value===this.state.new_password?this.setState({is_password_correct:1}):this.setState({is_password_correct:0});
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  hideChange1 = () => this.setState({ hide1: !this.state.hide1 })

  hideChange2 = () => this.setState({ hide2: !this.state.hide2 })

  changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    var element = document.getElementById("bodyParam");
    element.classList.remove('mycoachpageadded');
    localStorage.removeItem("ideaspaceid");
    localStorage.removeItem("current_page");
    localStorage.removeItem("doubtid");

    this.setState({ mobilenavigation: false });
  }

  show3 = () => this.setState({ open1: true });
  close = () => this.setState({ open1: false });

  menuToggleActions(){
    const currentState = this.state.mobilenavigation;
    this.setState({ mobilenavigation: !currentState });
  }

  menuToggleClose(){
    this.setState({ mobilenavigation: false });
  }

  mobileCloseclick(){
      var element = document.getElementById("bodyParam");
      element.classList.remove('mobileview');
  }

  goToAssessment = () =>{
    if(this.props.learnerProfile?.institute_settings?.dashboard_type == "dynamic" && this.props.learnerProfile?.institute_settings?.custom_header_text){
      window.open("/learner/classroom/485","_self")
    }else{
      window.open("/learner/classroom/436","_self")
    }
  }

 
filter = (keyName) => {
  //console.log(keyName);
  let data = this.props.saveLanguageWiseData;
  //console.log(data[keyName]);
  return data[keyName];
}

  render() {
    

     const {settingsList,widgetSettingsCss,widgetList,widgetdata,widget_visible_list,widgetSettings,user_name} = this.state

     localStorage.setItem("level",this.props.learnerProfile?.classs?.level)
     localStorage.setItem("dashboard_type",this.porps?.learnerProfile?.institute_settings?.dashboard_type)
     const isEnabled = !this.state.password || !this.state.new_password || !this.state.confirm_password || !this.state.is_password_correct;
    return (

      <div>
      <Popup open={this.state.open1} closeOnDocumentClick onClose={this.close}
          className="change-password">
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="change-password-notification-popup">
            <b>Hello</b> {this.state?.profile?.name},<br/>
            Welcome onboard<br/><br/>
            Do you want to change your Password?
          </div>
           <div Style="display: flex;width: 100%;">
            <div Style="width: 60%">
              <img src={ForgotImage} alt="change-password" Style="width: 106%;"/>
            </div>
            <div Style="padding-top:77px;">
              {<div className="fw_input fw_input2">
                <div className="input_field">
                   <input type={this.state.hide ? "password" : "text"}  placeholder='Current Password' name='password'
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword}/>
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see"/>
                      : <img src={See} alt="hide"/>}
                  </div>
                  <div className="textContent"><i>password has been sent to your Email id</i></div>
                </div>
                <div className="input_field">
                   <input type={this.state.hide1 ? "password" : "text"} placeholder='New Password' name='new_password'
                    value={this.state.new_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeNewPassword}/>
                    <div className="togglePassword" onClick={this.hideChange1}>
                    {this.state.hide1 === true ? <img src={Hide} alt="see"/>
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>

                <div className="input_field" Style={this.state.is_password_correct?"border-bottom: 1px solid green":"border-bottom: 1px solid red"}>
                   <input type={this.state.hide2 ? "password" : "text"} placeholder='Confirm Password' name='confirm_password'
                    value={this.state.confirm_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeConfirmPassword}/>
                  <div className="togglePassword" onClick={this.hideChange2}>
                    {this.state.hide2 === true ? <img src={Hide} alt="see"/>
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>

                <div className="button-class">
                  <button onClick={() => this.changePassword(this.state.uname,this.state.password,this.state.new_password)} disabled={ isEnabled ? "disabled":""}>
                    Change Password
                  </button>
                </div>
              </div>}
            </div>
           </div>
        </Popup>

        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close6} className="complete-details">
          <div>
            <div className="content-state">
              Hey {this.state.profile?.name}, <br/><br/>

              {this.props.learnerProfile?.institute_settings?.dashboard_type == "dynamic" && this.props.learnerProfile?.institute_settings?.custom_header_text ? "Welcome to ImaginXP Future-Ready Talent Assessment 2021. You may practice the mock test now, to secure good grades in Final Assessment on 20th June 2021." : this.props.learnerProfile?.institute_settings?.dashboard_type == "dynamic" ? "Welcome to the Design Thinking and Innovation program for teachers brought to you by CBSE and AICTE, to set the pace and to help you with course better, we have planned a short pre - assessment for you, we highly recommend that you take the assessment now!":"Your coach is trying to reach out to you, for your career path. Please share your details to create a bridge between your career path and your personal coach.Click 'ok' to update."}
            </div>
            <div className="content-button">
              {this.props.dashboard_type == 'dynamic' ?<button onClick={this.goToAssessment} className="go-to-assessment">Go to Assessment</button>:<button onClick={this.show2}>Ok</button> }
              <button className="go-to-assessment" onClick={this.close6}>Cancel</button>
            </div>
          </div>
        </Popup>

      {widgetList?.length >0 && widgetList.map( (wd,wi) => {
        return (<Popup open={this.state.widget_visible_list[wi]} closeOnDocumentClick onClose={() => this.close2(wi)} className="complete-details-dialog-config" style={widgetSettingsCss[wi]}>
          {widgetdata[wd.id]?.length && widgetdata[wd.id].map(cont => <div className="complete-details-dialog-config-container" style={this.setBgColor(settingsList[wi])}>
            <span className="close" onClick={() =>this.close2(wi)}>&#x2715;</span>
            <div className="content-image">
              <img src={common.addFileUrl(cont?.image)}/>
            </div>
            <div className="config-dialog-content">
              <div className="content-title">
                <div>{widgetSettings[wi]?.placeholders?.includes('username') ? cont.title.indexOf('{username}')!= -1 ?`Hi ${user_name}`:cont.title : cont.title}</div>
              </div>
              <div className="content-desc">
                {cont?.content}
              </div>
              {cont.url && <div className="content-button">
                <a href={cont?.url}>{widgetSettings[wi]?.anchor_text || "Click"}</a>
              </div>}
            </div>
          </div>
          )}

        </Popup>
        )
      }

      )}

      <div className="bottomSidebar">
        <a href="javascript:void(0);" className="toggleMenu" onClick={()=>this.menuToggleActions()}>
            <span></span>
            <span></span>
            <span></span>
        </a>
      </div>


      <div  className={this.state.mobilenavigation ? 'header2 mobilenavigationActive':'header2 new-dashboard-navigation'}>
        <div className="CloseLink"><a href="javascript:void(0)" onClick={()=>this.menuToggleClose()}>&#x2715;</a></div>

        <div className="sc-logo-div-up">
        {this.state.getCenterId == 49 && 
            <div className="sc-logo-div"><img src={vguLogo} alt="logo"/></div>
          }
          {this.state.getCenterId != 49 &&
          <Link to={'/learner/dashboard'} onClick={this.changeTab} id={this.props.currentPage === '/learner/dashboard'
            || this.props.currentPage === '/learner/dashboard' ? 'selected-sl': ''}>
            {this.state.image === null || this.state.image === '' ? <div className="sc-logo-div">
            {/* <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> */}
            {localStorage.getItem('logo_url') != 'undefined' ?
            <img src={localStorage.getItem('logo_url')} alt="Brand logo"/>
            :
            <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/>
            }

            </div> : <div className="sc-logo-div">
            {/* <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> */}
            {localStorage.getItem('logo_url') != 'undefined' ?
            <img src={localStorage.getItem('logo_url')} alt="Brand logo"/>
            :
            <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/>
            }
            </div>}
          </Link>
          }
        </div>
        {/* <div className="sidebar-links">
          <Link to={'/learner'} onClick={this.changeTab} id={this.props.currentPage === '/learner'
            || this.props.currentPage === '/learner/' ? 'selected-sl': ''}>
            <img src={Dashboard} alt="dash"/>
            <span>Dashboard</span>
          </Link> */}
          {/* <Link to={'/learner/practice'} onClick={this.changeTab} id={this.props.currentPage === '/learner/practice'
            || this.props.currentPage === '/learner/practice/' ? 'selected-sl': ''}>
            <img src={Practice} alt="dash"/>
            <span>Practice</span>
          </Link> */}
          {/* <Link to={'/learner/tests'} onClick={this.changeTab} id={this.props.currentPage === '/learner/tests'
            || this.props.currentPage === '/learner/tests/' ? 'selected-sl': ''}>
            <img src={Tests} alt="dash"/>
            <span>Tests</span>
          </Link> */}
          {/* <Link to={'/learner/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/learner/sessions'
            || this.props.currentPage === '/learner/sessions/' ? 'selected-sl': ''}>
            <img src={Sessions} alt="dash"/>
            <span>Sessions</span>
          </Link> */}
          {/* <Link to={'/learner/doubts'} onClick={this.changeTab} id={this.props.currentPage === '/learner/doubts'
            || this.props.currentPage === '/learner/doubts/' ? 'selected-sl': ''}>
            <img src={Doubts} alt="dash"/>
            <span>Discussions</span>
          </Link> */}
            {/*<span className="menu-icon"><img src={Dashboard} alt="dash"/></span>
            <span className="menu-text">Dashboard</span>
          </Link>
          <Link to={'/learner/practice'} onClick={this.changeTab} id={this.props.currentPage === '/learner/practice'
            || this.props.currentPage === '/learner/practice/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Practice} alt="practice"/></span>
            <span className="menu-text">Practice</span>
          </Link>
          <Link to={'/learner/tests'} onClick={this.changeTab} id={this.props.currentPage === '/learner/tests'
            || this.props.currentPage === '/learner/tests/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Tests} alt="tests"/></span>
            <span className="menu-text">Tests</span>
          </Link>
          <Link to={'/learner/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/learner/sessions'
            || this.props.currentPage === '/learner/sessions/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Sessions} alt="sessions"/></span>
            <span className="menu-text">Sessions</span>
          </Link>
          <Link to={'/learner/webinar'} onClick={this.changeTab} id={this.props.currentPage === '/learner/webinar'
            || this.props.currentPage === '/learner/webinar/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Sessions} alt="webinar"/></span>
            <span className="menu-text">Webinar</span>
          </Link>
          <Link to={'/learner/doubts'} onClick={this.changeTab} id={this.props.currentPage === '/learner/doubts'
            || this.props.currentPage === '/learner/doubts/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Doubts} alt="doubts"/></span>
            <span className="menu-text">Doubts</span>
          </Link>
          <Link to={'/learner/assignment'} onClick={this.changeTab} id={this.props.currentPage === '/learner/assignment'
            || this.props.currentPage === '/learner/assignment/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Assignment} alt="dash"/></span>
            <span className="menu-text">Assignments</span>
          </Link>
          <Link to={'/learner/free-courses'} onClick={this.changeTab} id={this.props.currentPage === '/learner/free-courses'
            || this.props.currentPage === '/learner/free-courses/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Library} alt="dash"/></span>
            <span className="menu-text">Library</span>
          </Link>

          {/*<Link to={'/learner/calendar'} onClick={this.changeTab} id={this.props.currentPage === '/learner/calendar'
            || this.props.currentPage === '/learner/calendar/' ? 'selected-sl': ''}>
            <img src={Calendar} alt="dash"/>
            Calendar
          </Link>*/}

          {this.props.dashboard_type == 'dynamic' ? <div className="sidebar-links new-dashboard-navigation MOHIT dynamic-menu-config"> {this.props.learnerProfile?.institute_settings?.menu.sort(order => parseInt(order.order)-parseInt(order.order)).map( mn =>
            <Link to={mn.url} onClick={this.changeTab} id={this.props.currentPage === '/learner'
              || this.props.currentPage === `/app${mn.url}` ? 'selected-sl': ''}>
              <span className="menu-icon"><img src={mn.image ? mn.image : ""} alt="dash"/></span>
              {/* <span className="menu-text">{mn.label}</span> */}
            

              {/* {this.props.saveLanguageWiseData} */}
              {/* {mn.key} */}
              <span className="menu-text">{this.filter(mn.key)? this.filter(mn.key) : mn.label}</span>
              {/* <span className="menu-text">{mn.label}</span> */}
            </Link>
          )}</div>:""}
          {/* <a href="javascript:void(0)" title="Coming soon" alt="Coming soon" className="is_disabled">
            <img src={xpSidebarTVIcon} alt="TV"/>
            <span>T.V</span>
          </a> */}
          {/*<div className="sidebar-links new-dashboard-navigation dynamic-menu-config"><Link to={'/learner/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/app/learner/sessions'
            || this.props.currentPage === '/learner/sessions' ? 'selected-sl': ''}>
            <img src={Sessions} alt="dash"/>
            Sessions
        </Link></div>*/}
        {/* <div className="brand">
          <a className="logo-sow" href={this.state.redirectURL}>
            <img src={Logo} alt="img"/>
            <span>Virtual University</span>
          </a>
        </div> */}
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.commonReducer.currentPage,
    loading: state.commonReducer.loading,
    learnerProfile : state.commonReducer.logindetails,
    dashboard_type:state.commonReducer.dashboard_type,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}
const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage,getWidgetDataById,instituteWidgetList, getProfile,changePassword,userLogin} = actions;
  return {
    instituteWidgetList: () =>dispatch(instituteWidgetList()),
    getWidgetDataById : (id) => dispatch(getWidgetDataById(id)),
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getProfile: (center_id) => dispatch(getProfile(center_id)),
    changePassword: (username,otp,password,language) => dispatch(changePassword(username,otp,password,language)),
    userLogin:(username, password,is_xp_signup) => dispatch(userLogin(username, password,is_xp_signup))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetSidebarLearner);
