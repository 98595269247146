import React from 'react';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import configureStore from './redux/configureStore';
import AppComponent from './AppComponent';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from "./config";
const store = configureStore();
const history = createBrowserHistory({ basename: '/app' });
const themePrimaryColor = config.themePrimaryColor;
const themeSecondaryColor = config.themeSecondaryColor;
const themePrimaryLightColor = config.themePrimaryLightColor;


function App() {
  
  // document.documentElement.style.setProperty('--primary',themePrimaryColor);
  // document.documentElement.style.setProperty('--secondary',themeSecondaryColor);
  // document.documentElement.style.setProperty('--primary-rgba',themePrimaryLightColor);

  document.documentElement.style.setProperty('--primary', localStorage.getItem('primary_color') != 'undefined' ?  localStorage.getItem('primary_color'): themePrimaryColor);
  document.documentElement.style.setProperty('--secondary',localStorage.getItem('secondary_color')!= 'undefined' ? localStorage.getItem('secondary_color') : themeSecondaryColor);
  document.documentElement.style.setProperty('--primary-rgba',localStorage.getItem('primary_color_light')!= 'undefined' ?  localStorage.getItem('primary_color_light'): themePrimaryLightColor);


  let getLanguageFromLocal = localStorage.getItem("lang");
  document. documentElement. setAttribute("lang", getLanguageFromLocal);
  
  return (

    <GoogleOAuthProvider clientId="688254251234-b2emj2la0897kfvod23tioh9h5gvanhu.apps.googleusercontent.com">

    <Provider store={store}>
      <Router history={history}>
        <Route path='/' component={AppComponent}/>
      </Router>
    </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
