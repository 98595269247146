import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import DifficultyMeter from '../../public/icons/difficulty-meter.svg';
import Correct from '../../public/icons/correct.svg';
import Attempted from '../../public/icons/attempted.svg';
import AvgTime from '../../public/icons/avg-time.svg';
import Score from '../../public/icons/score.svg';
import Pin from '../../public/icons/pin.svg';
import CheckSymbol from '../../public/icons/check-symbol.svg';
import VideoPlayer2 from '../../components/players/video/VideoPlayer2';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import Zoom from '../../public/icons/full-screen.svg';
import download from '../../public/icons/down-arrow.svg';
import './styles.css';

const ques_types = {
  1: 'MCQ',
  2: 'Single Integer',
  3: 'Multiple Response',
  4: 'Fill in the blanks',
  5: 'True or False',
  6: 'Subjective',
  7: 'Matching'
}

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 10,
    slidesToSlide: 10
  },
  tablet: {
    breakpoint: { max: 720, min: 590 },
    items: 10,
    slidesToSlide: 10
  },
};

const questionColors = {
  answered: "#56affc", correct: "#66bb6a", not_visited: "#FFFFFF", skipped: "#f2e4c8", wrong: "#ff8080"
}

const SlideButtons = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const { carouselState: { totalItems } } = rest;
  const lastSlide = Math.floor(totalItems / 4) + (totalItems % 4) - 1;
  if(totalItems <= 10) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="slide-buttons-xy">
      <img src={Left} className='slide-button-xy' onClick={() => previous()} alt="img"/>
      <img src={Right} className='slide-button-xy' onClick={() => next()} alt="img"/>
    </div>
  );
};

class LearnerReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: this.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      no_ques: false,
      showSolution: false,
      sections: [],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      options: [],
      open5:false,
      selected_image:null,
      selected_image_on:null,
      file_extension:"",
      question_id:'',
      attempt_id:'',
      newAnalyticsData:''
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    // this.renderMath()
    this.getReviewData();
    //this.getAnalyticsData();
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
    
  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"learner");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }

  // componentDidUpdate() {
  //   this.renderMath()
  // }

  // renderMath() {
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node2.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node3.current
  //   ])
  // }

  getReviewData = async () => {
    const { id } = this.props.match.params;
    
    const response =  await this.props.getAssessmentReviewData(id)
    if(response !== undefined){
       this.setState({attempt_id:response.data.attempt_id});
      
      response.data.sections && response.data.sections.map(section => {
        section.questions.map((question, index) => {
          question.question_number = "Q"+(index+1);
        })
      })
      this.setState({ title: response.data.title, code: response.data.uid, 
        sections: response.data.sections, 
        question: response.data.sections[0]?.questions,
        options: response.data.sections[0]?.questions[0]?.options,
        section_title: response.data.sections[0]?.title, exerciseAttempt: response.data,
        question_title: response.data.sections[0]?.questions[0]?.title });
      this.setCurrentSectionQ(response.data.sections);
     
    }
    else {
      this.setState({ no_ques: true, loading: false })
    }
  }

  
  

  setCurrentSectionQ = (arr) => {
    var cng = 0;
    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    for(let i = 0; i < arr.length; i ++) {
      if(arr[i].questions.length > 0) {
        
        this.setState({ currentSection: arr[i], currentQuestion: arr[i]?.questions[0] });
        cng = 1;

        console.log(arr[i]?.questions[0].id)
        if(arr[i]?.questions[0].image){
          //console.log(currentSection.questions[index].image.split('/').pop())
          if(blockedTile.indexOf(arr[i]?.questions[0].image.split('.').pop()) != -1)
          {  
            this.setState({file_extension:1,selected_image_on:arr[i]?.questions[0].image.split('/').pop()}); //1 is used for image file
          }else{
            this.setState({file_extension:2,selected_image_on:arr[i]?.questions[0].image.split('/').pop()}); //2 is used for content file
          }
        }

        this.getAnalyticsData(arr[i]?.questions[0].id);
        break;
      }
    }
    if(cng === 0) {
      this.setState({ no_ques: true })
    }
    this.setState({ loading: false })
  }

  getQuestionColorStatus = (analytics, actionId,question_type_id) => {
    var check = null;
    if(analytics.is_correct === true) {
      check = true;
    }
    else if(analytics.is_correct === false) {
      check = false
    }
    if(check === null) {
      if(actionId === 1){
        if(question_type_id == 6) return questionColors.correct;
        return questionColors.answered
      }
      else if(actionId === 2)
        return questionColors.skipped
      else
        return questionColors.not_visited
    }
    else {
      if(check === true)
        return questionColors.correct
      else
        return questionColors.wrong
    }
  }

  changeCurrentQuestion = async (sectionId, quesionId) => {
    this.getAnalyticsData(quesionId);
    this.moveToNext(sectionId, quesionId);
   
  }


  getAnalyticsData = async (quesionId) => {
    let {exerciseAttempt,newAnalyticsData } = this.state;
    const response =  await this.props.getAssessmentReviewAnalyticsData(exerciseAttempt.id,quesionId)
    if(response !== undefined){
      console.log(exerciseAttempt.id);
      console.log(response);
      this.setState({newAnalyticsData:response.data})
    }
    else {
     
    }
  }

  zoomOn = (url) => {
    this.setState({open5:true,selected_image:url})
  }

  moveToNext = (sectionId, questionId) => {
    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    let { currentQuestion, currentSection, exerciseAttempt } = this.state;
    
    if(currentSection.id === sectionId){
      const questionIndex = currentSection.questions.findIndex(p => p.id === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    } 
    else {
      const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === sectionId)
      currentSection = exerciseAttempt.sections[sectionIndex]
      const questionIndex = currentSection.questions.findIndex(p => p.id === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    }

    let index=currentSection.questions.findIndex(p => p.id === questionId);
    if(currentSection?.questions[index]?.image){
      //console.log(currentSection.questions[index].image.split('/').pop())
      if(blockedTile.indexOf(currentSection.questions[index].image.split('.').pop()) != -1)
      {  
        this.setState({file_extension:1,selected_image_on:currentSection.questions[index].image.split('/').pop()}); //1 is used for image file
      }else{
        this.setState({file_extension:2,selected_image_on:currentSection.questions[index].image.split('/').pop()}); //2 is used for content file
      }
      console.log(currentSection.questions[index].image.split('.').pop())
    }

    this.setState({
      currentQuestion,
      currentSection,
      showSolution: false
    })
  }

  close5 = () => this.setState({ open5: false ,selected_image:null});

  previousSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== 0) {
      this.getAnalyticsData(exerciseAttempt.sections[sectionIndex - 1]?.questions[0]?.id);
      this.moveToNext(exerciseAttempt.sections[sectionIndex - 1].id, exerciseAttempt.sections[sectionIndex - 1]?.questions[0]?.id);
    }
  }

  nextSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== exerciseAttempt.sections.length-1) {
      this.getAnalyticsData(exerciseAttempt.sections[sectionIndex+1]?.questions[0]?.id);
      this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1]?.questions[0]?.id);
    }
  }

  previous = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
    if(questionIndex === 0) {
      if(sectionIndex !== 0) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex-1].id, exerciseAttempt.sections[sectionIndex-1].questions[exerciseAttempt.sections[sectionIndex-1].questions.length-1].id)
      }
    } 
    else {
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex - 1].id)
    }
    this.getAnalyticsData(currentSection.questions[questionIndex - 1].id);
  }

  next = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions
                          .findIndex(p => p.id === currentQuestion.id)
    if(questionIndex === currentSection.questions.length - 1) {
      if(sectionIndex !== exerciseAttempt.sections.length-1) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1].questions[0].id)
      }
    } else{
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex+1].id)
    }
    this.getAnalyticsData(currentSection.questions[questionIndex+1].id);
  }

  render() {
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      showSolution,
      loading
    } = this.state;
    if(this.props.loading || loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    else if(exerciseAttempt === '' || exerciseAttempt === null || exerciseAttempt === undefined
        || this.state.no_ques === true || exerciseAttempt.sections.length === 0) {
      return (
        <div className="assessment-div">
          <span id="no-questions">{this.props.saveLanguageWiseData.no_questions}</span>
        </div>
      )
    }
    else {
      return (
        <div>
          <Helmet>
            <title>Review - {this.state.title}</title>
          </Helmet>
          <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="zoom-image-on">
            <div className="zoom-image">
              <img src={this.state.selected_image} />
            </div>
          </Popup>
          <div className="assessment-head">
            <div className="assessment-head1">
              <span>{this.state.title}</span>
            </div>
            <div className="assessment-head2">
              <div className="assessment-section-buttons">
                <img src={MoveLeft} alt="img" onClick={this.previousSection}/>
                {currentSection.title}
                <img src={MoveRight} alt="img" onClick={this.nextSection}/>
              </div>
              <Carousel containerClass="section-layer-tray" responsive={responsive}
                keyBoardControl={false} arrows={false} swipeable={false} draggable={false}
                customButtonGroup={<SlideButtons/>} renderButtonGroupOutside={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}>
                {currentSection.questions !== undefined 
                  && currentSection.questions.map((question, index) => 
                  <div className="sec-ques-div" key={`${question.id}_${index}`}
                    onClick={()=>this.changeCurrentQuestion(currentSection.id, question.id)}
                    style={{backgroundColor: this.getQuestionColorStatus(question.analytics, question.analytics.action_id,question.question_type_id)}}>
                    {index !== 0 && <hr className="sec-line-one"/>}
                    {index + 1}
                    {(index !== (currentSection.questions.length - 1)) && <hr className="sec-line-two"/>}
                  </div>
                )}
              </Carousel>
            </div>
            <div className="assessment-head4">
              <Link to={{pathname: `/learner/scorecard/${this.state.id}`,
                state: {contentId: this.state.id}}}>
                <button>
                  {this.props.saveLanguageWiseData.scorecard}
                </button>
              </Link>
              <button id="button-reverse" onClick={() => this.props.history.goBack()}>
                {this.props.saveLanguageWiseData.exit_review}
              </button>
            </div>
          </div>
          {currentSection.questions.length > 0 ? <div className="assessment-main">
            <div className="assessment-ques">
              <div className="subject">{currentQuestion?.duration_seconds && currentQuestion?.duration_seconds+ "s"} {/*currentSection.title*/}</div>
              <div className="time-remaining">
                <span className="question_no">{currentQuestion?.question_number}</span>
                <div className="ques_type_prev">
                  {/*ques_types[currentQuestion?.question_type_id]*/}
                  {currentQuestion.analytics.time_taken && currentQuestion.analytics.time_taken+ "s"} 
                </div>
              </div>
              <div className="questionImage">
                <p ref={this.node} dangerouslySetInnerHTML={{__html: currentQuestion.statement}} />
                {currentQuestion.question_type_id == 6 ? <> { this.state.file_extension ==2 ? <div className="download-file-attempt" Style={!currentQuestion.image?"display:none":""}><a href={common.addFileUrl(currentQuestion.image)} target="_blank" rel="" download>
                {/* <i className="fas fa-download"/> */}
                <img src={download} alt="download"/> {this.props.saveLanguageWiseData.download} {this.state.selected_image_on}
                </a></div> :<span onClick={()=>this.zoomOn(common.addFileUrl(currentQuestion.image))} Style={!currentQuestion.image?"display:none":""}><img src={common.addFileUrl(currentQuestion.image)} /></span>} </>:
                <span onClick={()=>this.zoomOn(common.addFileUrl(currentQuestion.image))} Style={!currentQuestion.image?"display:none":""}><img src={common.addFileUrl(currentQuestion.image)} /></span>}
              </div>
            </div>

            <div className="assessment-options">
              {this.state.newAnalyticsData.is_correct === true ? <>
                  {currentQuestion.marks !== 0 ?
                    <div className="correct-answer">
                      <div><img src={CheckSymbol} alt="correct-answer" /> {this.props.saveLanguageWiseData.correct}</div>
                      <div>{this.state.newAnalyticsData.score == null ? "_":this.state.newAnalyticsData.score}/{currentQuestion.marks}</div>
                    </div> :
                    <div className="correct-answer">
                      <div><img src={CheckSymbol} alt="correct-answer" /> {this.props.saveLanguageWiseData.correct}</div>
                    </div>
                  }
                </> : this.state.newAnalyticsData.is_correct === false ? <>
                  { currentQuestion.marks !== 0 ?
                    <div className="incorrect-answer">
                      <div>X {this.props.saveLanguageWiseData.incorrect}</div>
                      <div>{this.state.newAnalyticsData.score == null ? "_":this.state.newAnalyticsData.score}/{currentQuestion.marks}</div>
                    </div> :
                    <div className="incorrect-answer">
                      <div>X {this.props.saveLanguageWiseData.incorrect}</div>
                    </div>
                  }
                </> : currentQuestion.question_type_id == 6 ?<div className="correct-answer"><div>{this.state.newAnalyticsData.score == null ? "__":this.state.newAnalyticsData.score}/{currentQuestion?.marks}</div></div> :"" }
              {currentQuestion?.question_type_id == 6 ? <div Style="margin-bottom: 15px;"><div className="subjective">
                {this.props.saveLanguageWiseData.your_answer} :
              </div><div className="subjective_sol">
                {currentQuestion?.student_solution}
               
              </div><div>
                {currentQuestion?.analytics?.users_file && <div className="download-file"><a href={common.addFileUrl(currentQuestion?.analytics?.users_file)} target="_blank" rel="" download>
                  <i className="fas fa-download"/>
                  <img src={download} alt="download"/> {this.props.saveLanguageWiseData.download_file}
                </a></div>}
              </div></div>: <>
                {currentQuestion?.options &&
                  currentQuestion?.options.map((option, index) =>
                  <div className="option-container" key={`option_${index}`}>
                    { option.is_selected && <div className="your-answer"> {this.props.saveLanguageWiseData.your_answer} </div> }
                    <div ref={this.node2} key={`option_${index}`}
                      className={ option.is_selected  ? option.is_correct ?"correct-option select-option" :"incorrect-option select-option" : "select-option"}
                      style={option.is_correct ? {borderColor: 'var(--primary)'} : {}}
                      dangerouslySetInnerHTML={{__html: option?.statement}}
                    />
                   
                    {option.image && <><span className="option-image" onClick={()=>this.zoomOn(common.addFileUrl(option.image))}><img Style={option.is_correct ? "border: 1px solid #66bb6a" : ""} src={common.addFileUrl(option.image)}/><span>{this.props.saveLanguageWiseData.click_image_zoom}</span></span>
                                </>}
                     
                  </div>
                )}
              </>}

              {/*currentQuestion.explanation && currentQuestion.solution.video_file !== undefined
                && !showSolution && <button onClick={()=>this.setState({showSolution: true})} 
                className="see-solution">See Solution</button>
              */}
              { currentQuestion.explanation && !showSolution && <button onClick={()=>this.setState({showSolution: true})} 
                className="see-solution">{this.props.saveLanguageWiseData.see_solution}</button>
              }
              {showSolution &&
                <div className="solution-content">
                  {currentQuestion.explanation &&
                    <>
                      <span><b>{this.props.saveLanguageWiseData.solution}:</b></span><br/><span ref={this.node3} dangerouslySetInnerHTML={{__html: currentQuestion.explanation}} />
                    </>
                  }
                  {currentQuestion.solution.video_file &&
                    <VideoPlayer2 url={common.addFileUrl(currentQuestion?.solution?.video_file)}/>
                  }
                </div>
              }
            </div>
          </div> : <span id="no-questions">{this.props.saveLanguageWiseData.no_questions}</span>}
          <div className="assessment-bottom2">
            <div className="assessment-bottom-left">
              {!isEmpty(this.state.newAnalyticsData) && <div className="dpp-details">
                <div className="dpp-difficulty">
                  <span>
                    <img src={DifficultyMeter} alt="img"/>
                    <img className={`${this.state.newAnalyticsData.difficulty_level}-pin`} src={Pin} alt="img"/>
                  </span>
                  <div className="dpp-difficult">
                    {this.props.saveLanguageWiseData.difficulty}:<p>{this.state.newAnalyticsData.difficulty_level}</p>
                  </div>
                </div>
                {this.state.currentQuestion.question_type_id != 6 && <div className="dpp_average">
                  <span className="dpp_avg_icon">
                    <img src={Correct} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{this.state.newAnalyticsData.batch_correct_percentage}%</span>
                    {this.props.saveLanguageWiseData.answered_this_correctly}
                  </div>
                </div>}
                <div className="dpp_average">
                  <span className="dpp_avg_icon">
                    <img src={AvgTime} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{this.state.newAnalyticsData.batch_avg_time}s</span>
                    {this.props.saveLanguageWiseData.avg_time_taken}
                  </div>
                </div>
              </div>}
            </div>
            <div className="assessment-bottom-right">
              <span onClick={this.previous}>
                {this.props.saveLanguageWiseData.previous}
              </span>
              <span onClick={this.next}>
              {this.props.saveLanguageWiseData.next}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentReviewData,getAssessmentReviewAnalyticsData,getLanguageWiseData } = actions;
  return {
    getAssessmentReviewData: (assessmentId) => dispatch(getAssessmentReviewData(assessmentId)),
    getAssessmentReviewAnalyticsData: (attempt_id,question_id) => dispatch(getAssessmentReviewAnalyticsData(attempt_id,question_id)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerReview);