import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';

import actions from '../../redux/actions';
import common from '../../common';
import Dashboard from '../../public/icons/dashboard.svg';
import Practice from '../../public/icons/practice.svg';
import Tests from '../../public/icons/tests.svg';
import Calendar from '../../public/icons/calendar-icon.svg';
import Sessions from '../../public/icons/sessions.svg';
import Doubts from '../../public/icons/doubts.svg';
import Logo from '../../public/icons/favicon.svg';
import LogoSchoolOnWeb from '../../public/icons/schoolsonweb-logo-only.png';
// import Settings from '../../public/icons/settings.svg';
import Random from '../../public/icons/random.svg';
import './styles.css';
import ForgotImage from '../../public/icons/Group 270.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import config from '../../config.js';
import StudyMaterialCreate from '../../public/icons/learning.svg';
import Assignment from '../../public/icons/assignment.svg';
import StudioProjectIcon from '../../public/icons/studio-project-icon.png';
import vguLogo from '../../public/images/vgu-logo1.jpg';
import lessonplan from '../../public/icons/bot.svg';

class SidebarFaculty extends Component {
	constructor(props) {
    super(props);
    this.state = {
      profile: {},
      no_data: false,
      image: '',
      center_id: '',
      open1: false,
      password:"",
      new_password:"",
      confirm_password:"",
      is_password_correct:0,
      profile:"",
      hide:true,
      hide1:true,
      hide2:true,
      userid:"",
      uname:"",
      username:"",
      email:"",
      mobile:"",
      profile_picture:"",
      redirectURL:"",
      mobilenavigation:false,
      getCenterId : ''
    }
  }

  componentDidMount() {
    let centerid = localStorage.getItem("center_id");
    console.log("getcenterid",centerid);
    this.setState({getCenterId:centerid})
    this.getFacultyProfile();
    this.redirectURL();
		setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0)
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({profile:response?.data});
    this.getProfile(response?.data.center_id);
    this.setState({userid:response?.data.email,uname:response?.data.username,username:response?.data.name,email:response?.data.email,mobile:response?.data.mobile,profile_picture:response?.data.profile_picture});

    if(response?.data.new_login === true){
      this.show3();
    }
  }

  redirectURL = () => {
    this.setState({redirectURL:`${config.homePage}`});
  }
  

  changePassword = async (username,password,new_password) => {
    const response = await this.props.changePassword(username,password,new_password,localStorage.getItem('lang'));
    
    this.setState({open1:false});
  }

  getProfile = async (center) => {
    if(localStorage.getItem('token') && center !== undefined && center !== '') {
      const response = await this.props.getProfile(center);
      if(response !== undefined) {
        this.setState({ image: response.data.logo });
      }
    }
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  handleChangeNewPassword = (e) => {
    //e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
    this.setState({ new_password: e.target.value ,confirm_password:'',is_password_correct:0})
  }

  handleChangeConfirmPassword = (e) => {
    this.setState({confirm_password:e.target.value});

    e.target.value===this.state.new_password?this.setState({is_password_correct:1}):this.setState({is_password_correct:0});
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  hideChange1 = () => this.setState({ hide1: !this.state.hide1 })

  hideChange2 = () => this.setState({ hide2: !this.state.hide2 })
	
	changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    this.setState({ mobilenavigation: false });
  }
  
  show3 = () => this.setState({ open1: true });
  close = () => this.setState({ open1: false });

  menuToggleActions(){
    const currentState = this.state.mobilenavigation;
    this.setState({ mobilenavigation: !currentState });
  }
  
  menuToggleClose(){
    this.setState({ mobilenavigation: false });
  }



  render() {
    const isEnabled = !this.state.password || !this.state.new_password || !this.state.confirm_password || !this.state.is_password_correct;

    return (
      <div>
      
        <Popup children={<>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="change-password-notification-popup">
            
            <b>{this.props.saveLanguageWiseData.hello}</b> {this.state?.profile?.name},<br/>
            {this.props.saveLanguageWiseData.welcome_onboard_on_schools_on_web}<br/><br/>
            {this.props.saveLanguageWiseData.do_you_want_to_change_your_password}
          </div>
           <div Style="display: flex;width: 100%;">
            <div Style="width: 60%"> 
              <img src={ForgotImage} alt="change-password" Style="width: 106%;"/>
            </div>
            <div Style="padding-top:81px;">
              {<div className="fw_input fw_input2">
                
                <div className="input_field">
                   <input type={this.state.hide ? "password" : "text"}  placeholder={this.props.saveLanguageWiseData.current_password} name='password'
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword}/>
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                <div className="input_field">
                   <input type={this.state.hide1 ? "password" : "text"} placeholder={this.props.saveLanguageWiseData.new_password} name='new_password'
                    value={this.state.new_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeNewPassword}/>
                    <div className="togglePassword" onClick={this.hideChange1}>
                    {this.state.hide1 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>

                <div className="input_field" Style={this.state.is_password_correct?"border-bottom: 1px solid green":"border-bottom: 1px solid red"}>
                   <input type={this.state.hide2 ? "password" : "text"} placeholder={this.props.saveLanguageWiseData.confirm_password} name='confirm_password'
                    value={this.state.confirm_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeConfirmPassword}/>
                  <div className="togglePassword" onClick={this.hideChange2}>
                    {this.state.hide2 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                
                <div className="button-class">
                  <button onClick={() => this.changePassword(this.state.uname,this.state.password,this.state.new_password)} disabled={ isEnabled ? "disabled":""}>
                    {this.props.saveLanguageWiseData.change_password}
                  </button>
                </div>
              </div>}
            </div>
           </div>
        </>}  open={this.state.open1} closeOnDocumentClick onClose={this.close}
          className="change-password"/>
          
       

        <div className="bottomSidebar">
        <a className="toggleMenu" onClick={()=>this.menuToggleActions()}>
            <span></span>
            <span></span>
            <span></span>
        </a>
      </div>



      <div className={this.state.mobilenavigation ? 'header2 mobilenavigationActive': 'header2'}>
      <div className="CloseLink"><a href="javascript:void(0)" onClick={()=>this.menuToggleClose()}>&#x2715;</a></div>
        <div className="sc-logo-div-up">
          {this.state.getCenterId == 49 && 
            <div className="sc-logo-div"><img src={vguLogo} alt="logo"/></div>
          }
          {this.state.image === null || this.state.image === '' && this.state.getCenterId != 49 ? <div className="sc-logo-div">
          {/* <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> */}
          {localStorage.getItem('logo_url') != 'undefined' ? 
              <span><img src={localStorage.getItem('logo_url')} alt="Brand logo"/></span>
            : 
            <span><img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span>
            }
          </div> : <div className="sc-logo-div"
            // style={{backgroundImage: "url("+common.addFileUrl(this.state.image)+")"}}>
               style={{backgroundImage: "url("+localStorage.getItem('logo_url')+")"}}>
          </div>}
        </div>
        <div className="sidebar-links">
          <Link to={'/faculty'} onClick={this.changeTab} id={this.props.currentPage === '/faculty' 
						|| this.props.currentPage === '/faculty/' ? 'selected-sl': ''}>
            <img src={Dashboard} alt="dash"/>
            <span>{this.props.saveLanguageWiseData.dashboard}</span>
          </Link>


          {/*<Link to={'/faculty/practice'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/practice' 
						|| this.props.currentPage === '/faculty/practice/' ? 'selected-sl': ''}>
            <img src={Practice} alt="dash"/>
            <span>Practice</span>
          </Link>
          <Link to={'/faculty/tests'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/tests' 
						|| this.props.currentPage === '/faculty/tests/' ? 'selected-sl': ''}>
            <img src={Tests} alt="dash"/>
            <span>Tests</span>
          </Link>
          <Link to={'/faculty/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/sessions' 
						|| this.props.currentPage === '/faculty/sessions/' ? 'selected-sl': ''}>
            <img src={Sessions} alt="dash"/>
            <span>Sessions</span>
          </Link>
          <Link to={'/faculty/doubts'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/doubts' 
						|| this.props.currentPage === '/faculty/doubts/' ? 'selected-sl': ''}>
            <img src={Doubts} alt="dash"/>
            <span>Discussions</span>
          </Link>*/}
          <Link to={'/faculty/practice'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/practice' 
						|| this.props.currentPage === '/faculty/practice/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Practice} alt="dash"/></span>
            <span className="menu-text">{this.props.saveLanguageWiseData.practice_test}</span>
          </Link>
          <Link to={'/faculty/tests'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/tests' 
						|| this.props.currentPage === '/faculty/tests/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Tests} alt="dash"/></span>
            <span className="menu-text"> {this.props.saveLanguageWiseData.scheduled_test}</span>
          </Link>
          <Link to={'/faculty/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/sessions' 
						|| this.props.currentPage === '/faculty/sessions/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Sessions} alt="dash"/></span>
            <span className="menu-text">{this.props.saveLanguageWiseData.sessions}</span>
          </Link>
          {/* <Link to={'/faculty/doubts'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/doubts' 
						|| this.props.currentPage === '/faculty/doubts/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Doubts} alt="dash"/></span>
            <span className="menu-text">Doubts</span>
          </Link> */}
          <Link to={'/faculty/assignment'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/assignment' 
            || this.props.currentPage === '/faculty/assignment/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Assignment} alt="dash"/></span>
            <span className="menu-text">{this.props.saveLanguageWiseData.assignments}</span>
          </Link>
          <Link to={'/faculty/studio-project'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/studio-project' 
            || this.props.currentPage === '/faculty/studio-project/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={StudioProjectIcon} alt="Studio Project"/></span>
            <span className="menu-text">{this.props.saveLanguageWiseData.studio_project}</span>
          </Link>
          {/*<Link to={'/faculty/study-material-create'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/study-material-create' 
            || this.props.currentPage === '/faculty/study-material-create/' ? 'selected-sl': ''}>
            <img src={StudyMaterialCreate} alt="dash"/>
            Study Material
          </Link>*/}
          {/*<Link to={'/faculty/calendar'} onClick={this.changeTab} id={this.props.currentPage === '/faculty/calendar' 
						|| this.props.currentPage === '/faculty/calendar/' ? 'selected-sl': ''}>
            <img src={Calendar} alt="dash"/>
            Calendar
          </Link>*/}

          <Link to={'/faculty/amplifiu-create'} onClick={this.changeTab} id={this.props.currentPage === '/faculty' 
						|| this.props.currentPage === '/faculty/amplifiu-create/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={lessonplan} alt="lessonplan"/></span>
            <span className="menu-text">Lesson Plan</span>
          </Link>
        </div>
        {/* <div className="brand">
          <a className="logo-sow" href={this.state.redirectURL}>
            <img src={Logo} alt="img"/>
            <span>Virtual University</span>
          </a>
        </div> */}

         
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
	return {
    currentPage: state.commonReducer.currentPage,
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}
  
const mapDispatchToProps = (dispatch) => {
	const { setCurrentPage, getFacultyProfile, getProfile,changePassword } = actions;
	return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getProfile: (center_id) => dispatch(getProfile(center_id)),
    changePassword: (username,otp,password,language) => dispatch(changePassword(username,otp,password,language)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFaculty);