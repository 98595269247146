import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import actions from '../../redux/actions';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Admin from '../../public/images/contacts-icon.png';
import Pagination from "react-js-pagination";
import Back from '../../public/icons/back.svg';
class MarkAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batch_id:'',
      lecture_id : '',
      class_id : '',
      user_id:'',
      studentList:[],
      current_page:0,
      totalItems:0,
      token:localStorage.getItem('token'),
      is_absent:false,
      is_search:false,
    }
  }
  
  componentDidMount(){
    let getLectureData = this.props.location.state?.lecture;
    if(getLectureData){
      this.getAllData(getLectureData)
     }
   }
 
   getAllData = (data) => {
     let getLectureData = data;
     setTimeout(() => {
      console.log(getLectureData);
       this.setState({batch_id:getLectureData?.batch?.id});
       this.setState({lecture_id:getLectureData?.id});
       this.setState({class_id:getLectureData?.classs?.id});
       this.getAllStudentListData()
     }, 1000);
    
 }
 
 
 getAllStudentListData = async (page) =>{
   console.log("api call");
   let {batch_id,lecture_id,current_page,is_search} = this.state;
  //  console.log("search",search);
  //  console.log("current_page",current_page);
  //  console.log("current_page",this.state.current_page);

  console.log("this.state.is_search");
  console.log(this.state.is_search);
   if(batch_id,lecture_id){
     const response = await this.props.adminLectureWiseAttendance(batch_id,lecture_id,current_page ? current_page : 1, this.state.is_search ? this.state.is_search:'')
     if(response !== undefined) {
      // console.log("response");
      // console.log(response?.data?.data);
      // console.log("pagination");
      // console.log(response?.data?.pagination);
      
      if(response?.data?.pagination != undefined){
        this.setState({studentList: response?.data?.data ? response?.data?.data : response?.data,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20})
      }else{
        this.setState({studentList: response?.data})
      }

       
     }
 
  }
 }


  
 handlePageChange(pageNumber) {
   console.log("pageNumber",pageNumber);
  this.setState({current_page: pageNumber});
  setTimeout(() => {
    this.getAllStudentListData(pageNumber);
   }, 1000);
  
}

handleAttendanceChange(e,studentId) {
  let saveStudentId = studentId;
  // console.log(e.target.value)
  if(e.target.value == 'Absent'){
    this.setState({is_absent: true});
  }else{
    this.setState({is_absent: false});
  }
  // console.log('studentId')
  // console.log(saveStudentId)
  this.setState({user_id: saveStudentId});
  setTimeout(() => {
    this.adminMarkAttendanceAPI()
  }, 500);
}

adminMarkAttendanceAPI = async () =>{
  let today = new Date();
  let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  let dateTime = date + ' ' + time;
  let {batch_id,lecture_id,user_id,is_absent} = this.state;
  // console.log("api call adminMarkAttendanceAPI");
  //  console.log(this.state.user_id);
  //   console.log(this.state.lecture_id);
  //   console.log(this.state.batch_id);
  //   console.log(dateTime);
  //   console.log(this.state.is_absent);
    if(batch_id,lecture_id,user_id){
    const response = await this.props.adminMarkAttendanceAPI(lecture_id,user_id,dateTime,'attendance',is_absent)
    if(response !== undefined) {
    //  console.log("response");
    //  console.log(response?.data?.data);
    setTimeout(() => {
      this.getAllStudentListData(this.state.current_page);
     }, 1000);
     
    }
  }
 }

 handleSearchChange= (e) =>{
   console.log(e.target.value.toLowerCase());
   let Save = e.target.value.toLowerCase();
   this.setState({is_search: Save});
   setTimeout(() => {
    this.getAllStudentListData()
   }, 1000);
   
 }

	render() {
    
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div mark-attendance-page">
        <Helmet>
          <title>Attendance</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search study material"/>
        <div className='main-part'>
          <div className='sub-head'>
              <div className='heading'><div id="trace-back" onClick={() => this.props.history.goBack()}>
                <img src={Back} alt="back"/></div><h1>Attendance</h1></div>
              <div className='filterBlock'>Search <input type="search" onChange={(e) => this.handleSearchChange(e)} placeholder='Enter student name' /></div>
            </div>
            <div className='markAttendanceWrapper'>
                <div className='markAttendanceContainer'>

                
                {/* {JSON.stringify(this.state.studentList)} */}
                {this.state.studentList && this.state.studentList.length > 0 &&
                  <div className='byClassHeading'>Class - {this.state.studentList[0].classs}</div>
                }
                <ol>
                {this.state.studentList && this.state.studentList.length > 0 && this.state.studentList.map(data =>
                  <li key={data.id}>
                        <div className="thumb">
                          <img src={Admin} />
                        </div>
                        <div className="thumbContent">
                            <div>
                                <h1>Name - {data.user_name}</h1>
                                <p>Email - {data.user_email}</p>
                                <p>Roll No -{data.roll_no ? data.roll_no : "-"}</p>
                                {/* <p>Class - {data.classs}</p> */}
                            </div>
                            <div>
                              {/* {data?.attendence} */}
                              <select defaultValue={data?.attendence} id={data?.user_id} onChange={(e) => this.handleAttendanceChange(e, data?.user_id)}>
                                <option>Absent</option>
                                <option>Present</option>
                              </select>
                            </div>
                        </div>
                    </li>
                  
                )}
                {this.state.studentList.length == 0 &&
                <li className='noResultMessage'>No Result Found</li>
                  }
                </ol>
                
                {!this.state.is_search && this.state.studentList && this.state.studentList.length > 0 && <Pagination
                  activePage={this.state.current_page}
                  itemsCountPerPage={20}
                  totalItemsCount={this.state.totalItems}
                  pageRangeDisplayed={10}
                  onChange={this.handlePageChange.bind(this)}
                />}
                </div>
            </div>



        </div>
        
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    learnerprofile : state.commonReducer.logindetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { adminLectureWiseAttendance, adminMarkAttendanceAPI} = actions;
  return { 
    adminLectureWiseAttendance: (batch_id,lecture_id,page,search) => dispatch(adminLectureWiseAttendance(batch_id,lecture_id,page,search)),
    adminMarkAttendanceAPI: (lecture_id,user_id,join_time,join_type,is_absent) => dispatch(adminMarkAttendanceAPI(lecture_id,user_id,join_time,join_type,is_absent)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkAttendance);