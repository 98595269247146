import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import basketIcon from '../../public/icons/lessonplanner-basket-icon.svg';
import communicationIcon from '../../public/icons/lessonplanner-communication-icon.svg';
import config from '../../config';
import blanklessonplanThumb from '../../public/icons/blanklessonplan.svg';
import lessonplanThumb from '../../public/icons/amplifiu-lesson-plan-icon.svg';
import lessonplanRubricThumb from '../../public/icons/lessonplan-rubric.svg';
import lessonplanAssessmentThumb from '../../public/icons/lessonplan-assessment.svg';
import lessonplanQuizThumb from '../../public/icons/lessonplan-quiz.svg';
import lessonplanTextlevellerThumb from '../../public/icons/lessonplan-textleveller.svg';
import lessonplanSummariserThumb from '../../public/icons/lessonplan-summariser.svg';
import lessonplanGroupactivityThumb from '../../public/icons/lessonplan-groupactivity.svg';
import lessonplanClassnewsletterThumb from '../../public/icons/lessonplan-classnewsletter.svg';
import lessonplanLoading from '../../public/icons/ajax-loader.gif';
import featureCoimingSoon from '../../public/icons/feature-coming-soon.svg';
import disclaimerIcon from '../../public/icons/disclaimer-icon.svg';
import previewIcon from '../../public/icons/amplifiu-preview-icon.svg';
import editIcon from '../../public/icons/amplifiu-edit-icon.svg';
import makeacopy from '../../public/icons/amplifiu-makeacopy-icon.svg';
import download from '../../public/icons/amplifiu-download-icon.svg';
import trashicon from '../../public/icons/trashicon.svg';
import versionhistoryicon from '../../public/icons/version-history-icon.svg';
import conceptNoteicon from '../../public/icons/amplifiu-concept-note-icon.svg';
import debateicon from '../../public/icons/amplifiu-debate-icon.svg';
import groupDiscussionicon from '../../public/icons/amplifiu-group-discussion-icon.svg';
import projecticon from '../../public/icons/amplifiu-project-icon.svg';
import roleplayicon from '../../public/icons/amplifiu-roleplay-icon.svg';
import unitPlanicon from '../../public/icons/amplifiu-unit-plan-icon.svg';
import collaborateIcon from '../../public/icons/amplifiu-collaborate-welcome.svg';
import collaborateIcon2 from '../../public/icons/amplifiu-collaborate-section2.svg';
import collaborateEventIcon from '../../public/icons/amplifiu-collaborate-events.svg';
import collaborateResourcesIcon from '../../public/icons/amplifiu-collaborate-resources.svg';
import collaborateGetStatedIcon from '../../public/icons/amplifiu-collaborate-get-started.svg';

import collaborateGuidelineIcon1 from '../../public/icons/amplifiu-collaborate-guidelines-icon.svg';
import collaborateGuidelineIcon2 from '../../public/icons/amplifiu-collaborate-guidelines-icon2.svg';
import collaborateGuidelineIcon3 from '../../public/icons/amplifiu-collaborate-guidelines-icon3.svg';
import collaborateGuidelineIcon4 from '../../public/icons/amplifiu-collaborate-guidelines-icon4.svg';

import moment from 'moment';
import 'moment-timezone';
import MobileNotReady from '../../components/error/MobileNotReady';


class CollaborateLessonPlan extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      selected:'',
      token:localStorage.getItem('token'),
      user_id : localStorage.getItem('user_id'),
      lessonPlanDialogOpen:false,
      saveLessonPlanName:'',
      saveLessonPlanClass:'',
      saveLessonPlanSubject:'',
      saveLessonPlanTopic:'',
      saveLessonPlanBoard:'',
      AllListingData:[],
      OrignalAllListingData:{},
      dialogOpen:false,
      previewContent:'',
      loading:false,
      is_search:false,
      comingSoondialogOpen:false,
      iconsloaded:false,
      sortbyValue:'',
      page: 2,
      searchTerm:'',
      confitmationDialogOpen:false,
      saveIdForDelete:'',
      getIDofLastLi: '',
      timer: null,
      advanceSearchDialogOpen:false,
      versionHistoryDialogOpen:false,
      saveVersionhistryId:'',
      selectVersionHistoryID:0,
      toggleViewAll:true,
      saveAdvanceGrade:'',
      saveAdvanceSubject:'',
      saveAdvanceCurriculum:'',
      saveAdvanceDuration:'',
      advancedSearchStarted: false,
      saveclickIndex:'-1',
      popIndex: null,
      facultySidebarHidden_ids:true,
      center_id : '',
      isScrolled:false,
      nameColumnClass: '',
      typeColumnClass: '',
      gradeColumnClass: '',
      modifiedColumnClass: ''




    
      
      
    };
    this.scrollContainer = React.createRef();
    this.scrollMainContainer = React.createRef();
   
  }



  
  componentDidMount() {
    this.getFacultyProfile();
    let getCenterID = localStorage.getItem("center_id");
    let getUserID = localStorage.getItem("user_id");
    let getToken = localStorage.getItem("token");

    if (config?.facultySidebarHidden_ids?.includes(parseInt(localStorage.getItem("center_id")))){
      this.setState({facultySidebarHidden_ids : false});
      document.getElementById("bodyParam").classList.add("lessonPlanPageActive");
    }
  

    if(getToken){
      this.setState({token : getToken});
    }
    if(getUserID){
      this.setState({user_id : getUserID});
    }
    if(getCenterID){
      this.setState({center_id : getCenterID});
      
    }
    
 
    
   
  
    this.handleScroll();


    document.addEventListener("mousedown", this.handleClickOutside);


    if (this.scrollMainContainer.current) {
      this.scrollMainContainer.current.addEventListener('scroll', this.handleDivScroll);
    }

    
  }


 

  

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    if (this.scrollMainContainer.current) {
      this.scrollMainContainer.current.removeEventListener('scroll', this.handleDivScroll);
    }
  }

  handleDivScroll = () => {
    console.log(this.scrollMainContainer.current.scrollTop)
    if (this.scrollMainContainer.current.scrollTop > 10) {
      this.setState({ isScrolled: true });
      document.getElementById("bodyParam").classList.add("scrollActivate");
    } else {
      this.setState({ isScrolled: false });
      document.getElementById("bodyParam").classList.remove("scrollActivate");
    }
  }


  viewContent = (e,content) =>{
    console.log(content);
    this.setState({previewContent : content});
    this.setState({dialogOpen : true});

   }

   dialogClose = () =>{
    this.setState({dialogOpen : false});

   }

    
   comingSoon = () =>{
    this.setState({comingSoondialogOpen : true});
   }

   advanceSearchCancelClick = () =>{
    this.setState({advanceSearchDialogOpen : false});
  }

   comingSoondialogClose = () =>{
    this.setState({comingSoondialogOpen : false});
  }

  createLessonPlan = (type) =>{
    console.log("type",type);
    let {user_id,token} = this.state;
    let sendtoken = btoa(token);
    let sendType = btoa(type);
    let userid = btoa(user_id);
    let lessonplanType = btoa("lessonplan");
    const url = `${config?.editordomainUrl}/q?&type=${sendType}&t=${sendtoken}`;
    window.open(url, '_self');
    // this.setState({lessonPlanDialogOpen : true});
  }





  createLessonPlanDialogClose = () =>{
    this.setState({lessonPlanDialogOpen : false});
  }


  
 
  

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  batches: response.data.batches });
  }

  
  changeName = (e) =>{
    console.log(e.target.value);
    this.setState({saveLessonPlanName : e.target.value});
  }

  changeClassSelect = (e) =>{
    console.log(e.target.value);
    this.setState({saveLessonPlanClass : e.target.value});
  }

  changeSubjectInput = (e) =>{
    console.log(e.target.value);
    this.setState({saveLessonPlanSubject : e.target.value});
  }

  changeTopicInput = (e) =>{
    console.log(e.target.value);
    this.setState({saveLessonPlanTopic : e.target.value});
  }

  changeBoardInput = (e) =>{
    console.log(e.target.value);
    this.setState({saveLessonPlanBoard : e.target.value});
  }


  submitButtonClick = (e) =>{
    let {user_id,saveLessonPlanName,saveLessonPlanClass,saveLessonPlanTopic, saveLessonPlanSubject,saveLessonPlanBoard} = this.state;
    console.log(saveLessonPlanName,saveLessonPlanClass,saveLessonPlanSubject,saveLessonPlanTopic,saveLessonPlanBoard);
    this.createNewLessonPlan()
  }

  
  createNewLessonPlan = async () =>{
    let {user_id,saveLessonPlanName,saveLessonPlanClass,saveLessonPlanTopic, saveLessonPlanSubject,saveLessonPlanBoard} = this.state;
    let form_json = { user_id: user_id, name:saveLessonPlanName, class_level:saveLessonPlanClass, topic:saveLessonPlanTopic,subject:saveLessonPlanSubject,board:saveLessonPlanBoard};
    console.log(form_json);
    const response = await this.props.amplifiuThreadCreation(user_id,form_json);
      if(response !== undefined) {
        console.log("response")
        console.log(response?.data.id);
        let threadid = response?.data.id;
        console.log(threadid);
        const url = `${config?.editordomainUrl}/q?t=${threadid}&user_id=${user_id}`;
        window.open(url, '_blank');
        //window.location.assign(`${config?.editordomainUrl}/q?t=${threadid}&a=asst_OgRC0X5nANK5lYA8385PuEuK&toc_id=${chapter_id}&user_id=${user_id}`)
      }
    
   }

   formatTimestamp(inputDate) {
    const currentDate = new Date();

    const myDate = moment.tz(inputDate, 'Asia/Kolkata').utc().format('YYYY-MM-DD HH:mm:ss');
    const areaDate = moment.utc(myDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss');
    
    const inputDateTime = new Date(areaDate);
    const timeDifference = currentDate - inputDateTime;
 
    // Convert milliseconds to seconds
    const seconds = Math.floor(timeDifference / 1000);
    // Convert seconds to minutes
    const minutes = Math.floor(seconds / 60);
    // Convert minutes to hours
    const hours = Math.floor(minutes / 60);
    // Convert hours to days
    const days = Math.floor(hours / 24);
    // Convert days to months (average 30 days per month)
    const months = Math.floor(days / 30);
 
 
    const formattedDate = `${inputDateTime.getDate()}/${inputDateTime.getMonth() + 1}/${inputDateTime.getFullYear()}`;
    let formattedHours = inputDateTime.getHours();
    const amPm = formattedHours < 12 ? 'AM' : 'PM';
    formattedHours = formattedHours % 12 || 12; // Convert 0 to 12
    const formattedTime = `${formattedHours}:${inputDateTime.getMinutes()} ${amPm}`;
 
    if (seconds < 60) {
        // return  `${formattedDate} ${formattedTime} (Just now)`;
        return  `(Just now)`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
        // return `${formattedDate} ${formattedTime} (${minutes} min ago)`;
    } else if (hours < 24) {
        // return `${formattedDate} ${formattedTime} (${hours} hr ago)`;
        return `${hours} hr ago`;
    } else if (days === 1) {
      // return `${formattedDate} ${formattedTime} (${days} day ago)`;
      return `Yesterday`;
    }else if (days < 30) {
        // return `${formattedDate} ${formattedTime} (${days} day ago)`;
        return `${formattedDate} ${formattedTime}`;
    } else if (months < 12) {
        // return `${formattedDate} ${formattedTime} (${months} month ago)`;
        return `${formattedDate} ${formattedTime}`;
    } else {
        // return `${formattedDate} ${formattedTime} (${hours} hrs ago)`;
        return `${formattedDate} ${formattedTime}`;
    }
}



isElementVisible(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


handleScroll = () =>{
  const viewDiv = document.querySelector('.lastli');
  if (viewDiv) {
    const isVisible = this.isElementVisible(viewDiv);
    if(isVisible){
      let getIDofLastLi = this.state.AllListingData[this.state.AllListingData.length -1]?.openai_userthread_id;
      console.log("getIDofLastLi",getIDofLastLi);
      console.log('Is view div visible:', isVisible, " getIDofLastLi", getIDofLastLi);
      this.setState({getIDofLastLi:getIDofLastLi})
      this.setState((prevState) => {
        // if (prevState.getIDofLastLi !== getIDofLastLi) {
        //   return { getIDofLastLi: getIDofLastLi };
        // } else {
        //   getIDofLastLi = null;
        //   return null;
        // }
      });
      if(getIDofLastLi){
        viewDiv.classList.remove('lastli');
        console.log('Is view');
        this.getLessonPlanListing();
      }
      
    }
    
  }
}




   getLessonPlanListing = async () =>{
    let {user_id,loading,getIDofLastLi,saveAdvanceGrade,saveAdvanceCurriculum,saveAdvanceSubject,saveAdvanceDuration} = this.state;
    this.setState({loading : true})
    let lastId;
    let limit = 15
    //in advanced search
    
    if(saveAdvanceGrade != '' || saveAdvanceCurriculum != '' || saveAdvanceSubject != '' ||  saveAdvanceDuration !== ''){
      
      if(this.state.advancedSearchStarted == false ){
        //first time search 
        lastId = 0
      }
      else{
        lastId = this.state.getIDofLastLi;
      }
    }
    else{
      lastId =  this.state.getIDofLastLi ? this.state.getIDofLastLi : 0
    }

    console.log("advancedSearchStarted1",this.state.advancedSearchStarted)
    
      const response = await this.props.amplifiuFetchThreadDocumentListing(limit,
       lastId,
        user_id,saveAdvanceGrade,saveAdvanceSubject,saveAdvanceCurriculum,saveAdvanceDuration);
      if(response !== undefined) {
        console.log("response listing");
        console.log("getIDofLastLi");
        console.log(getIDofLastLi);
        console.log(response.data);
        const keys = Object.keys(response?.data);
        console.log("old data", this.state.AllListingData)
          let list = keys.map((key) => {
            this.state.OrignalAllListingData[key] = response?.data[key]
            return response?.data[key][0]; // Return the value inside map
          });

          console.log("list");
          console.log(list);
          console.log("OrignalAllListingData",this.state.OrignalAllListingData);
          
          console.log("advancedSearchStarted2",this.state.advancedSearchStarted)

          if(list.length == 0){
            const element = document.getElementById('NoScrollData');
            if(element !== null) {
            element.style.display = "flex"; }
            // document.getElementById('NoScrollData')?.style?.display="flex";
          }
          
          if(saveAdvanceGrade != ''  || saveAdvanceCurriculum != '' || saveAdvanceSubject != '' ||  saveAdvanceDuration !== ''){

            if(this.state.advancedSearchStarted == false){
              this.setState({ AllListingData: [...list] });
              this.setState({advancedSearchStarted : true})
            }
            else{

              this.setState({ AllListingData: [...this.state.AllListingData,...list] });


            }
           
          }else{
            
            if(this.state.AllListingData.length != 0){
              list = [...this.state.AllListingData,...list ]
            }
            
            
   
            this.setState({ AllListingData: [...list] });
          }
          
      
        
        
        console.log("Advance",saveAdvanceGrade,keys.length);


       
        this.setState({loading : false})
        this.setState({ advanceSearchDialogOpen: false});
        
      }
    
   }


   OkButtonClick = () => {
    console.log(this.state.saveIdForDelete);
    this.deleteLessonPlan()
 }

 CancelButtonClick = () => {
  this.setState({confitmationDialogOpen : false});
  this.setState({saveIdForDelete : ''});
}


   deleteLessonPlanClick = (e,id) => {
      console.log(id);
      this.setState({saveIdForDelete : id});
      this.setState({confitmationDialogOpen : true});
   }


   deleteLessonPlan = async () =>{
    console.log("saveid",this.state.saveIdForDelete);
      const response = await this.props.amplifiuSelectActiveInactive(this.state.saveIdForDelete ? this.state.saveIdForDelete :'','Deleted');
      if(response !== undefined) {
        console.log("Delete response listing")
        console.log(response.data);
      }
   }


   

   editLessonPlan = (e,threadid,version_num,gettype) =>{
    let {user_id,token} = this.state;
    let savethreadid = btoa(threadid);
    let versionnumber = btoa(version_num); 
    let userid = btoa(user_id);
    let lessonplan = btoa('lessonplan');
    let sendtoken = btoa(token);
    gettype = btoa(gettype ?? 'lesson Plan');
      // console.log("savethreadid",btoa(savethreadid))
      // console.log("savethreadid",atob(savethreadid))
      // console.log("userid",user_id)
      // console.log("version_num",version_num)
        const url = `${config?.editordomainUrl}/q?t=${sendtoken}&thread=${savethreadid}&v=${versionnumber}&type=${gettype}`;
        window.open(url, '_self');
        //window.location.assign(`${config?.editordomainUrl}/q?t=${threadid}&a=asst_OgRC0X5nANK5lYA8385PuEuK&toc_id=${chapter_id}&user_id=${user_id}`)
  }

  handleSortByChange = (e) => {
    
    const selectedValue = e.target.getAttribute('value');
    console.log(selectedValue);
    // this.setState({ sortbyValue: e.target.value});
    this.setState({ sortbyValue: selectedValue});
};


downloadLessonPlanClick = (e,data) => {
  console.log("htmldata");
  console.log(data);
  if(data){
    this.generateHTMLTOPDF(data);
  }
  
};

generateHTMLTOPDF = async (data) =>{
    let html_data = data;
    console.log("html_data");
    console.log(html_data);
    const response = await this.props.htmlToPDF(html_data);
    if(response !== undefined) {
      console.log(response.data);
      const link = document.createElement('a');
      link.href = response.data;
      link.target = '_blank';
      link.setAttribute('download', 'downloaded_file.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
  
 }


advanceSearchClick = () => {
  setTimeout(()=>{
    this.setState({ advanceSearchDialogOpen: true});
  },300)
  
}

cloneLessonPlan = async (id, threadid, openai_userthread_id) => {
  let {user_id} = this.state;
  let thread_id = threadid;
  let saveid = id;
  let openaiuserthreadid = openai_userthread_id
  const response = await this.props.amplifiuLessonPlanClone(saveid,thread_id,openaiuserthreadid,user_id);
  if(response !== undefined) {
      console.log("hello response");
      console.log(response);
      this.getLessonPlanListing();
    }

}

makeAcopyLessonPlanClick = async(e,id,form_json) => {
  let {user_id} = this.state;
  console.log(id);
  let saveid = id;
  let formjson = form_json;
  if(saveid){
      const response = await this.props.amplifiuThreadCreation(user_id,formjson);
      if(response !== undefined) {
        console.log("response")
        let threadid = response?.data.id;
        let openai_userthread_id = response?.data.openai_userthread;
        console.log("threadid",threadid);
        console.log("openai_userthread_id",openai_userthread_id);
        if(threadid){
          this.cloneLessonPlan(saveid, threadid, openai_userthread_id);
        }
      }
    }
  }

  

versionHistoryLessonPlanClick = (e,thread_id) =>{
  let {user_id} = this.state;
  if(thread_id){
    this.setState({ saveVersionhistryId: thread_id});
    this.setState({ versionHistoryDialogOpen: true});
  }
  
}

versionHistoryDialogBack = () =>{
  this.setState({ selectVersionHistoryID: 0});
  this.setState({ versionHistoryDialogOpen: false});
}


versionHistoryClick = (id) =>{
  setTimeout(()=>{
    this.setState({ selectVersionHistoryID: id});
  },300)
}

toggleViewAll = () =>{
  this.setState({ toggleViewAll: !this.state.toggleViewAll });
}



advanceSearchButtonClick = () => {
  let {user_id,advancedSearchStarted,saveAdvanceGrade,saveAdvanceCurriculum,saveAdvanceSubject,saveAdvanceDuration} = this.state;
  console.log(saveAdvanceGrade,saveAdvanceCurriculum,saveAdvanceSubject,saveAdvanceDuration,advancedSearchStarted);
   setTimeout(()=>{
    this.setState({ getIDofLastLi:0});
    this.setState({ advancedSearchStarted:false});
    this.getLessonPlanListing();
  },300)
  
  
}

changeAdvanceDuration = (e) =>{
  if(e.target.value){
    this.setState({ saveAdvanceDuration: e.target.value});
  }
}

changeAdvanceCurriculum = (e) =>{
  if(e.target.value){
    this.setState({saveAdvanceCurriculum: e.target.value});
  }
}

changeAdvanceGrade = (e) =>{
  if(e.target.value){
    this.setState({ saveAdvanceGrade: e.target.value});
  }
}

handleSearchChange = (e) =>{
  if(e.target.value){
    this.setState({ searchTerm: e.target.value});
  }
}

changeAdvanceSubject = (e) =>{
  if(e.target.value){
    this.setState({ saveAdvanceSubject: e.target.value});
  }
}

clearSearchClick = () => {
  let {user_id,advancedSearchStarted,saveAdvanceGrade,saveAdvanceCurriculum,saveAdvanceSubject,saveAdvanceDuration} = this.state;
  this.setState({ advancedSearchStarted: false});
  this.setState({ getIDofLastLi: 0});
  this.setState({ saveAdvanceGrade: ''});
  this.setState({ saveAdvanceCurriculum: ''});
  this.setState({ saveAdvanceSubject: ''});
  this.setState({ saveAdvanceDuration: ''});
  this.setState({ AllListingData: ''});
  setTimeout(()=>{
    this.getLessonPlanListing();
  },300)
}


selectedFilterGradeChange = (e) => {
  this.setState({ saveAdvanceGrade: ''});
  this.setState({ getIDofLastLi: 0});
  this.setState({ AllListingData: ''});
  setTimeout(()=>{
    this.getLessonPlanListing();
  },300)
}
selectedFilterSubjectChange = (e) => {
  this.setState({ saveAdvanceSubject: ''});
  this.setState({ getIDofLastLi: 0});
  this.setState({ AllListingData: ''});
  setTimeout(()=>{
    this.getLessonPlanListing();
  },300)
}
selectedFilterCurriculumChange = (e) => {
  this.setState({saveAdvanceCurriculum: ''});
  this.setState({ getIDofLastLi: 0});
  this.setState({ AllListingData: ''});
  setTimeout(()=>{
    this.getLessonPlanListing();
  },300)
}
selectedFilterDurationChange = (e) => {
  this.setState({saveAdvanceDuration: ''});
  this.setState({ getIDofLastLi: 0});
  this.setState({ AllListingData: ''});
  setTimeout(()=>{
    this.getLessonPlanListing();
  },300)
}
liCick = (e,index) => {
  console.log("item index",index);
  if(index){ 
    this.setState({saveclickIndex : index})
  }else{
    this.setState({saveclickIndex : ''})
  }
  
}


handleClickOutside = (event) => {
  if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
    
    this.setState({ saveclickIndex: null });
    
  }
  
};


nameColumnClick = () =>{
  this.setState({ gradeColumnClass:'' });
  this.setState({ typeColumnClass:'' });
  this.setState({ modifiedColumnClass:'' });
  this.setState((prevState) => {
    let newClass;
    if (prevState.nameColumnClass === '') {
      newClass = 'asc';
    } else if (prevState.nameColumnClass === 'asc') {
      newClass = 'desc';
    } else {
      newClass = '';
    }
    return { nameColumnClass: newClass };
  }, () => {
    console.log("nameColumnClass", this.state.nameColumnClass); 
  });
}

typeColumnClick = () =>{
  this.setState({ gradeColumnClass:'' });
  this.setState({ nameColumnClass:'' });
  this.setState({ modifiedColumnClass:'' });
  this.setState((prevState) => {
    let newClass;
    if (prevState.typeColumnClass === '') {
      newClass = 'asc';
    } else if (prevState.typeColumnClass === 'asc') {
      newClass = 'desc';
    } else {
      newClass = '';
    }
    return { typeColumnClass: newClass };
  }, () => {
    console.log("typeColumnClass", this.state.typeColumnClass); 
  });
}

gradeColumnClick = () =>{
  this.setState({ nameColumnClass:'' });
  this.setState({ typeColumnClass:'' });
  this.setState({ modifiedColumnClass:'' });
  this.setState((prevState) => {
    let newClass;
    if (prevState.gradeColumnClass === '') {
      newClass = 'asc';
    } else if (prevState.gradeColumnClass === 'asc') {
      newClass = 'desc';
    } else {
      newClass = '';
    }
    return { gradeColumnClass: newClass };
  }, () => {
    console.log("gradeColumnClass", this.state.gradeColumnClass); 
  });
}

modifiedColumnClick = () =>{
  this.setState({ nameColumnClass:'' });
  this.setState({ typeColumnClass:'' });
  this.setState({ gradeColumnClass:'' });
  this.setState((prevState) => {
    let newClass;
    if (prevState.modifiedColumnClass === '') {
      newClass = 'asc';
    } else if (prevState.modifiedColumnClass === 'asc') {
      newClass = 'desc';
    } else {
      newClass = '';
    }
    return { modifiedColumnClass: newClass };
  }, () => {
    console.log("modifiedColumnClass", this.state.modifiedColumnClass); 
  });
}
    
   
  render() {
    const isEnabled = this.state.saveLessonPlanName != '' && this.state.saveLessonPlanClass != '' && this.state.saveLessonPlanSubject != '' && this.state.saveLessonPlanTopic != '' && this.state.saveLessonPlanBoard != '';
    const isEnabled2 = this.state.saveAdvanceGrade != '' || this.state.saveAdvanceSubject != '' || this.state.saveAdvanceCurriculum != '' || this.state.saveAdvanceDuration != ''
   
    return (
      <>
        <MobileNotReady/>
      <div id="appBody" onScroll={() =>{
        clearTimeout(this.state.timer);
        this.state.timer = 
        setTimeout(()=>{
          this.handleScroll();
        },300)

      }}  className={this.state.lessonPlanDialogOpen || this.state.dialogOpen || this.state.comingSoondialogOpen || this.state.confitmationDialogOpen || this.state.advanceSearchDialogOpen || this.state.versionHistoryDialogOpen ? "app-body IndexChange" :"app-body"}>
        <ReactNotifications/>
        <div className="app-div collaboratePage" ref={this.scrollMainContainer}>
          <Helmet>
            <title>Amplifiu Learn</title>
          </Helmet>
          {/* <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/> */}


       


            <div className='lessonPlanWrapper'>
              <div className='lessonPlanContainer'> 
                
               <div className='tabsContentWrapper'>
                  {/* <div title='View all' className="viewAllToggle"><a href="/faculty/explore-all">Explore All<i className='fa fa-chevron-left'></i></a></div> */}                   
                  
                  <div className='allTabData'> 

                      <div className='collaborateWrapper'>

                          <div className='welcomeSection'>
                                <div className='block'>
                                  <h2>Welcome <span>👋</span></h2>
                                  <p>Welcome to AmplifiU Community — We’re glad you enrolled in one of our cohorts, and we want to give you a warm greeting to our community!</p>
                                </div>
                                <div className='block'><img src={collaborateIcon}/></div>

                          </div>

                          <div className='welcomeSection'>
                                <div className='block'><img src={collaborateIcon2}/></div>
                                <div className='block'>
                                  <p>In here, you will be able to find everything, from discussions, to resources, and even your cohort assignments so you can have everything in one place.</p>
                                </div>
                          </div>

                          <div className='gettingStartedWrapper'>
                            <div className='gettingStartedBlock'>
                                <h2>Getting started</h2>
                                <p>We have dozens of different resources and content types, so you may find yourself a little confused at first, but no worries, you can get started here</p>
                            </div>
                            <ol>
                              <li>
                                <i><img src={collaborateGetStatedIcon}/></i>
                                <h2>Getting started</h2>
                                <p>We have dozens of different resources and content types, so you may find yourself a little confused at first, but no worries, you can get started here</p>
                              </li>
                              <li>
                              <i><img src={collaborateResourcesIcon}/></i>
                                <h2>Resources</h2>
                                <p>A dedicated space for members to discover and share resources for various subjects and interests among community.</p>
                              </li>
                              <li>
                              <i><img src={collaborateEventIcon}/></i>
                                <h2>Events</h2>
                                <p>Join one of our monthly events to share and learn knowledge about all things education, AI, and technology.</p>
                              </li>
                            </ol>
                          </div>


                          <div className='communityGuidelinesWrapper'>
                            <div className='gettingStartedBlock'>
                                <h2>Community guidelines</h2>
                                <p>While we prefer not to use the term 'rules,' please consider these guidelines as recommendations for fostering a positive, respectful, and engaging communication environment within our community.</p>
                            </div>
                            <ol>
                              <li>
                                <i><img src={collaborateGuidelineIcon1}/></i>
                                <h2>1. Be kind and respectful</h2>
                                <p>We encourage members to interact with kindness, empathy, and respectful language.</p>
                              </li>
                              <li>
                              <i><img src={collaborateGuidelineIcon2}/></i>
                                <h2>2. No SPAM, please</h2>
                                <p>No one likes SPAM, so please avoid sharing links repeatedly or you may be restricted.</p>
                              </li>
                              <li>
                              <i><img src={collaborateGuidelineIcon3}/></i>
                                <h2>3. Sharing is caring</h2>
                                <p>Found something interesting? Share it with the community. Remember sharing is caring.</p>
                              </li>
                              <li>
                                <i><img src={collaborateGuidelineIcon4}/></i>
                                <h2>4. Stay on topic</h2>
                                <p>We love movies and TV shows too, but for the sake of keeping order, please stay on topic.</p>
                              </li>
                            </ol>
                          </div>



                      </div>


                  </div>           

               </div>

             
                
               <div className='disclaimer'>
                  <span title='AI Disclaimer'><i><img src={disclaimerIcon}/></i>AI Disclaimer 
                  <div className='disclaimerInfoPop'>
                  AmplifiU generates great educational material design but isn't a replacement for educator expertise. It's important to recognise AI can introduce biases or errors, so human insight is essential to ensure content is accurate and tailored to your students’ unique cultural and linguistic needs.
                  </div> 
                  </span>
                </div>

              </div> 
             
            </div>
          
           
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,amplifiuThreadCreation,amplifiuFetchThreadDocumentListing,amplifiuSelectActiveInactive,htmlToPDF,amplifiuLessonPlanClone} = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    amplifiuThreadCreation: (user_id,form_json) => dispatch(amplifiuThreadCreation(user_id,form_json)),
    amplifiuFetchThreadDocumentListing: (limit,last_id,user_id,grade,subject,curriculum,duration) => dispatch(amplifiuFetchThreadDocumentListing(limit,last_id,user_id,grade,subject,curriculum,duration)),
    amplifiuSelectActiveInactive: (id,status) => dispatch(amplifiuSelectActiveInactive(id,status)),
    htmlToPDF: (html_data) => dispatch(htmlToPDF(html_data)),
    amplifiuLessonPlanClone: (id,thread_id,openai_userthread_id,user_id) => dispatch(amplifiuLessonPlanClone(id,thread_id,openai_userthread_id,user_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollaborateLessonPlan);