import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import common from '../../common';
import axios from 'axios';
import { store } from 'react-notifications-component';
import learnfestSessionsThumb1 from '../../public/images/learnfest-sessions-thumb1.jpg'
import learnfestSessionsThumb2 from '../../public/images/learnfest-sessions-thumb2.jpg'
import learnfestSessionsThumb3 from '../../public/images/learnfest-sessions-thumb3.jpg'
import learnfestSessionsThumb4 from '../../public/images/learnfest-sessions-thumb4.jpg'
import learnfestSessionsThumb5 from '../../public/images/learnfest-sessions-thumb5.jpg'
import learnfestSessionsThumb6 from '../../public/images/learnfest-sessions-thumb6.jpg'
import learnfestSessionsThumb7 from '../../public/images/learnfest-sessions-thumb7.jpg'
import learnfestSessionsThumb8 from '../../public/images/learnfest-sessions-thumb8.jpg'
import learnfestSessionsThumb9 from '../../public/images/learnfest-sessions-thumb9.jpg'
import learnfestSessionsThumb10 from '../../public/images/learnfest-sessions-thumb10.jpg'
import learnfestSessionsThumb11 from '../../public/images/learnfest-sessions-thumb11.jpg'
import activateUserBanner from '../../public/images/activate-user-banner.png'
class LearnfestSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      

  }
    
  }
    
  componentDidMount(){
    
  }


  
   

   
	render() {
    return (
      <>
      <Helmet>
      <title>Learnfest Sessions</title>
    </Helmet>
    <div class="app-body" id="appBody">
     <div className='learnfestWrapper'>
      <div className='learnfestContainer'>
        <div className='headingTitle'>Learnfest Event Schedule</div>
        <ol>
            <li>
              <div className='cardWrapper'>
                <div className='cardTop'>
                  <div className='cardThumb'><img src={learnfestSessionsThumb1}/></div>
                  <div className='cardContent'>
                      <div className='cardTitle'>New Age Bloom's Taxonomy</div>
                      <div className='cardTime'>09.00 AM - 09.45 AM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                  </div>
                </div>
                 <div className='cardBy'>By - Vasundhara Kaul</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
                <div className='cardTop'>
                <div className='cardThumb'><img src={learnfestSessionsThumb2}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Integrating Applied Drama in Curriculum</div>
                      <div className='cardTime'>10.00 AM - 10.45 AM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By Chetna Mehrotra,Roshan Karkera, & Lini Kapse </div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
              <div className='cardTop'>
              <div className='cardThumb'><img src={learnfestSessionsThumb3}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Become an EdTech Savvy Teacher</div>
                      <div className='cardTime'>11.00 AM - 11.45 AM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Sarika Kaushal</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
                 <div className='cardTop'>
                 <div className='cardThumb'><img src={learnfestSessionsThumb4}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>The Pedagogy of Experiential Learning</div>
                      <div className='cardTime'>12.00 PM - 12.45 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Aishwariya Taneja</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
               <div className='cardTop'>
               <div className='cardThumb'><img src={learnfestSessionsThumb5}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Implementation of NEP in the Classroom</div>
                      <div className='cardTime'>01.00 PM - 01.45 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Mathews Thomas</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
              <div className='cardTop'>
              <div className='cardThumb'><img src={learnfestSessionsThumb6}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Helping Learners Learn How To Learn-Strategies</div>
                      <div className='cardTime'>02.00 PM - 02.45 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Ruchi Sengar</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
              <div className='cardTop'>
              <div className='cardThumb'><img src={learnfestSessionsThumb7}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>360 Degree Report Card Building</div>
                      <div className='cardTime'>03.00 PM - 03.45 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Navinder Ruprai</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
              <div className='cardTop'>
              <div className='cardThumb'><img src={learnfestSessionsThumb8}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Gamification in Teaching and Learning</div>
                      <div className='cardTime'>04.00 PM - 04.45 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Dr. Narjeet Kaur</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
              <div className='cardTop'>
              <div className='cardThumb'><img src={learnfestSessionsThumb9}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Augmented Reality For Classrooms with 3D Bear</div>
                      <div className='cardTime'>05.00 PM - 05.45 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Shalini Chauhan</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
              <div className='cardTop'>
              <div className='cardThumb'><img src={learnfestSessionsThumb10}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Pedagogical Power of Storytelling</div>
                      <div className='cardTime'>06.00 PM - 06.45 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Rituparna Ghosh</div>
              </div>
            </li>
            <li>
              <div className='cardWrapper'>
              <div className='cardTop'>
              <div className='cardThumb'><img src={learnfestSessionsThumb11}/></div>
                 <div className='cardContent'>
                      <div className='cardTitle'>Self-Care & Compassion for Teachers' Well-Being</div>
                      <div className='cardTime'>07.00 PM - 07.30 PM</div>
                      <div className='joinnow'><a href="https://www.youtube.com/watch?v=NYfgFdEUM0g" target="_blank">Join Now</a></div>
                 </div>
                 </div>
                 <div className='cardBy'>By - Debarati Basak</div>
              </div>
            </li>
        </ol>   

      </div>
     </div>
    </div>
    </>
   

    
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const {createLeadUser,resetPasswordActivateUser} = actions;
  return {
    createLeadUser: (secret) => dispatch(createLeadUser(secret)),
   
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnfestSessions);