import React, { Component } from 'react';
import { connect } from 'react-redux';

import actions from '../../redux/actions';
import './styles.css';
import './languageStyle.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.match?.params?.user,
      password: '0z1vfz8aau14nt5ipzdmc_',
      hide: true
    }
  }

  componentDidMount() {
    this.onSubmit();
  }

  onSubmit = async () => {
    const { username, password } = this.state;
    await this.props.userLogin(username, password);
  }

  render() {
    return (
      <div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userLogin } = actions;
  return {
    userLogin: (username, password) => dispatch(userLogin(username, password)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);