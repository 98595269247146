import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import Popup from 'reactjs-popup';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import config from '../../config.js';
// import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
// import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import Admin from '../../public/images/contacts-icon.png';
import WWW from '../../public/icons/web.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
// import InfiniteScroll from "react-infinite-scroll-component";
//import 'react-calendar/dist/Calendar.css';

const lec_modes = {
  2: 'Live',
  0: 'Classroom',
  1: 'Online'
}

class StartTimer extends Component{
  constructor(props){
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    //this.second = 1000;
    //this.minute = this.second * 60;
    //this.hour = this.minute * 60;
    //this.postQuestions = []
    this.state = {
      //currentTime: props.startTime,
      differenceTime:this.props.count
    }
  }
  
  componentDidMount(){
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {
          
    let end = new Date();
    this.distance =  this.startTime - end.getTime();

    if(this.distance > 0)
      this.props.onChange(this.distance,this.state.differenceTime)
  } 

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken *1000);
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? {color: '#ec6972'} : {}}>
       
      </span>
    )
  }
}

class FacultySessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lectures: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      open5:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      id:0,
      classs: '',
      batch: 0,
      open_url:null,
      getUrl:false,
      classes: [],
      batches: [],
      batchesFilter: [],
      subjects: [],
      subject:0,
      subjectsFilter: [],
      is_recent:0,
      id:0,
      is_view_recording:0,
      current_date_records:[],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      username: localStorage.getItem("username"),
      name: localStorage.getItem("name"),
      user_id: localStorage.getItem("user_id"),
      encrypted_url:null,
      currentLectureURL:'',
      is_attendanceURL:false,
      record_url:''
      
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date});
   this.getCurrentDate();
   this.getFacultyProfile();
   this.getCurrentDate();
   this.getFacultyLectureList(0,0,date,15,'forward',true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false })
    }
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, color: Object.fromEntries(
                  response.data.subjects.map(e => [e.id, e.background_code])
                ), classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  classs: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0]?.class_id,
                  subjectsFilter: response.data.subjects.filter(subject => subject.class_id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batchesFilter: response.data.batches.filter(batch => batch.classs?.id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batches: response.data.batches });
  }

  getFacultyLectureList = async (subject_id,batch_id,date,days,direction,if_loading) => {
    const response = await this.props.getFacultyLectureList(subject_id,batch_id,date,days,direction,if_loading);
    if(response){
      this.setState({lectures:response.data.lectures?response.data.lectures:"", loading: false,countBottom:1,id:response.data.lectures?response.data?.lectures[0]?.id:"NA"})
      this.ifRecordsExists();

      if(!response.data.lectures.length){

        this.setState({id:"NA"});
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');
        
        this.getFacultyLectureListOnBackward(this.state.subject,this.state.batch,currentDate,15,'backward',false);
      }
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getFacultyLectureListOnForward = async (subject_id,batch_id,date,days,direction,if_loading) => {
    const response= await this.props.getFacultyLectureList(subject_id,batch_id,date,days,direction,if_loading);
    if(response){
      this.setState({
        lectures: this.state.lectures?this.state.lectures.concat(response.data.lectures):response.data.lectures,
        loading: false,countBottom:1
      });
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getFacultyLectureListOnBackward = async (subject_id,batch_id,date,days,direction,if_loading) => {
    const response= await this.props.getFacultyLectureList(subject_id,batch_id,date,days,direction,if_loading);
    if(response){
      this.setState({
        lectures: response.data.lectures?response.data.lectures.concat(this.state.lectures):this.state.lectures,
        loading: false,countTop:1
      });
      this.ifRecordsExists();
      if(this.state.id)
        this.handleScrollPos();
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      this.state.mlist[d.getMonth()]
    ];
    return date;
  }

  onChange = value => {
    var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');


    this.setState({ hasMore: false,countTop:0,current_date:calendarDate,lectures:[]});
    this.setState({open:false})
    
    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });

    this.setState({start_date:date,loading:true,is_recent:1})
    this.setState({ hasMore: false,countTop:0,current_date:calendarDate});

    this.getFacultyLectureList(this.state.subject,this.state.batch,date,15,'forward',true);
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date,is_recent:0,loading:true,countTop:0,lectures:[],subject:0,batch:0})
   this.getCurrentDate();
   this.getFacultyLectureList(0,0,date,15,'forward',true);
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({current_date:date,date:currentDate});
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  openCalendar=()=>{
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();

    if(calendarMonth<currentMonth){
      document.getElementsByClassName("react-calendar__navigation__next-button")[0].click();
    }else if(calendarMonth>currentMonth){
      document.getElementsByClassName("react-calendar__navigation__prev-button")[0].click();
    }
    
    if(this.state.open===true)
      this.setState({open:false})
    if(this.state.open===false)
      this.setState({open:true})
  } 

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date,is_recent:0,loading:true,countTop:0,lectures:[],subject:0,batch:0})
   this.getCurrentDate();
   this.getFacultyLectureList(0,0,date,15,'forward',true);
  }

  handleScroll = (e) => {

    var scrolltop=[];
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('list-div-x2');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.lectures && this.state.lectures.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){

          this.setState({current_date:this.getTime(test.start_date_time)[4]});
        }
      });
    }

    const top = e.target.scrollTop===0;
    if(top){
      //this.setState({"loading":true})
      if(this.state.countTop===0){

        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getFacultyLectureListOnBackward(this.state.subject,this.state.batch,currentDate,15,'backward',false);
      }
    }
  }

  ifRecordsExists = () =>{
    var val=this.state.start_date;
    const month=this.state.mlist;

    var d = new Date(val);
    var arr=[];
    var map = {};
    var temparray=new Array();

    for(let i=d.getMonth(); i<12;i++){

      let contentDate = [
        d.getFullYear(),
        ('0' + (i+1)).slice(-2)
      ].join('-');

      this.state.lectures && this.state.lectures.some(function(test,index) {
        
        if(i <= new Date(test.start_date_time).getMonth()){
          
          map[month[i]]=new Date(contentDate).getMonth() === new Date(test.start_date_time).getMonth()
                        && new Date(contentDate).getFullYear() === new Date(test.start_date_time).getFullYear()?1:0
          let obj ={"month":month[i],"status":map[month[i]],"year":"2020"}            
          temparray.push(obj);
          return true;
        }
      });
    }
    this.setState({current_date_records:temparray})
  }

  saveQuestionTime = (time_taken,difference) => {
   if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) {
    setTimeout(function(){ window.location.reload(true) },60000);
   }
  }
  
  onClassChange = (e) => {
    if(e.target.value !== '') {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classs: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })
    }
    else {
      this.setState({ subjectsFilter: this.state.subjects, subject: 0,
                        batchesFilter: this.state.batches, batch: 0 });
    }
    this.setState({is_recent:1});
  }

  onSubjectChange = (e) => {
    this.setState({ subject:parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getFacultyLectureList(parseInt(e.target.value),this.state.batch,this.state.start_date,15,'forward',true);
    this.setState({is_recent:1});
  }

  onSectionChange = (e) => {
    this.setState({ batch: parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getFacultyLectureList(this.state.subject,parseInt(e.target.value),this.state.start_date,15,'forward',true);
    this.setState({is_recent:1});
  }

  createContent = async (id) => {
    this.setState({loading :false})
    if(!id || isNaN(parseInt(id))) {
      this.setState({open5 : true,loading:false,encrypted_url:''})
      document.getElementsByClassName("header2")[0].style.position="unset";
      return ;
    }
    const response = await this.props.encryption(this.state.username)
    
    if(response != undefined){
      this.setState({encrypted_url: response.data?.encrypted_text+'&class_id='+parseInt(id),open5 :false ,loading:false})
      //document.getElementById("appBody").style.position="unset";
      //document.getElementsByClassName("header2")[0].style.position="unset";

      var hiddenElement = document.createElement('a');
      hiddenElement.href = config.liveClassContentCreationUrl+response.data?.encrypted_text+'&class_id='+parseInt(id);
      hiddenElement.target = '_blank';
      //hiddenElement.download = 'usersLog-'+dateObj.toISOString()+'.csv';
      hiddenElement.click();
    }
  }

  close5 =() => {
    this.setState({open5 :false})
    document.getElementsByClassName("header2")[0].style.position="fixed";
  }

  getURL =(url) =>{
    this.setState({getUrl:true,open_url:url})
    document.getElementById("appBody").style.zIndex=11;
    document.getElementById("appBody").style.backgroundColor="transparent";
    document.getElementById("bodyParam").style.backgroundColor="transparent";
  }

  closeUrl = () => {
    this.setState({getUrl:false})
    document.getElementById("appBody").style.zIndex="";
    document.getElementById("appBody").style.backgroundColor="";
    document.getElementById("bodyParam").style.backgroundColor="";
  }

  onSchedule = (val,id) => {
    this.timer = setInterval(()=>this.scheduledOn(new Date(val),id), 1000)
  }

  scheduledOn = (val,due,id) =>{
    let dt1 = new Date();
    let currentTime='';
    var diff = val.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    diff = Math.round(diff);
    if(diff <= 0 ) {
      currentTime = val.getTime();
      this.timer = setInterval(()=>this.scheduledOn1(new Date() ,currentTime,id), 1000)
      //setTimeout(function(){  window.location.reload(true) },60000);
    }
    //console.log("onsched"+diff)
  }

  scheduledOn1 = (val1,currentTime,id) =>{
    var diff = val1.getTime()/(1000 * 60) - currentTime/(1000 * 60);
    diff = Math.round(diff);
    if(diff >= 15 ) {
      clearInterval(this.timer);
      this.setState({is_view_recording: 1});
      clearInterval(this.timer);
      //setTimeout(function(){  window.location.reload(true) },60000);
    }
    //console.log("onsched7878"+diff)
  }

  bbbJoinLiveClass = async (etc,session) =>{

    const {name,user_id} = this.state

    let etcObject = JSON.parse(etc);

    console.log(session)

    const response = await this.props.bbbJoinLiveClass(session,name,etcObject.moderatorPasswordBBB,user_id)
     
    if(response != undefined){
      window.open(response.data?.liveClassUrl, '_blank').focus();
    }
  }
  // zoomAttendance = (faculty_id,room,lectureURL) =>{
  //   let facultyID = faculty_id
  //   let roomID = room
  //   if(facultyID,roomID,lectureURL){
  //     this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
  //   }
    
  // }

  zoomAttendance = async(faculty_id,room,lectureURL,lecture_id) =>{
    let facultyID = faculty_id
    let roomID = room
    if(facultyID,roomID,lectureURL){
      this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
    }
    //let roomID = room
    let lectureID = lecture_id
    console.log("lectureID",lectureID);

    if(lectureID){
      const response = await this.props.facultyZoomCreate(lectureID);
      console.log("response",response)
      if(response !== undefined || response?.status == 1){
        let lectureURL = response.data.url;
        let roomID = response.data.meeting_id;
        console.log("lectureURLdddd",lectureURL)
        console.log("roomID",roomID)
        if(facultyID,roomID,lectureURL){
          this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
        }
      }
    }

  }
 

  zoomAttendanceAPI = async (faculty_id,room,lectureURL) =>{
    this.setState({currentLectureURL:lectureURL});
    const response = await this.props.callFacultyzoomAttendanceAPI(faculty_id,room);
  
      this.setState({is_attendanceURL:false});
      console.log("faculty_id", faculty_id);
      console.log("room", room);
      console.log("currentLectureURL", this.state.currentLectureURL);
      window.open(this.state.currentLectureURL,"_blank");
   

   }
  
   closeIframe = () =>{
    let {is_attendanceURL} = this.state;
    this.setState({is_attendanceURL:false})
   }

  render() {
    const { value } = this.state.start_date;
    const {encrypted_url,loading} =this.state;
    if(loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    return (
      <div  id="appBody" onScroll={this.handleScroll} className={this.state.is_attendanceURL ? "app-body zIndexchange" : 'app-body'}>

        <div className={this.state.is_attendanceURL ? "IFrameDialogWrapper open" : 'IFrameDialogWrapper'}>
          <div className="IFrameDialogContainer">
            <a href="javascript:void(0)" onClick={this.closeIframe} title="close" className="iframeClose">X</a>
            <iframe name="iframe_a" src={this.state.currentLectureURL}>

            </iframe>
          </div>
        </div>

        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="content-create-list">
          <span className="close" onClick={this.close5}>&#x2715;</span>
          {encrypted_url ? <iframe src={"https://learningoxygen.com/login?secret="+encrypted_url} name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe> :
            <div className="adminContact"><img src={Admin} alt="admin"/><div>!! Contact to Administrator !!</div></div>
          }
        </Popup>

       

        <ReactNotifications/>
        
        <div className="app-div">
          <Helmet>
            <title>Lectures - Faculty</title>
          </Helmet>
          <Popup open={this.state.getUrl} closeOnDocumentClick onClose={this.closeUrl} className="content-geturl">
            <span className="close" onClick={this.closeUrl}>&#x2715;</span>
            <div className="url-child1">GET URL</div>
            <div className="url-child2">{this.state?.open_url}</div>
          </Popup>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="page-header-faculty">
          <div className="page-header">
              <div className="react-calendar-date" onClick={this.openCalendar}
                ref={this.wrapperRef}>
                {this.state.current_date}<br/>
                <img src={Calendar2} alt="cal"/>
              </div>
              <div className="page-header-right">
                <select onChange={this.onClassChange} defaultValue={parseInt(this.state.classs)}>
                  {this.state.classes.map(classs => 
                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                      {classs.class_name}
                    </option>
                  )}
                </select>
                <select onChange={this.onSectionChange} value={parseInt(this.state.batch)}>
                  <option value={parseInt(0)}>
                  {this.props.saveLanguageWiseData.select_section}
                  </option>
                  {this.state.batchesFilter.map(batch => 
                    <option value={parseInt(batch.id)} key={batch.id}>
                      {batch.label}
                    </option>
                  )}
                </select>
                <select onChange={this.onSubjectChange} value={parseInt(this.state.subject)}>
                  <option value={parseInt(0)}>
                  {this.props.saveLanguageWiseData.select_subject}
                  </option>
                  {this.state.subjectsFilter.map(subject =>
                    <option value={parseInt(subject.id)} key={subject.id}>
                      {subject.label}
                    </option>
                  )}
                </select>
              </div>
              </div>
              {this.state.is_recent?
                <div className="back-to-recent-faculty" onClick={this.switchToRecent}>
                  Today
                </div>:""
              }
            </div>
            <div className="react-calendar1" Style={this.state.open?"display:block":"display:none"}
              ref={this.wrapperRef}>
              <Calendar
                onChange={this.onChange}
                value={value}
              />
            </div>
            {!this.state.loading && <div className="list-sticky">
           { this.state.lectures && this.state.lectures.length <=2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}

              {this.state.lectures && !this.state.lectures.length && <Empty/>}
              <div className="list-content">
                {this.state.current_date_records.map((data,index)=>
                  <div>
                    { data.status==0 ? 
                      <div><div className="dash-test-first">{data.month} {data.year}</div><div className="dash-test-no-found">No Lectures Planned</div></div>:""}
                  </div>
                )}

                
                {this.state.lectures && this.state.lectures.map((lecture,index) =>
                  <div className="list-div-x2" id={lecture.id}>
                    {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime} 
                     count={0}/>}
                     {this.onSchedule(lecture.end_date_time,lecture.id)}
                    <div className="dash-test-first">
                      {index === 0 ? <>
                        <span>{this.getTime(lecture.start_date_time)[0]} {this.getTime(lecture.start_date_time)[5]} </span>
                        <span>{this.getTime(lecture.start_date_time)[1]}</span>
                      </> : this.checkDateTime(lecture.start_date_time, 
                        this.state.lectures[index - 1].start_date_time) ? null : <>
                        <span>{this.getTime(lecture.start_date_time)[0]} {this.getTime(lecture.start_date_time)[5]}</span>
                        <span>{this.getTime(lecture.start_date_time)[1]}</span>
                      </>}
                    </div>
                    <div className="dash-test" id={lecture.id==this.state.id?"conter":""} key={index} 
                      Style={this.state.date==this.getTime(lecture.start_date_time)[2] && this.getTime(lecture.start_date_time)[3]?"background-color:#fff6e4":""}>
                      <div>
                        <div Style="display: inline-block;">
                          <span Style="font-size:17px">{lecture.title}</span><br/><br/>
                          <span style={{color: '#777'}}>
                            {this.getTimeAMPMFormat(new Date(lecture.start_date_time))} | {lecture.duration_hrs} Hrs
                          </span>
                        </div>
                        <div className="lec-mode-dash">
                          {lec_modes[lecture.mode]}
                        </div>
                      </div>
                      <div className="dash-test-bottom">
                        <div>
                          <span>{lecture.classs} | {lecture.batch} | {lecture.subject}</span>
                          {lecture.mode === 0 ? <span>Location: {lecture.url}</span> : null}
                        </div>
                        {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label 
                          !== undefined ? lecture.action_label === "Join Class" &&  lecture.liveClass_server === 3 &&  <a onClick={() => this.zoomAttendance(lecture.faculty_user_id, lecture.room, lecture.url, lecture.id)} target="_blank">
                          <button>{lecture.action_label}</button>
                        </a> :''}
                        {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label 
                          !== undefined ? lecture.action_label === "Join Class"  && !lecture.record_available  &&  lecture.liveClass_server !== 3
                          ? <span Style="margin-left: 21px;"><a href={lecture.url} target="_blank">
                          <button>{lecture.action_label}</button>
                        </a> <span id="view-recording">
                          <button onClick={() => this.createContent(lecture.room)}>Schedule</button>
                        </span></span>: <span id="action-reverse">
                          {lecture.is_delivered && lecture.record_available && !this.state.is_view_recording && lecture.action_label}
                          
                          {lecture.record_available && this.state.is_view_recording && <span id="view-recording"><a href={lecture.record_url} target="_blank">
                            <button>View Recording</button>
                          </a></span>}
                          {lecture.action_label=="Scheduled" && <span id="view-recording">
                          <button onClick={() => this.createContent(lecture.room)}>Schedule</button>
                        </span>}
                        </span> : null}
                        <span title="get Url" className="get-url" onClick={() => this.getURL(lecture?.open_url)}><img src={WWW} alt="get url"/></span>
                      </div>
                    </div>
                  </div> 
                 
                )}
                  { this.state.lectures && this.state.lectures.length>2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
                
                 </div>
            </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getFacultyLectureList,encryption ,callFacultyzoomAttendanceAPI,bbbJoinLiveClass,facultyZoomCreate} = actions;
  return {
    encryption : (username) => dispatch(encryption(username)),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyLectureList: (subject_id,batch_id,date,days,direction,if_loading) => dispatch(getFacultyLectureList(subject_id,batch_id,date,days,direction,if_loading)),
    callFacultyzoomAttendanceAPI : (faculty_id,room) => dispatch(callFacultyzoomAttendanceAPI(faculty_id,room)),
    bbbJoinLiveClass:(session,name,meetingPassword,userId) => dispatch(bbbJoinLiveClass(session,name,meetingPassword,userId)),
    facultyZoomCreate : (lecture_id) => dispatch(facultyZoomCreate(lecture_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultySessions);