import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import basketIcon from '../../public/icons/lessonplanner-basket-icon.svg';
import communicationIcon from '../../public/icons/lessonplanner-communication-icon.svg';
import config from '../../config';
import blanklessonplanThumb from '../../public/icons/amp_blanklessonplan.svg';
import lessonplanThumb from '../../public/icons/amplifiu-lesson-plan-icon.svg';
import lessonplanRubricThumb from '../../public/icons/lessonplan-rubric.svg';
import lessonplanAssessmentThumb from '../../public/icons/lessonplan-assessment.svg';
import lessonplanQuizThumb from '../../public/icons/lessonplan-quiz.svg';
import lessonplanTextlevellerThumb from '../../public/icons/lessonplan-textleveller.svg';
import lessonplanSummariserThumb from '../../public/icons/lessonplan-summariser.svg';
import lessonplanGroupactivityThumb from '../../public/icons/lessonplan-groupactivity.svg';
import lessonplanClassnewsletterThumb from '../../public/icons/lessonplan-classnewsletter.svg';
import lessonplanLoading from '../../public/icons/ajax-loader.gif';
import featureCoimingSoon from '../../public/icons/feature-coming-soon.svg';
import disclaimerIcon from '../../public/icons/disclaimer-icon.svg';
import previewIcon from '../../public/icons/amplifiu-preview-icon.svg';
import editIcon from '../../public/icons/amplifiu-edit-icon.svg';
import makeacopy from '../../public/icons/amplifiu-makeacopy-icon.svg';
import download from '../../public/icons/amplifiu-download-icon.svg';
import trashicon from '../../public/icons/trashicon.svg';
import versionhistoryicon from '../../public/icons/version-history-icon.svg';
import conceptNoteicon from '../../public/icons/amplifiu-concept-note-icon.svg';
import debateicon from '../../public/icons/amplifiu-debate-icon.svg';
import groupDiscussionicon from '../../public/icons/amplifiu-group-discussion-icon.svg';
import projecticon from '../../public/icons/amplifiu-project-icon.svg';
import roleplayicon from '../../public/icons/amplifiu-roleplay-icon.svg';
import unitPlanicon from '../../public/icons/amplifiu-unit-plan-icon.svg';
import sortArrowDown from '../../public/icons/amplifiu-sort-arrow-down.svg';
import sortArrowUp from '../../public/icons/amplifiu-sort-arrow-up.svg';
import thinkPairShareIcon from '../../public/icons/lessonplan-think-pare-share-icon.svg';
import caseStudyIcon from '../../public/icons/lessonplan-case-study-icon.svg';
import exploreAllIcon from '../../public/icons/amplifiu-explore-all-icon.svg';
import MobileNotReady from '../../components/error/MobileNotReady';
import verticalDots from '../../public/icons/amplifiu-vertical-dits.svg';

import moment from 'moment';
import 'moment-timezone';


class LessonPlan extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      selected: '',
      token: localStorage.getItem('token'),
      user_id: localStorage.getItem('user_id'),
      lessonPlanDialogOpen: false,
      saveLessonPlanName: '',
      saveLessonPlanClass: '',
      saveLessonPlanSubject: '',
      saveLessonPlanTopic: '',
      saveLessonPlanBoard: '',
      AllListingData: [],
      OrignalAllListingData: {},
      dialogOpen: false,
      previewContent: '',
      loading: false,
      is_search: false,
      comingSoondialogOpen: false,
      iconsloaded: false,

      page: 2,
      searchTerm: '',
      confitmationDialogOpen: false,
      saveIdForDelete: '',
      saveIdForDeleteName: '',
      getIDofLastLi: '',
      timer: null,
      advanceSearchDialogOpen: false,
      versionHistoryDialogOpen: false,
      saveVersionhistryId: '',
      selectVersionHistoryID: 0,
      toggleViewAll: true,
      saveAdvanceGrade: '',
      saveAdvanceSubject: '',
      saveAdvanceCurriculum: '',
      saveAdvanceDuration: '',
      advancedSearchStarted: false,
      saveclickIndex: '-1',
      popIndex: null,
      facultySidebarHidden_ids: true,
      center_id: '',
      isScrolled: false,
      nameColumnClass: '',
      subjectColumnClass: '',
      typeColumnClass: '',
      gradeColumnClass: '',
      modifiedColumnClass: '',
      from: 0,
      limit: 5,
      searchText: '',
      sortbyValue: false,
      sortby: '',
      sortByorder: '',
      selectedSorter: '',
      AllHistoryListingData: [],
      getUserName: localStorage.getItem('name'),
      AllTemplateListingData: [],
      licenseAgreementIsChecked: false,
      licenseAgreementDialogOpen: false,
      eluaLoading: false

    };
    this.scrollContainer = React.createRef();
    this.scrollMainContainer = React.createRef();
    this.timeoutId = null;

  }




  componentDidMount() {
    this.getFacultyProfile();
    let getCenterID = localStorage.getItem("center_id");
    let getUserID = localStorage.getItem("user_id");
    let getToken = localStorage.getItem("token");

    if (config?.facultySidebarHidden_ids?.includes(parseInt(localStorage.getItem("center_id")))) {
      this.setState({ facultySidebarHidden_ids: false });
      document.getElementById("bodyParam").classList.add("lessonPlanPageActive");
    }


    if (getToken) {
      this.setState({ token: getToken });
    }
    if (getUserID) {
      this.setState({ user_id: getUserID });
    }
    if (getCenterID) {
      this.setState({ center_id: getCenterID });

    }


    this.getLessonPlanListing();

    this.amplifiuGetTemplateData();

    this.handleScroll();


    document.addEventListener("mousedown", this.handleClickOutside);


    if (this.scrollMainContainer.current) {
      this.scrollMainContainer.current.addEventListener('scroll', this.handleDivScroll);
    }


  }




  amplifiuGetTemplateData = async () => {
    const response = await this.props.amplifiuGetTemplateData();
    if (response !== undefined) {
      console.log("amplifiuGetTemplateData", response)
      this.setState({ AllTemplateListingData: response?.data })
    }

  }

  typeColumnClick = (bytype) => {
    let gettype = bytype;
    this.setState((prevState) => {
      let newClass;

      if (prevState.typeColumnClass == bytype) {
        newClass = '';
      }
      else {
        newClass = bytype;
      }
      // if (prevState.typeColumnClass === 'desc') {
      //   newClass = 'desc';
      // } else if (prevState.typeColumnClass === 'asc') {
      //   newClass = 'asc';
      // } 
      return { typeColumnClass: newClass };
    }, () => {
      console.log("typeColumnClass", this.state.typeColumnClass);
      this.setState({ is_filter: true });
      this.setState({ sortby: gettype });
      this.setState({ sortByorder: this.state.typeColumnClass });
      setTimeout(() => {
        this.getLessonPlanListing()
      }, 500);

    });
  }


  getLessonPlanListing = async () => {
    let { user_id, loading, getIDofLastLi, sortbyValue, nameColumnClass, subjectColumnClass, is_filter, typeColumnClass, gradeColumnClass, modifiedColumnClass, from, limit, searchTerm, sortby, sortByorder, is_search } = this.state;
    this.setState({ loading: true })
    let lastId;
    //in advanced search
    let response;

    if (searchTerm != '') {
      response = await this.props.amplifiuFetchThreadDocumentListing(limit, is_search ? 0 : this.state.AllListingData.length, searchTerm, user_id, typeColumnClass ? typeColumnClass : '', typeColumnClass ? '-' : '');
    } else if (sortby != '') {
      console.log("sortByorder", sortByorder);
      console.log("sortby", sortby);
      console.log("typeCollumnClass", typeColumnClass);
      console.log("limit", limit);
      console.log("is_filter", is_filter);
      // response = await this.props.amplifiuFetchThreadDocumentListing(this.state.AllListingData.length,is_filter ? 0 : from ,searchTerm,user_id, sortby ? sortby : "", sortByorder ? sortByorder : '');
      response = await this.props.amplifiuFetchThreadDocumentListing(is_filter ? this.state.AllListingData.length : limit, is_filter ? 0 : this.state.AllListingData.length, searchTerm, user_id, typeColumnClass ? typeColumnClass : sortby, typeColumnClass ? '-' : '');
    } else {
      response = await this.props.amplifiuFetchThreadDocumentListing(limit, this.state.AllListingData.length, searchTerm, user_id, '', '', '', '', '', '');
    }

    if (response !== undefined) {

      const keys = Object.keys(response?.data);
      let list = keys.map((key) => {
        this.state.AllListingData[key] = response?.data[key]
        return response?.data[key][0]; // Return the value inside map
      });
      console.log("list", list)
      console.log("list length",)
      this.setState({ loading: false })
      this.setState({ from: this.state.AllListingData.length });
      this.setState({ is_search: false });
      this.setState({ is_filter: false });
      this.setState({ advanceSearchDialogOpen: false });
      console.log("current value 1", sortby)
      console.log("current value 2", is_filter)
      console.log("current value 3", searchTerm)
      console.log("current value 4", this.state.AllListingData)



      if (searchTerm != '') {
        if (!is_search) {
          this.setState({ AllListingData: [...this.state.AllListingData, ...list] });
        } else {
          this.setState({ AllListingData: list });
        }
      } else if (sortby != '') {
        if (is_filter) {
          this.setState({ AllListingData: list });
        } else {
          this.setState({ AllListingData: [...this.state.AllListingData, ...list] });
        }
        // this.setState({ AllListingData: list});
      } else {
        this.setState({ AllListingData: [...this.state.AllListingData, ...list] });
      }

      console.log("current value 4 after ", this.state.AllListingData)



      console.log("AllListingData", this.state.AllListingData)

      //this.handleScroll();

    }

  }





  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    if (this.scrollMainContainer.current) {
      this.scrollMainContainer.current.removeEventListener('scroll', this.handleDivScroll);
    }
  }

  handleDivScroll = () => {
    // console.log(this.scrollMainContainer.current.scrollTop)
    if (this.scrollMainContainer.current.scrollTop > 10) {
      this.setState({ isScrolled: true });
      document.getElementById("bodyParam").classList.add("scrollActivate");
    } else {
      this.setState({ isScrolled: false });
      document.getElementById("bodyParam").classList.remove("scrollActivate");
    }
  }


  viewContent = (e, content) => {
    console.log(content);
    this.setState({ previewContent: content });
    this.setState({ dialogOpen: true });

  }

  dialogClose = () => {
    this.setState({ dialogOpen: false });

  }


  comingSoon = () => {
    this.setState({ comingSoondialogOpen: true });
  }

  advanceSearchCancelClick = () => {
    this.setState({ advanceSearchDialogOpen: false });
  }

  comingSoondialogClose = () => {
    this.setState({ comingSoondialogOpen: false });
  }

  createLessonPlan = (type) => {
    console.log("type", type);
    let { user_id, token } = this.state;
    let sendtoken = btoa(token);
    let sendType = btoa(type);
    let userid = btoa(user_id);
    let lessonplanType = btoa("lessonplan");
    const url = `${config?.editordomainUrl}/template?&type=${sendType}&t=${sendtoken}`;
    window.open(url, '_self');
    // this.setState({lessonPlanDialogOpen : true});
  }





  createLessonPlanDialogClose = () => {
    this.setState({ lessonPlanDialogOpen: false });
  }






  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ licenseAgreementDialogOpen: response?.data?.eula == 0 || response?.data?.eula == null ? true : false })
    this.setState({
      subjects: response.data.subjects, classes: Object.values(response.data.subjects
        .map(({ class_id, class_name }) => ({ class_id, class_name }))
        .reduce((acc, cur) => Object.assign(acc, { [cur.class_id]: cur }), {})),
      batches: response.data.batches
    });
  }


  changeName = (e) => {
    console.log(e.target.value);
    this.setState({ saveLessonPlanName: e.target.value });
  }

  changeClassSelect = (e) => {
    console.log(e.target.value);
    this.setState({ saveLessonPlanClass: e.target.value });
  }

  changeSubjectInput = (e) => {
    console.log(e.target.value);
    this.setState({ saveLessonPlanSubject: e.target.value });
  }

  changeTopicInput = (e) => {
    console.log(e.target.value);
    this.setState({ saveLessonPlanTopic: e.target.value });
  }

  changeBoardInput = (e) => {
    console.log(e.target.value);
    this.setState({ saveLessonPlanBoard: e.target.value });
  }


  submitButtonClick = (e) => {
    let { user_id, saveLessonPlanName, saveLessonPlanClass, saveLessonPlanTopic, saveLessonPlanSubject, saveLessonPlanBoard } = this.state;
    console.log(saveLessonPlanName, saveLessonPlanClass, saveLessonPlanSubject, saveLessonPlanTopic, saveLessonPlanBoard);
    this.createNewLessonPlan()
  }


  createNewLessonPlan = async () => {
    let { user_id, saveLessonPlanName, saveLessonPlanClass, saveLessonPlanTopic, saveLessonPlanSubject, saveLessonPlanBoard } = this.state;
    let form_json = { user_id: user_id, name: saveLessonPlanName, class_level: saveLessonPlanClass, topic: saveLessonPlanTopic, subject: saveLessonPlanSubject, board: saveLessonPlanBoard };
    console.log(form_json);
    const response = await this.props.amplifiuThreadCreation(user_id, form_json);
    if (response !== undefined) {
      console.log("response")
      console.log(response?.data.id);
      let threadid = response?.data.id;
      console.log(threadid);
      const url = `${config?.editordomainUrl}/q?t=${threadid}&user_id=${user_id}`;
      window.open(url, '_blank');
      //window.location.assign(`${config?.editordomainUrl}/q?t=${threadid}&a=asst_OgRC0X5nANK5lYA8385PuEuK&toc_id=${chapter_id}&user_id=${user_id}`)
    }

  }

  formatTimestamp(inputDate) {
    const currentDate = new Date();

    const myDate = moment.tz(inputDate, 'Asia/Kolkata').utc().format('YYYY-MM-DD HH:mm:ss');
    const areaDate = moment.utc(myDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate();

    const timeDifference = currentDate - areaDate;

    // Convert milliseconds to seconds
    const seconds = Math.floor(timeDifference / 1000);
    // Convert seconds to minutes
    const minutes = Math.floor(seconds / 60);
    // Convert minutes to hours
    const hours = Math.floor(minutes / 60);
    // Convert hours to days
    const days = Math.floor(hours / 24);
    // Convert days to months (average 30 days per month)
    const months = Math.floor(days / 30);


    const formattedDate = `${areaDate.getDate()}/${areaDate.getMonth() + 1}/${areaDate.getFullYear()}`;
    let formattedHours = areaDate.getHours();
    const amPm = formattedHours < 12 ? 'AM' : 'PM';
    formattedHours = formattedHours % 12 || 12; // Convert 0 to 12
    const formattedTime = `${formattedHours}:${areaDate.getMinutes().toString().padStart(2, '0')} ${amPm}`;

    if (seconds < 60) {
      // return  `${formattedDate} ${formattedTime} (Just now)`;
      return `(Just now)`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
      // return `${formattedDate} ${formattedTime} (${minutes} min ago)`;
    } else if (hours < 24) {
      // return `${formattedDate} ${formattedTime} (${hours} hr ago)`;
      return `${hours} hr ago`;
    } else if (days === 1) {
      // return `${formattedDate} ${formattedTime} (${days} day ago)`;
      return `Yesterday`;
    } else if (days < 30) {
      // return `${formattedDate} ${formattedTime} (${days} day ago)`;
      return `${formattedDate} ${formattedTime}`;
    } else if (months < 12) {
      // return `${formattedDate} ${formattedTime} (${months} month ago)`;
      return `${formattedDate} ${formattedTime}`;
    } else {
      // return `${formattedDate} ${formattedTime} (${hours} hrs ago)`;
      return `${formattedDate} ${formattedTime}`;
    }
  }



  isElementVisible(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }


  handleScroll = () => {
    const viewDiv = document.querySelector('.lastli');
    if (viewDiv) {
      const isVisible = this.isElementVisible(viewDiv);
      if (isVisible) {
        let getIDofLastLi = this.state.AllListingData[this.state.AllListingData.length - 1]?.openai_userthread_id;
        console.log("getIDofLastLi", getIDofLastLi);
        console.log('Is view div visible:', isVisible, " getIDofLastLi", getIDofLastLi);
        this.setState({ getIDofLastLi: getIDofLastLi })
        this.setState((prevState) => {
          // if (prevState.getIDofLastLi !== getIDofLastLi) {
          //   return { getIDofLastLi: getIDofLastLi };
          // } else {
          //   getIDofLastLi = null;
          //   return null;
          // }
        });
        if (getIDofLastLi) {
          viewDiv.classList.remove('lastli');
          console.log('Is view');
          this.getLessonPlanListing();
        }

      }

    }
  }







  OkButtonClick = () => {
    console.log(this.state.saveIdForDelete);
    this.deleteLessonPlan()
  }

  CancelButtonClick = () => {
    this.setState({ confitmationDialogOpen: false });
    this.setState({ saveIdForDelete: '' });
  }


  deleteLessonPlanClick = (e, id, name) => {
    console.log(id);
    this.setState({ saveIdForDelete: id });
    this.setState({ saveIdForDeleteName: name });
    this.setState({ confitmationDialogOpen: true });
  }


  deleteLessonPlan = async () => {
    console.log("saveid", this.state.saveIdForDelete);
    const response = await this.props.amplifiuSelectActiveInactive(this.state.saveIdForDelete ? this.state.saveIdForDelete : '', 'Deleted');
    if (response !== undefined) {
      console.log("Delete response listing")
      console.log(response.data);
    }
  }



  generateLink = (threadid, version_num, gettype) => {
    let { user_id, token } = this.state;
    let savethreadid = btoa(threadid);
    let versionnumber = btoa(version_num);
    let userid = btoa(user_id);
    let lessonplan = btoa('lessonplan');
    let sendtoken = btoa(token);
    gettype = btoa(gettype ?? 'lesson Plan');
    // console.log("savethreadid",btoa(savethreadid))
    // console.log("savethreadid",atob(savethreadid))
    // console.log("userid",user_id)
    // console.log("version_num",version_num)
    const url = `${config?.editordomainUrl}/q?t=${sendtoken}&thread=${savethreadid}&v=${versionnumber}&type=${gettype}`;
    return url;
  }


  editLessonPlan = (e, threadid, version_num, gettype) => {
    let { user_id, token } = this.state;
    let savethreadid = btoa(threadid);
    let versionnumber = btoa(version_num);
    let userid = btoa(user_id);
    let lessonplan = btoa('lessonplan');
    let sendtoken = btoa(token);
    gettype = btoa(gettype ?? 'lesson Plan');
    // console.log("savethreadid",btoa(savethreadid))
    // console.log("savethreadid",atob(savethreadid))
    // console.log("userid",user_id)
    // console.log("version_num",version_num)
    const url = `${config?.editordomainUrl}/q?t=${sendtoken}&thread=${savethreadid}&v=${versionnumber}&type=${gettype}`;
    window.open(url, '_self');
    //window.location.assign(`${config?.editordomainUrl}/q?t=${threadid}&a=asst_OgRC0X5nANK5lYA8385PuEuK&toc_id=${chapter_id}&user_id=${user_id}`)
  }

  handleSortByChange = (e) => {

    const selectedValue = e.target.getAttribute('value');
    console.log(selectedValue);
    // this.setState({ sortbyValue: e.target.value});
    this.setState({ sortbyValue: selectedValue });
  };


  downloadLessonPlanClick = (e, data) => {
    console.log("htmldata");
    console.log(data);
    if (data) {
      this.generateHTMLTOPDF(data);
    }

  };

  generateHTMLTOPDF = async (data) => {
    let html_data = data;
    console.log("html_data");
    console.log(html_data);
    const response = await this.props.htmlToPDF(html_data);
    if (response !== undefined) {
      console.log(response.data);
      const link = document.createElement('a');
      link.href = response.data;
      link.target = '_blank';
      link.setAttribute('download', 'downloaded_file.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }


  advanceSearchClick = () => {
    setTimeout(() => {
      this.setState({ advanceSearchDialogOpen: true });
    }, 300)

  }

  cloneLessonPlan = async (id, threadid, openai_userthread_id) => {
    let { user_id } = this.state;
    let thread_id = threadid;
    let saveid = id;
    let openaiuserthreadid = openai_userthread_id
    const response = await this.props.amplifiuLessonPlanClone(saveid, thread_id, openaiuserthreadid, user_id);
    if (response !== undefined) {
      console.log("hello response");
      console.log(response);
      this.getLessonPlanListing();
    }

  }

  makeAcopyLessonPlanClick = async (e, id, form_json) => {
    let { user_id } = this.state;
    console.log(id);
    let saveid = id;
    let formjson = form_json;
    if (saveid) {
      const response = await this.props.amplifiuThreadCreation(user_id, formjson);
      if (response !== undefined) {
        console.log("response")
        let threadid = response?.data.id;
        let openai_userthread_id = response?.data.openai_userthread;
        console.log("threadid", threadid);
        console.log("openai_userthread_id", openai_userthread_id);
        if (threadid) {
          this.cloneLessonPlan(saveid, threadid, openai_userthread_id);
        }
      }
    }
  }



  versionHistoryLessonPlanClick = async (e, thread_id) => {
    let { user_id } = this.state;
    console.log("thread_id", thread_id)
    if (thread_id) {
      this.setState({ saveVersionhistryId: thread_id });
      this.setState({ versionHistoryDialogOpen: true });
      const response = await this.props.amplifiuFetchThreadVersionHistoryListing(thread_id, user_id);
      if (response !== undefined) {
        console.log("response", response?.data);
        this.setState({ AllHistoryListingData: response?.data })
      }
    }

  }

  versionHistoryDialogBack = () => {
    this.setState({ selectVersionHistoryID: 0 });
    this.setState({ versionHistoryDialogOpen: false });
  }


  versionHistoryClick = (id) => {
    setTimeout(() => {
      this.setState({ selectVersionHistoryID: id });
    }, 300)
  }

  toggleViewAll = () => {
    this.setState({ toggleViewAll: !this.state.toggleViewAll });
  }



  advanceSearchButtonClick = () => {
    let { user_id, advancedSearchStarted, saveAdvanceGrade, saveAdvanceCurriculum, saveAdvanceSubject, saveAdvanceDuration } = this.state;
    console.log(saveAdvanceGrade, saveAdvanceCurriculum, saveAdvanceSubject, saveAdvanceDuration, advancedSearchStarted);
    setTimeout(() => {
      this.setState({ getIDofLastLi: 0 });
      this.setState({ advancedSearchStarted: false });
      this.getLessonPlanListing();
    }, 300)


  }

  changeAdvanceDuration = (e) => {
    if (e.target.value) {
      this.setState({ saveAdvanceDuration: e.target.value });
    }
  }

  changeAdvanceCurriculum = (e) => {
    if (e.target.value) {
      this.setState({ saveAdvanceCurriculum: e.target.value });
    }
  }

  changeAdvanceGrade = (e) => {
    if (e.target.value) {
      this.setState({ saveAdvanceGrade: e.target.value });
    }
  }

  handleSearchChange = (e) => {
    const value = e.target.value;
    console.log("value", value);
    if (value) {
      this.setState({ searchTerm: value, is_search: true });
    } else {
      this.setState({ searchTerm: '', is_search: false, from: 0 });
      this.setState({ AllListingData: [] });
    }
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.getLessonPlanListing();
    }, 500);
  };


  changeAdvanceSubject = (e) => {
    if (e.target.value) {
      this.setState({ saveAdvanceSubject: e.target.value });
    }
  }

  clearSearchClick = () => {
    let { user_id, advancedSearchStarted, saveAdvanceGrade, saveAdvanceCurriculum, saveAdvanceSubject, saveAdvanceDuration } = this.state;
    this.setState({ advancedSearchStarted: false });
    this.setState({ getIDofLastLi: 0 });
    this.setState({ saveAdvanceGrade: '' });
    this.setState({ saveAdvanceCurriculum: '' });
    this.setState({ saveAdvanceSubject: '' });
    this.setState({ saveAdvanceDuration: '' });
    this.setState({ AllListingData: '' });
    setTimeout(() => {
      this.getLessonPlanListing();
    }, 300)
  }


  selectedFilterGradeChange = (e) => {
    this.setState({ saveAdvanceGrade: '' });
    this.setState({ getIDofLastLi: 0 });
    this.setState({ AllListingData: '' });
    setTimeout(() => {
      this.getLessonPlanListing();
    }, 300)
  }
  selectedFilterSubjectChange = (e) => {
    this.setState({ saveAdvanceSubject: '' });
    this.setState({ getIDofLastLi: 0 });
    this.setState({ AllListingData: '' });
    setTimeout(() => {
      this.getLessonPlanListing();
    }, 300)
  }
  selectedFilterCurriculumChange = (e) => {
    this.setState({ saveAdvanceCurriculum: '' });
    this.setState({ getIDofLastLi: 0 });
    this.setState({ AllListingData: '' });
    setTimeout(() => {
      this.getLessonPlanListing();
    }, 300)
  }
  selectedFilterDurationChange = (e) => {
    this.setState({ saveAdvanceDuration: '' });
    this.setState({ getIDofLastLi: 0 });
    this.setState({ AllListingData: '' });
    setTimeout(() => {
      this.getLessonPlanListing();
    }, 300)
  }
  liCick = (e, index) => {
    console.log("item index", index);
    if (index) {
      this.setState({ saveclickIndex: index })
    } else {
      this.setState({ saveclickIndex: '' })
    }

  }


  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {

      this.setState({ saveclickIndex: null });

    }

  };


  licenseAgreementCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    this.setState({ licenseAgreementIsChecked: isChecked });
    console.log("Checkbox value:", isChecked);
  };

  licenseSkipClick = () => {
    this.setState({ licenseAgreementDialogOpen: false });

  }


  licenseClick = async () => {
    this.setState({ eluaLoading: true })
    const response = await this.props.acceptEULA(1)
    console.log("resonse ", response)
    if (response !== undefined) {
      this.setState({ licenseAgreementDialogOpen: false });
    } this.setState({ eluaLoading: true })
  }



  render() {


    var typeData = {
      'blank':
      {

        'name': 'Blank',
      },
      '':
      {

        'name': 'Blank',
      },

      'activity':
      {

        'name': 'Activity',
      },
      'assessment':
      {

        'name': 'Assessment',
      },
      'case_study':
      {

        'name': 'Case Study',
      },
      'classroom_game':
      {

        'name': 'Classroom Game ',
      },
      'concept_note':
      {

        'name': 'Concept Note ',
      },
      'data_analysis':
      {

        'name': 'Data Analysis ',
      },
      'debate':
      {

        'name': 'Debate ',
      },
      'essay':
      {

        'name': 'Essay ',
      },
      'fishbowl':
      {

        'name': 'Fishbowl ',
      },
      'group_discussion':
      {

        'name': 'Group Discussion ',
      },
      'group_grid':
      {

        'name': 'Group Grid ',
      },
      'hangman':
      {

        'name': 'Hangman ',
      },
      'jeopardy':
      {

        'name': 'Jeopardy ',
      },
      'jigsaw':
      {

        'name': 'Jigsaw ',
      },
      'journalist_method':
      {

        'name': 'Journalist Method',
      },
      'lessonplan':
      {

        'name': 'Lesson Plan ',
      },
      'lesson_plan_5e':
      {

        'name': 'Lesson Plan - 5E ',
      },
      'lesson_plan_gagnes_nine_events':
      {

        'name': `Lesson Plan - Gagne's Nine Events`,
      },
      'pair_share_repeat':
      {

        'name': 'Pair, Share, Repeat ',
      },
      'points_of_view':
      {

        'name': 'Points of View ',
      },
      'problem':
      {

        'name': 'Problem ',
      },
      'project':
      {

        'name': 'Project ',
      },
      'quiz':
      {

        'name': 'Quiz ',
      },
      'reading_comprehension':
      {

        'name': 'Reading Comprehension ',
      },
      'roleplay':
      {

        'name': 'Roleplay ',
      },
      'rubric':
      {

        'name': 'Rubrics ',
      },
      'scavenger_hunt':
      {

        'name': 'Scavenger Hunt ',
      },
      'think_pair_share':
      {

        'name': 'Think, Pair, Share ',
      },
      'unit_plan':
      {

        'name': 'Unit Plan ',
      },
      'vocabulary_list':
      {

        'name': 'Vocabulary List ',
      },
    };
    const isEnabled = this.state.saveLessonPlanName != '' && this.state.saveLessonPlanClass != '' && this.state.saveLessonPlanSubject != '' && this.state.saveLessonPlanTopic != '' && this.state.saveLessonPlanBoard != '';
    const isEnabled2 = this.state.saveAdvanceGrade != '' || this.state.saveAdvanceSubject != '' || this.state.saveAdvanceCurriculum != '' || this.state.saveAdvanceDuration != ''
    const isEnabled3 = this.state.licenseAgreementIsChecked
    return (
      <>
        <MobileNotReady />

        <div id="appBody" onScroll={() => {
          clearTimeout(this.state.timer);
          this.state.timer =
            setTimeout(() => {
              this.handleScroll();
            }, 300)

        }} className={this.state.lessonPlanDialogOpen || this.state.dialogOpen || this.state.comingSoondialogOpen || this.state.confitmationDialogOpen || this.state.advanceSearchDialogOpen || this.state.versionHistoryDialogOpen || this.state.licenseAgreementDialogOpen ? "app-body IndexChange" : "app-body"}>
          <ReactNotifications />
          <div className="app-div lessonPlanner" ref={this.scrollMainContainer}>
            <Helmet>
              <title>Lesson Plan</title>
            </Helmet>
            {/* <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/> */}

            <div className={this.state.licenseAgreementDialogOpen ? "endUserDialog open" : "endUserDialog "}>
              <div className='endUserContainer'>
                <div className='endContent'>
                  <h2>End User License Agreement (EULA) for AmplifiU</h2>
                  <p>Version 1.0 – June 2024</p>
                  <h3>Introduction</h3>
                  <p>This End User License Agreement ("EULA") is a legal agreement between you ("User" or "you") and AI FutureCraft Pty Ltd ("Company," "we," or "us"), located in New South Wales, Australia, for the use of AmplifiU ("Software"), a Software as a Service (SaaS) AI solution. By accessing or using the Software, you agree to be bound by the terms and conditions of this EULA. </p>
                  <h3>License Grant</h3>
                  <p>Subject to your compliance with the terms and conditions of this EULA, the Company grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Software solely for your internal business purposes.</p>
                  <h3>Restrictions</h3>
                  <p>You shall not:</p>
                  <ul>
                    <li>Modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from the Software.</li>
                    <li>Reverse engineer, decompile, or disassemble the Software.</li>
                    <li>Use the Software to develop a competing product.</li>
                  </ul>
                  <p>Use the Software in any manner that could damage, disable, overburden, or impair any Company server, or the network(s) connected to any Company server, or interfere with any other party's use and enjoyment of the Software. </p>
                  <h3>User Responsibilities </h3>
                  <p>As a user of the AmplifiU Software, you agree to the following responsibilities: </p>
                  <ul>
                    <li><span>Compliance with Laws:</span> You must comply with all applicable local, state, national, and international laws and regulations in connection with your use of the Software. </li>
                    <li><span>Account Security: </span> You are responsible for maintaining the confidentiality of your account information, including your username and password, and for all activities that occur under your account. You agree to notify the Company immediately of any unauthorised use of your account. </li>
                    <li><span>Accurate Information: </span> You agree to provide accurate, current, and complete information as required for the Software registration process and to maintain and update your information to ensure it remains accurate, current, and complete. </li>
                    <li><span>Prohibited Conduct: </span> You agree not to engage in any conduct that could damage, disable, overburden, or impair the Software or interfere with any other party's use and enjoyment of the Software, including but not limited to: Using the Software to transmit, distribute, or store material that is harmful, abusive, defamatory, obscene, or invasive of another's privacy. </li>
                    <li>Uploading or distributing files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Software or another's computer.</li>
                    <li>Attempting to gain unauthorised access to the Software, other accounts, computer systems, or networks connected to the Software through hacking, password mining, reverse engineering, or any other means. </li>
                    <li><span>Data Backup:</span>You are responsible for maintaining appropriate backups of your data. The Company is not responsible for any loss of data. </li>
                    <li><span>Notification of Breach:</span> You agree to notify the Company promptly if you become aware of any breach of security or unauthorised use of your account. </li>
                  </ul>
                  <h3>Intellectual Property </h3>
                  <p>The Software and all related intellectual property rights are the exclusive property of the Company and its licensors. No ownership rights are granted to you by this EULA, and you acknowledge that no title to the intellectual property in the Software is transferred to you. </p>
                  <h3>User Data </h3>
                  <p>You retain all rights and ownership to your data used or created with the Software. The Company will use your data only to provide the services and as otherwise permitted by this EULA and our Privacy Policy. </p>
                  <h3>Privacy </h3>
                  <p>Your use of the Software is also governed by our Privacy Policy, which is incorporated by reference into this EULA. By using the Software, you consent to the collection and use of your data as outlined in the Privacy Policy. </p>
                  <h3>Updates and Modifications </h3>
                  <p>The Company may provide updates, upgrades, or modifications to the Software ("Updates"). This EULA applies to any Updates unless accompanied by a separate license agreement, in which case that license agreement will govern. </p>
                  <h3>Term and Termination </h3>
                  <p>This EULA is effective until terminated. You may terminate this EULA at any time by ceasing all use of the Software. The Company may terminate this EULA immediately if you violate any provision of this EULA. Upon termination, you must cease all use of the Software and destroy all copies of the Software in your possession or control. </p>
                  <h3>Disclaimer of Warranties </h3>
                  <p>The Software is provided "AS IS" and "AS AVAILABLE," without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The Company does not warrant that the Software will meet your requirements or that the operation of the Software will be uninterrupted or error-free. </p>
                  <h3>Limitation of Liability </h3>
                  <p>In no event shall the Company be liable for any indirect, incidental, special, or consequential damages, or damages for loss of profits, revenue, data, or use, incurred by you or any third party, whether in an action in contract or tort, arising from your access to, or use of, the Software. </p>
                  <h3>Indemnification </h3>
                  <p>You agree to indemnify, defend, and hold harmless the Company, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Software. </p>
                  <h3>Governing Law </h3>
                  <p>This EULA shall be governed by and construed in accordance with the laws of New South Wales, Australia, without regard to its conflict of law principles. </p>
                  <h3>Dispute Resolution </h3>
                  <p>Any disputes arising out of or related to this EULA or the Software shall be resolved through binding arbitration in accordance with the rules of the Australian Centre for International Commercial Arbitration (ACICA). The arbitration shall be conducted in Sydney, New South Wales, and the language of the arbitration shall be English. </p>
                  <h3>Severability </h3>
                  <p>If any provision of this EULA is held to be invalid or unenforceable, the remaining provisions of this EULA will remain in full force and effect. </p>
                  <h3>Entire Agreement </h3>
                  <p>This EULA constitutes the entire agreement between you and the Company regarding the use of the Software and supersedes all prior or contemporaneous understandings and agreements, whether written or oral, regarding such subject matter. </p>
                  <h3>Contact Information</h3>
                  <p>If you have any questions about this EULA, please contact Paras Aggarwal (<a href="mailto:contact@amplifiu.com">contact@amplifiu.com</a>), AI FutureCraft Pty Ltd, NSW, Australia. </p>
                  <p>By clicking "Accept" or using the Software, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this EULA. </p>
                </div>
                <div className='endActions'>
                  <label><input type="checkbox" onChange={(e) => this.licenseAgreementCheckboxChange(e)} />I have read and agree with the End User License Agreement</label>
                  <div className='innnerAction'>

                    {this.state.eluaLoading ? <img alt='loader' src={lessonplanLoading} /> : <span className={this.state.licenseAgreementIsChecked ? "accreptBtn" : "accreptBtn is_disabled"} disabled={!isEnabled3} onClick={(e) => this.licenseClick(e)}>Accept</span>}

                  </div>
                </div>
              </div>
            </div>





            <div className={this.state.versionHistoryDialogOpen ? "versionHistoryDialog open" : "versionHistoryDialog"}>
              <div className='versionHistoryDialogContainer'>

                <div className='versionHistoryDialogContent'>
                  <div className='previewArea'>
                    <div className='previewBar'>
                      <h3><a href="javascript:void(0);" onClick={(e) => this.versionHistoryDialogBack()}><i className='fa fa-arrow-left'></i></a>
                        {this.state.AllHistoryListingData &&
                          this.state.AllHistoryListingData[this.state.saveVersionhistryId]?.length > 0 && this.state.AllHistoryListingData[this.state.saveVersionhistryId][this.state.selectVersionHistoryID]?.document_name}
                      </h3>
                    </div>
                    <div className='prewviewBlock'>
                      {
                        this.state.AllHistoryListingData &&
                        this.state.AllHistoryListingData[this.state.saveVersionhistryId] &&
                        <div dangerouslySetInnerHTML={{ __html: this.state.AllHistoryListingData[this.state.saveVersionhistryId][this.state.selectVersionHistoryID]?.document }}></div>

                      }
                    </div>
                  </div>


                  <div className='previewSidebar'>
                    <h3>Version history</h3>
                    <ol>
                      {
                        this.state.AllHistoryListingData &&
                        this.state.AllHistoryListingData[this.state.saveVersionhistryId] && (this.state.AllHistoryListingData[this.state.saveVersionhistryId].map((data, index) => (
                          <li id={index}>
                            <a href='javascript:void(0);' className={this.state.selectVersionHistoryID == index ? "selected" : ''} onClick={() => { this.versionHistoryClick(index) }}>
                              <h4>{data.document_name}</h4>
                              <p> {index == 0 ? "Current version" : this.formatTimestamp(data?.updated_on)}</p>
                              <div className='profile'><i></i>{this.state.getUserName ? this.state.getUserName : ''}</div>
                            </a>
                          </li>
                        ))
                        )}
                    </ol>
                  </div>
                </div>
              </div>
            </div>


            <div className={this.state.advanceSearchDialogOpen ? "advanceSearchDialog open" : "advanceSearchDialog"}>
              <div className='advanceSearchDialogContainer'>
                <div className='dialogClose' title='Close' onClick={() => this.advanceSearchCancelClick()}><i className='fa fa-times'></i></div>
                <div className='advanceSearchDialogContent'>
                  <h3>Advance Search</h3>
                  <div className='formWrapper'>
                    <div className='formGroup'>
                      <div className='formBlock'>
                        <label>Grade</label>
                        <select onChange={(e) => this.changeAdvanceGrade(e)} value={this.state.saveAdvanceGrade ? this.state.saveAdvanceGrade : ''}>
                          <option value="">Select grade</option>
                          <option value="Class 1">Class 1</option>
                          <option value="Class 2">Class 2</option>
                          <option value="Class 3">Class 3</option>
                          <option value="Class 4">Class 4</option>
                          <option value="Class 5">Class 5</option>
                          <option value="Class 6">Class 6</option>
                          <option value="Class 7">Class 7</option>
                          <option value="Class 8">Class 8</option>
                          <option value="Class 9">Class 9</option>
                          <option value="Class 10">Class 10</option>
                          <option value="Class 11">Class 11</option>
                          <option value="Class 12">Class 12</option>
                        </select>
                      </div>
                      <div className='formBlock'>
                        <label>Subject</label>
                        <select onChange={(e) => this.changeAdvanceSubject(e)} value={this.state.saveAdvanceSubject ? this.state.saveAdvanceSubject : ''}>
                          <option value="">Select subject</option>
                          <option value="hindi">Hindi</option>
                          <option value="english">English</option>
                          <option value="math">Math</option>
                          <option value="science">Science</option>
                          <option value="history">History</option>
                          <option value="physics">Physics</option>
                          <option value="chemistry">Chemistry</option>
                        </select>
                      </div>
                      <div className='formBlock'>
                        <label>Curriculum</label>
                        <select onChange={(e) => this.changeAdvanceCurriculum(e)} value={this.state.saveAdvanceCurriculum ? this.state.saveAdvanceCurriculum : ''}>
                          <option value="">Select curriculum</option>
                          <option value="ncrt">NCRT</option>
                          <option value="cbsc">CBSC</option>
                          <option value="icse">ICSE</option>
                          <option value="haryanaboard">Haryana Board</option>
                        </select>
                      </div>
                      <div className='formBlock'>
                        <label>Duration</label>
                        <select onChange={(e) => this.changeAdvanceDuration(e)} value={this.state.saveAdvanceDuration ? this.state.saveAdvanceDuration : ''}>
                          <option value="">Select duration</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="30">30</option>
                          <option value="35">35</option>
                          <option value="40">40</option>
                          <option value="45">45</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='actions'>
                    {/* <a href='javascript:void(0);' onClick={() => this.advanceSearchCancelClick()}>Cancel</a> */}
                    <button disabled={!isEnabled2} title="search" onClick={() => this.advanceSearchButtonClick()}>Search</button>
                  </div>
                </div>

              </div>
            </div>



            <div className={this.state.comingSoondialogOpen ? "comingSoonDialog open" : "comingSoonDialog"}>
              <div className='comingSoonDialogContainer'>
                <div className='dialogClose' title='Close' onClick={(e) => this.comingSoondialogClose()}><i className='fa fa-times'></i></div>
                <div className='comingSoonContent'>
                  <div className='icon'><img src={featureCoimingSoon} /></div>
                  <div className='iconContent'>Feature Coming Soon!</div>
                </div>
              </div>
            </div>

            <div className={this.state.confitmationDialogOpen ? "confitmationDialog open" : "confitmationDialog"}>
              <div className='confitmationDialogContainer'>
                <div className='dialogClose' title='Close' onClick={() => this.CancelButtonClick()}>&#x2715;</div>
                <div className='confitmationDialogContent'>
                  <h5>Delete File</h5>
                  <p>Are you sure you want to delete?</p>
                  <h6>{this.state.saveIdForDeleteName}</h6>
                  <div className='actions'>
                    <a href='javascript:void(0);' onClick={() => this.CancelButtonClick()}>Cancel</a>
                    <button onClick={() => this.OkButtonClick()}>Delete</button>
                  </div>
                </div>
              </div>
            </div>




            <div className={this.state.lessonPlanDialogOpen ? "createLessonPlanDialog open" : "createLessonPlanDialog"}>
              <div className='createLessonPlanDialogContainer'>
                <div className='dialogClose' title='Close' onClick={(e) => this.createLessonPlanDialogClose()}><i className='fa fa-times'></i></div>
                <h3>Lesson Plan</h3>
                <p>Generate a lesson plan for a topic or objective you’re teaching.</p>
                <div className='formWrapper'>
                  <div className='formGroup'>
                    <label>Name</label>
                    <div>
                      <input type="text" onChange={this.changeName} placeholder='Enter name' />
                    </div>
                  </div>
                  <div className='formGroup'>
                    <label>Class/Grade Level</label>
                    <div>
                      <select onChange={this.changeClassSelect}>
                        <option value="">Select Class</option>
                        <option value="class1">Class1</option>
                        <option value="class2">Class2</option>
                        <option value="class3">Class3</option>
                        <option value="class4">Class4</option>
                        <option value="class5">Class5</option>
                        <option value="class6">Class6</option>
                        <option value="class7">Class7</option>
                        <option value="class8">Class8</option>
                        <option value="class9">Class9</option>
                        <option value="class10">Class10</option>
                        <option value="class11">Class11</option>
                        <option value="class12">Class12</option>
                      </select>
                    </div>
                  </div>
                  <div className='formGroup'>
                    <label>Subject</label>
                    <div>
                      <input type="text" onChange={this.changeSubjectInput} placeholder='Enter subject' />
                    </div>
                  </div>
                  <div className='formGroup'>
                    <label>Topic</label>
                    <div>
                      <input type="text" onChange={this.changeTopicInput} placeholder='Enter topic' />
                    </div>
                  </div>
                  <div className='formGroup'>
                    <label>Board</label>
                    <div>
                      <input type="text" onChange={this.changeBoardInput} placeholder='Enter board' />
                    </div>
                  </div>
                  <div className='formActions'>
                    <button title='Submit' onClick={this.submitButtonClick} disabled={!isEnabled}>Submit</button>
                  </div>
                </div>
              </div>
            </div>


            <div className={this.state.dialogOpen ? "previewContentDialogWrapper open" : "previewContentDialogWrapper"}>
              <div className='previewContentDialogContainer'>
                <div className='dialogClose' title='Close' onClick={() => this.dialogClose()}>X</div>
                <h3>Preview Lesson Plan</h3>
                {!this.state.previewContent && <div className='noPreviewMessage'>No Preview Found</div>}
                {this.state.previewContent && <div className='contentWrapper' dangerouslySetInnerHTML={{ __html: this.state.previewContent }}>
                </div>}
              </div>

            </div>



            <div className='lessonPlanWrapper'>
              <div className='lessonPlanContainer'>

                <div className='tabsContentWrapper'>
                  {/* <div title='View all' className="viewAllToggle"><a href="/faculty/explore-all">Explore All<i className='fa fa-chevron-left'></i></a></div>                      */}
                  <div className='allTabData'>
                    <div class="fiterBlock">
                      <div class="search">
                        <label>Choose a  <span>Template</span></label>
                        {/* <input type="text" placeholder="Search..."/> */}
                      </div>
                      <div className='disclaimer'>
                        <span title='AI Disclaimer'><i><img src={disclaimerIcon} /></i>AI Disclaimer
                          <div className='disclaimerInfoPop'>
                            AmplifiU generates great educational material design but isn't a replacement for educator expertise. It's important to recognise AI can introduce biases or errors, so human insight is essential to ensure content is accurate and tailored to your students’ unique cultural and linguistic needs.
                          </div>
                        </span>
                      </div>
                    </div>

                    {this.state.AllTemplateListingData.length == 0 && <div className='templateLoading'><img src={lessonplanLoading} /></div>}

                    {this.state.AllTemplateListingData && this.state.AllTemplateListingData.length != 0 && <div id='CreateTab'>
                      <ol>
                        {this.state.AllTemplateListingData && this.state.AllTemplateListingData.slice(0, 13).map((item, index) =>
                          <li id={item.id} key={index}>
                            <a href='javascript:void(0);' title={item.title} onClick={(e) => this.createLessonPlan(item.prompt_type)}>
                              <div className='thumb'>
                                {!this.state.iconsloaded &&
                                  <img alt='loader' src={lessonplanLoading} />
                                }
                                <img alt={item.title} style={this.state.iconsloaded ? {} : { display: 'none' }} onLoad={() => this.setState({ iconsloaded: true })} src={item.image} />
                              </div>
                              <p>{item.title}</p>
                              <p>{item.description}</p>
                            </a>
                          </li>
                        )}
                        <li className=''>
                          <a href='/faculty/explore-all' title='Explore More'>
                            <div className='thumb'>
                              {!this.state.iconsloaded &&
                                <img alt='loader' src={lessonplanLoading} />
                              }
                              <img alt='Explore More' style={this.state.iconsloaded ? {} : { display: 'none' }} onLoad={() => this.setState({ iconsloaded: true })} src={exploreAllIcon} />
                            </div>
                            <p>Explore More <i className='fa fa-chevron-right'></i></p>
                          </a>
                        </li>
                      </ol>
                    </div>}
                  </div>

                </div>

                <div className='listingWrapper' ref={this.scrollContainer}>


                  <div className='fiterBlock '>
                    <div className='search'>
                      <label>Quick <span>Access</span></label>
                      <input type="text" onKeyUp={(e) => this.handleSearchChange(e)} placeholder="Search..." />
                      <div className='advanceSearch' style={{ display: 'none' }}><span onClick={() => this.advanceSearchClick()}>Advance Search</span></div>
                      {/* {this.state.advancedSearchStarted && <div className='advanceSearch'><span onClick={() => this.clearSearchClick()}>Clear Search</span></div> } */}
                    </div>
                    <div className='sortBy hidden'>
                      <div className='sortByDDWrapper'>
                        <div className='sortSelected'>
                          <span>
                            {/* {this.state.sortbyValue ? this.state.sortbyValue :  'All Recent' } */}
                            {
                              this.state.sortbyValue === 'byname' ? 'Sort by name' :
                                this.state.sortbyValue === 'bygrade' ? 'Sort by grade' :
                                  this.state.sortbyValue === 'bydate' ? 'Sort by date' :
                                    'Recently Used'
                            }
                          </span>
                          <i className='fa fa-chevron-down'></i>
                        </div>
                        <div className='sortByDD'>
                          <div className='sortDDContainer'>
                            <span value="" onClick={(e) => this.handleSortByChange(e)}>Recently Used</span>
                            <span value="byname" onClick={(e) => this.handleSortByChange(e)}>By Name</span>
                            <span value="bygrade" onClick={(e) => this.handleSortByChange(e)}>By Grade</span>
                            <span value="bydate" onClick={(e) => this.handleSortByChange(e)}>By Date</span>
                            {/* <span value="byclass"  onClick={(e) => this.handleSortByChange(e)}>By Class</span> */}
                            {/* <span value="byboard"  onClick={(e) => this.handleSortByChange(e)}>By Board</span> */}
                            {/* <span value="bysubject"  onClick={(e) => this.handleSortByChange(e)}>By Subject</span>
                                  <span value="byduration"  onClick={(e) => this.handleSortByChange(e)}>By Duration</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='selectedFilters'>
                    {/* {this.state.saveAdvanceGrade}  {this.state.saveAdvanceSubject}  {this.state.saveAdvanceCurriculum}  {this.state.saveAdvanceDuration}      */}
                    {this.state.saveAdvanceGrade && <div><span>Grade - {this.state.saveAdvanceGrade}</span><i title='Remove' onClick={(e) => this.selectedFilterGradeChange(e)} className='fa fa-times'></i></div>}
                    {this.state.saveAdvanceSubject && <div><span>Subject - {this.state.saveAdvanceSubject}</span><i title='Remove' onClick={(e) => this.selectedFilterSubjectChange(e)} className='fa fa-times'></i></div>}
                    {this.state.saveAdvanceCurriculum && <div><span>Curriculam - {this.state.saveAdvanceCurriculum}</span><i title='Remove' onClick={(e) => this.selectedFilterCurriculumChange(e)} className='fa fa-times'></i></div>}
                    {this.state.saveAdvanceDuration && <div><span>Duration - {this.state.saveAdvanceDuration}</span><i title='Remove' onClick={(e) => this.selectedFilterDurationChange(e)} className='fa fa-times'></i></div>}

                  </div>
                  {this.state.AllListingData.length > 0 &&
                    <>
                      <div className='fieldsWrapper'>

                        <div className={`type-filter ${this.state.typeColumnClass == 'document_name' ? 'desc' : 'asc'}`} onClick={(e) => this.typeColumnClick("document_name")}>Document Name/ Topic <span className='column-filter'><i className='sortArrowUp'><img src={sortArrowUp} /></i><i className='sortArrowDown'><img src={sortArrowDown} /></i></span></div>
                        <div className={`type-filter ${this.state.typeColumnClass == 'subject' ? 'desc' : 'asc'}`} onClick={(e) => this.typeColumnClick("subject")}>Subject <span className='column-filter'><i className='sortArrowUp'><img src={sortArrowUp} /></i><i className='sortArrowDown'><img src={sortArrowDown} /></i></span></div>
                        <div className={`type-filter ${this.state.typeColumnClass == 'prompt_type' ? 'desc' : 'asc'}`} onClick={(e) => this.typeColumnClick("prompt_type")}>Type <span className='column-filter'><i className='sortArrowUp'><img src={sortArrowUp} /></i><i className='sortArrowDown'><img src={sortArrowDown} /></i></span></div>
                        <div className={`type-filter ${this.state.typeColumnClass == 'grade' ? 'desc' : 'asc'}`} onClick={(e) => this.typeColumnClick("grade")}>Grade <span className='column-filter'><i className='sortArrowUp'><img src={sortArrowUp} /></i><i className='sortArrowDown'><img src={sortArrowDown} /></i></span></div>
                        <div className={`type-filter ${this.state.typeColumnClass == 'updated_on' ? 'desc' : 'asc'}`} onClick={(e) => this.typeColumnClick("updated_on")}>Modified On <span className='column-filter'><i className='sortArrowUp'><img src={sortArrowUp} /></i><i className='sortArrowDown'><img src={sortArrowDown} /></i></span></div>
                        <div></div>
                      </div>
                      <ol ref={this.wrapperRef}>

                        {this.state.AllListingData.map((item, index) =>

                          // this.state.AllListingData[keyName].map((data,index) =>
                          //   //<>
                          // {index == 0 && <li>{data.document_name}</li>} */}

                          //
                          //onClick={(e) => this.liCick(e,index)}
                          <li id={item.openai_userthread_id} key={index} className={`${index == this.state.saveclickIndex ? "opendd" : ''}  ${index == this.state.AllListingData.length - 1 ? "lastli" : ''}`}>
                            {/* {JSON.stringify(this.state.AllListingData[index])} */}
                            <div className='planInfo'>
                              {/* <div className='icon'><img src={basketIcon}/></div> */}
                              <div className='iconContent'>
                                <h3><a href={`${this.generateLink(item.thread_id, item.version_num, item?.form_json?.type)}`} title={item?.document_name} onClick={(e) => this.editLessonPlan(e, item.thread_id, item.version_num, item?.form_json?.type)}>{item?.document_name ? item?.document_name : '---'}</a></h3>
                                {/* <p>{this.formatTimestamp(item?.updated_on)}</p> */}
                                <div className='infoDisplay' style={{ "display": "none" }}>
                                  <div>Plan Name - <span>{item?.form_json?.name}</span></div>
                                  <div>Class - <span>{item?.form_json?.grade}</span></div>
                                  <div>Subject - <span>{item?.form_json?.subject}</span></div>
                                  <div>Board - <span>{item?.form_json?.curriculum}</span></div>
                                  <div>Duration - <span>{item?.form_json?.duration}</span></div>
                                </div>
                              </div>
                            </div>
                            <div className='planSubject'>
                              {item?.form_json?.subject}
                            </div>


                            <div className='planStatus'>
                              {/* {item?.form_json?.type ? item?.form_json?.type.replace(/_/g, ' , ') : '---'} */}
                              {/* <p> {item?.form_json?.type}</p> */}
                              {/* {  typeData[`${item?.form_json?.type}`]['name']} */}
                              {this.state.AllTemplateListingData.filter((elem) => elem.prompt_type == item?.form_json?.type)[0]?.title}
                              {/* {  typeData && typeData[item?.form_json?.type] ? typeData[item?.form_json?.type]['name'] : 'Blank' } */}
                              {/* <div className='planning'><span>Lesson Plan</span>{item?.clone_id !== null && <span>Cloned</span>}</div> */}
                            </div>
                            <div className='planGrade'>
                              {item?.form_json?.grade}
                            </div>
                            <div className='updateTime'>
                              {this.formatTimestamp(item?.updated_on)}
                            </div>
                            <div className='planActions'>
                              <div className='ddMain'>
                                <span><img src={verticalDots} /></span>
                                <div className='moreDDWrapper'>
                                  <div className='moreDDContainer'>
                                    <span title='Edit' onClick={(e) => this.editLessonPlan(e, item.thread_id, item.version_num, item?.form_json?.type)}><i><img src={editIcon} /></i>Edit</span>
                                    <span title='Preview' onClick={(e) => this.viewContent(e, item.document)}><i><img src={previewIcon} /></i>Preview</span>
                                    {item.document != '' && <span title='Download' onClick={(e) => this.downloadLessonPlanClick(e, item.document)}><i><img src={download} /></i>Download</span>}
                                    <span title='Duplicate' onClick={(e) => this.makeAcopyLessonPlanClick(e, item.id, item.form_json)}><i><img src={makeacopy} /></i>Make a copy</span>
                                    {/* <span title='Version history' onClick={(e) => this.versionHistoryLessonPlanClick(e,item.thread_id)}><i><img src={versionhistoryicon}/></i>Version history</span> */}
                                    <span title='Delete' onClick={(e) => this.deleteLessonPlanClick(e, item.id, item.document_name)}><i><img src={trashicon} /></i>Delete</span>
                                  </div>
                                </div>
                              </div>
                            </div>



                          </li>




                        )}

                      </ol>
                    </>
                  }
                  {this.state.AllListingData.length == 0 &&
                    <li className='noResultMessage'>No Result Found</li>
                  }

                  {this.state.AllListingData.length > 0 &&
                    <li id='NoScrollData' className='noResultMessage' style={{ display: 'none' }}></li>
                  }

                  {this.state.AllListingData.length > 0 && this.state.loading && <div className='loading'><img src={lessonplanLoading} /><br />Please wait</div>}

                </div>

                {/* <div className='disclaimer'>
                  <span title='AI Disclaimer'><i><img src={disclaimerIcon}/></i>AI Disclaimer 
                  <div className='disclaimerInfoPop'>
                  AmplifiU generates great educational material design but isn't a replacement for educator expertise. It's important to recognise AI can introduce biases or errors, so human insight is essential to ensure content is accurate and tailored to your students’ unique cultural and linguistic needs.
                  </div> 
                  </span>
                </div> */}

              </div>

            </div>


          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData: state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile, amplifiuThreadCreation, amplifiuFetchThreadDocumentListing, amplifiuSelectActiveInactive, htmlToPDF, amplifiuLessonPlanClone, amplifiuFetchThreadVersionHistoryListing, amplifiuGetTemplateData, acceptEULA } = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    acceptEULA: (eula_val) => dispatch(acceptEULA(eula_val)),
    amplifiuThreadCreation: (user_id, form_json) => dispatch(amplifiuThreadCreation(user_id, form_json)),

    amplifiuFetchThreadVersionHistoryListing: (thread_id, user_id) => dispatch(amplifiuFetchThreadVersionHistoryListing(thread_id, user_id)),
    amplifiuFetchThreadDocumentListing: (limit, from, search, user_id, sort, order) => dispatch(amplifiuFetchThreadDocumentListing(limit, from, search, user_id, sort, order)),
    amplifiuSelectActiveInactive: (id, status) => dispatch(amplifiuSelectActiveInactive(id, status)),
    htmlToPDF: (html_data) => dispatch(htmlToPDF(html_data)),
    amplifiuLessonPlanClone: (id, thread_id, openai_userthread_id, user_id) => dispatch(amplifiuLessonPlanClone(id, thread_id, openai_userthread_id, user_id)),
    amplifiuGetTemplateData: () => dispatch(amplifiuGetTemplateData()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonPlan);