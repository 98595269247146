import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Listing from '../../public/icons/listing.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Linked from '../../public/icons/add-file.png';
import MultiSelect from "react-multi-select-component";
import 'react-notifications-component/dist/theme.css';
import './styles.css';


const levels =[0,1,2,3,4,5,6,7,8,9,10,11,12];

class AdminSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      open3: false,
      opend: false,
      class_id: null,
      filterCenter: parseInt(localStorage.getItem('center_id')),
      label: '',
      short_code: '',
      order: '',
      level:'',
      description: '',
      sessionBranch:parseInt(localStorage.getItem('center_id')),
      branches: [],
      center_id: localStorage.getItem('center_id'),
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      classesFilter: [],
      filterSectionName:'',
      is_faculty_admin:false,
      start_date:'',
      end_date:'',
      selected:[],
      programs:[],
      filterSection:''
    }
  }
  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getCenterList();
    this.sessionList(this.state.filterCenter);
    this.programList(this.state.filterCenter);
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
  }

  show = () => this.setState({ open: true, center_id: localStorage.getItem('center_id'),
                              label: '', short_code: '', description: '', start_date:'',end_date:''});
  close = () => this.setState({ open: false });

  show2 = (val) => {
    this.setState({d_id:val.id,open2: true, label: val.label, short_code: val.short_code,
                      description: val.description,start_date:val.start_date,end_date:val.end_date,sessionBranch:this.state.filterCenter});
  }

  show3 = (id) => {
    let {programs} = this.state
    let program_session_mapping = [];

    let sessionIndex = this.state.classes.findIndex( p => p.id == id)

    program_session_mapping = this.state.classes[sessionIndex]?.programs_mapped.map(m => m.id);

    programs = this.state.programs.map( prog => {
      if(program_session_mapping.includes(prog.value)){
        prog.disabled = true
      }else{
        prog.disabled = false
      }
      return prog
    })

    console.log(programs)
    this.setState({open3: true,filterSection:id,filterSectionName:this.state.classes[sessionIndex]?.label,programs});
  }

  close3 = () => this.setState({open3:false})

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }

  sessionList = async (center_id) => {
    const response = await this.props.sessionList(center_id);
    if(response !== undefined) {
      this.setState({ classes: response.data, classesFilter: response.data });
    }
  }

  programList = async (center_id) => {
    const response = await this.props.programList(center_id);
    if(response !== undefined) {

      const programs = response.data?.map( prog => ({label:prog.label,value:prog.id,"disabled":false}))
      this.setState({ programs});
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  nameChange = (e) => {
    this.setState({ label: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ short_code: e.target.value })
  }

  descriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  orderChange = (e) => {
    const order = (e.target.validity.valid) ? e.target.value : this.state.order;
    this.setState({ order })
  }

  levelChange = (e) =>{
    const level = (e.target.validity.valid) ? e.target.value : this.state.level;

    this.setState({ level })
  }

  centerChange = (e) => {
    if(e.target.value === 'Filter by Center') {
      this.setState({ filterCenter: null });
      this.setState({ classesFilter: this.state.classes });
    }
    else {
      this.setState({ filterCenter: e.target.value });
      this.programList(e.target.value);
      this.sessionList(e.target.value)
    }
  }

  newClass = async () => {
    const {label,description,short_code,start_date,end_date,sessionBranch} = this.state;
    await this.props.sessionCreate(label, description, short_code, start_date, end_date,sessionBranch);
  }

  editSession = async () => {
    const {label,short_code,start_date,end_date,description,d_id,sessionBranch} = this.state;
    await this.props.programSessionEdit(d_id,label,description,short_code,start_date,end_date,sessionBranch);
    this.setState({ open2: false });
  }

  deleteClass = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteClass(d_id, center_id);
    this.setState({ opend: false });
  }

  onChangeTime = (e) =>{
    var d = new Date(e.target.value),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    this.setState({[e.target.name]:[year, month, day].join('-')});
  }

  setSelected = (selected) =>{
    console.log(selected);

    this.setState({selected});
  }

  mapSessionProgram = async () =>{
    let selectedPrograms = this.state.selected.map(slct => slct.value)

    const response = await this.props.sessionProgramMapping(this.state.filterSection,selectedPrograms)
  }

  sessionBranchChange = (e) =>{
    this.setState({sessionBranch:e.target.value})
  }

  render() {

    const isEnabled = this.state.label.length > 0 && this.state.short_code.length > 0 && this.state.start_date && this.state.end_date && this.state.description;
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search classes"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.classes}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.classes}</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder={this.props.pagewiseLanguageData.search_classes}/>
        <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="popup-session">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.map_program_with_session}
          </div>
          <span className="close" onClick={this.close3}>&#x2715;</span>
          <div className="session-label">{this.props.pagewiseLanguageData.session} : {this.state.filterSectionName}</div>
          <div className="popup-data-session">
            <MultiSelect
              options={this.state.programs}
              value={this.state.selected}
              onChange={this.setSelected}
              labelledBy={this.props.pagewiseLanguageData.select_programs}
            />
            <div className="content-message">
            {/* {`you have selected total ${this.state.selected.length} programs to map`} */}
            {`${this.props.pagewiseLanguageData.you_have_selected_total} ${this.state.selected.length} ${this.props.pagewiseLanguageData.program_to_map}`}
            </div>
          </div>

          <div className="popup-actions">
            <button disabled="" onClick={this.mapSessionProgram}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_session}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs-session">
              <input type="text" placeholder={this.props.pagewiseLanguageData.session_name} onChange={this.nameChange}/>
              <input type="text" placeholder={this.props.pagewiseLanguageData.session_code} onChange={this.codeChange}/>
              <select value={this.state.sessionBranch} onChange={this.sessionBranchChange}>
                <option value={null}>--{this.props.pagewiseLanguageData.select_center}--</option>
                {this.state.branches.map(branch =>
                  <option key={branch.id} value={branch.id}>{branch.name}</option>
                )}
              </select>
            </div>
            <div className="popup-inputs-session">
              <input type="textarea" placeholder={this.props.pagewiseLanguageData.description} onChange={this.descriptionChange}/>
            </div>
            <div className="popup-inputs-session-date">
              <input type="date" placeholder={this.props.pagewiseLanguageData.startdate} name="start_date"
                onChange={this.onChangeTime} value={this.state.start_date}/>
              <input type="date" placeholder={this.props.pagewiseLanguageData.enddate} name="end_date"
                onChange={this.onChangeTime} value={this.state.end_date}/>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newClass}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_session}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs-session">
              <input value ={this.state.label} type="text" placeholder={this.props.pagewiseLanguageData.session_name} onChange={this.nameChange}/>
              <input value={this.state.short_code} type="text" placeholder={this.props.pagewiseLanguageData.session_code} onChange={this.codeChange}/>
              <select value={this.state.sessionBranch} onChange={this.sessionBranchChange} disabled={true}>
                <option value={null}>--{this.props.pagewiseLanguageData.select_center}--</option>
                {this.state.branches.map(branch =>
                  <option key={branch.id} value={branch.id}>{branch.name}</option>
                )}
              </select>
            </div>
            <div className="popup-inputs-session">
              <input value ={this.state.description} type="textarea" placeholder={this.props.pagewiseLanguageData.description} onChange={this.descriptionChange}/>
            </div>
            <div className="popup-inputs-session-date">
              <input value={this.state.start_date} type="date" placeholder={this.props.pagewiseLanguageData.startdate} name="start_date"
                onChange={this.onChangeTime} value={this.state.start_date}/>
              <input value={this.state.end_date} type="date" placeholder={this.props.pagewiseLanguageData.enddate} name="end_date"
                onChange={this.onChangeTime} value={this.state.end_date}/>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.editSession}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteClass}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <h1>{this.props.pagewiseLanguageData.sessions}</h1>
              </div>
            </div>
            <div className="heading-part2">
              {!this.state.is_faculty_admin && <span onClick={this.show}>
                <img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
              </span>}
              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
            </div>
          </div>
          <div className="sub-filter">
            {!this.state.is_faculty_admin && <select value={this.state.filterCenter} onChange={this.centerChange}>
              <option value={null}>{this.props.pagewiseLanguageData.filter_by_center}</option>
              {this.state.branches.map(branch =>
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              )}
            </select>}
          </div>
          {this.state.classesFilter.length === 0 ? <><span id="no-data-admin">
               {this.props.pagewiseLanguageData.no_class_subject_is_mapped_yet}
              </span> <Empty/></> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.name}
              </div>
              <div className="list-section">
              {this.props.pagewiseLanguageData.code}
              </div>
              <div className="list-class">
              {this.props.pagewiseLanguageData.description}
              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.center}
              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.programs}
              </div>
              {!this.state.is_faculty_admin &&<div className="list-action2">
              {this.props.pagewiseLanguageData.actions}
              </div>}
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.classesFilter.map((classs, index) =>
                <div className="list-item" key={classs.id}>
                  <div className="title-item">
                    {classs.label}
                  </div>
                  <div className="section-item">
                    {classs.short_code}
                  </div>
                  <div className="class-item">
                    {classs.description}
                  </div>
                  <div className="faculty-item">
                    {classs.center_details?.name}
                  </div>
                  <div className="faculty-item">
                    {classs.programs_mapped.length}
                  </div>
                  <div className="action-item2">
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.link} onClick={() => this.show3(classs.id)}>
                        <img src={Linked} alt={this.props.pagewiseLanguageData.link}/>
                    </span>}
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(classs)}>
                      <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                    </span>}
                    {/*!this.state.is_faculty_admin && <span title="Delete" onClick={() => this.showd(classs.id)}>
                      <img src={Delete} alt="delete"/>
                    </span>*/}
                    {/*<Link id="section-button2" to={{ pathname: `/admin/subjects/${classs.id}`,
                      state: {class_label: classs.label, center_id: classs.center} }} onClick={this.changeTab}>
                      <img src={Listing} alt="list"/>
                      Subjects
                    </Link>
                    <Link id="section-button2" to={{ pathname: `/admin/class-sections/${classs.id}`,
                      state: {class_label: classs.label, center_id: classs.center} }}
                      onClick={this.changeTab}>
                      <img src={Listing} alt="list"/>
                      Sections
                    </Link>*/}
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.classesFilter.map((classs, index) =>
                <div className="grid-item" key={classs.id}>
                  <div className="grid-up grid-up-v1">
                    <div className="title-grid">
                      {classs.label}
                    </div>
                    <div className="grid-actions">
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.link} onClick={() => this.show3(classs.id)}>
                        <img src={Linked} alt={this.props.pagewiseLanguageData.link}/>
                      </span>}
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(classs)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>}
                      {/*!this.state.is_faculty_admin && <span title="Delete" onClick={() => this.showd(classs.id)}>
                        <img src={Delete} alt="delete"/>
                      </span>*/}
                    </div>
                  </div>
                  <div className="grid-up grid-up-v1">
                    <div className="grid-sec">
                    {this.props.pagewiseLanguageData.code}
                      <span>{classs.short_code}</span>
                    </div>
                     <div className="grid-sec">
                      {this.props.pagewiseLanguageData.total_mapped_programs}
                      <span>{classs.programs_mapped.length}</span>
                    </div>
                    {/*<div className="grid-sec2">
                      <Link id="section-button2" to={{ pathname: `/admin/subjects/${classs.id}`,
                        state: {class_label: classs.label, center_id: classs.center} }} onClick={this.changeTab}>
                        <img src={Listing} alt="list"/>
                        Subjects
                      </Link>
                      <Link id="section-button2" to={{ pathname: `/admin/class-sections/${classs.id}`,
                        state: {class_label: classs.label, center_id: classs.center} }}
                        onClick={this.changeTab}>
                        <img src={Listing} alt="list"/>
                        Sections
                      </Link>
                    </div>*/}
                  </div>
                </div>
              )}
            </div>}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage,programSessionEdit,sessionProgramMapping,sessionList,programList,sessionCreate,createSubject, createClass, updateClass, getClassList,
          deleteClass, getCenterList,getPageLanguageWiseData} = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    createClass: (label, description, short_code, center, order,level) => dispatch(createClass(label, description, short_code, center, order,level)),
    createSubject: (label, short_code, classs, master_subject, background_code, image, center_id) => dispatch(createSubject(label, short_code, classs, master_subject, background_code, image, center_id)),
    updateClass: (center, class_id, name, description, code, order,level) => dispatch(updateClass(center, class_id, name, description, code, order,level)),
    getClassList: () => dispatch(getClassList()),
    getCenterList: () => dispatch(getCenterList()),
    deleteClass: (id, center_id) => dispatch(deleteClass(id, center_id)),
    sessionList: (center_id) => dispatch(sessionList(center_id)),
    programList: (center_id) => dispatch(programList(center_id)),
    sessionProgramMapping : (session,programs) => dispatch(sessionProgramMapping(session,programs)),
    sessionCreate:(label,description,short_code,start_date,end_date,center_id) => dispatch(sessionCreate(label,description,short_code,start_date,end_date,center_id)),
    programSessionEdit:(id,label,description,short_code,start_date,end_date,center_id) =>dispatch(programSessionEdit(id,label,description,short_code,start_date,end_date,center_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSessions);
