import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './mycoach.css';
import HeaderMyCoach from './HeaderMyCoach';

import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import thanksDialogCheckmark from '../../public/icons/thanksdialog-checkmark.png';
import axios from 'axios';
import common from '../../common';

class stationaryShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      myBannerdata:[],
      profile: {},
      isvisible:false,
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
   }
  }
  
   componentDidMount() {
    localStorage.setItem("page","stationary shop");
    if(this.state.is_webview){
      localStorage.setItem('token', this.state.token);
      this.getCertificationListCall();
       
    }else{
      this.getCertificationListCall();
    }
    
  }

  getCertificationListCall = async() =>{
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.xpCertificationBannerListing}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(res => {
          this.setState({ myBannerdata: res?.data.data})
      }).catch(err => { console.error('error', err.message) });
    }else{
      console.log("function all");
      const response = await this.props.getCertificationBannerList();
      //this.setState({mycoachdata : response?.data})
      this.setState({myBannerdata:response?.data})
      console.log("sdfsdf response" + JSON.stringify(this.state.myBannerdata));
    }
  }
  ApplyBanner = async(banner_id) =>{
    console.log("hellossss" +  banner_id);
    if(this.state.is_webview){
      await axios.post(`${common.apiBase}${common.xpUserBannerCreation}`,{banner_id},{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
          if(response.data?.status == 1){
            console.log("statue 1");
            this.setState({isvisible:true})
          }else{
            console.log("statue 0")
            this.setState({isvisible:false})
          }
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.xpUserBannerCreation(banner_id);
      console.log("createbanner"+ response)
      if(response?.status == 1){
        console.log("statue 1");
        this.setState({isvisible:true})
      }else{
        console.log("statue 0")
        this.setState({isvisible:false})
      }
    }
  }

  closeDialog(){
    this.setState({isvisible : false});
  }


	render() {
    const MAX_LENGTH = 55;
    return (
      <>
      <ReactNotifications/>
      {!this.state.is_webview && <HeaderMyCoach/>}
        <div className={"app-body "+(this.state.is_webview == 1 ? 'webview' : '')}>
          
          <div className="app-div mycoach-div">
            <Helmet>
              <title>Stationary Shop - Learner</title>
            </Helmet>
            <div className="mycoach-wrapper">

                <div className="stationaryshop-block">
                      <h3>Stationary Shop</h3> 
                      <p>Equip yourself with toolkits, workbooks and merchandise for design, tech and more.</p>
                    <div className="design-block">
                      
                      <ol>
                      {this.state.myBannerdata?.filter(bannertype => bannertype.banner_type == 'Stationary Shop').map(item =>
                        <li>
                          <div className="thumb"><img src={item.image}/></div>
                          <div className="hoverOverlay">
                            <div className="thumb-title">{item.title}</div>
                            <div className="thumb-content">{item.description}</div>
                            <div className="thumb-action">
                            <a onClick={()=>this.ApplyBanner(item.id)}>Apply</a>
                            </div>
                          </div>
                          <div className="thumb-title">{item.title}</div>
                          <div className="thumb-content">{`${item.description.substring(0, MAX_LENGTH)}...`}</div>
                          <div className="thumb-action">
                            {/* <div className="duedate-tag"><span>Due date</span></div> */}
                            <a onClick={()=>this.ApplyBanner(item.id)}>Apply</a>
                          </div>
                      </li>
                    )}       
                  </ol>
                    </div>
                </div>



              </div>
            </div>
          </div>


          <div className= {this.state.isvisible ? 'isvisible thanksApplyDialog':'thanksApplyDialog'}>
          <div className="thanksApplyDialog-inner">
              <div className="popup-header"><i><img src={thanksDialogCheckmark} /></i>Thank you for Applying</div>
              <span className="close" onClick={()=>this.closeDialog()}>&#x2715;</span> 
              <div className="thanksApplyDialog-content">
                We will contact with you soon
              </div>
          </div>
        </div>
        
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage
   
	}
}

const mapDispatchToProps = (dispatch) => {
  const {getCertificationBannerList,xpUserBannerCreation} = actions;
  return {
    getCertificationBannerList: () => dispatch(getCertificationBannerList()),
    xpUserBannerCreation: (banner_id) => dispatch(xpUserBannerCreation(banner_id)),
    
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(stationaryShop);