import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import {MP} from '../../redux/constants';
import 'react-notifications-component/dist/theme.css';
import './mycoach.css';
import axios from 'axios';
import HeaderMyCoach from './HeaderMyCoach';
import 'react-circular-progressbar/dist/styles.css';
import thanksDialogCheckmark from '../../public/icons/thanksdialog-checkmark.png';


class myCoachPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      myBannerdata:[],
      profile: {},
      isvisible:false,
      saveBannerType:'',
      saveBannerLabel:'',
      saveMobileBannerLabel:'',
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
      stateLanguageData:[],
      mobileViewLanguageData:[]
   }
  }
  
   componentDidMount() {
    localStorage.setItem("page","my-coach-pages");
    let getMobileBannerType = this.props.match.params.banner_type;
    let getBannerType = window.location.pathname.split("/").pop();
    let getURL = this.props.match.url;
    //let getMobileTokenFromURL = window.location.href.split("&").pop();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getMobileTokenFromURL = urlParams.get('token')
    console.log(getMobileTokenFromURL);
    console.log(getBannerType);
    console.log(getURL);
    console.log(getMobileBannerType)
    let saveMenuData = this.props.getmenuData;
    if(saveMenuData){
      let getLabel = saveMenuData?.filter(data => data?.url == getURL);
      this.setState({saveBannerLabel:getLabel[0]?.label});
    }


    this.fetchFunction();
    
    

    if(getBannerType){
      this.setState({saveBannerType:getBannerType});
     
    }
 


    if(this.state.is_webview){
      localStorage.setItem('token', this.state.token);
      this.setState({saveMobileBannerLabel:getMobileBannerType});
      this.getCertificationListCall();
       this.allProfileApi(getMobileTokenFromURL);
    }else{
      this.getCertificationListCall();
    }

    
  }


  allProfileApi = async (getMobileTokenFromURL) =>{
    await axios.get(`${common.apiBase}${common.callprofileApI}`,{headers: {
      'Authorization': 'token ' + getMobileTokenFromURL}
  }).then(res => {
      this.setState({ stateLanguageData: res?.data.data});
      console.log("stateLanguageData");
      console.log(this.state.stateLanguageData);
      this.getLanguagePagewiseData();

  }).catch(err => { console.error('error', err.message) });

  }

  getLanguagePagewiseData = async () =>{
    let filterlanguage = this.state.stateLanguageData.language;
    console.log("filterlanguage");
    console.log(filterlanguage);
    const response = await this.props.getPageLanguageWiseData(filterlanguage, 'learner');
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      this.setState({mobileViewLanguageData: response?.data});
      
      
    }
  } 

  fetchFunction = () =>{
    setTimeout(() => {
    const data = this.props.saveLanguageWiseData;
    let saveMenuData = this.props.getmenuData;
    let getURL = this.props.match.url;
    if(data){
      console.log("data")
      console.log(data)
      let getLabel = saveMenuData?.filter(data => data?.url == getURL);
      let matchlabel = getLabel[0]?.label;
      let matchkey = getLabel[0]?.key;
      let dataLabel  = data[matchkey];
      console.log("matchlabel")
      console.log(matchlabel)
      console.log("dataLabel")
      console.log(dataLabel);
      if(dataLabel){
        this.setState({saveBannerLabel:dataLabel});
      }
    }
  }, 800)
  }

  getCertificationListCall = async() =>{
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.xpCertificationBannerListing}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(res => {
          this.setState({ myBannerdata: res?.data.data})
      }).catch(err => { console.error('error', err.message) });
    }else{
      console.log("function all");
      const response = await this.props.getCertificationBannerList();
      //this.setState({mycoachdata : response?.data})
      this.setState({myBannerdata:response?.data})
      console.log("sdfsdf response" + JSON.stringify(this.state.myBannerdata));
    }
  }

  ApplyBanner = async(banner_id) =>{
    console.log("hellossss" +  banner_id);
    if(this.state.is_webview){
      await axios.post(`${common.apiBase}${common.xpUserBannerCreation}`,{banner_id},{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
          if(response.data?.status == 1){
            console.log("statue 1");
            this.setState({isvisible:true})
          }else{
            console.log("statue 0")
            this.setState({isvisible:false})
          }
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.xpUserBannerCreation(banner_id);
      console.log("createbanner"+ response)
      if(response?.status == 1){
        console.log("statue 1");
        this.setState({isvisible:true})
      }else{
        console.log("statue 0")
        this.setState({isvisible:false})
      }
    }
  }

  closeDialog(){
    this.setState({isvisible : false});
  }

 

	render() {
    const MAX_LENGTH = 55;
    return (
      <>
       <ReactNotifications/>
      {!this.state.is_webview && <HeaderMyCoach/>}
        <div className={"app-body "+(this.state.is_webview == 1 ? 'webview' : '')}>
         
          <div className="app-div mycoach-div">
            <Helmet>
              <title>My Coach Pages - Learner</title>
            </Helmet>
            <div className="mycoach-wrapper">

                <div className="liveprojects-block">
                    {/* {JSON.stringify(this.state.saveBannerLabel)} */}
                    <h3>{this.state.saveBannerLabel ? this.state.saveBannerLabel : this.state.saveMobileBannerLabel}</h3>
                    {/* <h3>{this.state.saveBannerType ? this.state.saveBannerType : ''}</h3> */}
                    <p>{this.props.saveLanguageWiseData.menu_banner_description}</p>  
                    <div className="design-block">
                      <ol>
                        {this.state.myBannerdata?.filter(bannertype => bannertype?.banner_type?.toLowerCase() == this.state.saveBannerType.toLowerCase()).map(item =>
                            <li>
                             <div className="thumb"><img src={item.image}/></div>
                              <div className="hoverOverlay">
                                <div className="thumb-title">{item.title}</div>
                                <div className="thumb-content">{item.description}</div>
                                <div className="thumb-action">
                                {this.state.is_webview &&
                                  <a onClick={()=>this.ApplyBanner(item.id)}>{this.state.mobileViewLanguageData.join}</a>
                                }
                                {!this.state.is_webview &&
                                  <a onClick={()=>this.ApplyBanner(item.id)}>{this.props.saveLanguageWiseData.join}</a>
                                }
                                </div>
                              </div>
                              <div className="thumb-title">{item.title}</div>
                              <div className="thumb-content">{`${item.description.substring(0, MAX_LENGTH)}...`}</div>
                              <div className="thumb-action">
                                {/* <div className="duedate-tag"><span>Due date</span></div> */}
                                {/* {JSON.stringify(this.state.mobileViewLanguageData)} */}
                                {this.state.is_webview &&
                                <a onClick={()=>this.ApplyBanner(item.id)}>{this.state.mobileViewLanguageData.join}</a>
                                  }
                                  {!this.state.is_webview &&
                                    <a onClick={()=>this.ApplyBanner(item.id)}>{this.props.saveLanguageWiseData.join}</a>
                                  }
                              </div>
                          </li>
                        )}       
                        
                      </ol>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div className= {this.state.isvisible ? 'isvisible thanksApplyDialog':'thanksApplyDialog'}>
          <div className="thanksApplyDialog-inner">
              {!this.state.is_webview &&                 
              <div className="popup-header"><i><img src={thanksDialogCheckmark} /></i>{this.props.saveLanguageWiseData.thanks_applying}</div>
              }
              {this.state.is_webview && 
              <div className="popup-header"><i><img src={thanksDialogCheckmark} /></i>{this.state.mobileViewLanguageData.thanks_applying}</div>
                }
              <span className="close" onClick={()=>this.closeDialog()}>&#x2715;</span> 
              {!this.state.is_webview &&  
              <div className="thanksApplyDialog-content">{this.props.saveLanguageWiseData.will_contact_soon}</div>
              }
               {this.state.is_webview &&  
              <div className="thanksApplyDialog-content">{this.state.mobileViewLanguageData.will_contact_soon}</div>
              }
          </div>
        </div>
        
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage,
    getmenuData:state.commonReducer.logindetails?.institute_settings?.menu,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getCertificationBannerList,xpUserBannerCreation,getPageLanguageWiseData} = actions;
  return {
    getCertificationBannerList: () => dispatch(getCertificationBannerList()),
    xpUserBannerCreation: (banner_id) => dispatch(xpUserBannerCreation(banner_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(myCoachPages);