import React, { Component } from "react";
import Popup from 'reactjs-popup';
import OOPS from '../public/icons/WiredMobile.png';
import './styles.css';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false ,is_error_found_login:false};
  }

  static getDerivedStateFromError(error) {
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error or send logging data to log management tool
    console.log("345678456789"+error)
    //logErrorToMyService(error, errorInfo);
  }

  componentDidMount() {
    this.setState({is_error_found_login:parseInt(localStorage.getItem("is_error_found_login")) ? true:false})
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler)
  }

  /*componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }*/

  promiseRejectionHandler = () =>{
    this.setState({
      hasError: true
    })
  }

  onResourceLoad = () =>{
    localStorage.clear()
    window.location.href='/login';
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (<Popup className="iserrorfound" open={true} closeOnDocumentClick={false}>
              <div><img src={OOPS} alt="oops" width="200px" height="124px"/></div>
              <div className="ohno">Oops!</div>
              <div className="connection_failed">We ran into a problem, please try again!</div>
              <div style={{marginTop:"20px"}}>
                <button onClick={this.onResourceLoad}>Try Again Now!</button>
              </div>
            </Popup>)
    }

    return this.props.children;
  }
}
