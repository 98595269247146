import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import common from '../../common';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Profile from '../../public/icons/user-profile.svg';
import Invite from '../../public/icons/invite.svg';
import Password from '../../public/icons/password.svg';
import Invitew from '../../public/icons/invite-w.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Pagination from "react-js-pagination";
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Select from 'react-select'
import MultiSelect from "react-multi-select-component";
const options = [
  { label: "Grapes 🍇", value: "grapes" },
  { label: "Mango 🥭", value: "mango" },
  { label: "Strawberry 🍓", value: "strawberry", disabled: true },
];


class AdminBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      openf: false,
      id: null,
      image: null,
      imageURL: null,
      name: '',
      email: '',
      mobile: '',
      roll_no: '',
      password: '',
      section: '',
      classs: '',
      batch: '',
      filterClass: '',
      filterClassName: '',
      filterSection: '',
      filterSectionName: '',
      class_centers: {},
      class_center: '',
      center_id: localStorage.getItem('center_id'),
      branch: localStorage.getItem('center_id'),
      filterCenter: localStorage.getItem('center_id'),
      class_names: {},
      section_names: {},
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      centers: [],
      branches: [],
      classes: [],
      filterClasses: [],
      sections: [],
      students: [],
      current_page:0,
      totalItems:0,
      filterStudents: [],
      mail_type: 1,
      user_ids: [],
      dis_branch: '',
      dis_code: '',
      invite: false,
      resend:false,
      is_search: false,
      is_primary:'',
      class_batch_mapping :[],
      sections1:{},
      classListOnChange:[], 
      bannerStruct:{
        banner_title:"",
        banner_description:"",
        banner_url:"",
        banner_level:"",
        banner_image:"",
        start_date_time:"",
        end_date_time:"",
        banner_type:"",
        banner_image_url:"",
        banner_page:"",
        banner_slug:"",
        webbanner_image:"",
        webbanner_image_url:"",
        ignore_class_ids:''
      },
      selected_banner_id:'',
      classList:[],
      batchList:[],
      batch_ids:'',
      classDataID:'',
      saveclasss_id:'',
      savebatch_ids:'',
      batchListData:[],
      newbatchList:[],
      selected:''

    }
  }
  
  componentDidMount() {

    this.getCertificationBannerList('',this.state.center_id)
    this.getCenterList();
    this.getClassListData()
  }

  getClassListData = async () => {
    const response = await this.props.getClassList();
    if(response !== undefined) {
      this.setState({ classList: response.data});
   }
  }

  onChangeClass = (e) =>{
    this.state.selected = '';
    let classDataID = e.target.selectedOptions[0].getAttribute('classid');
    this.setState({classDataID});
    console.log("classDataID")
    console.log(classDataID)
    if(classDataID !='' && classDataID != null){
      this.setState({ batch_ids: ''});
      let center_id = localStorage.getItem("center_id");
      let class_id = classDataID;
      let show_all=1;
      let page =localStorage.getItem("page_url")
      this.getSectionListData(center_id, class_id,show_all,page);
      }else{
        this.setState({ batch_ids: ''});
      }
  }

  selected = (selected) =>{
    console.log(selected);
    let {batch_ids, batchList,filterBatches} = this.state;
    let selectedOptionsArray = [];
     if(!Array.isArray(selected)){
      selectedOptionsArray.push(selected);
     }else{
      selectedOptionsArray =  selected;
     }
     batch_ids = selectedOptionsArray.map(data => data.id).join(',');
     this.setState({ selected,batch_ids})
  }

  onChangeBatch = (selectedOptions) =>{
    let {batch_ids, batchList,filterBatches} = this.state;
    let selectedOptionsArray = [];
     if(!Array.isArray(selectedOptions)){
      selectedOptionsArray.push(selectedOptions);
     }else{
      selectedOptionsArray =  selectedOptions;
     }
     batch_ids = selectedOptionsArray.map(data => data.id).join(',');
     this.setState({ batch_ids: batch_ids});
  }

  getSectionListData = async (center_id, class_id,show_all,page) => {
    const response = await this.props.getSectionList(center_id, class_id,show_all,page);
    if(response !== undefined) {
      let resp = response.data;
      resp.map(function(items){
        items.value = items.label;
      });
     
      this.setState({ batchList: resp});
    }
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data, centers: response.data });
    }
  }

  getCertificationBannerList = async(type,center) => {
    let response = await this.props.getCertificationBannerList(type,center);

    if(response !== undefined){
      this.setState({filterStudents: response.data })
    }
  }

  show = () => this.setState({ open: true, name: '', email: '', mobile: '', branch: '',
                                password: '' });
  
  close = () => this.setState({ open: false });

/****************function called on edit *********************************/
  show2 = async (value) => {

    if(this.state.is_search === true){
          const val ={"user":value._source,"id":value._source.student_id};

          this.setState({ open2: true, id: val.user.student_id,user_id:val.user.user_id,
                        name: val.user?.name === undefined || val.user?.name === null ? '' : val.user?.name, 
                        email: val.user?.email === undefined || val.user?.email === null ? '' : val.user?.email,
                        mobile: val.user?.mobile === undefined || val.user?.mobile === null ? '' : val.user?.mobile, 
                        image: val.user?.profile_picture === undefined || val.user?.profile_picture === null 
                                ? '' : val.user?.profile_picture, 
                        roll_no: val.user.roll_no === undefined || val.user.roll_no === null ? '' : val.user.roll_no,
                        imageURL: null, classs: val.user?.class_id === undefined ? '' 
                          : val.user?.class_id, branch: val.user?.center,
                        section: val.user?.batch_id === undefined ? '' : val.user?.batch_id,
                        dis_code: val.user?.roll_no === undefined || val.user?.roll_no === null ? '' : val.user?.roll_no,
                        dis_branch: val.user?.center_label });
          if(val.user?.class_id !== undefined && val.user?.class_id !='' && val.user?.class_id !=null) {
            this.getSectionList(this.state.class_centers[val.user?.class_id], val.user?.class_id);
          }

    }else{
      const val = value;

      this.setState({ open2: true, id: val.id, 
                        name: val.user.name === undefined || val.user.name === null ? '' : val.user.name, 
                        email: val.user.email === undefined || val.user.email === null ? '' : val.user.email,
                        mobile: val.user.mobile === undefined || val.user.mobile === null ? '' : val.user.mobile, 
                        image: val.user.profile_picture === undefined || val.user.profile_picture === null 
                                ? '' : val.user.profile_picture, 
                        roll_no: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
                        imageURL: null, classs: val.class_details.id === undefined ? '' 
                          : val.class_details.id, branch: val.user.center,
                        section: val.section_details.id === undefined ? '' : val.section_details.id,
                        //filterClasses: this.state.classes.filter(classs => classs.center === val.user.center),
                        dis_code: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
                        dis_branch: val.user.branch.name });
      if(val.class_details?.id !== undefined) {
        this.getSectionList(this.state.class_centers[val.class_details?.id], val.class_details?.id);
      }
    }

    document.getElementsByClassName("search-container")[0].style.display="none";
    document.getElementsByClassName("pagination")[0].style.cssText = 'display:none !important';
  }

  /****************************************************************/

  /**************function called on search box********************************/
  show3 = async (value) => {
    if(this.state.is_search === true){
          const val ={"user":value._source,"id":value._source.student_id};

          this.setState({ open3: true, id: val.user.student_id,user_id:val.user.user_id,
                        name: val.user.name === undefined || val.user.name === null ? '' : val.user.name, 
                        email: val.user.email === undefined || val.user.email === null ? '' : val.user.email,
                        mobile: val.user.mobile === undefined || val.user.mobile === null ? '' : val.user.mobile, 
                        image: val.user.profile_picture === undefined || val.user.profile_picture === null 
                                ? '' : val.user.profile_picture, 
                        roll_no: val.user.roll_no === undefined || val.user.roll_no === null ? '' : val.user.roll_no,
                        imageURL: null, classs: val.user?.class_id === undefined ? '' 
                          : val.user?.class_id, branch: val.user.center,
                        section: val.user?.batch_id === undefined ? '' : val.user?.batch_id,
                        dis_code: val.user.roll_no === undefined || val.user.roll_no === null ? '' : val.user.roll_no,
                        dis_branch: val.user?.center_label });
          if(val.user?.class_id !== undefined && val.user?.class_id !='' && val.user?.class_id !=null) {
            this.getSectionList(this.state.class_centers[val.user?.class_id], val.user?.class_id);
          }

          document.getElementsByClassName("search-container")[0].style.display="none";

    }else{
      const val = value;

      this.setState({ open3: true, id: val.id, user_id:val.user.id,
                      name: val.user.name === undefined || val.user.name === null ? '' : val.user.name, 
                      email: val.user.email === undefined || val.user.email === null ? '' : val.user.email,
                      mobile: val.user.mobile === undefined || val.user.mobile === null ? '' : val.user.mobile, 
                      image: val.user.profile_picture === undefined || val.user.profile_picture === null 
                              ? '' : val.user.profile_picture, 
                      roll_no: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
                      imageURL: null, classs: val.class_details.id === undefined ? '' 
                        : val.class_details.id, branch: val.user.center,
                      section: val.section_details.id === undefined ? '' : val.section_details.id,
                      //filterClasses: this.state.classes.filter(classs => classs.center === val.user.center),
                      dis_code: val.roll_no === undefined || val.roll_no === null ? '' : val.roll_no,
                      dis_branch: val.user.branch.name });
    if(val.class_details?.id !== undefined) {
      this.getSectionList(this.state.class_centers[val.class_details?.id], val.class_details?.id);
    }
    }
  }
  /*******************************************************/
  
  close2 = () => {
     document.getElementsByClassName("search-container")[0].style.display="flex";
     document.getElementsByClassName("pagination")[0].style.cssText = 'display:content !important';
    this.setState({ open2: false });
  }

  close3 = () => {
    let {class_batch_mapping} = this.state;

    class_batch_mapping =[];

    var option = {
          "class_id":"",
          "batch_id":""
    }

    class_batch_mapping.push(option);
    this.setState({ open3: false ,class_batch_mapping});
  }

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  showf = (val) => this.setState({ openf: true });
  
  closef = () => this.setState({ openf: false });

  bulkInvite = () => this.setState({ invite: true, 
    user_ids: this.state.filterStudents.map(function (obj) {return obj.id}) });

  bulkResendPassword = () => this.setState({ resend: true, 
    user_ids: this.state.filterStudents.map(function (obj) {return obj.user.id}) });

  closeInvite = () => this.setState({ invite: false, user_ids: [] });
  closeResendPassword  = () => this.setState({ resend: false, user_ids: [] });

  getStudentList = async (center,filterClass,filterSection,page) => {
    const response = await this.props.getStudentList(center,filterClass,filterSection,page);
    if(response !== undefined) {
      this.setState({ students: response.data.data, filterStudents: response.data.data,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20 })
    }
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data, centers: response.data });
    }
  }

  getClassList = async () => {
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ), 
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
    }
  }

  getSectionList = async (class_center, class_id) => {
    const response = await this.props.getSectionList2(class_center, class_id);
    if(response !== undefined) {

      this.setState({ sections: response.data, section_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}) });
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  filterCenterChange = (e) => {
    console.log(e.target.value)
    if(e.target.value === 'Filter by Center') {
      this.setState({ filterCenter: ''});
      this.getCertificationBannerList('',e.target.value);
    }
    else {
      this.setState({ filterCenter: e.target.value});
      this.getCertificationBannerList('',e.target.value);
    }
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  rollChange = (e) => {
    this.setState({ roll_no: e.target.value })
  }

  emailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  mobileChange = (e) => {
    const mobile = (e.target.validity.valid) ? e.target.value : this.state.mobile;
    this.setState({ mobile })
  }

  passwordChange = (e) => {
    this.setState({ password: e.target.value })
  }

  branchChange = (e) => {
    if(e.target.value === 'Select Branch' || e.target.value === 'Edit Branch') {
      this.setState({ branch: '' })
    }
    else {
      this.setState({ branch: e.target.value })
    }
  }

  classChange = (e) => {

    if(e.target.value === 'Assign Class') {
      this.setState({ classs: '', section: '', class_center: '' })
    }
    else {
      this.setState({ classs: e.target.value, section: '', 
          class_center: this.state.class_centers[e.target.value] })

      this.getSectionList(this.state.class_centers[e.target.value], e.target.value)
    }
  }

  classChange1 = (e,index) => {
    let {class_batch_mapping,filterClasses,classListOnChange} = this.state

    if(e.target.value === 'Assign Class') {
      this.setState({ classs: '', section: '', class_center: '' })
    }
    else {

      classListOnChange.push(parseInt(e.target.value));
      this.setState({ classs: e.target.value, section: '', 
          class_center: this.state.class_centers[e.target.value] })

      class_batch_mapping[index].class_id=e.target.value;

      this.setState({class_batch_mapping,filterClasses,classListOnChange})

      this.getSectionList1(this.state.class_centers[e.target.value], e.target.value,index)
    }
  }

  getSectionList1 = async (class_center, class_id,index) => {

    let {sections1} = this.state;

    const response = await this.props.getSectionList2(class_center, class_id);
    if(response !== undefined) {
      
      sections1[index] = response.data;

      this.setState({ sections: response.data ,sections1,section_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}) });
    }
  }


  sectionChange = (e) => {

    if(e.target.value === 'Assign Section') {
      this.setState({ section: '' })
    }
    else {
      this.setState({ section: e.target.value })
    }
  }

  sectionChange1 = (e,index) => {
    let {class_batch_mapping} = this.state

    if(e.target.value === 'Assign Section') {
      this.setState({ section: '' })
    }
    else {
      this.setState({ section: e.target.value })
      class_batch_mapping[index].batch_id=e.target.value;

      this.setState({class_batch_mapping})
    }
  }

  classChange2 = (e) => {
    if(e.target.value === 'Select Class') {
      this.setState({ filterClass: '', filterSection: '', filterClassName: '',
                        filterSectionName: '' });
    }
    else {
      this.setState({ filterClass: e.target.value, filterSection: '', filterSectionName: '',
                        filterClassName: this.state.class_names[e.target.value],
                        class_center: this.state.class_centers[e.target.value] })
      this.getSectionList(this.state.class_centers[e.target.value], e.target.value)
    }
  }

  sectionChange2 = (e) => {
    if(e.target.value === 'Select Section') {
      this.setState({ filterSection: '', filterSectionName: '' })
    }
    else {
      this.setState({ filterSection: e.target.value, 
          filterSectionName: this.state.section_names[e.target.value] });
    }
  }

  filterClassSection = () => {
    if(this.state.filterClass !== '' && this.state.filterSection !== '') {
      /*var students = this.state.students.filter(student => {
        return student?.class_details?.id === parseInt(this.state.filterClass) 
          && student?.section_details?.id === parseInt(this.state.filterSection)
      });
      this.setState({ filterStudents: students });*/
      this.getStudentList(this.state.center_id,this.state.filterClass,this.state.filterSection,this.state.current_page);
    }
    this.setState({ openf: false })
  }

  removeFilters = () => {

    this.setState({ filterClass: '', filterSection: '', filterClassName: '',
              filterSectionName: '', openf: false });

    this.getStudentList(this.state.center_id,'','',this.state.current_page);
  }

  getStudentCenter = (center) =>{

    if(center){
      return this.state.branches.filter(p => p.id == center).map( center => center.name)[0];
    }
    
  }

  handleChange = (event) => {
    if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({ 
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `Only images can be uploaded here.`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('img-input').value = "";
  }

  newStudent = async () => {
    const { name, email, mobile, password, center_id, roll_no } = this.state;
    if(common.validateEmail(email)) {
      await this.props.createStudent(name, email, mobile, password, center_id, roll_no);
      this.setState({ open: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: 'Please enter a valid email',
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  updateStudent = async () => {
    const { id, name, email, mobile, password, classs, image, section } = this.state;
    if(common.validateEmail(email)) {
      await this.props.updateStudent(id, name, email, mobile, password, classs, image, section);
      this.setState({ open2: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: 'Please enter a valid email',
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }


  deleteBanner = async (d_id) => {
    console.log(this.state.filterStudents);
    let bannerid = d_id.id;
    console.log(bannerid);
    const response = await this.props.deleteBanner(bannerid);
    if(response !== undefined) {
      this.setState({ opend: false });
      console.log("response")
    }
  }


  
  deleteStudent = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteStudent(d_id, center_id);
    this.setState({ opend: false });
  }

  sendInvite = async (val) => {
    const { mail_type } = this.state;
    await this.props.sendInvite(mail_type, [val]);
  }

  resendPassword = async (val) => {
    await this.props.sendInvitePassword([val]);
  }

  sendBulkInvite = async () => {
    const { mail_type, user_ids } = this.state;
    await this.props.sendInvite(mail_type, user_ids); 
    this.setState({ user_ids: [], invite: false })
  }

  sendBulkPassword = async () => {

    const {user_ids } = this.state;
    await this.props.sendInvitePassword(user_ids); 
    this.setState({ user_ids: [], resend: false })
  }

  changeIds = (val) => {
    if(this.state.user_ids.indexOf(val) !== -1) {
      const index = this.state.user_ids.indexOf(val);
      this.state.user_ids.splice(index, 1);
    }
    else {
      this.state.user_ids.push(val);
    }
  }

  show4 = async(page) => {

    if(this.state.is_search){
      const response = await this.props.userSearchList(this.state.text,page);
      if(response !== undefined) {
        this.setState({is_search:true, students: response.data.data, filterStudents: response.data.data})
      }
    }else{
       const response = await this.props.getStudentList(this.state.center_id,this.state.filterClass,this.state.filterSection,page);
      if(response !== undefined) {
        this.setState({ students: response.data.data, filterStudents: response.data.data})
      }
    }
   
    this.setState({current_page:page});
  }

  handlePageChange(pageNumber) {
    this.show4(pageNumber);
    this.setState({current_page: pageNumber});
  }

  show6 = async (e) => {
    //if(!e.target.value) document.getElementsByClassName("tab-content")[0].style.display="none";
    this.setState({text:e.target.value})
    if(e.target.value){
      const response = await this.props.userSearchList(e.target.value,1);
      if(response !== undefined) {
        this.setState({is_search:true, students: response.data.data, filterStudents: response.data.data,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20 })
      }
    }else{
      const response = await this.props.getStudentList(this.state.center_id,this.state.filterClass,this.state.filterSection,this.state.current_page);
      if(response !== undefined) {
        this.setState({ is_search:false,students: response.data.data, filterStudents: response.data.data,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20 })
      }
    }
  }

  changeBannerField = (e) =>{

    let {bannerStruct} = this.state

    let name = e.target.name
    let value = e.target.value

    if(name =="banner_image" && e.target.files[0].type.split('/')[0]) {
      bannerStruct['banner_image'] = e.target.files[0]
      bannerStruct['banner_image_url'] =  URL.createObjectURL(e.target.files[0])
    }else{
      bannerStruct[name] = value
    }
    
    this.setState({bannerStruct})
  }



  ignoreClassIDS = (e) =>{

    //console.log(e.target.value)
    let {bannerStruct} = this.state

    let name = e.target.name
    let value = e.target.value
    bannerStruct['ignore_class_ids'] = value
    this.setState({bannerStruct});
    console.log(bannerStruct)
  }


  web_imagechangeBannerField = (e) =>{

    let {bannerStruct} = this.state

    let name = e.target.name
    let value = e.target.value
    
    if(name =="webbanner_image" && e.target.files[0].type.split('/')[0]) {
      bannerStruct['webbanner_image'] = e.target.files[0]
      bannerStruct['webbanner_image_url'] =  URL.createObjectURL(e.target.files[0])
    }else{
      bannerStruct[name] = value
    }

    this.setState({bannerStruct})
  }


  addOption = (val) =>{
    let {class_batch_mapping} = this.state;

    var option = {
          "class_id":"",
          "batch_id":""
    }
    class_batch_mapping.push(option);

    this.setState({class_batch_mapping})
  }

  classbatchmapping = async () =>{

    const {class_batch_mapping,center_id,user_id} = this.state

    const response = await this.props.classbatchmapping(center_id,user_id,class_batch_mapping);
    
  }

  addBanner = () =>{
    let {bannerStruct} = this.state

    bannerStruct['banner_title'] = '';
    bannerStruct['banner_description'] ='';
    bannerStruct['banner_url'] = '';
    bannerStruct['banner_level'] = '';
    bannerStruct['banner_image'] = '';
    bannerStruct['start_date_time'] = '';
    bannerStruct['end_date_time'] = '';
    bannerStruct['banner_type'] = '';
    bannerStruct['banner_image_url'] = '';
    bannerStruct['webbanner_image'] = '';
    bannerStruct['webbanner_image_url'] = '';
    bannerStruct['ignore_class_ids'] = '';
    this.setState({addBanner:true,show:false})
  }

  editBanner = async (val) =>{
    console.log(val)
    let {bannerStruct,batch_ids,newbatchList} = this.state
    bannerStruct['banner_title'] = val.title;
    bannerStruct['banner_description'] = val.content;
    bannerStruct['banner_url'] = val.url;
    bannerStruct['banner_level'] = val.level;
    bannerStruct['banner_image'] = '';
    bannerStruct['start_date_time'] = val.start_date_time;
    bannerStruct['end_date_time'] = val.end_date_time;
    bannerStruct['banner_type'] = val.banner_type;
    bannerStruct['banner_image_url'] = val.image;
    bannerStruct['banner_slug'] = val.ext?.slug;
    bannerStruct['banner_page'] = val.ext?.page;
    bannerStruct['webbanner_image'] = '';
    bannerStruct['webbanner_image_url'] = val.web_image;
    bannerStruct['ignore_class_ids'] =  val.ignore_class_ids;
    
    let classid = val?.classs_id;
    let batchids = val?.batch_ids;
    this.setState({saveclasss_id:classid,classDataID:classid})
    this.setState({batch_ids:batchids})
    this.setState({editBanner:true,show:false,bannerStruct,selected_banner_id:val.id});
    if(classid !=''){
      let center_id = localStorage.getItem("center_id");
      let class_id = classid;
      let show_all=1;
      let page =localStorage.getItem("page_url")
      //this.getSectionListData(center_id, class_id,show_all,page);
      const response = await this.props.getSectionList(center_id, class_id,show_all,page);
        if(response !== undefined) {
          let resp = response.data;
          resp.map(function(items){
            items.value = items.label;
          });
          this.setState({batchList:resp});

          if(batchids){
            let batchListData = this.state.batchList.filter(items => batchids.includes(items.id));
           batchListData.map(function(items){
              delete items['center_details'];
            });
            this.setState({ batchListData});   
            this.setState({ selected:batchListData});   
          }
      }
    }
     
  }

  closeEditBanner = () =>{
    this.setState({editBanner:false})
  }

  closeAddBanner = () =>{
    this.setState({addBanner:false})
  }

  addNewBanner = async() =>{
    const {bannerStruct} = this.state
    console.log(bannerStruct);
    let ext ='';
    if(bannerStruct['banner_type'] == 'partner_site')
      ext = {"page":bannerStruct['banner_page'],"slug":bannerStruct['banner_slug']}
    const response = await this.props.bannerCreate(bannerStruct['banner_title'], bannerStruct['banner_description'], bannerStruct['start_date_time'], bannerStruct['end_date_time'], bannerStruct['banner_type'], bannerStruct['banner_level'], bannerStruct['banner_image'],  bannerStruct['banner_url'],bannerStruct['banner_description'],this.state.classDataID,this.state.center_id,ext ? JSON.stringify(ext):'',this.state.batch_ids, bannerStruct['webbanner_image'],bannerStruct['ignore_class_ids'])
  
    this.setState({addBanner:false,show:true})

    //window.location.reload()
    //this.getCertificationBannerList('banner',this.state.center_id)
  }

  editNewBanner = async() =>{

    const {bannerStruct,selected_banner_id} = this.state
    let ext ='';
    if(bannerStruct['banner_type'] == 'partner_site')
      ext = {"page":bannerStruct['banner_page'],"slug":bannerStruct['banner_slug']}

    const response = await this.props.bannerUpdate(selected_banner_id,bannerStruct['banner_title'], bannerStruct['banner_description'], bannerStruct['start_date_time'], bannerStruct['end_date_time'], bannerStruct['banner_type'], bannerStruct['banner_level'], bannerStruct['banner_image'], bannerStruct['banner_url'],bannerStruct['banner_description'],this.state.classDataID,this.state.center_id,ext ? JSON.stringify(ext):'',this.state.batch_ids,bannerStruct['webbanner_image'], bannerStruct['ignore_class_ids'])
    
    this.setState({editBanner:false,show:true})

  }

  render() {
    const { classListOnChange,newbatchList } = this.state

    const isEnabled = this.state.name.length > 0 && this.state.email.length > 0 &&
              (this.state.mobile + '').length === 10 && this.state.password.length > 0 &&
              this.state.roll_no.length > 0 && this.state.branch !== '';
    const isEnabled2 = this.state.name.length > 0 && this.state.email.length > 0 &&
              (this.state.mobile +'').length === 10;
              // && this.state.roll_no.length > 0;
    const isEnabled3 = this.state.user_ids.length > 0;
    const isEnabledf = this.state.filterClass !== '' && this.state.filterSection !== '';
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search students"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>Students</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>Students</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder="Search students"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content">
          <div className="popup-header">
            Add new student
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <input placeholder="Student Name" type="text" onChange={this.nameChange}/>
              <input placeholder="Roll No." type="text" onChange={this.rollChange}/>
            </div>
            <div className="popup-inputs">
              <input placeholder="Email ID" type="email" onChange={this.emailChange}/>
              <input placeholder="Mobile No." type="text" onInput={this.mobileChange}
                maxLength={10} value={this.state.mobile} pattern="[0-9]*"/>
            </div>
            <div className="popup-inputs">
              <select value={this.state.branch} onChange={this.branchChange}>
                <option value={''}>
                  Select Branch
                </option>
                {this.state.branches.map((branch, index) =>
                  <option value={branch.id} key={index}>
                    {branch.name}
                  </option>
                )}
              </select>
              <input placeholder="Set Password" type="password" onChange={this.passwordChange}/>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newStudent}>Add new</button>
          </div>
        </Popup>

        

        <Popup open={this.state.addBanner} closeOnDocumentClick onClose={this.closeAddBanner} className="row-type">
          <div className="add-row newDesign">
            <span className="close" onClick={this.closeAddBanner}>&#x2715;</span>
            <div className="sub-div-01">
              <span>Add Banner</span>
              <hr/>
              <div className="newDesign">
              <div className="row">
                <div className="col-25">
                  <label for="fname">Title</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_title']} onChange={this.changeBannerField} name="banner_title"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Description</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_description']} onChange={this.changeBannerField} name="banner_description"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Start date</label>
                </div>
                <div className="col-75">
                  <input type="datetime-local" value={this.state.bannerStruct['start_date_time']} onChange={this.changeBannerField} name="start_date_time"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">End date</label>
                </div>
                <div className="col-75">
                  <input type="datetime-local" value={this.state.bannerStruct['end_date_time']} min={this.state.bannerStruct['start_date_time']} onChange={this.changeBannerField} name="end_date_time"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Banner type</label>
                </div>
                <div className="col-75">
                  <select value={this.state.bannerStruct['banner_type']} onChange={this.changeBannerField} name="banner_type">
                    <option value="Certification_Academy">Certification Academy</option>
                    <option value="Live_Projects">Live Projects</option>
                    <option value="Stationary_Shop">Stationary Shop</option>
                    <option value="Events">Events</option>
                    <option value="Generic">Generic</option>
                    <option value="banner">banner</option>
                    <option value="heading">heading</option>
                    <option value="partner_site">Partner Site</option>
                  </select>
                </div>
              </div>
              <div className="row" style={{display: this.state.bannerStruct['banner_type'] == 'partner_site'?"block":"none"}}>
                <div className="col-25">
                  <label for="fname">slug</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_slug']} onChange={this.changeBannerField} name="banner_slug"/>
                </div>
              </div>
              <div className="row" style={{display: this.state.bannerStruct['banner_type'] == 'partner_site'?"block":"none"}}>
                <div className="col-25">
                  <label for="fname">page</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_page']} onChange={this.changeBannerField} name="banner_page"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">level</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_level']} onChange={this.changeBannerField} name="banner_level"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Url</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_url']} onChange={this.changeBannerField} name="banner_url"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Mobile Image</label>
                </div>
                <div className="col-75">
                  <input type="file" value="" onChange={this.changeBannerField} name="banner_image"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Web Image</label>
                </div>
                <div className="col-75">
                  <input type="file" value="" onChange={this.web_imagechangeBannerField} name="webbanner_image"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Ignore Class IDS</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['ignore_class_ids']} onChange={this.ignoreClassIDS} name="ignore_class_ids"/>
                </div>
              </div>
              <div className="addBannerClassWrapper">
                  <div className="block">
                    <label>Select Class</label>
                    <select onChange={this.onChangeClass}>
                        <option value="">Select Class</option>
                        {this.state.classList.map((items) => (
                          <option value={items.label} key={items.id} classid={items.id}>{items.label}</option>
                        ))}
                    </select>  
                  </div>
                  {this.state.batchList.length > 0 &&  
                  <div className="block">
                    <label>Select Batch</label>
                    <Select isClearable={false} isSearchable="false"  placeholder={"Select Batch"} onChange={this.onChangeBatch} options={this.state.batchList} isMulti />
                    
                    {/* <select onChange={this.onChangeBatch}>
                        <option value="">Select Batch</option>
                        {this.state.batchList.map((items,index) => (
                          <option value={items.label} batchid={items.id} key={index}>{items.label}</option>
                        ))}
                    </select>   */}
                  </div>  
                  }
              </div>
              </div>
            </div>
            <div id="type-button" className="dialogactions">
              <button className="widget-button" onClick={this.addNewBanner}>Add</button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.editBanner} closeOnDocumentClick onClose={this.closeEditBanner} className="row-type">
          <div className="add-row newDesign">
            <span className="close" onClick={this.closeEditBanner}>&#x2715;</span>
            <div className="sub-div-01">
              
              <span>Edit Banner</span>
              <hr/>
              <div className="newDesign">
              <div className="row">
                <div className="col-25">
                  <label for="fname">Title</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_title']} onChange={this.changeBannerField} name="banner_title"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Description</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_description']} onChange={this.changeBannerField} name="banner_description"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Start date</label>
                </div>
                <div className="col-75">
                  <input type="datetime-local" value={this.state.bannerStruct['start_date_time']} onChange={this.changeBannerField} name="start_date_time"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">End date</label>
                </div>
                <div className="col-75">
                  <input type="datetime-local" value={this.state.bannerStruct['end_date_time']} onChange={this.changeBannerField} name="end_date_time"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Banner type</label>
                </div>
                <div className="col-75">
                  <select value={this.state.bannerStruct['banner_type']} onChange={this.changeBannerField} name="banner_type">
                    <option value="Certification_Academy">Certification Academy</option>
                    <option value="Live_Projects">Live Projects</option>
                    <option value="Stationary_Shop">Stationary Shop</option>
                    <option value="Events">Events</option>
                    <option value="Generic">Generic</option>
                    <option value="banner">banner</option>
                    <option value="heading">heading</option>
                    <option value="partner_site">Partner Site</option>
                  </select>
                </div>
              </div>
              <div className="row" style={{display: this.state.bannerStruct['banner_type'] == 'partner_site'?"block":"none"}}>
                <div className="col-25">
                  <label for="fname">slug</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_slug']} onChange={this.changeBannerField} name="banner_slug"/>
                </div>
              </div>
              <div className="row" style={{display: this.state.bannerStruct['banner_type'] == 'partner_site'?"block":"none"}}>
                <div className="col-25">
                  <label for="fname">page</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_page']} onChange={this.changeBannerField} name="banner_page"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">level</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_level']} onChange={this.changeBannerField} name="banner_level"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Url</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_url']} onChange={this.changeBannerField} name="banner_url"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Mobile Image</label>
                </div>
                <div className="col-75">
                  <div className='previewBannerImage'>
                    <input type="file" value="" onChange={this.changeBannerField} name="banner_image"/>
                    {this.state.bannerStruct['banner_image_url'] && <div className='previewBannerImage'><img src={this.state.bannerStruct['banner_image_url']}/></div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">web Image</label>
                </div>
                <div className="col-75">
                  <div className='previewBannerImage'>
                    <input type="file" value="" onChange={this.web_imagechangeBannerField} name="webbanner_image"/>
                    {this.state.bannerStruct['webbanner_image_url'] && <div className='previewBannerImage'><img src={this.state.bannerStruct['webbanner_image_url']}/></div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Ignore Class IDS</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['ignore_class_ids']} onChange={this.ignoreClassIDS} name="ignore_class_ids"/>
                </div>
              </div>
                  <div className="addBannerClassWrapper">
                  <div className="block">
                    <label>Select Class</label>
                    <select onChange={this.onChangeClass}>
                        <option value="">Select Class</option>
                        {this.state.classList.map((items) => (
                          <option value={items.label} selected={items.id == this.state.saveclasss_id} key={items.id} classid={items.id}>{items.label}</option>
                        ))}
                    </select>  
                  </div>
                  {this.state.batchList.length > 0 && 
                  <div className="block">
                    <label>Select Batch</label>
                    {/* <Select isClearable={false} defaultValue={this.state.batchListData[0]} isSearchable={false}  placeholder={"Select Batch"} onChange={this.onChangeBatch} options={this.state.batchList} isMulti /> */}
                    <MultiSelect
                    options={this.state.batchList}
                    value={this.state.selected}
                    onChange={this.selected}
                    disableSearch = "false"
                    labelledBy="Select Batch"
                    ClearIcon="false"
                  />      
                  </div> 
                  } 
                  </div>   
              </div>
            </div>
            <div id="type-button" className="dialogactions">
              <button className="widget-button" onClick={this.editNewBanner}>Update</button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.openf} closeOnDocumentClick onClose={this.closef}
          className="popup-content5">
          <div className="popup-header">
            Filter with class and section
          </div>
          <span className="close" onClick={this.closef}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select value={this.state.filterClass} onChange={this.classChange2}>
                <option value={''}>
                  Select Class
                </option>
                {this.state.filterClasses.map((classs, index) =>
                  <option value={classs.id} key={index}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select value={this.state.filterSection} onChange={this.sectionChange2}>
                <option value={''}>
                  Select Section
                </option>
                {this.state.sections.map((section, index) =>
                  <option value={section.id} key={index}>
                    {section.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="filter-buttons">
            <span style={this.state.filterClass === '' && this.state.filterSection === '' 
              ? {pointerEvents: 'none'} : {}} onClick={this.removeFilters}>Remove Filters</span>
            <button onClick={this.filterClassSection} disabled={!isEnabledf}>
              Save filter
            </button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            Are you sure you want to<br/>delete this Banner?
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              Cancel
            </button>
            <button onClick={(bannerid) => this.deleteBanner(this.state.d_id)}>
              Delete
            </button>
          </div>
        </Popup>
        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1-ques">
              {!this.state.open3 && <div className="search-head">
                {/*<img src={Search} alt="search"/>
                <input placeholder={this.props.placeholder}/>*/}
                {/*<div className="search-wrapper-admin">
                  <div className="search-container">
                    <input type="search" placeholder="Search students.." onChange = {this.show6} onFocus={this.handleUsernameFocus} value={this.state.text || ''}/>
                  </div>  
                </div>*/}
              </div>}
            </div>
            <div className="heading-part2" style={{display:"none"}}>
              {this.state.open2 === false ? <>
                {this.state.invite ? <>
                  <button id="button-reverse" onClick={this.closeInvite}>
                    Cancel
                  </button>
                  <button disabled={!isEnabled3} onClick={this.sendBulkInvite}>
                    Send
                  </button>
                </> : <>  {this.state.resend ? <>
                  <button id="button-reverse" onClick={this.closeResendPassword}>
                    Cancel
                  </button>
                  <button disabled={!isEnabled3} onClick={this.sendBulkPassword}>
                    Send
                  </button>
                </>  :<>
                  <span onClick={this.bulkInvite}>
                    <img src={Invitew} alt="img"/>Send Invite
                  </span>
                  <span onClick={this.bulkResendPassword}>
                    <img src={Password} alt="img"/>Resend Password
                  </span>
                  {this.state.is_primary && <span onClick={this.show}>
                    <img src={Add} alt="img"/>Add New
                  </span>}
                  {!this.state.list ? <span onClick={() => this.changeView('false')}>
                    <img src={List} alt="img"/>List View
                  </span> : <span onClick={() => this.changeView('true')}>
                    <img src={Grid} alt="img"/>Grid View
                  </span>}
                </>}
              </> }</>: null}
            </div>
            <div className="heading-part2">
              <span onClick={this.addBanner}>
                <img src={Add} alt="img"/>Add New
              </span>
            </div>
          </div>
          {!this.state.is_search && <div className="sub-filter">
            {!this.state.open2 && <select defaultValue={parseInt(this.state.filterCenter)} 
              onChange={this.filterCenterChange}>
              <option value="Filter by Center">--select--</option>
              {this.state.centers.map(center =>
                <option key={center.id} value={center.id}>{center.name}</option>
              )}
            </select>}
            {/*!this.state.open2 && <>
              {this.state.filterClass !== '' && this.state.filterSection !== ''
                ? <div className="sub-filter-x" onClick={this.showf}>
                {this.state.filterClassName} | {this.state.filterSectionName}
              </div> : <div className="sub-filter-x" onClick={this.showf}>
                Filter Class & Section
              </div>}
            </>*/}
          </div>}
          {this.state.filterStudents.length === 0 ? <Empty/> : <>
            {this.state.open2 ? <div className="edit-div">
              <div className="edit-div-1">
                {this.state.image === null || this.state.image === '' 
                ? <div className="edit-dp" onClick={() => this.fileInput.click()}>
                  <img id="img-holder" src={ImgHolder} alt="DP"/>
                </div> : <div className="edit-dp" style={this.state.imageURL === null
                  ? {backgroundImage: "url("+common.addFileUrl(this.state.image)+")"}
                  : {backgroundImage: "url("+this.state.imageURL+")"}}>
                  <span onClick={this.removeImage}>&#x2715;</span>
                </div>}
                <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                <div className="edit-sub-div-1">
                  <input placeholder="Student Name" value={this.state.name} type="text"
                    onChange={this.nameChange}/>
                  <input placeholder="Email ID" value={this.state.email} type="email"
                    onChange={this.emailChange}/>
                </div>
              </div>
              <div className="edit-div-2">
                <input placeholder="Mobile No." type="text" onInput={this.mobileChange}
                  maxLength={10} value={this.state.mobile} pattern="[0-9]*"/>
                <input placeholder="Password" type="password" onChange={this.passwordChange}/>
              </div>
              <div className="edit-div-2">
                <div>
                  {this.state.dis_branch}
                </div>
                <div>
                  {this.state.dis_code}
                </div>
              </div>
              {/* <div className="edit-div-4">
                <select value={this.state.branch} onChange={this.branchChange}>
                  <option value={null}>
                    Edit Branch
                  </option>
                  {this.state.branches.map((branch, index) =>
                    <option value={branch.id} key={index}>
                      {branch.name}
                    </option>
                  )}
                </select>
              </div> */}
              <div className="edit-div-3">
                <select value={this.state.classs} onChange={this.classChange}>
                  <option value={''}>
                    Assign Class
                  </option>
                  {this.state.filterClasses.map((classs, index) =>
                    <option value={classs.id} key={index}>
                      {classs.label}
                    </option>
                  )}
                </select>
                <select value={this.state.section} onChange={this.sectionChange}>
                  <option value={''}>
                    Assign Section
                  </option>
                  {this.state.sections.map((section, index) =>
                    <option value={section.id} key={index}>
                      {section.label}
                    </option>
                  )}
                </select>
              </div>
              <div className="edit-buttons">
                <button id="button-reverse" onClick={this.close2}>
                  Cancel
                </button>
                <button disabled={!isEnabled2} onClick={this.updateStudent}>
                  Save
                </button>
              </div>
            </div> :<>{this.state.open3 ? <div className="edit-div">
              <div className="edit-div-1">
                {this.state.image === null || this.state.image === '' 
                ? <div className="edit-dp" onClick={() => this.fileInput.click()}>
                  <img id="img-holder" src={ImgHolder} alt="DP"/>
                </div> : <div className="edit-dp" style={this.state.imageURL === null
                  ? {backgroundImage: "url("+common.addFileUrl(this.state.image)+")"}
                  : {backgroundImage: "url("+this.state.imageURL+")"}}>
                  <span onClick={this.removeImage}>&#x2715;</span>
                </div>}
                <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                <div className="edit-sub-div-1">
                  <input placeholder="Student Name" value={this.state.name} type="text"
                    onChange={this.nameChange} disabled="disabled"/>
                  <input placeholder="Email ID" value={this.state.email} type="email"
                    onChange={this.emailChange} disabled="disabled"/>
                </div>
              </div>
              <div className="edit-div-2">
                <input placeholder="Mobile No." type="text" onInput={this.mobileChange}
                  maxLength={10} value={this.state.mobile} pattern="[0-9]*" disabled="disabled"/>
                <input placeholder="Password" type="password" onChange={this.passwordChange} disabled="disabled"/>
              </div>
              <div className="edit-div-2">
                <div>
                  {this.state.dis_branch}
                </div>
                <div>
                  {this.state.dis_code}
                </div>
              </div>
              <div>
              {this.state.class_batch_mapping && this.state.class_batch_mapping?.map((val,vindex) =>
                <div className="edit-div-3">
                  <select value={this.state.class_batch_mapping[vindex]?.class_id} onChange={(e) => this.classChange1(e, vindex)}>
                    <option value={''}>
                      Assign Class
                    </option>
                    {this.state.filterClasses.map((classs, index) =>
                      <option value={classs.id} key={index} disabled={classListOnChange.indexOf(classs.id) !== -1 ?"disabled":""}>
                        {classs.label}
                      </option>
                    )}
                  </select>
                  <select value={this.state.class_batch_mapping[vindex]?.batch_id} onChange={(e) => this.sectionChange1(e, vindex)}>
                    <option value=''>
                      Assign Section
                    </option>
                    {this.state.sections1 && this.state.sections1[vindex]?.map((section, index) =>
                      <option value={section.id} key={index}>
                        {section.label}
                      </option>
                    )}
                  </select>
                </div>
                
              )}
              <div className="add-more"><button onClick={() => this.addOption()}>Map More</button></div>
              </div>
              <div className="edit-buttons">
                <button id="button-reverse" onClick={this.close3}>
                  Cancel
                </button>
                <button disabled={!isEnabled2} onClick={this.classbatchmapping}>
                  Save
                </button>
              </div>
            </div> : <>  {this.state.list ? <div className="list-head list-assessment">
                <div className="list-title">
                  Title
                </div>
                <div className="list-section">
                  Start Date
                </div>
                <div className="list-class">
                  Type
                </div>
                <div className="list-action">
                  Actions
                </div>
              </div> : null}
              {this.state.list ? <div className="the-list the-assessment-list">
                {this.state.filterStudents.map((student, index) =>
                  <div className="list-item" key={student.id}>
                    <div className="title-item3">
                      {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(student.id)}
                        defaultChecked={this.state.user_ids.indexOf(student.id) !== -1 ? true : false}/>}
                      {this.state.resend && <input type="checkbox" onClick={() => this.changeIds(student.id)}
                        defaultChecked={this.state.user_ids.indexOf(student.id) !== -1 ? true : false}/>}
                      {student?.image === null || student?.image === ''
                        ? <img src={Profile} alt="dp"/> : <div className="dp_div"
                        style={{backgroundImage: "url("+common.addFileUrl(student?.image)+")"}}>
                      </div>}
                      <div>
                        {student.title}
                      </div>
                    </div>
                    <div className="section-item">
                      {student.start_date_time}
                    </div>
                    <div className="class-item">
                      {student.banner_type}
                    </div>
                   
                    <div className="action-item">
                      {/* <span title="Edit" onClick={() => this.show2(student)}>
                        <img src={Edit} alt="edit"/>
                      </span>
                      <span title="Delete" onClick={() => this.showd(student?.id ? student?.id : student?._source.student_id)}>
                        <img src={Delete} alt="delete"/>
                      </span> */}
                      <span title="Edit" onClick={() => this.editBanner(student)}>
                          <img src={Edit} alt="edit"/>
                        </span>
                        {/* <span title="Delete" onClick={() => this.showd(student?.id ? student?.id : student?._source.student_id)}>
                          <img src={Delete} alt="delete"/>
                        </span> */}
                        <span title="Delete" onClick={() => this.showd(student)}>
                          <img src={Delete} alt="delete"/>
                        </span>
                      {/*<div className="invite-class">
                        <div title="Invite" id="send-invite" 
                          onClick={() => this.sendInvite(student?.id ? student?.id : student?._source.student_id)}>
                          <img src={Invite} alt="invite"/>Send invite
                        </div>
                        <div title="Invite" id="send-invite" 
                          onClick={() => this.resendPassword(student.user?.id ? student.user?.id : student?._source.student_id)}>
                          <img src={Password} alt="invite"/>Resend Password
                        </div>
                      </div>*/}
                    </div>
                  </div>
                )}
              </div> : <div className="the-grid">
                {this.state.filterStudents.map((student, index) =>
                  <div className="grid-item" key={index}>
                    <div className="grid-up">
                      <div className="title-grid4">
                        {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(student.id)}
                          defaultChecked={this.state.user_ids.indexOf(student.id) !== -1 ? true : false}/>}
                        {this.state.resend && <input type="checkbox" onClick={() => this.changeIds(student.id)}
                        defaultChecked={this.state.user_ids.indexOf(student.user.id) !== -1 ? true : false}/>}
                        {student.image === null || student.image === ''
                          ? <img src={Profile} alt="dp"/> : <div className="dp_div"
                            style={{backgroundImage: "url("+common.addFileUrl(student.image)+")"}}>
                        </div>}
                        <div>
                          {student.title}
                        </div>
                      </div>
                      <div className="grid-actions">
                        {/*<span title="Edit" onClick={() => this.show3(student)}>
                          map
                        </span>*/}
                        <span title="Edit" onClick={() => this.editBanner(student)}>
                          <img src={Edit} alt="edit"/>
                        </span>
                        {/* <span title="Delete" onClick={() => this.showd(student?.id ? student?.id : student?._source.student_id)}>
                          <img src={Delete} alt="delete"/>
                        </span> */}
                        <span title="Delete" onClick={() => this.showd(student)}>
                          <img src={Delete} alt="delete"/>
                        </span>
                      </div>
                    </div>
                    <div className="grid-up">
                      {/*<div style={{display: "grid"}}>
                        <span id="grid-up-span">{student.section_details?.name ? student.class_details?.name :student._source?.class_label}</span>
                        <span id="grid-up-span">{student.user?.email ? student.user?.email : student._source?.email}</span>
                      </div>
                      <div className="invite-class">
                        <div title="Invite" id="send-invite" 
                          onClick={() => this.sendInvite(student?.id ? student?.id : student?._source.student_id)}>
                          <img src={Invite} alt="invite"/>Send invite
                        </div>
                        <div title="Invite" id="send-invite" 
                          onClick={() => this.resendPassword(student.user?.id ? student.user?.id : student?._source.student_id)}>
                          <img src={Password} alt="invite"/>Resend Password
                        </div>
                      </div>*/}
                    </div>
                  </div>
                )}
              </div>}</>}</>}
          </>}
        </div>
        {!this.state.open3 && this.state.filterStudents.length >0 && <Pagination
          activePage={this.state.current_page}
          itemsCountPerPage={20}
          totalItemsCount={this.state.totalItems}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange.bind(this)}
        />}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const {getCertificationBannerList,bannerCreate,getCenterList,bannerUpdate,getClassList,getSectionList,deleteBanner} = actions;

  return {
    bannerUpdate: (id,title, description, start_date_time, end_date_time, banner_type, level, image, url,content,classs,center,ext,batch_ids,web_image,ignore_class_ids) => dispatch(bannerUpdate(id,title, description, start_date_time, end_date_time, banner_type, level, image, url,content,classs,center,ext,batch_ids,web_image,ignore_class_ids)),
    bannerCreate: (title, description, start_date_time, end_date_time, banner_type, level, image, url,content,classs,center,ext,batch_ids,web_image,ignore_class_ids) => dispatch(bannerCreate(title, description, start_date_time, end_date_time, banner_type, level, image, url,content,classs,center,ext,batch_ids,web_image,ignore_class_ids)),
    getCenterList: () => dispatch(getCenterList()),
    getCertificationBannerList : (type,center) => dispatch(getCertificationBannerList(type,center)),
    getClassList: () => dispatch(getClassList()),
    getSectionList:(center_id, class_id,show_all,page)=>dispatch(getSectionList(center_id, class_id,show_all,page)),
    deleteBanner: (bannerid) => dispatch(deleteBanner(bannerid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminBanner);