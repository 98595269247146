import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import DifficultyMeter from '../../public/icons/difficulty-meter.svg';
import Correct from '../../public/icons/correct.svg';
import Attempted from '../../public/icons/attempted.svg';
import AvgTime from '../../public/icons/avg-time.svg';
import Score from '../../public/icons/score.svg';
import Pin from '../../public/icons/pin.svg';
import CheckSymbol from '../../public/icons/check-symbol.svg';
import VideoPlayer2 from '../../components/players/video/VideoPlayer2';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import Zoom from '../../public/icons/full-screen.svg';
import ZoomIn from '../../public/icons/zoom-in.svg';
import download from '../../public/icons/down-arrow.svg';
import clockicon from  '../../public/icons/assignment-clock-icon.png';
import defaultImage from '../../public/icons/maxresdefault.png';
import gradingflag from '../../public/icons/gradingflag.png';
import Submission from '../../public/icons/submission.svg';
import Attempt from '../../public/icons/attempt.jpeg';
import Attempt1 from '../../public/icons/attempt1.svg';
import tick from '../../public/icons/admin-assignment-tick.png';
import tickdisabled from '../../public/icons/admin-assignment-tickdisabled.png';
import downarrow from  '../../public/icons/assignment-listing-arrow.png';
import Back from '../../public/icons/back.svg';
import axios from 'axios';
import './styles.css';
import Trash from '../../public/icons/trash.svg';
import PreviewEye from '../../public/icons/preview-eye-icon.png';
import AddMoreIcon from '../../public/icons/add-file.png';
import FileDownloadIcon from '../../public/icons/file-download-icon.png';
const ques_types = {
  1: 'MCQ',
  2: 'Single Integer',
  3: 'Multiple Response',
  4: 'Fill in the blanks',
  5: 'True or False',
  6: 'Subjective',
  7: 'Matching'
}

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 10,
    slidesToSlide: 10
  },
  tablet: {
    breakpoint: { max: 720, min: 590 },
    items: 10,
    slidesToSlide: 10
  },
};

const questionColors = {
  answered: "#56affc", correct: "#66bb6a", not_visited: "#FFFFFF", skipped: "#f2e4c8", wrong: "#ff8080"
}

const SlideButtons = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const { carouselState: { totalItems } } = rest;
  const lastSlide = Math.floor(totalItems / 4) + (totalItems % 4) - 1;
  if(totalItems <= 10) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="slide-buttons-xy">
      <img src={Left} className='slide-button-xy' onClick={() => previous()} alt="img"/>
      <img src={Right} className='slide-button-xy' onClick={() => next()} alt="img"/>
    </div>
  );
};

class LearnerAssignmentReview2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: this.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      no_ques: false,
      showSolution: false,
      sections: [],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      options: [],
      open5:false,
      selected_image:null,
      selected_image_on:null,
      uploaded_selected_image_on:null,
      file_extension:"",
      uploaded_file_extension:"",
      assignment:"",
      image:null,
      imageURL: null,
      userProfile:"",
      batchIndex :null,
      is_scheduled:0,
      onDueEnd:0,
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      difficulty:['easy', 'medium', 'hard', 'very-hard'],
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
      contentID:'',
      moreimage:null,
      moreimageURL: null,
      more_file_extension:"",
      more_uploaded_file_extension:"",
      more_uploaded_selected_image_on:null,
      previewDialog:false,
      previewFile:'',
      latestAttempt:0,
      set_is_archive:localStorage.getItem('set_is_archive')
      
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    // this.renderMath()
    this.getAssignment(this.props.match.params.id.split("?")[0]);
    this.getLearnerProfile();
    console.log(this.props.location?.state?.fromDashboard);

    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"learner");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data));
      
      this.setState({difficulty:[this.props.saveLanguageWiseData.easy, this.props.saveLanguageWiseData.medium,this.props.saveLanguageWiseData.hard, this.props.saveLanguageWiseData.very_hard]});
      
    }
  }


  getLearnerProfile = async () => {
    
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.userProfile}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
        this.setState({userProfile:response.data.data,batchIndex:response.data.data.batches.findIndex(p => parseInt(p.id) === parseInt(response.data.data.default_batch_id))})
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.getLearnerProfile();
      this.setState({userProfile:response.data,batchIndex:response.data.batches.findIndex(p => parseInt(p.id) === parseInt(response.data.default_batch_id))})
    }
  }

  getAssignment = async (id) => {
    var blockedTile = new Array("jpg", "jpeg", "png", "gif" ,"svg");
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.learnerAssignmentReview}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {

        if(response.data.data.file){
          if(blockedTile.indexOf(response.data.data.file.split('.').pop()) != -1)
          {  
            this.setState({file_extension:1,selected_image_on:response.data.data.file.split('/').pop()}); //1 is used for image file
          }else{
            this.setState({file_extension:2,selected_image_on:response.data.data.file.split('/').pop()}); //2 is used for content file
          }
        }

        this.timer = setInterval(()=>this.scheduledOn(new Date(response.data.data?.scheduled_date) ,new Date(response.data.data?.due_date)), 1000)
        this.timer1 = setInterval(()=>this.dueOn(new Date(response.data.data?.scheduled_date) ,new Date(response.data.data?.due_date)), 1000)
        

        this.setState({ assignment:response.data.data, title: response.data.data.title, code: response.data.data.uid });
       
      }).catch(err => { console.error('error', err.message) });
    }
    else{
      let fromDashboard = this.props.location?.state?.fromDashboard;
        console.log("fromDashboard",fromDashboard)
        if(fromDashboard){
          var response= '';
          response = await this.props.learnerAssignmentReview2(id);
          this.setState({contentID:response.data.id})
        }else{
          var response= '';
          response = await this.props.learnerAssignmentReview(id);
        }
      //const response = await this.props.learnerAssignmentReview(id);
      this.setState({ loading: false })
      if(response !== undefined) {
        /*response.data.sections.map(section => {
          section.questions.map((question, index) => {
            question.question_number = "Q"+(index+1);
          })
        })*/
        if(response.data.file){
          if(blockedTile.indexOf(response.data.file.split('.').pop()) != -1)
          {  
            this.setState({file_extension:1,selected_image_on:response.data.file.split('/').pop()}); //1 is used for image file
          }else{
            this.setState({file_extension:2,selected_image_on:response.data.file.split('/').pop()}); //2 is used for content file
          }
        }

        this.timer = setInterval(()=>this.scheduledOn(new Date(response.data?.scheduled_date) ,new Date(response.data?.due_date)), 1000)
        this.timer1 = setInterval(()=>this.dueOn(new Date(response.data?.scheduled_date) ,new Date(response.data?.due_date)), 1000)
        

        this.setState({ assignment:response?.data, title: response?.data.title, code: response.data.uid });
        //this.setCurrentSectionQ(response.data.sections);
        console.log("response.data.data");
        console.log(response.data);
        if(response?.data?.attempts.length){
          var maxListData = response?.data?.attempts.reduce((a, b) => {
            return new Date(a.submit_date) > new Date(b.submit_date) ? a : b;
          })
          this.setState({latestAttempt: maxListData?.id});
          console.log(this.state.latestAttempt)
          }
      }
    }
    
  }


  close5 = () => this.setState({ open5: false ,selected_image:null});

  

  next = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions
                          .findIndex(p => p.id === currentQuestion.id)
    if(questionIndex === currentSection.questions.length - 1) {
      if(sectionIndex !== exerciseAttempt.sections.length-1) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1].questions[0].id)
      }
    } else{
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex+1].id)
    }
  }

  zoomOn = (url) => {
    this.setState({open5:true,selected_image:url})
  }

  scheduledOn = (val,due) => {
    let dt1 = new Date();
    var diff = val.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    diff = Math.round(diff);
    if(diff === 0 ) {
      this.setState({is_scheduled: 1})
      clearInterval(this.timer);
      setTimeout(function(){  window.location.reload(true) },60000);
    }
    if(diff < 0 && dt1.getTime() <= due.getTime()){
      this.setState({is_scheduled: 1})
    }
    //console.log("onsched"+diff)
  }

  dueOn = (val,due) => {
    let dt1 = new Date();
    var diff = due.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    diff = Math.round(diff);
    if(diff === 0 ) {
      this.setState({is_scheduled: 0})
      clearInterval(this.timer1);
      setTimeout(function(){  window.location.reload(true) },60000);
    }else if(dt1.getTime() > due.getTime()){
      this.setState({onDueEnd :1,is_scheduled:1})
    }
    //console.log("ondue"+diff)
  }



  handleChange = async (event) => {
    var filename = event.target.files[0];
    var getFileExtensions = this.props.learnerprofile?.institute_settings?.allowed_file_extensions;
    let fromDashboard = this.props.location?.state?.fromDashboard;
    //console.log('validFileExtensions', getFileExtensions);
    // var validFileExtensions = ["xls","xlsx", "ppt", "pptx", "doc", "docx", "pdf", "zip", "rar", "jpg", "jpeg"]; 
      
    if(filename){
      var fileext = filename.name.split('.').pop();
      console.log("fileext",fileext);

      if(getFileExtensions.indexOf(event.target.files[0].type.split('/')[1]) != -1)
      {  
        this.setState({uploaded_file_extension:1,uploaded_selected_image_on:event.target.files[0].name}); //1 is used for image file
      }else{
        this.setState({uploaded_file_extension:2,uploaded_selected_image_on:event.target.files[0].name}); //2 is used for content file
      }
      //console.log("filename",filename);
      if (getFileExtensions.lastIndexOf(fileext) == -1) {
        this.setState({uploadFileError:true});
        this.setState({image:''});
        this.setState({imageURL:''});
        console.log("invalid");
        return false;
      }else{
        this.setState({uploadFileError:false});
        this.setState({ 
          image: event.target.files[0],
          imageURL: URL.createObjectURL(event.target.files[0])
        })
        
      }
    }

    if(fromDashboard){
      console.log("fromDashboard condition")
      console.log(fromDashboard)
      console.log("contentID")
      console.log(this.state.contentID);
      let contentID = this.state.contentID;
      const response= await this.props.uploadUserAssigFile(contentID,event.target.files[0]);
    }else{
      console.log("fromDashboard else condition")
      console.log(fromDashboard)
      const response= await this.props.uploadUserAssigFile(this.props.match.params.id,event.target.files[0]);
    }
   
    
  }
  

  // handleChange = async (event) => {
  //   var blockedTile = new Array("jpg", "jpeg", "png", "gif","svg");
  //   let fromDashboard = this.props.location?.state?.fromDashboard;
  //   if(event.target.files[0].type.split('/')[0]) {
  //     this.setState({ 
  //       image: event.target.files[0],
  //       imageURL: URL.createObjectURL(event.target.files[0])
  //     })

  //     if(blockedTile.indexOf(event.target.files[0].type.split('/')[1]) != -1)
  //     {  
  //       this.setState({uploaded_file_extension:1,uploaded_selected_image_on:event.target.files[0].name}); //1 is used for image file
  //     }else{
  //       this.setState({uploaded_file_extension:2,uploaded_selected_image_on:event.target.files[0].name}); //2 is used for content file
  //     }

  //     if(fromDashboard){
  //       console.log("fromDashboard condition")
  //       console.log(fromDashboard)
  //       console.log("contentID")
  //       console.log(this.state.contentID);
  //       let contentID = this.state.contentID;
  //       const response= await this.props.uploadUserAssigFile(contentID,event.target.files[0]);
  //     }else{
  //       console.log("fromDashboard else condition")
  //       console.log(fromDashboard)
  //       const response= await this.props.uploadUserAssigFile(this.props.match.params.id,event.target.files[0]);
  //     }

      
  //     //this.setState({image:response.data.image});
  //   }
  //   else {
  //     this.setState({ image: null, imageURL: null });
  //     document.getElementById('img-input').value = "";
  //     store.addNotification({
  //       title: 'Error',
  //       message: `${this.props.saveLanguageWiseData.images_uploaded}`,
  //       type: 'danger',
  //       container: 'top-right',
  //       dismiss: {
  //         duration: 3000
  //       }
  //     })
  //   }
  // }

  addMoreHandleChange = async (event, id) => {
    var filename = event.target.files[0];
    var getFileExtensions = this.props.learnerprofile?.institute_settings?.allowed_file_extensions;
    //console.log('validFileExtensions', getFileExtensions);
    // var validFileExtensions = ["xls","xlsx", "ppt", "pptx", "doc", "docx", "pdf", "zip", "rar", "jpg", "jpeg"]; 
      
    if(filename){
      var fileext = filename.name.split('.').pop();
      console.log("fileextsssss",fileext);
      console.log("id",id);

      if(getFileExtensions.indexOf(event.target.files[0].type.split('/')[1]) != -1)
      {  
        this.setState({more_uploaded_file_extension:1,more_uploaded_selected_image_on:event.target.files[0].name}); //1 is used for image file
      }else{
        this.setState({more_uploaded_file_extension:2,more_uploaded_selected_image_on:event.target.files[0].name}); //2 is used for content file
      }
      //console.log("filename",filename);
      if (getFileExtensions.lastIndexOf(fileext) == -1) {
        this.setState({uploadFileError:false});
        this.setState({image:''});
        this.setState({imageURL:''});
        console.log("invalid");
        document.getElementById(id).classList.add('errorShow');
        return false;
      }else{
        this.setState({uploadFileError:false});
        this.setState({ 
          moreimage: event.target.files[0],
          moreimageURL: URL.createObjectURL(event.target.files[0])
        })
        document.getElementById(id).classList.remove('errorShow');
        const response= await this.props.uploadUserAssigAddMoreFile(id,event.target.files[0]);
      }
    }
   
    
  }




  // addMoreHandleChange = async (event, id) => {
  //   var blockedTile = new Array("jpg", "jpeg", "png", "gif","svg");
  //   if(event.target.files[0].type.split('/')[0]) {
  //     this.setState({ 
  //       moreimage: event.target.files[0],
  //       moreimageURL: URL.createObjectURL(event.target.files[0])
  //     })

  //     if(blockedTile.indexOf(event.target.files[0].type.split('/')[1]) != -1)
  //     {  
  //       this.setState({more_uploaded_file_extension:1,more_uploaded_selected_image_on:event.target.files[0].name}); //1 is used for image file
  //     }else{
  //       this.setState({more_uploaded_file_extension:2,more_uploaded_selected_image_on:event.target.files[0].name}); //2 is used for content file
  //     }
      
  //     console.log(event.target.files[0])
  //     console.log(id)
     
  //     const response= await this.props.uploadUserAssigAddMoreFile(id,event.target.files[0]);
  //     //this.setState({image:response.data.image});
  //   }
  //   else {
  //     this.setState({ moreimage: null, moreimageURL: null });
  //     document.getElementById('addmore-img-input').value = "";
  //     store.addNotification({
  //       title: 'Error',
  //       message: `${this.props.saveLanguageWiseData.images_uploaded}`,
  //       type: 'danger',
  //       container: 'top-right',
  //       dismiss: {
  //         duration: 3000
  //       }
  //     })
  //   }
  // }

  previewFileDialog = (file) => {
    const{ previewDialog } = this.state;
    this.setState({previewDialog:true})
    this.setState({previewFile:file})
  }

  closeDialogLink = () =>{
    this.setState({previewDialog:false});
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null});
    document.getElementById('img-input').value = "";
  }

  displayTime = (val) => {
    var hours = val.getHours();
    var minutes = val.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var timewithampm = hours + ':' + minutes + ' ' + ampm;
    return `${timewithampm}`.toUpperCase();
  }

  dueOn1 = (due) => {
    let dt1 = new Date();
    var diff = due.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    let hours = Math.round(diff/60);

    let days = Math.round(hours/24);
    
    if(days > 0)
      return days + ' '+ this.props.saveLanguageWiseData.days_left;
    if(hours > 0)
      return parseInt(hours) + ' '+ this.props.saveLanguageWiseData.hrs_left;
    else if(diff > 0)
      return parseInt(diff) + ' '+ this.props.saveLanguageWiseData.mins_left;
  }

  openToggle = (id) => {
    var element = document.getElementById("arrow");
    if(element) {
      
      var element1 = document.getElementsByClassName("open");
      if(element1[0]){
        element1[0].classList.remove("open");
        document.getElementsByClassName("listing-bottom")[0].style.display="none";
      }else{
        element.classList.add("open");
        document.getElementsByClassName("listing-bottom")[0].style.display="flex";
      }
    }
  }

  addMoreFileInput1 = (e,index) =>{
    document.getElementById("addmore-img-input"+index).click();
  }

  downloadFile = (e,file) => {
    e.preventDefault();
    let fileName =  file;
    //https://cors-anywhere.herokuapp.com/
    fetch(fileName, {
      method: 'GET',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        fileName,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }

  render() {
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      showSolution,
      loading,assignment,userProfile,batchIndex,is_scheduled
    } = this.state;
    if(this.props.loading || loading) {
      return (
        <div className="assessment-div height100vh">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
   
    else {
      return (
         <div className="assignment-div height100vh alignitemstart">
        <Helmet>
          <title>Assignment Review - {this.state.title}</title>
        </Helmet>
        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="zoom-image-on">
          <div className="zoom-image">
            <img src={this.state.selected_image} />
          </div>
        </Popup>
        
        <ReactNotifications/>
        {/*preview dialog Start */} 
        {this.state.previewDialog}
            <div className={`previewDialogWrapper ${this.state.previewDialog ? "open" : ""}`}>
              <div className="previewDialogContainer">
                <a href="#" className="closeDialogLink" title="Close"  onClick={()=>this.closeDialogLink()}>X</a>
                <img src={this.state.previewFile} alt="Preview Image"/>
              </div>  
            </div>
          {/*preview dialog Ends */} 
         {/* New Design Start */}       
         <div className="assignment-review-wrapper">
            <div className="assignment-review-container">
                <button id="trace-back" onClick={() => this.props.history.goBack()}>
                  <img src={Back} alt="back"/>
                </button>
                <div className="assignment-review-top">
                    <div className="assignment-title">{assignment.title} <span>{assignment.uid}</span></div>
                    <div className="assignment-title-desc">{assignment.description}</div>
                </div>

                <div className="assignment-review-bottom">
                    <div className="assignment-bottom-left">
                         
                        <div className="hours-block"><i><img src={clockicon}/></i>
                         {/* {this.dueOn1(new Date(assignment.due_date))} */}
                         {new Date(assignment.due_date).getTime() < new Date().getTime() && !assignment.allowed_after_duedate  ? this.props.saveLanguageWiseData.due_date_passed : this.dueOn1(new Date(assignment.due_date))}
                        </div>
                        <div className="marks-block">{this.props.saveLanguageWiseData.total_marks} <span>{assignment.total_marks}</span></div>
                        <div className="passing-block">{this.props.saveLanguageWiseData.passing_marks} <span>{assignment.passing_marks}</span></div>
                      </div>
                    <div className="assignment-bottom-right">
                        <div className="tag-block"><span Style="text-transform: capitalize;">{this.state.difficulty[assignment.difficulty]}</span></div>
                        <div className="attempts-block"><span>{assignment.attempts_allowed ? assignment.attempts_allowed : this.props.saveLanguageWiseData.unlimited} {this.props.saveLanguageWiseData.attempts}</span></div>
                        <p>{assignment.closed_after_passing ? this.props.saveLanguageWiseData.attempt_closed_after_passing:""}</p>
                        {new Date(assignment.due_date).getTime() < new Date().getTime() &&  assignment.allowed_after_duedate ? <div className="attempts-block"><span>{this.props.saveLanguageWiseData.due_date_passed_still_attempt}</span></div> : ''}
                    </div>
                </div>

                <div  Style="display:flex; width:100%; margin-top: 45px;">
                  {this.state.file_extension ==2 ? <div className="download-file-attempt"><a href={assignment.file} target="_blank" rel="" download>
                  {/* <i className="fas fa-download"/> */}
                  <img src={download} alt="download"/> {this.props.saveLanguageWiseData.download} {this.state.selected_image_on}
                  </a></div> :<div className="imagepreview"><img src={assignment.file}/>
                  <span onClick={()=>this.zoomOn(assignment.file)}><img src={ZoomIn} alt="zoom"/></span>
                  </div> }

                  {is_scheduled? <div Style={(this.props.location?.state?.action != "Attempt" && this.props.location?.state?.action != "Re-Attempt") || (assignment?.attempts_allowed && assignment?.attempts?.length === assignment?.attempts_allowed) || (assignment?.closed_after_passing && assignment?.attempts[0]?.marks_obtained >= assignment.passing_marks ) || (this.state.onDueEnd && !assignment.allowed_after_duedate) || (this.state.set_is_archive == 'true') ? "display:none" :""}>
                    {(this.state.image === '' || this.state.image === null) ? <div className="img-prev-statement1 cngedirection ques-statement1">

                      {this.props.location?.state?.action == "Attempt" ? 
                      <div onClick={() => this.fileInput.click()}>
                        <img src={this.state.image?common.addFileUrl(this.state.image) : Attempt1} alt="Prev"/>
                        <div><span>Supported Format : - {this.props.learnerprofile?.institute_settings?.allowed_file_extensions.toLowerCase()}</span><span>{this.props.saveLanguageWiseData.max_size}</span></div><div className={`uploadFileError ${this.state.uploadFileError ? "show" : ""}`}>Please upload valid format</div>
                        </div>
                       : 
                      <><div onClick={() => this.fileInput.click()}><img src={this.state.image?common.addFileUrl(this.state.image) : Submission} alt="Prev"/></div>
                      <div><span>Supported Format : - {this.props.learnerprofile?.institute_settings?.allowed_file_extensions.toLowerCase()}</span><span>{this.props.saveLanguageWiseData.max_size}</span></div><div className={`uploadFileError ${this.state.uploadFileError ? "show" : ""}`}>Please upload valid format</div></>}


                    </div> : 
                     <>{this.state.uploaded_file_extension === 1 ? <div className="img-prev-statement1 ques-statement1">
                        <span onClick={this.removeImage}>&#x2715;</span>
                        <img src={common.addFileUrl(this.state.imageURL)} alt="Prev"/>
                      </div> : <div className="img-prev-statement1 ques-statement1">
                        <span onClick={this.removeImage}>&#x2715;</span>
                        <a href={common.addFileUrl(this.state.imageURL)} alt="Prev" download >{this.state.image.name}</a>
                        
                      </div>}
                      </>
                    }
                    <div className="input_field" Style="display:none">
                       <input type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                    </div>
                  </div> : ""
                }

                </div>

                <div className="assignment-review-last-bottom">
                    <div className="assignment-last-bottom-left">
                        <div className="submission-block" Style="display:none"><span>20%</span> {this.props.saveLanguageWiseData.submission}</div>
                    </div>
                    <div className="assignment-last-bottom-right">
                        {/* assignment.is_graded && <div className="grading-block"><i><img src={gradingflag}/></i>Grading Inprogress</div> */}
                    </div>
                </div>
                <div className="assignment-review-listing">
                  <ol>
                  {assignment?.attempts?.length ? assignment?.attempts?.map((assg,index)=>
                    <li id={assg?.id} className={`${this.state.latestAttempt == assg.id ? "active" : "notActive"}`}>
                      <div className="listing-top">
                        <div className="listing-top-left">
                            <div className="thumb"><img src={common.addFileUrl(userProfile.profile_picture)} alt="file"/></div>
                            <div className="thumb-content">
                              <div className="title">{userProfile.name}</div>
                              <div className="title-email">{userProfile.email}</div>
                              <div className="title-phone">+91-{userProfile.mobile}</div>
                            </div>
                            <div className="class-content">
                              <div className="title">{this.props.saveLanguageWiseData.class}</div>
                              <div className="title-value">{userProfile?.classs?.label}</div>
                            </div>
                            <div className="section-content">
                              <div className="title">{this.props.saveLanguageWiseData.section}</div>
                              <div className="title-value">{batchIndex != null && userProfile?.batches[batchIndex]?.label}</div>
                            </div>
                            <div className="subject-content">
                              <div className="title">{this.props.saveLanguageWiseData.subject}</div>
                              <div className="title-value">{this.props.location?.state?.subject}</div>
                            </div>
                            {/* {JSON.stringify(assg?.is_faculty_reviewed)}
                            {JSON.stringify(assignment.allowed_after_duedate)}
                            {JSON.stringify(assignment.due_date)} */}
                            {(!assg?.is_faculty_reviewed && assignment.allowed_after_duedate && this.state.set_is_archive == "false"  ) && 
                                <div className="addMoreFile clearBoth"><span  onClick={(e) => this.addMoreFileInput1(e,index)}><img src={AddMoreIcon} />{this.props.saveLanguageWiseData.add_more_files}</span><br/>
                                <div className={`uploadFileError`}>Please upload valid format</div>
                                </div>
                              }
                              {new Date(assignment.due_date).getTime() > new Date().getTime() && !assignment.allowed_after_duedate && !assg?.is_faculty_reviewed && this.state.set_is_archive == "false" && 
                              <div className="addMoreFile"><span  onClick={(e) => this.addMoreFileInput1(e,index)}><img src={AddMoreIcon} />{this.props.saveLanguageWiseData.add_more_files}</span></div>
                              }
                        </div> 
                        <div className="input_field" Style="display:none">
                          <input type="file" id={"addmore-img-input"+index} attr={index} onChange={(evt) => this.addMoreHandleChange(evt,assg.id)}  ref={addMoreFileInput => this.addMoreFileInput = addMoreFileInput}/>
                        </div>
                        <div className="listing-top-right">
                            {assg?.length ? <div className="date">{this.props.saveLanguageWiseData.last_submitted_on} : {assg.length ? (new Date(assg.submit_date)).getDate() :""} {assg?.length ? this.state.mlist[(new Date(assg.submit_date)).getMonth()] :""} ,{assg.length ? (new Date(assg.submit_date)).getFullYear() :""}<span></span><span Style="margin-left:10px">{assg.length ? this.displayTime(new Date(assg.submit_date)) :""}</span></div>:""}
                           
                            {assg?.length ? <div className="marks-block-top"><span>{assg.marks_obtained !="_" ? assg.marks_obtained+this.props.saveLanguageWiseData.marks :"" }</span></div> :""}

                            {/* <div className="checked"><span>Checked</span></div> */}
                            {/*assignment?.attempts?.length ? <div className="arrow" id="arrow" onClick={this.openToggle}><img src={downarrow}/></div> :""*/}
                        </div>
                      </div>
                       
                      <div className="listing-bottom" Style="display:flex">
                      

                        <div className="list-view newDesign">
                          {/* <div className="view-content">{this.props.location?.state?.subject}</div> */}
                          {/* <div className="view-content">Submitted On : {(new Date(assg.submit_date)).getDate()} {this.state.mlist[(new Date(assg.submit_date)).getMonth()]} ,{(new Date(assg.submit_date)).getFullYear()} </div>
                          <div className="view-content">Student Notes : NA</div> */}
                          
                          {assg?.users_file.length > 0 && assg?.users_file.map((file,index)=>
                          <div className="review-content">
                            <div className='fileBlock'>
                              <span>{file ? this.props.saveLanguageWiseData.submitted:""}</span>
                              {file &&
                                <a href={common.addFileUrl(file)} alt={file} download>{file.split('/').pop()}</a>
                              }
                            </div>
                            <div className="actions">
                                 <div className="previewFile dddd">
                                   {/* {file.split('.').pop()} */}
                                   {/* {(file.split('.').pop() === 'pdf') || (file.split('.').pop() === 'PDF') ?
                                    <a href={common.addFileUrl(file)} target="_blank" download title="View PDF"><img src={PreviewEye}/></a>
                                   : <i onClick={() => this.previewFileDialog(common.addFileUrl(file))} title="Preview"><img src={PreviewEye}/></i>
                                  } */}

                                  {(file.split('.').pop() === 'pdf' || file.split('.').pop() === 'PDF') &&
                                    <a href={common.addFileUrl(file)} target="_blank" download title="View PDF"><img src={PreviewEye}/></a>
                                  }
                                  {(file.split('.').pop() === 'png' || file.split('.').pop() === 'jpeg' || file.split('.').pop() === 'jpg' || file.split('.').pop() === 'gif') &&
                                    <i onClick={() => this.previewFileDialog(common.addFileUrl(file))} title="Preview"><img src={PreviewEye}/></i>    
                                  }
                                  </div>
                                 <div className="previewFile"><a href={common.addFileUrl(file)} onClick={(e) => this.downloadFile(e,common.addFileUrl(file))} title={common.addFileUrl(file)}><img src={FileDownloadIcon}/></a></div>
                            </div>
                          </div>
                          )
                      }
                          {assg?.faculty_file.length > 0 && assg?.faculty_file.map((file,index)=>
                          <div className="review-content">
                            <div className='fileBlock'>
                            <span>{assg?.faculty_file ? this.props.saveLanguageWiseData.reviewed:""}</span>
                            <a href={common.addFileUrl(file)} alt={file} download>{file?.split('/').pop()}</a>
                            </div>
                           <div className="actions">
                                  {(file.split('.').pop() === 'png' || file.split('.').pop() === 'jpeg' || file.split('.').pop() === 'jpg' || file.split('.').pop() === 'gif') &&
                                     <div className="previewFile"><a href="#" onClick={() => this.previewFileDialog(common.addFileUrl(file))} title="Preview"><img src={PreviewEye}/></a></div>
                                  }
                             
                              <div className="previewFile"><a href={common.addFileUrl(file)} download title={common.addFileUrl(file)}><img src={FileDownloadIcon}/></a></div>
                              </div>
                          
                          </div>
                          )}
                          {assignment.is_graded && 
                          <div className="marks-block">
                            <span>{assg.marks_obtained !="_" ? assg.marks_obtained+this.props.saveLanguageWiseData.marks : this.props.saveLanguageWiseData.under_review}</span>
                            <i>{assg.marks_obtained !="_" ? <img src={tick}/> : "" }</i>
                          </div>}
                        </div>
                        
                       

                        
                        {/*<div className="list-view">
                            <div className="view-content">Algebra Questions PDF</div>
                            <div className="view-content">Submitted On : 12 Aug, 2020</div>
                            <div className="view-content">Student Notes : This is my Submission</div>
                            <div className="review-content"><button>Upload Reviewed File</button></div>
                            <div className="marks-block">
                              <input type="text" placeholder="20 Marks"/>
                              <i><img src={tickdisabled}/></i>
                            </div>
                        </div>*/}
                        
                      </div>

                    </li>
                     ) : ""}
                  </ol>

                </div>


              
            </div>       
        </div>
        {/* New Design Start */}    

      </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData,
    learnerprofile : state.commonReducer.logindetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentReviewData,learnerAssignmentReview,uploadUserAssigFile,getLearnerProfile,learnerAssignmentReview2,uploadUserAssigAddMoreFile,getLanguageWiseData } = actions;
  return {
    learnerAssignmentReview : (id) => dispatch(learnerAssignmentReview(id)),
    learnerAssignmentReview2 : (id) => dispatch(learnerAssignmentReview2(id)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    uploadUserAssigFile: (assignment_id,users_file) => dispatch(uploadUserAssigFile(assignment_id,users_file)),
    uploadUserAssigAddMoreFile: (assignment_attempt_id,users_file) => dispatch(uploadUserAssigAddMoreFile(assignment_attempt_id,users_file)),
    getAssessmentReviewData: (assessmentId) => dispatch(getAssessmentReviewData(assessmentId)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerAssignmentReview2);