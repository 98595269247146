export const LOADING = 'LOADING';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const LOGGED_IN = 'LOGGED_IN';

export const REQUEST_LESSONS = 'REQUEST_LESSONS';
export const GET_TOC_LIST = "GET_TOC_LIST";
export const TOGGLE_UNIT = "TOGGLE_UNIT";
export const TOGGLE_CHAPTER = "TOGGLE_CHAPTER";
export const TOGGLE_TOPIC = "TOGGLE_TOPIC";
export const SELECTED_CONTENT_ID = "SELECTED_CONTENT_ID";

export const OPEN_TOC_ID = 'OPEN_TOC_ID';
export const EXERCISE_ATTEMPT = 'EXERCISE_ATTEMPT';
export const CONTENT_PREVIEW = 'CONTENT_PREVIEW';
export const CONTENT_NOTES_DETAIL = 'CONTENT_NOTES_DETAIL';
export const CONTENT_VIDEO_DETAIL = 'CONTENT_VIDEO_DETAIL';
export const CONTENT_SOLVED_EXAMPLE = 'CONTENT_SOLVED_EXAMPLE';
export const NOTIFICATION ='NOTIFICATION';
export const LEARNER_PROFILE ='LEARNER_PROFILE';
export const DASHBOARD_TYPE ='DASHBOARD_TYPE';
export const WIDGET_ALIGNMENT_SIZE ='WIDGET_ALIGNMENT_SIZE';
export const WIDGET_ALIGNMENT_ALIGNMENT ='WIDGET_ALIGNMENT_ALIGNMENT';
export const MP ='0z1vfz8aau14nt5ipzdmc_';
export const LOGINDETAILS ='LOGINDETAILS';
export const WIDGET_LIST ='WIDGET_LIST';
export const WIDGET_DATA ='WIDGET_DATA';
export const LANGUAGE_DATA ='LANGUAGE_DATA';
export const PAGEWISE_LANGUAGE_DATA ='PAGEWISE_LANGUAGE_DATA';
