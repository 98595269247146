import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Target from '../../public/icons/target.svg';
import Empty from '../../components/error/Empty';
import queryString from 'query-string'
import 'react-notifications-component/dist/theme.css';
import './styles.css';


class Practice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      practice: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      showModal: false,
      content_id:0,
      is_recent:0,
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
    let value="ggg";
    const regex = new RegExp(`^${value}`, 'i');
    localStorage.setItem("page","practice")
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date})
   this.getCurrentDate();
   this.getExerciseList(date,15,'forward',false);
  }

  showAttemptModal = () => this.setState({ showModal: true })

  closeAttemptModal = () => this.setState({ showModal: false })

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if(label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        {modal: true}
      )
    }
    else {
      this.setState({showAttemptModal: false})
      this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );
    }
  }

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if(label.toLowerCase() === 'review' || label.toLowerCase() === 'result') {
      this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        {modal: true}
      )
    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })
    }
  }

  getExerciseList = async (date,days,direction,if_loading) => {
    const response = await this.props.getExerciseList(date,days,direction,if_loading);
    
    if(response){
      this.setState({practice:response.data.results?response.data.results:"", loading: false ,countBottom:1,content_id:response.data.results?response.data?.results[0]?.content_id:0})
      
      const searchId=queryString.parse(this.props.location.search);
      if(searchId.id){
        var elmnt = document.getElementById(searchId.id);
        document.getElementById(searchId.id).firstChild.style.border="3px solid #f1dfa1";
        if(elmnt) elmnt.scrollIntoView();
      }
        
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
    
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date(this.state.current_date.split(' ').join('-'));

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      cd.getMonth()+1,
      d.getMonth()+1
    ];
    return date;
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ current_date: date, date: currentDate});
  }


  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) + this.props.saveLanguageWiseData.hrs:minutes +' mins';
  }

  onTextChange = (e) => {
    const value = e.target.value;
    console.log(value)
    let suggestions = [];
    if(value.length > 0){
        const regex = new RegExp(`^${value}`, 'g');
        suggestions = this.state.practice.sort().filter(v => regex.test(v.title))
    }else{
      var d = new Date();
      let date = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
      ].join('-');
     this.getExerciseList(date,15,'forward',false);
    }

    console.log(suggestions)

    this.setState(() => ({
        practice:suggestions,
        text: value
    }))
  }

  render() {
    return (
      <>
        {!isEmpty(this.props.contentPreview) 
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
          onClose={this.closeAttemptModal}>
          <AssessmentPreview 
            contentPreview={this.props.contentPreview}
            attempt={this.attemptPaper}
            showAttemptButton={true}
          />
        </Popup>}
        <div className="app-body" id="appBody" >
          <ReactNotifications/>
          <div className={this.props.dashboard_type == 'dynamic' ? "app-div widget-app-div" : "app-div"}>
            <Helmet>
              <title>Practice - Learner</title>
            </Helmet>
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
              <div className="page-header">
                <div className="page-header-left">
                  <h1>{this.props.saveLanguageWiseData.practice}</h1>
                  {/*<input id="query" type="text" onChange={this.onTextChange} value={this.state.text}/>*/}
                </div>
              </div>
              {!this.state.loading && <div className="list-sticky-practice" onScroll={this.handleScroll}>
                {!this.state.practice.length && <Empty/>}
                <div className="list-content">
                  {this.state.practice && this.state.practice.map((test,index) =>
                    <div className="list-div-x2" id={test.content_id} ref={this.myRef}>
                      <div className="dash-test" id={test.content_id==this.state.content_id?"conter":""} key={index} 
                        Style={this.state.date==this.getTime(test.submit_by)[2] && this.getTime(test.submit_by)[3]?"background-color:#fff6e4":""}>
                        <div className="dash-flex2">
                          <div Style="display: inline-block;">
                            <span className="dash-flex2-title">{test.title}</span>
                            <span>
                              {test.marks ? <span>
                              <span Style="color:#a9a0a0">Score</span> : <span Style="color:var(--primary)">{test.marks}</span>
                              </span>:<span Style="margin-bottom:18px;color:#777777">{this.convertedHour(test.timed_duration_mins)}</span>
                            }</span>
                          </div>
                          <div>
                            <span className="dash-flex2-img">
                              <img id="idea" src={Target} alt="Target"/>
                            </span>
                          </div>
                        </div>
                        <div className="faculty-dash-actions">
                          {test.actions && test.actions.map(action =>
                            <div key={action.id} 
                              onClick={()=>this.attemptAndReview(action.label, test.content_id)}>
                              <button>
                                {action.label}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div> 
                  )}</div>
              </div>}

          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    dashboard_type : state.commonReducer.dashboard_type,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAttemptPreview, getExerciseList } = actions;
  return {
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    getExerciseList: (date,days,direction,if_loading) => dispatch(getExerciseList(date,days,direction,if_loading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Practice);