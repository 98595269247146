import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import common from '../../common';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Admin from '../../public/icons/admin.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class Branches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      branch_id: null,
      admin: '',
      name: '',
      code: '',
      country: null,
      state: null,
      city: null,
      country_label: '',
      state_label: '',
      city_label: '',
      parent: localStorage.getItem('center_id'),
      countries: [],
      states: [],
      cities: [],
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      branches: [],
      admins: [],
      all_admins: [],
      filterAdmins: [],
      admin_name: '',
      image: null,
      imageURL: null
    }
  }

  componentDidMount() {
    this.getCenterList();
    this.getCountries();
    this.getAdminList();

    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  show = () => this.setState({ open: true, name: '', branch_id: '', code: '', admin: null,
                                  country: null, state: null, city: null, image: null });

  close = () => this.setState({ open: false });

  show2 = (val) => {
    this.setState({ open2: true, name: val.name, branch_id: val.id, image: val.logo_url,
                      code: val.code !== null ? val.code : '', admin: val.admin.id,
                      country: val.country.id, state: val.state.id, city: val.city.id,
                      admin_name: val.admin.name, filterAdmins: this.state.admins.concat
                        (this.state.all_admins.filter(admin => admin.id === val.admin.id)),
                      imageURL: val.logo_url, country_label: val.country.name,
                      state_label: val.state.name, city_label: val.city.name });
  }

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  getAdminList = async () => {
    const response = await this.props.getAdminList();
    if(response !== undefined) {
      /*this.setState({ admins: response.data.filter(admin => (admin.assigned_branch.admin === undefined
        || admin.assigned_branch.admin === null)), all_admins: response.data });*/

      this.setState({ admins: response.data})
    }
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }

  getCountries = async () => {
    const response = await this.props.getCCS(1, null);
    if(response !== undefined) {
      this.setState({ countries: response.data });
    }
  }

  getStates = async (id) => {
    const response = await this.props.getCCS(2, id);
    if(response !== undefined) {
      this.setState({ states: response.data });
    }
  }

  getCities = async (id) => {
    const response = await this.props.getCCS(3, id);
    if(response !== undefined) {
      this.setState({ cities: response.data });
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  handleChange = (event) => {
    if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('img-input').value = "";
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  chooseCountry = (e) => {
    if(e.target.value === 'Edit country' || e.target.value === 'Select country') {
      this.setState({ country: null, state: null, city: null });
    }
    else {
      this.setState({ country: e.target.value, state: null, city: null });
      this.getStates(e.target.value);
    }
  }

  chooseState = (e) => {
    if(e.target.value === 'Edit state' || e.target.value === 'Select state') {
      this.setState({ state: null, city: null });
    }
    else {
      this.setState({ state: e.target.value, city: null });
      this.getCities(e.target.value);
    }
  }

  chooseCity = (e) => {
    if(e.target.value === 'Edit city' || e.target.value === 'Select city') {
      this.setState({ city: null })
    }
    else {
      this.setState({ city: e.target.value });
    }
  }

  chooseAdmin = (e) => {
    if(e.target.value === 'Select Admin') {
      this.setState({ admin: null })
    }
    else {
      this.setState({ admin: e.target.value });
    }
  }

  newCenter = async () => {
    const { name, code, country, state, city, image, center_type, parent, admin } = this.state;
    await this.props.createCenter(name, code, country, state, city, image, center_type, parent, admin);
    this.setState({ open: false });
  }

  editCenter = async () => {
    const { branch_id, name, code, country, state, city, image, admin } = this.state;
    await this.props.updateCenter( branch_id, name, code, country, state, city, image, admin);
    this.setState({ open2: false });
  }

  deleteCenter = async () => {
    const { d_id, parent } = this.state;
    await this.props.deleteCenter(d_id, parent);
    this.setState({ opend: false });
  }

  render() {
    const isEnabled = this.state.name.length > 0 && this.state.code.length > 0 &&
        this.state.country !== null && this.state.state !== null && this.state.city !== null;

    const isEnabledEdit = this.state.name.length > 0 && this.state.code.length > 0 &&
        this.state.country !== null && this.state.state !== null && this.state.city !== null  && this.state.admin !== undefined && this.state.admin !== null;    
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search branches"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.branches}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.branches}</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder="Search branches"/>
        {this.state.admins.length !== 0 ? <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_branch}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs2">
              {this.state.image === '' || this.state.image === null
                ? <div className="img-prev" onClick={() => this.fileInput.click()}>
                <img src={ImgHolder} alt="Prev"/>
              </div> : <div className="img-prev">
                <span onClick={this.removeImage}>&#x2715;</span>
                <img src={common.addFileUrl(this.state.imageURL)} alt="Prev"/>
              </div>}
              <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
              <input placeholder={this.props.pagewiseLanguageData.branch_code} type="text"  onChange={this.codeChange}/>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.branch_name} type="text" onChange={this.nameChange}/>
              {/*<select onChange={this.chooseAdmin}>
                <option value={null}>
                  Select Admin
                </option>
                {this.state.admins.map(admin =>
                  <option key={admin.id} value={admin.id}>
                    {admin.name}
                  </option>
                )}
              </select>*/}
            </div>
            <div className="popup-dropdowns">
              <div className="popup-dropdown-div">
                <select onChange={this.chooseCountry}>
                  <option value={null}>
                    {this.props.pagewiseLanguageData.select_country}
                  </option>
                  {this.state.countries.map(country =>
                    <option key={country.id} value={country.id}>
                      {country?.label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                    </option>
                  )}
                </select>
              </div>
              <div className="popup-dropdown-div">
                <select onChange={this.chooseState}>
                  <option value={null}>
                  {this.props.pagewiseLanguageData.select_state}
                  </option>
                  {this.state.states.map(state =>
                    <option key={state.id} value={state.id}>
                      {state?.label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                    </option>
                  )}
                </select>
              </div>
              <div className="popup-dropdown-div">
                <select onChange={this.chooseCity}>
                  <option value={null}>
                  {this.props.pagewiseLanguageData.select_city}
                  </option>
                  {this.state.cities.map(city =>
                    <option key={city.id} value={city.id}>
                      {city?.label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newCenter}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup> : <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content2">
          <div className="condition-goto">
            <img src={Admin} alt="admin"/>
            <Link id="section-button" to={`/admin/branch-admins`}>{this.props.pagewiseLanguageData.go_to_branch_admin}</Link>
            <span>
              {this.props.pagewiseLanguageData.before_creating_branches_you_need_to_add_branch_admins}
            </span>
          </div>
        </Popup>}
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_branch}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data" id="slight-popup-data">
            <div className="popup-inputs2">
              {this.state.image === '' || this.state.image === null
                ? <div className="img-prev" onClick={() => this.fileInput.click()}>
                <img src={ImgHolder} alt="Prev"/>
              </div> : <div className="img-prev">
                <span onClick={this.removeImage}>&#x2715;</span>
                <img src={common.addFileUrl(this.state.imageURL)} alt="Prev"/>
              </div>}
              <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
              <input placeholder={this.props.pagewiseLanguageData.branch_code} onChange={this.codeChange}
                value={this.state.code}/>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.branch_name} onChange={this.nameChange}
                value={this.state.name}/>
                {this.state.branch_id === parseInt(this.state.parent) ? <select>
                  <option>
                    {this.state.admin_name}
                  </option>
                </select> : <select onChange={this.chooseAdmin} value={this.state.admin}>
                  <option value={null}>
                    {this.props.pagewiseLanguageData.select_admin}
                  </option>
                  {this.state.filterAdmins.map(admin =>
                    <option key={admin.id} value={admin.id}>
                      {admin.name}
                    </option>
                  )}
                </select>}
            </div>
            <div className="popup-dropdowns">
              <div className="popup-dropdown-div">
                <label>
                  {this.state?.country_label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                </label>
                <select onChange={this.chooseCountry}>
                  <option value={null}>
                    {this.props.pagewiseLanguageData.edit_country}
                  </option>
                  {this.state.countries.map(country =>
                    <option key={country.id} value={country.id}>
                      {country?.label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                    </option>
                  )}
                </select>
              </div>
              <div className="popup-dropdown-div">
                <label>
                  {this.state?.state_label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                </label>
                <select onChange={this.chooseState}>
                  <option value={null}>
                    {this.props.pagewiseLanguageData.edit_state}
                  </option>
                  {this.state.states.map(state =>
                    <option key={state.id} value={state.id}>
                      {state?.label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                    </option>
                  )}
                </select>
              </div>
              <div className="popup-dropdown-div">
                <label>
                  {this.state?.city_label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                </label>
                <select onChange={this.chooseCity}>
                  <option value={null}>
                    {this.props.pagewiseLanguageData.edit_city}
                  </option>
                  {this.state.cities.map(city =>
                    <option key={city.id} value={city.id}>
                      {city?.label?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabledEdit} onClick={this.editCenter}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteCenter}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <h1>{this.props.pagewiseLanguageData.branches}</h1>
              </div>
            </div>
            <div className="heading-part2">
              <span onClick={this.show}>
                <img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
              </span>
              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
            </div>
          </div>
          {this.state.branches.length === 0 ? <Empty/> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
              {this.props.pagewiseLanguageData.name}
              </div>
              <div className="list-section">
              {this.props.pagewiseLanguageData.code}
              </div>
              <div className="list-class">
              {this.props.pagewiseLanguageData.state}
              </div>
              <div className="list-subject">
              {this.props.pagewiseLanguageData.city}
              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.admin}
              </div>
              <div className="list-action">
              {this.props.pagewiseLanguageData.actions}
              </div>
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.branches.map((branch, index) =>
                <div className="list-item" key={branch.id}>
                  <div className="title-item3">
                    {branch.logo_url === null || branch.logo_url === ''? null
                      : <div className="dp_div"
                      style={{backgroundImage: "url("+common.addFileUrl(branch.logo_url)+")"}}>
                    </div>}
                    <div>
                      {branch.name}
                    </div>
                  </div>
                  <div className="section-item">
                    {branch.code}
                  </div>
                  <div className="class-item">
                    {branch?.state?.name?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                  </div>
                  <div className="subject-item">
                    {branch?.city?.name?.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}
                  </div>
                  <div className="faculty-item">
                    {branch.admin.name}
                  </div>
                  <div className="action-item">
                    <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(branch)}>
                      <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                    </span>
                    {branch.id !== parseInt(this.state.parent)
                      ? <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(branch.id)}>
                      <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                    </span> : null}
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.branches.map((branch, index) =>
                <div className="grid-item" key={branch.id}>
                  <div className="grid-up grid-up-v1">
                    <div className="title-grid4">
                      {branch.logo_url === null || branch.logo_url === ''? null
                        : <div className="dp_div"
                        style={{backgroundImage: "url("+common.addFileUrl(branch.logo_url)+")"}}>
                      </div>}
                      <div>
                        {branch.name}
                      </div>
                    </div>
                    <div className="grid-actions">
                      <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(branch)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>
                      {branch.id !== parseInt(this.state.parent)
                        ? <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(branch.id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                      </span> : null}
                    </div>
                  </div>
                  <div className="grid-middle">
                    {this.props.pagewiseLanguageData.admin}
                    <span>{branch.admin.name}</span>
                  </div>
                </div>
              )}
            </div>}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { createCenter, updateCenter, getCenterList, getCCS, deleteCenter,
          getAdminList,getPageLanguageWiseData } = actions;
  return {
    getAdminList: () => dispatch(getAdminList()),
    createCenter: (name, code, country, state, city, logo, center_type, parent, admin) => dispatch(createCenter(name, code, country, state, city, logo, center_type, parent, admin)),
    updateCenter: (center_id, name, code, country, state, city, logo, admin) => dispatch(updateCenter(center_id, name, code, country, state, city, logo, admin)),
    deleteCenter: (id, center_id) => dispatch(deleteCenter(id, center_id)),
    getCCS: (type, parent) => dispatch(getCCS(type, parent)),
    getCenterList: () => dispatch(getCenterList()),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Branches);
