import React from 'react';

const SortingComponent = ({ sortState, onToggleSort }) => {

    let topColor, bottomColor;
    switch (sortState) {
        case true:
            topColor = '#171C26';
            bottomColor = '#BCC2CE';
            break;
        case false:
            topColor = '#BCC2CE';
            bottomColor = '#171C26';
            break;
        case null:
            topColor = '#BCC2CE';
            bottomColor = '#BCC2CE';
            break;


    }



    return (
        <div onClick={onToggleSort} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Arrow isUp color={topColor} />
            <Arrow color={bottomColor} />

        </div>
    );
};

export default SortingComponent;



const Arrow = ({ color, isUp }) => {
    return (
        isUp ? <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.74311 0.585167C3.54406 0.300808 3.12293 0.300807 2.92388 0.585166L0.384208 4.21327C0.152236 4.54466 0.389312 5 0.793824 5H5.87317C6.27768 5 6.51476 4.54466 6.28278 4.21327L3.74311 0.585167Z" fill={color} />
        </svg> : <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.74311 4.41483C3.54406 4.69919 3.12293 4.69919 2.92388 4.41483L0.384208 0.786732C0.152236 0.455343 0.389312 0 0.793824 0H5.87317C6.27768 0 6.51476 0.455342 6.28278 0.786731L3.74311 4.41483Z" fill={color} />
        </svg>

    )
}

