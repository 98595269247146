import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';
import axios from 'axios';
import common from '../../common';
import actions from '../../redux/actions';
//import PathD from '../../public/icons/path-d.svg';
//import PathB from '../../public/icons/path-b.svg';
//import Profile from '../../public/icons/profile.svg';
import ProfileThumb from '../../public/icons/profile-icon.png';
// import Comment from '../../public/icons/comment.svg';
import MonthTable from '../../components/temp';
import Empty from '../../components/error/Empty';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
//import Pagination from "react-js-pagination";
import HeaderMyCoach from '../mycoach/HeaderMyCoach'
import designcategoryicon from '../../public/icons/design-categoryicon.png';
import businesscategoryicon from '../../public/icons/business-categoryicon.png';
import technologycategoryicon from '../../public/icons/technology-categoryicon.png';
import ugpgBanner1 from '../../public/images/ugpgBanner1.png';
import ugpgBanner2 from '../../public/images/ugpgBanner2.png';
import ugpgBanner3 from '../../public/images/ugpgBanner3.png';

class LearnerUGPGProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      designtab: "block",
      businesstab: "none",
      technologytab: "none",
      tool:this.props.match?.params?.category.split('-')[0],
      key:this.props.match?.params?.category.split('-')[1], 
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
      username:''
    }
  }

  openTab = (evt,tabName) => {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with className="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with className="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    var t1 = document.getElementById(tabName);
    if(t1){
        t1.style.display = "block";
    }
    evt.currentTarget.className += " active";
  }


  visibletabs = (val) =>{
    this.setState({designtab:"none", businesstab:"none", technologytab:"none"});
    if(val == "designtab"){
      this.setState({designtab:"block"});
       //this.openSubTab("bdesuxcourse");
    }
    if(val == "businesstab"){
      this.setState({businesstab:"block"});
      //this.openSubTab("bbainfintechcourse");
    }
    if(val == "technologytab"){
      this.setState({technologytab:"block"});
     // this.openSubTab("bcahonsdatascience");
    }
  }


  openSubTab = (val) =>{
    var i, tabcontent, tablinks;

    // Get all elements with className="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with className="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    var t1 = document.getElementById(val);
    if(t1){
        t1.style.display = "block";
    }

    var t2 = document.getElementsByClassName(val);

    if(t2){
        t2[0].classList.add("active")
    }

  }

  componentDidMount() {
    localStorage.setItem("page","UGPGProgram");
   
   console.log("category" + this.state.tool);

   console.log("Key" + this.state.key);
   
   console.log("props" + this.props.match.params.category);

   this.setState({designtab:"none", businesstab:"none", technologytab:"none"});

    if(this.state.tool == "design"){
        this.setState({designtab:"block"});
        //this.openSubTab(this.state.key);
    }
    if(this.state.tool == "business"){
        this.setState({businesstab:"block"});
        //this.openSubTab(this.state.key);
    }
    if(this.state.tool == "technology"){
        this.setState({technologytab:"block"});
       //this.openSubTab(this.state.key);
    } 
  }

  redirectTo= async(slug,domain) =>{
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.userProfile}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(res => {
        this.setState({username:res.data?.data.username})
        this.redirectToPage(slug,domain)
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.getwpUserToken();
      this.setState({token:response.data?response.data.token:null});
      const username = localStorage.getItem("username")

      /*console.log(`${domain}/1.php?token=${response.data?.token}&username=${username}&page=${slug}`)
      return false*/
      //if(slug)
      window.open(`${domain}/1.php?token=${response.data?.token}&username=${username}&page=${slug}`, "_self");
    }
  }

  redirectToPage = async(slug,domain) =>{
      await axios.get(`${common.apiBase}/${common.wpUserToken}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
        
        const username = localStorage.getItem("username") || this.state.username
        window.open(`${domain}/1.php?token=${response.data.data?.token}&username=${username}&page=${slug}`, "_self");
      }).catch(err => { console.error('error', err.message) });
    
  }

	render() {

    let CID = localStorage.getItem("ideaspaceid");
    //let subjectid = parseInt(CID); 

    return (
      <>
      
        <div className= {"app-body ugpgprogram-page "+(this.state.is_webview == 1 ? 'webview' : '')}>
          
          <div className="app-div">
            <Helmet>
              <title>UG PG Programs</title>
            </Helmet>
            <ReactNotifications/>
           
            <div className="page-header">
              {/*<div className="page-header-left">
                <h1>UG PG Programs</h1>
              </div>*/}
           </div>

            <div className="UGPGProgramWrapper">

              <div className="categoryWrapper">
                <div className='banner'>
                    <img src={ugpgBanner1}/>
                    <img src={ugpgBanner2}/>
                    <img src={ugpgBanner3}/>
                </div>
                <div className="heading">Find the Right Online Degree for You</div>
                <ol>
                  <li>
                    <a href="javascript:void(0);" onClick={(e)=>this.visibletabs("designtab")} className={this.state.designtab == "block" ? "active" : ''}>
                      <div className="icon"><img src={designcategoryicon} /></div>
                      <div className="title">Faculty of Management</div>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" onClick={(e)=>this.visibletabs("businesstab")} className={this.state.businesstab == "block" ? "active" : ''}>
                      <div className="icon"><img src={businesscategoryicon} /></div>
                      <div className="title">Faculty of Computer<br/>Applications</div>
                    </a>
                  </li>
                  {/* <li>
                    <a href="javascript:void(0);" onClick={(e)=>this.visibletabs("technologytab")} className={this.state.technologytab == "block" ? "active" : ''}>
                      <div className="icon"><img src={technologycategoryicon} /></div>
                      <div className="title">Faculty of Arts</div>
                    </a>
                  </li> */}
                </ol>
              </div>
               {/* Design Category Tab */} 
              <div className="designCategoryWrapper designtab" style={{display:this.state.designtab}}>
                  <div className="designCategoryContainer">
                   
                  <div className="rightsection">
                      {/* Tab1 */} 
                        <div className="tabcontent">
                            <ol>
                                <li>
                                    <div className="university-thumb"><img src="https://onlinevgu.com/wp-content/uploads/Rectangle-1212.png"/></div>
                                    <div className="university-content">
                                        {/* <div className="university-logo"><img src="https://imaginxp.com/wp-content/uploads/headernavigation-chitkara-university.png"/></div> */}
                                        <div className="university-title">BBA I Bachelor of Business Administration</div>
                                        <div className="university-location">Specializations:-<span>Aviation Management • Enterprise Risk Management • Digital Marketing • Retail Management • Fintech • Travel and Tourism</span></div>
                                        <div className="actions">
                                              <div className="duration"><i className="fa fa-clock-o"></i>3 years</div>
                                             <div className="links"><a target="_blank" onClick={() =>this.redirectTo('bba-i-bachelor-of-business-administrations','https://onlinevgu.com')}>View Program</a></div>
                                          </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="university-thumb"><img src="https://onlinevgu.com/wp-content/uploads/Rectangle-1211.png"/></div>
                                    <div className="university-content">
                                          {/* <div className="university-logo"><img src="https://imaginxp.com/wp-content/uploads/headernavigation-dit-university.png"/></div> */}
                                        <div className="university-title">MBA I Master of Business Administration</div>
                                        <div className="university-location">Specializations:-<span>HR • Marketing • Finance • Healthcare • ABM • IT • Risk Management • International Finance • Retail Management</span></div>
                                        <div className="actions">
                                              <div className="duration"><i className="fa fa-clock-o"></i>2 Years</div>
                                              <div className="links"><a target="_blank" onClick={() =>this.redirectTo('mba-i-master-of-business-administrations','https://onlinevgu.com')}>View Program</a></div>
                                           </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        {/* --Tab1-->*/} 
                    </div>
                </div>
              </div>
               {/* Design Category Ends */}

               {/* Business Category Tab */} 
               <div className="designCategoryWrapper businesstab" style={{display:this.state.businesstab}}>
                      <div className="designCategoryContainer">
                        
                        <div className="rightsection">
                            
                            {/*<!--Tab1-->*/}
                            <div className="tabcontent">
                                <ol>
                                    <li>
                                        <div className="university-thumb"><img src="https://onlinevgu.com/wp-content/uploads/Rectangle-1212.png"/></div>
                                        <div className="university-content">
                                            {/* <div className="university-logo"><img src="https://imaginxp.com/wp-content/uploads/headernavigation-chitkara-university.png"/></div> */}
                                            <div className="university-title">BCA I Bachelor of Computer Applications</div>
                                            <div className="university-location">Specializations:-<span>UX • Data Science • RPA • CTIS: Cloud Technology and Information Security • Blockchain • Artificial Intelligence</span></div>
                                            <div className="actions">
                                                  <div className="duration"><i className="fa fa-clock-o"></i>3 years</div>
                                                  <div className="links"><a target="_blank" onClick={() =>this.redirectTo('bca-i-bachelor-of-computer-applications','https://onlinevgu.com')}>View Program</a></div>
                                              </div>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            {/*<!--Tab1-->*/}
                        </div>


                      </div>
                  </div>
               {/* Business Category Tab */}  

               {/* Technology Category Tab */} 
               <div className="designCategoryWrapper technologytab" style={{display:this.state.technologytab}}>
                    <div className="designCategoryContainer">
                      
                     
                      <div className="rightsection">
                          
                          {/*<!--Tab1-->*/}
                          <div className="tabcontent">
                              <ol>
                                  <li>
                                      <div className="university-thumb"><img src="https://onlinevgu.com/wp-content/uploads/Rectangle-1211.png"/></div>
                                      <div className="university-content">
                                          {/* <div className="university-logo"><img src="https://imaginxp.com/wp-content/uploads/headernavigation-jlu-university.png"/></div> */}
                                          <div className="university-title">BA I Bachelor of Arts</div>
                                          <div className="university-location">Specializations:-<span>Economics • History • Political Science • Public Policy & Development • International Relations</span></div>
                                          <div className="actions">
                                                <div className="duration"><i className="fa fa-clock-o"></i>3 years</div>
                                                <div className="links"><a target="_blank"  onClick={() =>this.redirectTo('ba-i-bachelor-of-arts','https://onlinevgu.com')}>View Program</a></div>
                                            </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="university-thumb"><img src="https://onlinevgu.com/wp-content/uploads/Rectangle-1211.png"/></div>
                                      <div className="university-content">
                                          {/* <div className="university-logo"><img src="https://imaginxp.com/wp-content/uploads/headernavigation-jlu-university.png"/></div> */}
                                          <div className="university-title">MA-English I Master of Arts in English</div>
                                          <div className="university-location">Specializations:-<span>Economics • Philosophy  • Sociology • Political Science • History • Literature</span></div>
                                          <div className="actions">
                                                <div className="duration"><i className="fa fa-clock-o"></i>2 years</div>
                                                <div className="links"><a target="_blank"  onClick={() =>this.redirectTo('ma-english-i-master-of-arts-in-english','https://onlinevgu.com')}>View Program</a></div>
                                            </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div className="university-thumb"><img src="https://onlinevgu.com/wp-content/uploads/Rectangle-1211.png"/></div>
                                      <div className="university-content">
                                          {/* <div className="university-logo"><img src="https://imaginxp.com/wp-content/uploads/headernavigation-jlu-university.png"/></div> */}
                                          <div className="university-title">MA-International Relations I Master of Arts in International Relations</div>
                                          <div className="university-location">Specializations:-<span>Economics • Philosophy  • Sociology • Political Science • History • Literature</span></div>
                                          <div className="actions">
                                                <div className="duration"><i className="fa fa-clock-o"></i>2 years</div>
                                                <div className="links"><a target="_blank"  onClick={() =>this.redirectTo('ma-international-relations-i-master-of-arts-in-international-relations','https://onlinevgu.com')}>View Program</a></div>
                                            </div>
                                      </div>
                                  </li>
                              </ol>
                          </div>
                           {/*<!--Tab1-->*/}
                        </div>


                    </div>
              </div>
              {/* Technology Category Tab */} 


            </div>
           
          </div>
        </div>
      
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    currentpage :state.commonReducer.currentpage,
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const {setCurrentPage,getwpUserToken, getLearnerProfile, getStudentDoubtList } = actions;
  return {
    getwpUserToken: () => dispatch(getwpUserToken()),
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerUGPGProgram);