import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import Assignment from '../../public/icons/assignment.svg';
import common from '../../common';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Back from '../../public/icons/back.svg';
import SMLib from '../../public/images/sm-lib.svg';
import ContentLib from '../../public/images/content-lib.png';
import Target from '../../public/icons/sm-assess.svg';
import Empty from '../../components/error/Empty';
import Notes from '../../public/icons/notes.svg';
import Video from '../../public/icons/video.svg';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import './styles.css';
import config from '../../config';

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 3,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 590 },
    items: 2,
    slidesToSlide: 2
  },
};

class StudyMaterialTopics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      is_faculty: localStorage.getItem('is_faculty') === 'true' ? true : false,
      subject_id: this.props.match.params.id,
      topics: this.props.location.state?.topics,
      subject_label: this.props.location?.state?.subject_label,
      units: {},
      showModal: false,
      class_label:'',
      toc_ids: [],
      subjects: [],
      thumbnail:null,
      class_id:0
    }
  }

  componentDidMount() {
    localStorage.setItem('fromtopic',true);
    this.state.is_faculty ? this.getFacultyProfile():this.getLearnerProfile();
    this.getStudyMaterial();

    
  }

  getStudyMaterial = async () => {
    const response = await this.props.getStudentTOC(this.state.subject_id);
    this.setState({ units: response.data.toc_data.units,
          toc_ids: common.getContentIds(response.data.toc_data.units), loading: false })
  }

  getLearnerProfile = async () => {
    const response = await this.props.getLearnerProfile();
    this.setState({subjects: response.data.subjects});
    this.getBatchName(response.data.batches);
    this.getSubject(response.data);
    this.getCoachListCall();
  }

  getCoachListCall = async() =>{
    console.log("function all");
    const subjectId=this.props.match.params;
    const response = await this.props.getMyCoachList();
    //this.setState({mycoachdata : response?.data})
    if(response?.status == 1){
      for(let i = 0; i < response?.data?.open_subjects?.length; i++) {
        if(parseInt(subjectId.id) === parseInt(response?.data?.open_subjects[i].id)) {
          
          this.setState({ thumbnail: response?.data?.open_subjects[i].thumbnail });
          //this.setState({ class_label: val.subjects[i].class_name });
          this.setState({ class_id: response?.data?.open_subjects[i].class_id });
        }
      }
    }
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({subjects: response.data.subjects});
    this.getSubject(response.data);
    this.getBatchName(response.data.batches);
  }

  getSubject = (val) => {
    const subjectId=this.props.match.params;
    
    if(val.subjects && val.subjects.length){
      for(let i = 0; i < val.subjects.length; i++) {
        console.log(val.subjects[i].id)
        if(parseInt(subjectId.id) === parseInt(val.subjects[i].id)) {
          
          this.setState({ thumbnail: val.subjects[i].thumbnail });
          this.setState({ class_label: val.subjects[i].class_name });
          this.setState({ class_id: val.subjects[i].class_id });
        }
      }
    }
  }

  getBatchName = (val) => {
    if(val && val.length){  
      for(let i = 0; i < val.length; i++) {
        if(parseInt(val[i].program.id) === parseInt(this.state.class_id))
        this.setState({batch_id:val[i].id});
      }
    }
  }

  showAttemptModal = () => this.setState({ showModal: true })

  closeAttemptModal = () => this.setState({ showModal: false })

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if(label.toLowerCase() === 'review' || label.toLowerCase() === 'result') {
      this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        {modal: true}
      )
    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })
    }
  }

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if(label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        {modal: true}
      )
    }
    else {
      this.setState({showAttemptModal: false})
      this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );
    }
  }

  getColor = (content) => {
    if(content != undefined){
      if(content.content_subtype === 4 || content.content_subtype === 5) {
        return {}
      }
      else {
        if(content.actions.length === 1) {
          if(content.actions[0].label.toLowerCase() === 'attempt') {
            return {backgroundColor: 'config.themePrimaryLightColor'};
          }
          else {
            return {backgroundColor: config.themePrimaryLightColor};
          }
        }
        else {
          return {backgroundColor: config.themePrimaryLightColor};
        }
      }
    } 
  }

  contentScore = (val) =>{

    if(isEmpty(val)) {
      return false;
    } else {
        return val;
    }
  }

	render() {

    const thumbNail=this.state.thumbnail;

    if(this.props.loading || this.state.loading) {
      return (
        <div className="app-body">
          <div className="app-div">
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
          </div>
        </div>
      )
    }
		return (
      <>
      {!isEmpty(this.props.contentPreview) 
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
          onClose={this.closeAttemptModal}>
          <AssessmentPreview 
            contentPreview={this.props.contentPreview}
            attempt={this.attemptPaper}
            showAttemptButton={true}
          />
        </Popup>}
			<div className="app-body">
        <div className={this.props.dashboard_type == 'dynamic'? "app-div app-studymaterial widget-app-div" : "app-div app-studymaterial"}>
          <Helmet>
            <title>{`${this.props.saveLanguageWiseData.study_material} - {this.props.saveLanguageWiseData.faculty}`}</title>
          </Helmet>
          <ReactNotifications/>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="study-material-main">
            <div className="heading alignLeft">
              <div id="trace-back" onClick={() => this.props.history.goBack()}>
                <img src={Back} alt="back"/>
              </div>
							<h2>{this.state.class_label} | {this.state.subject_label} | {this.props.saveLanguageWiseData.topics}</h2>
						</div>
            
            {this.state.topics.length === 0 && <Empty 
              statement={`No content is created yet in ${this.state.subject_label}`}/>}
            {this.state.topics.map(topic =>
              <div className="sm-units newDesign" key={topic.id}>
                <div className="sm-unit-div">
                  <div className="sm-unit-div1">
                    <span>{topic.label}</span>
                    {/* <img src={SMLib} alt="img"/> */}
                  </div>
                  {topic?.content?.length ?
                  <div className="sm-unit-div2">
                  <Carousel containerClass="sm-chapters" responsive={responsive}
                      keyBoardControl={false} swipeable={false} draggable={false}
                      removeArrowOnDeviceType={["tablet", "mobile"]}>
                    {topic?.content?.length > 0  ? topic?.content?.map(topic =>
                    <>
                     { topic?.content_type == 1 && topic?.content_subtype == 4 && 
                      <Link className="sm-unit-content" to={{
                        pathname: !this.state.is_faculty 
                          ? `/learner/classroom/${this.state.subject_id}`
                          : `/faculty/classroom/${this.state.subject_id}`,
                        state: {table_id: topic.id,subject_label:this.state.subject_label,fromStudyMaterial:true,assignment_id:topic.assignment_id}}} style={this.getColor(topic)}
                        onClick={() => this.props.getContentPreview(topic.content_id, topic.content_subtype)}>
                        {isEmpty(topic?.thumbnail)?<div className='sm-unit-content-thumbnail'><img src={ContentLib} alt="img"/></div> :<div className="sm-unit-content-thumbnail"
                            ><img src={common.addFileUrl(topic?.thumbnail)} alt="img"/>
                        </div>}
                        <div>{topic.title}</div>
                      </Link>
                     }

                      {topic?.content_type == 1 && topic.content_subtype == 5 && 
                      <Link className="sm-unit-content" to={{
                        pathname: !this.state.is_faculty 
                          ? `/learner/classroom/${this.state.subject_id}`
                          : `/faculty/classroom/${this.state.subject_id}`,
                        state: {table_id: topic.id,subject_label:this.state.subject_label,fromStudyMaterial:true,assignment_id:topic.assignment_id}}} style={this.getColor(topic)}
                        onClick={() => this.props.getContentPreview(topic.content_id, topic.content_subtype)}>
                        {isEmpty(topic?.thumbnail)?<div className='sm-unit-content-thumbnail'><img src={ContentLib} alt="img"/></div> :<div className="sm-unit-content-thumbnail"
                            ><img src={common.addFileUrl(topic?.thumbnail)} alt="img"/>
                        </div>}
                        <div>{topic.title}</div>
                      </Link>
                     }


                     {topic?.content_type == 2 && topic.content_subtype == 1 && <div className="sm-item"><Link style={this.getColor(topic)} className="sm-item" to={{
                        pathname: !this.state.is_faculty 
                          ? `/learner/classroom/${this.state.subject_id}`
                          : `/faculty/classroom/${this.state.subject_id}`,
                        state: {table_id: topic.id,subject_label:this.state.subject_label,fromStudyMaterial:true,assignment_id:topic.assignment_id}}} style={this.getColor(topic)}
                        onClick={() => this.props.getContentPreview(topic.content_id, topic.content_subtype)}>
                         <div className='sm-content-title'>
                            <img src={topic.assignment_id ? Assignment :Target} id="sm-assessm" alt="img"/>
                            <span>{topic.title}</span>
                            {!this.state.is_faculty && <span Style="margin-bottom: 10px;"><b Style={!this.contentScore(topic.score) ? "display:none" : ""}>Score :</b>{this.contentScore(topic.score)}<br/></span>}
                        </div>
                       </Link>
                       {topic.actions.length > 0 && !this.state.is_faculty ? <div className="sm-content-actions">
                              {topic.actions && topic.actions.map(action =>
                              <div key={action.id} 
                              onClick={()=>this.attemptAndReview(action.label, topic.content_id)}>
                              <button>
                                {action.label}
                              </button>
                            </div>
                           )}
                          </div> : <div className="sm-content-actions">
                              {topic.actions && topic.actions.map(action => 
                             <Link key={topic.content_id} to={{pathname: `/faculty/assessment-review/${topic.content_id}`,
                             state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                             <button>
                               {action.label}
                             </button>
                           </Link>
                           )}
                          </div>}
                      </div>
                     }

                     {topic?.content_type == 3 && topic.content_subtype == 0 && <div className="sm-item"><Link style={this.getColor(topic)} className="sm-item" to={{
                      pathname: !this.state.is_faculty 
                        ? `/learner/classroom/${this.state.subject_id}`
                        : `/faculty/classroom/${this.state.subject_id}`,
                      state: {table_id: topic.id,subject_label:this.state.subject_label,fromStudyMaterial:true,assignment_id:topic.assignment_id}}} style={this.getColor(topic)}
                      onClick={() => this.props.getContentPreview(topic.content_id, topic.content_subtype)}>
                      <div className="sm-content-title">
                        <img src={topic.assignment_id ? Assignment :Target} id="sm-assessm" alt="img"/>
                        {!this.state.is_faculty && <span Style="margin-bottom: 10px;"><b Style={!this.contentScore(topic.score) ? "display:none" : ""}>{this.props.saveLanguageWiseData.score} :</b>{this.contentScore(topic.score)}<br/></span>}
                        <span>{topic.title}</span>
                      </div>
                      </Link>
                      {topic.actions.length > 0 && !this.state.is_faculty ? <div className="sm-content-actions">
                              {topic.actions && topic.actions.map(action =>
                              <Link key={topic.assignment_id} to={{pathname: `/learner/assignment-review/${topic.assignment_id}`,
                              state: {action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id,class_id:this.state.class_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                            </div> : <div className="sm-content-actions">
                              {topic.actions && topic.actions.map(action =>
                              <Link key={topic.assignment_id} to={{pathname: `/faculty/assignment-review/${topic.assignment_id}`,
                              state: {action:action.label,subject:this.state.subject_label,id:this.state.subject_id,class_id:this.state.class_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                            </div>} 
                    </div>
                   }
                   </>

                    ): <div className="sm-unit-content-noexist">
                    {isEmpty(thumbNail)? <img src={ContentLib} alt="img"/>  :
                      <div className="sm-unit-content-thumbnail"
                        style={{backgroundImage: `url(${common.addFileUrl(this.state.thumbnail)})`}}>
                      </div>
                    }
                    <div>{topic.label}</div></div>
                     }
                  
                  </Carousel>

                  </div>
                  :''}
                </div>
                
                {topic.subtopics.map(subtopic =>
                  <div className="sm-layers wid100" key={subtopic.id}>
                    <h3>{subtopic.label}</h3>
                    <Carousel containerClass="sm-chapters" responsive={responsive}
                      keyBoardControl={false} swipeable={false} draggable={false}
                      removeArrowOnDeviceType={["tablet", "mobile"]}>
                      {subtopic.content.map(content => <>
                        {content.content_subtype === 4 || content.content_subtype === 5 
                          ? <Link className="sm-item" to={{
                            pathname: !this.state.is_faculty 
                              ? `/learner/classroom/${this.state.subject_id}`
                              : `/faculty/classroom/${this.state.subject_id}`,
                            state: {table_id: subtopic.id,subject_label:this.state.subject_label}}}
                            onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}
                            key={content.content_id} style={this.getColor(content)}>
                            {content.content_subtype === 4 || content.content_subtype === 5 
                              ? <div className="sm-thumbnail">
                              {content?.thumbnail?.length === 0 || content?.thumbnail === null
                                ? <div className="sm-thumbnail-img">
                                <img src={SMLib} alt="thumbnail"/>
                                {content.content_subtype === 4 ? <img src={Notes} id="sm-icon" alt="img"/>
                                  : content.content_subtype === 5 ? <img src={Video} id="sm-icon" alt="img"/> 
                                : null}
                              </div> : <div className="sm-thumbnail-img" style={{backgroundImage: 
                                `url(${common.addFileUrl(content?.thumbnail)})`}}>
                                {content.content_subtype === 4 ? <img src={Notes} id="sm-icon" alt="img"/>
                                  : content.content_subtype === 5 ? <img src={Video} id="sm-icon" alt="img"/> 
                                : null}
                              </div>}
                            </div> : null}
                            <div className="sm-content-title">

                              <span>{content.title}</span>
                            </div>
                            {content.actions.length > 0 && !this.state.is_faculty && <div className="sm-content-actions">
                              {content.actions && content.actions.map(action =>
                              <div key={action.id} 
                                onClick={()=>this.attemptAndReview(action.label, content.content_id)}>
                                <button>
                                  {action.label}
                                </button>
                              </div>
                            )}
                            </div>}

                             {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 0 && <div className="sm-content-actions">
                              {content.actions && content.actions.map(action =>
                              <Link key={content.assignment_id} to={{pathname: `/learner/assignment-review/${content.assignment_id}`,
                                  state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                            </div>}

                            {this.state.is_faculty && <div className="sm-content-actions">
                              {content.actions && content.actions.map(action =>
                                <>{content.content_subtype  === 1 ? <Link key={content.content_id} to={{pathname: `/faculty/assessment-review/${content.content_id}`,
                                state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link> : <Link to={{pathname: `/faculty/assignment-review/${content.assignment_id}` , state:{action:action.label,subject:this.state.subject_label,id:this.state.subject_id,class_id:this.state.class_id}}}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link>} </>
                            )}
                            </div>
                          }           
                          </Link> : <div className="sm-item"
                          key={content.content_id}>
                          <Link className="sm-item" style={this.getColor(content)}
                            to={{ pathname: !this.state.is_faculty 
                                ? `/learner/classroom/${this.state.subject_id}`
                                : `/faculty/classroom/${this.state.subject_id}`,
                              state: {table_id: subtopic.id,subject_label:this.state.subject_label} }}
                            onClick={() => this.props.getContentPreview(content.assignment_id ? content.assignment_id : content.content_id, content.content_subtype)}>
                            <div className="sm-content-title">
                              <img src={content.assignment_id ? Assignment :Target} id="sm-assessm" alt="img"/>
                              {!this.state.is_faculty && <span Style="margin-bottom: 10px;"><b Style={!this.contentScore(content.score) ? "display:none" : ""}>Score :</b>{this.contentScore(content.score)}<br/></span>}
                              <span>{content.title}</span>
                            </div>
                          </Link>
                          {content.actions.length > 0 && !this.state.is_faculty && <div className="sm-content-actions">
                              {content.actions && content.actions.map(action =>
                              <div key={action.id} 
                                onClick={()=>this.attemptAndReview(action.label, content.content_id)}>
                                <button>
                                  {action.label}
                                </button>
                              </div>
                            )}
                          </div>}

                          {content.actions.length > 0 && !this.state.is_faculty && content.content_subtype === 0 && <div className="sm-content-actions">
                              {content.actions && content.actions.map(action =>
                              <Link key={content.assignment_id} to={{pathname: `/learner/assignment-review/${content.assignment_id}`,
                                  state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            )}
                          </div>}


                          {this.state.is_faculty && <div className="sm-content-actions">
                              {content.actions && content.actions.map(action =>
                                <>{content.content_subtype  === 1 ? <Link key={content.content_id} to={{pathname: `/faculty/assessment-review/${content.content_id}`,
                                state: { action:action.label,batch:this.state.batch_id,is_subject:1,modal:true,class_id:this.state.class_id,subject:this.state.subject_label,id:this.state.subject_id}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link> : <Link to={{pathname: `/faculty/assignment-review/${content.assignment_id}` , state:{action:action.label,subject:this.state.subject_label,id:this.state.subject_id,class_id:this.state.class_id}}}>
                                    <button>
                                      {action.label}
                                    </button>
                                  </Link>} </>
                            )}
                            </div>
                          }
                        </div>}
                      </>
                      )}
                    </Carousel>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
			</div>
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    dashboard_type : state.commonReducer.dashboard_type,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentTOC, getContentPreview ,getMyCoachList,getLearnerProfile,getFacultyProfile,getAttemptPreview} = actions;
  return {
    getMyCoachList: () => dispatch(getMyCoachList()),
    getStudentTOC: (subject_id) => dispatch(getStudentTOC(subject_id)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    getContentPreview: (contentId, content_subtype) => dispatch(getContentPreview(contentId, content_subtype))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterialTopics);