import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import ReactNotifications, { store } from 'react-notifications-component';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import config from '../../config.js';
import common from '../../common';
import actions from '../../redux/actions';
import WeekTable from '../../components/temp3';
import Add from '../../public/icons/add.svg';
import Classroom from '../../public/icons/classroom.svg';
import Live from '../../public/icons/live.svg';
import Edit from '../../public/icons/edit.svg';
import markAttendanceIcon from '../../public/images/mark-attendance-icon.png';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Weblink from '../../public/icons/weblink.svg';
import Header from '../../components/navigation/Header';
import Calendar2 from '../../public/icons/calendar2.svg';
import WWW from '../../public/icons/web.svg';
import Content from '../../public/images/content.svg';
import Empty from '../../components/error/Empty';
import Admin from '../../public/images/contacts-icon.png';
import './styles.css';
import { Link } from 'react-router-dom';
let changeN = 0;

class LiveSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      openc: false,
      open: false,
      open2: false,
      open3: false,
      opend: false,
      d_id: '',
      center_id: localStorage.getItem('center_id'),
      branch: parseInt(localStorage.getItem('center_id')),
      classes: [],
      classes2: [],
      filterClasses: [],
      filterClass: '',
      filterSection: '',
      class_centers: [],
      class_center: '',
      subjects: [],
      sections: [],
      filterSections: [],
      faculties: [],
      faculties_ids: [],
      faculties2: [],
      branches: [],
      lectures: [],
      showCal: true,
      title: '',
      mode: 1,
      url: '',
      weblink:false,
      is_webinar:'',
      room: '',
      allow_room: false,
      duration: 1,
      classs: '',
      subject: '',
      section: '',
      faculty: '',
			currentYear: new Date(),
			currentMonth: new Date(),
      selectedDate: new Date(),
      lecture_start_date_time: '',
      start_date_time: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
      loading: true,
      lectures: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      date:"",
      open_url:null,
      contentDate:"",
      countTop:0,
      countBottom:0,
      
      group_type:'',
      id:0,
      is_recent:0,
      getUrl:false,
      current_date_records:[],
      is_faculty_admin:false,
      user_id: localStorage.getItem("user_id"),
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      username: localStorage.getItem("username"),
      encrypted_url:null,
      lecture_view_record_url:"",
      is_attendanceURL:false,
      currentLectureURL:'',
      record_url:'',
      serverMode:0,
      liveclass_server:0
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    document.addEventListener('mousedown', this.handleClickOutside);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ start_date:date })
    this.getCurrentDate();
    this.getCenterList();
    this.getClassList();
    this.getFacultyList(this.state.center_id);
    this.getLectureList(date, 15, 'forward', true, this.state.center_id, this.state.filterClass,
                          this.state.filterSection);
   this.getUrlfromCurrentPage();                      
  }


  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ openc: false })
    }
  }

  getLectureList = async (date, days, direction, if_loading, center, classs, section) => {
    const response = await this.props.getLectureList(date, days, direction, if_loading, center, classs, section, this.state.user_id,localStorage.getItem("is_faculty"));
    if(response) {
      this.setState({ lectures: response.data.data, loading: false, countBottom:1,
                        id:response.data.data ? response.data?.data[0]?.id : "NA" });
      this.ifRecordsExists();

      if(!response.data.data.length){

        this.setState({id:"NA"});
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');
        
        this.getLectureListOnBackward(currentDate, 15, 'backward', false, this.state.branch,
                                        this.state.filterClass, this.state.filterSection,this.state.user_id);
      }
    }
    else {
      this.setState({hasMore:false,loading: false})
    }
  }

   ifRecordsExists = () =>{
    var val=this.state.start_date;
    const month=this.state.mlist;

    var d = new Date(val);
    var arr=[];
    var map = {};
    var temparray=new Array();

    for(let i=d.getMonth(); i<12;i++){

      let contentDate = [
        d.getFullYear(),
        ('0' + (i+1)).slice(-2)
      ].join('-');

      this.state.lectures && this.state.lectures.some(function(test,index) {
        
        if(i <= new Date(test.start_date_time).getMonth()){
          
          map[month[i]]=new Date(contentDate).getMonth() === new Date(test.start_date_time).getMonth()
                        && new Date(contentDate).getFullYear() === new Date(test.start_date_time).getFullYear()?1:0
          let obj ={"month":month[i],"status":map[month[i]],"year":"2020"}            
          temparray.push(obj);
          return true;
        }
      });
    }
    this.setState({current_date_records:temparray})
  }

  getLectureListOnForward = async (date, days, direction, if_loading, center, classs, section) => {
    const response= await this.props.getLectureList(date, days, direction, if_loading, center, classs, section,this.state.user_id,localStorage.getItem("is_faculty"));
    if(response){
      this.setState({
        lectures: this.state.lectures ? this.state.lectures.concat(response.data.data) : response.data.data,
        loading: false, countBottom:1
      });
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getLectureListOnBackward = async (date, days, direction, if_loading, center, classs, section) => {
    const response= await this.props.getLectureList(date, days, direction, if_loading, center, classs, section,this.state.user_id,localStorage.getItem("is_faculty"));
    if(response){
      this.setState({
        lectures: response.data.data ? response.data.data.concat(this.state.lectures):this.state.lectures,
        loading: false, countTop:1
      });
      this.ifRecordsExists();
      if(this.state.id)
        this.handleScrollPos();
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
   this.setState({start_date:date,is_recent:0,loading:true,countTop:0,lectures:[],filterClass:0,filterSection:0})

   this.getCurrentDate();
   this.getLectureList(date, 15, 'forward', true, this.state.branch,0,
                          0,this.state.user_id);
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date(this.state.current_date.split(' ').join('-'));

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      cd.getMonth(),
      d.getMonth()
    ];
    return date;
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  onChange = value => {
    var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');


    this.setState({ hasMore: false,countTop:0,current_date:calendarDate,lectures:[]});
    this.setState({openc:false})

    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });
    this.setState({start_date:date,loading:true,is_recent:1})
    
    this.getLectureList(date, 15, 'forward', true, this.state.branch, this.state.filterClass,
                          this.state.filterSection,this.state.user_id);
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ current_date: date, date: currentDate});
  }


  openCalendar=()=>{
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();

    if(calendarMonth<currentMonth){
      document.getElementsByClassName("react-calendar__navigation__next-button")[0].click();
    }
    else if(calendarMonth>currentMonth){
      document.getElementsByClassName("react-calendar__navigation__prev-button")[0].click();
    }
    
    if(this.state.open===true)
      this.setState({openc:false})
    if(this.state.open===false)
      this.setState({openc:true})
  }

  handleScroll = (e) => { 
    var scrolltop=[];
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('session-div');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.lectures && this.state.lectures.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){

          this.setState({current_date:this.getTime(test.start_date_time)[4]});
        }
      });
    }

    const top = e.target.scrollTop===0;
    if(top){
      if(this.state.countTop===0){
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getLectureListOnBackward(currentDate, 15, 'backward', false, this.state.branch,
                                        this.state.filterClass, this.state.filterSection,this.state.user_id);
      }
    }
  }

  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) +' HRS':minutes +' mins';
  }

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }
	
	goToday = (goToSlide) => {
		this.setState({
			currentMonth: new Date(),
      selectedDate: new Date()
		});
		let date2 = (new Date()).getDate();
		let slide = date2 - 1;
    goToSlide(slide); 
    var date = new Date();
    var datex = date.getDate();
    if(datex < 10) {
      datex = '0' + datex;
    }
    var month = date.getMonth() + 1;
    if(month < 10) {
      month = '0' + month;
    }
		var apiDate = `${date.getFullYear()}-${month}-${datex}T00:00:00`;
    this.getLectureList(date, 15, 'forward', true, this.state.branch, this.state.filterClass,
                          this.state.filterSection,this.state.user_id)
  }

  branchChange = (e) => {
    this.setState({ branch: e.target.value, classes2: this.state.classes.filter(classs => classs.center === parseInt(e.target.value))
            ,filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value))
            ,filterSections: [], subjects: [], sections: [] ,classs: '', filterClass: '', 
            filterSection: '', section: '', faculty: '', subject: ''
           });
    this.setState({ loading: true, page: 1, hasMore: false, countTop: 0 })
    this.getFacultyList(e.target.value);
    this.getLectureList(this.state.start_date, 15, 'forward', true, parseInt(e.target.value),
                          this.state.filterClass, this.state.filterSection,this.state.user_id);
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }

  getClassList = async () => {
		const response = await this.props.getClassList2();
		if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ),
        classes2: response.data.filter(classs => classs.center === parseInt(this.state.center_id)),
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
    }
  }

  getSectionList = async (classs) => {
    if(classs === '') {
      this.setState({ sections: [] });
    }
    else {
      const response = await this.props.getSectionList2(this.state.branch, classs);
      if(response !== undefined) {
        this.setState({ sections: response.data });
      }
    }
  }

  getSectionList2 = async (classs) => {
    if(classs === '') {
      this.setState({ filterSections: [] });
    }
    else {
      const response = await this.props.getSectionList2(this.state.branch, classs);
      if(response !== undefined) {
        this.setState({ filterSections: response.data });
      }
    }
  }

  getSubjectList = async (classs) => {
    if(classs === '') {
      this.setState({ subjects: [] });
    }
    else {
      const response = await this.props.getSubjectList2(this.state.branch,classs);
      if(classs !== undefined && classs !== '') {
        var arr = response.data.filter(subject => subject.class_details.id === parseInt(classs));
        this.setState({ subjects: arr });
      }
    }
  }

  getFacultyList = async (center) => {
		const response = await this.props.getFacultyList(center,1);
		this.setState({ faculties: response.data, faculties_ids: response.data.map(function(el) {
      var faculty = Object.assign({}, el);
      faculty.class_ids = faculty.class_details.map(obj => { return obj.id });
      faculty.section_ids = faculty.section.map(obj => { return obj.batch_id });
      faculty.subject_ids = faculty.subjects.map(obj => { return obj.id });
      return faculty;
    }) });
  }

  show = (val) => this.setState({ open: true, open2: false, title: '',  record_url: '', liveclass_server: 0, duration: 1, classs: '', 
                                    subject: '', section: '', faculty: '', mode: val,
                                    subjects: [], sections: [], faculties2: [], weblink: '',
                                    lecture_start_date_time: '', url: '', allow_room: false });
  
  close = () => this.setState({ open: false });

  show2 = () => this.setState({ open2: true });
  
  close2 = () => this.setState({ open2: false });

  show3 = (val) => {
    console.log(val);
    this.getSectionList(val.classs.id);
    this.getSubjectList(val.classs.id);
    this.getFaculties2(parseInt(val.classs.id), parseInt(val.subject.id), parseInt(val.batch.id));
    this.setState({ liveclass_server: val.liveClass_server, d_id: val.id, title: val.title, record_url: val.record_url, duration: val.duration_hrs, classs: val.classs.id, 
                      subject: val.subject.id, section: val.batch.id, faculty: val.faculty.id, mode: val.mode,
                      lecture_start_date_time: val.start_date_time, url: val.mode === 0 ? val.url : '',
                      room: val?.room === '' || val?.room === 'False' ? '' : val?.room, weblink: val.mode === 1 ? val.url: '',
                      allow_room: val.mode === 2 && val?.room !== '' && val?.room !== 'False', group_type: val.group_type});
  }
  
  close3 = () => this.setState({ open3: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });


  onLiveClassServerChange = (e) =>{
    console.log(e.target.value)
   this.setState({liveclass_server:e.target.value});
   //console.log(this.state.liveClass_server)
  }

  
  onSessionTypeChange = (e) =>{
    this.setState({ mode: parseInt(e.target.value)})
  }

  titleChange = (e) => {
    this.setState({ title: e.target.value })
  }
  isWebinar = (e) => {
    this.setState({ is_webinar: !this.state.is_webinar })
  }

  durationChange = (e) => {
    const duration = (e.target.validity.valid) ? e.target.value : this.state.duration;
    this.setState({ duration });
  }

  minusTime = () => {
    var duration = this.state.duration;
    if(this.state.duration >= 1) {
      duration = parseInt(duration) - 1;
      this.setState({ duration: duration });
    }
  }

  addTime = () => {
    var duration = this.state.duration;
    duration = parseInt(duration) + 1;
    this.setState({ duration: duration });
  }

  getFaculties = (subject, section) => {
    if(section !== '' && subject !== '') {
      var arr = this.state.faculties_ids;
      var faculties = [];
      for(let i = 0; i < arr.length; i++) {
        if(arr[i].class_ids.indexOf(parseInt(this.state.classs)) !== -1 && 
          arr[i].subject_ids.indexOf(subject) !== -1 &&
          arr[i].section_ids.indexOf(section) !== -1) {
          
          if(arr[i].user_id == this.state.user_id){
            faculties = [];
            faculties.push(arr[i]);
            break;
          }else{
            faculties.push(arr[i]);
          }
        }
      }
      this.setState({ faculties2: faculties });
    }
  }

  getFaculties2 = (classs, subject, section) => {
    if(section !== '' && subject !== '') {
      var arr = this.state.faculties_ids;
      var faculties = [];
      for(let i = 0; i < arr.length; i++) {
        if(arr[i].class_ids.indexOf(classs) !== -1 && 
          arr[i].subject_ids.indexOf(subject) !== -1 &&
          arr[i].section_ids.indexOf(section) !== -1) {

          if(arr[i].user_id == this.state.user_id){
            faculties = [];
            faculties.push(arr[i]);
            break;
          }else{
            faculties.push(arr[i]);
          }
        }
      }
      this.setState({ faculties2: faculties, open3: true });
    }
  }

  onBusinessTypeChange = (e) => {
    this.setState({ group_type: e.target.value})
  }

  onClassChange = (e) => {
    this.setState({ classs: e.target.value, subject: '', section: '', faculty: '' })
    this.getSectionList(e.target.value);
    this.getSubjectList(e.target.value, this.state.classs);
  }

  onSubjectChange = (e) => {
    this.setState({ subject: e.target.value, faculty: '' })
    this.getFaculties(parseInt(e.target.value), parseInt(this.state.section));
  }

  onSectionChange = (e) => {
    this.setState({ section: e.target.value, faculty: '' })
    this.getFaculties(parseInt(this.state.subject), parseInt(e.target.value));
  }

  onFacultyChange = (e) => {
    this.setState({ faculty: e.target.value })
  }

  onClassChange2 = (e) => {
    this.setState({ filterClass: e.target.value, filterSection: '' ,is_recent:1})
    this.setState({ loading: true, page: 1, hasMore: false, countTop: 0 })
    this.getSectionList2(e.target.value);
    this.getLectureList(this.state.start_date, 15, 'forward', true, this.state.branch,
                          e.target.value, this.state.filterSection,this.state.user_id);
  }

  onSectionChange2 = (e) => {
    this.setState({ loading: true, page: 1, hasMore: false, countTop: 0 ,is_recent:1})
    this.setState({ filterSection: e.target.value });
    this.getLectureList(this.state.start_date, 15, 'forward', true, this.state.branch,
                          this.state.filterClass, e.target.value,this.state.user_id);
  }

  onChangeTime = (e) => {
    this.setState({ lecture_start_date_time: e.target.value });
  }

  onChangeViewRecordUrl = (e) =>{
    this.setState({lecture_view_record_url: e.target.value });
  }

  modeChange = (e) => {
    console.log("mode change", e.target.value)
    this.setState({ mode: parseInt(e.target.value) });
    
  }

  timeChange = (e) => {
    this.setState({ start_date_time: e.target.value })
    this.getLectureList(new Date(new Date(e.target.value).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0], this.state.branch
                          ,this.state.filterClass, this.state.filterSection,this.state.user_id);
  }

  locationChange = (e) => {
    if(this.state.mode === 2) {
      if(this.state.allow_room) {
        const room = (e.target.validity.valid) ? e.target.value : this.state.room;
        this.setState({ room });
      }
    }
    else {
      this.setState({ url: e.target.value })
    }
  }

  allowRoom = () => {
    if(this.state.allow_room) {
      this.setState({ room: '' })
    }
    this.setState({ allow_room: !this.state.allow_room })
  }

  urlChange = (e) => {
    this.setState({ weblink: e.target.value })
  }

  displayTime = (val) => {
    var hours = val.getHours();
    var minutes = val.getMinutes();
    var ampm = hours >= 12 ? this.props.pagewiseLanguageData.pm : this.props.pagewiseLanguageData.am;
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var timewithampm = hours + ':' + minutes + ' ' + ampm;
    return `${timewithampm}`.toUpperCase();
  }

  updateLecture = async () => {
    if(this.state.mode === this.state.prev_mode) {
      this.setState({ url: this.state.prev_url });
    }
    const { d_id, branch, lecture_start_date_time, mode, title, duration, url, weblink, room, classs, subject, section, 
            faculty,is_webinar,group_type,record_url } = this.state;
    if(mode === 1) {
      if(common.validateUrl(weblink)) {
        await this.props.editLecture(d_id, branch, lecture_start_date_time, mode, title, duration, url, weblink, room, classs, subject, 
          section, faculty,is_webinar,record_url,group_type);
        this.setState({ open3: false });
      }
      else {
        store.addNotification({
          title: 'Invalid URL',
          message: this.props.pagewiseLanguageData.please_enter_a_valid_URL,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        })
      }
    }

    else {
      await this.props.editLecture(d_id, branch, lecture_start_date_time, mode, title, duration, url, weblink, room, classs, subject, 
        section, faculty,is_webinar,record_url,group_type);
      this.setState({ open3: false });
    }
  }

  newLecture = async () => {
    const { branch, lecture_start_date_time, mode, title, duration, url, weblink, room, classs, subject, section, 
            faculty,is_webinar,liveclass_server,record_url,group_type} = this.state;
    console.log("group_type",group_type)        
    if(mode === 1) {
      if(common.validateUrl(weblink)) {
        await this.props.newLecture(branch, lecture_start_date_time, mode, title, duration, url, weblink, room, classs, subject, 
          section, faculty,is_webinar,liveclass_server,record_url,group_type);
        this.setState({ open: false });
      }
      else {
        store.addNotification({
          title: 'Invalid URL',
          message: this.props.pagewiseLanguageData.please_enter_a_valid_URL,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        })
      }
    }
    else {
      await this.props.newLecture(branch, lecture_start_date_time, mode, title, duration, url, weblink, room, classs, subject, 
        section, faculty,is_webinar,liveclass_server,record_url,group_type);
      this.setState({ open: false });
    }
  }

  deleteLecture = async () => {
    const { d_id } = this.state;
    await this.props.deleteLecture(d_id);
    this.setState({ opend: false });
  }

  createContent = async (id) => {
    this.setState({loading :false})
    if(!id || isNaN(parseInt(id))) {
      this.setState({open5 : true,loading:false,encrypted_url:''})
      //document.getElementsByClassName("header2")[0].style.position="unset";
      return ;
    }
    const response = await this.props.encryption(this.state.username)
    
    if(response != undefined){
      this.setState({encrypted_url: response.data?.encrypted_text+'&class_id='+parseInt(id),open5 :false ,loading:false})
      //document.getElementById("appBody").style.position="unset";
      //document.getElementsByClassName("header2")[0].style.position="unset";

      var hiddenElement = document.createElement('a');
      hiddenElement.href = config.liveClassContentCreationUrl+response.data?.encrypted_text+'&class_id='+parseInt(id);
      hiddenElement.target = '_blank';
      //hiddenElement.download = 'usersLog-'+dateObj.toISOString()+'.csv';
      hiddenElement.click();
    }
  }

  close5 =() => {
    this.setState({open5 :false})
    //document.getElementsByClassName("header2")[0].style.position="fixed";
  }

  getURL =(url) =>{
    this.setState({getUrl:true,open_url:url})
  }

  closeUrl = () => {
    this.setState({getUrl:false})
  }

  bbbJoinLiveClass = async (etc,session) =>{
    const {name,user_id} = this.state

    if(etc){
      let etcObject = JSON.parse(etc);

      const response = await this.props.bbbJoinLiveClass(session,name,etcObject.moderatorPasswordBBB,user_id)
      console.log(response)
      if(response != undefined){
        window.open(response.data?.liveClassUrl, '_blank').focus();
      }
    }
    
  }

  // zoomAttendance = (faculty_id,room,lectureURL) =>{
  //   let facultyID = faculty_id
  //   let roomID = room;
  //   console.log(roomID,facultyID)
  //   if(facultyID,roomID){
  //     this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
  //   }
    
  // }

  zoomAttendance = async(faculty_id,room,lectureURL,lecture_id) =>{
    let facultyID = faculty_id
    let roomID = room;
    console.log(roomID,facultyID)
    if(facultyID,roomID){
      //this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
    }
    //let roomID = room
    let lectureID = lecture_id
    console.log("lectureID",lectureID);
    if(lectureID){
      const response = await this.props.facultyZoomCreate(lectureID);
      console.log("response",response)
      if(response !== undefined || response?.status == 1){
        let lectureURL = response.data.url;
        let roomID = response.data.meeting_id;
        console.log("lectureURLdddd",lectureURL)
        console.log("roomID",roomID)
        if(facultyID,roomID,lectureURL){
          this.zoomAttendanceAPI(facultyID,roomID,lectureURL);
        }
      }
    }

  }
 

  zoomAttendanceAPI = async (faculty_id,room,lectureURL) =>{
    this.setState({currentLectureURL:lectureURL});
    const response = await this.props.callFacultyzoomAttendanceAPI(faculty_id,room);
    
      this.setState({is_attendanceURL:false});
      console.log("faculty_id", faculty_id);
      console.log("roomdddd", room);
      window.open(this.state.currentLectureURL,"_blank");
    

   }
  
   closeIframe = () =>{
    let {is_attendanceURL} = this.state;
    this.setState({is_attendanceURL:false})
   }

   recordURLChange = (e) => {
    this.setState({ record_url: e.target.value })
   }

   markAttendance = (lecture) => {
   let save_lecture_id = lecture.id;
   let save_batch_id = lecture.batch.id;
   console.log(save_lecture_id);
   console.log(save_batch_id);
  }

	render() {
    const { value } = this.state.start_date;
    const {encrypted_url,loading} =this.state;
    const isEnabled = this.state.title.length > 0 && this.state.classs !== '' &&
      this.state.subject !== '' && this.state.section !== '' && this.state.faculty !== '' &&
      this.state.lecture_start_date_time !== '' && (this.state.duration + '').length > 0 &&
      (this.state.mode === 0 ? this.state.url.length > 0 : this.state.mode === 1
        ? this.state.weblink.length > 0 : true)
		return (
			<div className={this.state.is_attendanceURL ? "admin-div zIndexchange" : 'admin-div'}>

        <div className={this.state.is_attendanceURL ? "IFrameDialogWrapper open" : 'IFrameDialogWrapper'}>
          <div className="IFrameDialogContainer">
            <a href="javascript:void(0)" onClick={this.closeIframe} title="close" className="iframeClose">X</a>
            <iframe name="iframe_a" src={this.state.currentLectureURL}>

            </iframe>
          </div>
        </div>


        <Helmet>
          <title>{this.props.pagewiseLanguageData.sessions}</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search sessions"/>
        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="content-create-list">
          <span className="close" onClick={this.close5}>&#x2715;</span>
          {encrypted_url ? <iframe src={"https://learningoxygen.com/login?secret="+encrypted_url} name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe> :
            <div className="adminContact"><img src={Admin} alt="admin"/><div>{this.props.pagewiseLanguageData.contact_to_administrator}</div></div>
          }
        
        </Popup>

        <Popup open={this.state.getUrl} closeOnDocumentClick onClose={this.closeUrl} className="content-geturl">
          <span className="close" onClick={this.closeUrl}>&#x2715;</span>
          <div className="url-child1">{this.props.pagewiseLanguageData.get_url}</div>
          <div className="url-child2">{this.state?.open_url}</div>
        </Popup>

        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content4">
          <div className="popup-header2">
            {this.props.pagewiseLanguageData.choose_session_type}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data4">
            <div className="choose-content2">
              <div className="type-content2" onClick={() => this.show(2)}>
                <img src={Live} alt="live"/>
                <div>
                  {this.props.pagewiseLanguageData.noesis_virtual_live_class}
                  <span>
                    {this.props.pagewiseLanguageData.safe_message}
                  </span>
                </div>
              </div>
              <div className="type-content2" onClick={() => this.show(0)}>
                <img src={Classroom} alt="class"/>
                <div>
                  {this.props.pagewiseLanguageData.classroom_session}
                  <span>
                    {this.props.pagewiseLanguageData.manage_your_class_schedules_through_classroom_sessions}
                  </span>
                </div>
              </div>
              <div className="type-content2" onClick={() => this.show(1)}>
                <img src={Weblink} alt="web"/>
                <div>
                  {this.props.pagewiseLanguageData.embed_any_other_live_session_from_web}
                  <span>
                    {this.props.pagewiseLanguageData.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content4">
          
          <div className="popup-header2">
            {this.props.pagewiseLanguageData.scheduling_new_session}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data4">
            {/*New DD Added*/}
            <div className="singlerow">
              <div className="block">
                <select onChange={this.onSessionTypeChange}>
                  <option value={''}>
                    {this.props.pagewiseLanguageData.choose_session_type}
                  </option>
                  <option  value="2" selected = {this.state.mode == 2 ?"selected": ''}>
                    {this.props.pagewiseLanguageData.noesis_virtual_live_class}
                  </option>
                  <option value="0" selected = {this.state.mode == 0 ?"selected": ''}>
                    {this.props.pagewiseLanguageData.classroom_session}
                  </option>
                  <option value="1" selected = {this.state.mode == 1 ? "selected": ''}>
                  {this.props.pagewiseLanguageData.embed_any_other_live_session_from_web}
                  </option>
                </select>
                </div>  
              </div>
              {/*New DD Added*/}
            <div className="popupx3-inputs">
            <div className="block">
              <input placeholder={this.props.pagewiseLanguageData.session_title} onChange={this.titleChange}
                value={this.state.title}/>
              </div>
              {this.state.mode === 1 &&
              <div className="block">
              <input placeholder={this.props.pagewiseLanguageData.recording_url} onChange={this.recordURLChange}
                value={this.state.record_url}/>
              </div>
                }
              {this.state.mode === 2 &&
              <div className="block">
              <select onChange={this.onLiveClassServerChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_server}
                </option>
                <option value="1">
                {this.props.pagewiseLanguageData.congrea_server}
                </option>
                <option value="2">
                {this.props.pagewiseLanguageData.lo2_server}
                </option>
                <option value="3">
                  {this.props.pagewiseLanguageData.zoom_server}
                </option>
              </select>
              </div>
              }
            </div>
           
            <div className="popupx3-inputs">
              <div className="block">
              <input type="datetime-local" placeholder={this.props.pagewiseLanguageData.scheduled_date_time} 
                onChange={this.onChangeTime}/>
              </div> 
              <div className="block">
              {this.state.mode === 0 ? <input type="text" placeholder="Location" 
                onChange={this.locationChange}/> : this.state.mode === 1 
                ?  <input type="text" placeholder={this.props.pagewiseLanguageData.web_url} onChange={this.urlChange}/>
                : <div className="lecture-room">
                  <input type="text" placeholder={this.props.pagewiseLanguageData.room_id} onInput={this.locationChange}
                    value={this.state.room}/>
                  <input type="checkbox" onChange={this.allowRoom}/>
                  <label>{this.props.pagewiseLanguageData.use_existing_room_id}</label>
                </div>}
                </div> 
            </div>



            <div className="popupx1-inputs">
              {this.state.mode === 1 ? <><input type="checkbox"  checked={this.state.is_webinar === true} onChange={this.isWebinar}/> {this.props.pagewiseLanguageData.is_webinar}</> : ""}
            </div>

            {/* <div className="popupx3-inputs">
              <input type="text" placeholder="record_url" onChange={this.onChangeViewRecordUrl} name="view_record_url"/>
            </div> */}

            <div className="popupx-inputs">
              <div>
                <span>{this.props.pagewiseLanguageData.duration}</span>
                <div className="asm-setting-duration">
                  <span onClick={this.minusTime}>&#45;</span>
                  <div><input type="text" value={this.state.duration} pattern="[0-9]*"
                    onInput={this.durationChange}/> {this.props.pagewiseLanguageData.hrs}</div>
                  <span onClick={this.addTime}>&#43;</span>
                </div>
              </div>
              <div className="popupx-dropdowns">
                {this.state.liveclass_server == 3 ?
                <select onChange={this.onBusinessTypeChange}>
                  <option value={''}>{this.props.pagewiseLanguageData.select_business_vertical}</option>
                  <option value={'university'}>{this.props.pagewiseLanguageData.university}</option>
                  <option value={'retail'}>{this.props.pagewiseLanguageData.retail}</option>
                </select>
                : ''}
              </div>
            </div>
            <div className="popupx-dropdowns">
              <select onChange={this.onClassChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_class}
                </option>
                {this.state.classes2.map(classs =>
                  <option key={classs.id} value={classs.id}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select onChange={this.onSubjectChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_subject}
                </option>
                {this.state.subjects.map(subject =>
                  <option key={subject.id} value={subject.id}>
                    {subject.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popupx-dropdowns">
              <select onChange={this.onSectionChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_section}
                </option>
                {this.state.sections.map(section =>
                  <option key={section.id} value={section.id}>
                    {section.label}
                  </option>
                )}
              </select>
              <select value={this.state.faculty} onChange={this.onFacultyChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_faculty}
                </option>

                {this.state.faculties2.map(faculty =>
                  <option key={faculty.id} value={faculty.id}>
                    {faculty.user.name}
                  </option>
                )}
              </select>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newLecture}>{this.props.pagewiseLanguageData.schedule}</button>
          </div>
        </Popup>
        <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="popup-content4">
          <div className="popup-header2">
            {this.props.pagewiseLanguageData.editing_session}
          </div>
          <span className="close" onClick={this.close3}>&#x2715;</span>
          <div className="popup-data4">
            {/*New DD Added*/}
            <div className="singlerow">
              <div className="block">
                <select onChange={this.onSessionTypeChange}>
                  <option value={''}>
                    {this.props.pagewiseLanguageData.choose_session_type}
                  </option>
                  <option  value="2" selected = {this.state.mode == 2 ?"selected": ''}>
                    {this.props.pagewiseLanguageData.noesis_virtual_live_class}
                  </option>
                  <option value="0" selected = {this.state.mode == 0 ?"selected": ''}>
                    {this.props.pagewiseLanguageData.classroom_session}
                  </option>
                  <option value="1" selected = {this.state.mode == 1 ? "selected": ''}>
                  {this.props.pagewiseLanguageData.embed_any_other_live_session_from_web}
                  </option>
                </select>
                </div>  
              </div>
              {/*New DD Added*/}
            <div className="popup-inputs">
              <div className="block">
              <input placeholder={this.props.pagewiseLanguageData.session_title} onChange={this.titleChange}
                value={this.state.title}/>
                </div>
                {this.state.mode === 1 &&
                <div className="block">
                <input placeholder={this.props.pagewiseLanguageData.recording_url} onChange={this.recordURLChange}
                  value={this.state.record_url}/>
                </div>
                }
                {this.state.mode === 2 ? 
                <div className="block">
                <select value={this.state.liveclass_server} onChange={this.onLiveClassServerChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_server}
                </option>
                <option value="1">
                 {this.props.pagewiseLanguageData.congrea_server}
                </option>
                <option value="2">
                {this.props.pagewiseLanguageData.lo2_server}
                </option>
                <option value="3">
                {this.props.pagewiseLanguageData.zoom_server}
                </option>
              </select>
              </div>
              : '' }
            </div>
            <div className="popupx3-inputs">
              <div className="block">
              <input type="datetime-local" placeholder={this.props.pagewiseLanguageData.scheduled_date_time} 
                onChange={this.onChangeTime} value={this.state.lecture_start_date_time}/>
              </div>  
              <div className="block">
              {this.state.mode === 0 ? <input type="text" placeholder={this.props.pagewiseLanguageData.location} 
                onChange={this.locationChange} value={this.state.url}/> 
                : this.state.mode === 1 ? <input type="text" placeholder={this.props.pagewiseLanguageData.web_url} 
                  onChange={this.urlChange} value={this.state.weblink}/>
                : <div className="lecture-room">
                  <input type="text" placeholder={this.props.pagewiseLanguageData.room_id}  onInput={this.locationChange}
                    value={this.state.room}/>
                  <input type="checkbox" onChange={this.allowRoom} 
                    defaultChecked={this.state.allow_room}/>
                  <label>{this.props.pagewiseLanguageData.use_existing_room_id}</label>
                </div>}
               </div> 
            </div>

            <div className="popupx1-inputs">
              {this.state.mode === 2 ? <><input type="checkbox"  checked={this.state.is_webinar === true} onChange={this.isWebinar}/> {this.props.pagewiseLanguageData.is_webinar}</> : ""}
            </div>

            {/* <div className="popupx3-inputs">
              <input value={this.state.lecture_view_record_url} type="text" placeholder="record_url" onChange={this.onChangeViewRecordUrl} name="view_record_url"/>
            </div> */}

            <div className="popupx-inputs">
              <div>
                <span>{this.props.pagewiseLanguageData.duration}</span>
                <div className="asm-setting-duration">
                  <span onClick={this.minusTime}>&#45;</span>
                  <div><input type="text" value={this.state.duration} pattern="[0-9]*"
                    onInput={this.durationChange}/> {this.props.pagewiseLanguageData.hrs}</div>
                  <span onClick={this.addTime}>&#43;</span>
                </div>
              </div>
              <div className="popupx-dropdowns">
                {this.state.mode == 2 && this.state.liveclass_server == 3 &&
                <select onChange={this.onBusinessTypeChange}>
                  <option value={''} selected = {this.state.group_type == "null" ?"selected": ''}>{this.props.pagewiseLanguageData.select_business_vertical}</option>
                  <option value={'University Vertical'} selected={this.state.group_type == "University Vertical" ?"selected": ''} >{this.props.pagewiseLanguageData.university_vertical}</option>
                  <option value={'Retail Vertical'} selected={this.state.group_type == "Retail Vertical"  ?"selected": ''}>{this.props.pagewiseLanguageData.retail_vertical}</option>
                </select>
                }
              </div>
            </div>
            <div className="popupx-dropdowns">
              <select value={this.state.classs} onChange={this.onClassChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_class}
                </option>
                {this.state.classes2.map(classs =>
                  <option key={classs.id} value={classs.id}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select value={this.state.subject} onChange={this.onSubjectChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_subject}
                </option>
                {this.state.subjects.map(subject =>
                  <option key={subject.id} value={subject.id}>
                    {subject.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popupx-dropdowns">
              <select value={this.state.section} onChange={this.onSectionChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_section}
                </option>
                {this.state.sections.map(section =>
                  <option key={section.id} value={section.id}>
                    {section.label}
                  </option>
                )}
              </select>
              <select value={this.state.faculty} onChange={this.onFacultyChange}>
                <option value={''}>
                 {this.props.pagewiseLanguageData.select_faculty}
                </option>
                {this.state.faculties2.map(faculty =>
                  <option key={faculty.id} value={faculty.id}>
                    {faculty.user.name}
                  </option>
                )}
              </select>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.updateLecture}>{this.props.pagewiseLanguageData.update_session}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt={this.props.pagewiseLanguageData.delete}/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteLecture}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
					<div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
								<h1>{this.props.pagewiseLanguageData.sessions}</h1>
							</div>
						</div>
						<div className="heading-part2">
              {!this.state.is_faculty_admin && <select value={this.state.branch} onChange={this.branchChange}>
                {this.state.branches.map((branch, index) =>
                  <option value={branch.id} key={index}>
                    {branch.name}
                  </option>
                )}
              </select>}
              <span onClick={this.show2}>
								<img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
							</span>
						</div>
					</div>
          <div className="admin-calendar">
            <div className="cal-buttons">
              <div className="cal-header">
                <div className="header-op">
                  <div className="react-calendar-date" onClick={this.openCalendar}
                    ref={this.wrapperRef}>
                    {this.state.current_date}
                    <span>
                      <img src={Calendar2} alt="cal"/>
                    </span>
                  </div>
                  {this.state.is_recent?
                      <button onClick={this.switchToRecent}>
                          {this.props.pagewiseLanguageData.today}
                      </button>:""
                  }
                </div>
                <div className="header-op2">
                  <select value={this.state.filterClass} onChange={this.onClassChange2}>
                    <option value={""}>{this.props.pagewiseLanguageData.class}</option>
                    {this.state.filterClasses.map(classs =>
                      <option value={classs.id} key={classs.id}>
                        {classs.label}
                      </option>
                    )}
                  </select>
                  <select value={this.state.filterSection} onChange={this.onSectionChange2}>
                    <option value={""}>{this.props.pagewiseLanguageData.section}</option>
                    {this.state.filterSections.map(section =>
                      <option value={section.id} key={section.id}>
                        {section.label}
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>
				  </div>
          
          <div className="react-calendar1" Style={this.state.openc ? "display:block" 
            : "display:none"} ref={this.wrapperRef}>
            <Calendar
              onChange={this.onChange}
              value={value}
            />
          </div>
          
          
          {!this.props.loading && <div className="lecture-list-x2" onScroll={this.handleScroll}>
           { this.state.lectures && this.state.lectures.length <= 2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
            
            {this.state.lectures.length === 0 ? <Empty/> : null}
            <div className="list-content">
            {this.state.current_date_records.map((data,index)=>
              <div>
                { data.status==0 ? 
                  <div className="session-div"><div className="session-date">{data.month} {data.year}</div><div className="dash-test-no-found">{this.props.pagewiseLanguageData.no_lectures_planned}</div></div>:""}
              </div>
            )}

            
            {this.state.lectures.map(lecture => {
              return <div className="session-div" key={lecture.id}>
                <div className="session-date">
                  <span>{(new Date(lecture.start_date_time)).getDate()} {this.state.mlist[(new Date(lecture.start_date_time)).getMonth()]}</span>
                  {WeekTable[(new Date(lecture.start_date_time)).getDay()]} 
                </div>
                <div className="session-content" id={lecture.id==this.state.id?"conter":""}>
                  <div className="session-up">
                    <div>
                      {lecture.title}
                      <span>
                        {this.displayTime(new Date(lecture.start_date_time))} |&nbsp;
                        {lecture.duration_hrs} {lecture.duration_hrs === 1 ? 'Hr.' : 'Hrs.'}
                      </span>
                      {lecture.host_email &&
                      <div className='hostEmail'>{this.props.pagewiseLanguageData.host}:<span>{lecture.host_email}</span></div>
                      }
                    </div>
                    <div>
                      <div className="lecture_data_labels">
                        {lecture.classs.name} | {lecture.batch.name}
                      </div>
                      <div className="lecture_data_labels">
                        {lecture.subject.name} | {lecture.faculty.name}
                      </div>
                    </div>
                  </div>
                  <div id="session-band">
                    {lecture.mode === 2 ? this.props.pagewiseLanguageData.live : lecture.mode === 0 
                      ? this.props.pagewiseLanguageData.offline : this.props.pagewiseLanguageData.online}
                  </div>


                  <Link className="mark-attendance-button" to={{ pathname: '/admin/mark-attendance', state: { lecture: lecture } }}>
                    <img src={markAttendanceIcon}/>
                  </Link>

              

                  <span title="Mark Attendance" onClick={() => this.markAttendance(lecture)}
                    >
                    
                  </span>  
                                    
                  {lecture.mode === 1 ? <a target="__blank" href={lecture.url} 
                    id="live_class_location">
                      {this.props.pagewiseLanguageData.go_to_class}
                    </a> : lecture.mode === 2  && lecture.liveClass_server != 3 ? <>
                      <a target="__blank" href={lecture.url_lms} id="live_class_location">
                      {this.props.pagewiseLanguageData.go_to_class}
                      </a>
                    </> : lecture.mode === 2 && lecture.liveClass_server == 3 && !lecture.record_available ? <>
                      <a target="__blank" onClick={() => this.zoomAttendance(lecture.faculty_user_id, lecture.room, lecture.url,lecture.id)} id="live_class_location">
                      {this.props.pagewiseLanguageData.go_to_class}
                      </a>
                    </> : lecture.mode === 2  && lecture.liveClass_server == 3 && lecture.record_available && lecture.record_available 
                          !== undefined ? <>
                      <a target="__blank" href={lecture.record_url} id="live_class_location">
                        {this.props.pagewiseLanguageData.view_recording}
                      </a>
                    </> : <div id="live_class_location">
                      {this.props.pagewiseLanguageData.location}: {lecture?.url}
                    </div>}

                    

                  
                   
                  <span title="Edit" onClick={() => this.show3(lecture)}
                    className="edit-lecture-button" Style={(lecture.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"}>
                    <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                  </span>  
                  <span title="Delete" onClick={() => this.showd(lecture.id)}
                    className="edit-lecture-button2" Style={(lecture.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"}>
                    <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                  </span>  
                  <span title="Content" onClick={() => this.createContent(lecture.room)}
                    className="edit-lecture-button3" Style={(lecture.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"}>
                    <img src={Content} alt={this.props.pagewiseLanguageData.delete}/>
                  </span> 

                  <span title="get URL" onClick={() => this.getURL(lecture?.open_url)}
                    className="edit-lecture-button4" Style={(lecture.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"}>
                    <img src={WWW} alt={this.props.pagewiseLanguageData.delete}/>
                  </span> 
                  
                </div>
              </div>
            })}{ this.state.lectures && this.state.lectures.length>2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
          </div></div>}
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getClassList2, getSectionList2, getSubjectList2, getFacultyList, newLecture,
            getLectureList, getCenterList, editLecture, deleteLecture,encryption ,bbbJoinLiveClass,callFacultyzoomAttendanceAPI,facultyZoomCreate,getPageLanguageWiseData} = actions;
  return {
    encryption : (username) => dispatch(encryption(username)),
    getCenterList: () => dispatch(getCenterList()),
    getClassList2: () => dispatch(getClassList2()),
    bbbJoinLiveClass:(session,name,meetingPassword,userId) => dispatch(bbbJoinLiveClass(session,name,meetingPassword,userId)),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getSubjectList2: (center_id,class_id) => dispatch(getSubjectList2(center_id,class_id)),
    getFacultyList: (center_id,show_ext_dt) => dispatch(getFacultyList(center_id,show_ext_dt)),
    deleteLecture: (id) => dispatch(deleteLecture(id)),
    editLecture:(id, center, start_date_time, mode, title, duration_hrs, url, weblink, room, classs, subject, batch, faculty,is_webinar,record_url,group_type) => dispatch(editLecture(id, center, start_date_time, mode, title, duration_hrs, url, weblink, room, classs, subject, batch, faculty,is_webinar,record_url,group_type)),
    newLecture: (center, start_date_time, mode, title, duration_hrs, url, weblink, room, classs, subject, batch, faculty,is_webinar,liveclass_server,record_url,group_type) => dispatch(newLecture(center, start_date_time, mode, title, duration_hrs, url, weblink, room, classs, subject, batch, faculty,is_webinar,liveclass_server,record_url,group_type)),
    getLectureList: (date, days, direction, if_loading, center, classs, section,user_id,is_faculty) => dispatch(getLectureList(date, days, direction, if_loading, center, classs, section,user_id,is_faculty)),
    callFacultyzoomAttendanceAPI : (faculty_id,room) => dispatch(callFacultyzoomAttendanceAPI(faculty_id,room)),
    facultyZoomCreate : (lecture_id) => dispatch(facultyZoomCreate(lecture_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveSession);