import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import { isEmpty } from 'lodash';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import Empty from '../../components/error/Empty';
import queryString from 'query-string'
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Assignment from '../../public/icons/assignment.svg';
// import InfiniteScroll from "react-infinite-scroll-component";


class LearnerAssignmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tests: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      content_id:0,
      is_recent:0,
      showModal: false,
      current_date_records:[],
      record_last_date:null,
      activeDate:null,
      is_on_change:false,
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      getis_archive:''
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {

    localStorage.setItem("page","dpp")
    
    const searchId=queryString.parse(this.props.location.search);

    let getis_archive = this.props.location?.state?.archiveBatchID; 
    if(getis_archive){
      this.setState({getis_archive: getis_archive});
      localStorage.setItem('set_is_archive',true)
    }else{
      localStorage.setItem('set_is_archive',false)
    }
    console.log("getis_archive", getis_archive)
    let getCurrentClassId = this.props.location?.state?.currentClassid; 
    console.log("getCurrentClassId",getCurrentClassId);
    this.setState({getCurrentClassId:getCurrentClassId});
      


    if(searchId.id){
      var elmnt = document.getElementById(searchId.id);
      if(elmnt) document.getElementById(searchId.id).lastChild.style.border="";
    }
    document.addEventListener('mousedown', this.handleClickOutside);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let currentDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let year = parseInt(d.getFullYear())
    let month = parseInt(d.getMonth())
    let day = parseInt(d.getDate())

   this.setState({start_date:date,current_date:currentDate,activeDate: new Date(year,month,day)})
   this.getCurrentDate();
   //this.getStudentDPPList(date,15,'forward',false,this.state.getis_archive);
     setTimeout(() => { this.getStudentDPPList(date,15,'forward',false,this.state.getis_archive);}, 100);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false })
    }
  }

  showAttemptModal = () => this.setState({ showModal: true })

  closeAttemptModal = () => this.setState({ showModal: false })

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if(label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        {modal: true}
      )
    }
    else {
      this.setState({showAttemptModal: false})
      this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );
    }
  }

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if(label.toLowerCase() === 'review' || label.toLowerCase() === 'result') {
      this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        {modal: true}
      )
    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })
    }
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let year = parseInt(d.getFullYear())
    let month = parseInt(d.getMonth())
    let day = parseInt(d.getDate())

   this.setState({is_on_change:false,start_date:date,activeDate: new Date(year,month,day),is_recent:0,loading:true,countTop:0,tests:[]})

   this.getCurrentDate();
   this.getStudentDPPList(date,15,'forward',true,this.state.getis_archive);
  }

  getStudentDPPList = async (date,days,direction,if_loading) => {
    const response = await this.props.learnerAssignmentList(date,days,direction,if_loading,this.state.getis_archive);
    
    if(response){
      this.setState({tests:response.data?response.data:"", loading: false ,countBottom:1,content_id:response.data ? response.data[0]?.planner_id:"NA",record_last_date:response.data?response.data[0]?.scheduled_date :""})
      this.ifRecordsExists();
      if(!response.data?.length){

        this.setState({content_id:"NA"});
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getStudentDPPListOnBackward(currentDate,15,'backward',false,this.state.getis_archive);
      }

      const searchId=queryString.parse(this.props.location.search);

      if(searchId.id){
        var elmnt = document.getElementById(searchId.id);
        
        if(elmnt){
          elmnt.lastChild.style.border="3px solid #f1dfa1";
          elmnt.scrollIntoView();
        } 
      }
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getStudentDPPListOnForward = async (date,days,direction,if_loading) => {
    const response= await this.props.learnerAssignmentList(date,days,direction,if_loading,this.state.getis_archive);
    if(response){
      this.setState({
        tests: this.state.tests?this.state.tests.concat(response.data):response.data,
        loading: false,countBottom:1
      });
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getStudentDPPListOnBackward = async (date,days,direction,if_loading) => {
    const response= await this.props.learnerAssignmentList(date,days,direction,if_loading,this.state.getis_archive);
    if(response){
      this.setState({
        tests: response.data?response.data.concat(this.state.tests):this.state.tests,
        loading: false,countTop:1
      });
      //this.ifRecordsExists();
      let dashTest=document.getElementsByClassName("dash-test");
      if(dashTest && dashTest[0]) dashTest[0].style.border="";

      const searchId=queryString.parse(this.props.location.search);

      if(searchId.id){
        var elmnt = document.getElementById(searchId.id);
        
        if(elmnt){
          elmnt.lastChild.style.border="3px solid #f1dfa1";
          elmnt.scrollIntoView();
        } 
      }else{
        if(this.state.content_id)
        this.handleScrollPos();
      }
     
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date(this.state.current_date.split(' ').join('-'));

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      this.state.mlist[d.getMonth()]
    ];
    return date;
  }

  onChange = value => {
     var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');


    this.setState({is_on_change:true,hasMore: false,countTop:0,current_date:calendarDate,tests:[]});
    this.setState({open:false})
    
    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });

    this.setState({start_date:date,loading:false,is_recent:1})
    this.setState({ hasMore: false,countTop:0,current_date:calendarDate});
    
    this.getStudentDPPList(date,15,'forward',false,this.state.getis_archive);
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ current_date: date, date: currentDate});
  }


  openCalendar=()=>{
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();

    if(calendarMonth<currentMonth){
      document.getElementsByClassName("react-calendar__navigation__next-button")[0].click();
    }else if(calendarMonth>currentMonth){
      document.getElementsByClassName("react-calendar__navigation__prev-button")[0].click();
    }

    if(this.state.open===true)
      this.setState({open:false})
    if(this.state.open===false)
      this.setState({open:true})
  }

  handleScroll = (e) => { 
    e.preventDefault();
    var scrolltop=[];
    var count=0;
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('list-div-x2');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.tests && this.state.tests.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){

          this.setState({current_date:this.getTime(test.scheduled_date)[4]});
        }
      });
    }

    //document.getElementsByClassName("dash-test")[0].style.border="";
    const top = e.target.scrollTop===0;
    if(top){
      if(this.state.countTop===0){
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');
        //let currentDate = this.state.start_date;

        this.getStudentDPPListOnBackward(currentDate,15,'backward',false);
      }
    }
  }

  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) + this.props.saveLanguageWiseData.hrs:minutes + this.props.saveLanguageWiseData.mins;
  }

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  checkAssessment =(id) => {
    let ele=document.getElementById(id);
    if(ele) {
      ele.lastChild.style.border="";
    }
  }

   ifRecordsExists = () =>{

      var val=this.state.start_date;
      var d = new Date(val);
      var dval = new Date(this.state.record_last_date);

      let arr = [];
      const month=this.state.mlist;


      var d1 = new Date(month[d.getMonth()] +"-"+d.getFullYear());
      var d2 = new Date(month[dval.getMonth()] +"-"+dval.getFullYear());

      var months;
      var html;
      var temparray =[];

      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      console.log(months <= 0 ? 0 : months);

      for(let i=0; i<months;i++){
        
        html = month[d.getMonth()+i]+' '+d.getFullYear();
         //html += '<div><div class="dash-test-first">'+month[d.getMonth()+i]+' '+d.getFullYear()+' </div><div class="dash-test-no-found">No Assignment Planned</div></div>';
         //localStorage.setItem("record_date",scheduled_date)

         temparray.push(html)
      }

      this.setState({current_date_records:temparray})
  }

	render() {
    const { value,activeDate } = this.state.start_date;
    
		return (
      <>
        {!isEmpty(this.props.contentPreview) 
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
          onClose={this.closeAttemptModal}>
          <AssessmentPreview 
            contentPreview={this.props.contentPreview}
            attempt={this.attemptPaper}
            showAttemptButton={true}
          />
        </Popup>}
        <div className="app-body" id="appBody" >
          <ReactNotifications/>
          <div className={this.props.dashboard_type == 'dynamic' ? "app-div widget-app-div" : "app-div app-list-div"}>
            <Helmet>
              <title>Tests - Learner</title>
            </Helmet>
            <div className="topHeading">
            {this.state.getCurrentClassId !== undefined &&  
            <Link id="trace-back" 
              to={{ pathname: `/learner`,
              state: {currentClassId: this.state.getCurrentClassId}}}>
                <img src={BackArrow} alt="back with class id"/>
            </Link>
              } 

            {this.state.getCurrentClassId === undefined &&        
            //  <div id="trace-back" onClick={() => this.props.history.goBack()}>
            //     <img src={BackArrow} alt="back"/>
            //   </div>
              <Link id="trace-back" 
                to={{ pathname: `/learner`,
                state: {currentClassId: ''}}}>
                  <img src={BackArrow} alt="back"/>
              </Link>
              }  
            </div>
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
              <div className="page-header-test">
                <div className="react-calendar-date" onClick={this.openCalendar}
                  ref={this.wrapperRef}>
                  {this.state.current_date}
                  <img src={Calendar2} alt="cal"/>
                </div>
                {
                  this.state.is_recent?
                  <div className="back-to-recent" onClick={this.switchToRecent}>
                      {this.props.saveLanguageWiseData.today}
                  </div>:""
                }
              </div>
              
              {!this.state.loading && <div className="react-calendar1" Style={this.state.open?"display:block":"display:none"}
                ref={this.wrapperRef}>
                <Calendar
                  onChange={this.onChange}
                  value={value}
                  defaultActiveStartDate = {activeDate}
                />
              </div>}
              
              {!this.state.loading && <div className="list-sticky notwidgetheader" onScroll={this.handleScroll}>
              { this.state.tests && this.state.tests.length <= 2 ?<div id={this.state.content_id=="NA"?"conter":""}></div> :""}
                  
                {!this.state.tests.length && <Empty/>}
                  <div className="list-content">
                  {this.state.is_on_change && this.state.current_date_records.map((data,index)=>
                    <div> 
                        <div><div className="dash-test-first">{data}</div><div className="dash-test-no-found">No Assignments Planned</div></div>
                    </div>
                  )}

                  
                  {this.state.tests && this.state.tests.map((test,index) =>
                    <div className="list-div-x2" id={test.planner_id} ref={this.myRef}>
                      <div className="dash-test-first">
                        {index === 0 ? <>
                          <span>{this.checkAssessment(test.id)}{this.getTime(test.scheduled_date)[0]} {this.getTime(test.scheduled_date)[5]}</span>
                          <span>{this.getTime(test.scheduled_date)[1]}</span>
                        </> : this.checkDateTime(test.scheduled_date, 
                          this.state.tests[index - 1].scheduled_date) ? null : <>
                          <span>{this.getTime(test.scheduled_date)[0]} {this.getTime(test.scheduled_date)[5]}</span>
                          <span>{this.getTime(test.scheduled_date)[1]}</span>
                        </>}
                      </div>
                      <div className="dash-test" id={test.planner_id==this.state.content_id?"conter":""} key={index} 
                        Style={this.state.date==this.getTime(test.scheduled_date)[2] && this.getTime(test.scheduled_date)[3]?"background-color:#fff6e4":""}>
                        <div className="dash-flex2">
                          <div Style="display: inline-block;">
                            <span className="dash-flex2-title">{test.title}</span>
                            {test.is_faculty_reviewed == true && test.is_graded == true ? <span>
                              <span Style="color:#a9a0a0">{this.props.saveLanguageWiseData.score}</span> : <span Style="color:var(--primary)">{test?.score+"/"+test?.total_marks}</span>
                            </span>:""}
                          </div>
                          <div>
                            <span className="dash-flex2-img">
                              <img id="idea" src={Assignment} alt="idea"/>
                            </span>
                          </div>
                        </div>
                        <div className="faculty-dash-actions">
                          {test.action && test.action.map(action =>
                            <>{action.label != "Scheduled" ? <Link to={{pathname: `/learner/assignment-review/${test.id}` , state:{action:action.label,subject:test?.subject_name,id:test?.subject_id}}}>
                              <button>
                                {action.label}
                              </button>
                            </Link> : <span className="action-class">{action.label}</span>}</>
                          )}
                        </div>
                      </div>
                    </div> 
                  )}{ this.state.tests && this.state.tests.length>2 ?<div id={this.state.content_id=="NA"?"conter":""}></div> :""}
                  </div>
              </div>}

          </div>
        </div>
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    dashboard_type : state.commonReducer.dashboard_type,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const { learnerAssignmentList, getAttemptPreview } = actions;
  return {
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    learnerAssignmentList: (date,days,direction,if_loading,batch_id) => dispatch(learnerAssignmentList(date,days,direction,if_loading,batch_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerAssignmentList);