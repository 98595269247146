import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import Calendar2 from '../../public/icons/calendar.svg';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Listing from '../../public/icons/listing.svg';
import Calendar from '../../public/icons/calendar2.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import ImgHolder from '../../public/images/img-holder.svg';
import common from '../../common';
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Pagination from "react-js-pagination";
import MultiSelect from "react-multi-select-component";
import DownloadArrow from '../../public/icons/down-arrow.svg';
import sampleQuestionUpdateFile from '../../public/images/bulk_question_create.csv';

const alphabet = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N',
  15: 'O',
  16: 'P',
  17: 'Q',
  18: 'R',
  19: 'S',
  20: 'T',
  21: 'U',
  22: 'V',
  23: 'W',
}

const difficultyArray = {
  0: 'Easy',
  1: 'Medium',
  2: 'Hard',
  3: 'Very Hard'
}

class QuestionBank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      title:'',
      scheduleimage: null,
      scheduleimageURL:null,
      mode:'',
      question_schedule_date:'',
      question_due_date:'',
      type:'',
      showResult:false,
      classs:'',
      section:'',
      center:'',
			open: false,
      open2: false,
      open3: false,
      opend: false,
      class_id: null,
      question_id:true,
      filterClass: null,
      name: '',
      code: '',
      order: '',
      image: null,
      question_image:null,
      description: '',
      current_page:1,
      totalItems:0,
      branch: parseInt(localStorage.getItem('center_id')),
      branches: [],
      question_ids:[],
      questionsIds:[],
      center_id: localStorage.getItem('center_id'),
			list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      classListItems:[],
      centerListItems:[],
      sectionListItems:[],
      questions:[],
      classes2: [],
      subjects: [],
      sections: [],
      is_search:false,
      source: this.props.location.state?.source,
      assessment_section_id:this.props.location.state?.assessment_section_id,
      question:{},
      classesFilter: [],
      sectionClass:null,

      isBulkQuestionDialogVisible:false,
      bulkQuestionUpdateJobData:[],
      bulkQuestionUpdateTableShow:false,
      bulkQuestionUpdateJobStatus:false,
      bulkQuestionUpdateJobCounter:0,
      bulkQuestionUpdateJobStatusError:false,
    }
  }

  componentDidMount() {
    this.getQuestionsList(1);
    this.getCenterList();
    this.getClassList();
    //this.getSectionList();
    // setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }


  changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
  }

  show = () => {
    this.setState({ open: true, editq:false,center_id: localStorage.getItem('center_id'),
                              name: '', code: '', order: '', description: '',
                              branch: parseInt(this.state.center_id) });

    this.setState({ question: {
      id: "",
      statement: "",
      options: "",
      type: ""
    }, question_image:"",image:"",marks: '', negative_marks:'',
       difficulty: "",duration_seconds:""})
  }

  close = () => this.setState({ open: false });

  show2 = () => this.setState({ open2: true });

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  getQuestionsList = async (page) => {
    const response = await this.props.getQuestionsList(page);
    if(response !== undefined) {
      this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20});
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null ,question_image:null});
    document.getElementById('img-input').value = "";
  }

	changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  checkEnablity = () => {
    let condition1 = this.state.question.statement?.length > 0 || this.state.image;

    let condition2;

    if(this.state.question.marks?.length !=undefined && this.state.question.duration_seconds?.length != undefined){
       condition2 = (this.state.marks && this.state.marks.length >= 0) && (this.state.duration_seconds && this.state.duration_seconds.length >= 0) && this.state.difficulty !== '';
    }else{
      if(this.state.marks !=="" && this.state.duration_seconds !==""){
        condition2 = true;
      }
    }

    let condition3 = this.checkOptions();

    return condition1 && condition2 && condition3;
  }

  checkEnablity2 = () => {
    let condition1 = this.state.question.statemen?.length > 0 || this.state.image;
    let condition2;
    if(this.state.marks?.length !=undefined){
       condition2 = (this.state.marks && this.state.marks?.length >= 0) && this.state.difficulty !== '';

    }else{

      if(this.state.marks !=="" && this.state.negative_marks !==""){
        condition2 = true;
      }
    }

    let condition3 = this.checkOptions();
    return condition1 && condition2 && condition3;
  }

  checkOptions() {
    if(this.state.question.type === 6) {
      return true;
    }
    else {
      let con1 = false;
      let con2 = true;
      let options = this.state.question?.options;
      for(let i = 0; i < options?.length; i++) {
        if(options[i].is_correct === true) {
          con1 = true;
        }
        if(isEmpty(options[i].statements[0].statement)) {
          con2 = false;
        }
      }
      return con1 && con2 && options.length > 0;
    }
  }

  onTypeChange = (e) => {
    var question = this.state.question;
    for(var i=0; i < question.options?.length ; i++) {
      question.options[i].is_correct = false;
      question.options[i].statements[0].statement = '';
    }
    question.type = parseInt(e.target.value);
    if(parseInt(e.target.value) === 5) {
      question.options = [
        {
          is_correct: false,
          statements: [{
            language: 1,
            explanation: '',
            statement: 'True',
          }]
        },
        {
          is_correct: false,
          statements: [{
            language: 1,
            explanation: '',
            statement: 'False',
          }]
        }
      ]
    }else{
      question = {
        type: 1,
        statement: '',
        options: []
      }
    }
    this.setState({ question });
  }

  onStatementChange = (e) => {
    var question = this.state.question;
    question.statement = e.target.value;
    this.setState({ question });
  }

  onChangeTime = (e) => {
    var duration_seconds = this.state.duration_seconds;

    duration_seconds = (e.target.validity.valid) ? e.target.value : this.state.duration_seconds;

    this.setState({ duration_seconds });
  }

  positiveChange = (e) => {
    var marks = this.state.marks;
    marks = (e.target.validity.valid) ? e.target.value : this.state.marks;
    this.setState({ marks });
  }

  negativeChange = (e) => {
    var negative_marks = this.state.negative_marks;
    negative_marks = (e.target.validity.valid) ? e.target.value : this.state.negative_marks;
    this.setState({ negative_marks });
  }

  updateSection = async () => {
    const { section_id, section_title, negative_marking_per_q, instructions } = this.state;
    await this.props.updateAssessmentSection(section_id, section_title, negative_marking_per_q, instructions);
  }

  addMarksPlus =() =>{
    var positive_marks = this.state.marks;
    if(positive_marks == "") positive_marks=0;
    positive_marks = parseInt(positive_marks) + 1;
    this.setState({ marks:positive_marks });
  }

  addMarksMinus =() =>{
    var positive_marks = this.state.marks;
    if(parseInt(positive_marks)>0){
      positive_marks = parseInt(positive_marks) - 1;
      this.setState({ marks:positive_marks });
    }
  }

  addNegativeMarks = () => {
    var negative_marks = this.state.negative_marks;
    if(negative_marks == "") negative_marks=0;
    negative_marks = parseInt(negative_marks) + 1;
    this.setState({ negative_marks});
  }

  addNegativeMarksm =() =>{
    var negative_marks = this.state.negative_marks;
    if(parseInt(negative_marks)>0){
      negative_marks = parseInt(negative_marks) - 1;
      this.setState({ negative_marks });
    }
  }

  minusTime = () => {
    var duration_seconds = this.state.duration_seconds;
    if(parseInt(this.state.duration_seconds) >= 5) {
      duration_seconds = parseInt(duration_seconds) - 5;
      this.setState({ duration_seconds });
    }
  }

  addTime = () => {
    var duration_seconds = this.state.duration_seconds;
    if(duration_seconds == "") duration_seconds=0;
    duration_seconds = parseInt(duration_seconds) + 5;
    this.setState({ duration_seconds });
  }

  addMarks =() =>{
    var positive_marks = this.state.marks;
    positive_marks = parseInt(positive_marks) + 1;
    this.setState({ marks:positive_marks });
  }

  difficultyChange = (e) => {
    this.setState({ difficulty: e.target.value });
  }

  addOption = () => {
    if(this.state.question.options.length < 24) {
      var question = this.state.question;
      if(this.state.question.options.length < 20) {
        var option = {
          is_correct: false,
          statements: [{
            image:'',
            language: 1,
            explanation: '',
            statement: '',
          }],
          image:''
        }
        question.options.push(option);
        this.setState({ question })
      }
    }
  }

  checkTimeTyed = async (val,sectionId,question) => {
    console.log(question) ;

    let duration_seconds = 0;
    let duration_min = 0;

    this.state.sections.map((section,index1) => {
      section.questions.map((data,index) => {
        duration_seconds += data.question_data.duration_seconds;
      })
    })

    if(parseInt(this.state.duration_seconds) && val === 1) duration_seconds +=parseInt(this.state.duration_seconds);

    if(this.state.assessment.timed_type === 2){
      duration_min = Math.floor(duration_seconds / 60);
      //await this.props.editAssessmentBasic(this.state.assessment.id,this.state.assessment.uid, this.state.assessment.title, localStorage.getItem("center_id"), this.state.assessment.timed_type, duration_min);
    }
  }


  createQuestion = async () => {

    const { id, question, marks, negative_marks, difficulty, duration_seconds } = this.state;

    //if(this.state.assessment.is_graded === true ) this.canCreate(parseInt(marks),1)
    //this.checkTimeTyed(1,this.state.id,question.id);

    if(question.type === 6) {
      await this.props.createQuestion(id, question.type, [{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],
                          [], parseInt(marks), negative_marks ? parseInt(negative_marks) :0,
                         difficulty, parseInt(duration_seconds));
    }
    else {
      await this.props.createQuestion(id, question.type, [{language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],
                          question.options, parseInt(marks), negative_marks ? parseInt(negative_marks) :0,
                         difficulty, parseInt(duration_seconds));
    }
  }

  keyDown = (e, index) => {
    var question = this.state.question;
    var key = e.keyCode || e.charCode;
    if(this.state.question.type === 2) {
      if(key === 8 || key === 46) {
        question.options[index].statements[0].statement = '';
        this.setState({ question });
      }
    }
  }

  removeOption = (index) => {

    var question = this.state.question;
    if(this.state.question.options.length > 0) {
      question.options.splice(index, 1);
      this.setState({ question });
    }
  }

  show6 = async (e) => {
    clearInterval(this.timer)
    //if(!e.target.value) document.getElementsByClassName("tab-content")[0].style.display="none";
    this.setState({text:e.target.value})

    let textValue = e.target.value

    this.timer = setTimeout(async () => {
      if(textValue){
        const response = await this.props.questionSearch(textValue,1);
        if(response !== undefined) {
          this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,is_search:true});
        }
      }else{
        const response = await this.props.getQuestionsList(1);
        if(response !== undefined) {
          this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,is_search:false});
        }
      }
    },1000);
  }


  isCorrect = (index) => {
    var question = this.state.question;
    console.log(question)
    if(this.state.question.type !== 3) {
      for(var i=0; i < question.options.length ; i++) {
        question.options[i].is_correct = false;
      }
    }
    question.options[index].is_correct = !question.options[index].is_correct;

    console.log(question)

    this.setState({ question });
  }

  handleChange = async (event) => {

    if(event.target.files[0].type.split('/')[0]) {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })

      const response= await this.props.updateImage(event.target.files[0]);
      this.setState({image:response.data.image});
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }
  handleChange1 = async (event,index) => {
    var question = this.state.question;
    console.log(event.target.files)
    if(event.target.files[0].type.split('/')[0] === 'image') {
      console.log("445354543")
      this.setState({
        image1: event.target.files[0],
        imageURL1: URL.createObjectURL(event.target.files[0])
      })

      const response= await this.props.updateImage(event.target.files[0]);

      this.setState({option_image:response.data.image});
      question.options[index].statements[0].image = (response.data.image)
        ? response.data.image : question.options[index].statements[0].image;
      this.setState({ question });
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  show4 = async(page) => {
    this.setState({ values:[] });

    if(this.state.is_search){
      const response = await this.props.questionSearch(this.state.text,page);
      if(response !== undefined) {
        this.setState({ questions: response.data ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,is_search:true});
      }
    }else{
      const response = await this.props.getQuestionsList(page);
      if(response !== undefined) {
        this.setState({ questions: response.data });
      }
    }

    this.setState({current_page:page});
  }

  moveOn = (page) =>{
    this.show4(page);
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.show4(pageNumber);
    this.setState({current_page: pageNumber});
  }

  onEditQuestion = async (val,index) => {

    if(!this.state.is_search){
      this.setState({editq: true,open: true, titleq: `Question ${index}` });
      this.setState({ question: {
      id: val.id,
      statement: val.statement,
      options: val.options,
      type: val.question_type_id
    }, question_image:val.image,image:val.image,marks: val.marks + '', negative_marks: val.negative_marking + '',
       difficulty: val.difficulty_id,duration_seconds:val.duration})
    }else{

      const response = await this.props.editQuestionBank(val._id)

      if(response != undefined){
        this.setState({editq: true,open: true, titleq: `Question ${index}` });
        this.setState({ question: {
        id:val._id,
        statement: response.data[0].statement,
        options: response.data[0].options,
        type: response.data[0].question_type_id
        }, question_image:response.data[0].image,image:response.data[0].image,marks: response.data[0].marks + '', negative_marks: response.data[0].negative_marking + '',
         difficulty: response.data[0].difficulty_id,duration_seconds:response.data[0].duration})

      }
    }
  }

  closeQuestionBox = (val) => {
    /*var question_ob = this.state.question_ob;
    if(this.state.question_ob[val] === true) {
      question_ob[val] = false;
      this.setState({ question_ob: question_ob, editing: false })
    }*/

    this.setState({open : false})
  }

  schedule =(id) =>{
    this.setState({open3 : true,question_id:id})
  }

  close3 =() =>{
    this.setState({open3 : false })
  }

  getClassList = async () => {
    const {classListItems} = this.state;
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ),
        classes2: response.data.filter(classs => classs.center === parseInt(this.state.center_id)),
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });

      for(let i =0; i<response.data.length; i++){
        classListItems.push({value:response.data[i].id, checked:false,label:response.data[i].label})
      }
    }
  }

  getSectionList = async (classs) => {
    const {sectionListItems} =this.state
    if(classs === '') {
      this.setState({ sections: [] });
    }
    else {
      const response = await this.props.getSectionList2(this.state.branch, classs);
      if(response !== undefined) {
        this.setState({ sections: response.data });
      }

      for(let i =0; i<response.data.length; i++){
        sectionListItems.push({value:response.data[i].id, checked:false,label:response.data[i].label})
      }
    }
  }

  getCenterList = async () => {
    const {centerListItems} = this.state;
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
      for(let i =0; i<response.data.length; i++){
        centerListItems.push({value:response.data[i].id, checked:false,label:response.data[i].name})
      }
    }
  }

  titleChange = (e) => {
    this.setState({title: e.target.value})
  }

  changeScheduleImage = async (event) => {

    if(event.target.files[0].type.split('/')[0]) {
      this.setState({
        scheduleimage: event.target.files[0],
        scheduleimageURL: URL.createObjectURL(event.target.files[0])
      })

      //const response= await this.props.updateImage(event.target.files[0]);
      //this.setState({image:response.data.image});
    }
    else {
      this.setState({ scheduleimage: null, scheduleimageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  reset = () =>{
    let {question_ids} = this.state

    question_ids.length = 0
    this.setState({question_ids})

    console.log(question_ids)
  }

  typeChange =(e) =>{
    this.setState({type:e.target.value})
  }

  modeChange = (e) =>{
    //console.log(this.state.class_centers)
    this.setState({mode:e.target.value})
  }

  showResult = (e) => {
    this.setState({showResult: !this.state.showResult})
  }

  onCenterChange =(e) =>{
    const {branches} = this.state
    const findIndex = branches.findIndex( p => parseInt(p.id) === parseInt(e.target.value) )

    if(branches[findIndex]?.checked === true){
      branches[findIndex].checked = false;
    }else{
      branches[findIndex].checked = true;
    }
    this.setState({branches,center:e.target.value})
  }

  scheduleQuestion = async () => {
    let dateObj= new Date();
    let selected;
    let selectedCenter=[];

    const {question_id,question_schedule_date,question_due_date,title,mode,type,showResult,scheduleimage,center,classs,section,branch} = this.state


    selected = mode == "institute" ? branch : mode == "class" ? classs.map(val => val.value) : mode == "section" ? section.map(val => val.value) :"";

    const response = await this.props.qodCreate(question_id,question_schedule_date,question_due_date,title,mode,type,showResult,dateObj.toISOString(),scheduleimage,selected)

    this.setState({open3: false})
  }

  onChangeTime1 = (e) => {
    this.setState({question_schedule_date : e.target.value})
  }
  onChangeTime2 = (e) => {
    this.setState({question_due_date : e.target.value})
  }

  onClassChange = (e) => {
    this.setState({sectionClass :e.target.value })
    this.getSectionList(e.target.value);
  }

  optionChange = (e, index) => {
    var question = this.state.question;
    if(this.state.question.type === 5) {
      e.preventDefault();
    }
    else {
      if(this.state.question.type === 2) {
        if(question.options[index].statements[0].statement.length < 2) {
          if(question.options[index].statements[0].statement.length === 1) {
            if(question.options[index].statements[0].statement === '-') {
              if(e.target.value === '--') {
                e.preventDefault();
              }
              else {
                question.options[index].statements[0].statement = (e.target.validity.valid)
                  ? e.target.value : question.options[index].statements[0].statement;
                question.options[index].statements[0].image = (this.state.option_image)
                  ? this.state.option_image : question.options[index].statements[0].image;
                this.setState({ question });
              }
            }
            else {
              e.preventDefault();
            }
          }
          else {
            question.options[index].statements[0].statement = (e.target.validity.valid)
              ? e.target.value : question.options[index].statements[0].statement;
            question.options[index].statements[0].image = (this.state.option_image)
              ? this.state.option_image : question.options[index].statements[0].image ;
            this.setState({ question });
          }
        }
        else {
          e.preventDefault();
        }
      }
      else {
        question.options[index].statements[0].statement = (e.target.validity.valid)
          ? e.target.value : question.options[index].statements[0].statement;
        question.options[index].image = (this.state.option_image)
          ? this.state.option_image : question.options[index].statements[0].image ;
        this.setState({ question });
      }
    }
  }

  fileInput2 = (e,index) => {
    document.getElementById("img-input"+index).click();
  }

  editQuestion = async () => {
    const { id,question, marks, negative_marks, difficulty, duration_seconds } = this.state;
    //if(this.state.assessment.is_graded === true ) this.canCreate(parseInt(marks),2)
    //this.checkTimeTyed(2,this.state.id,question.id);

    if(question.type === 6) {
      await this.props.editQuestion(id,question.id, parseInt(marks), negative_marks ? parseInt(negative_marks) :0,
                          difficulty, parseInt(duration_seconds),question.type,question.options,[{id:question.id,language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],true);
    }
    else {
      await this.props.editQuestion(id,question.id, parseInt(marks), negative_marks ? parseInt(negative_marks) :0,
                          difficulty, parseInt(duration_seconds),question.type,question.options,[{id:question.id,language: 1, statement: question.statement, explanation: this.state.explanation,image:this.state.image}],true);
    }
  }

  changeIds = (val) => {

    let {question_ids} = this.state

    if(question_ids.indexOf(val) !== -1) {
      const index = question_ids.indexOf(val);
      question_ids.splice(index, 1);
      this.setState({question_ids})
    }
    else {
      question_ids.push(val);
      this.setState({question_ids})
    }

    console.log(question_ids)
  }


  mapSection = async () => {
    let questionsIds =[] ;
    let {question_ids} = this.state

    console.log(question_ids)
    for(let i=0 ; i<question_ids.length; i++){
      questionsIds.push({'question_id':question_ids[i]})
    }

    await this.props.questionSectionMapping(this.state.assessment_section_id,questionsIds)

    this.props.history.goBack()
  }

  removeImage1 = (index) => {
    var question = this.state.question;
    this.setState({ image1: null, imageURL1: null ,option_image:null});
    question.options[index].statements[0].image='';
    this.setState({ question });
    document.getElementById('img-input'+index).value = "";
  }
  
  bulkQuestionUpload = (id) => {
    this.setState({saveBatchId: id});
    this.setState({isBulkQuestionDialogVisible: true});
  }

  bulkQuestionClose = () => {
    this.setState({isBulkQuestionDialogVisible: false});
  }

  bulkQuestionFileClick = (event) => {
    document.getElementById("bulkQuestionUpdate").click();
  }

  bulkQuestionFileChange =  async (event) => {
    console.log(event.target.files[0].type.split('/')[0]);
    let getFile =  event.target.files[0];
    let getFileURL =  URL.createObjectURL(getFile);
    console.log(getFile);
    console.log(getFileURL);
    const response = await this.props.bulkQuestionUpdate(getFile,this.state.saveBatchId); 
    if(response !== undefined) {
      console.log(response.data);
      this.setState({bulkQuestionUpdateJobData: response.data.job_id});
      this.setState({bulkQuestionUpdateTableShow:true});
      this.intervalFunction2(response.data.job_id);
    }
  }

  intervalFunction2 = async (jobid) =>{
    return await new Promise(resolve => {
      const interval =  setInterval(() => {
        console.log('Logs every minute');
        this.bulkBatchUpdateFileCheckStatus(jobid);
        if(this.state.bulkQuestionUpdateJobStatus){
          console.log(this.state.bulkQuestionUpdateJobStatus);
          clearInterval(interval);
        }
      }, 10000);
    })  
  }
  
  
  bulkBatchUpdateFileCheckStatus =  async (jobid) => {
      if(!this.state.bulkQuestionUpdateJobStatus){
        const response  =  await this.props.bulkQuestionJobID(jobid);
        if(response !== undefined) {
          console.log('response Batch Update');
          console.log(response);
          this.setState({bulkQuestionUpdateJobStatus:true});
       }else{
          console.log("response?.status Batch update")
            this.setState({bulkQuestionUpdateJobCounter:this.state.bulkQuestionUpdateJobCounter+1});
            if(this.state.bulkQuestionUpdateJobCounter == 5){
              this.setState({bulkQuestionUpdateJobStatus:true});
              this.setState({bulkQuestionUpdateJobStatusError:true});
              setTimeout(window.location.reload.bind(window.location), 1000);
            }
          }
       
      }
     }

     decodeHTMLEntities = (questionvalue) =>{
      var textArea = document.createElement('textarea');
        textArea.innerHTML = questionvalue;
        return textArea.value;
    }
  

     decodeHTMLEntitiesInput = (questionvalue) =>{
      let orignalValue = questionvalue;
      let finalValue  =  orignalValue.replace(/&#44;/g, ",");
      return finalValue;
    }

	render() {

    const {center,classs,section} =this.state
    const isEnabled = this.state.title.length > 0 && this.state.question_schedule_date && this.state.question_due_date && this.state.mode;

    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search classes"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>Questions</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.questions}</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder={this.props.pagewiseLanguageData.search_classes}/>

          {/* Bulk Question Update Dialog Start*/}
      <div className={this.state.isBulkQuestionDialogVisible? 'bulkUserUploadDialog open' : 'bulkUserUploadDialog'}>
          <div className='bulkUserUploadContainer'>
              <span className="close" onClick={this.bulkQuestionClose}>✕</span>
              <div className='popup-header'>{this.props.pagewiseLanguageData.bulk_question_create}</div>
              <div className='actions'>
                <a href={sampleQuestionUpdateFile} download><img src={DownloadArrow}/><i>{this.props.pagewiseLanguageData.download_sample_file}</i></a>
                <input type="file" onChange={this.bulkQuestionFileChange}  id='bulkQuestionUpdate' accept=".csv" style={{ display: 'none' }} />  
                <span onClick={this.bulkQuestionFileClick} ><img src={DownloadArrow}/><i>{this.props.pagewiseLanguageData.upload_file}</i></span>  
              </div>
              <div className={this.state.bulkQuestionUpdateTableShow ? 'tableWrapper display' : 'tableWrapper'}>
                <table>
                <thead>
                   <tr>
                     <th>{this.props.pagewiseLanguageData.job_id}</th>
                     <th>{this.props.pagewiseLanguageData.status}</th>
                   </tr>
                   </thead>
                   <tbody>
                   <tr>
                     <td>{this.state.bulkQuestionUpdateJobData}</td>
                     <td>{this.state.bulkQuestionUpdateJobStatus ? this.props.pagewiseLanguageData.success : this.props.pagewiseLanguageData.processing}</td>
                   </tr>
                  </tbody>
                </table>
              </div>
              {this.state.bulkQuestionUpdateJobStatusError &&
              <div className='somthingWenWrongMsg'>{this.props.pagewiseLanguageData.invalid_csv_Format}</div>
              }
          </div>
        </div>
        {/* Bulk Question Update Dialog Ends*/}  


        <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="popup-content4">
          <div className="popup-header2">
            {this.props.pagewiseLanguageData.schedule_question}
          </div>
          <span className="close" onClick={this.close3}>&#x2715;</span>
          <div className="popup-data4">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.title} onChange={this.titleChange}
                value={this.state.title}/>
              <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
              onChange={this.changeScheduleImage} ref={fileInput2 => this.fileInput2 = fileInput2}/>
              {this.state.scheduleimage === '' || this.state.scheduleimage === null
              ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput2.click()}/>
              : <div className="schedule-img" onClick={() => this.fileInput2.click()}
              style={{backgroundImage: "url("+common.addFileUrl(this.state.scheduleimageURL)+")"}}></div>}
            </div>

            <div className="popupx-dropdowns">
              <select value={this.state.mode} onChange={this.modeChange}>
                <option value="">
                 {this.props.pagewiseLanguageData.select_level}
                </option>
                <option value="institute">
                 {this.props.pagewiseLanguageData.institute}
                </option>
                <option value="class">
                  {this.props.pagewiseLanguageData.class}
                </option>
                <option value="section">
                {this.props.pagewiseLanguageData.section}
                </option>
              </select>

              {/*this.state.mode == "center" && <MultiSelect
                options={this.state.centerListItems}
                value={center}
                onChange={center => this.setState({center})}
                labelledBy={"Select"}
              />*/}
              {this.state.mode == "class" && <MultiSelect
                options={this.state.classListItems}
                value={classs}
                onChange={classs => this.setState({classs})}
                labelledBy={"Select"}
              />}
              {this.state.mode == "section" &&
              <><select value={this.state.sectionClass} onChange={this.onClassChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_class}
                </option>
                {this.state.classes2.map(classs =>
                  <option key={classs.id} value={classs.id}>
                    {classs.label}
                  </option>
                )}
              </select>
              <MultiSelect
                options={this.state.sectionListItems}
                value={section}
                onChange={section => this.setState({section})}
                labelledBy={"Select"}
              /></>}
            </div>
          <div className="popupx-inputs">
            <input placeholder={this.props.pagewiseLanguageData.type} onChange={this.typeChange}
              value={this.state.type}/>
            <input type="checkbox"  checked={this.state.showResult === true} onChange={this.showResult}/> {this.props.pagewiseLanguageData.show_result}
          </div>
          <div className="popupx3-inputs">
            <div>
              <span>{this.props.pagewiseLanguageData.schedule_date} :</span>
              <input type="datetime-local" placeholder="Scheduled Date & Time"
              onChange={this.onChangeTime1} value={this.state.question_schedule_date}/>
            </div>

            <div>
              <span>{this.props.pagewiseLanguageData.due_date} :</span>
              <input type="datetime-local" placeholder="due Date & Time"
              onChange={this.onChangeTime2} value={this.state.question_due_date}/>
            </div>
          </div>
        </div>
        <div className="popup-actions">
          <button disabled={!isEnabled} onClick={this.scheduleQuestion}>{this.props.pagewiseLanguageData.schedule}</button>
        </div>

        </Popup>

        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="question-bank-dialog popup-content">
          <div className="popup-header">
            {this.state.editq ? this.props.pagewiseLanguageData.edit_question :this.props.pagewiseLanguageData.add_new_question}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="dialog-content">
            {/* Creating question */}
            <div className="subunit-data2">
                  <div className="edit-question">
                    {!this.state.editing && <div className="edit-ques-head">
                      {this.state.editq ? <span>{this.state.titleq}</span>
                        : <span>{this.props.pagewiseLanguageData.question} </span>}
                      {!this.state.editq && <select value={this.state.question?.type}
                        onChange={this.onTypeChange}>
                        <option value={''} disabled>{this.props.pagewiseLanguageData.question_type}</option>
                        <option value={1}>{this.props.pagewiseLanguageData.mcq}</option>
                        <option value={5}>{this.props.pagewiseLanguageData.true_false}</option>
                        {/*<option value={3}>Multiple Response</option>
                        <option value={2}>Single Integer</option>
                        <option value={4}>Fill in the blanks</option>
                        <option value={6}>Subjective</option>
                        <option value={7}>Matching</option>*/}
                      </select>}
                    </div>}
                    {this.state.editing && <div className="edit-ques-head"><span>{this.state.titleq}</span></div>}
                    <div className="ques-statement ddddd">
                      <textarea rows={3} placeholder={this.props.pagewiseLanguageData.question_statement} value={this.decodeHTMLEntities(this.state.question?.statement)}
                        onChange={this.onStatementChange}/>
                    </div>

                    {(this.state.image === '' || this.state.image === null) && (this.state.question_image === null || this.state.question_image === '') && this.state.question?.type !== 6 ?<div className="img-prev-statement ques-statement">
                      <span>{this.props.pagewiseLanguageData.upload} {this.state.question.type === 6 ? this.props.pagewiseLanguageData.file:this.props.pagewiseLanguageData.image} : </span><div onClick={() => this.fileInput.click()}><img src={ImgHolder} alt="Prev"/></div>
                      </div>:
                      <div className="img-prev-statement ques-statement" Style={this.state.question?.type === 6 ?"display:none":""}>
                        <span onClick={this.removeImage}>&#x2715;</span>
                        <img src={this.state.question_image ? common.addFileUrl(this.state.question_image) : common.addFileUrl(this.state.imageURL)} alt="Prev"/>
                      </div>
                    }

                    {this.state.question?.type === 6 ? <div className="input_field_file" Style="display:block">
                       <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/pdf" type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                    </div>:<div className="input_field" Style="display:none">
                       <input accept="image/*" type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                    </div>}


                    <div className="ques-sett-ops">
                      <div className="asm-ques-settings">
                        {/*<span onClick={this.addMarksMinus}>&#45;</span>*/}
                        <div>{this.props.pagewiseLanguageData.positive_marks}<input type="text" pattern="[0-9]*" defaultValue={this.state.marks}
                          onInput={this.positiveChange}/>
                        </div>
                        {/*<span onClick={this.addMarksPlus}>&#43;</span>*/}
                      </div>
                      {this.state.question?.type != 6 && <div className="asm-ques-settings">
                        {/*<span onClick={this.addNegativeMarksm}>&#45;</span>*/}
                        <div>{this.props.pagewiseLanguageData.negative_marks}<input type="text" pattern="[0-9]*" value={this.state.negative_marks}
                          onInput={this.negativeChange}/>
                        </div>
                        {/*<span onClick={this.addNegativeMarks}>&#43;</span>*/}
                      </div>}
                      <div className="asm-setting-duration">
                        {/*<span onClick={this.minusTime}>&#45;</span>*/}
                        <div>{this.props.pagewiseLanguageData.secs}<input type="text" pattern="[0-9]*" value={this.state.duration_seconds}
                          onInput={this.onChangeTime}/></div>
                        {/*<span onClick={this.addTime}>&#43;</span>*/}
                      </div>

                    </div>
                    <div className="ques-sett-ops" id="ques-sett-ops2">
                      <select value={this.state.difficulty} onChange={this.difficultyChange} disabled={this.state.uid?"disabled":""}>
                        <option value={''}>
                          {this.props.pagewiseLanguageData.select_difficulty}
                        </option>
                        <option value={0}>
                        {this.props.pagewiseLanguageData.easy}
                        </option>
                        <option value={1}>
                        {this.props.pagewiseLanguageData.medium}
                        </option>
                        <option value={2}>
                        {this.props.pagewiseLanguageData.hard}
                        </option>
                        <option value={3}>
                        {this.props.pagewiseLanguageData.very_hard}
                        </option>
                      </select>


                      {this.state.uid ?<div className="ques-sett-ops-uid">
                        <div><input type="text" value={this.state.uid}
                          disabled="disabled" placeholder="Unique Code"/></div>
                      </div>:""}
                      {!this.state.editing && <span id="ques-explanation-add" onClick={this.show2}>
                        {this.props.pagewiseLanguageData.add_solution}
                      </span>}
                    </div>


                      {this.state.question?.type === 6 ? null
                      : <div className="ques-options">
                        {this.state.question.type === 5
                          ? <span>{this.props.pagewiseLanguageData.select_the_correct_answer}</span>
                          : <>{this.state.question.type && <span>{this.props.pagewiseLanguageData.add_options_and_select_correct_answer}</span>}</>}
                        {this.state.question?.options && this.state.question?.options.map((option, index) =>
                          <div className="ques-option" key={index}>
                            <span onClick={() => this.isCorrect(index)}
                              id={option.is_correct === true ? "is_correct" : ""}>
                              {alphabet[index + 1]}
                            </span>
                            <input value={this.decodeHTMLEntitiesInput(option?.statements[0]?.statement)} placeholder={`Option ${index + 1}`}
                              onInput={(e) => this.optionChange(e, index)} onKeyDown={(e) => this.keyDown(e, index)}
                              pattern={this.state.question?.type === 2 ? "[0-9-]*" : null}/>
                            {this.state.question?.type !== 5
                              ? <> {!this.state.editing && <span onClick={() => this.removeOption(index)} id="remove-opt">
                              &#10005;
                            </span>} </>: null}
                            { this.state.question?.type !== 5
                              ? <div className="img-prev-statement">{this.state.question?.options[index]?.statements[0]?.image && <div onClick={() => this.removeImage1(index)}>&#x2715;</div>}<div onClick={(e) => this.fileInput2(e,index)}><img src={this.state.question.options[index]?.statements[0]?.image ?common.addFileUrl(this.state.question.options[index]?.statements[0]?.image ) : ImgHolder} alt="Prev"/></div>
                                </div>: null }
                            <div className="input_field" Style="display:none">
                             <input accept="image/*" type="file" id={"img-input"+index} onChange={(e) => this.handleChange1(e, index)} ref={fileInput1 => this.fileInput1 = fileInput1}/>
                            </div>
                          </div>
                        )}
                        {this.state.question?.type === 5 ? null
                          : <> {!this.state.editing && this.state.question.type && <button onClick={this.addOption}>{this.props.pagewiseLanguageData.add_option}</button> } </>}
                      </div>}
                    {this.state.editing}
                    {this.state.editing && <div className="ques-save">
                      {this.state.editing && <span id="ques-explanation" onClick={this.show2}>
                        {this.props.pagewiseLanguageData.edit_solution}
                      </span>}
                      <div>
                        <button onClick={() => this.closeQuestionBox(1)}
                          id="button-reverse">
                          {this.props.pagewiseLanguageData.cancel}
                        </button>
                        {this.state.editq ? <button onClick={this.editQuestion}
                          disabled={!this.checkEnablity2()}>
                          {this.props.pagewiseLanguageData.save}
                        </button> : <button onClick={this.createQuestion}
                          disabled={!this.checkEnablity()}>
                          {this.props.pagewiseLanguageData.save}
                        </button>}
                      </div>
                    </div>}
                    {!this.state.editing && <div className="ques-save-noedit">
                      <div>

                      </div>
                    </div>}
                  </div>
                </div>
            {/* question creation ends */}
          </div>
          <div className="popup-actions">
              <button onClick={() => this.closeQuestionBox(1)}
                id="button-reverse" Style="margin-right: 9px;">
                {this.props.pagewiseLanguageData.cancel}
              </button>
             {this.state.editq ? <button onClick={this.editQuestion}
                disabled={!this.checkEnablity2()}>
                {this.state.editq ? this.props.pagewiseLanguageData.update :this.props.pagewiseLanguageData.add_new}
              </button> : <button onClick={this.createQuestion}
                disabled={!this.checkEnablity()}>
                {this.state.editq ? this.props.pagewiseLanguageData.update :this.props.pagewiseLanguageData.add_new}
              </button>}
            {/*<button disabled={!isEnabled} onClick={this.newClass}>Add new</button>*/}
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.solution_of_question}</span>
            <textarea value={this.state.explanation} onChange={this.onExplanationChange}
              placeholder="Enter solution"/>
            <div>
              <button onClick={this.close2}>
                {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteClass}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
					<div className="sub-head">
						<div className="heading-part1-ques">
              <div className="search-head">
                {/*<img src={Search} alt="search"/>
                <input placeholder={this.props.placeholder}/>*/}
                <div className="search-wrapper-admin">
                  <div className="search-container">
                    <input type="search" placeholder={this.props.pagewiseLanguageData.search_questions} onChange = {this.show6} onFocus={this.handleUsernameFocus}/>
                  </div>
                </div>
              </div>
						</div>
						<div className="heading-part2 wrap">
							{!this.state.question_ids?.length ? <><span onClick={this.show}>
								<img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
							</span>
              <Link to={{ pathname: '/admin/scheduled-questions' }}>
                <img src={Calendar2} alt="img"/>{this.props.pagewiseLanguageData.schedule_question}
              </Link></>:""}

              {this.state.question_ids?.length ? <><span onClick={this.mapSection}>
               {this.props.pagewiseLanguageData.map_questions}
              </span>
              <span onClick={this.reset}>
                {this.props.pagewiseLanguageData.reset}
              </span></> :""}

							{!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
              </span>}

              <span onClick={this.bulkQuestionUpload}>{this.props.pagewiseLanguageData.bulk_question_update}</span>
						</div>
					</div>

          {this.state.questions?.length === 0 ? <Empty/> : <>
            {this.state.list ? <table className="questionbank-table">
              <tr>
                <th>{this.props.pagewiseLanguageData.statement}</th>
                <th>{this.props.pagewiseLanguageData.type}</th>
                <th>{this.props.pagewiseLanguageData.difficulty}</th>
                <th>{this.props.pagewiseLanguageData.source}</th>
                <th>{this.props.pagewiseLanguageData.marks}</th>
                <th>{this.props.pagewiseLanguageData.actions}</th>
              </tr>
              {this.state.questions?.data.map((question, index) =>
                <tr key={question.question_id}>

                  <td>
                    <div>
                      <div className="title-grid" dangerouslySetInnerHTML={{ __html: question?.statement ? question.statement?.length > 50 ? question.statement.substr(0, 50) : question.statement : question._source?.statements[0]?.statement }} />
                      <span onClick={() => this.onEditQuestion(question, index + 1)}>{question.statement?.length > 50 ? "view more" : ""}</span>
                    </div>
                  </td>
                  <td>
                    {question.question_type_name}
                  </td>
                  <td>
                    <span className="difficulty-easy">{question.difficulty_name ? question.difficulty_name : difficultyArray[question._source.difficulty]}</span>
                  </td>
                  <td>
                    {question.source_name}
                  </td>
                  <td>
                    {question.marks}
                  </td>
                  <td>
                    <span title="Edit" onClick={() => this.onEditQuestion(question, index + 1)}>
                      <img src={Edit} height="20" alt="edit"/>
                    </span>
                    {/*<span title="Delete" onClick={() => this.showd(question.id)}>
                      <img src={Delete} height="20" alt="delete"/>
                    </span>*/}
                    <span title="Schedule" onClick={() => this.schedule(question.question_id)}>
                      <img src={Calendar} height="20" alt="schedule"/>
                    </span>
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) !== -1 && <input type="checkbox" defaultChecked={true} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) === -1 && <input type="checkbox" defaultChecked={false} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}
                  </td>
                </tr>
              )}
            </table> : <div className="the-grid">
              {this.state.questions.data.map((question, index) =>
                <div className="grid-item" key={question.question_id}>
                  <div className="grid-up grid-up-v1">
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) !== -1 && <input type="checkbox" defaultChecked={true} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}
                    {this.state.source == "add-from-question-bank" && this.state.question_ids.indexOf(question.question_id ? question.question_id : question._id) === -1 && <input type="checkbox" defaultChecked={false} onClick={() => this.changeIds(question.question_id ? question.question_id : question._id)}/>}

                    <div>
                      <div className="title-grid" dangerouslySetInnerHTML={{ __html: question?.statement ? question.statement.length > 50 ? question.statement.substr(0, 50) : question.statement : question._source?.statements[0]?.statement }} />
                      <span onClick={() => this.onEditQuestion(question, index + 1)}>{question.statement?.length > 50 ? this.props.pagewiseLanguageData.view_more:""}</span>
                    </div>
                    <div className="grid-actions">
                      <span title="Edit" onClick={() => this.onEditQuestion(question, index + 1)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>
                      {/*<span title="Delete" onClick={() => this.showd(question.question_id)}>
                        <img src={Delete} alt="delete"/>
                      </span>*/}
                      <span title="Schedule" onClick={() => this.schedule(question.question_id)}>
                        <img src={Calendar} alt={this.props.pagewiseLanguageData.schedule_date}/>
                      </span>
                    </div>
                  </div>
                  <div className="grid-up grid-up-v1">
                    <div className="grid-sec">
                      {this.props.pagewiseLanguageData.difficulty}
                      <span>{question.difficulty_name ? question.difficulty_name : difficultyArray[question._source.difficulty]}</span>
                    </div>
                    <div className="grid-sec2">

                    </div>
                  </div>
                </div>
              )}
            </div>}
          </>}
				</div>
        <Pagination
          activePage={this.state.current_page}
          itemsCountPerPage={20}
          totalItemsCount={this.state.totalItems}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange.bind(this)}
        />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage, getQuestionsList,editQuestionBank,questionSearch,qodCreate, questionSectionMapping,questionBankSearch,createQuestion,updateImage,getCenterList,getClassList2,getSectionList2,editQuestion,
    bulkQuestionUpdate,bulkQuestionJobID,getPageLanguageWiseData
  
  } = actions;
  return {
    // setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    qodCreate :(question,schedule_date,due_date,title,level,type,show_result,created_on,image,level_id) => dispatch(qodCreate(question,schedule_date,due_date,title,level,type,show_result,created_on,image,level_id)),
    getCenterList: () => dispatch(getCenterList()),
    questionSearch : (key,page) => dispatch(questionSearch(key,page)),
    questionBankSearch : (key) => dispatch(questionBankSearch(key)),
    editQuestionBank : (id) => dispatch(editQuestionBank(id)),
    getClassList2: () => dispatch(getClassList2()),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getQuestionsList: (page) => dispatch(getQuestionsList(page)),
    updateImage : (file) => dispatch(updateImage(file)),
    questionSectionMapping:(assessment_section_id,questions) => dispatch(questionSectionMapping(assessment_section_id,questions)),
    editQuestion: (assessment_section_id,id, marks, negative_marking, difficulty, duration_seconds,question_type,options,statements,ifloading) => dispatch(editQuestion(assessment_section_id,id, marks, negative_marking, difficulty, duration_seconds,question_type,options,statements,ifloading)),
    createQuestion: (assessment_section_id, question_type, statements, options, marks, negative_marking, difficulty, duration_seconds) => dispatch(createQuestion(assessment_section_id, question_type, statements, options, marks, negative_marking, difficulty, duration_seconds)),
    bulkQuestionUpdate : (uploadfile) => dispatch(bulkQuestionUpdate(uploadfile)),
    bulkQuestionJobID : (jobid) => dispatch(bulkQuestionJobID(jobid)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBank);
