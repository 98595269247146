import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';

import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import SimpleReactValidator from 'simple-react-validator';
import './styles.css';

class NewAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      opendes: false,
      openi: false,
      next_step: false,
      id: this.props.match.params.id,
      loading: false,
      center_id: localStorage.getItem('center_id'),
      title: '',
      code: '',
      timed_type: '',
      timed_duration_mins: '',
      sections: '',
      isGraded: false,
      attemptsClose: false,
      attemptsCloseToggle: false,
      is_negative:false,
      isTimed :false,
      resultAfter: false,
      solutionAfter: false,
      endDateTime: new Date(),
      resultAfterDate: new Date(),
      solutionAfterDate: new Date(),
      closed_after_passing: null,
      allowed_after_duedate: null,
      time_bound_type:0,
      result_after: null,
      show_solution_after: null,
      total: '',
      passing: '',
      difficulty: 0,
      instruction: '',
      description: '',
      type: '',
      timeline: 1,
      image:null,
      imageURL: null,
      attempts: 0,
      if_image:null,
      sectionsList: [],
      assignmentList:"",
      url: this.props.location.state?.url === undefined ? null : this.props.location.state?.url,
      breads: this.props.location?.state?.breads,
      toc_id: this.props.location.state?.toc_id === undefined 
                ? null : this.props.location.state?.toc_id,
      class_id: this.props.location.state?.class_id === undefined 
                  ? null : this.props.location.state?.class_id,
      subject_id: this.props.location.state?.subject_id === undefined 
                    ? null : this.props.location.state?.subject_id,
      username : localStorage.getItem("username"),
      name : localStorage.getItem("name"),
      fromStudymaterial:false,
      uploadFileError :false,

    }

    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if(this.state.id) this.getAssignment(this.state.id);
    let fromStudymaterial = this.props.location?.state?.fromStudymaterial;

    console.log("fromstudymaterial",fromStudymaterial)
    this.setState({fromStudymaterial})
    //console.log("fromStudymaterial",this.state.fromStudymaterial);

    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
    this.getUrlfromCurrentPage();
  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"menu");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }


  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL1 = getUrl.split("/")
    let modifyURL = modifyURL1[2];
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  getSectionsList = async () => {
    const response = await this.props.getAssessmentSectionList(this.props.match.params.id,true);
    this.setState({ sectionsList: response.data });
  }

  getAssignment = async (id) => {
    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    
    const response = await this.props.assignmentReview(id);
    
    if(response !== undefined) {
      this.setState({ attemptsCloseToggle:(!response.data.data.allowed_after_duedate || response.data.data.closed_after_passing) ? true : false});
      this.setState({ loading: false ,assignmentList:response.data.data,isGraded: response.data.data.is_graded,attemptsClose: response.data.data.closed_after_passing})
      this.setState({ title: response.data.data.title, code: response.data.data.uid, 
                    duration: 0,
                    instruction: response.data.data.description, description: response.data.data.description,
                    difficulty: response.data.data.difficulty, attempts: response.data.data.attempts_allowed,
                    passing: response.data.data.passing_marks + '', total: response.data.data.total_marks + '',allowed_after_duedate : !response.data.data?.allowed_after_duedate,if_image:response.data.data.file})
    }
  }

  getAssessment = async (id) => {
    const response = await this.props.getAssessment(id);
    this.setState({ title: response.data.title, code: response.data.uid, 
                    duration: response.data.timed_duration_mins,
                    instruction: response.data.description, description: response.data.description,
                    difficulty: response.data.difficulty, attempts: response.data.attempts_allowed,
                    passing: response.data.passing_marks + '', total: response.data.total_marks + ''})

  }

  show = () => this.setState({ open: true });
  
  close = () => this.setState({ open: false });

  showdes = () => this.setState({ opendes: true });
  
  closedes = () => this.setState({ opendes: false });

  showi = () => this.setState({ openi: true });
  
  closei = () => this.setState({ openi: false });

  onTitleChange = (e) => {
    this.setState({ title: e.target.value })
  }

  onCodeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  onTypeChange = (val) => {
    this.setState({ timed_type: val ,duration : 5})
  }

  changeDuration = (e) => {
    const duration = (e.target.validity.valid) ? e.target.value : this.state.duration;
    this.setState({ duration });
  }

  totalChange = (e) => {
    const total = (e.target.validity.valid) ? e.target.value : this.state.total;
    this.setState({ total })
  }

  passingChange = (e) => {
    const passing = (e.target.validity.valid) ? e.target.value : this.state.passing;
    this.setState({ passing })
  }

  onDifficultChange = (val) => {
    this.setState({ difficulty: val })
  }

  onDurationChange = (e) => {
    this.setState({ duration: e.target.value })
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  onInstructionChange = (e) => {
    this.setState({ instruction: e.target.value })
  }
  
  minusAttempt = (e) => {
    if(this.state.attempts !== 0) {
      var attempt = this.state.attempts - 1;
      this.setState({ attempts: attempt })
    }
  }

  addAttempt = (e) => {
    var attempt = this.state.attempts + 1;
    this.setState({ attempts: attempt })
  }

  levelChange = (val) => {
    this.setState({ level: val });
  }

  timelineChange = (val) => {
    this.setState({ timeline: val });
  }

  toggleGraded = () => {
    if(this.state.isGraded === true) {
      this.setState({ passing: '', total: '' })
    }
    this.setState({ isGraded: !this.state.isGraded })
  }

  toggleAttemptClose = async() => {

    const {allowed_after_duedate , attemptsClose} = this.state;

    this.setState({ attemptsCloseToggle: !this.state.attemptsCloseToggle});

    if(!this.state.attemptsCloseToggle === false){
      this.setState({allowed_after_duedate : false ,attemptsClose : false});
    }
    if(!this.state.attemptsCloseToggle === true){
      if(this.state.id) {
        const response = await await this.props.assignmentReview(this.state.id);
        this.setState({allowed_after_duedate :!response.data.data?.allowed_after_duedate ,attemptsClose : response.data.data?.closed_after_passing});
      }
    }
  }

  toggleNegativeClose = () => {

    this.setState({ is_negative:!this.state.is_negative});
  }


  toggleResultAfter = () => {
    if(this.state.resultAfter === true) {
      this.setState({ result_after: 0 })
    }
    this.setState({ resultAfter: !this.state.resultAfter })
  }

  toggleTimedClose = () => {
    this.setState({ isTimed:!this.state.isTimed})
    if(!this.state.isTimed === false){
      this.setState({ timed_duration_mins:0,duration:0,timed_type:0})
    }
    if(this.state.isTimed){
      this.setState({duration:5})
    }
  }

  toggleSolutionAfter = () => {
    if(this.state.solutionAfter === true) {
      this.setState({ show_solution_after: 0 })
    }
    this.setState({ solutionAfter: !this.state.solutionAfter })
  }

  changeAttemptAfter = (val) => {
    //this.setState({ closed_after_passing: val })
    if(val == 2){
      console.log(!this.state.allowed_after_duedate)
      this.setState({ allowed_after_duedate: !this.state.allowed_after_duedate });
      //this.setState({ closed_after_passing: 0 });
    }
    if(val == 1){
      this.setState({ attemptsClose: !this.state.attemptsClose });
      //this.setState({ time_bound_type: 0 });
    }
  }

  changeResultAfter = (val) => {
    this.setState({ result_after: val })
  }

  changeSolutionAfter = (val) => {
    this.setState({ show_solution_after: val })
  }

  changeEndDate = (date) => {
    this.setState({ endDateTime: date })
  }

  changeSolutionDate = (date) => {
    this.setState({ solutionAfterDate: date })
  }

  changeResultDate = (date) => {
    this.setState({ resultAfterDate: date })
  }

  editAssessmentBasic = async () => {
    this.setState({open:false})
    let duration_seconds = 0;
    let duration = this.state.duration;
    const { id, code, title, center_id, timed_type} = this.state;

    if(timed_type === 2){
      this.state.sectionsList.map((section,index1) => {
        section.questions.map((data,index) => {
          duration_seconds += data.question_data.duration_seconds;
        })
      })
      duration = Math.floor(duration_seconds / 60);
    }
    if(timed_type === 0){
      duration = 0;
    }

    await this.props.editAssessmentBasic(id, code, title, center_id, timed_type, duration);
  } 

  removeImage = () => {
    this.setState({if_image:null});
    let element =document.getElementById('img-input');
    if(element) element.value = "";
  }

  updateAssessment = async () => {
    

    if (this.validator.allValid()) {

      const {code,title,duration,difficulty,attempts, total, passing,description,isGraded,attemptsClose,image,allowed_after_duedate,center_id,imageURL} = this.state;
      
      if(parseInt(total) && parseInt(passing) >= parseInt(total)){
         store.addNotification({
          title: 'Invalid data',
          message: this.props.pagewiseLanguageData.passing_marks_should_be_less_than_total_marks,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        })

         return ;
      }else{
        
        let response ;
        if(this.props.match.params.id)
          response = await this.props.assignmentEdit(this.props.match.params.id,code,title,0,0,total, passing,attempts,isGraded,attemptsClose,allowed_after_duedate,difficulty,"",description,this.props.location.state.class_id,this.props.location.state.subject_id,this.state.fromStudymaterial != undefined ? false : true,this.state.toc_id,center_id,image);
        else 
          response = await this.props.assignmentCreate(code,title,0,0,total, passing,attempts,isGraded,attemptsClose,allowed_after_duedate,difficulty,"",description,this.props.location.state.class_id,this.props.location.state.subject_id,this.state.fromStudymaterial != undefined ? false : true,this.state.toc_id,center_id, imageURL ? image :"");
        

        localStorage.setItem("expandVal",this.state.toc_id)
        localStorage.setItem("expand","true")
        this.setState({currentTocId : this.state.toc_id})

        if(response?.status === 1)
          this.props.history.goBack()
      }
      
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    //this.setState({ next_step: true });
  }

  // handleChange = async (event) => {

  //   if(event.target.files[0].type.split('/')[0]) {
  //     this.setState({ 
  //       image: event.target.files[0],
  //       imageURL: URL.createObjectURL(event.target.files[0])
  //     })

  //     //const response= await this.props.updateImage(event.target.files[0]);
  //     //this.setState({image:response.data.image});
  //   }
  //   else {
  //     this.setState({ image: null, imageURL: null });
  //     document.getElementById('img-input').value = "";
  //     store.addNotification({
  //       title: 'Error',
  //       message: `${this.props.pagewiseLanguageData.only_images_can_be_uploaded_here}`,
  //       type: 'danger',
  //       container: 'top-right',
  //       dismiss: {
  //         duration: 3000
  //       }
  //     })
  //   }
  // }

  handleChange = async (event) => {
    var filename = event.target.files[0];
    var getFileExtensions = this.props.learnerprofile?.institute_settings?.allowed_file_extensions;
    //console.log('validFileExtensions', getFileExtensions);
    // var validFileExtensions = ["xls","xlsx", "ppt", "pptx", "doc", "docx", "pdf", "zip", "rar", "jpg", "jpeg"]; 
      
    if(filename){
      var fileext = filename.name.split('.').pop();
      console.log("fileext",fileext);
      //console.log("filename",filename);
      if (getFileExtensions.lastIndexOf(fileext) == -1) {
        this.setState({uploadFileError:true});
        this.setState({image:''});
        this.setState({imageURL:''});
        console.log("invalid");
        return false;
      }else{
        this.setState({uploadFileError:false});
        this.setState({ 
          image: event.target.files[0],
          imageURL: URL.createObjectURL(event.target.files[0])
        })
       
      }
    }
   
    
  }


  cancleAssignment = () => {
    this.props.history.goBack();
    //window.location.href="/admin/assignment";
  }

  render() {
    const { isGraded, attemptsClose, resultAfter, solutionAfter, difficulty, next_step, 
              id,is_negative,isTimed ,assignmentList,attemptsCloseToggle,if_image,image} = this.state;
    const isEnabled = this.state.image  || this.state.if_image
    const isPassEnabled = !this.state.total
    if(this.props.loading) {
      return (
        <div className="admin-div">
        </div>
      )
    }
    if(next_step) {
      return (
        <Redirect to={{ pathname: `/admin/assessment-questions/${id}`,
                        state: { toc_id: this.state?.toc_id, class_id: this.state?.class_id, 
                          subject_id: this.state?.subject_id, data: this.props.location.state?.data,
                          url: this.state.url } }}/>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.new_assignment}</title>
        </Helmet>
        <ReactNotifications/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content2">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_basic_details}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data2">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.assessment_title} onChange={this.onTitleChange}
                value={this.state.title}/>
              <input placeholder={this.props.pagewiseLanguageData.test_code} onChange={this.onCodeChange}
                value={this.state.code}/>
            </div>
            <div className="base-settings">
              <div>
                
                <div className="asm-setting-div-popup">
                  <div className="asm-setting-option">
                    <span>{this.props.pagewiseLanguageData.is_timed}</span>
                    <>
                      <input
                        checked={isTimed}
                        onChange={this.toggleTimedClose}
                        className="react-switch-checkbox"
                        id={`react-switch-istimed`}
                        type="checkbox"
                      />
                      <label
                        style={{ background: isTimed && '#489198' }}
                        className="react-switch-label"
                        htmlFor={`react-switch-istimed`}
                      >
                        <span className={`react-switch-button`} />
                      </label>
                    </>
                  </div>
                  {isTimed && <div className="asm-setting-horizon">
                    <div>
                      <input name="time" type="radio" value={1} onClick={() => this.onTypeChange(1)}
                        checked={this.state.timed_type === 1}/>
                      <label htmlFor={1}>{this.props.pagewiseLanguageData.timed_on_total}</label>
                      {this.state.timed_type === 1 ? <div className="asm-setting-duration">
                        <div><input value={this.state.duration} onInput={this.changeDuration}
                          type="text" pattern="[0-9]*"/> {this.props.pagewiseLanguageData.mins}</div>
                      </div> : null}
                    </div> 
                    <div>
                      <input name="time" type="radio"  value={2} onClick={() => this.onTypeChange(2)}
                        checked={this.state.timed_type === 2}/>
                      <label htmlFor={2}>{this.props.pagewiseLanguageData.timed_on_individual_question}</label>
                    </div> 
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="popup-actions-onedit">
            <button disabled={!isEnabled} onClick={this.editAssessmentBasic}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.opendes} closeOnDocumentClick onClose={this.closedes}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.assessment_description}</span>
            <textarea value={this.state.description} onChange={this.onDescriptionChange}
              placeholder={this.props.pagewiseLanguageData.enter_description}/>
            <div>
              <button onClick={this.closedes}>
                {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.openi} closeOnDocumentClick onClose={this.closei}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.assessment_instruction}</span>
            <textarea value={this.state.instruction} onChange={this.onInstructionChange}
              placeholder={this.props.pagewiseLanguageData.enter_instructions}/>
            <div>
              <button onClick={this.closei}>
                {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <div className="assg-header1">
          <div>{this.props.pagewiseLanguageData.great_going} <b>{this.state.name}</b><br/><span Style="color: #8d9297;">{this.props.pagewiseLanguageData.now_lets_create_new_assignment}</span></div>
          {/* <span className="assg-header-select">
            {this.props.pagewiseLanguageData.admin}
          </span> */}
        </div>
        <div className="assg-header1">
          <div className="header2-content">{this.props.location.state.classname} | {this.props.location.state.subjectname} > {this.props.pagewiseLanguageData.add_new_assignment}</div>
          <span className="button-header">
            <button onClick={this.cancleAssignment}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.updateAssessment} disabled={!isEnabled}>
            {this.props.pagewiseLanguageData.save}
            </button>
          </span>
        </div>
        <div className="assg-container">
          <div className="asm-ques">
            <div className="asm-info">
              <div className="assg-head-edit">
                <span id="assg-titles2">{this.props.pagewiseLanguageData.basic_details}</span>
              </div>
              <div className="assg-title">
                <div className="basic-details-row">
                  <div>{this.props.pagewiseLanguageData.assignment_title}</div>
                  <input  placeholder="" defaultValue={this.state.title} type="text" onChange={this.onTitleChange}/>
                  {this.validator.message('title', this.state.title, 'required')}
                </div>
                <div className="basic-details-row">
                  <div>{this.props.pagewiseLanguageData.assignment_code}</div>
                  <input  placeholder="" defaultValue = {this.state.code} type="text" onChange={this.onCodeChange}/>
                  {this.validator.message('code', this.state.code, 'required')}
                </div>
              </div>
              <div className="assg-title">
                <div className="basic-details-row">
                    <div>{this.props.pagewiseLanguageData.assignment_description}</div>
                    <textarea  placeholder="" defaultValue = {this.state.description} type="text" onChange={this.onDescriptionChange}/>
                    {this.validator.message('description', this.state.description, 'required|min:10|max:1000')}
                </div>
              </div>
            </div>
            <div className="asm-info" id="asm-info2">
              <div className="assg-setting-div2">
                <div>
                  <span id="assg-titles3">{this.props.pagewiseLanguageData.difficulty_level}</span>
                  <div className="assg-difficulty">
                    <span onClick={() => this.onDifficultChange(0)}
                      className={difficulty === 0 ? "selected-span easy" : this.props.pagewiseLanguageData.easy}>{this.props.pagewiseLanguageData.easy}</span>
                    <span onClick={() => this.onDifficultChange(1)}
                      className={difficulty === 1 ? "selected-span medium" : this.props.pagewiseLanguageData.medium}>{this.props.pagewiseLanguageData.medium}</span>
                    <span onClick={() => this.onDifficultChange(2)}
                      className={difficulty === 2 ? "selected-span hard" : this.props.pagewiseLanguageData.hard}>{this.props.pagewiseLanguageData.hard}</span>
                    <span onClick={() => this.onDifficultChange(3)}
                      className={difficulty === 3 ? "selected-span very-hard" : this.props.pagewiseLanguageData.very_hard}>{this.props.pagewiseLanguageData.very_hard}</span>
                  </div>
                  
                </div>
                {/*<div className="assg-title">
                  <div className="basic-details-row">
                    <div>Duration</div>
                    <input placeholder="" type="text" defaultValue={id ? assignmentList.timed_duration_mins:this.state.duration} onChange={this.onDurationChange}/>
                  </div>
                </div>*/}

                {/*<div className="asm-setting-sections">
                  <span id="asm-titles3">Attempts (0 is for unlimited attempts)</span>
                  <div className="assg-setting-duration">
                    <div>
                      <span onClick={this.minusAttempt}>&#45;</span>
                      <div>{this.state.attempts}</div>
                      <span onClick={this.addAttempt}>&#43;</span>
                    </div>
                    <div>
                      0 means unlimited attempts
                    </div>
                  </div>
                </div>*/}
              </div>
              {/*<div className="asm-setting-div3">
                <span onClick={this.showdes}>Assessment Description</span>
                <span onClick={this.showi}>Assessment Instruction</span>
              </div>*/}
              <div className="assg-setting-div">
                <div className="asm-setting-option">
                  <span>
                    {this.props.pagewiseLanguageData.is_graded}
                  </span>
                  <>
                    <input
                      checked={isGraded}
                      onChange={this.toggleGraded}
                      className="react-switch-checkbox"
                      id={`react-switch-new`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: isGraded && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-new`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>
                {isGraded ? <div className="assg-setting-data" id="assg-setting-graded">
                    <div className="assg-title">
                      <div className="basic-details-row">
                        <div>{this.props.pagewiseLanguageData.total_marks}</div>
                        <input type="text" pattern="[0-9]*" placeholder="" onInput={this.totalChange} defaultValue={this.state.total}/>
                        {this.validator.message(this.props.pagewiseLanguageData.total_marks, this.state.total, this.props.pagewiseLanguageData.required)}
                      </div>
                    </div>
                    <div className="assg-title">
                      <div className="basic-details-row">
                        <div>{this.props.pagewiseLanguageData.passing_marks}</div>
                        <input type="text" pattern="[0-9]*" placeholder="" disabled={isPassEnabled ?"disabled":""}
                          onInput={this.passingChange} defaultValue={this.state.passing}/>
                        {this.validator.message(this.props.pagewiseLanguageData.passing_marks,this.state.passing, this.props.pagewiseLanguageData.required)}
                      </div>
                    </div>
                  </div>
                  : null}
              </div>

              <div className="assg-info">
                <div className="asm-head-edit">
                  <span id="assg-titles2">{this.props.pagewiseLanguageData.number_of_attempts}</span>
                </div>
                <div className="assg-setting-duration">
                  <div>
                    <span onClick={this.minusAttempt}>&#45;</span>
                    <div>{this.state.attempts}</div>
                    <span onClick={this.addAttempt}>&#43;</span>
                  </div>
                  <div Style="color: #489198;">
                    {this.props.pagewiseLanguageData.zero_means_unlimited_attempts}
                  </div>
                </div>
                
              </div>

              <div className="assg-setting-div">
                <div className="asm-setting-option">
                  <span>
                    {this.props.pagewiseLanguageData.attempts_closed_after}
                  </span>
                  <>
                    <input
                      checked={attemptsCloseToggle}
                      onChange={this.toggleAttemptClose}
                      className="react-switch-checkbox"
                      id={`react-switch-new1`}
                      type="checkbox"
                    />
                    <label
                      style={{ background: attemptsCloseToggle && '#489198' }}
                      className="react-switch-label"
                      htmlFor={`react-switch-new1`}
                    >
                      <span className={`react-switch-button`} />
                    </label>
                  </>
                </div>
                {attemptsCloseToggle ? <div className="asm-setting-data">
                  <div className="asm-options-layer">
                    <div className="asm-single-sett">
                      <input name="attempt1" type="checkbox"  onChange={() => this.changeAttemptAfter(2)}
                        checked={this.state.allowed_after_duedate}/>
                      <label>{this.props.pagewiseLanguageData.scheduled_end_date_time}</label>
                    </div>
                    <div className="asm-single-sett">
                      <input name="attempt2" type="checkbox" onChange={() => this.changeAttemptAfter(1)}
                        checked={attemptsClose === true}/>
                      <label>{this.props.pagewiseLanguageData.assignment_is_passed}</label>
                    </div>
                  </div>
                </div> : null}
              </div>


              {if_image ? <div className="assg-info">
                <div className="">
                  <span onClick={this.removeImage} Style="cursor:pointer">&#x2715;</span>
                  <div className="input_field">
                    <a href={image} alt="prev" download> {this.props.pagewiseLanguageData.download} {assignmentList?.file?.split("/").pop()} </a>
                  </div>
                </div>
              </div>:<div className="assg-info">
                <div className="asm-head-edit">
                  <span id="assg-titles2">{this.props.pagewiseLanguageData.upload_assignment}</span>
                </div>
                <div className="">
                  <div className="input_field">
                    <input type="file" id="img-input" onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                    <div className='uploadNoteText'>Supported file format {this.props.learnerprofile?.institute_settings?.allowed_file_extensions}</div>
                    <div className={`uploadFileError ${this.state.uploadFileError ? "show" : ""}`}>Please upload valid format</div>
                  </div>
                </div>
              </div> }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    learnerprofile : state.commonReducer.logindetails,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessment,assignmentReview, updateAssessment ,updateImage,assignmentEdit, editAssessmentBasic,getAssessmentSectionList,assignmentCreate,getPageLanguageWiseData,getLanguageWiseData} = actions;
  return {
    getAssessment: (id) => dispatch(getAssessment(id)),
    updateImage : (file) => dispatch(updateImage(file)),
    assignmentReview: (id) => dispatch(assignmentReview(id)),
    assignmentEdit : (id,uid,title,type,timed_duration_mins,total_marks,passing_marks,attempts_allowed,is_graded,closed_after_passing,allowed_after_duedate,difficulty,instructions,description,class_id,subject_id,is_published,toc_id,center,file) => dispatch(assignmentEdit(id,uid,title,type,timed_duration_mins,total_marks,passing_marks,attempts_allowed,is_graded,closed_after_passing,allowed_after_duedate,difficulty,instructions,description,class_id,subject_id,is_published,toc_id,center,file)),
    getAssessmentSectionList: (id,ifloading) => dispatch(getAssessmentSectionList(id,ifloading)),
    assignmentCreate: (uid,title,type,timed_duration_mins,total_marks,passing_marks,attempts_allowed,is_graded,closed_after_passing,allowed_after_duedate,difficulty,instructions,description,class_id,subject_id,is_published,toc_id,center,file) => dispatch(assignmentCreate(uid,title,type,timed_duration_mins,total_marks,passing_marks,attempts_allowed,is_graded,closed_after_passing,allowed_after_duedate,difficulty,instructions,description,class_id,subject_id,is_published,toc_id,center,file)),
    editAssessmentBasic: (id, uid, title, center_id, timed_type, timed_duration_mins) => dispatch(editAssessmentBasic(id, uid, title, center_id, timed_type, timed_duration_mins)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAssignment);