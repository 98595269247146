import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Tab } from "semantic-ui-react";

import actions from '../../redux/actions';
import './styles.css';
import './circle.css';

function getData(value, data) {
  if(data.lowest_in_batch < 0) {
    return(value - data.lowest_in_batch)
  }
  else {
    return value;
  }
}

function getData4(value,data){
  let prec = (100*value);  
  return 'linear-gradient(100deg, transparent '+`${prec}` +'%, rgb(30, 40, 41) 0%,rgb var(--primary), linear-gradient(90deg, rgb(226, 236, 239) 0%, transparent 0%)';
}

function getDataLow(value, data) {
  if(value <= 0 && (data.highest_in_batch > 0 && data.batch_average > 0)) {
    return '6px';
  }
  else {
    return `${(value/maxmin(data))*100}%`;
  }
}

function maxmin(data) {
  if(data.lowest_in_batch < 0) {
    return(data.highest_in_batch - data.lowest_in_batch);
  }
  else {
    return data.highest_in_batch
  }
}

const Questions = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.ques_attemped}</span>
      <div className="scorecard_card">
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.highest_in_batch * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_high"
            style={{width: `${(getData(props.data.highest_in_batch, props.data)/maxmin(props.data))*100}%`}}>
          </div>
        </div>
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.batch_average * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_avg"
            style={{width: `${(getData(props.data.batch_average, props.data)/maxmin(props.data))*100}%`}}>
          </div>
        </div>
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.lowest_in_batch * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_low"
            style={{width: getDataLow(props.data.lowest_in_batch, props.data)}}>
          </div>
        </div>
      </div>
      <div className="scorecard_colors">
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_high">
          </div>
          <span>{props.saveLanguageWiseData.highest_in_batch}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_avg">
          </div>
          <span>{props.saveLanguageWiseData.avg_in_batch}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_low">
          </div>
          <span>{props.saveLanguageWiseData.lowest_in_batch}</span>
        </div>
      </div>
    </div>
  )
}

const MaxScore = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.data.label}</span>
      <div className="scorecard_card">
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.highest_in_batch * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_high"
            style={{width: `${(getData(props.data.highest_in_batch, props.data)/maxmin(props.data))*100}%`}}>
          </div>
        </div>
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.batch_average * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_avg"
            style={{width: `${(getData(props.data.batch_average, props.data)/maxmin(props.data))*100}%`}}>
          </div>
        </div>
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.lowest_in_batch * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_low"
            style={{width: getDataLow(props.data.lowest_in_batch, props.data)}}>
          </div>
        </div>
      </div>
      <div className="scorecard_colors">
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_high">
          </div>
          <span>{props.saveLanguageWiseData.highest_in_batch}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_avg">
          </div>
          <span>{props.saveLanguageWiseData.avg_in_batch}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_low">
          </div>
          <span>{props.saveLanguageWiseData.lowest_in_batch}</span>
        </div>
      </div>
    </div>
  )
}

const CorrectAnswers = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.correct_answers}</span>
      <div className="scorecard_card3">
        <div className="scorecard_times">
          <div id="activeBorder" class="active-border1" style={{backgroundImage: getData4((Math.round(props.data.highest_in_batch * 100) / 100), props.data)}}>
            <div id="circle" class="circle1">
              <span class="prec 270" id="prec">{(Math.round(props.data.highest_in_batch * 100) / 100)}</span>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.highest_in_batch}</span>
        </div>
        <div className="scorecard_times">
          <div id="activeBorder" class="active-border1" style={{backgroundImage: getData4((Math.round(props.data.batch_average * 100) / 100), props.data)}}>
            <div id="circle" class="circle1">
              <span class="prec 270" id="prec">{(Math.round(props.data.batch_average * 100) / 100)}</span>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.avg_in_batch}</span>
        </div>
        <div className="scorecard_times">
          <div id="activeBorder" class="active-border1" style={{backgroundImage: getData4((Math.round(props.data.lowest_in_batch * 100) / 100), props.data)}}>
            <div id="circle" class="circle1">
              <span class="prec 270" id="prec">{(Math.round(props.data.lowest_in_batch * 100) / 100)}</span>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.lowest_in_batch}</span>
        </div>
      </div>
      <div className="scoreNotify">{props.saveLanguageWiseData.subjective_scorecard}</div>
    </div>
  )
}

const AvgTime = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.avg_time_ques}</span>
      <div className="scorecard_card2">
        <div className="scorecard_times">
          <div className="scorecard_time score_low">
            {(Math.round(props.data.highest_in_batch * 100) / 100)}s
          </div>
          <span>{props.saveLanguageWiseData.highest_in_batch}</span>
        </div>
        <div className="scorecard_times">
          <div className="scorecard_time score_avg">
            {(Math.round(props.data.batch_average * 100) / 100)}s
          </div>
          <span>{props.saveLanguageWiseData.avg_in_batch}</span>
        </div>
         <div className="scorecard_times">
          <div className="scorecard_time score_high">
            {(Math.round(props.data.lowest_in_batch * 100) / 100)}s
          </div>
          <span>{props.saveLanguageWiseData.lowest_in_batch}</span>
        </div>
      </div>
    </div>
  )
}

const Accuracy = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.accuracy}</span>
      <div className="scorecard_card2">
        <div className="scorecard_times">
          <div class={"c100 p"+(Math.round((props.data.highest_in_batch * 100) / 100))}>
            <span>{(Math.round((props.data.highest_in_batch * 100) / 100))}%</span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.highest_in_batch}</span>
        </div>
        <div className="scorecard_times">
          <div class={"c100 p"+(Math.round((props.data.batch_average * 100) / 100))}>
            <span>{(Math.round((props.data.batch_average * 100) / 100))}%</span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.avg_in_batch}</span>
        </div>
        <div className="scorecard_times">
          <div class={"c100 p"+(Math.round((props.data.lowest_in_batch * 100) / 100))}>
            <span>{(Math.round((props.data.lowest_in_batch * 100) / 100))}%</span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.lowest_in_batch}</span>
        </div>
      </div>
      <div className="scoreNotify">{props.saveLanguageWiseData.subjective_scorecard}</div>
    </div>
  )
}

const CorrectPattern = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.correct_answers_pattern}</span>
      <div className="scorecard_card2">
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{props.data.easy.highest_in_batch}</span>
              <div className="high_pattern score_high"
                style={{height: `${(getData(props.data.easy.highest_in_batch, props.data.easy)/maxmin(props.data.easy))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.easy.batch_average}</span>
              <div className="high_pattern score_avg"
                style={{height: `${(getData(props.data.easy.batch_average, props.data.easy)/maxmin(props.data.easy))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.easy.lowest_in_batch}</span>
              <div className="high_pattern score_low"
                style={{height: getDataLow(props.data.easy.lowest_in_batch, props.data.easy)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.easy}</span>
        </div>
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{props.data.medium.highest_in_batch}</span>
              <div className="high_pattern score_high"
                style={{height: `${(getData(props.data.medium.highest_in_batch, props.data.medium)/maxmin(props.data.medium))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.medium.batch_average}</span>
              <div className="high_pattern score_avg"
                style={{height: `${(getData(props.data.medium.batch_average, props.data.medium)/maxmin(props.data.medium))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.medium.lowest_in_batch}</span>
              <div className="high_pattern score_low"
                style={{height: getDataLow(props.data.medium.lowest_in_batch, props.data.medium)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.medium}</span>
        </div>
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{props.data.hard.highest_in_batch}</span>
              <div className="high_pattern score_high"
                style={{height: `${(getData(props.data.hard.highest_in_batch, props.data.hard)/maxmin(props.data.hard))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.hard.batch_average}</span>
              <div className="high_pattern score_avg"
                style={{height: `${(getData(props.data.hard.batch_average, props.data.hard)/maxmin(props.data.hard))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.hard.lowest_in_batch}</span>
              <div className="high_pattern score_low"
                style={{height: getDataLow(props.data.hard.lowest_in_batch, props.data.hard)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.hard}</span>
        </div>
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{props.data.very_hard.highest_in_batch}</span>
              <div className="high_pattern score_high"
                style={{height: `${(getData(props.data.very_hard.highest_in_batch, props.data.very_hard)/maxmin(props.data.very_hard))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.very_hard.batch_average}</span>
              <div className="high_pattern score_avg"
                style={{height: `${(getData(props.data.very_hard.batch_average, props.data.very_hard)/maxmin(props.data.very_hard))*100}%`}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{props.data.very_hard.lowest_in_batch}</span>
              <div className="high_pattern score_low"
                style={{height: getDataLow(props.data.very_hard.lowest_in_batch, props.data.very_hard)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.difficult}</span>
        </div>
      </div>
      <div className="scorecard_colors">
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_high">
          </div>
          <span>{props.saveLanguageWiseData.highest_in_batch}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_avg">
          </div>
          <span>{props.saveLanguageWiseData.avg_in_batch}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_low">
          </div>
          <span>{props.saveLanguageWiseData.lowest_in_batch}</span>
        </div>
      </div>
    </div>
  )
}

class Scorecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overall: [],
      student_wise: [],
      questions: [],
      avg_time: [],
      correct_pattern: [],
      max_score: [],
      correct_answers: [],
      accuracy: [],
      content: this.props.location.state?.contentId,
      batch: this.props.location.state?.batchId,
      loading: true
    }
  }

  componentDidMount() {
    this.getScoreCard(this.props.location.state?.contentId, this.props.location.state?.batchId);
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"faculty");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }

  getScoreCard = async (contentId, batchId) => {
    const response = await this.props.getFacultyScorecard(contentId, batchId);
    this.setState({
      overall: response?.assessment_summary,
      student_wise: response?.student_wise.slice(1,response.student_wise.length),
      questions: response?.overall.attempted,
      max_score: response?.overall.score,
      correct_answers: response?.overall.correct_answers,
      avg_time: response?.overall.avg_time_per_question,
      accuracy: response?.overall.accuracy,
      correct_pattern: response?.overall.scoring_pattern,
      loading: false
    })
  }

  render() {
    if(this.props.loading || this.state.loading) {
      return (<div></div>)
    }
    else {
      return (
        <div>
          <Helmet>
            <title>{this.props.saveLanguageWiseData.scorecard}</title>
          </Helmet>
          <div className="scorecard-header">
            <a className="close" onClick={() => this.props.history.goBack()}>&times;</a>
            <div className="scorecard-header-left">
            </div>
            <div className="scorecard-header-right">
            </div>
            <div className="overall_scorehead">
             {this.props.saveLanguageWiseData.overall_scorecard}
            </div>
            <div className="overall_scorehead_display">
              <div className="overall_scorehead_div">
                <div className="single_scorecard">
                  {this.state.overall?.avg_attempted}
                </div>
                <span>{this.props.saveLanguageWiseData.avg_attempted}</span>
              </div>
              <div className="overall_scorehead_div">
                <div className="single_scorecard">
                  {(Math.round(this.state.overall?.avg_correct * 100) / 100)}
                </div>
                <span>{this.props.saveLanguageWiseData.avg_correct}</span>
              </div>
              <div className="overall_scorehead_div">
                <div className="single_scorecard">
                  {(Math.round(this.state.overall?.avg_score * 100) / 100)}
                </div>
                <span>{this.props.saveLanguageWiseData.avg_score}</span>
              </div>
            </div>
            <div className="overall_avg_time">
              {this.props.saveLanguageWiseData.your_avg_time}: {(Math.round(this.state.overall?.avg_time * 100) / 100)}s
            </div>
          </div>
          <Tab
            className="scorecard-main"
            menu={{ secondary: true, pointing: true }}
            panes={[
              {
                menuItem: this.props.saveLanguageWiseData.overall,
                render: () => (
                  <div className="scorecard-overall">
                    <Questions data={this.state.questions} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
                    <MaxScore data={this.state.max_score} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
                    <CorrectAnswers data={this.state.correct_answers} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
                    <AvgTime data={this.state.avg_time} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
                    <Accuracy data={this.state.accuracy} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
                    <CorrectPattern data={this.state.correct_pattern} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
                  </div>
                )
              },
              {
                menuItem: this.props.saveLanguageWiseData.student_wise,
                render: () => (
                  <div className="student_scorecard">
                    <div className="scorecard_student_header">
                      <div className="scorecard_header_title rank_header">
                        {this.props.saveLanguageWiseData.rank}
                      </div>
                      <div className="scorecard_header_title name_header">
                      {this.props.saveLanguageWiseData.name}
                      </div>
                      <div className="scorecard_header_title similar_header">
                      {this.props.saveLanguageWiseData.attempted}
                      </div>
                      <div className="scorecard_header_title similar_header">
                      {this.props.saveLanguageWiseData.correct}
                      </div>
                      <div className="scorecard_header_title similar_header">
                        {this.props.saveLanguageWiseData.your_avg_time}
                      </div>
                      <div className="scorecard_header_title similar_header">
                      {this.props.saveLanguageWiseData.score}
                      </div>
                    </div>
                    <div className="scorecard_scroll_list">
                    {this.state.student_wise.map(student =>
                      <div className="scorecard_student_content" key={student[0]}>
                        <div className="scorecard_student_title rank_header">
                          {student[0]}
                        </div>
                        <div className="scorecard_student_title name_header">
                          {student[1]}
                        </div>
                        <div className="scorecard_student_title similar_header">
                          {student[2]}
                        </div>
                        <div className="scorecard_student_title similar_header">
                          {student[3]}
                        </div>
                        <div className="scorecard_student_title similar_header">
                          {Math.round(student[4] * 100) / 100}
                        </div>
                        <div className="scorecard_student_title similar_header">
                          {(Math.round(student[5] * 100) / 100)}
                        </div>
                      </div>
                    )}
                    </div>
                  </div>
                )
              }
            ]}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  };
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage, getFacultyScorecard,getLanguageWiseData } = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getFacultyScorecard: (content_id, batch_id) => dispatch(getFacultyScorecard(content_id, batch_id)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scorecard);