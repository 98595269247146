import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Select from 'react-select'
import actions from '../../redux/actions';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import common from '../../common';
import Profile from '../../public/icons/user-profile.svg';
import Pagination from "react-js-pagination";
import Back from '../../public/icons/back.svg';
class AssignmentIncreaseAttempt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_faculty_admin:false,
      AllLists: [],
      UpdateList:[],
      defaultListSave:[],
      classes: [],
      center_id:localStorage.getItem('center_id'),
      sections: [],
      students: [],
      classidsave:'',
      batchidsave:'',
      searchText:'',
      classfilterData:[],
      batchFilterData:[],
      pageTotalItems:0,
      currentpage:1,
      assessmentIdsave:'',
      saveBatchInputValue : ''

    }
  }

  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    let fromAssessment = this.props.location?.state?.classID;
    let getAssessmentID = this.props.location?.state?.assessmentID;
    
    console.log("this.props.location?.state")
    console.log(this.props.location?.state)

    if(getAssessmentID){
      console.log("getAssessmentID",getAssessmentID);
      this.setState({ assessmentIdsave:getAssessmentID});
      
    }

    if(fromAssessment){
      this.setState({ classidsave:fromAssessment});
      this.getClassList();
      this.defaultClassChange(fromAssessment);
    }    
  
  }


  defaultClassChange = (classid) => {
    //this.getSectionList(e.target.value);
    //alert(classid);
    //alert(this.state.assessmentIdsave);
    if(classid){
      this.getSectionList(classid);
    }
    
  
}
getSectionList = async (classid) => {
  const response = await this.props.getSectionList(this.state.center_id, classid,1,this.state.page_url);
  if(response !== undefined) {
      console.log(response?.data);
      if(response?.data){
          var newdata = response?.data.map((o)=>({
            ...o,
            value:o.id
        }))
        this.setState({ sections:newdata});
      } 
  }
}
  // onClassChange = (e) => {
  //   this.setState({ classidsave: e.target.value });
  //   // alert(e.target.value)
  //   if(e.target.value){
  //     this.getSectionList(e.target.value);
  //   }
  // }


  onBatchChange = (e) => {
    this.setState({ batchidsave: e.target.value });
    console.log(e.target.value)
    let batchFilterData = this.state.sections.filter((word) => word.id == e.target.value);
    console.log("sections");
    console.log(this.state.sections);
    console.log(batchFilterData);
    if(e.target.value){
      this.setState({ batchFilterData: batchFilterData});
      this.getStudentListNotifications(e.target.value);
    }
  }

  getStudentListNotifications = async (batch_id) => {
		const response = await this.props.getStudentListIncreaseAttemptAssignment(batch_id,this.state.assessmentIdsave);
		if(response !== undefined) {
			this.setState({ AllLists: response.data})
      this.setState({UpdateList: response.data})
      this.setState({defaultListSave: response.data})
    }
	}



  getClassList = async () => {
    const response = await this.props.getClassList();
    if(response !== undefined) {
      this.setState({ classes: response.data});
      let saveFilterData = response.data.filter((word) => word.id == this.state.classidsave);
      console.log("saveFilterData",saveFilterData);
      let saveLabel = saveFilterData[0].label;
      console.log("saveLabel",saveLabel);
      if(saveLabel){
        this.setState({ classfilterData: saveLabel});
      }
      
      
    }
  }




  searchChange = async (e) => {
    let  {AllLists,defaultListSave} = this.state;
    clearInterval(this.timer)
   this.setState({searchText:e.target.value})

    let textValue = e.target.value;
    console.log('e.target.value',e.target.value);

    this.timer = setTimeout(async () => {
      if(textValue){
        console.log(AllLists)
        const SearchFileredData =  defaultListSave.filter((obj) =>
          JSON.stringify(obj).toLowerCase().includes(textValue.toString().toLowerCase())
          )
        console.log("SearchFileredData")
        console.log(SearchFileredData)
        console.log("AllLists")
        console.log(AllLists)
        console.log("defaultListSave")
        console.log(defaultListSave)
        this.setState({AllLists: SearchFileredData})
        this.setState({UpdateList: SearchFileredData});
      }else{
        const response = await this.props.getStudentListIncreaseAttemptAssignment(this.state.batchidsave, this.state.assessmentIdsave);
        if(response !== undefined) {
          this.setState({AllLists: response.data})
          this.setState({UpdateList: response.data})
        }
      }
    },1000);

  }

  onAttemptInputChange = (e,index) =>{
    //alert(e.target.value);
    let attemtValue  = e.target.value;
    let user_id = e.target.id;
    this.setState({dataSendAttemptValue: attemtValue});
    this.setState({dataSendUserId: user_id});
    this.state.UpdateList[index].assignment_attempt_count = attemtValue;
    this.setState({UpdateList: this.state.UpdateList});
    
  }

   onSaveButtonClick = () =>{
    console.log("dataSendAttemptValue",this.state.dataSendAttemptValue)
    console.log("dataSendUserId",this.state.dataSendUserId)
    console.log("dataSendAssessmentID",this.state.assessmentIdsave);
    this.attemptValueSaveSend(this.state.dataSendUserId,this.state.dataSendAttemptValue,this.state.assessmentIdsave)
  }

  attemptValueSaveSend = async (user_id,attemtValue,saveAssessmentID) =>{
    const response = await this.props.increaseAssignmentAttemptCount(user_id,attemtValue,saveAssessmentID);
    if(response.status == 1) {
      console.log(response);
        setTimeout(function() { //Start the timer
          this.getStudentListNotifications(this.state.batchidsave);
      }.bind(this), 1000)
      
    }
  }

  onPageChange = (pageNumber) =>{
    console.log(`current page is ${pageNumber}`);
    this.setState({currentpage: `${pageNumber}`});
    //this.getUnreadReadNotificationLogs(0,`${pageNumber}`);
    this.getStudentListNotifications(this.state.center_id,this.state.classidsave,this.state.batchidsave,`${pageNumber}`);
  }


  
  batchInputChange = (e) =>{
    //alert(e.target.value);
    let saveBatchInputValue  = e.target.value;
    this.setState({saveBatchInputValue: `${saveBatchInputValue}`});

    
  }



  batchSaveClick = (e) =>{
    this.saveCompleteBatchSIncreaseAttempt(this.state.saveBatchInputValue);
    
  }
 

  saveCompleteBatchSIncreaseAttempt = async () => {
    console.log("this.state.batchidsave",this.state.batchidsave)
    console.log("this.state.assessmentIdsave",this.state.assessmentIdsave)
    console.log("this.state.saveBatchInputValue",this.state.saveBatchInputValue)

    const response = await this.props.increaseBatchAssignmentAttemptCount(this.state.batchidsave,this.state.assessmentIdsave,this.state.saveBatchInputValue);
    if(response !== undefined) {
      this.setState({ classes: response.data});
      let saveFilterData = response.data.filter((word) => word.id == this.state.classidsave);
      console.log("saveFilterData",saveFilterData);
      let saveLabel = saveFilterData[0].label;
      console.log("saveLabel",saveLabel);
      if(saveLabel){
        this.setState({ classfilterData: saveLabel});
      }
      
      
    }
  }

	render() {

      if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search classes"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>Increase Attempt</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>Increase Attempt</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search classes"/>
        <div className="main-part">
					<div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  
                </div>
              </div>
            </div>


            <div className='increaseAtmmeptWrapper'>
            <h1> <div id="trace-back" onClick={() => this.props.history.goBack()}>
                <img src={Back} alt="back"/>
              </div>Increase Attempt</h1>
               <div className='filtersWrapper'>
                   <div className='filtersBlock'>
                     <div className='block'>
                      <label>Filter By Batch</label> 
                      {/* {this.state.classidsave} */}
                     {/* <select onChange={this.onClassChange}>
                        <option value={null}>
                          Select class
                        </option>
                        {this.state.classes.map(classed =>
                          <option key={classed.id} value={classed.id} selected={this.state.classidsave == classed.id}>
                            {classed.label}
                          </option>
                        )}
                      </select> */}
                      {this.state.sections && this.state.sections?.length > 0 && 
                      <select onChange={(e) => this.onBatchChange(e)} defaultValue={this.state.batchidsave}>
                        <option value={null}>
                          Select Batch
                        </option>
                        {this.state.sections.map(sections =>
                          <option key={sections.id} value={sections.id}>
                            {sections.label}
                          </option>
                        )}
                      </select>
                      }
                      </div>
                    </div> 
                    <div className='searchWrapper'><label>Search</label>
                        <input type="search" placeholder="Students name" onChange = {this.searchChange} onFocus={this.handleUsernameFocus} value={this.state.searchText || ''}/>
                    </div>        
               </div>
               <div className='classNameDisplay'>Class Name  :- {this.state.classfilterData}</div>
               {this.state.batchFilterData.map((batchData,index) =>
                <div className='batchNameDisplay' key={index}>
                  <div>Batch Name  :- {batchData.label}</div>
                  {this.state.AllLists.length > 0 &&
                  <div className='actions'>
                      <input type="number" onChange = {this.batchInputChange} placeholder='Enter attempt'/><button className={this.state.saveBatchInputValue > 0 ? "" : 'disabled'} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onClick = {this.batchSaveClick}>Save</button>
                  </div>
                  }
                </div>
                )}
              
                <ol>
                {/* {JSON.stringify(this.state.AllLists)}
                {JSON.stringify(this.state.UpdateList)} */}
                {/* {JSON.stringify(this.state.classfilterData)} */}
                
                {this.state.AllLists.map((AllList,index) =>
                  <li id={AllList?.user?.id ? AllList?.user?.id : AllList?._source?.user_id} key={index}> 
                       <div className="listBlock">
                         <div className="thumb"><img src={AllList.user?.profile_picture ? common.addFileUrl(AllList.user?.profile_picture) : Profile} alt="dp"/></div>
                         <div className='thumbContent'>
                            <div>Name - {AllList?.user?.name ? AllList?.user?.name : AllList?._source?.name}</div>
                            <div>Email - {AllList?.user?.email ? AllList?.user?.email : AllList?._source?.email}</div>
                            <div>Mobile - {AllList?.user?.mobile ? AllList?.user?.mobile : AllList?._source?.mobile}</div>
                         </div>
                        </div>
                        {/* {JSON.stringify(AllList?.assessment_attempt_count)} */}
                    <div className='actions'>
                      <input type="number" placeholder='Enter attempt' key={index}  value={this.state.UpdateList[index]?.assignment_attempt_count ? this.state.UpdateList[index]?.assignment_attempt_count : ''}key={AllList?.user?.id ? AllList?.user?.id : AllList?._source?.user_id} onChange={(e) => this.onAttemptInputChange(e,index)} id={AllList?.user?.id ? AllList?.user?.id : AllList?._source?.user_id} /><button className={this.state.UpdateList[index]?.assignment_attempt_count > 0 ? "" : 'disabled'} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onClick={this.onSaveButtonClick}>Save</button></div>
                    </li>
                )}

                {!this.state.AllLists.length && <div className='noDataFound'>No Data Found</div> }


                    
                </ol>
                
            </div>

           


          </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage,scheduleFBNotifications,getClassList,getSectionList,getStudentListAssignmentNotifications,increaseAssignmentAttemptCount,userSearchList,increaseBatchAssignmentAttemptCount,getStudentListIncreaseAttemptAssignment} = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    scheduleFBNotifications: (title,body,batch_ids,image,scheduled_date,channel_id,class_id,is_schedule) => dispatch(scheduleFBNotifications(title,body,batch_ids,image,scheduled_date,channel_id,class_id,is_schedule)),
    getClassList: () => dispatch(getClassList()),
    getSectionList: (center_id, class_id,show_all,page) => dispatch(getSectionList(center_id, class_id,show_all,page)),
    getStudentListAssignmentNotifications: (center_id,class_id,section_id,page,assignment_id) => dispatch(getStudentListAssignmentNotifications(center_id,class_id,section_id,page,assignment_id)),
    increaseAssignmentAttemptCount: (user_id,attempt_count,assignment_id) => dispatch(increaseAssignmentAttemptCount(user_id,attempt_count,assignment_id)),
    userSearchList:(key,page) => dispatch(userSearchList(key,page)),
    increaseBatchAssignmentAttemptCount:(batch_id,assignment_id,attempt_count) => dispatch(increaseBatchAssignmentAttemptCount(batch_id,assignment_id,attempt_count)),
    getStudentListIncreaseAttemptAssignment:(batch_id,assignment_id) => dispatch(getStudentListIncreaseAttemptAssignment(batch_id,assignment_id)),
    
   
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentIncreaseAttempt);
