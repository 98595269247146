import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Popup from 'reactjs-popup';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Listing from '../../public/icons/listing.svg';
import Back from '../../public/icons/back.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Pagination from "react-js-pagination";
import './styles.css';

class ScheduleAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      openf: false,
      id: null,
      class_id: '',
      subject_id: '',
      sectionLabel:null,
      classes: [],
      subjects: [],
      subjects2: [],
      schedule_step: false,
      center_id: localStorage.getItem('center_id'),
      filterCenter: localStorage.getItem('center_id'),
      filterClass: '',
      filterClasses: [],
      filterClassName: '',
      filterSubject: '',
      filterSubjectName: '',
      class_centers: {},
      class_center: '',
      centers: [],
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      title: '',
      section_id: '',
      scheduled_date: '',
      solution_date: '',
      result_date: '',
      subjectName: "",
      classNameText :"",
      is_released: true,
      due_date: '',
      sections: [],
      assessments: [],
      filterAssessments: [],
      is_faculty_admin:false,
      planner_id:null,
      assigned_batch_id:[],
      user_id: localStorage.getItem("user_id"),
    }
  }
  
  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getCenterList();
    this.getAssessmentList(this.state.center_id,this.state.filterSubject,this.state.filterClass,1);
    this.getClassList();  
    this.getSubjectList(this.state.center_id, 0);
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  show = () => this.setState({ open: true, class_id: '', subject_id: '', section_id: '' });
  
  close = () => this.setState({ open: false });

  show2 = async (val) => {

    const {subject_names} = this.state;
    //this.getSubjectList(this.state.center_id,this.state.filterClass?this.state.filterClass:val?.class_id)
    const response = await this.props.getSectionList2(this.state.center_id, val?.class_id);

    this.getSubjectList(this.state.class_centers[val?.class_id], val?.class_id)
    
    if(response.data != undefined){
      for(let i=0; i<response.data?.length; i++){
        response.data[i].is_selected = val.assigned_batch_ids?.length && val.assigned_batch_ids.indexOf(response.data[i].id) != -1 ? true:false;
      }
    }
    this.setState({
      planner_id:val.planner_id,
      scheduled_date: val.scheduled_date,
      due_date:val.due_date ,
      open2: true, 
      id: val.id,title: val.title,is_released: true ,
      sections: response?.data , 
      section_id:val.batch_id,
      filterClass:val?.class_id,
      filterSubject:val?.subject_id,
      assigned_batch_id:val?.assigned_batch_ids,
      subject_id:val?.subject_id});
  }
  
  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  showf = (val) => this.setState({ openf: true });
  
  closef = () => this.setState({ openf: false });

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ centers: response.data });
    }
  }

  getAssessmentList = async (center_id,id,class_id,page) => {
    const response = await this.props.assignmentPlannerList(center_id,parseInt(id),class_id,false,page);
    if(response !== undefined) {
      /*this.setState({ assessments: response.data.filter(assessment => assessment.type === 2),
        filterAssessments: response.data.filter(assessment => assessment.type === 2) });*/
        this.setState({ assessments: response.data.data,filterAssessments: response.data.data,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20});
    }
  }

  getClassList = async () => {
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ), 
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
    }
  }

  getSubjectList = async (class_center, class_id) => {
    const response = await this.props.getSubjectList2(class_center,class_id);
    if(response !== undefined) {
      this.setState({ subjects: response.data.filter(subject => subject.class_details.id === parseInt(class_id)), 
        subject_names: response.data.reduce((obj, item) => Object.assign(obj, { [item.id]: item.label }), {}) });
    }
  }

  getSubjectList2 = async (class_center, class_id) => {
    const response = await this.props.getSubjectList2(class_center,class_id);
    if(response !== undefined) {
      this.setState({ subjects2: response.data.filter(subject => subject.class_details.id === parseInt(class_id)) });
    }
  }

  filterCenterChange = (e) => {
    if(e.target.value === '') {
      this.setState({ filterCenter: '', filterClass: '', filterSubject: '',
                        filterClassName: '', filterSubjectName: '' });
      this.getAssessmentList(this.state.center_id,this.state.filterCenter);
    }
    else {
      this.setState({ filterCenter: e.target.value, filterClass: '', filterSubject: '',
                        filterClassName: '', filterSubjectName: '',
                        filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value)) });
      this.getAssessmentList(this.state.center_id,e.target.value);
    }
  }

  classChange = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({ class_id: e.target.value, subject_id: '',classNameText:e.nativeEvent.target[index].text })
    this.getSubjectList2(this.state.class_centers[e.target.value], e.target.value)
  }

  subjectChange = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    this.setState({ subject_id: e.target.value,subjectName:e.nativeEvent.target[index].text })
  }

  sectionChange = (e) => {
    this.setState({ section_id: e.target.value })
  }

  scheduleChange = (e) => {
    this.setState({ scheduled_date: e.target.value })
  }

  dueChange = (e) => {
    this.setState({ due_date: e.target.value })
  }

  solutionChange = (e) => {
    this.setState({ solution_date: e.target.value })
  }

  resultChange = (e) => {
    this.setState({ result_date: e.target.value })
  }

  filterClassSubject = () => {
    if(this.state.filterClass !== '' && this.state.filterSubject !== '') {
      var assessments = this.state.assessments.filter(assessment => {
        return assessment?.classs === parseInt(this.state.filterClass) 
          && assessment?.subject_id === parseInt(this.state.filterSubject)
      });
      this.setState({ filterAssessments: assessments });
    }
    this.setState({ openf: false })
  }

  classChange2 = (e) => {
    if(e.target.value === '') {
      this.setState({ filterClass: '', filterSubject: '', filterClassName: '',
                        filterSubjectName: '' });
    }
    else {
      this.setState({ filterClass: e.target.value, filterSubject: '', filterSubjectName: '',
                        filterClassName: this.state.class_names[e.target.value],
                        class_center: this.state.class_centers[e.target.value] })
      this.getSubjectList(this.state.class_centers[e.target.value], e.target.value)
      this.getAssessmentList(this.state.center_id,this.state.filterSubject,e.target.value,1)
    }
  }

  subjectChange2 = (e) => {
    if(e.target.value === '') {
      this.setState({ filterSubject: '', filterSubjectName: '' })
    }
    else {
      var index = e.nativeEvent.target.selectedIndex;
      this.setState({openf:false,filterSubject: e.target.value,filterSubjectName :e.nativeEvent.target[index].text})
      this.getAssessmentList(this.state.center_id,e.target.value,this.state.filterClass,1)
      //this.setState({ filterSubject: e.target.value, filterSubjectName: this.state.subject_names[e.target.value] });
    }
  }

  removeFilters = () => {
    this.setState({ filterClass: '', filterSubject: '', filterClassName: '',
              filterSubjectName: '', filterAssessments: this.state.assessments, openf: false });
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  deleteAssessment = async () => {
    const { d_id } = this.state;
    await this.props.deleteAssignment(d_id);
    this.setState({ opend: false });
  }

  createDPP = async () => {
    const { id, title, filterClass, filterSubject, section_id, scheduled_date, solution_date,
              result_date, due_date, is_released ,planner_id} = this.state;


    if(planner_id){
      const response = await this.props.updateAssignmentPlanner(planner_id,id,parseInt(filterSubject),title,scheduled_date,scheduled_date,scheduled_date,scheduled_date,due_date,
        120,parseInt(filterClass),"","",parseInt(this.state.section_id),is_released,"");
    }

    else{
      const response = await this.props.assignmentPlanner(id,parseInt(filterSubject),title,scheduled_date,scheduled_date,scheduled_date,scheduled_date,due_date,
      120,parseInt(filterClass),"","",parseInt(this.state.section_id),is_released,"");
    }
      
    this.props.history.goBack()
    //this.setState({ schedule_step: true })
  }

  show4 = async(page) => {

    const response = await this.props.assignmentPlannerList(this.state.center_id,this.state.filterSubject,this.state.filterClass,false,page);
    if(response !== undefined) {
      /*this.setState({ assessments: response.data.filter(assessment => assessment.type === 2),
        filterAssessments: response.data.filter(assessment => assessment.type === 2) });*/
        this.setState({ assessments: response.data.data,filterAssessments: response.data.data});
    }
    this.setState({current_page:page});
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.show4(pageNumber);
    this.setState({current_page: pageNumber});
  }

  render() {
    const isEnabled = this.state.class_id !== '' && this.state.subject_id !== '';
    const isEnabledf = this.state.filterClass !== '' && this.state.filterSubject !== '';
    const isEnabled2 =this.state.scheduled_date?.length > 0 && this.state.due_date?.length > 0 && this.state.section_id;
    if(this.state.schedule_step) {
      return (
        <Redirect to={`/admin/scheduled-tests`}/>
      )
    }
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search assessments"/>
          <div className="main-part paginationCorrections">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <Link id="trace-back" to={`/admin/scheduled-tests`}>
                    <img src={Back} alt="back"/>
                  </Link>
                  <h2>{this.props.pagewiseLanguageData.assignments_select_assignments_schedule}</h2>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Header placeholder="Search assessments"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content5">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.select_class_and_subject}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select defaultValue={this.state.class_id} onChange={this.classChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.assign_class}
                </option>
                {this.state.filterClasses.map((classs, index) =>
                  <option value={classs.id} key={index}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select defaultValue={this.state.subject_id} onChange={this.subjectChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.assign_subject}
                </option>
                {this.state.subjects2.map((subject, index) =>
                  <option value={subject.id} key={index}>
                    {subject.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <Link style={!isEnabled ? { pointerEvents: 'none', opacity: 0.8, cursor: 'not-allowed' } 
            : { }} className="popup-actions" to={{ pathname: '/admin/new-assignment', state: { type: 2, 
            url: '/admin/dpp-scheduling', subject_id: this.state.subject_id,
            class_id: this.state.class_id , classname: this.state.classNameText ,subjectname: this.state.subjectName } }}>
            <button >{this.props.pagewiseLanguageData.next}</button>
          </Link>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content4-schedule">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.schedule}: {this.state.title}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data4-schedule">
            <div className="popup-inputs">
              <select defaultValue={this.state.section_id}  onChange={this.sectionChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_section}
                </option>
                {this.state.sections.map((section, index) =>
                  <option value={section.id} key={index} selected={section.id === this.state.section_id ? "selected":""} disabled={section.is_selected ? "disabled":""}>
                    {section.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs5">
              <span>{this.props.pagewiseLanguageData.scheduled_date}</span>
              <input type="datetime-local" defaultValue={this.state.scheduled_date} placeholder={this.props.pagewiseLanguageData.scheduled_date_time}
                onChange={this.scheduleChange}/>
            </div>
            <div className="popup-inputs5">
              <span>{this.props.pagewiseLanguageData.due_date}</span>
              <input type="datetime-local" defaultValue={this.state.due_date} placeholder={this.props.pagewiseLanguageData.due_date_time}
                onChange={this.dueChange}/>
            </div>
            {/*<div className="popup-inputs5">
              <span>Solution Date</span>
              <input type="datetime-local" placeholder="Solution Date & Time"
                onChange={this.solutionChange}/>
            </div>
            <div className="popup-inputs5">
              <span>Result Date</span>
              <input type="datetime-local" placeholder="Result Date & Time"
                onChange={this.resultChange}/>
            </div>*/}
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled2} onClick={this.createDPP}>{this.props.pagewiseLanguageData.schedule}</button>
          </div>
        </Popup>
        <Popup open={this.state.openf} closeOnDocumentClick onClose={this.closef}
          className="popup-content5">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.filter_with_class_and_subject}
          </div>
          <span className="close" onClick={this.closef}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select value={this.state.filterClass} onChange={this.classChange2}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_class}
                </option>
                {this.state.filterClasses.map((classs, index) =>
                  <option value={classs.id} key={index}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select value={this.state.filterSubject} onChange={this.subjectChange2}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_subject}
                </option>
                {this.state.subjects.map((subject, index) =>
                  <option value={subject.id} key={index}>
                    {subject.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="filter-buttons" Style="display:none">
            <span style={this.state.filterClass === '' && this.state.filterSubject === '' 
              ? {pointerEvents: 'none'} : {}} onClick={this.removeFilters}>{this.props.pagewiseLanguageData.revome_filters}</span>
            <button onClick={this.filterClassSubject} disabled={!isEnabledf}>
              {this.props.pagewiseLanguageData.save_filter}
            </button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt={this.props.pagewiseLanguageData.delete}/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteAssessment}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part paginationCorrections">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <Link id="trace-back" to={`/admin/assignment`}>
                  <img src={Back} alt={this.props.pagewiseLanguageData.back}/>
                </Link>
                <h2>{this.props.pagewiseLanguageData.assignments_select_assignments_schedule}</h2>
              </div>
            </div>
            <div className="heading-part2">
              <span onClick={this.show}>
                <img src={Add} alt={this.props.pagewiseLanguageData.add_new}/>{this.props.pagewiseLanguageData.add_new}
              </span>
              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt={this.props.pagewiseLanguageData.list_view}/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt={this.props.pagewiseLanguageData.grid_view}/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
            </div>
          </div>
          <div className="sub-filter">
            {!this.state.is_faculty_admin && <select defaultValue={parseInt(this.state.filterCenter)} 
              onChange={this.filterCenterChange}>
              {this.state.centers.map(center =>
                <option key={center.id} value={center.id}>{center.name}</option>
              )}
            </select>}
            {this.state.filterClass !== '' && this.state.filterClassName!=='' && this.state.filterSubjectName!=='' && this.state.filterSubject !== ''
              ? <div className="sub-filter-x" onClick={this.showf}>
              {this.state.filterClassName} | {this.state.filterSubjectName}
            </div> : <div className="sub-filter-x" onClick={this.showf}>
              {this.props.pagewiseLanguageData.filter_class_subject}
            </div>}
          </div>
          {this.state.filterAssessments.length === 0 ? <Empty/> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.title}
              </div>
              <div className="list-email">
              {this.props.pagewiseLanguageData.class}
              </div>
              <div className="list-email">
              {this.props.pagewiseLanguageData.subject}
              </div>
              <div className="list-action">
              {this.props.pagewiseLanguageData.actions}
              </div>
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.filterAssessments.map((assessment, index) =>
                <div className="list-item" key={assessment.id}>
                  <Link className="title-item" 
                    to={{pathname: `/admin/assignment-preview/${assessment.id}`,
                      state: {modal: false,subject:assessment.subject}}}>
                    {assessment.title}
                    <span>{assessment.is_published ? this.props.pagewiseLanguageData.published : this.props.pagewiseLanguageData.draft}</span>
                  </Link>
                  <div className="email-item">
                    {assessment?.classs}
                  </div>
                  <div className="email-item">
                    {assessment?.subject}
                  </div>
                  <div className="action-item">
                    <Link Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"} title="Edit" to={{ pathname: `/admin/new-assignment/${assessment.id}`,
                      state: {
                          url: this.props.location.pathname,
                          toc_id: null,
                          classname: assessment.classs,
                          subjectname: assessment.subject,
                          class_id:assessment.class_id,
                          subject_id:assessment.subject_id
                        }}
                      }>
                      <img src={Edit} alt="edit"/>
                    </Link>

                    <span Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"} title="Delete" onClick={() => this.showd(assessment.id)}>
                      <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                    </span>
                    {!assessment.planner_id ? <div id="section-button2" 
                        onClick={() => this.show2(assessment)}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.schedule}/>
                        {this.props.pagewiseLanguageData.schedule}
                      </div> : <div id="section-button2" onClick={() => this.show2(assessment)}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.reschedule}/>
                        {this.props.pagewiseLanguageData.reschedule}
                      </div>}
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.filterAssessments.map((assessment, index) =>
                <div className="grid-item" key={assessment.id}>
                  <div className="grid-up">
                    <Link className="title-grid" 
                      to={{ pathname: `/admin/assignment-preview/${assessment.id}`,
                        state: {modal: true,subject:assessment.subject} }}>
                      {assessment.title}
                      <span>{assessment.is_published ? this.props.pagewiseLanguageData.published : this.props.pagewiseLanguageData.draft}</span>
                    </Link>
                    <div className="grid-actions">
                    
                      <Link Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"} title="Edit" to={{ pathname: `/admin/new-assignment/${assessment.id}`,
                        state: {
                            url: this.props.location.pathname,
                            toc_id: null,
                            classname: assessment.classs,
                            subjectname: assessment.subject,
                            class_id:assessment.class_id,
                            subject_id:assessment.subject_id
                          }}
                        }>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </Link>
                      <span Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"} title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(assessment.id)} >
                          <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                        </span>
                    </div>
                  </div>
                  <div className="grid-down">
                    <div className="grid-actions">
                    <Link className='increaseAttempt' id={assessment.id} to={{pathname: `/admin/assignment-increase-attempt/`,
                        state: {classID:assessment.class_id, assessmentID: assessment.id}}}>
                        Increase Attempt
                      </Link>
                      {!assessment.planner_id ? 
                      <div id="section-button2" 
                        onClick={() => this.show2(assessment)}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.schedule}/>
                        {this.props.pagewiseLanguageData.schedule}
                      </div> : <div id="section-button2" onClick={() => this.show2(assessment)}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.reschedule}/>
                        {this.props.pagewiseLanguageData.reschedule}
                      </div>}
                    </div>
                  </div>
                </div>
              )}
            </div>}
          </>}
        </div>
         <Pagination
          activePage={this.state.current_page}
          itemsCountPerPage={20}
          totalItemsCount={this.state.totalItems}
          pageRangeDisplayed={10}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentList, deleteAssessment, getClassList2, getSubjectList2,
            createDPP, getSectionList2, assignmentPlannerList,getCenterList ,facultyAssignmentList ,deleteAssignment,assignmentPlanner,updateAssignmentPlanner,getPageLanguageWiseData} = actions;
  return {
    getCenterList: () => dispatch(getCenterList()),
    createDPP: (assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date) => dispatch(createDPP(assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date)),
    getAssessmentList: (center) => dispatch(getAssessmentList(center)),
    deleteAssessment: (id) => dispatch(deleteAssessment(id)),
    deleteAssignment : (id) => dispatch(deleteAssignment(id)),
    getClassList2: () => dispatch(getClassList2()),
    facultyAssignmentList : (center_id,id,class_id) => dispatch(facultyAssignmentList(center_id,id,class_id)),
    assignmentPlanner : (assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty) => dispatch(assignmentPlanner(assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty)),
    updateAssignmentPlanner : (id,assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty) => dispatch(updateAssignmentPlanner(id,assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty)),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getSubjectList2: (center_id,class_id) => dispatch(getSubjectList2(center_id,class_id)),
    assignmentPlannerList : (center_id,subject_id,class_id,is_loading,page) => dispatch(assignmentPlannerList(center_id,subject_id,class_id,is_loading,page)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAssignment);