import commonActions from './commonActions';
import getActions from './getActions';
import postActions from './postActions';
import classroomActions from './classroomActions';

export default {
  ...classroomActions,
	...commonActions,
  ...getActions,
  ...postActions
}