import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Redirect } from 'react-router-dom';
import ReactNotifications, { store } from 'react-notifications-component';
import common from '../../common';
import actions from '../../redux/actions';
import ImgHolder from '../../public/icons/doubt-image.svg';
import More from '../../public/icons/more.svg';
import 'react-notifications-component/dist/theme.css';
import askicon from '../../public/icons/mycoach-ask-icon.png';
import './styles.css';

class AskDoubts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posted: false,
      question: null,
      subjects: [],
      no_data: false,
      subject: null,
      description: '',
      image: null,
      imageURL: null,
      course_id:null,
      classes:[],
      select_course:null,
      pathname:null,
      profile:''
    }
  }

  componentDidMount() {
    this.setState({loading:false,pathname:window.location.pathname.split('/').pop()})
  }

  show = () => this.setState({ open: true, description: '', image: null, imageURL: null,
                                subject: null });
  
  close = () => this.setState({ open: false });

  handleChange = (event) => {
		if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({ 
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('doubt_img').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.saveLanguageWiseData.images_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('doubt_img').value = "";
  }

  onSubjectChange = (val) => {
    this.setState({ subject: val });
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  }

 postDoubt = async () => {
    const { subject, description, image,course_id } = this.state;
    //const response = await this.props.postDoubt(subject, description, image, null);
    const getideaspaceId = localStorage.getItem('ideaspaceid');
    console.log("getideaspaceId" + getideaspaceId);
    if(getideaspaceId){
      console.log("enter if dounbts")
      const response = await this.props.postDoubt(null, description, image, null,this.state.select_course,this.state.pathname =="doubts" ?1:2);
      if(response !== undefined) {
        this.setState({ posted: true, question: response.data, open: false });
      }
    }else{
      console.log("enter else dounbts")
      const response = await this.props.postDoubt(null,description, image, null,this.state.select_course,this.state.pathname == "doubts" ?1:2); 
      if(response !== undefined) {
        this.setState({ posted: true, question: response.data, open: false });
      }
    }
    
  }

  selectCourse =(e) =>{
    this.setState({select_course:e.target.value})
  }

	render() {
    const { posted, question } = this.state;
    //const isEnabled = this.state.subject !== null && this.state.description.length > 0;
    const isEnabled = this.state.description.length > 0;
    if(this.state.loading) {
      return (
        <div>
        </div>
      )
    }
		return (
			<div className="ask-doubt">
        {posted && question !== null && <Redirect 
          to={{ pathname: `/learner/doubt-responses/${question.id}`,
          state: { question: question } }}/>}
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-doubt">
          <ReactNotifications/>
          <div className="popup-ask-doubt">
            <div className="popup-header">
             {this.props.saveLanguageWiseData.share_thoughts}
            </div>
            <span className="close" onClick={this.close}>&#x2715;</span>
            <div className="ask-doubt-content">
              <div className="popup-doubt-subjects" Style="display:none;">
                {this.state.subjects && this.state.subjects.map(subject =>
                  <div key={subject.id} onClick={() => this.onSubjectChange(subject.id)}
                    id={this.state.subject === subject.id ? 'selected-dsubject' : ''}>
                    {subject.label}
                  </div>
                )}
              </div>
              <div>
                <select onChange={this.selectCourse} defaultValue={this.state.select_course}> 
                  <option value="">--{this.props.saveLanguageWiseData.select_course} --</option>
                  {this.props.logindetails.batches && this.props.logindetails.batches.map(course =>
                    <option value={course.classs.id}>{course.classs.label}</option>
                  )}
                </select>
              </div>
              <div className="doubt-content-div">
                <input style={{display: 'none'}} accept="image/*" type="file"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}
                  id="doubt_img"/>
                <div className="image-upload">
                  <span>{this.props.saveLanguageWiseData.add_an_image}</span>
                  {this.state.imageURL === null
                    ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput.click()}/>
                    : <div className="post-doubt-img"
                      style={{backgroundImage: "url("+this.state.imageURL+")"}}>
                      <span className="close" onClick={this.removeImage}>&#x2715;</span>
                  </div>}
                </div>
                <textarea placeholder={this.props.saveLanguageWiseData.start_typing} 
                  onChange={this.onDescriptionChange}/>
              </div>
            </div>
            <div className="ask-doubt-action">
              <button disabled={!isEnabled} onClick={this.postDoubt}>
                {this.props.saveLanguageWiseData.submit}
              </button>
            </div>
          </div>
        </Popup>
        <div title="Ask Doubt" className="askdoubt-button" onClick={this.show}>
          <img src={askicon} alt="ask ffffff"/>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    logindetails:state.commonReducer.logindetails,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const {postDoubt } = actions;
  return {
    postDoubt: (subject_id, description, media, doubt_forum_id,class_id,type) => dispatch(postDoubt(subject_id, description, media, doubt_forum_id,class_id,type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AskDoubts);