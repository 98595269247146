import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Helmet } from "react-helmet";
import { store } from 'react-notifications-component';
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
import  { Redirect } from 'react-router-dom'
import ReactNotifications from 'react-notifications-component';
import mycoachicon1 from '../../public/icons/mycoach-icon1.png';
import actions from '../../redux/actions';
import Profile from '../../public/icons/profile.svg';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Empty from '../../components/error/Empty';
import common from '../../common';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import PaidCourse from '../../public/icons/paid_course_csknew.png';

import Slider from "react-slick";
import AssessmentPreview from '../../components/study-material/CommonComponents';
import subscribeicon from '../../public/icons/mycoach-subscribed-icon.png';
import arrowicon from '../../public/icons/mycoach-arrow-icon.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import './styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tickicon from '../../public/icons/iconfinder_Tick_Mark_Circle_1398913.png';
import shareicon from '../../public/icons/share-icon.png';
import WidgetPreview from '../widget/WidgetPreview'
//import referralrightbg from '../../public/images/referral-rightbg1.png';


class WidgetDashboard extends Component {
  constructor(props) {
    super(props);
    this.state ={
      widgetList:[],
      widgetIds :[],
      widgetdata:{},
      widgetSettings:[],
      widgetSettingsCss:[],
      widgetRightAlign:[],
      widgetLeftAlign:[],
      widgetSettingsConfig:[],
      questions:[],
      loading:true,
      open_refer:false,
      isEnabled:false,
      selected_list_id:null,
      widget_form_data:[],
      username:localStorage.getItem("name")??localStorage.getItem("username"),
      showModal: false,
      is_checked:false,
      onsave:false,
      errors:{},
      fields:{},
      widgetSettingAlignment:{}
    }
    //this.getWidgetData = this.getWidgetData.bind(this)
  }
  
  componentDidMount() {
    //this.instituteWidgetList();
  }

  render() {
    const {widgetSettingAlignment,widgetdata,widgetSettings,widgetSettingsCss,widgetSettingsConfig,loading,username,fields,errors} = this.state

    return (
        <div className="app-body widget-dashboard">
          <ReactNotifications/>
          <div className="app-div">
            <Helmet>
              <title>Dashboard - Learner</title>
            </Helmet>
            {/*Code start from here*/}
            <WidgetPreview/>
            {/*Code start from here*/}
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
    learnerprofile : state.commonReducer.logindetails,
    widget_alignment_alignment : state.commonReducer.widget_alignment_alignment,
    widget_alignment_size : state.commonReducer.widget_alignment_size,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const {switchCourse,bbbJoinLiveClass,getWidgetDataById,getAttemptPreview,widgetQuestionSubmit} = actions;
  return {
    getWidgetDataById : (id) => dispatch(getWidgetDataById(id)),
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    widgetQuestionSubmit : (questions) => dispatch(widgetQuestionSubmit(questions)),
    switchCourse: (batch_id) => dispatch(switchCourse(batch_id)),
    bbbJoinLiveClass:(session,name,meetingPassword,userId) => dispatch(bbbJoinLiveClass(session,name,meetingPassword,userId))
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetDashboard);