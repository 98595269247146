import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import ReactNotifications, { store } from 'react-notifications-component';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';

import common from '../../common';
import actions from '../../redux/actions';
import MonthTable from '../../components/temp';
import Add from '../../public/icons/add.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Calendar2 from '../../public/icons/calendar.svg';
import Calendar3 from '../../public/icons/calendar2.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import './styles.css';
import Back from '../../public/icons/back.svg';

let changeN = 0;

class ScheduledTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      id: null,
      loading: true,
      open: false,
      openc: false,
      open2: false,
      opend: false,
      center_id: localStorage.getItem('center_id'),
      branch: parseInt(localStorage.getItem('center_id')),
      title: '',
      assessments: [],
      filterAssessments: [],
      currentYear: new Date(),
			currentMonth: new Date(),
      selectedDate: new Date(),
      showCal: true,
      branches: [],
      classes: [],
      classes2: [],
      filterClasses: [],
      filterSections: [],
      filterSubjects: [],
      sections: [],
      filterClass: '',
      filterSection:"",
      filterSubject:"",
      class_centers: [],
      class_center: '',
      scheduled_date: '',
      solution_date: '',
      result_date: '',
      due_date: '',
      subjects: [],
      classs: '',
      subject: '',
      loading: true,
      start_date:"",
      current_date:"",
      hasMore:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      id:0,
      is_recent:0,
      is_faculty_admin:false,
      current_date_records:[],
      user_id: localStorage.getItem("user_id"),
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    document.addEventListener('mousedown', this.handleClickOutside);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
    this.setState({ start_date:date })

    this.getCurrentDate();
    this.getCenterList();
    this.getClassList();
    this.getDPPList(0,0,date, 15, 'forward', true, this.state.branch);
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ openc: false })
    }
  }

  getDPPList = async (subject_id,batch_id,date, days, direction, if_loading, center) => {
    console.log("Amit"+batch_id);
    const response = await this.props.getDPPList(subject_id,batch_id,date, days, direction, if_loading, center);
    if(response) {
      this.setState({ assessments: response.data.data, filterAssessments: response.data.data,
                  filterClass:this.state.filterClass, filterSection: this.state.filterSection, loading: false, loading: false, countBottom:1,
                  id: response.data.data ? response.data?.data[0]?.assessment : "NA" });

      this.ifRecordsExists();
      if(!response.data.data.length){

        this.setState({id:"NA"});

        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');
        
        this.getDPPListOnBackward(this.state.filterSubject,this.state.filterSection,currentDate, 15, 'backward', false, this.state.branch);
      }
    }
    else {
      this.setState({hasMore:false,loading: false})
    }
  }

  getDPPListOnForward = async (date, days, direction, if_loading, center) => {
    const response= await this.props.getDPPList(date, days, direction, if_loading, center);
    if(response) {
      this.setState({
        assessments: this.state.assessments ? this.state.assessments.concat(response.data.data) : response.data.data,
        loading: false, countBottom:1
      });
      if(this.state.filterClass === '') {
        this.setState({
          filterAssessments: this.state.filterAssessments 
            ? this.state.filterAssessments.concat(response.data.data) : response.data.data
        })
      }
      else if(this.state.filterSection === '' && this.state.filterSubject === '') {
        this.setState({
          filterAssessments: this.state.filterAssessments 
            ? this.state.filterAssessments.concat(response.data.data.filter(assessment => 
              assessment.classs.id === parseInt(this.state.filterClass))) 
            : response.data.data.filter(assessment => 
              assessment.classs.id === parseInt(this.state.filterClass))
        })
      }
      else if(this.state.filterSection !== '' && this.state.filterSubject === '') {
        this.setState({
          filterAssessments: this.state.filterAssessments 
            ? this.state.filterAssessments.concat(response.data.data.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.batch.id === parseInt(this.state.filterSection)))) 
              : response.data.data.filter(assessment => 
                (assessment.classs.id === parseInt(this.state.filterClass) &&
                assessment.batch.id === parseInt(this.state.filterSection)))
        })
      }
      else if(this.state.filterSection === '' && this.state.filterSubject !== '') {
        this.setState({
          filterAssessments: this.state.filterAssessments 
            ? this.state.filterAssessments.concat(response.data.data.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.subject.id === parseInt(this.state.filterSubject)))) 
              : response.data.data.filter(assessment => 
                (assessment.classs.id === parseInt(this.state.filterClass) &&
                assessment.subject.id === parseInt(this.state.filterSubject)))
        })
      }
      else if(this.state.filterSection !== '' && this.state.filterSubject !== '') {
        this.setState({
          filterAssessments: this.state.filterAssessments 
            ? this.state.filterAssessments.concat(response.data.data.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.batch.id === parseInt(this.state.filterSection) &&
              assessment.subject.id === parseInt(this.state.filterSubject)))) 
              : response.data.data.filter(assessment => 
                (assessment.classs.id === parseInt(this.state.filterClass) &&
                assessment.batch.id === parseInt(this.state.filterSection) &&
                assessment.subject.id === parseInt(this.state.filterSubject)))
        })
      }
    }
    else{
      this.setState({ hasMore:false, loading: false })
    }
  }

  getDPPListOnBackward = async (subject_id,batch_id,date, days, direction, if_loading, center) => {
    const response= await this.props.getDPPList(subject_id,batch_id,date, days, direction, if_loading, center);
    if(response){
      this.setState({
        assessments: response.data.data ? response.data.data.concat(this.state.assessments):this.state.assessments,
        loading: false, countTop:1
      });

     this.setState({
        filterAssessments: response.data.data ? response.data.data.concat(this.state.filterAssessments) 
          : this.state.filterAssessments,loading: false, countTop:1
     })
      
      /*if(this.state.filterClass === '') {
        this.setState({
          filterAssessments: response.data.data ? response.data.data.concat(this.state.filterAssessments) 
            : this.state.filterAssessments
        })
      }
      else if(this.state.filterSection === '' && this.state.filterSubject === '') {
        this.setState({
          filterAssessments: response.data.data 
            ? response.data.data.filter(assessment => 
              assessment.classs.id === parseInt(this.state.filterClass)).concat(this.state.filterAssessments.filter(assessment => 
              assessment.classs.id === parseInt(this.state.filterClass))) 
            : this.state.filterAssessments.filter(assessment => 
              assessment.classs.id === parseInt(this.state.filterClass))
        })
      }
      else if(this.state.filterSection !== '' && this.state.filterSubject === '') {
        this.setState({
          filterAssessments: response.data.data 
            ? response.data.data.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.batch.id === parseInt(this.state.filterSection))).concat(this.state.filterAssessments.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.batch.id === parseInt(this.state.filterSection)))) 
              : this.state.filterAssessments.filter(assessment => 
                (assessment.classs.id === parseInt(this.state.filterClass) &&
                assessment.batch.id === parseInt(this.state.filterSection)))
        })
      }
      else if(this.state.filterSection === '' && this.state.filterSubject !== '') {
        this.setState({
          filterAssessments: response.data.data 
            ? response.data.data.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.subject.id === parseInt(this.state.filterSubject))).concat(this.state.filterAssessments.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.subject.id === parseInt(this.state.filterSubject)))) 
              : this.state.filterAssessments.filter(assessment => 
                (assessment.classs.id === parseInt(this.state.filterClass) &&
                assessment.subject.id === parseInt(this.state.filterSubject)))
        })
      }
      else if(this.state.filterSection !== '' && this.state.filterSubject !== '') {
        this.setState({
          filterAssessments: response.data.data 
            ? response.data.data.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.batch.id === parseInt(this.state.filterSection) &&
              assessment.subject.id === parseInt(this.state.filterSubject))).concat(this.state.filterAssessments.filter(assessment => 
              (assessment.classs.id === parseInt(this.state.filterClass) &&
              assessment.batch.id === parseInt(this.state.filterSection) &&
              assessment.subject.id === parseInt(this.state.filterSubject)))) 
              : this.state.filterAssessments.filter(assessment => 
                (assessment.classs.id === parseInt(this.state.filterClass) &&
                assessment.batch.id === parseInt(this.state.filterSection) &&
                assessment.subject.id === parseInt(this.state.filterSubject)))
        })
      }*/

      this.ifRecordsExists();
      if(this.state.id)
        this.handleScrollPos();
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  show = () => this.setState({ open: true, class_id: '', subject_id: '', section_id: '' });
  
  close = () => this.setState({ open: false });

  show2 = async (val) => {
    const response = await this.props.getSectionList2(this.state.branch, val.classs.id);
    this.setState({ open2: true, id: val.assessment, scheduled_date: val.scheduled_date, 
                      solution_date: val.solution_date, result_date: val.result_date, 
                      due_date: val.due_date, title: val.title.label, 
                      section_id: val.batch.id, sections: response.data });
  }
  
  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  branchChange = (e) => {
    this.setState({ branch: e.target.value, classes2: this.state.classes.filter(classs => classs.center === parseInt(e.target.value))
            ,filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value))
            ,filterSections: [], subjects: [], sections: [] ,classs: '', filterClass: '', 
            filterSection: '', section: '', subject: ''
           });
    this.getDPPList(this.state.start_date, 15, 'forward', true, parseInt(e.target.value));
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }

  getClassList = async () => {
		const response = await this.props.getClassList2();
		if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ),
        classes2: response.data.filter(classs => classs.center === parseInt(this.state.center_id)),
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
    }
  }

  getSectionList2 = async (classs) => {
    if(classs === '') {
      this.setState({ filterSections: [] });
    }
    else {
      const response = await this.props.getSectionList2(this.state.branch, classs);
      if(response !== undefined) {
        this.setState({ filterSections: response.data });
      }
    }
  }

  getSubjectList = async (classs) => {
    if(classs === '') {
      this.setState({ subjects: [] });
    }
    else {
      const response = await this.props.getSubjectList2(this.state.branch,classs);
      if(classs !== undefined && classs !== '') {
        var arr = response.data.filter(subject => subject.class_details.id === parseInt(classs));
        this.setState({ subjects: arr });
      }
    }
  }

  getSubjectList2 = async (class_center, class_id) => {
    if(class_id === '') {
      this.setState({ filterSubjects: [] });
    }
    else {
      const response = await this.props.getSubjectList2(class_center,class_id);
      if(response !== undefined) {
        this.setState({ filterSubjects: response.data.filter(subject => subject.class_details.id === parseInt(class_id)) });
      }
    }
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

   this.setState({start_date:date,current_date:calendarDate,is_recent:0,loading:true,countTop:0,filterSection:0,filterSubject:0})

   this.getCurrentDate();
   this.getDPPList(0,0,date, 15, 'forward', true, this.state.branch);
   //this.getDPPList(date, 15, 'forward', true, this.state.branch);
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date(this.state.current_date.split(' ').join('-'));

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      cd.getMonth(),
      d.getMonth()
    ];
    return date;
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  onChange = value => {
    var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');


    this.setState({ hasMore: false,countTop:0,current_date:calendarDate,assessments:[],
                      filterAssessments: []});
    this.setState({openc:false})

    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });
    this.setState({start_date:date,loading:true,is_recent:1})
    
    this.getDPPList(this.state.filterSubject,this.state.filterSection,date, 15, 'forward', true, this.state.branch);
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ current_date: date, date: currentDate});
  }


  openCalendar=()=>{
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();

    if(calendarMonth<currentMonth){
      document.getElementsByClassName("react-calendar__navigation__next-button")[0].click();
    }
    else if(calendarMonth>currentMonth){
      document.getElementsByClassName("react-calendar__navigation__prev-button")[0].click();
    }
    
    if(this.state.open===true)
      this.setState({openc:false})
    if(this.state.open===false)
      this.setState({openc:true})
  }

  handleScroll = (e) => { 
    var scrolltop=[];
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('sch-test');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.filterAssessments && this.state.filterAssessments.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){
          this.setState({current_date:this.getTime(test.due_date)[4]});
        }
      });
    }

    const top = e.target.scrollTop===0;
    if(top){
      if(this.state.countTop===0){
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getDPPListOnBackward(this.state.filterSubject,this.state.filterSection,currentDate, 15, 'backward', false, this.state.branch);
      }
    }
  }

  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) +' HRS':minutes +' mins';
  }

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }
	
	goToday = (goToSlide) => {
		this.setState({
			currentMonth: new Date(),
      selectedDate: new Date()
		});
		let date2 = (new Date()).getDate();
		let slide = date2 - 1;
    goToSlide(slide); 
    var date = new Date();
    var datex = date.getDate();
    if(datex < 10) {
      datex = '0' + datex;
    }
    var month = date.getMonth() + 1;
    if(month < 10) {
      month = '0' + month;
    }
		var apiDate = `${date.getFullYear()}-${month}-${datex}T00:00:00`;
    this.getDPPList(date, 15, 'forward', true, this.state.branch)
  }

  onClassChange = (e) => {
    this.setState({ classs: e.target.value, subject: '' });
    this.getSubjectList(e.target.value);
  }
  
  onClassChange2 = (e) => {
    if(e.target.value === '') {
      this.setState({ filterClass: e.target.value, filterSections: [], filterSubjects: [] });
      this.setState({ filterAssessments: this.state.assessments });
    }
    else {
      this.setState({ filterClass: e.target.value, filterSection: '', filterSubject: '' })
      this.getSectionList2(e.target.value);
      this.getSubjectList2(this.state.class_centers[e.target.value], e.target.value);
      /*this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                        assessment.classs.id === parseInt(e.target.value)) });*/
    }
  }

  onSectionChange2 = (e) => {
    /*if(e.target.value === '') {
      this.setState({ filterSection: e.target.value });
      if(this.state.filterClass === '') {
        this.setState({ filterAssessments: this.state.assessments });
      }
      else if(this.state.filterSubject === '') {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                          assessment.classs.id === parseInt(this.state.filterClass)) });
      }
      else if(this.state.filterSubject !== '') {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                          (assessment.classs.id === parseInt(this.state.filterClass) &&
                            assessment.subject.id === parseInt(this.state.filterSubject))) });
      }
    }
    else {
      this.setState({ filterSection: e.target.value });
      if(this.state.filterSubject === '') {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                          (assessment.classs.id === parseInt(this.state.filterClass) &&
                            assessment.batch.id === parseInt(e.target.value))) });
      }
      else {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                        (assessment.classs.id === parseInt(this.state.filterClass) &&
                          assessment.subject.id === parseInt(this.state.filterSubject) &&
                          assessment.batch.id === parseInt(e.target.value))) });
      }
    }*/

    this.setState({filterSection: e.target.value,is_recent:1});
    this.setState({ hasMore: false,countTop:0});
    this.getDPPList(this.state.filterSubject,e.target.value,this.state.start_date, 15, 'forward', false, this.state.branch);
  }

  onSubjectChange2 = (e) => {
    /*if(e.target.value === '') {
      this.setState({ filterSubject: e.target.value });
      if(this.state.filterClass === '') {
        this.setState({ filterAssessments: this.state.assessments });
      }
      else if(this.state.filterSection === '') {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                          assessment.classs.id === parseInt(this.state.filterClass)) });
      }
      else if(this.state.filterSection !== '') {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                          (assessment.classs.id === parseInt(this.state.filterClass) &&
                            assessment.batch.id === parseInt(this.state.filterSection))) });
      }
    }
    else {
      this.setState({ filterSubject: e.target.value });
      if(this.state.filterSection === '') {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                          (assessment.classs.id === parseInt(this.state.filterClass) &&
                            assessment.subject.id === parseInt(e.target.value))) });
      }
      else {
        this.setState({ filterAssessments: this.state.assessments.filter(assessment => 
                        (assessment.classs.id === parseInt(this.state.filterClass) &&
                          assessment.subject.id === parseInt(e.target.value) &&
                          assessment.batch.id === parseInt(this.state.filterSection))) });
      }
    }*/
    this.setState({filterSubject: e.target.value,is_recent:1});
    this.setState({ hasMore: false,countTop:0});
    this.getDPPList(e.target.value,this.state.filterSection,this.state.start_date, 15, 'forward', false, this.state.branch);
  }

  onSubjectChange = (e) => {
    this.setState({ subject: e.target.value })
  }

  scheduleChange = (e) => {
    this.setState({ scheduled_date: e.target.value })
  }

  dueChange = (e) => {
    this.setState({ due_date: e.target.value })
  }

  solutionChange = (e) => {
    this.setState({ solution_date: e.target.value })
  }

  resultChange = (e) => {
    this.setState({ result_date: e.target.value })
  }

  editDPP = async () => {
    const { id, scheduled_date, solution_date, result_date, due_date } = this.state;
    await this.props.editDPP(id, scheduled_date, solution_date, result_date, due_date, 
        new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0]);
    this.setState({ open2: false });
  }

  deleteDPP = async () => {
    const { d_id } = this.state;
    await this.props.deleteDPP(d_id);
    this.setState({ opend: false });
  }


  ifRecordsExists = () =>{
    var val=this.state.start_date;
    const month=this.state.mlist;

    var d = new Date(val);
    var arr=[];
    var map = {};
    var temparray=new Array();

    for(let i=d.getMonth(); i<12;i++){

      let contentDate = [
        d.getFullYear(),
        ('0' + (i+1)).slice(-2)
      ].join('-');

      this.state.filterAssessments && this.state.filterAssessments.some(function(test,index) {
        
        if(i <= new Date(test.due_date).getMonth()){
          
          map[month[i]]=new Date(contentDate).getMonth() === new Date(test.due_date).getMonth()
                        && new Date(contentDate).getFullYear() === new Date(test.due_date).getFullYear()?1:0
          let obj ={"month":month[i],"status":map[month[i]],"year":"2020"}            
          temparray.push(obj);
          return true;
        }
      });
    }
    this.setState({current_date_records:temparray})
  }


	render() {
    const { value } = this.state.start_date;
    const isEnabled = this.state.classs !== '' && this.state.subject !== '';
    const isEnabled2 = this.state.scheduled_date.length > 0 && 
                        this.state.due_date.length > 0 && 
                        this.state.solution_date.length > 0 && 
                        this.state.result_date.length > 0;
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.scheduled_tests}</title>
        </Helmet>
				<Header placeholder="Search assessments"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content5">
          <div className="popup-header">
           {this.props.pagewiseLanguageData.select_class_and_subject}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select defaultValue={this.state.classs} onChange={this.onClassChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.assign_class}
                </option>
                {this.state.classes2.map((classs, index) =>
                  <option value={classs.id} key={index}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select defaultValue={this.state.subject} onChange={this.onSubjectChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.assign_subject}
                </option>
                {this.state.subjects.map((subject, index) =>
                  <option value={subject.id} key={index}>
                    {subject.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <Link style={!isEnabled ? { pointerEvents: 'none', opacity: 0.8, cursor: 'not-allowed' } 
            : { }} className="popup-actions" to={{ pathname: '/admin/new-assessment', state: { type: 2, 
            url: '/admin/dpp-scheduling', subject_id: this.state.subject,
            class_id: this.state.classs } }}>
            <button >{this.props.pagewiseLanguageData.next}</button>
          </Link>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content4">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.reschedule}: {this.state.title}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data4">
            <div className="popup-inputs">
              <select value={this.state.section_id}>
                {this.state.sections.map((section, index) => {
                  if(parseInt(section.id) === parseInt(this.state.section_id)) {
                    return <option value={section.id} key={index}>
                      {section.label}
                    </option>
                  }
                })}
              </select>
            </div>
            <div className="popup-inputs5">
              <span>{this.props.pagewiseLanguageData.scheduled_date}</span>
              <input type="datetime-local" placeholder={this.props.pagewiseLanguageData.scheduled_date_time}
                onChange={this.scheduleChange} defaultValue={this.state.scheduled_date}/>
            </div>
            <div className="popup-inputs5">
              <span>{this.props.pagewiseLanguageData.due_date}</span>
              <input type="datetime-local" placeholder={this.props.pagewiseLanguageData.due_date_time}
                onChange={this.dueChange} defaultValue={this.state.due_date}/>
            </div>
            <div className="popup-inputs5">
              <span>{this.props.pagewiseLanguageData.solution_date}</span>
              <input type="datetime-local" placeholder={this.props.pagewiseLanguageData.solution_date_time}
                onChange={this.solutionChange} defaultValue={this.state.solution_date}/>
            </div>
            <div className="popup-inputs5">
              <span>{this.props.pagewiseLanguageData.result_date}</span>
              <input type="datetime-local" placeholder={this.props.pagewiseLanguageData.result_date_time}
                onChange={this.resultChange} defaultValue={this.state.result_date}/>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled2} onClick={this.editDPP}>{this.props.pagewiseLanguageData.reschedule}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt={this.props.pagewiseLanguageData.delete}/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteDPP}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="">
					<div className="sub-head newdesign">
						<div className="heading-part1">
              
							<div className="heading">
								<h1>{this.props.pagewiseLanguageData.scheduled_tests}</h1>
							</div>
						</div>
						<div className="heading-part2">
              {!this.state.is_faculty_admin && <select value={this.state.branch} onChange={this.branchChange}>
                {this.state.branches.map((branch, index) =>
                  <option value={branch.id} key={index}>
                    {branch.name}
                  </option>
                )}
              </select>}
              <span onClick={this.show}>
								<img src={Add} alt={this.props.pagewiseLanguageData.add_new}/>{this.props.pagewiseLanguageData.add_new}
							</span>
              <Link to={{ pathname: '/admin/dpp-scheduling' }}>
								<img src={Calendar2} alt={this.props.pagewiseLanguageData.schedule_existing}/>{this.props.pagewiseLanguageData.schedule_existing}
              </Link>
						</div>
					</div>
          <div className="admin-calendar">
            <div className="cal-buttons">
              <div className="cal-header">
                <div className="header-op">
                  <div className="react-calendar-date" onClick={this.openCalendar}
                    ref={this.wrapperRef}>
                    {this.state.current_date}
                    <span>
                      <img src={Calendar3} alt="cal"/>
                    </span>
                  </div>
                  {this.state.is_recent ? <button onClick={this.switchToRecent}>
                    {this.props.pagewiseLanguageData.today}
                  </button> : null}
                </div>
                <div className="header-op2">
                  <select value={this.state.filterClass} onChange={this.onClassChange2}>
                    <option value={''}>{this.props.pagewiseLanguageData.class}</option>
                    {this.state.filterClasses.map(classs =>
                      <option value={classs.id} key={classs.id}>
                        {classs.label}
                      </option>
                    )}
                  </select>
                  <select value={this.state.filterSection} onChange={this.onSectionChange2}>
                    <option value={''}>{this.props.pagewiseLanguageData.section}</option>
                    {this.state.filterSections.map(section =>
                      <option value={section.id} key={section.id}>
                        {section.label}
                      </option>
                    )}
                  </select>
                  <select value={this.state.filterSubject} onChange={this.onSubjectChange2}>
                    <option value={''}>{this.props.pagewiseLanguageData.subject}</option>
                    {this.state.filterSubjects.map(subject =>
                      <option value={subject.id} key={subject.id}>
                        {subject.label}
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>
				  </div>
          <div className="react-calendar1" Style={this.state.openc ? "display:block" 
            : "display:none"} ref={this.wrapperRef}>
            <Calendar
              onChange={this.onChange}
              value={value}
            />
          </div>

        

          { this.state.filterAssessments && this.state.filterAssessments.length <= 2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
          {!this.props.loading && !this.state.loading && <>
             <div 
              className="lecture-list-x2" onScroll={this.handleScroll}>

              {this.state.filterAssessments.length === 0 &&  <Empty/>}

              <div className="list-content">
              {this.state.current_date_records && this.state.current_date_records.map((data,index)=>
                  <div>
                    { data.status==0 ? 
                      <div><div className="dash-test-first">{data.month} {data.year}</div><div className="dash-test-no-found">{this.props.pagewiseLanguageData.no_tests_planned}</div></div>:""}
                  </div>
              )}

              {this.state.filterAssessments.map((assessment, index) => {
                return <div className="sch-test" key={`${assessment.assessment}_${index}`}>
                    <div className="sch-left">
                      <span>{(new Date(assessment.due_date)).getDate()}</span>
                      {MonthTable[(new Date(assessment.due_date)).getMonth() + 1]}
                    </div>
                    <div className="sch-right" id={assessment.assessment === this.state.id
                      ? "conter" : ""}>
                      <Link to={{pathname: `/admin/assessment-preview/${assessment.title.id}`,
                        state: {modal: false}}}>
                        {assessment.title.label}
                        <span>{assessment.classs.label} | {assessment.batch.label}&nbsp;&nbsp;&nbsp;
                        {this.props.pagewiseLanguageData.subject} - {assessment.subject.label}</span>
                      </Link>
                      <div className="sch-options">
                        <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(assessment)} Style={assessment.created_by != this.state.user_id && assessment.assigned_by != this.state.user_id  && !localStorage.getItem('is_admin') ? "display:none":""}>
                          <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                        </span>
                        <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(assessment.assessment)} Style={assessment.created_by != this.state.user_id && assessment.assigned_by != this.state.user_id  && !localStorage.getItem('is_admin') ? "display:none":""}>
                          <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                        </span>
                      </div>
                    </div>
                  </div>
                }
              )}{ this.state.filterAssessments && this.state.filterAssessments.length>2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
            </div></div>
          </>}
          
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getClassList2, getSectionList2, getDPPList, getSubjectList2,
            getCenterList, deleteDPP, editDPP,getPageLanguageWiseData } = actions;
  return {
    deleteDPP: (id) => dispatch(deleteDPP(id)),
    editDPP: (assessment, scheduled_date, solution_date, result_date, due_date, release_date) => dispatch(editDPP(assessment, scheduled_date, solution_date, result_date, due_date, release_date)),
    getCenterList: () => dispatch(getCenterList()),
    getDPPList: (subject_id,batch_id,date, days, direction, if_loading, center) => dispatch(getDPPList(subject_id,batch_id,date, days, direction, if_loading, center)),
    getClassList2: () => dispatch(getClassList2()),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getSubjectList2: (center_id,class_id) => dispatch(getSubjectList2(center_id,class_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledTests);