import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './mycoach.css';
import HeaderMyCoach from './HeaderMyCoach';
import { CircularProgressbar , buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import comingsoonbanner from '../../public/images/my-percentage-coming-soon-banner.jpg'

class MyPercentage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      myBannerdata:[],
      profile: {},
   }
  }
  
   componentDidMount() {
    localStorage.setItem("page","liveprojects");
    
  }

  
	render() {
    const percentage = 66;
    return (
      <>
      <ReactNotifications/>
      {!this.state.is_webview && <HeaderMyCoach/>}
        <div className="app-body">
          <div className="app-div mycoach-div">
            <Helmet>
              <title>My Percentage - Learner</title>
            </Helmet>
            <div className="mycoach-wrapper">
                <div className="comingsoon">
                  <h3>My Percentage</h3>
                  <div className="banner-block"> <img src={comingsoonbanner} /></div>
                 
                </div>
                <div className="mypercentage-block" Style="display:none;">
                    <h3>My Percentage <CircularProgressbar styles={buildStyles({
                      strokeLinecap: 'butt',
                      textSize: '25px',
                      textColor: '#000',
                      trailColor: '#d6d6d6',
                      backgroundColor: '#1D73F2',
                    })}  value={percentage} text={`${percentage}%`} /></h3>
                    <div className="design-block">
                      <h3>Details View</h3>
                      <ol>
                      <li>
                          <div className="percentage-value">10</div>
                          <div className="percentage-title">D.S</div>
                      </li>
                      <li>
                          <div className="percentage-value">12</div>
                          <div className="percentage-title">T.S</div>
                      </li>
                      <li>
                          <div className="percentage-value">5</div>
                          <div className="percentage-title">B.S</div>
                      </li>
                      <li>
                          <div className="percentage-value">3</div>
                          <div className="percentage-title">E.S</div>
                      </li>
                      <li>
                          <div className="percentage-notvalue">Not Started</div>
                          <div className="percentage-title">P & A</div>
                      </li>
                      <li>
                          <div className="percentage-value">5</div>
                          <div className="percentage-title">CSI</div>
                      </li>
                      <li>
                          <div className="percentage-value">5</div>
                          <div className="percentage-title">Build Resume</div>
                      </li>
                      <li>
                          <div className="percentage-value">7</div>
                          <div className="percentage-title">Get Certification</div>
                      </li>
                            
                        
                      </ol>
                    </div>
                </div>
              </div>
            </div>
          </div>
        
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage
   
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getCertificationBannerList} = actions;
  return {
    getCertificationBannerList: () => dispatch(getCertificationBannerList()),
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyPercentage);