import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';

import 'react-notifications-component/dist/theme.css';
import './styles.css';


import addFile from '../../public/icons/add-file.png';



class StudioProjectDetailLearner extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
     
      loading: true,
      projectTitle:'',
      projectScheduledDate:'',
      projectDueDate:'',
      projectID:'',
      uploadFilePath:'',
      uploadFilePathURL:'',
      attemptData:[]
      
      
    }
   
  }

  componentDidMount() {
    console.log(this.props.location.state);
    let getProjectTitle = this.props.location.state.projectTitle;
    let getProjectScheduledDate = this.props.location.state.projectScheduledDate;
    let getProjectDueDate = this.props.location.state.projectDueDate;
    let getProjectIDFromURL = this.props.match.params.id;
    let getProjectID = getProjectIDFromURL;
    
    console.log(getProjectIDFromURL);


    
    
     if(getProjectTitle){
       this.setState({projectTitle: getProjectTitle})
     }
     if(getProjectScheduledDate){
      this.setState({projectScheduledDate: getProjectScheduledDate})
    }
    if(getProjectDueDate){
      this.setState({projectDueDate: getProjectDueDate})
    }
    if(getProjectID){
      this.setState({projectID: getProjectID})
      this.studioProjectUserAttemptList(getProjectID);
    }
    
    
  }

  componentWillUnmount() {
    
  }


  studioProjectUserAttemptList = async (getProjectID) => {
    let {projectID} = this.state;
    console.log(projectID)
    const response =  await this.props.studioProjectUserAttemptList(getProjectID);
    if(response !== undefined) {
      console.log("attampt response data");
      console.log(response);
      this.setState({attemptData:response.data})
    }
  }

  getFormattedDocumentDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }


  uploadfileClick = () => {
    document.getElementById("fileInput").click();
  }

  uploadHandleChange =(e) => {
    console.log(e.target.files[0].name);
    console.log(e.target.files[0])
    console.log(e.target.files[0].type.split('/')[1]);

    if(e.target.files[0].type.split('/')[1] == "pdf" || e.target.files[0].type.split('/')[0] == "doc" || e.target.files[0].type.split('/')[0] == "docx"){
      this.setState({uploadFilePath: e.target.files[0].name})
      this.setState({uploadFilePathURL: e.target.files[0]});
      console.log("Right ext Uploaded");
    }else{
      alert("please upload pdf and docx");
    }

    
    
  }

  sendUploadData = async () => {
    let {projectID,uploadFilePath,uploadFilePathURL} = this.state;
    console.log(projectID)
    console.log(uploadFilePath)
    const response =  await this.props.studioProjectUserUploadFile(projectID,uploadFilePathURL);
    if(response !== undefined) {
      console.log("response data");
     
    }
  }
  

  render() {
    
    return (
      <>

      <div className="app-body studioProjectLearner" id="appBody" onScroll={this.handleScroll}>
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>Studio Project Details - Faculty</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>

            <div className='studioProjectWrapper'>
              <div className='studioProjectContainer'>

                
                <h3>Studio Project Learner</h3>
                  <div className='learnerView'>
                    <div className='viewLeft'>
                      <h3>{this.state.projectTitle}</h3>
                      <div className='projectDate'>Start Date - <span>{this.getFormattedDocumentDate(this.state.projectScheduledDate)}</span></div>
                      <div className='projectDate'>Due Date - <span>{this.getFormattedDocumentDate(this.state.projectDueDate)}</span></div>
                      {this.state.attemptData.marks_obtained ?
                      <div className='projectDate'>
                       Project Status -  <span>Reviewed</span>
                      </div>
                       : <div className='projectDate'>Project Status -  <span>Pending</span></div>}
                      {this.state.attemptData?.users_file && 
                        <div className='projectDate'>
                        Uploaded File - <span><a href={this.state.attemptData?.users_file} download target="_blank">{this.state.attemptData?.users_file}</a></span>
                        </div>
                      } 
                    </div>
                    {!this.state.attemptData.is_faculty_reviewed && (new Date(this.state.projectDueDate).getTime() > new Date().getTime()) && (new Date(this.state.projectScheduledDate).getTime() < new Date().getTime()) &&
                    <div className='viewRight'>
                        <div class="uploadBlock">
                        <input type="file" id={"fileInput"} onChange={(e) => this.uploadHandleChange(e)} style={{display: 'none'}}/>
                            <span onClick={() => this.uploadfileClick()}><img src={addFile}/><br/>upload file (PDF or DOC)</span>
                        </div>
                        {this.state.uploadFilePath && 
                        <div>{this.state.uploadFilePath} <button onClick={() => this.sendUploadData()}>Submit</button></div>
                        }
                    </div>
                    }
                </div>

              </div>  
            </div>
          
           
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getFacultyList,studioProjectUserUploadFile,studioProjectUserAttemptList} = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyList: (center_id,show_ext_dt) => dispatch(getFacultyList(center_id,show_ext_dt)),
    studioProjectUserUploadFile: (project_id,users_file) => dispatch(studioProjectUserUploadFile(project_id,users_file)),
    studioProjectUserAttemptList: (project_id) => dispatch(studioProjectUserAttemptList(project_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioProjectDetailLearner);