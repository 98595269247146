import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import { isEmpty } from 'lodash';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import notificationDefault from '../../public/icons/doubt-image.svg';
import queryString from 'query-string'
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import downarrow from  '../../public/icons/assignment-listing-arrow.png';
// import InfiniteScroll from "react-infinite-scroll-component";
import Pagination from "react-js-pagination";

class LearnerNotificationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      AllNotifications: [],
      UnreadNotifications: [],
      ReadNotifications: [],
      AllActiveClass: true,
      ReadActiveClass: false,
      UnreadActiveClass: false,
      allNotificationCurrentpage:1,
      allNotificationTotalItems:0,
      readNotificationCurrentpage:1,
      readNotificationTotalItems:0,
      unreadNotificationCurrentpage:1,
      unreadNotificationTotalItems:0,
      onLoad :false,
      previewDialogImage:'',
      previewDialogOpen:false
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {

   localStorage.setItem("page","notifications")
    
   this.getAllNotificationLogs(1);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  seeMore = async (index,id) =>{
    const elementBlock = document.querySelector("#block_"+index);
    
    if(elementBlock){
      if(elementBlock.classList.contains("open")){
        elementBlock.classList.remove("open");
      }else{
        elementBlock.classList.add("open");
        
        if(!elementBlock.classList.contains("open_notified_"+index)){
          const response = await this.props.userNotificationReads(id);
          // if(response){
          //   this.getUnreadReadNotificationLogs(0,1);
          // }
        }
        elementBlock.classList.add("open_notified_"+index);
      }
    }
  }

  getUnreadReadNotificationLogs = async (is_read,pagenumber) => {
    const response = await this.props.userNotificationLogs(is_read,pagenumber);
    
    if(response){
      this.setState({UnreadNotifications:response.data.data, unreadNotificationCurrentpage :response.data?.pagination?.current_page,unreadNotificationTotalItems: response.data?.pagination?.total_page * 10})
    }
  }


  getReadNotificationLogs = async (is_read,pagenumber) => {
    console.log("pagenumber",pagenumber)
    const response = await this.props.userNotificationLogs(is_read,pagenumber);
    
    if(response){
      this.setState({ReadNotifications:response.data.data, readNotificationCurrentpage :response.data?.pagination?.current_page,readNotificationTotalItems: response.data?.pagination?.total_page * 10})
    }
  }


  getAllNotificationLogs = async (pageNumber) => {
    const response = await this.props.AllUserNotificationLogs(pageNumber);
    if(response){
      this.setState({AllNotifications:response.data.data, allNotificationCurrentpage :response.data?.pagination?.current_page,allNotificationTotalItems: response.data?.pagination?.total_page * 10})
    }
  }

  allNotifications = () => {
    this.getAllNotificationLogs(1);
    this.setState({ReadActiveClass:false})
    this.setState({AllActiveClass:true})
    this.setState({UnreadActiveClass:false})
  }

  readNotifications = () => {
    this.getReadNotificationLogs(1,1);
    this.setState({ReadActiveClass:true})
    this.setState({AllActiveClass:false})
    this.setState({UnreadActiveClass:false})
   
  }

  unreadNotifications = () => {
    this.getUnreadReadNotificationLogs(0,1);
    this.setState({ReadActiveClass:false})
    this.setState({AllActiveClass:false})
    this.setState({UnreadActiveClass:true})
  }

  allhandlePageChange = (pageNumber) =>{
    console.log(`All notification active page is ${pageNumber}`);
    this.getAllNotificationLogs(pageNumber);
    this.setState({allNotificationCurrentpage: pageNumber});
  }

  readhandlePageChange = (pageNumber) =>{
    console.log(`Read notification active page is ${pageNumber}`);
    this.setState({onLoad:true})
    this.setState({readNotificationCurrentpage:` ${pageNumber}` })
    console.log("this.state.readNotificationCurrentpage", `${pageNumber}`)
    this.getReadNotificationLogs(1,`${pageNumber}`);
   
  }

  unreadhandlePageChange = (pageNumber) =>{
    console.log(` unread notification active page is ${pageNumber}`);
    this.setState({unreadNotificationCurrentpage: `${pageNumber}`});
    this.getUnreadReadNotificationLogs(0,`${pageNumber}`);
   
  }

  imageClick = (imageSrc) =>{
    console.log(`imageSrc ${imageSrc}`);
    this.setState({previewDialogImage: `${imageSrc}`});
    this.setState({previewDialogOpen: true});
  }

  previewDialogClose = () =>{
    this.setState({previewDialogOpen: false});
  }

	render() {
    
		return (
      <>
        <div className="app-body notficationWRAPPER" id="appBody" >
          <ReactNotifications/>
          <div className="app-div">
            <Helmet>
              <title>Notifications - Learner</title>
            </Helmet>
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
              <div className="page-header">
                <div className="page-header-left">
                  <h1>Notifications</h1>
                </div>
              </div>
              <div className='notificationFilter'>
                <label>Filter by</label>
                <div>
                  <span className={this.state.AllActiveClass ? 'selected': ''} onClick={this.allNotifications}>All</span>
                  <span className={this.state.ReadActiveClass ? 'selected': ''} onClick={this.readNotifications}>Read</span>
                  <span className={this.state.UnreadActiveClass ? 'selected': ''} onClick={this.unreadNotifications}>Unread</span>
                </div>
              </div>


                <div className={this.state.previewDialogOpen ? 'ImagePreviewDialog open' : 'ImagePreviewDialog' }>
                    <div className='ImagePreviewDialogContainer'>
                      <span onClick={this.previewDialogClose}><i className='fa fa-window-close'></i></span>
                      <div className='imageWrapper'><img src={this.state.previewDialogImage}/></div>
                    </div>
                </div>

              {/* { this.state.tests && this.state.tests.length <= 2 ?<div id={this.state.content_id=="NA"?"conter":""}></div> :""} */}

              {/* {JSON.stringify(this.state.AllNotifications)} */}
              {this.state.AllNotifications && this.state.AllActiveClass && 
              <div className="list-sticky">
                  {!this.state.AllNotifications.length && <div className='noNoitification'>No  Notification Found</div>}
                        <div className="notificationsWrapper" >
                          <div className="notificationsContainer">
                          {this.state.AllNotifications && this.state.AllNotifications.map((test,index) =>
                              <div className={`block ${test.is_read == false ? 'unread' : ''}`}  key={index} id={"block_"+index}>
                                <div className='notificationTop'>
                                <div className='notificationThumb'><img onClick={() => this.imageClick(test.image ? test.image : notificationDefault)} src={test.image ? test.image : notificationDefault}/></div>
                                <div className='notificationContent'>
                                  <div className="title" id={test.content_id} ref={this.myRef}><div>{test.subject}</div> <span onClick={() => this.seeMore(index,test.id)}><img src={downarrow}/></span></div>
                                </div>
                                </div>
                                <div className="content-desc" dangerouslySetInnerHTML={{__html: test.content}} />
                              </div>
                              
                              )}
                          </div>
                        </div>
                        {this.state.AllNotifications?.length ? <div className="pagination-list positionChange">
                          <Pagination
                            activePage={this.state.allNotificationCurrentpage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.allNotificationTotalItems}
                            pageRangeDisplayed={10}
                            onChange={this.allhandlePageChange.bind(this)}
                          />
                        </div>:''}  
                </div>
              }

           
          {this.state.ReadNotifications && this.state.ReadActiveClass && 
              <div className="list-sticky">
                {!this.state.ReadNotifications.length && <div className='noNoitification'>No  Notification Found</div>}
                         <div className="notificationsWrapper" >
                          <div className="notificationsContainer">
                          {this.state.ReadNotifications && this.state.ReadNotifications.map((test,index) =>
                              <div className="block" key={index} id={"block_"+index}>
                                <div className='notificationTop'>
                                <div className='notificationThumb'><img onClick={() => this.imageClick(test.image ? test.image : notificationDefault)} src={test.image ? test.image : notificationDefault}/></div>
                                  <div className='notificationContent'>
                                    <div className="title" id={test.content_id} ref={this.myRef}><div>{test.subject}</div> <span onClick={() => this.seeMore(index,test.id)}><img src={downarrow}/></span></div>
                                  </div>
                                </div>
                                <div className="content-desc" dangerouslySetInnerHTML={{__html: test.content}} />
                              </div>
                              )}
                          </div>
                        </div>
                        {this.state.ReadNotifications?.length ? <div className="pagination-list positionChange">
                          <Pagination
                            activePage={this.state.readNotificationCurrentpage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.readNotificationTotalItems}
                            pageRangeDisplayed={10}
                            onChange={this.readhandlePageChange.bind(this)}
                          />
                        </div>:''}
                </div>
              }   


            {this.state.UnreadNotifications && this.state.UnreadActiveClass && 
              <div className="list-sticky">
                {!this.state.UnreadNotifications.length && <div className='noNoitification'>No  Notification Found</div>}
                         <div className="notificationsWrapper" >
                          <div className="notificationsContainer">
                          {this.state.UnreadNotifications && this.state.UnreadNotifications.map((test,index) =>
                              <div  className={`block ${test.is_read == false ? 'unread' : ''}`} key={index} id={"block_"+index}>
                                <div className='notificationTop'>
                                <div className='notificationThumb'><img onClick={() => this.imageClick(test.image ? test.image : notificationDefault)} src={test.image ? test.image : notificationDefault}/></div>
                                <div className='notificationContent'>
                                  <div className="title" id={test.content_id} ref={this.myRef}><div>{test.subject}</div> <span onClick={() => this.seeMore(index,test.id)}><img src={downarrow}/></span></div>
                                </div>
                                </div>
                                <div className="content-desc" dangerouslySetInnerHTML={{__html: test.content}} />
                              </div>
                              )}
                          </div>
                        </div>
                        {this.state.UnreadNotifications?.length ? <div className="pagination-list positionChange">
                          <Pagination
                            activePage={this.state.unreadNotificationCurrentpage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.unreadNotificationTotalItems}
                            pageRangeDisplayed={10}
                            onChange={this.unreadhandlePageChange.bind(this)}
                          />
                        </div>:''}
                </div>
                 
              }   


             

          </div>
        </div>
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview,
	}
}

const mapDispatchToProps = (dispatch) => {
  const {userNotificationLogs,userNotificationReads,AllUserNotificationLogs} = actions;
  return {
    AllUserNotificationLogs: (page) => dispatch(AllUserNotificationLogs(page)),
    userNotificationLogs: (is_read,page) => dispatch(userNotificationLogs(is_read,page)),
    userNotificationReads: (id) => dispatch(userNotificationReads(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerNotificationHistory);