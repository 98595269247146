import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Profile from '../../public/icons/profile.svg';
// import Comment from '../../public/icons/comment.svg';
import MonthTable from '../../components/temp';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class FacultyDoubts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      opens: false,
      subjects: [],
      subjectsFilter: [],
      colors: {},
      doubts: [],
      classs: '',
      batch: 0,
      classes: [],
      batches: [],
      batchesFilter: [],
      imgShow: '',
      descriptionShow: '',
      subject: this.props.location.state?.subject_id !== undefined 
                  ? parseInt(this.props.location.state?.subject_id) : 0,
      list: this.props.location.state?.by_me !== undefined ? 1 : 0,
      page: 1,
      total_page: 1,
      current_page: 1,
    }
  }
  
  componentDidMount() {
    this.getFacultyProfile();
    this.getDoubts(this.state.subject, this.state.batch, this.state.list, this.state.page);
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, color: Object.fromEntries(
                  response.data.subjects.map(e => [e.id, e.background_code])
                ), classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  classs: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0]?.class_id,
                  subjectsFilter: response.data.subjects.filter(subject => subject.class_id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batchesFilter: response.data.batches.filter(batch => batch.classs?.id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batches: response.data.batches });
  }

  getDoubts = async (subject_id, batch_id, by_me, page) => {
    const response = await this.props.getFacultyDoubtList(subject_id, batch_id, by_me, page);
    if(response !== undefined) {
      this.setState({ doubts: this.state.doubts.concat(response.data.forums),
                        current_page: response.data.pagination.current_page,
                        total_page: response.data.pagination.total_page });
    }
  }

  seeMore = (subject_id, batch_id, by_me, page) => {
    var pageNumber = page + 1;
    this.setState({ page: pageNumber });
    this.getDoubts(subject_id, batch_id, by_me, page + 1);
  }

  show = (val) => this.setState({ open: true, imgShow: val });
  
  close = () => this.setState({ open: false });

  shows = (val) => this.setState({ opens: true, descriptionShow: val });
  
  closes = () => this.setState({ opens: false });

  onClassChange = (e) => {
    if(e.target.value !== '') {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classs: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })
    }
    else {
      this.setState({ subjectsFilter: this.state.subjects, subject: 0,
                        batchesFilter: this.state.batches, batch: 0 });
    }
    this.getDoubts(0, 0, this.state.list, 1);
  }

  onSubjectChange = (val) => {
    this.setState({ subject: val, loading: true, page: 1, doubts: [] });
    this.getDoubts(val, this.state.batch, this.state.list, 1);
  }

  onSectionChange = (e) => {
    this.setState({ batch: e.target.value, loading: true, page: 1, doubts: [] });
    this.getDoubts(this.state.subject, e.target.value, this.state.list, 1)
  }

  onListChange = (e) => {
    this.setState({ list: e.target.value, page: 1, doubts: [] });
    this.getDoubts(this.state.subject, this.state.batch, e.target.value, 1);
  }

  getDateTime = (val) => {
    var startDate = new Date(val);
		var currentDate = new Date(Date.now());
		var seconds = (currentDate.getTime() - startDate.getTime()) / 1000;
		var minut = seconds / 60;
    var hours = minut / 60;
    var minut2 = startDate.getMinutes();
    var hours2 = startDate.getHours(); 
		seconds = Math.floor(seconds);
		minut = Math.floor(minut);
		hours = Math.floor(hours);
		
		var date = startDate.getDate();
		var month = startDate.getMonth() + 1;
    var year = startDate.getFullYear();
    if(seconds < 60) {
      return `just now`;
    }
    else if(minut < 60) {
      if(minut <= 1) {
        return `${minut} min ago`;
      }
      else {
        return `${minut} mins ago`;
      }
    }
    else if(hours < 24) {
      if(hours <= 1) {
        return `${hours} hr ago`;
      }
      else {
        return `${hours} hrs ago`;
      }
    }
    else {
      if(minut2 < 10) {
        minut2 = '0' + minut2;
      }
      if(hours2 < 10) {
        hours2 = '0' + hours2;
      }
      return `${MonthTable[month]} ${date}, ${year}, ${hours2}:${minut2}`;
    }
  }

	render() {
    return (
      <>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="image">
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="image-popup">
            <img src={common.addFileUrl(this.state.imgShow)} alt="zoom"/>
          </div>
        </Popup>
        <Popup open={this.state.opens} closeOnDocumentClick onClose={this.closes}
          className="image">
          <span className="close" onClick={this.closes}>&#x2715;</span>
          <div className="description-popup">
            {this.state.descriptionShow}
          </div>
        </Popup>
        <div className="app-body">
          <div className="app-div">
            <Helmet>
              <title>Doubts - Faculty</title>
            </Helmet>
            <ReactNotifications/>
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
            <div className="page-header">
              <div className="page-header-left">
                <h1>Doubts</h1>
              </div>
              <div className="page-header-right">
                <select onChange={this.onClassChange} defaultValue={parseInt(this.state.classs)}>
                  {this.state.classes.map(classs => 
                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                      {classs.class_name}
                    </option>
                  )}
                </select>
                <select onChange={this.onSectionChange} defaultValue={parseInt(this.state.batch)}>
                  <option value={0}>
                    Select section
                  </option>
                  {this.state.batchesFilter.map(batch => 
                    <option value={parseInt(batch.id)} key={batch.id}>
                      {batch.label}
                    </option>
                  )}
                </select>
                <select onChange={this.onListChange} defaultValue={this.state.list}>
                  <option value={0}>
                    All questions
                  </option>
                  <option value={1}>
                    Answered by me
                  </option>
                </select>
              </div>
            </div>
            <div className="page-doubt-subjects">
              <div onClick={() => this.onSubjectChange(0)}
                id={this.state.subject === 0 ? 'selected-dsubject' : ''}>
                All
              </div>
              {this.state.subjectsFilter.map(subject =>
                <div key={subject.id} onClick={() => this.onSubjectChange(subject.id)}
                  id={this.state.subject === subject.id ? 'selected-dsubject' : ''}>
                  {subject.label}
                </div>
              )}
            </div>
            <div className="page-data">
              {this.state.doubts.length === 0 && <Empty/>}
              {this.state.doubts.map(doubt => 
                <div className="single-doubt" key={doubt.id}>
                  <Link className="single-doubt-left" to={{ 
                    pathname: `/faculty/doubt-responses/${doubt.id}`,
                    state: {
                      question: doubt
                    }
                  }}>
                    <div className="doubt-by">
                      <div className="doubt-by-div">
                        {doubt?.user?.profile_picture.length === 0 
                          ? <div className="doubt-by-profile">
                          <img src={Profile} alt="user"/>
                        </div> : <div className="doubt-by-profile" style={{backgroundImage: 
                          `url(${common.addFileUrl(doubt?.user?.profile_picture)})`}}>
                        </div>}
                        <div>
                          <span className="doubt-by-name">
                            {doubt.user.name}
                          </span>
                          <span className="doubt-by-time">
                            {this.getDateTime(doubt.added_on)}
                          </span>
                        </div>
                      </div>
                      <div className="single-doubt-info">
                        <span className="single-doubt-subject">{doubt.subject}</span>
                        {/* <div className="responses-count">
                          <img src={Comment} alt="responses"/> 3
                        </div> */}
                      </div>
                    </div>
                    <span className="single-doubt-description">{doubt.description}</span>
                  </Link>
                  {doubt.description.length > 1685 ? <span id="view_more"
                    onClick={() => this.shows(doubt.description)}>View more</span> 
                      : null}
                  {doubt.media === null ? null 
                    : <div title="Zoom" className="single-doubt-right" 
                      onClick={() => this.show(doubt.media)}
                    style={{ backgroundImage: `url(${common.addFileUrl(doubt.media)})`}}>
                  </div>}
                </div>
              )}
              {this.state.current_page === this.state.total_page ? <div></div>
                : <div className="more_doubts">
                {!this.props.loading ? <button onClick={() => this.seeMore(this.state.subject, this.state.batch, this.state.list, this.state.page)}>
                  See More
                </button> : <div>
                  <div className="list-loader"></div>
                </div>}
              </div>}
            </div>
          </div>
        </div>
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile, getFacultyDoubtList } = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyDoubtList: (subject_id, batch_id, by_me, page) => dispatch(getFacultyDoubtList(subject_id, batch_id, by_me, page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyDoubts);