import React from 'react';

import EmptyImg from '../../public/images/empty.svg';
import './styles.css';

const Empty = (props) => {
  return (
    <div className="empty-div">
      <img src={EmptyImg} alt="no-data"/>
      {props?.statement}
    </div>
  )
}

export default Empty;