import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import './styles.css';

const ques_types = {
  1: 'MCQ',
  2: 'Single Integer',
  3: 'Multiple Response',
  4: 'Fill in the blanks',
  5: 'True or False',
  6: 'Subjective',
  7: 'Matching'
}

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 10,
    slidesToSlide: 10
  },
  tablet: {
    breakpoint: { max: 720, min: 590 },
    items: 10,
    slidesToSlide: 10
  },
};

const SlideButtons = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const { carouselState: { totalItems } } = rest;
  const lastSlide = Math.floor(totalItems / 4) + (totalItems % 4) - 1;
  if(totalItems <= 10) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="slide-buttons-xy">
      <img src={Left} className='slide-button-xy' onClick={() => previous()} alt="img"/>
      <img src={Right} className='slide-button-xy' onClick={() => next()} alt="img"/>
    </div>
  );
};

class AssessmentPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      center_id: localStorage.getItem('center_id'),
      id: this.props.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      no_ques: false,
      sections: [],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      options: []
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    // this.renderMath();
    window.location.reload();
    this.getAssessment(this.props.props.match.params.id);
  }

  // componentDidUpdate() {
  //   this.renderMath()
  // }

  // renderMath() {
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node2.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node3.current
  //   ])
  // }

  getAssessment = async (id) => {
    const response = await this.props.getAssessment(id);
    if(response !== undefined) {
      response.data.sections.map(section => {
        section.questions.map((question, index) => {
          question.question_number = "Q"+(index+1);
        })
      })
      this.setState({ title: response.data.title, code: response.data.uid, 
                        sections: response.data.sections, 
                        question: response.data.sections[0]?.questions,
                        options: response.data.sections[0]?.questions[0]?.options,
                        section_title: response.data.sections[0]?.title, exerciseAttempt: response.data,
                        question_title: response.data.sections[0]?.questions[0]?.title });
      this.setCurrentSectionQ(response.data.sections);
    }
  }

  setCurrentSectionQ = (arr) => {
    var cng = 0;
    for(let i = 0; i < arr.length; i ++) {
      if(arr[i].questions.length > 0) {
        this.setState({ currentSection: arr[i], currentQuestion: arr[i]?.questions[0] });
        cng = 1;
        break;
      }
    }
    if(cng === 0) {
      this.setState({ no_ques: true })
    }
    this.setState({ loading: false })
  }

  changeCurrentQuestion = async (sectionId, quesionId) => {
    this.moveToNext(sectionId, quesionId);
  }

  moveToNext = (sectionId, questionId) => {
    let { currentQuestion, currentSection, exerciseAttempt } = this.state;
    if(currentSection.id === sectionId){
      const questionIndex = currentSection.questions.findIndex(p => p.question === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    } 
    else {
      const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === sectionId)
      currentSection = exerciseAttempt.sections[sectionIndex]
      const questionIndex = currentSection.questions.findIndex(p => p.question === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    }
    this.setState({
      currentQuestion,
      currentSection
    })
  }

  previousSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== 0) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex - 1].id, exerciseAttempt.sections[sectionIndex - 1]?.questions[0]?.question);
    }
  }

  nextSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== exerciseAttempt.sections.length-1) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1]?.questions[0]?.question);
    }
  }

  previous = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions.findIndex(p => p.question === currentQuestion.question)
    if(questionIndex === 0) {
      if(sectionIndex !== 0) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex-1].id, exerciseAttempt.sections[sectionIndex-1].questions[exerciseAttempt.sections[sectionIndex-1].questions.length-1].question)
      }
    } 
    else {
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex - 1].question)
    }
  }

  next = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions
                          .findIndex(p => p.question === currentQuestion.question)
    if(questionIndex === currentSection.questions.length - 1) {
      if(sectionIndex !== exerciseAttempt.sections.length-1) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1].questions[0].question)
      }
    } else{
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex+1].question)
    }
  }

  render() {
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      loading
    } = this.state;
    if(this.props.loading || loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    else if(exerciseAttempt === '' || exerciseAttempt === null || exerciseAttempt === undefined
        || this.state.no_ques === true || exerciseAttempt.sections.length === 0) {
      return (
        <div className="assessment-div">
          <span id="no-questions">No questions!</span>
        </div>
      )
    }
    return (
      <div className="assessment-div">
        <Helmet>
          <title>Assessment Preview - {this.state.title}</title>
        </Helmet>
        <div className="assessment-head">
          <div className="assessment-head1">
            <span>{this.state.title}</span>
          </div>
          <div className="assessment-head2">
            <div className="assessment-section-buttons">
              <img src={MoveLeft} alt="img" onClick={this.previousSection}/>
              {currentSection.title}
              <img src={MoveRight} alt="img" onClick={this.nextSection}/>
            </div>
            <Carousel containerClass="section-layer-tray" responsive={responsive}
              keyBoardControl={false} arrows={false} swipeable={false} draggable={false}
              customButtonGroup={<SlideButtons/>} renderButtonGroupOutside={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}>
              {currentSection.questions !== undefined 
                && currentSection.questions.map((question, index) => 
                <div className="sec-ques-div" id={currentQuestion.question === question.question
                  ? 'current-ques' : ''} key={question.question}
                  onClick={()=>this.changeCurrentQuestion(currentSection.id, question.question)}>
                  {index !== 0 && <hr className="sec-line-one"/>}
                  {index + 1}
                  {(index !== (currentSection.questions.length - 1)) && <hr className="sec-line-two"/>}
                </div>
              )}
            </Carousel>
          </div>
          <div className="assessment-head3">
            <button onClick={() => this.props.props.history.goBack()}>
              Exit Review
            </button>
          </div>
        </div>
        {currentSection.questions.length > 0 ? <div className="assessment-main">
          <div className="assessment-ques">
            <div className="subject">{currentSection.title}</div>
            <div className="time-remaining">
              <span className="question_no">{currentQuestion?.question_number}</span>
              <div className="ques_type_prev">
                {ques_types[currentQuestion?.question_data?.question_type]}
              </div>
            </div>
            <p ref={this.node} 
              dangerouslySetInnerHTML={{__html: currentQuestion.question_data?.statements[0]?.statement}} />
          </div>
          <div className="assessment-options">
            {currentQuestion?.question_data?.question_type === 6 ? <div className="subjective_sol">
              {currentQuestion.question_data?.statements[0]?.explanation}
            </div> : <>
              {currentQuestion?.question_data?.options &&
                currentQuestion?.question_data?.options.map((option, index) =>
                <div className="option-container" key={`option_${index}`}>
                  <div ref={this.node2} key={`option_${index}`}
                    className={ option.is_selected  ? "incorrect-option select-option" : "select-option"}
                    style={option.is_correct ? {borderColor: 'var(--primary)'} : {}}
                    dangerouslySetInnerHTML={{__html: option?.statements[0]?.statement}}
                  />
                </div>
              )}
            </>}
          </div>
        </div> : <span id="no-questions">No questions!</span>}
        <div className="assessment-bottom">
          <span onClick={this.previous}>
            Previous
          </span>
          <span onClick={this.next}>
            Next
          </span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessment } = actions;
  return {
    getAssessment: (id) => dispatch(getAssessment(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentPreview);