import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Target from '../../public/icons/target.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
// import InfiniteScroll from "react-infinite-scroll-component";
//import 'react-calendar/dist/Calendar.css';

class FacultyPractice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      practice: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      content_id:0,
      classs: '',
      batch: 0,
      classes: [],
      batches: [],
      batchesFilter: [],
      subjects: [],
      subject:0,
      subjectsFilter: [],
      is_recent:0,
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date});
   this.getCurrentDate();
   this.getFacultyProfile();
   this.getFacultyExerciseList(0,0,date,15,'forward',true);
  }

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects, color: Object.fromEntries(
                  response.data.subjects.map(e => [e.id, e.background_code])
                ), classes: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{})),
                  classs: Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0]?.class_id,
                  subjectsFilter: response.data.subjects.filter(subject => subject.class_id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batchesFilter: response.data.batches.filter(batch => batch.classs?.id === Object.values(response.data.subjects
                    .map(({ class_id, class_name }) => ({ class_id, class_name }))
                    .reduce((acc,cur) => Object.assign(acc,{[cur.class_id]: cur}),{}))[0].class_id),
                  batches: response.data.batches });
  }

  getFacultyExerciseList = async (subject_id,batch_id,date,days,direction,if_loading) => {
    const response = await this.props.getFacultyExerciseList(subject_id,batch_id,date,days,direction,if_loading);
    if(response){
      this.setState({practice:response.data.data?response.data.data:"", loading: false,countBottom:1,content_id:response.data.data?response.data?.data[0]?.content_id:0})
      
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll
    ];
    return date;
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({current_date:date,date:currentDate});
  }

  
  onClassChange = (e) => {
    if(e.target.value !== '') {
      this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
                        subject.class_id === parseInt(e.target.value)), 
                        classs: parseInt(e.target.value), batch: 0, subject: 0,
                        batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })
    }
    else {
      this.setState({ subjectsFilter: this.state.subjects, subject: 0,
                        batchesFilter: this.state.batches, batch: 0 });
    }
  }

  onSubjectChange = (e) => {
    this.setState({ subject:parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getFacultyExerciseList(parseInt(e.target.value),this.state.batch,this.state.start_date,15,'forward',true);
  }

  onSectionChange = (e) => {
    this.setState({ batch: parseInt(e.target.value), loading: true, page: 1});
    this.setState({ hasMore: false,countTop:0});
    this.getFacultyExerciseList(this.state.subject,parseInt(e.target.value),this.state.start_date,15,'forward',true);
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  render() {

    return (
      <div className="app-body" id="appBody">
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>{`${this.props.saveLanguageWiseData.tests} - ${this.props.saveLanguageWiseData.faculty}`}</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="page-header-faculty-practice">
              <div className="page-header-right">
                <select onChange={this.onClassChange} defaultValue={parseInt(this.state.classs)}>
                  {this.state.classes.map(classs => 
                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                      {classs.class_name}
                    </option>
                  )}
                </select>
                <select onChange={this.onSectionChange} defaultValue={parseInt(this.state.batch)}>
                  <option value={parseInt(0)}>
                   {this.props.saveLanguageWiseData.select_section}
                  </option>
                  {this.state.batchesFilter.map(batch => 
                    <option value={parseInt(batch.id)} key={batch.id}>
                      {batch.label}
                    </option>
                  )}
                </select>
                <select onChange={this.onSubjectChange} defaultValue={parseInt(this.state.subject)}>
                  <option value={parseInt(0)}>
                    {this.props.saveLanguageWiseData.select_subject}
                  </option>
                  {this.state.subjectsFilter.map(subject =>
                    <option value={parseInt(subject.id)} key={subject.id}>
                      {subject.label}
                    </option>
                  )}
                </select>
              </div>
            </div>
            
            {!this.state.loading && <div className="list-sticky-practice" onScroll={this.handleScroll}>
              {this.state.practice && !this.state.practice.length && <Empty/>}
              <div className="list-content">
                {this.state.practice && this.state.practice.map((test,index) =>
                  <div className="list-div-x2" id={test.content_id} ref={this.myRef}>
                    
                    <div className="dash-test" id={test.content_id==this.state.content_id?"conter":""} key={index} Style={this.state.date==this.getTime(test.due_date)[2] && this.getTime(test.due_date)[3]?"background-color:#f1e6ef":""}>
                      
                        <div>
                          <div Style="display: inline-block;">
                            <span Style="font-size:17px">{test.title}</span><br/><br/>
                            <span Style="color:#a9a0a0">{this.props.saveLanguageWiseData.average_score}</span> : <span Style="color:var(--primary)">{test.summary.score}</span>
                          </div>
                          <span className="dash-flex2-img"><img id="Target" src={Target} alt="Target"/></span>
                          
                        </div>
                      <div className="dash-test-bottom">
                        <div>
                          <span>{test.class_name} | {test.batch_name} | {test.subject_name}</span>
                        </div>
                        <div className="faculty-dash-actions">
                          {test.actions && test.actions.map(action =>
                            <Link key={action.id} to={{pathname: `/faculty/assessment-review/${test.content_id}`,
                              state: { batch: test?.batch_id, modal: true }}}>
                              <button>
                                {action.label}
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> 
                )}</div>
            </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getFacultyExerciseList } = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyExerciseList: (subject_id,batch_id,date,days,direction,if_loading) => dispatch(getFacultyExerciseList(subject_id,batch_id,date,days,direction,if_loading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyPractice);