import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';
import { Helmet } from "react-helmet";
import actions from '../../redux/actions';
import config from '../../config';
import unsubcribeIcon from '../../public/icons/optedout-icon.png';
import './styles.css';

class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state={
      status:false
    }
  }

  componentDidMount() {
    const token=this.props.location.search.replace("?query=", '');
    this.unsubscribe(token)
  }

  unsubscribe = async(token) => {
    const response = await this.props.unsubscribe(token)
    if(response != undefined){
      this.setState({status : response.data.status})
    }
  }
  
  
	render() {
		return (
      <div class="optedout-wrapper">
        <Helmet>
          <title>Imaginxp - Unsubscribe</title>
        </Helmet>
        <ReactNotifications />
        {this.state.status ? <div class="optedout-container">
          <img src={unsubcribeIcon}/>
          <div>You have unsubscribed</div>
          <p>You have successfully opted out from receiving system generated notification emails</p>
          <div>if you want to opt in again then write into us at - <a href="mailto:support@imaginxp.freshdesk.com">{config.is_xpmaster ? "support@imaginxp.freshdesk.com" : "support@schoolsonweb.com"}</a></div>
        </div> : ""
        }
      </div>
    )
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { unsubscribe } = actions;
  return {
    unsubscribe: (query) => dispatch(unsubscribe(query)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);