import React, { Component } from "react";
import { connect } from 'react-redux';
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight
} from "./pdf-react";
import config from '../../../config.js';
import Spinner from "./Spinner";
import "./pdfPlayer.css";
import actions from '../../../redux/actions';

import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
 
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

import type {
  T_Highlight,
  T_NewHighlight
} from "../../pdf-react/types";

import Full from '../../../public/images/full-screen.svg';
import ExitFull from '../../../public/images/exit-screen.svg';

import { PdfViewerComponent, Toolbar, Magnification, Navigation, Inject } from '@syncfusion/ej2-react-pdfviewer';


type T_ManuscriptHighlight = T_Highlight;

type Props = {};

type State = {
  highlights: Array<T_ManuscriptHighlight>
};

let Savetime = '';
let SavetimeNew = '';
let SavetimeFinal = '';
let saveCallTime = 0;
const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};
const apiMediaDomain =config.apiMediaDomain;
const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

class PdfPlayer2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      fileName:'',
      content_id:this.props.content_id,
      ref_id:this.props.ref_id,
      level:this.props.level,
      content_type:this.props.content_type,
      content_subtype:this.props.content_subtype,
      subject:this.props.subject_id,
      current_pointer:this.props.current_pointer,
      view: false,
      highlights: [],
      seconds :new Date().getTime() / 1000,
      is_faculty_admin:localStorage.getItem("is_faculty_admin") == "true"? true:false,
      is_admin:localStorage.getItem("is_admin") == "true" ? true :false,
      is_faculty:localStorage.getItem("is_faculty") == "true"? true:false,
      is_admin:localStorage.getItem("is_admin") == "true"? true:false,

      externalFile:false,
      getExternalfileCounter:localStorage.getItem('externalfile'),
      saveseconds:0,
      prevSaveSeconds:0,
      savesecondsnew:0,
      saveCurrentPage:0,
      savePrevPage:0,
      totalPages:'',
      notesFileExt : ''
      
    }
  }

  resetHighlights = () => {
    this.setState({
      highlights: []
    });
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  componentDidMount() {

  this.interval = setInterval(() => this.startCounter(), 1000);

  this.interval1 = setInterval(() => this.startCounterNew(), 1000);

  //this.interval1 = setInterval(() => this.startCounterNew(), 10000);


   localStorage.setItem("externalfile",true)
    let fileName = this.props.url.split('/').pop()
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );


    this.setState({fileName})
    console.log("this.props.url")  
    console.log(this.props.url)  

    var notesFileExt = this.props.url.split('.').pop().toLowerCase();
    
    if(notesFileExt){
      this.setState({notesFileExt, notesFileExt})
      localStorage.setItem("notesFileExt", notesFileExt)
    }
    
    
    let getHosturl = new URL(this.props.url)
    console.log(getHosturl.host)  
    console.log("config.apiMediaDomain")  
    console.log(config.apiMediaDomain);
    let configDomain = new URL(config.apiMediaDomain);
    console.log(configDomain.host);


    if(getHosturl.host !== configDomain.host){
        console.log("externalFile hai");
        this.setState({externalFile: true});
        localStorage.setItem("checkExternalFile",true)
     }else{
      console.log("externalFile nahi hai");
      this.setState({externalFile: false});
      localStorage.setItem("checkExternalFile",false)
      localStorage.setItem("externalfile",false)
     }
    

    //this.load()
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval1);
  }

  

  componentDidUpdate(prevProps) {
    if(this.props.url !== prevProps.url) {
      this.setState({ url: this.props.url, highlights: [] })
    }
    
  }

  getHighlightById(id: string) {
    const { highlights } = this.state;

    return highlights.find(highlight => highlight.id === id);
  }

  addHighlight(highlight: T_NewHighlight) {
    const { highlights } = this.state;

    console.log("Saving highlight", highlight);

    this.setState({
      highlights: [{ ...highlight, id: getNextId() }, ...highlights]
    });
  }

  updateHighlight(highlightId: string, position: Object, content: Object) {
    console.log("Updating highlight", highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map(h => {
        return h.id === highlightId
          ? {
              ...h,
              position: { ...h.position, ...position },
              content: { ...h.content, ...content }
            }
          : h;
      })
    });
  }

  screenFull = () => {
    this.setState({ view: !this.state.view });
  }

  load = () => {
  
    var pdfdata;
    //https://cors-anywhere.herokuapp.com/
    var staticUrl = this.state.url;
    console.log(staticUrl)
    var xhr = new XMLHttpRequest(); 
    xhr.open('GET', staticUrl, true); 
    xhr.responseType = 'blob'; 
    xhr.onload = function (e) { 
        if (this.status == 200) { 
            var myBlob = this.response; 
            var reader = new window.FileReader(); 
           
            reader.readAsDataURL(myBlob); 
            reader.onloadend = function () { 
              var base64data = reader.result;                   
              var pdfviewer = (document.getElementById('container')).ej2_instances[0];       
              
              pdfviewer.load(base64data, null);
            } 
        } 
    }
    xhr.send(); 
  }


  startCounter = (e) => {
    console.log("saveseconds",this.state.saveseconds);
    this.setState(prevState => ({saveseconds: prevState.saveseconds + 1}));
  }

 

  startCounterNew = (e) => {
    console.log("savesecondsnew",this.state.savesecondsnew);
    this.setState(prevState => ({savesecondsnew: prevState.savesecondsnew + 1}));

    if(this.state.savesecondsnew > 9){
      this.SendcounterAPI();
      clearInterval(this.interval1);
      this.setState({savesecondsnew:0});
      //console.log("clear interval");
      //console.log("savesecondsnew",this.state.savesecondsnew);
      this.interval1 = setInterval(() => this.startCounterNew(), 1000);
      
    }
    
  }


  SendcounterAPI = async(e) => {
    const {content_id,ref_id,level,content_type,content_subtype,subject} = this.state;
    let {current_pointer,saveCurrentPage,savePrevPage} = this.state;
    let currentPage  = 1;
      if(currentPage ==  null){
        currentPage = 1;
      }else{
        currentPage = saveCurrentPage;
      }
      let prevPage = current_pointer;
      console.log("current_pointer",current_pointer);
      console.log("currentPage",currentPage);
      console.log("prevPage",prevPage);
      
      
    
    if(prevPage == 1){
      prevPage = 1
    }else{
      prevPage = current_pointer ? current_pointer : 1
    }
    let totalPage=  this.state.totalPages;
   
    console.log("totalPage",totalPage);
    console.log("SavetimeNew",this.state.savesecondsnew);
    console.log("saveCurrentPage",this.state.saveCurrentPage);
    console.log("savePrevPage",this.state.savePrevPage);
    // if(!current_pointer) current_pointer =1;
    if(this.state.savesecondsnew > 9){
        Savetime = this.state.savesecondsnew;
        console.log("Savetime",Savetime);
        SavetimeNew = 0;
        console.log("this.state.savesecondsNew",this.state.savesecondsnew);
        clearInterval(this.interval1);
        this.interval1 = setInterval(() => this.startCounterNew(), 10000);
       if(!this.state.is_faculty && ! this.state.is_admin){
         saveCallTime +=10;
        const response = await this.props.userContentCompletion(content_id,ref_id,level,content_type,content_subtype,currentPage,parseInt(totalPage),Savetime,prevPage,parseInt(subject));
      }
     
    }
  }

  pdfPageChange = async(e) => {
    const {content_id,ref_id,level,content_type,content_subtype,subject} = this.state;
    let {current_pointer} =this.state;
    console.log("e.currentPage",e.currentPage)
    let currentPage = e.currentPage == 0 ?  1 : e.currentPage+1;
    // let prevPage = e.currentPage == 1 ? 1 :e.currentPage
    let prevPage = '';
    if(e.currentPage == 0){
      prevPage = 1
    }else{
      prevPage = e.currentPage
    }
    this.setState({saveCurrentPage:currentPage});
    let totalPage=  e.doc.numPages;
    if(!current_pointer) current_pointer =1;
    if(this.state.saveseconds > 1){
      clearInterval(this.interval);
      clearInterval(this.interval1);
      SavetimeFinal = this.state.saveseconds;
      Savetime =  this.state.savesecondsnew;
      console.log("SavetimeFinal",SavetimeFinal)
      console.log("Savetime",Savetime);
      console.log("saveCallTime", saveCallTime);
      console.log("prevPage", prevPage);
      console.log("currentPage", currentPage);
      this.setState({saveCurrentPage:currentPage})
      this.setState({savePrevPage:prevPage})
      //Savetime = 0;
      this.setState({saveseconds: 0});
      this.setState({savesecondsnew:0});

      
      this.interval = setInterval(() => this.startCounter(), 1000);
      this.interval1 = setInterval(() => this.startCounterNew(), 1000);
      if(!this.state.is_faculty && ! this.state.is_admin){
        const response = await this.props.userContentCompletion(content_id,ref_id,level,content_type,content_subtype,currentPage,parseInt(totalPage),Savetime,prevPage,parseInt(subject));
      }
    }
  }



  // pdfPageChange = async (e) => {
  //   let time = new Date().getTime() / 1000;
  //   let seconds_taken= (time - this.state.seconds);
  //   const {content_id,ref_id,level,content_type,content_subtype,subject} = this.state;
  //   let {current_pointer} =this.state;
  //   console.log("Current Page"+e.currentPage);
  //   console.log(ref_id)
  //   //var x = document.getElementById("container_totalPage").textContent;
  //   let totalPage=  e.doc.numPages

  //   if(!current_pointer) current_pointer =1;

  //   if(!this.state.is_faculty && ! this.state.is_admin){
  //     const response = await this.props.userContentCompletion(content_id,ref_id,level,content_type,content_subtype,e.currentPage,parseInt(totalPage),parseInt(seconds_taken),current_pointer,parseInt(subject));
  //   }
  // }

  afterLoadDom =async (e) =>{
    // let seconds_taken= (new Date().getTime() / 1000) - this.state.seconds;
    // const {content_id,ref_id,level,content_type,content_subtype,subject,current_pointer} = this.state;
    console.log("event",e.currentPage)
    let totalPage= e.doc.numPages;
    this.setState({totalPages : totalPage})

    // if(!this.state.is_faculty && ! this.state.is_admin){
    //   const response = await this.props.userContentCompletion(content_id,ref_id,level,content_type,content_subtype,1,parseInt(totalPage),parseInt(seconds_taken),1,parseInt(subject));
    // }
  }

  
  render() {
    const { highlights,is_faculty_admin,is_admin,fileName} = this.state;
    if(this.state.url === null) {
      return (
        <div>
        </div>
      )
    }
    else {
      return (
        
        <div className={this.state.view ? "pdf-web" : "pdf-noweb"}>
              {this.state.externalFile &&
              <div className="extewrnalURL">
                <p>Please click the below link for view the content</p>
                <a href={this.state.url} target="_blank">{this.state.url}</a>
              </div>
              }
              {!this.state.externalFile && this.state.notesFileExt === 'pdf' &&
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                  <div style={{ height: '750px' }}>
                      <Viewer fileUrl={this.state.url}  onPageChange={this.pdfPageChange} onDocumentLoad={this.afterLoadDom} initialPage={this.state.current_pointer}  />
                  </div>
              </Worker>
                }

              {!this.state.externalFile && this.state.notesFileExt != 'pdf' &&
                <div className="downloadMessage">Please Download the file</div>
                }
              
              {/* initialPage={this.state.current_pointer} */}
            {!this.state.externalFile && !is_faculty_admin && !is_admin && <button className="full-view" onClick={this.screenFull}>
              {!this.state.view ? <img src={ExitFull}/> : <img src={Full}/>}
            </button> }
        </div>
      
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userContentCompletion} = actions;
  return {
    userContentCompletion: (content,ref_id,level,content_type,content_subtype,page_number,max_page_no,duration_taken,previous_page_number,subject) => dispatch(userContentCompletion(content,ref_id,level,content_type,content_subtype,page_number,max_page_no,duration_taken,previous_page_number,subject)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (PdfPlayer2);