import React from 'react';
import { Link } from 'react-router-dom';

import common from '../../common';
import Time from '../../public/icons/preview_time.svg';
import Sections from '../../public/icons/preview_sections.svg';
import Marking from '../../public/icons/preview_marking.svg';
import Difficulty from '../../public/icons/preview_difficulty.svg';
import Attempts from '../../public/icons/preview_attempts.svg';
import Solution from '../../public/icons/Component 58 – 13.svg';
import Passed from '../../public/icons/Group 922.svg';
import PassedText from '../../public/icons/Passed.svg';
import Evaluation from '../../public/icons/evaluation.svg';
import './styles.css';


let is_admin = localStorage.getItem("is_admin") == "true" ?true:false;
let is_faculty_admin = localStorage.getItem("is_faculty_admin") == "true" ?true:false;

export const AssessmentPreview = (props) => (
  
  <div className="assesment-preview">
    <div className="assessment-preview-head">
      <div className="title">
        <span>{props.contentPreview.title} {props.contentPreview?.summary_values?.is_passed 
          ? <span>(Passed)</span> : null}</span>
        <div className="unique-code">{props.contentPreview.unique_no}</div>
      </div>
      <div className="submit-by-date">
        <div>{props.contentPreview?.summary_values?.submit_by && "Submit By:"}</div>
        {props.contentPreview?.summary_values?.submit_by && <span>{common.getFullDate(props.contentPreview.summary_values.submit_by)} {common.getTimeFromDate(props.contentPreview.summary_values.submit_by)}</span>}
      </div>
      <div className="submit-by-date">
        <div>{props.contentPreview.summary_values.submitted_on && "Submitted On:"}</div>
        {props.contentPreview.summary_values.submitted_on && 
          <span>{common.getFullDate(props.contentPreview.summary_values.submitted_on)} {common.getTimeFromDate(props.contentPreview.summary_values.submitted_on)}</span>}
      </div>
      <div className="submit-by-date">
        {props.contentPreview?.summary_values?.is_passed  && 
          <span Style="margin-left:280px"><div><img src={Passed} alt="img" height="170px"/></div><div Style="padding-left:40px"><img src={PassedText} alt="img"  width="80px"/></div></span>}
      </div>

    </div>
    <div className="summary-values sdfsdfsdfsdfsdfsdfsdfsdfsdfsd">
      {props.contentPreview.summary_values.duration && <div id="total_question_data">
        <img src={Time} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.duration}}></span>
        </div>
      </div>}
      {props.contentPreview.summary_values.time_taken && <div id="total_question_data">
        <img src={Time} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.time_taken}}></span>
        </div>
      </div>}

      {props.contentPreview.summary_values.marking && <div id="total_question_data">
        <img src={Marking} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.marking}}></span>
         
        </div>
      </div>}
      
      {props.contentPreview.summary_values.sections && <div id="total_question_data">
        <img src={Sections} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.sections}}></span>
        </div>
      </div>}
      {props.contentPreview?.summary_values?.result && <div id="total_question_data">
        <img src={Evaluation} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.result}}></span>
        </div>
      </div>}
      {props.contentPreview.summary_values.attempts_allowed && <div id="total_question_data">
        <img src={Attempts} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.attempts_allowed}}></span>
        </div>
      </div>}
      {(props.contentPreview?.show_solution_after !== 0 || props.contentPreview?.result_after !== 0) &&    
        props.contentPreview?.summary_values?.score  && <div id="total_question_data">
        <img src={Marking} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.score}}></span>
        </div>
      </div>}
      
      {props.contentPreview.summary_values.solution && <div id="total_question_data">
        <img src={Solution} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.solution}}></span>
        </div>
      </div>}

      {props.contentPreview.summary_values.difficulty && <div id="total_question_data">
        <img src={Difficulty} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.difficulty}}></span>
        </div>
      </div>}

    </div>
    <div className="instructions"><b>Instructions</b></div>
    <div className="preview-container">
      <div dangerouslySetInnerHTML={{__html: props.contentPreview.instructions}}></div>
    </div>
    <div className="instructions"><b>Description</b></div>
    <div className="preview-container">
      <div dangerouslySetInnerHTML={{__html: props.contentPreview.description}}></div>
    </div>
    {props.showAttemptButton && props.is_archive  == false &&
      <div Style={props.wChange ? "width:100%":""}
        className={is_faculty_admin || is_admin ? "action-buttonsx3" : "action-buttonsx2" }>
        {props.contentPreview.assesment_actions.map((actions, index) => {
          return <button onClick={() => props.attempt(actions.label)} key={`action_${index}`}
            className={`${actions.label}`} id={actions.label.toLowerCase() === 'review' ||
            actions.label.toLowerCase() === 'result' ? "button-reverse" : ""}>
            {actions.label}
          </button>
        })}
      </div>
    }
  
  {props.showAttemptButton && props.is_archive &&
        <div Style={props.wChange ? "width:100%":""}
          className={is_faculty_admin || is_admin ? "action-buttonsx3" : "action-buttonsx2" }>
          {props.contentPreview.assesment_actions.map((actions, index) => {
            return (actions.label == "Review" || actions.label == "Result") && <button onClick={() => props.attempt(actions.label)} key={`action_${index}`}
              className={`${actions.label}`} id={actions.label.toLowerCase() === 'review' ||
              actions.label.toLowerCase() === 'result' ? "button-reverse" : ""}>
              {actions.label}
            </button>
            
          })}
        </div>
      }
  </div>
)

const AssessmentPreview2 = (props) => (
  <div className="assesment-preview2">
    <div className="assessment-preview-head">
      <div className="title">
        <span>{props.contentPreview.title}</span>
        <div className="unique-code">{props.contentPreview.unique_no}</div>
      </div>
      <div className="submit-by-date2">
        <div>{props.contentPreview?.summary_values?.submit_by && "Submit By:"}</div>
        {props.contentPreview?.summary_values?.submit_by && <span>{common.getFullDate(props.contentPreview.summary_values.submit_by)} {common.getTimeFromDate(props.contentPreview.summary_values.submit_by)}</span>}
      </div>
      <div className="submit-by-date2">
        <div>{props.contentPreview?.summary_values?.submitted_on && "Submitted On:"}</div>
        {props.contentPreview?.summary_values?.submitted_on && 
          <span>{common.getFullDate(props.contentPreview.summary_values.submitted_on)} {common.getTimeFromDate(props.contentPreview.summary_values.submitted_on)}</span>}
      </div>
      <div className="submit-by-date">
        {props.contentPreview?.summary_values?.is_passed  && 
          <span Style="margin-left:280px"><div><img src={Passed} alt="img" height="170px"/></div><div Style="padding-left:40px"><img src={PassedText} alt="img"  width="80px"/></div></span>}
      </div>
    </div>
    <div className="summary-values   ddddsssss">

      {props.contentPreview?.summary_values?.duration && <div id="total_question_data">
        <img src={Time} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.duration}}></span>
        </div>
      </div>}

      {props.contentPreview?.summary_values?.time_taken && <div id="total_question_data">
        <img src={Time} alt="img"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.time_taken}}></span>
        </div>
      </div>}

      {props.contentPreview?.summary_values?.marking && <div id="total_question_data">
        <img src={Marking} alt="imgddddddggggggg"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.marking}}></span>
        </div>
      </div>}
      
      {props.contentPreview?.summary_values?.sections && <div id="total_question_data">
        <img src={Sections} alt="imghhhhhh"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.sections}}></span>
        </div>
      </div>}
      {props.contentPreview?.summary_values?.result && <div id="total_question_data">
        <img src={Evaluation} alt="im 555555g"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.result}}></span>
        </div>
      </div>}
      
      {props.contentPreview?.summary_values?.attempts_allowed && <div id="total_question_data">
        <img src={Attempts} alt="img ddddd"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.attempts_allowed}}></span>
        </div>
      </div>}
      {(props.contentPreview?.show_solution_after !== 0 || props.contentPreview?.result_after !== 0) &&    
      props.contentPreview?.summary_values?.score  && <div id="total_question_data">
        <img src={Marking} alt="img ggggg"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.score}}></span>
        </div>
      </div>
      }

      
      {props.contentPreview?.summary_values?.solution && <div id="total_question_data">
        <img src={Solution} alt="img kkkkk"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.solution}}></span>
        </div>
      </div>}

      {props.contentPreview?.summary_values?.difficulty && <div id="total_question_data">
        <img src={Difficulty} alt="img iiiii"/>
        <div>
          <span dangerouslySetInnerHTML={{__html: props.contentPreview.summary_values.difficulty}}></span>
        </div>
      </div>}
    </div>
    <div className="instructions"><b>{props?.saveLanguageWiseData?.instructions}</b></div>
    <div className="preview-container">
      <div dangerouslySetInnerHTML={{__html: props.contentPreview.instructions}}></div>
    </div>
    <div className="instructions"><b>{props?.saveLanguageWiseData?.description}</b></div>
    <div className="preview-container">
      <div dangerouslySetInnerHTML={{__html: props.contentPreview.description}}></div>
    </div>
    {props.showAttemptButton &&
      <div Style={props.wChange ? "width:100%":""}
        className={is_faculty_admin || is_admin ? "action-buttonsx2" : "action-buttonsx3" }>
        {props.contentPreview?.assesment_actions?.map((actions, index) => {
          return <button onClick={() => props.attempt(actions.label)} key={`action_${index}`}
            className={`${actions.label}`} id={actions.label.toLowerCase() === 'review' ||
            actions.label.toLowerCase() === 'result' ? "button-reverse" : ""}>
            {actions.label}
          </button>
        })}
      </div>
    }
  </div>
)

export const FacultyAssessmentPreview = (props) => (
  <div className="assesment-preview">
    <div className="title">
      <span>{props.contentPreview.title}</span>
    </div>
    <div className="submit-by-date">
      <div></div>
        { props.contentPreview.submit_by &&
          <span>{props.contentPreview.submit_by}</span>
        }
    </div>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      { props.contentPreview.summary_values.map((item, index) =>
        <div key={`summary_value_${index}`}>
          <div className="total-questions">
            <div>{item.label}</div>
            <div>{item.value}</div>
          </div>
        </div>
      )}
    </div>
    <div className="instructions">Instructions</div>
    <div className="preview-container">
      <div>{props.contentPreview.instructions}</div>
      <div>{props.contentPreview.description}</div>
    </div>
    <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
      <Link to={`/app/faculty/review/${props.contentPreview.content_id}`} className={`Review`}>
        Review
      </Link>
    </div>
  </div>
)

export default AssessmentPreview2;