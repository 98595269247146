import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
//import PathD from '../../public/icons/path-d.svg';
//import PathB from '../../public/icons/path-b.svg';
//import Profile from '../../public/icons/profile.svg';
import ProfileThumb from '../../public/icons/profile-icon.png';
// import Comment from '../../public/icons/comment.svg';
import MonthTable from '../../components/temp';
import Empty from '../../components/error/Empty';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
//import Pagination from "react-js-pagination";
import HeaderMyCoach from '../mycoach/HeaderMyCoach'


class LearnerDoubts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      subjects: [],
      colors: {},
      doubts: [],
      imgShow: '',
      descriptionShow: '',
      opens: false,
      subject: this.props.location.state?.subject_id !== undefined 
                  ? parseInt(this.props.location.state?.subject_id) : 0,
      list: this.props.location.state?.by_me !== undefined ? 1 : 0,
      page: 1,
      total_page: 1,
      current_page: 1,
      course_id:null,
      selected: false,
      pathname:null,
      is_webview: this.props.location.search.split('&')[0].split('=')[1],
    }
  }
  
  componentDidMount() {
    localStorage.setItem("page","ideaspace");
    
    //localStorage.setItem("current_page", this.state.page);
  
    
    this.getLearnerProfile();
    this.setState({pathname:window.location.pathname.split('/').pop()})
    //this.getDoubts(this.state.subject, this.state.list, this.state.page);
    //this.getDoubts(16, this.state.list, this.state.page);
  }

  getLearnerProfile = async () => {
    const response = await this.props.getLearnerProfile();
    if(response.data.subjects !== undefined) {
      this.setState({ course_id: response.data?.classs.id,subjects: response.data?.subjects, color: Object.fromEntries(
        response.data?.subjects?.map(e => [e.id, e.background_code])
      ) });

      localStorage.setItem("ideaspacecourseid",response.data?.classs.id)
      if(response.data?.classs.id === common.CourseId){
        localStorage.setItem("ideaspaceid","29");
      }else if(response.data?.classs.id === common.CourseId5){
        localStorage.setItem("ideaspaceid","28");
      }else{
        localStorage.setItem("ideaspaceid","16");
      }


      var getPageNumber = localStorage.getItem('current_page');
      var getDoubtid = localStorage.getItem('doubtid');
      //let CID = localStorage.getItem("ideaspacecourseid");

      //let subject_id = parseInt(CID) === parseInt(common.CourseId) ? 29 : parseInt(CID) === parseInt(common.CourseId5) ? 28 : 16; 
      let subject_id = parseInt(localStorage.getItem("ideaspaceid"));
      console.log("JJJJJJJJJ"+subject_id)

      if(getPageNumber || getDoubtid){
        
        if(getPageNumber && getDoubtid){

          for(let i=1; i<= parseInt(getPageNumber); i++){
            this.getDoubts(0, this.state.list, i,1);
          }
        }else{
          for(let i=1; i<= parseInt(1); i++){
            this.getDoubts(0, this.state.list, i,1);
          }
        }
      }else{
        getPageNumber = localStorage.removeItem('current_page');
        getDoubtid = localStorage.removeItem('doubtid');
        console.log("enter else")
        //this.getDoubts(this.state.subject, this.state.list, this.state.page);
        this.getDoubts(0, this.state.list, this.state.page,1);
      }
      
    }
  }

  getDoubts = async (subject_id, by_me, page,type) => {
    const response = await this.props.getStudentDoubtList(subject_id, by_me, page,type);
    if(response !== undefined) {
      this.setState({ doubts: response.data.forums,
                        current_page: response?.data.pagination.current_page,
                        total_page: response?.data.pagination.total_page,totalItems: response.data?.pagination?.total_page * 20 });
                        var getDoubtid = localStorage.getItem('doubtid');
                        var getPageNumber = localStorage.getItem('current_page');
                        
                          var element = document.getElementById(parseInt(getDoubtid));
                          console.log("element"+ element)
                          if(element) element.scrollIntoView({behavior: "smooth"});
                        
    }
  }

  seeMore = (subject_id, by_me, page) => {
    var pageNumber = page + 1;
    this.setState({ page: pageNumber });
    this.getDoubts(0, by_me, pageNumber,1);
    localStorage.removeItem("doubtid");
    localStorage.setItem("current_page", pageNumber);
    console.log("current_page" + pageNumber);
  }

  show = (val) => this.setState({ open: true, imgShow: val });
  
  close = () => this.setState({ open: false });

  shows = (val) => this.setState({ opens: true, descriptionShow: val });
  
  closes = () => this.setState({ opens: false });

  onSubjectChange = (val) => {
    this.setState({selected:false})
    this.setState({ subject: val, loading: true, page: 1, doubts: [] });
    this.getDoubts(val, this.state.list, 1,1);
  }

  onListChange = (e) => {
    this.setState({ list: e.target.value, page: 1, doubts: [] });
    this.getDoubts(this.state.subject, e.target.value,1, 1);
  }

  onTabChange = (val) => {
    this.setState({selected:true})
    this.setState({ subject: val, loading: true, page: 1, doubts: [] });
    this.getDoubts(0,val,1, 1);
  }

  getDateTime = (val) => {
    var startDate = new Date(val);
		var currentDate = new Date(Date.now());
		var seconds = (currentDate.getTime() - startDate.getTime()) / 1000;
		var minut = seconds / 60;
    var hours = minut / 60;
    var minut2 = startDate.getMinutes();
    var hours2 = startDate.getHours(); 
		seconds = Math.floor(seconds);
		minut = Math.floor(minut);
		hours = Math.floor(hours);
		
		var date = startDate.getDate();
		var month = startDate.getMonth() + 1;
    var year = startDate.getFullYear();
    if(seconds < 60) {
      return `just now`;
    }
    else if(minut < 60) {
      if(minut <= 1) {
        return `${minut} ${this.props.saveLanguageWiseData.min_ago}`;
      }
      else {
        return `${minut} ${this.props.saveLanguageWiseData.mins_ago}`;
      }
    }
    else if(hours < 24) {
      if(hours <= 1) {
        return `${hours} ${this.props.saveLanguageWiseData.hr_ago}`;
      }
      else {
        return `${hours} ${this.props.saveLanguageWiseData.hrs_ago}`;
      }
    }
    else {
      if(minut2 < 10) {
        minut2 = '0' + minut2;
      }
      if(hours2 < 10) {
        hours2 = '0' + hours2;
      }
      return `${MonthTable[month]} ${date}, ${year}, ${hours2}:${minut2}`;
    }
  }

  show4 = async(page) => {
   
    const response = await this.props.getStudentDoubtList(0, this.state.list, this.state.page,0);
    if(response !== undefined) {
      this.setState({ doubts: response.data.forums});
    }
    this.setState({current_page:page});
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.show4(pageNumber);
    this.setState({current_page: pageNumber});
  }

	render() {

    let CID = localStorage.getItem("ideaspaceid");
    //let subjectid = parseInt(CID); 

    return (
      <>
      <HeaderMyCoach/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="image">
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="image-popup">
            <img src={common.addFileUrl(this.state.imgShow)} alt="zoom"/>
          </div>
        </Popup>
        <Popup open={this.state.opens} closeOnDocumentClick onClose={this.closes}
          className="image">
          <span className="close" onClick={this.closes}>&#x2715;</span>
          <div className="description-popup">
            {this.state.descriptionShow}
          </div>
        </Popup>
       
        <div className={"app-body "+(this.state.is_webview == 1 ? 'webview' : '')}>
          
          <div className="app-div doubt-main">
            <Helmet>
              <title>Ideas Space - Learner</title>
            </Helmet>
            <ReactNotifications/>
            {/* <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/> */}
            <div className="page-header">
              <div className="page-header-left">
                {/* <h1>Idea Space</h1> */}
              </div>
              <div className="page-header-right">
                <div className="page-doubt-subjects">
                  <div onClick={() => this.onSubjectChange(0)}
                    className={this.state.selected === false ? 'selected' : ''}>
                    {this.props.saveLanguageWiseData.all_ideas}
                  </div>
                  <div className={this.state.selected === true ? 'selected' : ''} onClick={() => this.onTabChange(1)}>{this.props.saveLanguageWiseData.my_ideas}</div>
              </div>
                <select onChange={this.onListChange} defaultValue={this.state.list} Style="display:none;">
                  <option value={0}>
                    {this.props.saveLanguageWiseData.all_ideas}
                  </option>
                  <option value={1}>
                    {this.props.saveLanguageWiseData.asked_by_me}
                  </option>
                </select>
              </div>
            </div>
           
            <div className="page-doubt-subjects" Style="display:none;">
              <div onClick={() => this.onSubjectChange(0)}
                id={this.state.subject === 0 ? 'selected-dsubject' : ''}>
               {this.props.saveLanguageWiseData.all}
              </div>
              {this.state.subjects.map(subject =>
                <div key={subject.id} onClick={() => this.onSubjectChange(subject.id)}
                  id={this.state.subject === subject.id ? 'selected-dsubject' : ''}>
                  {subject.label}
                </div>
              )}
            </div>
            <div className="page-data">
              {this.state.doubts.length === 0 && <Empty/>}
              {this.state.doubts.map(doubt => 
                <div className="single-doubt" key={doubt.id} id={doubt.id}>
                  {doubt.media === null ? null 
                    : <div title="Zoom" className="single-doubt-right" 
                      onClick={() => this.show(doubt.media)}
                    style={{ backgroundImage: `url(${common.addFileUrl(doubt.media)})`}}>
                  </div>}
                  <Link className="single-doubt-left" to={{ 
                    pathname: `/learner/doubt-responses/${doubt.id}`,
                    state: {
                      question: doubt,
                      doubtid:doubt.id
                    }
                  }}>
                     <span className="single-doubt-description">
                       <div className="single-doubt-content-block">
                        <div className="desc-content">{doubt.description}</div>
                       </div>
                    </span>
                    <div className="doubt-by">
                      <div className="doubt-by-div">
                        {doubt?.user?.profile_picture.length === 0 
                          ? <div className="doubt-by-profile">
                          <img src={ProfileThumb} alt="user"/>
                        </div> : <div className="doubt-by-profile" style={{backgroundImage: 
                          `url(${common.addFileUrl(doubt?.user?.profile_picture)})`}}>
                        </div>}
                        <div>
                          <span className="doubt-by-name">
                            {doubt.user.name}
                          </span>
                          <span className="doubt-by-time">
                            {this.getDateTime(doubt.added_on)}
                          </span>
                        </div>
                        
                      </div>
                      {/* <div className="single-doubt-info">
                        <span className="single-doubt-subject">{doubt.subject}</span>
                        {/* <div className="responses-count">
                          <img src={Comment} alt="responses"/> 3
                        </div>
                      </div> */}
                    </div>
                    <div className="witharrowlink">{this.props.saveLanguageWiseData.Click_share_thoughts} <img src={BackArrow} alt="witharrowlink"/></div> 
                  </Link>
                  {doubt.description.length > 1685 ? <span id="view_more"
                    onClick={() => this.shows(doubt.description)}>{this.props.saveLanguageWiseData.view_more}</span> 
                      : null}
                  
                </div>
              )}

               {/*<Pagination
                activePage={this.state.current_page}
                itemsCountPerPage={20}
                totalItemsCount={this.state.totalItems}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />*/}
              {this.state.current_page === this.state.total_page ? <div></div>
                : <div className="more_doubts">
                {!this.props.loading ? <button onClick={() => this.seeMore(this.state.subject, this.state.list, this.state.page)}>
                  {this.props.saveLanguageWiseData.see_more}
                </button> : <div>
                  <div className="list-loader"></div>
                </div>}
              </div>}
            </div>
          </div>
        </div>
      
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    currentpage :state.commonReducer.currentpage,
    loading: state.commonReducer.loading,
    logindetails:state.commonReducer.logindetails,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const {setCurrentPage, getLearnerProfile, getStudentDoubtList } = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getStudentDoubtList: (subject_id, by_me, page,type) => dispatch(getStudentDoubtList(subject_id, by_me, page,type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerDoubts);