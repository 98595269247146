import { combineReducers } from 'redux';
import commonReducer from './commonReducer';
import getReducer from './getReducer';
import postReducer from './postReducer';
import classroomReducer from './classroomReducer';

const rootReducer = combineReducers({
  classroomReducer,
  commonReducer,
  getReducer,
  postReducer
});

export default rootReducer;
