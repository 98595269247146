import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';
import Popup from 'reactjs-popup';
import common from '../../common';
import actions from '../../redux/actions';
import Profile from '../../public/icons/profile.svg';
//import PathD from '../../public/icons/path-d.svg';
//import PathB from '../../public/icons/path-b.svg';
import MonthTable from '../../components/temp';
import modelBanner from '../../public/images/model-banner.jpg';
import { isEmpty } from 'lodash';
import AssessmentPreview from '../../components/study-material/CommonComponents';
//import funicon1 from '../../public/icons/mycoach-fun1.png';
import funicon2 from '../../public/icons/mycoach-fun2.png';
//import funicon3 from '../../public/icons/mycoach-fun3.png';
//import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import subscribeicon from '../../public/icons/mycoach-subscribed-icon.png';
import academyicon from '../../public/icons/mycoach-academy-icon.png';
import goalicon from '../../public/icons/mycoach-goal-icon.png';
import CourseImg from '../../public/images/courses.svg';
//import Empty from '../../components/error/Empty';
import Right from '../../public/icons/right-arrow-black.svg';
import Frame10 from '../../public/icons/Frame-10.svg';
import Frame11 from '../../public/icons/Frame-11.svg';
import Frame12 from '../../public/icons/Frame-12.svg';
//import Frame8 from '../../public/icons/Frame-8.svg';
//import Frame9 from '../../public/icons/Frame-9.svg';
//import Target from '../../public/icons/target.svg';
import arrowicon from '../../public/icons/mycoach-arrow-icon.png';
//import askicon from '../../public/icons/mycoach-ask-icon.png';
//import mainBanner from '../../public/images/model-banner.jpg';
import facebookIcon from '../../public/images/facebook-icon.png';
import linkedinIcon from '../../public/images/linkedin-icon.png';
import instagramIcon from '../../public/images/instagram-icon.png';
import mycoachicon1 from '../../public/icons/mycoach-icon1.png';
//import mycoachicon2 from '../../public/icons/mycoach-icon2.png';
//import mycoachicon3 from '../../public/icons/mycoach-icon3.png';
//import mycoachicon4 from '../../public/icons/mycoach-icon4.png';
//import mycoachicon5 from '../../public/icons/mycoach-icon5.png';
//import mycoachicon6 from '../../public/icons/mycoach-icon6.png';
//import mycoachicon7 from '../../public/icons/mycoach-icon7.png';
//import mycoachicon8 from '../../public/icons/mycoach-icon8.png';
//import gamebanner from '../../public/images/imaginxp-gamebanner.jpg'
import Assignment from '../../public/icons/assignment.svg';
import Assessment from '../../public/icons/target.svg';
//import Loader from '../../public/icons/loader.png';
import thanksDialogCheckmark from '../../public/icons/thanksdialog-checkmark.png';
import HeaderMyCoach from './HeaderMyCoach'


import 'react-notifications-component/dist/theme.css';
import './mycoach.css';

class StartTimer extends Component{
  constructor(props){
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    //this.second = 1000;
    //this.minute = this.second * 60;
    //this.hour = this.minute * 60;
    //this.postQuestions = []
    this.state = {
      //currentTime: props.startTime,
      differenceTime:this.props.count
    }
  }

  componentDidMount(){
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {

    let end = new Date();
    this.distance =  this.startTime - end.getTime();
    //this.distance =  this.startTime - 10 * 60 * 1000;

    if(this.distance > 0)
      this.props.onChange(this.distance,this.state.differenceTime)
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken *1000);
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? {color: '#ec6972'} : {}}>

      </span>
    )
  }
}

class MyCoachDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      mycoachdata:[],
      profile: {},
      isvisible:false,
      interest:'',
      location:'',
      company:'',
      thanksisvisible:false,
      tellinterest:'',
      tellInterestData:'',
      isFocused:false,
      hasdata:false,
      default_course:null,
      class_id:null,
      is_corporate:false,
      mode:['CLASSROOM','WEBLINK','LIVE'],
   }
  }

   componentDidMount() {
    localStorage.setItem("page","mycoach");

    this.getCoachListCall();
    this.getMentorInterest();
    this.getTellInterest();
    this.getDashboard();
    }


    getDashboard = async () => {
    const response = await this.props.getStudentDashboard();
    if(response != undefined)
      this.setState({ lecture_dpps: response.data?.lecture_dpps,
                      practices: response.data?.released_exercises});
  }

    getTellInterest = async() =>{

      const response = await this.props.getTellInterest();
      //this.setState({mycoachdata : response?.data})
      if(response?.status === 1){
        this.setState({tellinterest:response?.data?.interest});
        this.setState({hasdata:true});
      }
    }



    getCoachListCall = async() =>{

    const response = await this.props.getMyCoachList();
    //this.setState({mycoachdata : response?.data})
    if(response?.status === 1){
      this.setState({mycoachdata:response?.data?.open_subjects})

    }
  }


  getLearnerProfile = async () => {
    const response = await this.props.getLearnerProfile();
    if(response !== undefined){

      if(response.data.message === "You are not assigned to any class and section, Please contact Admin for assigning into class"){
        this.setState({ profile: response?.data, loading: false,
        no_data: response.data.message === "You are not assigned to any class and section, Please contact Admin for assigning into class"
          ? true : false })
      }else{
        let index = response?.data?.batches?.findIndex(p => p.id === response.data.default_batch_id)

        this.setState({class_id:response.data?.classs.id,default_course:response?.data?.batches[index]?.program.label,userid:response.data?.email,uname:response.data?.username,username:response.data?.name,email:response.data?.email,mobile:response.data?.mobile,profile_picture:response.data?.profile_picture});
        this.setState({ profile: response.data, subjects: response.data.subjects, courses: response.data.batches,current_batch: response.data.default_batch_id,loading: false});

        if(response.data?.classs.id === common.CourseId){
          this.setState({is_corporate: false})
        }
      }
    }
  }

  getMentorInterest = async () => {
    const response = await this.props.getMentorInterest();
    if(response?.status === 1){
      this.setState({ interest: response?.data.interest_area})
      this.setState({ company: response?.data.companies})
      this.setState({ location: response?.data.location})
    }

  }



  openDialog(){
    this.setState({isvisible : true});
  }

  closeDialog(){
    this.setState({isvisible : false});
  }

  thankscloseDialog(){
    this.setState({thanksisvisible : false});
  }


  onHandleChange= (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

  }


  postAction = async () => {
    const {interest,location,company} = this.state;
    //console.log("Interest" + interest + "location" + location+ "company" + company )
    const response = await this.props.xpsendMentorship(interest,location,company);
    //console.log("mentor form"+ response)
      if(response?.status === 1){
        //console.log("statue 1");
        this.setState({thanksisvisible:true})
        this.setState({isvisible : false});
      }else{
        //console.log("statue 0")
        this.setState({thanksisvisible:false})
      }
  }

  postTellInterest = async () => {
    const {tellinterest} = this.state;

    const response = await this.props.xpSubmitTellInterest(tellinterest);
    if(response?.status === 1){

    }
  }

  saveQuestionTime = (time_taken,difference) => {

    /*setInterval(function() {
      if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) window.location.reload();
    }, 60000);  */
     if(Math.floor(time_taken/(1000*60)) * 60 * 1000 === 10 * 60 * 1000) {
      setTimeout(function(){ window.location.reload(true) },60000);
     }
    //
  }

  addClass(){
    this.setState({isFocused : true});
  }

  removeClass(){
    this.setState({isFocused : false});
  }

  getTime = (val) => {
    var date = new Date(val);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var timewithampm = hours + ':' + minutes + ' ' + ampm;
    return timewithampm.toUpperCase();
  }

  getMode = (val) => {
     return this.state.mode[val];
  }

  switchCourse = async(id) =>{
    await this.props.switchCourse(id);
  }

  seeMore = () =>{
    let dashsubject = document.getElementsByClassName("dash-subject");

    if(dashsubject){
      for(let i =3 ; i <= dashsubject.length ; i++){
        if(dashsubject[i])
          dashsubject[i].style.display="flex";
      }

      this.setState({is_arrow: true})
    }
  }

  seeLess = () =>{
    let dashsubject = document.getElementsByClassName("dash-subject");

    if(dashsubject){
      for(let i =3 ; i <= dashsubject.length ; i++){
        if(dashsubject[i])
          dashsubject[i].style.display="none";
      }

      this.setState({is_arrow: false})
    }
  }

	render() {

    const isEnabled = this.state.interest.length > 0 && this.state.location.length > 0 && this.state.company.length > 0;
    //const isEnabledSubmit = this.state.tellinterest.length > 0
    var { isLoaded,is_corporate,loading} = this.state;

    if(loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }

    if(!is_corporate){
    return (
      <>
      <ReactNotifications/>
      <HeaderMyCoach/>
        <div className="app-body">

          <div className="app-div mycoach-div">
            <Helmet>
              <title>My Coach - Learner</title>
            </Helmet>
            <div className="mycoach-wrapper">
              <div className="mycoach-main">
                <div className="mycoach-left">
                  <ol>

                    {this.state.courses?.length && this.state.courses.filter(b => b.classs.id !== 3 && b.classs.id !== 21).map((batches) =>

                       <li onClick={() => this.switchCourse(batches.id)} className="course-academy" Style={batches.id === this.state.current_batch ? "box-shadow: 0 6px 8px rgb(3 18 36 / 90%);animation: shake 0.5s;animation-iteration-count: 3;":""}>
                        <a href="#">
                        <div className="arrow"><img src={arrowicon} /></div>
                        <div className="icon"><img src={subscribeicon} /></div>
                        <div className="content">
                          <h3>{batches.classs.label}</h3>
                          <p>Join live classes </p>
                        </div>
                        </a>
                      </li>

                    )}

                    {/*<li className="gameBanner">
                      <a href="https://game.imaginxp.live/" target="_blank">
                        <div className="arrow"><img src={arrowicon} /></div>
                        <div className="icon"></div>
                        <div className="content">
                          <h3>"Scavenger Hunt"</h3>
                          <p>Gaming Masters</p>
                          <p>are you ready for the Quest?</p>
                        </div>
                      </a>
                    </li>*/}
                    <li className="cacademy">
                      <a href="/mycoach/my-courses">
                        <div className="arrow"><img src={arrowicon} /></div>
                        <div className="icon"><img src={academyicon} /></div>
                        <div className="content">
                          <h3>Certification Academy</h3>
                          <p>Explore other courses for you</p>
                        </div>
                      </a>
                    </li>
                    <li className="goal">
                      <a href="/mycoach/mygoal">
                      <div className="arrow"><img src={arrowicon} /></div>
                      <div className="icon"><img src={goalicon} /></div>
                      <div className="content">
                        <h3>My Goal</h3>
                        <p>Set your goal</p>
                      </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" onClick={()=>this.openDialog()}>
                      <div className="content">
                        <h3>Apply for Mentorship</h3>
                        <span>Apply</span>
                      </div>
                      </a>
                    </li>
                  </ol>

                </div>
                <div className="mycoach-right">
                  <div className="coach-block" Style="height:400px">
                      <h3>Career coach helps you in building industry skills</h3>
                      <ol>
                          {this.state.mycoachdata?.length && this.state?.mycoachdata?.filter(bannertype => bannertype.tag == 'Box_1').map(item =>
                                <li index={item.id}>
                                  <Link className="dash-subject" key={item.id}
                                    to={{ pathname: `/learner/study-material/${item.id}`,
                                    state: {subject_label: item.label}}}>
                                    <i><img src={item.image ? item.image : mycoachicon1}/></i>
                                    {item.label}
                                  </Link>
                              </li>
                            )}
                      </ol>
                  </div>

                  <div className="mycoach-right-bottom">
                      <div className="subcribe-block" Style="display:none">
                        <a href="/learner">
                        <h3>Your Subscribed Certification</h3>
                        <div className="date-block">
                          <div className="date-left">
                            <span>Start Date</span>
                            18 Nov 2020
                          </div>
                          {/* <div className="date-right">
                            <span>Upcoming Class</span>
                            18 Nov at 11AM
                          </div> */}
                        </div>
                        <div className="learning-block">
                          <h4>Learning Outcome</h4>
                          <ul>
                            <li>DT importance in career</li>
                            <li>DT application in Industry</li>
                            <li>Knowledge of DT Tools</li>
                          </ul>
                        </div>
                        </a>
                      </div>
                      <div className="fun-block-list">
                      <h3>Have Some Fun</h3>
                      <ol>
                        <li>
                          <a href="https://game.imaginxp.live/" target="_blank">
                            <div className="arrow"><img src={arrowicon} /></div>
                            <div className="icon"></div>
                            <div className="">
                              <h3>"Scavenger Hunt"</h3>
                              <p>Gaming Masters</p>
                              <p>are you ready for the Quest?</p>
                            </div>
                          </a>
                        </li>
                          {this.state.mycoachdata?.filter(bannertype => bannertype.tag == 'Box_2').map(item =>
                            <li index={item.id} title={item.label}>
                              <a className="is_disabled" title={item.label} href={'/learner/study-material/' + item.id} >
                              <div className="content">
                                <h3>{item.label}</h3>
                                <i><img src={item.image ? item.image : funicon2}/></i>
                              </div>
                              </a>
                            </li>
                          )}
                          {/*<li>
                            <a href="javascript:void(0);" onClick={()=>this.openDialog()}>
                            <div className="content">
                              <h3>Apply for Mentorship</h3>
                              <span>Apply</span>
                            </div>
                            </a>
                          </li>*/}
                      </ol>
                      </div>

                  </div>

                  {/*<div className= {this.state.isFocused ? 'hasfocused  interest-block':'interest-block'}>
                      <h3>Tell Us Your Interest </h3>
                     <div className= {this.state.hasdata ? 'hasdata formBlock':'formBlock'}>
                        <input type="text" className= {this.state.isFocused ? 'hasfocused':''} onChange={this.onHandleChange}  onFocus={()=>this.addClass()} name="tellinterest" value={this.state.tellinterest} placeholder="Type here" />
                        <div className= {this.state.isFocused ? 'hasfocused actions':'actions'}><button disabled={!isEnabledSubmit} onClick={()=>this.postTellInterest()}>Send</button></div>
                      </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>






        <div className= {this.state.isvisible ? 'isvisible internshipDialog':'internshipDialog'}>

           <div className="internshipDialog-inner">
                <div className="popup-header">Apply for Mentorship</div>
                <span className="close" onClick={()=>this.closeDialog()}>&#x2715;</span>
                <div className="internshipdialog-content">
                     <div className="form-block">
                      <label>Your Interest area</label>
                       <input type="text" name="interest"  onChange={this.onHandleChange} value={this.state.interest} placeholder="Type Here" />
                     </div>
                    <div className="form-block">
                      <label>Your Location</label>
                      <input type="text" name="location" onChange={this.onHandleChange} value={this.state.location} placeholder="Type Here" />
                    </div>
                    <div className="form-block">
                      <label>What is your purpose of Mentorship</label>
                      <input type="text" name="company" onChange={this.onHandleChange} value={this.state.company} placeholder="Type Here" />
                    </div>
                </div>
              <div className="internshipdialog-action">
                <button disabled={!isEnabled} onClick={this.postAction}>
                  Submit
                </button>
              </div>
            </div>
          </div>


          <div className= {this.state.thanksisvisible ? 'isvisible thanksApplyDialog':'thanksApplyDialog'}>
          <div className="thanksApplyDialog-inner">
              <div className="popup-header"><i><img src={thanksDialogCheckmark} /></i>Thank you for Applying</div>
              <span className="close" onClick={()=>this.thankscloseDialog()}>&#x2715;</span>
              <div className="thanksApplyDialog-content">
                We will contact with you soon
              </div>
          </div>
        </div>
      </>
    )
  }else{
    return (
      <>

        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content2 modelBanner-content2">
          <div className="courses-popup modelBanner">
            {/* <span>Switch Course</span> */}
            {/* <Carousel containerClass="courses-popup-tray" responsive={responsive}
              keyBoardControl={false} swipeable={false} draggable={false}
              removeArrowOnDeviceType={["tablet", "mobile"]}>
              {this.state.courses.map(course =>
                <div className="course-div" key={course.id}
                  onClick={() => this.switchCourse(course.id)}>
                  <div style={course.id === this.state.current_batch
                    ? {backgroundColor: '#B0E1E5'} : {}}>
                    <img src={CourseImg} alt="course"/>
                  </div>
                  <div>
                    {course.program.label}
                  </div>
                </div>
              )}
            </Carousel> */}
            <img src={modelBanner}/>
          </div>
        </Popup>
        {!isEmpty(this.props.contentPreview)
          && <Popup className="attempt-modal" open={this.state.showModal} closeOnDocumentClick
          onClose={this.closeAttemptModal}>
          <AssessmentPreview
            contentPreview={this.props.contentPreview}
            attempt={this.attemptPaper}
            showAttemptButton={true}
          />
        </Popup>}

        <div className="app-body">
          <ReactNotifications/>
          <div className="app-div app-dashboard">
            <Helmet>
              <title>Dashboard - Learner</title>
            </Helmet>
            {/* <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/> */}
            <div className="dashboard-main">
              <div className="dashboard-left">

                {/*<div className="all-courses">
                  <a className="goto-course" onClick={() => this.getwpUserToken('testprep')}>
                    <div className="course-image">
                      <div className="course-image-div">
                        <img src={TestPrep} alt="img"/>
                      </div>
                    </div>
                    <span>
                      Test Prep Courses
                    </span>
                  </a>
                  <a className="goto-course" onClick={() => this.getwpUserToken('learncode')}>
                    <div className="course-image">
                      <div className="course-image-div">
                        <img src={Coding} alt="img"/>
                      </div>
                    </div>
                    <span>
                      Learning Code
                    </span>
                  </a>
                   <a className="goto-course" onClick={() => this.getwpUserToken('schoolcode')}>
                    <div className="course-image">
                      <div className="course-image-div">
                        <img src={CodeLab} alt="img"/>
                      </div>
                    </div>
                    <span>
                      School Coding Lab
                    </span>
                  </a>
                </div>*/}

                {/* <div className="banner-section">
                  <img src={mainBanner}/>
                </div> */}
                <div className="topHeading">
                  {/*<a href="app/mycoach"><img src={BackArrow} /></a>*/}<h3>UX Jump Starter</h3>
                </div>
                <div className='welcomeheading'>The ImaginXP UX Jumpstarted course comprises of interactive live classrooms, assignments & special masterclasses with industry experts. This course enables you to take a jump in UX career in the corporate world.</div>
                  {/* {this.state.courses.length > 0 ? <div className='welcomeheading'>You have one subscribed course <span>{this.state.batch_name}</span></div> : '' } */}

                {this.state.courses?.length > 0 && <div className="dashboard-exercise" Style="display:none;">
                  <h1>My Courses</h1>
                  <div className="dashboard-practices-course">
                    {this.state.courses?.map(course =>
                      <div className="course-div" key={course.id}>
                        <div style={course.id === this.state.current_batch
                          ? {backgroundColor: 'var(--primary-rgba)'} : {}}>
                          <img src={CourseImg} alt="course"/>
                        </div>
                        <div>
                          {course.program.label}
                        </div>
                      </div>
                    )}
                  </div>
                </div>}

                {/* <h1>{this.state.profile?.classs?.label} Subjects</h1> */}
                <h1>Your Activities</h1>
                <div className="dashboard-subjects">
                  {this.state.subjects?.map((subject,index) =>
                    <Link className="dash-subject" Style={index >2 ? "display:none":""} id={"dash_"+index} key={subject.id}
                      to={{ pathname: `/learner/study-material/${subject.id}`,
                      state: {subject_label: subject.label}}}
                      style={{backgroundColor: subject.background_code}}>
                      <div>
                        <img src={common.addFileUrl(subject.url)} alt="sub"/>
                      </div>
                      <span>{subject.label}</span>
                    </Link>
                  )}
                </div>

                {!this.state.is_arrow ? <span className="dashboard-list-downarrow" onClick={this.seeMore} Style={this.state.subjects.length <=3 ?"display:none":""}><i className="fa fa-chevron-down"></i></span> : <span className="dashboard-list-downarrow" onClick={this.seeLess} Style={this.state.subjects.length <=3 ?"display:none":""}><i className="fa fa-chevron-up"></i></span>}

                <div className="dashboard-assignment-list">
                  <a href="/learner/assignment">
                    <div className="arrow"><img src={arrowicon} /></div>
                    <div className="icon"><img src={Assignment} /></div>
                    <div className="content">
                    <h3>Assignment</h3>
                    </div>
                  </a>
                  <a href="/learner/tests">
                    <div className="arrow"><img src={arrowicon} /></div>
                    <div className="icon"><img src={Assessment} /></div>
                    <div className="content">
                    <h3>Assessment</h3>
                    </div>
                  </a>
                </div>


                {this.state.categoryList && this.state.categoryList?.length > 0 && <div Style="display:none;">

                  {this.state.categoryList?.map((list, index) =>{
                    return (
                      <div >
                        {!list.parent && <div className="dashboard-category">
                          <Link onClick={() => this.getwpUserToken('testprep',list.permalink)}>
                            <h1>{list.name}</h1>
                            <img src={Right} alt="img"/>
                          </Link>
                          <div className="dashboard-practices">
                            {this.state.categoryList?.map((subtest, key) =>

                                <div Style={list.id != subtest.parent ?"display:none":"display:block;"} className="category-list">
                                {list.id == subtest.parent &&
                                  <a className="goto-course-list" href={subtest.permalink} target="_blank">

                                    <div className="course-image">
                                      <div className="course-image-div">
                                        <img src={subtest.image} alt="img"/>
                                      </div>
                                    </div>
                                    <span>
                                      {subtest.name}
                                    </span>
                                  </a>
                                }
                              </div>
                            )}
                          </div>
                          </div>
                        }
                      </div>
                    );
                  })}
                  </div>}


                <div className="dashboard-category" Style="display:none;">
                  <Link onClick={() => this.getwpUserToken('testprep')}>
                    <h1>Career Booster</h1>
                    <img src={Right} alt="img"/>
                  </Link>
                  <div className="dashboard-practices">
                    <div Style="width:200px;margin-right: 20px;margin-bottom: 16px;box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);">
                      <a className="goto-course-list" href="https://schoolsonweb.com/career-listing/" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame10} alt="img"/>
                          </div>
                        </div>
                        <span>
                          Explore Career
                        </span>
                      </a>
                    </div>
                    <div Style="width:200px;margin-right: 20px;margin-bottom: 16px;box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);">
                      <a className="goto-course-list" href="https://schoolsonweb.com/opportunity-listing/" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame11} alt="img"/>
                          </div>
                        </div>
                        <span>
                          Explore Opportunities
                        </span>
                      </a>
                    </div>
                    <div Style="width:200px;margin-right: 20px;margin-bottom: 16px;box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);">
                      <a className="goto-course-list" href="https://schoolsonweb.com/scholarships/" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame12} alt="img"/>
                          </div>
                        </div>
                        <span>
                          Explore Scholarship
                        </span>
                      </a>
                    </div>

                    {/*<div Style="width:200px;margin-right: 20px;margin-bottom: 16px;box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);">
                      <a className="goto-course-list" href="#" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame8} alt="img"/>
                          </div>
                        </div>
                        <span>
                          Explore Exams
                        </span>
                      </a>
                    </div>*}
                    {/*<div Style="width:200px;margin-right: 20px;margin-bottom: 16px;box-shadow: 0 8px 18px 0 rgba(72, 145, 152, 0.2);">
                      <a className="goto-course-list" href="#" target="_blank">
                        <div className="course-image">
                          <div className="course-image-div">
                            <img src={Frame9} alt="img"/>
                          </div>
                        </div>
                        <span>
                          Explore Free Resources
                        </span>
                      </a>
                    </div>*/}
                  </div>
                </div>


              </div>
              <div className="dashboard-right">
                <div className="dashboard-profile">
                  <div className="dash-prof-img" Style="display:none;">
                    {this.state.profile?.profile_picture === ''
                      || this.state.profile?.profile_picture === null
                      ? <img src={Profile} alt="img"/>
                      : <div className="dash-prof-img-in" style={{backgroundImage:
                      `url(${common.addFileUrl(this.state.profile?.profile_picture)})`}}>
                    </div>}
                  </div>
                  <div className="dash-prof-name" Style="display:none;">{this.state.profile?.name}</div>
                  {/* <div className="drop-profile-creds" onClick={this.show}>
                    <div>{this.state.batch_name}</div>
                  </div> */}
                  <div className="drop-profile-creds">
                    <div>Course Schedule</div>
                  </div>
                  <div className="profile-email" Style="display:none;">
                  <div>{this.state.profile?.mobile}</div>
                  <div>{this.state.profile?.email}</div>
                  </div>
                  <hr Style="border: 1px solid #72c1be; !important;width: 34px;"/>
                  <div className="profile-social-icon" Style="display:none;">
                    <Link><img src={facebookIcon} /></Link>
                    <Link><img src={linkedinIcon} /></Link>
                    <Link><img src={instagramIcon} /></Link>
                  </div>
                </div>
                <div className="dashboard-schedule">
                  <div className="viewall-btn"><a href="/learner/sessions">View all</a></div>
                  {/* <div className="dash-sch-head">
                    <div>
                      <span>Upcoming Activities</span>
                    </div>
                  </div> */}
                  {this.state.lecture_dpps?.map(lecture => {
                    if(lecture.id !== undefined) {
                      return <div className="dash-lecture" key={lecture.id}>
                      {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime}
                       count={0} ref={this.questionTimer}/>}
                        <div className="dash-lecture-date">
                          <div>{(new Date(lecture.start_date_time)).getDate()}</div>
                          <span>{MonthTable[(new Date(lecture.start_date_time)).getMonth() + 1]}</span>
                          {this.getTime(lecture.start_date_time)}
                        </div>
                        <div className="dash-lecture-date-title">
                          <div className="left">
                            <div className="dash-lec-title">{lecture.title}</div>
                            <div className="dash-lec-title-hrs">{lecture.duration_hrs} hrs</div>
                            <div className="dash-lec-title-hrs">{lecture.faculty ? "By" +" "+lecture.faculty:""}</div>
                            <span>{lecture.action_label != "Join Class" && lecture?.action_label}</span>
                          </div>
                          <div className="dash-lecture-date-third">
                            <div>
                              {lecture.type === 'Lecture' ? <span>{this.getMode(lecture.mode)}</span> : null}
                            </div>
                            <div Style={lecture.action_label && lecture.action_label === "Join Class"  ? "display:block":"display:none"}>
                              {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label
                                !== undefined && lecture.action_label === "Join Class"
                                ? <a href={lecture.url} target="_blank">
                                {lecture.action_label}
                              </a> : null}
                            </div>
                          </div>
                        </div>


                      </div>
                    }
                    else {
                      return <div className="dash-lecture" key={lecture.content_id}>
                        <div className="dash-lecture-date">
                          <div>{(new Date(lecture.due_date)).getDate()}</div>
                          <span>{MonthTable[(new Date(lecture.due_date)).getMonth() + 1]}</span>
                          {this.getTime(lecture.due_date)}
                        </div>
                        <div className="dash-lecture-date-title">
                        <div className="left">
                          <div className="dash-lec-title">{lecture.title}</div>
                          <div className="dash-lec-title-hrs">{this.convertedHour(lecture.duration_mins)}</div>
                          <div className="dash-lec-title-hrs">{lecture.faculty ? "By" +" "+lecture.faculty:""}</div>
                          <span className="dash-lec-hrs">{lecture.time_left}</span>
                          </div>
                          <div className="dash-lecture-date-third">
                          <div Style="background-color:#fff6e4;font-family: lato;">
                            {lecture.type === 'DPPPlanner' ? <span>REAL TEST</span> : null}
                          </div>
                          <div Style={lecture.actions.length == 0 ? "display:none":"display:block"}>
                            {lecture.actions.map(action =>
                              <a index={action.id}
                                onClick={()=>this.attemptAndReview(action.label, lecture.content_id)}>
                                {action.label}
                              </a>

                            )}
                          </div>
                        </div>
                        </div>


                      </div>
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage,
    learnerprofile : state.classroomReducer.learnerprofile

	}
}

const mapDispatchToProps = (dispatch) => {
  const { getMyCoachList,getLearnerProfile,switchCourse,getStudentDashboard,xpsendMentorship,getMentorInterest,xpSubmitTellInterest,getTellInterest} = actions;
  return {
    getMyCoachList: () => dispatch(getMyCoachList()),
    getStudentDashboard: () => dispatch(getStudentDashboard()),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    xpsendMentorship: (interest_area,companies,location) => dispatch(xpsendMentorship(interest_area,companies,location)),
    getMentorInterest: () => dispatch(getMentorInterest()),
    xpSubmitTellInterest: (interest) => dispatch(xpSubmitTellInterest(interest)),
    getTellInterest: () => dispatch(getTellInterest()),
    switchCourse: (batch_id) => dispatch(switchCourse(batch_id)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCoachDashboard);
