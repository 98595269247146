import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';
import axios from 'axios';
import common from '../../common';
import actions from '../../redux/actions';
//import PathD from '../../public/icons/path-d.svg';
//import PathB from '../../public/icons/path-b.svg';
//import Profile from '../../public/icons/profile.svg';
import ProfileThumb from '../../public/icons/profile-icon.png';
// import Comment from '../../public/icons/comment.svg';
import MonthTable from '../../components/temp';
import Empty from '../../components/error/Empty';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
//import Pagination from "react-js-pagination";


class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
      username: ''
    }
  }


  render() {


    //let subjectid = parseInt(CID); 

    return (
      <>

        <div className={"app-body support-page " + (this.state.is_webview == 1 ? 'webview' : '')}>

          <div className="app-div">
            <Helmet>
              <title>Support</title>
            </Helmet>
            <ReactNotifications />



            <div className="supportPageWrapper">
              <div className="supportPageContainer">
                <h3>Grievance Redressal, Student Support and Escalation</h3>
                <p>How to reach University Support Services?</p>
                <ol>
                  <li>The Student Support Team can be reached via Students My Account</li>
                  <li>The student can logon to their My Account and students can post their queries enabling the
                    support team to revert for query resolution.</li>
                </ol>
                <div className="tableWrapper">
                  <table>
                    <tbody><tr>
                      <th>Particulars</th>
                      <th>Contact Details</th>
                      <th>Cell Number</th>
                      <th>Remark</th>
                    </tr>
                      <tr>
                        <td>MyAccount - Ticketing System</td>
                        <td colspan="3"><a href="mailto:esupport@onlinevgu.com">esupport@onlinevgu.com</a><br />This is First Contact Point where through Self-Service Portal, the student raises a Support Ticket through My Account for Support</td>
                      </tr>
                      <tr>
                        <td>Support Level 1</td>
                        <td><a href="mailto:studentdesk@onlinevgu.com">studentdesk@onlinevgu.com</a></td>
                        <td colspan="2">If through Ticketing System, query is not responded or resolved then mail to Support ID</td>
                      </tr>
                      <tr>
                        <td>Support Level - 2</td>
                        <td><a href="mailto:studentdesk@onlinevgu.com">studentdesk@onlinevgu.com</a></td>
                        <td>XXXXXXX New Number would be incorporated</td>
                        <td>If the above Support Level does not respond or resolve connect to Second Level</td>
                      </tr>
                      <tr>
                        <td>Support Level - 3</td>
                        <td>Director's Mail ID</td>
                        <td>XXXXXXX New Number would be incorporated</td>
                        <td>If Second Level does not respond or resolve connect to Third Level</td>
                      </tr>
                    </tbody></table>
                </div>
              </div>
            </div>

          </div>

        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentpage: state.commonReducer.currentpage,
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage, getwpUserToken, getLearnerProfile, getStudentDoubtList } = actions;
  return {
    getwpUserToken: () => dispatch(getwpUserToken()),
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);