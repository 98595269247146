
const faculty = [
	{
		id: 1,
		name: 'Faculty01',
		center: 'Gurugram',
		subjects: ['Physics - XIIJA']
	},
	{
		id: 2,
		name: 'Faculty02',
		center: 'Faridabad',
		subjects: ['Physics - XIIJA', 'Chemistry - XIIJA', 'Biology - XIIJA']
	},
	{
		id: 3,
		name: 'Faculty03',
		center: 'Gurugram',
		subjects: ['Physics - XIIJA', 'SST - XIIJA']
	},
	{
		id: 4,
		name: 'Faculty04',
		center: 'Safdarjang',
		subjects: ['Physics - XIIJA', 'Chemistry - XIIJA']
	},
	{
		id: 5,
		name: 'Faculty05',
		center: 'Gurugram',
		subjects: ['Chemistry - XIIJA']
	},
	{
		id: 6,
		name: 'Faculty06',
		center: 'Gurugram',
		subjects: ['Physics - XIIJA']
	},
	{
		id: 7,
		name: 'Faculty07',
		center: 'Gurugram',
		subjects: ['Maths - XIIJA', 'Physics - XIIJA']
	},
	{
		id: 8,
		name: 'Faculty08',
		center: 'Kanpur',
		subjects: ['Biology - XIIJA', 'Physics - XIIJA']
	},
	{
		id: 9,
		name: 'Faculty09',
		center: 'Bangalore',
		subjects: ['Chemistry - XIIJA']
	},
	{
		id: 10,
		name: 'Faculty10',
		center: 'Gurugram',
		subjects: ['Physics - XIIJA']
	}
]

const students = [
	{
		id: 1,
		name: 'Ankur Singh',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 2,
		name: 'Aarushi Thorat',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 3,
		name: 'Anupam Nair',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 4,
		name: 'Diya Benny',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 5,
		name: 'Tanmay Bhatnagar',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 6,
		name: 'Ansh Dokania',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 7,
		name: 'Ravi Dubey',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 8,
		name: 'Sidharth Shukla',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	},
	{
		id: 9,
		name: 'Prithviraj Lekha',
		class: 'XII-JEE(Mains)',
		program: 'AJAY',
		phase: 'Phase01 (ER - XII JEE-Mains)',
		batch: 'TEST6',
		session: 'Session 2020-2021 XIII JEE Mains',
	}
]

const assessments = [
	{
		id: 1,
		title: 'Assessment 1',
		section: 'Section 1',
		class: 'Class 7',
		subject: 'Maths',
		faculty: 'Ravi Kumar',
		published: true
	},
	{
		id: 2,
		title: 'Assessment 2',
		section: 'Section 2',
		class: 'Class 8',
		subject: 'Physics',
		faculty: 'Akshay Kumar',
		published: true
	},
	{
		id: 3,
		title: 'Assessment 3',
		section: 'Section 1',
		class: 'Class 7',
		subject: 'Maths',
		faculty: 'Salman Khan',
		published: false
	},
	{
		id: 4,
		title: 'Assessment 4',
		section: 'Section 3',
		class: 'Class 10',
		subject: 'Chemistry',
		faculty: 'Rajesh Khanna',
		published: true
	},
	{
		id: 5,
		title: 'Assessment 5',
		section: 'Section 3',
		class: 'Class 11',
		subject: 'Biology',
		faculty: 'Kenny Sebastian',
		published: false
	},
	{
		id: 6,
		title: 'Assessment 6',
		section: 'Section 3',
		class: 'Class 11',
		subject: 'Biology',
		faculty: 'Kenny Sebastian',
		published: true
	},
	{
		id: 7,
		title: 'Assessment 7',
		section: 'Section 3',
		class: 'Class 11',
		subject: 'Biology',
		faculty: 'Kenny Sebastian',
		published: true
	},
	{
		id: 8,
		title: 'Assessment 8',
		section: 'Section 3',
		class: 'Class 11',
		subject: 'Biology',
		faculty: 'Kenny Sebastian',
		published: false
	},
	{
		id: 45,
		title: 'Assessment 8',
		section: 'Section 3',
		class: 'Class 11',
		subject: 'Biology',
		faculty: 'Kenny Sebastian',
		published: false
	},
	{
		id: 9,
		title: 'Assessment 9',
		section: 'Section 3',
		class: 'Class 11',
		subject: 'Biology',
		faculty: 'Kenny Sebastian',
		published: false
	},
	{
		id: 10,
		title: 'Assessment 10',
		section: 'Section 3',
		class: 'Class 11',
		subject: 'Biology',
		faculty: 'Kenny Sebasti',
		published: true
	}
]

const branches = [
	{
		id: 1,
		name: 'Brand Name',
		admin: 'Pallavi Singla',
		code: '10345',
		state: 'Uttar Pradesh',
		city: 'Gurugram',
		country: 'India'
	},
	{
		id: 2,
		name: 'Brand Name',
		admin: 'Pallavi Singla',
		code: '10345',
		state: 'Uttar Pradesh',
		city: 'Gurugram',
		country: 'India'
	},
	{
		id: 3,
		name: 'Brand Name',
		admin: 'Pallavi Singla',
		code: '10345',
		state: 'Uttar Pradesh',
		city: 'Gurugram',
		country: 'India'
	},
	{
		id: 4,
		name: 'Brand Name',
		admin: 'Pallavi Singla',
		code: '10345',
		state: 'Uttar Pradesh',
		city: 'Gurugram',
		country: 'India'
	},
]

const toc_data = [
  {
    classs: 'Class 2',
    subject: 'PHY',
    img: ''
  },
  {
    classs: 'Class 3',
    subject: 'MTH',
    img: ''
  }
]

const content = [
  {
    id: 1,
    type: 0,
    title: 'Content 1',
    published: true,
    complete: '75%'
  },
  {
    id: 2,
    type: 1,
    title: 'Content 2',
    published: true,
    complete: '50%'
  },
  {
    id: 3,
    type: 2,
    title: 'Content 3',
    published: false,
    complete: '25%'
  },
  {
    id: 4,
    type: 2,
    title: 'Content 4',
    published: true,
    complete: '100%'
  },
  {
    id: 5,
    type: 0,
    title: 'Content 5',
    published: false,
    complete: '75%'
  }
]

export default {
	faculty,
	students,
	assessments,
  branches,
  toc_data,
  content
}