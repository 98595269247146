import { store } from 'react-notifications-component';
import { LANGUAGE_DATA, LOADING ,NOTIFICATION,PAGEWISE_LANGUAGE_DATA } from "../constants";

import Service from '../../utils/Service';
import common from '../../common';
import lmsService from '../../utils/Service';

const createCenter = (name, code, country, state, city, logo, center_type, parent, admin) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createCenter}`;
  const data = new FormData();
  if(name !== null) {
    data.append('name', name);
  }
  if(code !== null) {
    data.append('code', code);
  }
  if(country !== null) {
    data.append('country', country);
  }
  if(state !== null) {
    data.append('state', state);
  }
  if(city !== null) {
    data.append('city', city);
  }
  if(logo !== null && logo !== undefined) {
    data.append('logo', logo);
  }
  if(center_type !== null && center_type !== undefined) {
    data.append('center_type', center_type);
  }
  if(parent !== null) {
    data.append('parent', parent);
  }
  if(admin !== null) {
    data.append('admin', admin);
  }
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const assignmentCreate = (uid,title,type,timed_duration_mins,total_marks,passing_marks,attempts_allowed,is_graded,closed_after_passing,allowed_after_duedate,difficulty,instructions,description,class_id,subject_id,is_published,toc_id,center,file) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.assignmentCreate}`;
  const data = new FormData();

  if(title !== null) {
    data.append('title', title);
  }
  if(uid !== null) {
    data.append('uid', uid);
  }
  if(type !== null) {
    data.append('type', type);
  }
  if(timed_duration_mins !== null) {
    data.append('timed_duration_mins', timed_duration_mins);
  }
  if(total_marks !== null) {
    data.append('total_marks', total_marks);
  }
  if(file !== null && file !== undefined) {
    data.append('file', file);
  }
  if(passing_marks !== null && passing_marks !== undefined) {
    data.append('passing_marks', passing_marks);
  }
  if(attempts_allowed !== null) {
    data.append('attempts_allowed', attempts_allowed);
  }
  if(closed_after_passing !== null) {
    data.append('closed_after_passing', closed_after_passing);
  }
  if(allowed_after_duedate !== null){
    data.append('allowed_after_duedate', allowed_after_duedate);
  }
  if(is_graded !== null) {
    data.append('is_graded', is_graded);
  }
  if(difficulty !== null) {
    data.append('difficulty', difficulty);
  }
  if(instructions !== null) {
    data.append('instructions', "NA");
  }
  if(description !== null) {
    data.append('description', description);
  }
  if(class_id !== null) {
    data.append('class_id', class_id);
  }
  if(subject_id !== null) {
    data.append('subject_id', subject_id);
  }
  if(is_published !== null) {
    data.append('is_published', is_published);
  }
  if(toc_id !== null) {
    data.append('toc_id',toc_id);
  }
  if(center !== null) {
    data.append('center', center);
  } 

  const response = Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
    //window.location.href=history.go(-1);;
  }).catch(er => { 
    console.log(er)
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.uid){
      message=er.message[0]?.uid;
    }else{
      message="";
      for(let key in er.message[0]){
        message += key +":"+er.message[0][key];
      }
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })

  return response;
};

const publishAssignment = (id, is_published) => async dispatch => {
  const url = `${common.editAssignment}/${id}/`;
  Service.post(url, { is_published }, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const assignmentEdit = (id,uid,title,type,timed_duration_mins,total_marks,passing_marks,attempts_allowed,is_graded,closed_after_passing,allowed_after_duedate,difficulty,instructions,description,class_id,subject_id,is_published,toc_id,center,file) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url =  `${common.editAssignment}/${id}/`;
  const data = new FormData();
  if(title !== null) {
    data.append('title', title);
  }
  if(uid !== null) {
    data.append('uid', uid);
  }
  if(type !== null) {
    data.append('type', type);
  }
  if(timed_duration_mins !== null) {
    data.append('timed_duration_mins', timed_duration_mins);
  }
  if(total_marks !== null) {
    data.append('total_marks', total_marks);
  }
  if(file !== null && file !== undefined) {
    data.append('file', file);
  }
  if(passing_marks !== null && passing_marks !== undefined) {
    data.append('passing_marks', passing_marks);
  }
  if(attempts_allowed !== null) {
    data.append('attempts_allowed', attempts_allowed);
  }
  if(closed_after_passing !== null) {
    data.append('closed_after_passing', closed_after_passing);
  }
  if(allowed_after_duedate !== null){
    data.append('allowed_after_duedate', allowed_after_duedate);
  }
  if(is_graded !== null) {
    data.append('is_graded', is_graded);
  }
  if(difficulty !== null) {
    data.append('difficulty', difficulty);
  }
  if(instructions !== null) {
    data.append('instructions', "NA");
  }
  if(description !== null) {
    data.append('description', description);
  }
  if(class_id !== null) {
    data.append('class_id', class_id);
  }
  if(subject_id !== null) {
    data.append('subject_id', subject_id);
  }
  if(is_published !== null) {
    data.append('is_published', is_published);
  }
  if(toc_id !== null) {
    data.append('toc_id',toc_id);
  }
  if(center !== null) {
    data.append('center', center);
  } 

  const response = Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

     return response;
    //window.location.reload();
  }).catch(er => { 
    console.log(er)
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.uid){
      message=er.message[0]?.uid;
    }else{
      message="";
      for(let key in er.message[0]){
        message += key +":"+er.message[0][key];
      }
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })

  return response;
};


const updateCenter = (center_id, name, code, country, state, city, logo, admin) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateCenter}/${center_id}/`;
  const data = new FormData();
  if(name !== null) {
    data.append('name', name);
  }
  if(code !== null) {
    data.append('code', code);
  }
  if(country !== null) {
    data.append('country', country);
  }
  if(state !== null) {
    data.append('state', state);
  }
  if(city !== null) {
    data.append('city', city);
  }
  if(logo !== null && logo !== undefined && typeof(logo) !== 'string') {
    data.append('logo', logo);
  }
  if(logo === null) {
    data.append('logo', '');
  }
  if(admin !== null) {
    data.append('admin', admin);
  }
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteCenter = (id, center_id) => dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteCenter}${id}/`;

  Service.post(url, {center_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const createAdmin = (name, email, mobile, password, center, employee_code,language) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createAdmin}`;
  Service.post(url, {name, email, mobile, password, center, employee_code,language}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false });
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const updateAdmin = (id, name, email, mobile, password, center, profile_picture, employee_code) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateAdmin}${id}/`;
  const data = new FormData();
  if(profile_picture !== null && profile_picture !== undefined
      && typeof(profile_picture) !== 'string') {
    data.append('profile_picture', profile_picture);
  }
  if(profile_picture === null) {
    data.append('profile_picture', '');
  }
  data.append('employee_code', employee_code);
  data.append('name', name);
  data.append('email', email);
  data.append('mobile', mobile);
  data.append('center', center);
  if(password.length !== 0) {
    data.append('password', password);
  }
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    if(response.status) {
      window.location.reload();
    }
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteAdmin = (id, center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteAdmin}${id}/`;
  await Service.post(url, {center_id}).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();
}

const createClass = (label, description, short_code, center, order,level,tag) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createClass}`
  Service.post(url, {label, description, short_code, center, order,level,tag}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    console.log(er)
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const updateClass = (center, class_id, label, description, short_code, order,level,tag) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.updateClass}${class_id}/`
  Service.post(url, {center, label, description, short_code, order,level,tag}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteClass = (id, center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteClass}${id}/`;
  Service.post(url, {center_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const createSection = (label, short_code, center, class_id,session,is_current) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createSection}`;
  Service.post(url, {label, short_code, center, class_id,session,is_current}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const updateSection = (section_id, label, short_code, center, class_id,session,is_current) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateSection}${section_id}/`
  Service.post(url, {label, short_code, center, class_id,session,is_current}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteSection = (id, center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteBatch}${id}/`;
  await Service.post(url, {center_id}).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();
}

const createSubject = (label, short_code, classs, master_subject, background_code, image, thumbnail, center) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createSubject}`;
  let data = new FormData();
  data.append('label', label);
  data.append('short_code', short_code);
  data.append('classs', classs);
  data.append('master_subject', master_subject);
  data.append('background_code', background_code);
  data.append('image', image);
  if(thumbnail !== null) {
    data.append('thumbnail', thumbnail);
  }
  data.append('center', center);
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const updateSubject = (subject_id, label, short_code, classs, master_subject, background_code, image, thumbnail, center) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateSubject}${subject_id}/`;
  let data = new FormData();
  data.append('image', image);
  if(thumbnail !== null && typeof(thumbnail) !== 'string') {
    data.append('thumbnail', thumbnail);
  }
  if(thumbnail === null) {
    data.append('thumbnail', '');
  }
  data.append('label', label);
  data.append('short_code', short_code);
  data.append('classs', classs);
  data.append('master_subject', master_subject);
  data.append('background_code', background_code);
  data.append('center', center);
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteSubject = (id, center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteSubject}${id}/`;
  /*await Service.post(url, {center_id}).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();*/

   Service.post(url,{center_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.log(er)

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const createFaculty = (name, email, employee_code, mobile, password, center,linked_in,instagram,openai_api_key, openai_assistant_key) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createFaculty}`
  Service.post(url, {name, email, employee_code, mobile, password, center,linked_in,instagram,openai_api_key, openai_assistant_key}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    if(response.status === 1) {
      window.location.reload();
    }
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const updateFaculty = (faculty_id, name, email, mobile, password, profile_picture, classes, batches, subjects, employee_code,linked_in,instagram, openai_api_key, openai_assistant_key) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateFaculty}${faculty_id}/`;
  const data = new FormData();
  if(profile_picture !== null && profile_picture !== undefined
      && typeof(profile_picture) !== 'string') {
    data.append('profile_picture', profile_picture);
  }
  if(profile_picture === null) {
    data.append('profile_picture', '');
  }
  for(let i = 0; i < classes.length; i++) {
    data.append('classs', classes[i].id);
  }
  for(let i = 0; i < batches.length; i++) {
    data.append('batch', batches[i].batch_id);
  }
  for(let i = 0; i < subjects.length; i++) {
    data.append('subject', subjects[i].id);
  }
  if(classes.length === 0) {
    data.append('classs', '');
  }
  if(batches.length === 0) {
    data.append('batch', '');
  }
  if(subjects.length === 0) {
    data.append('subject', '');
  }
data.append('openai_api_key', openai_api_key);
data.append('openai_assistant_key', openai_assistant_key);


  data.append('name', name);
  data.append('email', email);
  data.append('mobile', mobile);
  data.append('employee_code', employee_code);
  data.append('linked_in', linked_in);
  data.append('instagram', instagram);
  if(password.length !== 0) {
    data.append('password', password);
  }
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    if(response.status === 1) {
      window.location.reload();
    }
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteFaculty = (id, center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteFaculty}${id}/`;
  await Service.post(url, {center_id}).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();

  /*Service.post(url,{center_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.log(er)

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })*/
}

const createStudent = (name, email, mobile, password, center, roll_no) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.createStudent}`;

  Service.post(url, {name, email, mobile, password, center, roll_no}, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    if(response.status === 1) {
      window.location.reload();
    }
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false });
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const updateStudent = (id, name, email, mobile, password, classs, profile_picture, batch, openai_api_key, openai_assistant_key) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  console.log("get the data os ", id, name, email, mobile, password, classs, profile_picture, batch, openai_api_key, openai_assistant_key)
  const url = `${common.updateStudent}${id}/`;
  const data = new FormData();
  if(profile_picture !== null && profile_picture !== undefined
      && typeof(profile_picture) !== 'string') {
    data.append('profile_picture', profile_picture);
  }
  if(profile_picture === null) {
    data.append('profile_picture', '');
  }
  data.append('name', name);
  data.append('email', email);
  data.append('mobile', mobile);

  if(openai_api_key != null){
    data.append('openai_api_key', openai_api_key);
  }
  if(openai_assistant_key != null){
    data.append('openai_assistant_key', openai_assistant_key);
  }
  if(classs !== undefined && classs !== null && classs !== '') {
    data.append('classs', classs);
  }
  if(classs.length === 0) {
    data.append('classs', '');
  }
  if(batch !== undefined && batch !== null && batch !== '') {
    data.append('batch', batch);
  }
  if(batch.length === 0) {
    data.append('batch', '');
  }
  if(password.length !== 0) {
    data.append('password', password);
  }
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    if(response.status === 1) {
      window.location.reload();
    }
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.non_field_errors){
      message=er.message[0]?.non_field_errors;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteStudent = (id, center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteStudent}${id}/`;
  await Service.post(url, {center_id}).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();

  /*Service.post(url,{center_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.log(er)

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })*/
}

const createAssessment = (type, uid, num_of_sections, title, center, timed_type, timed_duration_mins, toc_id, subject_id, classs, is_published, instruction, description) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createAssessment}`;
  const data = new FormData();
  data.append('type', type);
  data.append('uid', uid);
  data.append('num_of_sections', num_of_sections);
  data.append('title', title);
  data.append('center', center);
  data.append('timed_type', timed_type);
  data.append('timed_duration_mins', timed_duration_mins);
  if(toc_id !== null && toc_id !== '') {
    data.append('toc_id', toc_id);
  }
  data.append('instruction', instruction);
  data.append('description', description);
  data.append('subject_id', subject_id);
  data.append('class_id', classs);
  data.append('is_published', is_published);
  const response = Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.uid){
      message=er.message[0]?.uid;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
};

const editAssessmentBasic = (id, uid, title, center_id, timed_type, timed_duration_mins) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateAssessment}${id}/`;
  Service.post(url, {uid, title, center_id, timed_type, timed_duration_mins}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    if(er.message[0]?.uid){
      message=er.message[0]?.uid;
    }
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const updateAssessment = (id, difficulty, attempts_allowed, total_marks, passing_marks,
  closed_after_passing, result_after, show_solution_after, instructions, description,is_graded,time_bound_type,is_negative,back_tracking) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateAssessment}${id}/`;
  Service.post(url, {difficulty, attempts_allowed, total_marks, passing_marks,
    closed_after_passing, result_after, show_solution_after, instructions, description,is_graded,time_bound_type,is_negative,back_tracking}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const publishAssessment = (id, is_published) => async dispatch => {
  const url = `${common.updateAssessment}${id}/`;
  Service.post(url, { is_published }, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}


const deleteAssessment = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteAssessment}${id}/`;
  await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();

  /*Service.get(url,{}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.log(er)

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })*/
}

const deleteQod = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.qodDelete}${id}`;
  await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();

  /*Service.get(url,{}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.log(er)

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })*/
}

const addAssessmentSection = (assessment, title) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.addSection}`;
  Service.post(url, {assessment, title}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const updateAssessmentSection = (id, title, negative_marking_per_q, instructions) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateAssessmentSection}${id}/`;
  Service.post(url, {title, negative_marking_per_q, instructions}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const deleteAssessmentSection = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteSection}${id}/`;
  /*await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();*/

  Service.get(url, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.log(er)

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const createQuestion = (assessment_section_id, question_type, category,statements, options, marks, negative_marking,
                          difficulty, duration_seconds) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createQuestion}`;
  Service.post(url, {assessment_section_id, question_type, statements, options, marks, negative_marking,
                      difficulty, duration_seconds,category}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const editQuestion = (assessment_section_id,id, category,marks, negative_marking, difficulty, duration_seconds,question_type,options,statements,ifloading) => async dispatch => {
  dispatch({ type: LOADING, loading: ifloading });
  const url = `${common.editQuestion}${id}/`;
  Service.post(url, { assessment_section_id,question_type,marks, negative_marking, difficulty, duration_seconds,statements,options,category}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    if(ifloading === true )window.location.reload();
    else {
      store.addNotification({
        title: 'Success',
        message: `${response.error}`,
        type: 'success',
        container: 'top-right',
        dismiss: {
          duration: 3000,
        }
      })
    }
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    if(ifloading === true ){
      store.addNotification({
        title: 'Error',
        message: `${er.message}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  })
}

const assignSection = (assessment, title, classs, subject, batch, scheduled_date, is_released) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createdpp}`;
  Service.post(url, {assessment, title, classs, subject, batch, scheduled_date, is_released}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const createDPP = (assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createdpp}`;
  Service.post(url, {assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const assessmentCreateDPP = (assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.assessmentCreateDPP}`;
  Service.post(url, {assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const editDPP = (assessment, scheduled_date, solution_date, result_date, due_date, release_date) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.editDPP}${assessment}/`;
  Service.post(url, {scheduled_date, solution_date, result_date, due_date, release_date}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const assessmentEditDPP = (planner_id, scheduled_date, solution_date, result_date, due_date, release_date) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.assessmentEditDPP}${planner_id}/`;
  Service.post(url, {scheduled_date, solution_date, result_date, due_date, release_date}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const deleteDPP = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteDPP}${id}/`;
  await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();
}

const addUnit = (label, order, level, level_name, parent, subject) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createTOC}`;
  const data = new FormData();
  data.append('label', label);
  data.append('level', level);
  data.append('order', order);
  if(parent !== null) {
    data.append('parent', parent);
  }
  data.append('level_name', level_name);
  data.append('subject', subject);
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const updateUnit = (toc_id, label, order) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateTOC}${toc_id}/`;
  Service.post(url, {label, order}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const publishContent = (toc_id, is_published) => async dispatch => {
  const url = `${common.contentUpdate}${toc_id}/`;
  Service.post(url, { is_published }, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const deleteUnit = (toc_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteTOC}${toc_id}/`;
  //await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  //dispatch({ type: LOADING, loading: false });
  //window.location.reload();

  Service.get(url, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const addContent = (level, file, title, duration_mins, type, toc_id, subject_id, classs_id, thumbnail ,videoUrl,downloadable) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.createContent}`;
  
  const data = new FormData();
  data.append('level', level);
  if(file !== null && file !== undefined) {
    data.append('file', file);
  }
  data.append('title', title);
  data.append('toc_id', toc_id);
  data.append('duration_mins', duration_mins);
  data.append('type', type);
  data.append('subject_id', subject_id);
  data.append('classs_id', classs_id);
  
  if(videoUrl !== null && videoUrl !== undefined) {
     data.append('direct_url', videoUrl);
  }

  
  if(thumbnail !== null && thumbnail !== undefined && typeof(thumbnail) !== 'string') {
    data.append('thumbnail', thumbnail);
  }
  if(typeof(thumbnail) === 'string') {
    if(thumbnail.length === 0) {
      data.append('thumbnail', '');
    }
  }

  if(downloadable !== null && downloadable !== undefined) {
    data.append('downloadable', downloadable);
 }



  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const updateContent = (id, file, title, duration_mins, type, toc_id, subject_id, classs_id, thumbnail,videoUrl,downloadable) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.contentUpdate}${id}/`;
  const data = new FormData();
  if(file !== null && file !== undefined) {
    data.append('file', file);
  }
  if(thumbnail !== null && thumbnail !== undefined && typeof(thumbnail) !== 'string') {
    data.append('thumbnail', thumbnail);
  }
  if(thumbnail === null) {
    data.append('thumbnail', '');
  }
  data.append('title', title);
  data.append('duration_mins', duration_mins);
  data.append('type', type);
  data.append('toc_id', toc_id);
  data.append('subject_id', subject_id);
  data.append('classs_id', classs_id);
  if(videoUrl !== null && videoUrl !== undefined) {
     data.append('direct_url', videoUrl);
  }


  if(downloadable !== null && downloadable !== undefined) {
    data.append('downloadable', downloadable);
 }

  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const deleteContent = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.contentDelete}${id}/`;
  /*await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();*/

  Service.get(url, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const newLecture = (center, start_date_time, mode, title, duration_hrs, url, weblink, room, classs, subject, batch, faculty,is_webinar,liveclass_server,record_url,group_type) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const urlx = `${common.newLecture}`;
  const data = new FormData();
  data.append('center', center);
  data.append('start_date_time', start_date_time);
  data.append('mode', mode);
  data.append('title', title);
  data.append('duration_hrs', duration_hrs);
  data.append('classs', classs);
  data.append('subject', subject);
  data.append('batch', batch);
  data.append('faculty', faculty);
  data.append('liveClass_server',liveclass_server);
  data.append('record_url',record_url);
  data.append('group_type',group_type);
  if(is_webinar !== null && is_webinar !== undefined){
    data.append('is_webinar', is_webinar);
  }
  
  if(url.length > 0 && mode === 0) {
    data.append('url', url);
  }
  if(mode === 1) {
    data.append('url', weblink);
  }
  if(mode === 2) {
    data.append('room', room);
  }
  Service.post(urlx, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const editLecture = (id, center, start_date_time, mode, title, duration_hrs, url, weblink, room, classs, subject, batch, faculty,is_webinar,record_url,group_type) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const urlx = `${common.editLecture}${id}/`;
  const data = new FormData();
  data.append('center', center);
  data.append('start_date_time', start_date_time);
  data.append('mode', mode);
  data.append('title', title);
  data.append('duration_hrs', duration_hrs);
  data.append('classs', classs);
  data.append('subject', subject);
  data.append('batch', batch);
  data.append('faculty', faculty);
  data.append('record_url', record_url);
  data.append('group_type', group_type);
  if(is_webinar !== null && is_webinar !== undefined){

    data.append('is_webinar', is_webinar);
  }
  if(url.length > 0 && mode === 0) {
    data.append('url', url);
  }
  if(mode === 1) {
    data.append('url', weblink);
  }
  if(mode === 2) {
    data.append('room', room);
  }
  Service.post(urlx, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const deleteLecture = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteLecture}${id}/`;
  await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();
}

const sendInvite = (mail_type, user_ids) => async dispatch => {
  const url = `${common.sendInvite}`;
  Service.post(url, {mail_type, user_ids}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const sendOtp = (username,language,referral_domain, center_id = localStorage.getItem('center_id')) => async dispatch => {
  const url = `${common.sendOtp}`;
  const response=Service.post(url, {username,language,referral_domain, center_id}, (status,response) => {
    dispatch({ type: LOADING, loading: true });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })

  return response;
}

const updatePassword = (username,otp,new_password,language,referral_domain) => async dispatch => {
  const url = `${common.updatePassword}`;
  const response=Service.post(url, {username,otp,new_password,language,referral_domain}, (status,response) => {
    dispatch({ type: LOADING, loading: true });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
  }).catch(er => {
    
    dispatch({ type: LOADING, loading: false }); 
    // store.addNotification({
    //   title: 'Error',
    //   message: `${er.message}`,
    //   type: 'danger',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000
    //   }
    // })
    throw er;
  })

  return response;
}

const changePassword = (username,password,new_password,language) => async dispatch => {
  const url = `${common.updatePassword}`;
  const response=Service.post(url, {username,password,new_password,language}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
    //window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })

  return response;
}

const postDoubt = (subject_id, description, media, doubt_forum_id,class_id,type) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.postDoubt}`;
  let data = new FormData();
  data.append('description', description);
  data.append('subject_id', subject_id);
  data.append('class_id', class_id);
  data.append('type', type);
  if(doubt_forum_id !== null) {
    data.append('doubt_forum_id', doubt_forum_id);
  }
  if(media !== null) {
    data.append('media', media);
  }
  const response = Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const switchCourse = (batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateUserProfile}`;
  Service.post(url, { 'batch_id': batch_id }, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.href="/learner";
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const sendQuery = (name,dob,message) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.invalidLogin}`;
  Service.post(url, {name,dob,message}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.href = '/login';
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}
const updateImage = (file) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.imageUpload}`;

  const data = new FormData();
  
  if(file !== null && file !== undefined && typeof(file) !== 'string') {
    data.append('file', file);
  }

  if(file === null) {
    data.append('file', '');
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const updateProfile = (name,email,mobile,photo,countrycode,locale) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateUserProfile}`;

  const data = new FormData();
  
  if(name !== null) {
    data.append('name', name);
  }
  if(email !== null) {
    data.append('email', email);
  }
  if(mobile !== null) {
    data.append('mobile', mobile);
  }
  if(photo !== null && photo !== undefined && typeof(photo) !== 'string') {
    data.append('photo', photo);
  }

  if(countrycode !== null) {
    data.append('country_code', countrycode);
  }

  if(photo === null) {
    data.append('photo', '');
  }

  if(locale !== null) {
    data.append('locale', locale);
  }
  
  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    //return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  //return response;
}

const studentQuestionResponse = (content_id,question_id,batch_id,page) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.studentQuestionResponse}?page=${page}`;

  const response=Service.post(url, {content_id,question_id,batch_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'updated successfully',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    /*store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
  })
  return response;
}

const submitMarks = (attempt_id,questions) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.submitMarks}`;

  const response=Service.post(url, {attempt_id,questions}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const xpUserSignUp = (name,email,phone,resent_otp) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpUserSignup}`;

  const response=Service.post(url, {name,email,phone,resent_otp}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const learnerAssignmentList = (date,days,direction,if_loading,batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.learnerAssignmentList}`;

  let data =new FormData();
  
  if(date){
    data.append('start_date', date);
  }
  if(direction){
    data.append('direction', direction);
  }

  if(batch_id){
    data.append('batch_id', batch_id);
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'updated successfully',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const assignmentReview = (assignment_id,page,batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  let url;
  if(page) url=`${common.assignmentReview}?page=${page}`;
  else url=`${common.assignmentReview}`;

  const data = new FormData();
  if(assignment_id !== null) {
    data.append('assignment_id', assignment_id);
  }
  if(batch_id !== null && batch_id !=0 && batch_id != undefined) {
    data.append('batch_id', batch_id);
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'updated successfully',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const learnerAssignmentReview = (assignment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.learnerAssignmentReview}`;

  const response=Service.post(url, {assignment_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'updated successfully',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const learnerAssignmentReview2 = (content_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.learnerAssignmentReview}`;

  const response=Service.post(url, {content_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'updated successfully',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const xpUserOTPVerification = (email,email_otp,language) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpUserOTPVerification}`;

  const response=Service.post(url, {email,email_otp,language}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const uploadUserAssigFile  = (assignment_id,users_file) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.uploadUserAssigFile}`;
  const data = new FormData();

   data.append('assignment_id', assignment_id);
  if(users_file !== null && users_file !== undefined) {
    data.append('users_file', users_file);
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const facultyAssignmentList = (center_id,subject_id,class_id,is_loading,section_id,start_date,direction) => async dispatch => {
  dispatch({ type: LOADING, loading: is_loading });
  const url = `${common.facultyAssignmentList}`;

  let data =new FormData();
  data.append('center_id', center_id);
  if(subject_id) {
    data.append('subject_id', subject_id);
  }
  if(class_id) {
    data.append('class_id', class_id);
  }
  if(section_id) {
    data.append('batch_id', section_id);
  }
  if(start_date){
    data.append('start_date', start_date);
  }
  if(direction){
    data.append('direction', direction);
  }
  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'File uploaded!!',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const assignmentPlanner = (assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.assignmentPlanner}`;

  const response=Service.post(url, {assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const updateAssignmentPlanner = (id,assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.updateAssignmentPlanner}/${id}/`;

  const response=Service.post(url, {assignment,subject,title,release_date,scheduled_date,solution_date,result_date,due_date,duration_mins,classs,session,phase,batch,is_released,faculty}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const facultyUploadMarks = (attempt_id,faculty_file,marks_obtained) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.facultyUploadMarks}`;
  if(marks_obtained){
    const response=Service.post(url, {attempt_id,marks_obtained}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response;
    }).catch(er => {
      dispatch({ type: LOADING, loading: false }); 
      store.addNotification({
        title: 'Error',
        message: `${er.message}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000,
        }
      })
    })
    return response;
  }
  if(faculty_file){

    const data = new FormData();

    data.append('attempt_id', attempt_id);
    if(faculty_file !== null && faculty_file !== undefined) {
      data.append('faculty_file', faculty_file);
    }
    const response=Service.post(url,data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
    }).catch(er => {
      dispatch({ type: LOADING, loading: false }); 
      store.addNotification({
        title: 'Error',
        message: `${er.message}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000,
        }
      })
    })

    return response;
  }
}

const deleteAssignment = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.deleteAssignment}/${id}/`;
  await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();
}


const xpStudentCreation = (name, email, mobile, password, username,class_id, batch_id, center_id, wp_user_id ,is_xp_signup,language ) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpStudentCreation}`;

  const response=Service.post(url, {name, email, mobile, password, username,class_id, batch_id, center_id, wp_user_id ,is_xp_signup,language}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const sendInvitePassword = (user_ids) => async dispatch => {
  const url = `${common.sendInvitePassword}`;
  Service.post(url, {user_ids}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const xpUserBannerCreation = (banner_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpUserBannerCreation}`;

  const response=Service.post(url, {banner_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const xpsendMentorship = (interest_area,location,companies) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpsendMentorship}`;

  const response=Service.post(url, {interest_area,location,companies}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const xpSubmitGoalQuestionList = (answers) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpSubmitGoalQuestionList}`;

  const response=Service.post(url, {answers}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const xpSubmitTellInterest = (interest) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpSubmitTellInterest}`;

  const response=Service.post(url, {interest}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const xpGetAllChallengesAssignmentList = (center_id,type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.xpGetAllChallengesAssignmentList}`;

  const response=Service.post(url, {center_id,type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: 'Submitted successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const userContentCompletion =(content,ref_id,level,content_type,content_subtype,page_number,max_page_no,duration_taken,previous_page_number,subject) => async dispatch => {
  const url = `${common.userContentCompletion}/`;
  Service.post(url, {content,ref_id,level,content_type,content_subtype,page_number,max_page_no,duration_taken,previous_page_number,subject}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: `Password Resent`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })*/
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}


const decrypt =(query) => async dispatch => {
  const url = `${common.decrypt}`;
  const response =Service.post(url, {query}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
    /*store.addNotification({
      title: 'Success',
      message: `Password Resent`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })*/
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const exportNotes = (content_id) => async dispatch => {
  const url = `${common.exportNotes}`;
  const response =Service.post(url, {content_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
    /*store.addNotification({
      title: 'Success',
      message: `Password Resent`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })*/
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const exportVideos = (content_id) => async dispatch => {
  const url = `${common.exportVideos}`;
  const response =Service.post(url, {content_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
    /*store.addNotification({
      title: 'Success',
      message: `Password Resent`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })*/
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const userLogReport = (content_id) => async dispatch => {
  const url = `${common.userLogReport}`;
  const response =Service.post(url, {content_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
    /*store.addNotification({
      title: 'Success',
      message: `Password Resent`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })*/
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const encryption = (key) => async dispatch => {
  const url = `${common.encryption}`;

  let data =new FormData();
  data.append('key', key);
  const response =Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
    /*store.addNotification({
      title: 'Success',
      message: `Password Resent`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })*/
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const qodCreate = (question,schedule_date,due_date,title,level,type,show_result,created_on,image,level_id) => async dispatch => {
  const url = `${common.qodCreate}`;

  const data = new FormData();

  data.append('question', question);
  if(image !== null && image !== undefined) {
    data.append('image', image);
  }
  if(schedule_date !== null && schedule_date !== undefined) {
    data.append('schedule_date', schedule_date);
  }
  if(due_date !== null && due_date !== undefined) {
    data.append('due_date', due_date);
  }
  if(title !== null && title !== undefined) {
    data.append('title', title);
  }
  if(level !== null && level !== undefined) {
    data.append('level', level);
  }
  if(type !== null && type !== undefined) {
    data.append('type', type);
  }
  if(show_result !== null && show_result !== undefined) {
    data.append('show_result', show_result);
  }
  if(created_on !== null && created_on !== undefined) {
    data.append('created_on', created_on);
  }
  if(level_id !== null && level_id !== undefined) {
    data.append('level_id', level_id);
  }

  const response =Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    //return response;
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const qodUpdate = (question,schedule_date,due_date,title,level,type,show_result,created_on,image,level_id) => async dispatch => {
  const url = `${common.qodUpdate}/${question}`;

  const data = new FormData();

  if(image !== null && image !== undefined) {
    data.append('image', image);
  }
  if(schedule_date !== null && schedule_date !== undefined) {
    data.append('schedule_date', schedule_date);
  }
  if(due_date !== null && due_date !== undefined) {
    data.append('due_date', due_date);
  }
  if(title !== null && title !== undefined) {
    data.append('title', title);
  }
  if(level !== null && level !== undefined) {
    data.append('level', level);
  }
  if(type !== null && type !== undefined) {
    data.append('type', type);
  }
  if(show_result !== null && show_result !== undefined) {
    data.append('show_result', show_result);
  }
  if(created_on !== null && created_on !== undefined) {
    data.append('created_on', created_on);
  }
  if(level_id !== null && level_id !== undefined) {
    data.append('level_id', level_id);
  }

  const response =Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    //return response;
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    window.location.reload()
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const questionSectionMapping = (assessment_section_id,questions) => async dispatch => {
  const url = `${common.questionSectionMapping}`;

  const response =Service.post(url, {assessment_section_id,questions}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    //return response;
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    window.location.reload()
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const qodQuestionSubmit = (qod_question_id,selected_option_id,solution,time_taken) => async dispatch => {
  const url = `${common.qodQuestionSubmit}`;

  const data = new FormData();
  
  data.append('qod_question_id', qod_question_id);
  
  if(selected_option_id !== null && selected_option_id !== undefined) {
    data.append('selected_option_id', selected_option_id);
  }
  if(solution !== null && solution !== undefined) {
    data.append('solution', solution);
  }
  if(time_taken !== null && time_taken !== undefined) {
    data.append('time_taken', time_taken);
  }

  const response =Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    //return response;
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    return response;
    //window.location.reload()
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const questionSearch = (key,page) => async dispatch => {
  const url = `${common.questionSearch}?search=${key}&page=${page}`;

  const response =Service.get(url, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    //return response;
    /*store.addNotification({
      title: 'Success',
      message: `question scheduled`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })*/

    return response;
    //window.location.reload()
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const editQuestionBank = (id) => async dispatch => {
  const url = `${common.editQuestionBank}/${id}`;

  const response =Service.get(url, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const assignmentPlannerList = (center_id,subject_id,class_id,is_loading,page) => async dispatch => {
  dispatch({ type: LOADING, loading: is_loading });
  const url = `${common.assignmentPlannerList}?page=${page}`;

  let data =new FormData();
  
  data.append('center_id', center_id);
  if(subject_id) {
    data.append('subject_id', subject_id);
  }
  if(class_id) {
    data.append('class_id', class_id);
  }
  

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'updated successfully',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const assignmentPlannerdelete = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.assignmentPlannerdelete}/${id}/`;
  await Service.get(url).catch(er => { dispatch({ type: LOADING, loading: false }); })
  dispatch({ type: LOADING, loading: false });
  window.location.reload();

  /*Service.get(url,{}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.log(er)
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })*/
}


const unsubscribe = (query) => async dispatch => {
  const url = `${common.unsubscribe}`;

  const response =Service.post1(url, {query}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    //return response;
    return response
    //window.location.reload()
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const userNotificationReads = (ids) => async dispatch => {
  const url = `${common.userNotificationReads}`;

  let data =new FormData();
  
  data.append('ids', ids);
  
  const response =Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    //return response;
    //dispatch(userNotificationLogs(0,1))
    return response
    //window.location.reload()
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

// const userNotificationLogs =() => async dispatch => {
//   dispatch({ type: LOADING, loading: false });
//   const url = `${common.userNotificationLogs}`;

//   const response = Service.get(url,(status,response) => {
//     dispatch({ type: LOADING, loading: false });
//     dispatch({ type: NOTIFICATION, notifications: response.data.data });
//     return response;
//   }).catch(er => {dispatch({ type: LOADING, loading: false });})
//   return response;
// }


const userNotificationLogs =(is_read,page) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.userNotificationLogs}?is_read=${is_read}&&page=${page}`;
  const response = Service.get(url,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: NOTIFICATION, notifications: response.data.data });
    return response;
  }).catch(er => {dispatch({ type: LOADING, loading: false });})
  return response;
}

const AllUserNotificationLogs =(page) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.userNotificationLogs}?page=${page}`;
  const response = Service.get(url,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: NOTIFICATION, notifications: response.data.data });
    return response;
  }).catch(er => {dispatch({ type: LOADING, loading: false });})
  return response;
}


const classbatchmapping= (center_id,user_id,class_batch_mapping) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.classbatchmapping}`;

  const response = Service.post(url,{center_id,user_id,class_batch_mapping},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
   // dispatch({ type: NOTIFICATION, notifications: response.data.data });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const bbbJoinLiveClass= (session,name,meetingPassword,userId) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.bbbJoinLiveClass}`;

  const response = lmsService.lmsPost(url,{session,name,meetingPassword,userId},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    //window.location.reload();
   // dispatch({ type: NOTIFICATION, notifications: response.data.data });
    return response;
  }).catch(er => {dispatch({ type: LOADING, loading: false });})
  return response;
}

const widgetQuestionSubmit= (questions) => async dispatch => {
  const url = `${common.widgetQuestionSubmit}`;

  const response = Service.post(url,{questions},(status,response) => {
    // store.addNotification({
    //   title: 'Success',
    //   message: 'saved successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })

  return response
}

const promoteStudentSem = (from_batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.promoteStudentSem}`;

  let data =new FormData();
  data.append('from_batch_id', from_batch_id);

  const response = Service.post(url,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    //window.location.reload();
   // dispatch({ type: NOTIFICATION, notifications: response.data.data });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const programCreate = (label,short_code,center_id,no_of_sem,sem_details) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.programCreate}`;

  console.log(sem_details)
  let data =new FormData();
  data.append('label', label);
  data.append('center_id', center_id);
  data.append('short_code', short_code);
  data.append('no_of_sem', no_of_sem);
  data.append('sem_details', sem_details);

  const response = Service.post(url,{label,short_code,center_id,no_of_sem,sem_details},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload()
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })

  })
  return response;
}
const sessionCreate = (label,description,short_code,start_date,end_date,center) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.sessionCreate}`;

  let data =new FormData();
  data.append('label', label);
  data.append('description', description);
  data.append('short_code', short_code);
  data.append('start_date', start_date);
  data.append('end_date', end_date);

  const response = Service.post(url,{label,description,short_code,start_date,end_date,center},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const sessionProgramMapping = (session,programs) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.sessionProgramMapping}`;

  const response = Service.post(url,{session,programs},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const programSessionEdit = (id,label,description,short_code,start_date,end_date,center) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.programSessionEdit}/${id}`;

  const response = Service.post(url,{label,description,short_code,start_date,end_date,center},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const programEdit = (id,label,short_code,center_id,no_of_sem,sem_details) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.programEdit}/${id}`;

  const response = Service.post(url,{label,short_code,center_id,no_of_sem,sem_details},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const addMoreSemesters = (program,label,order,short_code,description,center) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.addMoreSemesters}`;

  const response = Service.post(url,{program,label,order,short_code,description,center},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const widgetReorder = (reorder) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.widgetReorder}`;

  const response = Service.post(url,{reorder},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    //window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const widgetSubmit = (widget_master_id,size,position,data,settings,row,column,selected_widget_id,title) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  let url ='';
  if(selected_widget_id)
    url = `${common.widgetSubmit}?widget_id=${selected_widget_id}`;
  else
    url = `${common.widgetSubmit}`;

  const response = Service.post(url,{widget_master_id,size,position,data,settings,row,column,title},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    //window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const bannerCreate = (title, description, start_date_time, end_date_time, banner_type, level, image, banner_url,content,classs,center,ext,batch_ids,web_image,ignore_class_ids) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.bannerCreate}/`;
  const data = new FormData();
  if(title !== null) {
    data.append('title', title);
  }
  if(description !== null) {
    data.append('description', description);
  }
  if(start_date_time !== null) {
    data.append('start_date_time', start_date_time);
  }
  if(end_date_time !== null) {
    data.append('end_date_time', end_date_time);
  }
  if(banner_type !== null) {
    data.append('banner_type', banner_type);
  }
  if(image !== null && image !== undefined && image!='') {
    data.append('image', image);
  }
  if(level !== null && level !== undefined) {
    data.append('level', level);
  }
  if(banner_url !== null) {
    data.append('url', banner_url);
  }
  if(content !== null) {
    data.append('content', content);
  }
  if(classs !== null) {
    data.append('classs', classs);
  }
  if(center !== null) {
    data.append('center', center);
  }
  if(ext !== null) {
    data.append('ext', ext);
  }
  if(batch_ids !== null) {
    data.append('batch_ids', batch_ids);
  }
  if(web_image !== null && web_image !== undefined && web_image!='') {
    data.append('web_image', web_image);
  }
  if(ignore_class_ids !== null) {
    data.append('ignore_class_ids', ignore_class_ids);
  }
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload()
    return response
    
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const formCreate = (title,description,success_message,type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.formCreate}`;

  const response = Service.post(url,{title,description,success_message,type},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    return response
    //window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const widgetQuestionCreate = (widgetform_id,question_type,statement,explanation,options,image) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.widgetQuestionCreate}`;

  const response = Service.post(url,{widgetform_id,question_type,statement,explanation,options,image},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    
    return response
    window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const bannerUpdate = (id,title, description, start_date_time, end_date_time, banner_type, level, image, banner_url,content,classs,center,ext,batch_ids,web_image,ignore_class_ids) => async dispatch => {
  
  dispatch({ type: LOADING, loading: true });
  const url = `${common.bannerUpdate}/${id}`;
  const data = new FormData();
 
  if(title !== null) {
    data.append('title', title);
  }
  if(description !== null) {
    data.append('description', description);
  }
  if(start_date_time !== null) {
    data.append('start_date_time', start_date_time);
  }
  if(end_date_time !== null) {
    data.append('end_date_time', end_date_time);
  }
  if(banner_type !== null) {
    data.append('banner_type', banner_type);
  }
  if(image !== null && image !== undefined && image !== '') {
    data.append('image', image);
  }
  if(level !== null && level !== undefined) {
    data.append('level', level);
  }
  if(banner_url !== null) {
    data.append('url', banner_url);
  }
  if(content !== null) {
    data.append('content', content);
  }
  
  if(classs == null) {
    data.append('classs', "");
  }else{
    data.append('classs', classs);
  }
  if(center !== null) {
    data.append('center', center);
  }
  if(ext !== null) {
    data.append('ext', ext);
  }
 
  data.append('batch_ids', batch_ids);
  if(web_image !== null && web_image !== undefined && web_image !== '') {
    data.append('web_image', web_image);
  }
  data.append('ignore_class_ids', ignore_class_ids);
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload()
    return response
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const submitSetting = (id,key,value,ext,center_id, options) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  console.log("options");
  console.log(options.type);
  if(options.type == "json" && value != '') {  
    console.log("value")
    console.log(value)
    value = JSON.parse(value)  
  }else if(options.type == "json" && value == ''){
    value = null  
  }else{
    value = value  
  }
  console.log(value)
  //value = typeof value === 'object' && !Array.isArray(value) ? JSON.parse(value):value;
  let url =''
  if(id)
    url = `${common.submitSetting}?id=${id}`;
  else
    url = `${common.submitSetting}`;
  
  Service.post(url, {key,value,ext,center_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload()
    return response
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
}

const userSignup = (name,email,mobile,center_id,language) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.userSignup}`;

  const response = Service.post1(url,{name,email,mobile,center_id,language},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    return response
    //window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const resetPassword = (user_id,password) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.resetPassword}`;

  const response = Service.post1(url,{user_id,password},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    //return response
    window.location.href='/aicte';
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const wpUserLinkTokenValidate = (token) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.wpUserLinkTokenValidate}`;

  const response = Service.post1(url,{token},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    let message = response.data.message != undefined ? response.data.message: "";

    if(message){
      store.addNotification({
        title: 'Error',
        message: `${message}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000,
        }
      })
    }

    return response
    //window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const getAssessmentsReportsDetails = (type,center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsReportsDetails}`;

  const response=Service.post(url, {type,center_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const getAssessmentsSessionFilters = (id,nested_filter_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsNestedFilters}`;

  const response=Service.post(url, {id,nested_filter_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const getChildSelectData = (id,nested_filter_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsNestedFilters}`;

  const response=Service.post(url, {id,nested_filter_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const getAssessmentsNestedProgramFilters = (id,nested_filter_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsNestedFilters}`;

  const response=Service.post(url, {id,nested_filter_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const getAssessmentsNestedClassFilters = (id,nested_filter_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsNestedFilters}`;

  const response=Service.post(url, {id,nested_filter_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const getAssessmentsNestedSubjectsFilters = (id,nested_filter_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsNestedFilters}`;

  const response=Service.post(url, {id,nested_filter_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const getAssessmentsNestedBatchFilters = (id,nested_filter_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsNestedFilters}`;

  const response=Service.post(url, {id,nested_filter_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const getAssessmentsNestedAssessmentsFilters = (id,nested_filter_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsNestedFilters}`;

  const response=Service.post(url, {id,nested_filter_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const sendAssessmentsResportsData = (from_date, to_date, download_type,type,center_id,session_id,program_id,class_id,subject_id,assessment_id,batch_id,column,lecture_id,content_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.sendAssessmentsResportsData}`;
  const response=Service.post(url, {from_date, to_date, download_type,type,center_id,session_id,program_id,class_id,subject_id,assessment_id,batch_id,column,lecture_id,content_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const callzoomAttendanceAPI = (lecture_id,user_id,join_time,join_type) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.callzoomAttendanceAPI}`;
  const response=Service.post(url, {lecture_id,user_id,join_time,join_type}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    console.log("API Call Ho Gyi");
    // store.addNotification({
    //   title: 'Success',
    //   message: 'Insert successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}



const callFacultyzoomAttendanceAPI = (faculty_id,room) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.callFacultyzoomAttendanceAPI}`;
  const response=Service.post(url, {faculty_id,room}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    console.log("Faculty API Call Ho Gyi");
    // store.addNotification({
    //   title: 'Success',
    //   message: 'Insert successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const getLectureDatabyID = (lecture_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getLectureDatabyID}`;
  const response=Service.post(url, {lecture_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    console.log("Call By id Lecture api API Call Ho Gyi");
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const resetPasswordActivateUser = (user_id,password,update_status) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.resetPassword}`;

  const response = Service.post(url,{user_id,password,update_status},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const mobileOTPSend = (mobile,language) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.mobileOTPSend}`;

  const response = Service.post(url,{mobile,language},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const uploadUserAssigAddMoreFile  = (assignment_attempt_id,users_file) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.uploadUserAssigAddMoreFile}`;
  const data = new FormData();

   data.append('assignment_attempt_id', assignment_attempt_id);
  if(users_file !== null && users_file !== undefined) {
    data.append('users_file', users_file);
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const mobileOTPValidate  = (mobile,otp,device_id,device_type,device_token,fcm_token,language) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.mobileOTPValidate}`;
  const data = new FormData();

  if(mobile) {
    data.append('mobile',mobile);
  }

  if(otp) {
    data.append('otp', otp);
  }
 
  if(device_id) {
    data.append('device_id', device_id);
  }

  if(device_type) {
    data.append('device_type', device_type);
  }

  if(device_token) {
    data.append('device_token', device_token);
  }

  if(fcm_token) {
    data.append('fcm_token', fcm_token);
  }

  if(language) {
    data.append('language', language);
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //return response;
    localStorage.setItem('logindetails', JSON.stringify(response.data));
    localStorage.setItem('token', response.data.auth_token);
    localStorage.setItem('name', response.data?.name);
    localStorage.setItem('preferredGrid', 'true');
    localStorage.setItem('user_id',  response.data.id);
    localStorage.setItem('username',  response.data.username);
    localStorage.setItem('dp',response.data.dashboard_type);
    localStorage.setItem('widget_alignment_size',response.data.widget_alignment.size);
    localStorage.setItem('widget_alignment_alignment',response.data.widget_alignment.alignment);
    
    
    
    if(response.data.center_details && response?.data?.center_details?.is_center_admin === true) {
      localStorage.setItem('is_admin', 'true');
      //localStorage.setItem('username',username);
      localStorage.setItem('center_id', response.data.center_details.center_id);
      Service.updateToken();
      window.location.href = '/admin/institute';
    }
    else if(response.data?.is_faculty === true) {
      
      localStorage.setItem('is_faculty', 'true');
      //localStorage.setItem('username',username);
      localStorage.setItem('is_faculty_admin',"false")
      localStorage.setItem('center_id', response.data.center_details.center_id);
      Service.updateToken();
      window.location.reload();
    }
    else {
      localStorage.setItem('is_faculty', 'false');
      localStorage.setItem('on_login', 1);
      localStorage.setItem('center_id', response.data.center_id);
      Service.updateToken();
      
      if(localStorage.getItem("gmail_source") == "1"){
        window.location.href = '/view-mapping'+localStorage.getItem("search");
      }else{
        window.location.reload();
      }
      
    }
    
    
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const facultyUploadMutlipleFiles = (attempt_id,faculty_files) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.facultyUploadMutlipleFiles}`;
  const data = new FormData();
  
    if(attempt_id) {
      data.append('attempt_id', attempt_id);
    }

    if(faculty_files) {
      data.append('faculty_files', faculty_files);
    }
    //console.log("data",data)
   
  const response=Service.post(url, data , (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response;
    }).catch(er => {
      dispatch({ type: LOADING, loading: false }); 
      store.addNotification({
        title: 'Error',
        message: `${er.message}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000,
        }
      })
    })
    return response;

}

const addExperience = (activity_type, title, description, start_date,end_date,institute,certificate,professional_key,user_id,edit_url_professional) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.addExperience}`;
  let data = new FormData();
  data.append('activity_type', activity_type);
  data.append('title', title);
  data.append('description', description);
  data.append('start_date', start_date);
  data.append('end_date', end_date);
  data.append('institute', institute);
  data.append('certificate', certificate);
  data.append('professional_key', professional_key);
  data.append('user_id', user_id);

  if(activity_type) {
    data.append('activity_type', activity_type);
  }
  if(title) {
    data.append('title', title);
  }
  if(description) {
    data.append('description', description);
  }
  if(start_date) {
    data.append('start_date', start_date);
  }else{
    data.append('start_date', "");
  }
  if(end_date) {
    data.append('end_date', end_date);
  }else{
    data.append('end_date', ''); 
  }
  if(institute) {
    data.append('institute', institute);
  }
  if(certificate) {
    data.append('certificate', certificate);
  }else{
    data.append('certificate', "");
  }
  
  if(professional_key) {
    data.append('professional_key', professional_key);
  }
  if(user_id) {
    data.append('user_id', user_id);
  }
  if(edit_url_professional) {
    data.append('edit_url_professional', edit_url_professional);
  }

  const response = Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload();
    return response;
    
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const deletePortfolio = (key,index,user_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.deletePortfolio}`;

  const response = Service.post(url,{key,index,user_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const addlanguageYearsOFExperience = (no_of_exp, preferred_language,user_id,location) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.addlanguageYearsOFExperience}`;

  const response = Service.post(url,{no_of_exp, preferred_language,user_id,location},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const facultyZoomCreate = (lecture_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.facultyZoomCreate}`;
  const response=Service.post(url, {lecture_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    console.log("API Call Ho Gyi");
    // store.addNotification({
    //   title: 'Success',
    //   message: 'Insert successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const teacherUploadDoucment = (user_id, file, type) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.teacherUploadDoucment}`;
  const data = new FormData();
  console.log(data)
  if(user_id !== null) {
    data.append('user_id', user_id);
  }
  if(file !== null) {
    data.append('file', file);
  }
  if(type !== null) {
    data.append('type', type);
  }
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
    //window.location.reload();
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  
};

const teacherUploadDoucmentList = (user_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.teacherUploadDoucmentList}`;

  const response = Service.post(url,{user_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const createStudioProject = (start_date_time,end_date_time,subject,title,duration_hrs,classs,faculty,url,mode,batch_ids,center) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const apiurl = `${common.createStudioProject}`;
  const data = new FormData();

  if(!start_date_time) {
    data.append('start_date_time', "");
  }else{
    data.append('start_date_time', start_date_time);
  }
  if(!end_date_time) {
    data.append('end_date_time', "");
  }else{
    data.append('end_date_time', end_date_time);
  }
  if(!subject) {
    data.append('subject', "");
  }else{
    data.append('subject', subject);
  }
  if(!title) {
    data.append('title', "");
  }else{
    data.append('title',title);
  }
  if(!duration_hrs) {
    data.append('duration_hrs', "");
  }else{
    data.append('duration_hrs', duration_hrs);
  }
  if(!classs) {
    data.append('classs', "");
  }else{
    data.append('classs', classs);
  }
  if(!faculty) {
    data.append('faculty', "");
  }else{
    data.append('faculty', faculty);
  }
  if(!url) {
    data.append('url', "");
  }else{
    data.append('url', url);
  }
  if(!mode) {
    data.append('mode', "");
  }else{
    data.append('mode', mode);
  }
  if(!batch_ids) {
    data.append('batch_ids', "");
  }else{
    data.append('batch_ids', batch_ids);
  }
  if(!center) {
    data.append('center', "");
  }else{
    data.append('center', center);
  }
  const response = Service.post(apiurl,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const updateStudioProject = (project_id,start_date_time,end_date_time,subject,title,duration_hrs,classs,faculty,url,mode,batch_ids,center) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const apiurl = `${common.updateStudioProject}${project_id}/`;
  const data = new FormData();

  if(!start_date_time) {
    data.append('start_date_time', "");
  }else{
    data.append('start_date_time', start_date_time);
  }
  if(!end_date_time) {
    data.append('end_date_time', "");
  }else{
    data.append('end_date_time', end_date_time);
  }
  if(!subject) {
    data.append('subject', "");
  }else{
    data.append('subject', subject);
  }
  if(!title) {
    data.append('title', "");
  }else{
    data.append('title',title);
  }
  if(!duration_hrs) {
    data.append('duration_hrs', "");
  }else{
    data.append('duration_hrs', duration_hrs);
  }
  if(!classs) {
    data.append('classs', "");
  }else{
    data.append('classs', classs);
  }
  if(!faculty) {
    data.append('faculty', "");
  }else{
    data.append('faculty', faculty);
  }
  if(!url) {
    data.append('url', "");
  }else{
    data.append('url', url);
  }
  if(!mode) {
    data.append('mode', "");
  }else{
    data.append('mode', mode);
  }
  if(!batch_ids) {
    data.append('batch_ids', "");
  }else{
    data.append('batch_ids', batch_ids);
  }
  if(!center) {
    data.append('center', "");
  }else{
    data.append('center', center);
  }
  const response = Service.post(apiurl,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const studioProjectUserUploadFile = (project_id,users_file) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const apiurl = `${common.studioProjectUserUploadFile}`;
  const data = new FormData();

  if(!project_id) {
    data.append('project_id', "");
  }else{
    data.append('project_id', project_id); 
  }
  if(!users_file) {
    data.append('users_file', "");
  }else{
    data.append('users_file', users_file); 
  }
  const response = Service.post(apiurl,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const getFacultyStudentProjectResponse = (project_id,page,batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const apiurl = `${common.getFacultyStudentProjectResponse}?page=${page}`;
  const data = new FormData();

  if(!project_id) {
    data.append('project_id', "");
  }else{
    data.append('project_id', project_id); 
  }
  if(!batch_id) {
    data.append('batch_id', "");
  }else{
    data.append('batch_id', batch_id); 
  }
  const response = Service.post(apiurl,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: 'Project updated successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const facultyUploadStudioProjectMarks = (data) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.facultyUploadStudioProjectMarks}`;
  const response = Service.post(url,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const studioProjectUserAttemptList = (project_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.studioProjectUserAttemptList}`;
  const response = Service.post(url,{project_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: 'Marks updated successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const studioProjectListing = (date,days,direction,date_range) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.studioProjectListing}`;

  let data =new FormData();
  
  if(date){
    data.append('start_date', date);
  }
  if(direction){
    data.append('direction', direction);
  }
  if(days){
    data.append('days', days);
  }
  if(date_range){
    data.append('date_range', date_range);
  }
  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    /*store.addNotification({
      title: 'Success',
      message: 'updated successfully',
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })*/
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const studioProjectByid = (project_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.studioProjectByid}`;

  let data =new FormData();
  
  if(project_id){
    data.append('project_id', project_id);
  }
  
  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const facultyAssignmentReportDownload = (assignment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.facultyAssignmentReportDownload}`;

  let data =new FormData();
  
  if(assignment_id){
    data.append('assignment_id', assignment_id);
  }
  
  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const curriculumCreate = (program_id,session_id,file,titile,description) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.curriculumCreate}`;

  let data =new FormData();

  if(program_id){
    data.append('program_id', program_id);
  }
  if(session_id){
    data.append('session_id', session_id);
  }
  if(file){
    data.append('file', file);
  }else{
    data.append('file', '');
  }
  if(titile){
    data.append('title', titile);
  }
  if(description){
    data.append('description', description);
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    console.log("response");
    console.log(response);
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const curriculumList = (program_id,session_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  const url = `${common.curriculumList}`;
  const response = Service.post(url,{program_id,session_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const curriculumUpdate = (id,file,titile,description) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.curriculumUpdate}/${id}`;

  let data =new FormData();

  
  if(file){
    data.append('file', file);
  }else{
    data.append('file', '');
  }
  if(titile){
    data.append('title', titile);
  }
  if(description){
    data.append('description', description);
  }

  const response=Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    console.log("response");
    console.log(response);
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const getCurriculumListing = (batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });

  const url = `${common.getCurriculumListing}`;
  const response = Service.post(url,{batch_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const bulkUserCreate = (uploadfile) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  console.log("uploadfile");
  console.log(uploadfile);
  const url = `${common.bulkUserCreate}`;
  let data =new FormData();
    if(uploadfile){
      data.append('uploadfile', uploadfile);
    }else{
      data.append('uploadfile', '');
    }
  const response = Service.post(url,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const userResetPassword = (user_id,password,language) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.userResetPassword}`;
  const response = Service.post(url,{user_id,password,language},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
   
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const studentActiveInactive = (user_id, is_active) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  let data =new FormData();
    if(user_id){
      data.append('user_id', user_id);
    }
    data.append('is_active', is_active);
    data.append('password', '');
    
  const url = `${common.userResetPassword}`;
  const response = Service.post(url,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const bulkPasswordUpdate = (uploadfile) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  console.log("uploadfile");
  console.log(uploadfile);
  const url = `${common.bulkPasswordUpdate}`;
  let data =new FormData();
    if(uploadfile){
      data.append('uploadfile', uploadfile);
    }else{
      data.append('uploadfile', '');
    }
  const response = Service.post(url,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const bulkBatchUpdate = (uploadfile,batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  console.log("uploadfile");
  console.log(uploadfile);
  const url = `${common.bulkBatchUpdate}`;
  let data =new FormData();
    if(uploadfile){
      data.append('uploadfile', uploadfile);
    }

    if(batch_id){
      data.append('batch_id', batch_id);
    }

  const response = Service.post(url,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const bulkQuestionUpdate = (uploadfile) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  console.log("uploadfile");
  console.log(uploadfile);
  const url = `${common.bulkQuestionUpdate}`;
  let data =new FormData();
    if(uploadfile){
      data.append('uploadfile', uploadfile);
    }
  const response = Service.post(url,data,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}
const scheduleFBNotifications = (title,body,batch_ids,image,scheduled_date,channel_id,is_schedule,class_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.scheduleFBNotifications}`;
  const response = Service.post(url,{title,body,batch_ids,image,scheduled_date,channel_id,is_schedule,class_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const getLanguageWiseData = (language,page) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getLanguageWiseData}`;
  //const url = 'http://localhost/lang'+ '/'+language + '/'+ page+'.json';
  const response = Service.post(url,{language,page},(status,response) => {
    // const response = Service.get(url,(status,response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: LANGUAGE_DATA, languageData: response.data });
    // store.addNotification({
    //   title: 'Success',
    //   message: 'Data Fetch successfully',
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    //window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Error',
    //   message: `${er.message}`,
    //   type: 'danger',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000
    //   }
    // })
  })
  return response;
}




// const getPageLanguageWiseData = (language,page) => async dispatch => {
//   dispatch({ type: LOADING, loading: false });
//   // const url = `${common.getPageLanguageWiseData}`;
//   const url = 'http://localhost/lang'+ '/'+language + '/'+ page+'.json';
//   //const response = Service.post(url,{language,page},(status,response) => {
//   const response = Service.get(url,(status,response) => {
//     dispatch({ type: LOADING, loading: false });
//     dispatch({ type: PAGEWISE_LANGUAGE_DATA, pagewiseLanguageData: response.data });
//    return response
//   }).catch(er => {
//     dispatch({ type: LOADING, loading: false });
//   })
//   return response;
// }

const getPageLanguageWiseData = (language,page) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getPageLanguageWiseData}`;
  const response = Service.post(url,{language,page},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: PAGEWISE_LANGUAGE_DATA, pagewiseLanguageData: response.data });
   return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
  })
  return response;
}


const getCustomLoginPageSettings = (path) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getCustomLoginPageSettings}`;
  const data = new FormData();
  data.append('path', path);
  Service.post(url, data, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    if(response.status === 1) {
      window.location.reload();
    }
  }).catch(er => { 
    dispatch({ type: LOADING, loading: false }); 
    let message=er.message;
    store.addNotification({
      title: 'Error',
      message: `${message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
};

const increaseAssessmentAttemptCount = (user_id,attempt_count,assessment_id,end_date,batch_id,is_toc) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.increaseAssessmentAttemptCount}`;
  const response = Service.post(url,{user_id,attempt_count,assessment_id,end_date,batch_id,is_toc},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const increaseAssessmentDateAttemptCount = (user_id,assessment_id,end_date,batch_id,is_toc) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.increaseAssessmentAttemptCount}`;
  const response = Service.post(url,{user_id,assessment_id,end_date,batch_id,is_toc},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const increaseAssignmentAttemptCount = (user_id,attempt_count,assignment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.increaseAssignmentAttemptCount}`;
  const response = Service.post(url,{user_id,attempt_count,assignment_id},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const increaseBatchAssignmentAttemptCount = (batch_id,assignment_id,attempt_count) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.increaseAssessmentAssignmentAttemptCount}`;
  const response = Service.post(url,{batch_id,assignment_id,attempt_count},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const increaseBatchAssessmentAttemptCount = (batch_id,assessment_id,attempt_count,is_toc) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.increaseAssessmentAssignmentAttemptCount}`;
  const response = Service.post(url,{batch_id,assessment_id,attempt_count,is_toc},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const increaseBatchAssessmentDateAttemptCount = (batch_id,assessment_id,end_date,is_toc) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.increaseAssessmentAssignmentAttemptCount}`;
  const response = Service.post(url,{batch_id,assessment_id,end_date,is_toc},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const getLearnerAttendanceReport = (batch_id,month,year) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getLearnerAttendanceReport}`;
  const response = Service.post(url,{batch_id,month,year},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    //window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const getLearnerAttendanceReportFilters = (token) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getLearnerAttendanceReportFilters}`;
  const response = Service.post(url,{token},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    //window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}


const adminLectureWiseAttendance = (batch_id,lecture_id,page,search) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.adminLectureWiseAttendance}?page=${page}`;
  const response = Service.post(url,{batch_id,lecture_id,page,search},(status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    //window.location.reload();
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
  })
  return response;
}

const adminMarkAttendanceAPI = (lecture_id,user_id,join_time,join_type,is_absent) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.adminMarkAttendanceAPI}`;
  const response=Service.post(url, {lecture_id,user_id,join_time,join_type,is_absent}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const userSurvayCompletion = (survey_id,content_id,responses) => async dispatch => {
  
  dispatch({ type: LOADING, loading: false });
  const url = `${common.userSurvayCompletion}`;
  const response=Service.post(url, {survey_id,content_id,responses}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


const amplifiuFetchThreadListing = (user_id,toc_id) => async dispatch => {
  
  dispatch({ type: LOADING, loading: false });
  const url = `${common.amplifiuFetchThreadListing}`;
  const response=Service.post(url, {user_id,toc_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    // store.addNotification({
    //   title: 'Error',
    //   message: `${er.message}`,
    //   type: 'danger',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
  })
  return response;
}


const amplifiuFetchThreadDocumentListing = (limit,from,search,user_id,sort,order) => async dispatch => {
  
  dispatch({ type: LOADING, loading: false });
  const url = `${common.amplifiuFetchThreadDocumentListing}`;
  const response=Service.post(url, {limit,from,search,user_id,sort,order}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er?.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}

const amplifiuSelectActiveInactive = (id,status) => async dispatch => {
  
  dispatch({ type: LOADING, loading: false });
  const url = `${common.amplifiuSelectActiveInactive}`;
  const response=Service.post(url, {id,status}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    // store.addNotification({
    //   title: 'Error',
    //   message: `${er?.message}`,
    //   type: 'danger',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
  })
  return response;
}


const amplifiuThreadCreation = (user_id,form_json) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.amplifiuThreadCreation}`;
  const response = Service.post(url, {user_id,form_json}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    //console.error('error', er.message[0]);
  })
  return response;
}


const htmlToPDF = (html_data) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.htmlToPDF}`;
  const response = Service.post(url, {html_data}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    //console.error('error', er.message[0]);
  })
  return response;
}

const amplifiuLessonPlanClone = (id,thread_id,openai_userthread_id,user_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.amplifiuLessonPlanClone}`;
  const response = Service.post(url, {id,thread_id,openai_userthread_id,user_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    // window.location.reload();
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message[0]);
  })
  return response;
}

const amplifiuFetchThreadVersionHistoryListing = (thread_id,user_id) => async dispatch => {
  
  dispatch({ type: LOADING, loading: false });
  const url = `${common.amplifiuFetchThreadVersionHistoryListing}`;
  const response=Service.post(url, {thread_id,user_id}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er?.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}



const amplifiuGetTemplateData = () => async dispatch => {
  
  dispatch({ type: LOADING, loading: false });
  const url = `${common.amplifiuGetTemplateData}`;
  const response=Service.post(url, {}, (status,response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false }); 
    store.addNotification({
      title: 'Error',
      message: `${er?.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
  })
  return response;
}


export default {
  wpUserLinkTokenValidate,
  resetPassword,
  userSignup,
  submitSetting,
  bannerUpdate,
  widgetQuestionCreate,
  formCreate,
  bannerCreate,
  widgetSubmit,
  widgetReorder,
  addMoreSemesters,
  programSessionEdit,
  programEdit,
  sessionProgramMapping,
  programCreate,
  sessionCreate,
  promoteStudentSem,
  widgetQuestionSubmit,
  bbbJoinLiveClass,
  classbatchmapping,
  userNotificationLogs,
  userNotificationReads,
  unsubscribe,
  assignmentPlannerdelete,
  assignmentPlannerList,
  editQuestionBank,
  questionSearch,
  encryption,
  qodQuestionSubmit,
  questionSectionMapping,
  qodUpdate,
  qodCreate,
  exportNotes,
  exportVideos,
  userLogReport,
  decrypt,
  deleteQod,
  userContentCompletion,
  sendInvitePassword,
  deleteAssignment,
  assignmentEdit,
  facultyUploadMarks,
  assignmentPlanner,
  facultyAssignmentList,
  uploadUserAssigFile,
  learnerAssignmentReview,
  assignmentReview,
  createCenter,
  updateCenter,
  deleteCenter,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  createClass,
  updateClass,
  deleteClass,
  createSection,
  updateSection,
  deleteSection,
  createSubject,
  updateSubject,
  deleteSubject,
  createFaculty,
  updateFaculty,
  deleteFaculty,
  createStudent,
  updateStudent,
  deleteStudent,
  createAssessment,
  editAssessmentBasic,
  updateAssessment,
  publishAssessment,
  deleteAssessment,
  addAssessmentSection,
  updateAssessmentSection,
  deleteAssessmentSection,
  createQuestion,
  editQuestion,
  assignSection,
  createDPP,
  editDPP,
  assessmentEditDPP,
  deleteDPP,
  addUnit,
  updateUnit,
  publishContent,
  deleteUnit,
  addContent,
  updateContent,
  deleteContent,
  newLecture,
  editLecture,
  deleteLecture,
  sendInvite,
  postDoubt,
  switchCourse,
  sendOtp,
  updatePassword,
  updateProfile,
  changePassword,
  sendQuery,
  updateImage,
  studentQuestionResponse,
  submitMarks,
  xpUserSignUp,
  xpUserOTPVerification,
  xpStudentCreation,
  xpUserBannerCreation,
  learnerAssignmentList,
  assignmentCreate,
  updateAssignmentPlanner,
  publishAssignment,
  xpsendMentorship,
  xpSubmitGoalQuestionList,
  xpSubmitTellInterest,
  xpGetAllChallengesAssignmentList,
  getAssessmentsReportsDetails,
  getAssessmentsSessionFilters,
  getChildSelectData,
  getAssessmentsNestedProgramFilters,
  getAssessmentsNestedClassFilters,
  getAssessmentsNestedSubjectsFilters,
  getAssessmentsNestedBatchFilters,
  getAssessmentsNestedAssessmentsFilters,
  sendAssessmentsResportsData,
  callzoomAttendanceAPI,
  callFacultyzoomAttendanceAPI,
  getLectureDatabyID,
  resetPasswordActivateUser,
  learnerAssignmentReview2,
  assessmentCreateDPP,
  mobileOTPSend,
  mobileOTPValidate,
  uploadUserAssigAddMoreFile,
  facultyUploadMutlipleFiles,
  addExperience,
  deletePortfolio,
  addlanguageYearsOFExperience,
  facultyZoomCreate,
  teacherUploadDoucment,
  teacherUploadDoucmentList,
  createStudioProject,
  updateStudioProject,
  studioProjectUserUploadFile,
  getFacultyStudentProjectResponse,
  facultyUploadStudioProjectMarks,
  studioProjectUserAttemptList,
  studioProjectListing,
  studioProjectByid,
  facultyAssignmentReportDownload,
  curriculumCreate,
  curriculumList,
  curriculumUpdate,
  getCurriculumListing,
  bulkUserCreate,
  userResetPassword,
  studentActiveInactive,
  bulkBatchUpdate,
  bulkPasswordUpdate,
  bulkQuestionUpdate,
  scheduleFBNotifications,
  getLanguageWiseData,
  getPageLanguageWiseData,
  getCustomLoginPageSettings,
  AllUserNotificationLogs,
  increaseAssessmentAttemptCount,
  increaseAssessmentDateAttemptCount,
  increaseAssignmentAttemptCount,
  increaseBatchAssignmentAttemptCount,
  increaseBatchAssessmentAttemptCount,
  increaseBatchAssessmentDateAttemptCount,
  getLearnerAttendanceReport,
  getLearnerAttendanceReportFilters,
  adminLectureWiseAttendance,
  adminMarkAttendanceAPI,
  userSurvayCompletion,
  amplifiuFetchThreadListing,
  amplifiuFetchThreadDocumentListing,
  amplifiuSelectActiveInactive,
  amplifiuThreadCreation,
  htmlToPDF,
  amplifiuLessonPlanClone,
  amplifiuFetchThreadVersionHistoryListing,
  amplifiuGetTemplateData
}