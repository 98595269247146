import StudyMaterial from '../pages/study-material/StudyMaterial';
import StudyMaterialTopics from '../pages/study-material/StudyMaterialTopics';
import FacultyDoubts from '../pages/doubts/FacultyDoubts';
import FacultyDoubtResponses from '../pages/doubts/FacultyDoubtResponses';
import SettingsFaculty from '../pages/settings/Faculty';
import FacultyTests from '../pages/student-assessments/FacultyTests';
import FacultySessions from '../pages/sessions/FacultySessions';
import Practice from '../pages/student-assessments/FacultyPractice';
import LessonUnits from '../pages/content/LessonUnits';
import LessonChapters from '../pages/content/LessonChapters';
import LessonTopics from '../pages/content/LessonTopics';
import LessonSubtopics from '../pages/content/LessonSubtopics';
import StudyMaterialCreate from '../pages/student-assessments/FacultyStudyMaterial';
import FacultyAssignmentMaterial from '../pages/admin/FacultyAssignmentMaterial';
import FacultyAssignment from '../pages/admin/FacultyAssignment';
import StudioProject from '../pages/studio-project/StudioProject';
import StudioProjectDetail from '../pages/studio-project/StudioProjectDetail';
import LessonPlan from '../pages/lessonplan/LessonPlan'
import ExploreAll from '../pages/lessonplan/ExploreAll';
import LearnLessonPlan from '../pages/lessonplan/LearnLessonPlan';
import CollaborateLessonPlan from '../pages/lessonplan/CollaborateLessonPlan';
import Terms from '../pages/lessonplan/Terms';

export default [
  {
    exact: true,
    component: StudyMaterial,
    path: '/faculty/study-material/:id'
  },
  {
    exact: true,
    component: FacultyAssignmentMaterial,
    path: '/faculty/assignment/:id'
  },
  {
    exact: true,
    component: FacultyAssignment,
    path: '/faculty/assignment'
  },
  {
    exact: true,
    component: StudioProject,
    path: '/faculty/studio-project'
  },
  {
    exact: true,
    component: StudioProjectDetail,
    path: '/faculty/studio-project-detail'
  },
  {
    exact: true,
    component: StudyMaterialTopics,
    path: '/faculty/study-material-topics/:id'
  },
  {
    exact: true,
    component: SettingsFaculty,
    path: '/faculty/settings'
  },
  {
    exact: true,
    component: FacultyDoubts,
    path: '/faculty/doubts'
  },
  {
    exact: true,
    component: FacultyDoubtResponses,
    path: '/faculty/doubt-responses/:id'
  },
  {
    exact: true,
    component: FacultyTests,
    path: '/faculty/tests'
  },
  {
    exact: true,
    component: FacultySessions,
    path: '/faculty/sessions'
  },
  {
    exact: true,
    component: Practice,
    path: '/faculty/practice'
  },
  {
    exact: true,
    component: LessonUnits,
    path: '/faculty/study-material-create/units'
  },
  {
    exact: true,
    component: LessonChapters,
    path: '/faculty/study-material-create/units/chapters'
  },
  {
    exact: true,
    component: LessonTopics,
    path: '/faculty/study-material-create/units/chapters/topics'
  },
  {
    exact: true,
    component: LessonSubtopics,
    path: '/faculty/study-material-create/units/chapters/topics/subtopic'
  },
  {
    exact: true,
    component: StudyMaterialCreate,
    path: '/faculty/study-material-create'
    //path: '/faculty/study-material/:id'
  },
  {
  exact: true,
  component: LessonPlan,
  path: '/faculty/amplifiu-create'
  //path: '/faculty/study-material/:id'
},

{
  exact: true,
  component: LearnLessonPlan,
  path: '/faculty/amplifiu-learn'
  //path: '/faculty/study-material/:id'
},

{
  exact: true,
  component: CollaborateLessonPlan,
  path: '/faculty/amplifiu-collaborate'
  //path: '/faculty/study-material/:id'
},

{
  exact: true,
  component: ExploreAll,
  path: '/faculty/explore-all'
  //path: '/faculty/study-material/:id'
},


]