import React, { Component } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Listing from '../../public/icons/listing.svg';
import Trash from '../../public/icons/trash.svg';
import Stamp from '../../public/icons/stamp.svg';
import Calendar from '../../public/icons/calendar.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import download from '../../public/icons/down-arrow.svg';
import common from '../../common';
import axios from 'axios';
import './styles.css';
import Back from '../../public/icons/back.svg';
import { CsvToHtmlTable } from 'react-csv-to-table';
import reportsLoader from '../../public/icons/loader.png';
import Multiselect from 'multiselect-react-dropdown';
import  DataTable from 'react-data-table-component';
import { capitalize } from 'lodash-es';
import Select from 'react-select'
import { type } from 'os';



const testing = [{value: 'Option 1️', id: 1},{value: 'Option 2️', id: 2}]
const items = [
  {value: 'apple'},
  {value: 'pear'},
  {value: 'orange'},
  {value: 'grape'},
  {value: 'banana'},
]

const selectData = [
  { value: 'chocolate', label: 'Chocolate', id:1},
  { value: 'strawberry', label: 'Strawberry',  id:2},
  { value: 'vanilla', label: 'Vanilla' , id:3}
]

class Report extends Component {

  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      opend: false,
      openf: false,
      openp: false,
      center_id: localStorage.getItem('center_id'),
      token: localStorage.getItem('token'),
      filterClass: '',
      filterClassName: '',
      filterSubject: '',
      filterSubjectName: '',
      class_centers: {},
      class_center: '',
      centers: [],
			list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      assessments: [],
      filterAssessments: [],
      is_published: false,
      filterClasses: [],
      classes: [],
      subjects: [],
      subjects2: [],
      class_id: '',
      subject_id: '',
      is_faculty_admin:false,
      user_id: localStorage.getItem("user_id"),
      assessmentsTypesData:[],
      assessmentsReportsDetailsData:[],
      assessmentsReportsDetailsDataColumn:{},
      assessmentsNestedFiltersProgramData:[],
      assessmentsNestedFiltersClassData:[],
      assessmentsNestedFiltersSubjectsData:[],
      assessmentsNestedFiltersBatchData:[],
      assessmentsNestedFiltersAssessmentData:[],
      
      SessionSelectedId:'',
      columnData:[],

      downloadType:'0',
      assessmentsType:'',
      centerType:'',
      sessionType:'',
      programType:'',
      classType:'',
      subjectType:'',
      assessmentID:'',
      batchType:'',
      columnType:'',
      lectureID:'',
      filtersType:[],
      assessmentsReportsData:'',
      assessmentsReportsDataJson:[],
      reportsLoader:false,

      programSelected:[],

      is_nested:false,

      logStartDate:'',
      logEndsDate:'',
      nestedFiltersRelationship:[],

      filterTypeIndexArray:[],

      activeOptions:[],

      activeButtonState:false
      
 
    }
    
  
  }
  
  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getCenterList();
    this.getAssessmentList(this.state.center_id);
    this.getClassList();
    this.getAssessmentsTypesList();
    this.getUrlfromCurrentPage();  
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  show = () => this.setState({ open: true, class_id: '', subject_id: '', section_id: '' });
  
  close = () => this.setState({ open: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  showp = (val) => this.setState({ openp: true, d_id: val.id, is_published: !val.is_published });
  
  closep = () => this.setState({ openp: false });

  showf = (val) => this.setState({ openf: true });
  
  closef = () => this.setState({ openf: false });

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ centers: response.data });
    }
  }

  getAssessmentList = async (center) => {
    const response = await this.props.getAssessmentList(center);
    if(response !== undefined) {
      this.setState({ assessments: response.data.filter(assessment => assessment.type === 1),
        filterAssessments: response.data.filter(assessment => assessment.type === 1) });
    }
  }

  getClassList = async () => {
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ), 
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
    }
  }

  getSubjectList = async (class_center, class_id) => {
    const response = await this.props.getSubjectList2(class_center);
    if(response !== undefined) {
      this.setState({ subjects: response.data.filter(subject => subject.class_details.id === parseInt(class_id)), 
        subject_names: response.data.reduce((obj, item) => Object.assign(obj, { [item.id]: item.label }), {}) });
    }
  }

  getSubjectList2 = async (class_center, class_id) => {
    const response = await this.props.getSubjectList2(class_center);
    if(response !== undefined) {
      this.setState({ subjects2: response.data.filter(subject => subject.class_details.id === parseInt(class_id)) });
    }
  }

	changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  filterCenterChange = (e) => {
    if(e.target.value === '') {
      this.setState({ filterCenter: '', filterClass: '', filterSubject: '',
                        filterClassName: '', filterSubjectName: '' });
      this.getAssessmentList(this.state.center_id);
    }
    else {
      this.setState({ filterCenter: e.target.value, filterClass: '', filterSubject: '',
                        filterClassName: '', filterSubjectName: '',
                        filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value)) });
      this.getAssessmentList(e.target.value);
    }
  }

  classChange2 = (e) => {
    if(e.target.value === '') {
      this.setState({ filterClass: '', filterSubject: '', filterClassName: '',
                        filterSubjectName: '' });
    }
    else {
      this.setState({ filterClass: e.target.value, filterSubject: '', filterSubjectName: '',
                        filterClassName: this.state.class_names[e.target.value],
                        class_center: this.state.class_centers[e.target.value] })
      this.getSubjectList(this.state.class_centers[e.target.value], e.target.value)
    }
  }

  subjectChange2 = (e) => {
    if(e.target.value === '') {
      this.setState({ filterSubject: '', filterSubjectName: '' })
    }
    else {
      this.setState({ filterSubject: e.target.value, 
          filterSubjectName: this.state.subject_names[e.target.value] });
    }
  }

  filterClassSubject = () => {
    if(this.state.filterClass !== '' && this.state.filterSubject !== '') {
      var assessments = this.state.assessments.filter(assessment => {
        return assessment?.classs === parseInt(this.state.filterClass) 
          && assessment?.subject_id === parseInt(this.state.filterSubject)
      });
      this.setState({ filterAssessments: assessments });
    }
    this.setState({ openf: false })
  }

  removeFilters = () => {
    this.setState({ filterClass: '', filterSubject: '', filterClassName: '',
              filterSubjectName: '', filterAssessments: this.state.assessments, openf: false });
  }

  classChange = (e) => {
    this.setState({ class_id: e.target.value, subject_id: '' })
    this.getSubjectList2(this.state.class_centers[e.target.value], e.target.value)
  }

  subjectChange = (e) => {
    this.setState({ subject_id: e.target.value })
  }

  deleteAssessment = async () => {
    const { d_id } = this.state;
    await this.props.deleteAssessment(d_id);
    this.setState({ opend: false });
  }

  publishAssessment = async () => {
    const { d_id, is_published } = this.state;
    await this.props.publishAssessment(d_id, is_published);
    this.setState({ openp: false });
  }

  changeTab = () =>{
    localStorage.setItem("is_faculty_admin","false")
    this.setState({is_faculty_admin:false})
  }

  exportFile = async() => {
    let dateObj= new Date();
    axios.get(`${common.apiBase}/${common.userLogReport}`,
      {headers: {
        'Authorization': 'token ' + this.state.token}
      }).then(res => {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'usersLog-'+dateObj.toISOString()+'.csv';
        hiddenElement.click();
    }).catch(err => { console.error('error', err.message) })
  }

  exportUserLoginLog = async() => {
    let dateObj= new Date();
    axios.get(`${common.apiBase}/${common.userLoginReport}`,
      {headers: {
        'Authorization': 'token ' + this.state.token}
      }).then(res => {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'usersLog-'+dateObj.toISOString()+'.csv';
        hiddenElement.click();
    }).catch(err => { console.error('error', err.message) })
  }

  exportUserAccessLogTime = async() => {
    let dateObj= new Date();
    axios.get(`${common.apiBase}/${common.userAccessLogTimeDuration}`,
      {headers: {
        'Authorization': 'token ' + this.state.token}
      }).then(res => {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'usersLog-'+dateObj.toISOString()+'.csv';
        hiddenElement.click();
    }).catch(err => { console.error('error', err.message) })
  }


  getAssessmentsTypesList = async () => {
    const response = await this.props.getAssessmentsTypesList();
    if(response !== undefined) {
      console.log("types response");
      this.setState({ assessmentsTypesData: response.data});
      
    }
  }

  assessmentsTypesSelect = (e) =>{
    let getselectoption = e.target.value; 
    let targetEl = e.target.parentElement;
    console.log("targetEl",targetEl);
    console.log("getselectoption",getselectoption);
    this.setState({assessmentsType:e.target.value});
    this.setState({activeButtonState:false});
    this.getAssessmentsReportsDetails(getselectoption);

  }

  getAssessmentsReportsDetails = async (key) => {
    let centerId = this.state.center_id;
   
    const response = await this.props.getAssessmentsReportsDetails(key,centerId);
    if(response !== undefined) {
     this.setState({assessmentsReportsDetailsData:[]});
      this.setState({assessmentsReportsDetailsData: response.data});
      this.setState({assessmentsReportsDetailsDataColumn:[]});
      this.setState({assessmentsReportsDataJson:[]});
      this.setState({filtersType:[]})
      this.setState({assessmentsReportsDetailsDataColumn: response.data.column});
      let tmpObject = {}
      if(response.data.filters)
        response.data.filters.map(val => tmpObject[val.filter_type] = val.nested_filter_type);
      this.setState({nestedFiltersRelationship : tmpObject});

      
      tmpObject = {}
      if(response.data.filters)
        response.data.filters.map((val,i) => tmpObject[val.filter_type] = i);
      this.setState({filterTypeIndexArray : tmpObject});
      // console.log("nestedFiltersRelationship", this.state.nestedFiltersRelationship)
      // console.log("filterTypeIndexArray", this.state.filterTypeIndexArray)
      }
  }

   childSelectData = (selectedOptions,inputProps) =>{
    let {assessmentsReportsDetailsData} = this.state;
    let allvalues = [];
    
    let selectedOptionsArray = [];
     if(!Array.isArray(selectedOptions)){
      selectedOptionsArray.push(selectedOptions);
     }else{
      selectedOptionsArray =  selectedOptions;
     }
     let tmpObject = Object.assign({}, this.state.assessmentsReportsDetailsData);


    console.log("selectedOptionsArray",selectedOptionsArray)
     let ID = selectedOptionsArray.map(data => data.id).join(',');
    let currentElementName = inputProps.name;
    let filterType = currentElementName.substring(10);
    console.log("filterType")
    console.log(filterType)
     if(filterType === 'program'){
      this.setState({programType:ID})
    }
    if(filterType === 'class'){
      this.setState({classType:ID})
    }
    if(filterType === 'subject'){
      this.setState({subjectType:ID})
    }
    if(filterType === 'batch'){
      this.setState({batchType:ID})
    }
    if(filterType === 'session'){
      this.setState({sessionType:ID})
    }
    if(filterType === 'assessment'){
      this.setState({assessmentID:ID})
    }
    if(filterType === 'lecture'){
      this.setState({lecture_id:ID})
    }
    if(filterType === 'content'){
      this.setState({content_id:ID})
    }
    let currentIndex = this.state.filterTypeIndexArray[filterType]
    for(let i in tmpObject.filters[currentIndex].values){
      tmpObject.filters[currentIndex].values[i].is_current=false
      for(let j in selectedOptionsArray){
        if(tmpObject.filters[currentIndex].values[i].id == selectedOptionsArray[j].id){
          tmpObject.filters[currentIndex].values[i].is_current = true
        }
      }
    }
    this.setState({assessmentsReportsDetailsData:tmpObject})
    console.log("assessmentsReportsDetailsData", assessmentsReportsDetailsData);


    //New COde Fro Button
    let checkList = [];

    let findRequiredFilters = assessmentsReportsDetailsData.filters.filter((item) => {
        return item.required == "true";
    });
    console.log("findRequiredFilters",findRequiredFilters)
    // let filteritem = findRequiredFilters.values.filter((item)=>{
    //   return item.required == "true";
    // });
    //console.log("filteritem",filteritem)
    let submitButton = true;
        findRequiredFilters.forEach(element => {
          // console.log(element)
            let submitButtonInner = false;
            element.values.forEach((item)=>{
              
              if(item.is_current){
                submitButtonInner = true;
                return;
              }
          });
          
          if(!submitButtonInner){
            submitButton = false;
            return false;
          }

          });

        this.setState({activeButtonState : submitButton})
    
    
    
    //New COde Fro Button

    

  


    if(inputProps.removedValue){
      
      let removedItems =  inputProps.removedValue;
      this.removeSelectedValues(removedItems,filterType)
      console.log("removedItems",removedItems)
    }else{
      if(this.state.nestedFiltersRelationship[filterType]){
        let subTypes = this.state.nestedFiltersRelationship[filterType]
        let subTypesArray = subTypes.split(',');
        subTypesArray.map(targetFilterType =>  { 
          this.getChildSelectDataTemp(ID, targetFilterType);
        })
        }
     
     }
  
    }


  removeSelectedValues = (removedValues, filterType)=>{
    let currentIndex = this.state.filterTypeIndexArray[filterType]
    console.log('indexarra:', this.state.filterTypeIndexArray, 'idx:' + currentIndex);
    let tmpObject = Object.assign({}, this.state.assessmentsReportsDetailsData);

    for(let j in tmpObject.filters[currentIndex].values){
      if(removedValues.id == tmpObject.filters[currentIndex].values[j].id){
        let childFilterTypeArray = tmpObject.filters[currentIndex].nested_filter_type
        
        if(childFilterTypeArray){
          childFilterTypeArray = childFilterTypeArray.split(',')
        }
        tmpObject.filters[currentIndex].values[j].is_current = false;
        // tmpObject.filters[currentIndex].values.splice(j);
        this.setState({assessmentsReportsDetailsData : tmpObject});
        if(childFilterTypeArray){
          childFilterTypeArray.map(childFilterType=>{this.removeSelectedValuesRec(removedValues.id, childFilterType)});
        }
      }
    }
   
    console.log('tmpObjectState:', this.state.assessmentsReportsDetailsData);
   }
  
   removeSelectedValuesRec = (removedParentId, filterType)=>{
      let currentIndex = this.state.filterTypeIndexArray[filterType]

      // let tmpObject = JSON.parse(JSON.stringify(this.state.assessmentsReportsDetailsData))
      let tmpObject = Object.assign({}, this.state.assessmentsReportsDetailsData)
      // console.log('----------------------', currentIndex , filterType);
      // let tmpObject = this.state.assessmentsReportsDetailsData
      while(true){
        let re = true;
        for(let j in tmpObject.filters[currentIndex].values){
          if(removedParentId == tmpObject.filters[currentIndex].values[j].parent_id){
            re = false;
            let childFilterTypeArray = tmpObject.filters[currentIndex].nested_filter_type
            if(childFilterTypeArray){
              childFilterTypeArray = childFilterTypeArray.split(',')
            }
    
            let currentId = tmpObject.filters[currentIndex].values[j].id
            //remove element
            tmpObject.filters[currentIndex].values.splice(j,1);

            this.setState({assessmentsReportsDetailsData : tmpObject});
            // console.log('--------------------new state: ' + j + '::', this.state.assessmentsReportsDetailsData, tmpObject);
            
            //call remove child elements          
              if(childFilterTypeArray){
                // this.removeSelectedValuesRec(currentId, childFilterType);
                childFilterTypeArray.map(childFilterType=>{this.removeSelectedValuesRec(currentId, childFilterType)});
              }
              break;
          }
        }
        if(re) break;
      }

   }

   getChildSelectDataTemp = async (myId, targetFilterType) => {
    const response = await this.props.getChildSelectData(myId, targetFilterType);
    if(response !== undefined) {
      let assessmentsReportsDetailsData = Object.assign({}, this.state.assessmentsReportsDetailsData)
      for(let i in assessmentsReportsDetailsData.filters){
        let f = assessmentsReportsDetailsData.filters[i];
        if(f.filter_type == targetFilterType){
          let oldValues = assessmentsReportsDetailsData.filters[i].values
          let oldValiuesIndex = [];
          for(let k in oldValues){
            oldValiuesIndex[oldValues[k].id] = (oldValues[k].is_current)?oldValues[k].is_current:false
          } 
          assessmentsReportsDetailsData.filters[i].values = response.data[targetFilterType];
          for(let j in assessmentsReportsDetailsData.filters[i].values){
            assessmentsReportsDetailsData.filters[i].values[j].is_current = oldValiuesIndex[assessmentsReportsDetailsData.filters[i].values[j].id]
          }
        }
      }
      // console.log('___________________newstatedata: ', assessmentsReportsDetailsData);
      this.setState({assessmentsReportsDetailsData: assessmentsReportsDetailsData});
      
      
    }
  }


  assessmentsFiltersChange = (e,value) =>{
    let {filtersType} = this.state;
    
    if(e.target.checked === true){
       filtersType.push(value);
       this.setState({filtersType})
    }else{
     let index = filtersType.indexOf(value)
     filtersType.splice(index,1);
     this.setState({filtersType})
    }
    
    console.log("filtersType");
    console.log(filtersType);
 
    }
   

   assessmentDataSend = async () =>{
    let {is_nested, columnData,assessmentsReportsDataJson, reportsLoader, downloadType, logStartDate, logEndsDate, assessmentsType,center_id,sessionType, programType ,classType ,subjectType ,assessmentID ,batchType ,filtersType,lecture_id,content_id} = this.state;
    this.setState({reportsLoader:true});
    console.log("lecture_id")
    console.log(lecture_id)
    console.log("content_id")
    console.log(content_id)
    const response = await this.props.sendAssessmentsResportsData(logStartDate, logEndsDate, downloadType, assessmentsType, center_id, sessionType, 
    programType, classType, subjectType, assessmentID,batchType, filtersType.join(','),lecture_id,content_id);
    if(response !== undefined) {

      if(assessmentsType !== 'assesment_attempt_detailed'){
        console.log("Send Json Data response");
        console.log(response.data);
        this.setState({assessmentsReportsDataJson: response.data});
        this.setState({reportsLoader:false});

        let columnData = Object.keys(response.data.length > 0 && response.data[0]).map(val => ({name:val.replace("_"," ").toUpperCase(), selector: row => row[val]}));
        console.log('columnData');
        console.log(columnData);
        this.setState({columnData:columnData})
      }else{
        console.log("Send Json Data response");
        console.log("assesment_attempt_detailed");
        console.log(response.data.answers);
        this.setState({assessmentsReportsDataJson: response.data.answers});
        this.setState({reportsLoader:false});

        let columnData = Object.keys(response.data.answers.length > 0 && response.data.answers[0]).map(val => ({name:val.replace("_"," ").toUpperCase(), selector: row => row[val]}));
        console.log('columnData');
        console.log(columnData);
        this.setState({columnData:columnData})
      }
    }

   }



   ReportsDownload = () =>{
    let {reportsLoader, logStartDate, logEndsDate, downloadType, assessmentsType,center_id,sessionType, programType ,classType ,subjectType ,assessmentID ,batchType ,filtersType,lecture_id,content_id} = this.state;

    let options = filtersType.join(',')
    this.setState({reportsLoader:true});
    axios.post(`${common.apiBase}${common.sendAssessmentsResportsData}`,
    {"from_date": logStartDate, "to_date" : logEndsDate, 'download_type': '1', 'type':assessmentsType, 'center_id':center_id, 'session_id': sessionType, 
    'program_id':programType, 'class_id': classType, 'subject_id': subjectType, 'assessment_id':assessmentID,'batch_id':batchType,'column':options,'lecture_id':lecture_id, 'content_id':content_id},
      {headers: {
        'Authorization': 'token ' + this.state.token}
      }).then(res => {
        this.setState({assessmentsReportsData: res.data});
        this.setState({reportsLoader:false});
        let dateObj= new Date();
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
        hiddenElement.target = '_blank';
        hiddenElement.download = this.state.assessmentsType+"-"+dateObj.toISOString()+'.csv';
        hiddenElement.click();
      }).catch(err => { console.error('error', err.message) })
    

   }

   assessmentsStartDateChange = (e) =>{
    let startDate = e.target.value;
    this.setState({logStartDate:startDate});
    console.log(this.state.logStartDate);
    
  }

  assessmentsEndsDateChange = (e) =>{
    let endDate = e.target.value;
    this.setState({logEndsDate:endDate});
    console.log(this.state.logEndsDate);
    
  }
  

	render() {
  
    const isEnabled = this.state.class_id !== '' && this.state.subject_id !== '';
    const isEnabledf = this.state.filterClass !== '' && this.state.filterSubject !== '';
    const isEnabledSubmit = this.state.filtersType.length > 0;
    const otherIsEnabledSubmit = (this.state.assessmentsTypesData.length > 0 && this.state.activeButtonState) || (this.state.logStartDate && this.state.logEndsDate);
    const {assessmentsReportsDetailsDataColumn,columnData} = this.state
    const customStyles = {
      headCells: {
        style: {
            fontFamily: 'Lato', // override the row height
            fontSize: '14px',
			      fontWeight: 700,
        },
      },
      cells: {
        style: {
            fontFamily: 'Lato', // override the row height
            fontSize: '16px',
			      fontWeight: 400,
        },
      },

    }


    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search assessments"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.practice_tests}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }

          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.reports}</title>
        </Helmet>
				<Header placeholder={this.props.pagewiseLanguageData.search_practice_tests}/>
				<div className="main-part">
					<div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
								<h1>{this.props.pagewiseLanguageData.reports}</h1>
							</div>
						</div>
					</div>
          {/* <div className="export-file" onClick={this.exportFile}><img src={download} alt="export"/> <span>Download User Access Log</span></div>
          <div className="export-file" onClick={this.exportUserLoginLog}><img src={download} alt="export"/> <span>Download User Login Log</span></div>
          <div className="export-file" onClick={this.exportUserAccessLogTime}><img src={download} alt="export"/> <span>Download User Access Log Time Duration</span></div> */}


          {/*Assessments Reports start*/}
              <div className="assessmentsReportsWrapper">
                <div className="assessmentsReportsContainer">
                    <div className={"reportsLoader " + (this.state.reportsLoader? "show":"")}><span></span></div>

                    <div className="selectBoxWrapper">
                    <div className="AssessmentTypesWrapper">
                    <div className="AssessmentTypesBlock">
                    <div className="block">
                      <label>{this.props.pagewiseLanguageData.select_report}</label>
                      <select onChange={this.assessmentsTypesSelect}>
                      <option value =''>{this.props.pagewiseLanguageData.select_report}</option>
                        {this.state.assessmentsTypesData  && this.state.assessmentsTypesData.map((types,index)=>
                          <option value={types.key}>{types.value}</option>
                        )}
                      </select>
                      </div>
                     {/* {JSON.stringify(this.state.assessmentsReportsDetailsData.filters)} */}

                    {this.state.assessmentsReportsDetailsData.filters?.length > 0 && this.state.assessmentsReportsDetailsData.filters?.length && this.state.assessmentsReportsDetailsData.filters?.map((types,index)=>
                   <>
  
                    {/*Code For Multiselect Start*/}
                    {(types.type == 'multi select') && <div className="block"><label>{types.label}</label>
                        
                    <Select className="reportsMultiSelectBox" value={types.values.filter(function(option) {return option.is_current ? true : ''})} closeMenuOnSelect = {true} cache={false} isClearable={false} isSearchable="false" name={"filter_id_" + types.filter_type} placeholder={this.props.pagewiseLanguageData.select} onChange={this.childSelectData} id={"filter_id_" + types.filter_type} options={types.values.filter(data=>data.id !== '')} isMulti />
                      
                    </div>
                    }

                    {/*Code For Multiselect Ends*/}


                    {/*Code For Single Select Start*/}  
                    {(types.type == 'select') &&
                     <div className="block"><label>{types.label}</label>
                      <Select value={types.values.filter(function(option) {return option.is_current ? true : false})} className="reportsSelectBox" isClearable={false} isSearchable="false" name={"filter_id_" + types.filter_type} placeholder={this.props.pagewiseLanguageData.select} onChange={this.childSelectData} id={"filter_id_" + types.filter_type} options={types.values} />
                     </div>
                      }
                     {/*Code For Single Select Ends*/}


                     {/*Code For Single Select Start*/}  
                      {types.filter_type=='from_date' && <div className="block">
                          <label>{types.label}</label>
                          <input type="date"  name="end_date"  onChange={(e) => this.assessmentsStartDateChange(e)} placeholder="enter start date" />   
                        </div>}

                        {types.filter_type=='to_date' && <div className="block">
                          <label>{types.label}</label>
                          <input type="date" name="end_date"  onChange={(e) => this.assessmentsEndsDateChange(e)} placeholder="enter start date" />   
                        </div>}
                     {/*Code For Single Select Ends*/} 
                    
                    </>
                     
                    )}                    
                    </div>
                  </div>  
                   </div>  
                  {assessmentsReportsDetailsDataColumn && Object.keys(assessmentsReportsDetailsDataColumn).length ? <div className="filtersWrapper">
                    <h2>{this.props.pagewiseLanguageData.select_columns_to_show_in_report}</h2>
                    <div className="labelWrapper">
                    {assessmentsReportsDetailsDataColumn && Object.keys(assessmentsReportsDetailsDataColumn).map(val =>
                      <>{this.state.filtersType.includes(val)}<div className="labelBlock"><label><input val={val} type="checkbox" defaultChecked={this.state.filtersType.includes(val)} onChange={(e) => this.assessmentsFiltersChange(e,val)} /><span></span>{assessmentsReportsDetailsDataColumn[val]}</label></div></>
                    )}
                  </div>
                  </div>
                  : '' }

                  {assessmentsReportsDetailsDataColumn && Object.keys(assessmentsReportsDetailsDataColumn).length ? 
                  <div className="assessmentsReportsActions"><button disabled={!isEnabledSubmit}  onClick={() => this.assessmentDataSend()}>{this.props.pagewiseLanguageData.submit}</button></div>
                   : <div className="assessmentsReportsActions"><button disabled={!otherIsEnabledSubmit}  onClick={() => this.assessmentDataSend()}>{this.props.pagewiseLanguageData.submit}</button></div>}       
               
                 {this.state.assessmentsReportsDataJson && this.state.assessmentsReportsDataJson?.length > 0 ?
                 <div className="assessmentsTableWrapper">
                   <h2>{this.props.pagewiseLanguageData.reports}</h2>
                 <div className="assessmentsReportsDownload"><button onClick={() => this.ReportsDownload()}>{this.props.pagewiseLanguageData.download_reports}</button></div>
                  <div className="tablewrapper">     
                      <DataTable
                        columns={this.state.columnData}
                        data={this.state.assessmentsReportsDataJson}
                        customStyles={customStyles}
                        highlightOnHover
                        pagination
                      />
                     
                  </div>
                 </div>
                 : <div className="noReportsFound">{this.props.pagewiseLanguageData.no_reports_found}</div> }


                  
                </div>
              </div>
        {/*Assessments Reports start*/}
        
        
        </div>

        

			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentList, deleteAssessment, publishAssessment, getClassList2,
            getSubjectList2, getCenterList ,getAssessmentsTypesList,getAssessmentsReportsDetails,
            getAssessmentsSessionFilters,getAssessmentsNestedProgramFilters,getAssessmentsNestedClassFilters,
            getAssessmentsNestedSubjectsFilters, getAssessmentsNestedAssessmentsFilters,sendAssessmentsResportsData,getChildSelectData,getPageLanguageWiseData} = actions;
  return {
    getCenterList: () => dispatch(getCenterList()),
    getAssessmentList: (center) => dispatch(getAssessmentList(center)),
    deleteAssessment: (id) => dispatch(deleteAssessment(id)),
    publishAssessment: (id, is_published) => dispatch(publishAssessment(id, is_published)),
    getClassList2: () => dispatch(getClassList2()),
    getSubjectList2: (center_id) => dispatch(getSubjectList2(center_id)),
    getAssessmentsTypesList:() => dispatch(getAssessmentsTypesList()),
    getAssessmentsReportsDetails:(type,center_id) => dispatch(getAssessmentsReportsDetails(type,center_id)),
    getChildSelectData:(id,nestedfiltertype) => dispatch(getChildSelectData(id,nestedfiltertype)),
    sendAssessmentsResportsData :(from_date, to_date, download_type,type,center_id,session_id,program_id,class_id,subject_id,assessment_id,batch_id,column,lecture_id,content_id) => dispatch(sendAssessmentsResportsData(from_date, to_date, download_type,type,center_id,session_id,program_id,class_id,subject_id,assessment_id,batch_id,column,lecture_id,content_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);