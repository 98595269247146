import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import actions from '../../redux/actions';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import edit from '../../public/icons/edit.svg';
import Pagination from "react-js-pagination";
import Back from '../../public/icons/back.svg';
import config from '../../config';

class amplifiuLessonPlanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject_id:this.props?.location?.state?.data.subject_id,
      unit_id : this.props?.location?.state?.data.unit_id,
      class_id : this.props?.location?.state?.data.class_id,
      chapter_id:this.props?.location?.state?.data.chapter_id,
      topic_id:this.props?.location?.state?.data.topic_id,
      user_id : localStorage.getItem('user_id'),
      amplifiuListing:[],
      dialogOpen:false,
      previewContent:'',
      
      
    }
  }
  
  componentDidMount(){
   
    let propsLocation = this.props?.location?.state?.data; 
    console.log("propsLocation");
    console.log(propsLocation);
    console.log("user_id")
    console.log(this.state.user_id)
    console.log("Chepter Id")
    console.log(this.state.chapter_id)
    console.log("Topic Id")
    console.log(this.state.topic_id)

    if(propsLocation){
      this.getAmplifiuListing();
    }
   
   }
 

   getAmplifiuListing = async () =>{
    let {user_id,chapter_id,topic_id} = this.state;
      const response = await this.props.amplifiuFetchThreadDocumentListing(user_id, topic_id ? topic_id :  chapter_id);
      if(response !== undefined) {
        console.log("response listing")
        console.log(response.data);
       this.setState({amplifiuListing : response?.data})
      }
    
   }
 
   
   createNewLessonPlan = async () =>{
    let {user_id,chapter_id,topic_id} = this.state;
      const response = await this.props.amplifiuThreadCreation();
      if(response !== undefined) {
        console.log("response")
        console.log(response?.data.id);
        let threadid = response?.data.id;
        console.log(threadid);

        const url = `${config?.editordomainUrl}/q?t=${threadid}&a=asst_OgRC0X5nANK5lYA8385PuEuK&toc_id=${topic_id ? topic_id :  chapter_id}&user_id=${user_id}`;
        window.open(url, '_blank');
        //window.location.assign(`${config?.editordomainUrl}/q?t=${threadid}&a=asst_OgRC0X5nANK5lYA8385PuEuK&toc_id=${chapter_id}&user_id=${user_id}`)
      }
    
   }


   handleSelectChange = async (e,threadid,id) =>{
    let {user_id,chapter_id,topic_id} = this.state;
    console.log(e.target.value);
    console.log(threadid);
    console.log(id);
      // const response = await this.props.amplifiuSelectActiveInactive(user_id,topic_id ? topic_id :  chapter_id,threadid,e.target.value);
      const response = await this.props.amplifiuSelectActiveInactive(id,e.target.value);
      if(response !== undefined) {
        console.log("response")
        console.log(response?.data);
        this.getAmplifiuListing();
        
      }
   }

   handleEditThreadClick = (e,threadid) =>{
    let {user_id,chapter_id,topic_id} = this.state;
    console.log(e.target.value);
    console.log(threadid);
    const url = `${config?.editordomainUrl}/q?t=${threadid}&a=asst_OgRC0X5nANK5lYA8385PuEuK&toc_id=${topic_id ? topic_id :  chapter_id}&user_id=${user_id}`;
        window.open(url, '_blank');
   }


   
   viewContent = (e,content) =>{
    console.log(content);
    this.setState({previewContent : content});
    this.setState({dialogOpen : true});

   }

   dialogClose = () =>{
    this.setState({dialogOpen : false});

   }


   formatTimestamp(timestamp) {
    const dateTime = new Date(timestamp);

    // Extracting date components
    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Month is zero-indexed, so we add 1
    const year = dateTime.getFullYear();

    // Formatting the date string
    const formattedDate = `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;

    // Extracting time components
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    // Formatting the time string
    const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

    const completeDateTime = formattedDate + " " +formattedTime;

    return completeDateTime;
  }

     

	render() {
    
    if(this.props?.loading) {
      return (
        <div className="admin-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
		return (

			<div className="admin-div amplifiu-lesson-planner-page">

          <div className={this.state.dialogOpen ? "previewContentDialogWrapper open" :"previewContentDialogWrapper"}>
              <div className='previewContentDialogContainer'>
                  <div className='dialogClose' onClick={() => this.dialogClose()}>X</div>
                  <div className='contentWrapper' dangerouslySetInnerHTML={{ __html: this.state.previewContent }}>
                     
                  </div>
              </div>

          </div>



        <Helmet>
          <title>Lesson Planner</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search study material"/>
        <div className='main-part'>
          <div className='sub-head'>
              <div className='heading'><div id="trace-back" onClick={() => this.props.history.goBack()}>
                <img src={Back} alt="back"/></div><h1>{this.props?.location?.state?.data?.breads?.classs} |  {this.props?.location?.state?.data?.breads?.subject} | {this.props?.location?.state?.data?.breads?.unit} | {this.props?.location?.state?.data?.breads?.topic} - Lesson Planner</h1></div>
              <div className='filterBlock'>
                 {this.state.amplifiuListing.length == 0 && <span onClick={(e) => this.createNewLessonPlan(e)}>Create New Lesson Plan</span>}
              </div>
            </div>
            <div className='amplifiuLessonPlanner'>
                <div className='amplifiuLessonPlannerContainer'>
                
                <ol>
                {this.state.amplifiuListing && this.state.amplifiuListing.length > 0 && this.state.amplifiuListing.map(data =>
                    <li>
                        <div className='title' onClick={(e) => this.viewContent(e,data.document)}>
                          <h3><span >{data.document_name}</span>{data.status == 'Active' && <div className='tag'><span>{data.status}</span></div> }</h3>
                          <p>Version - {data.version_num}</p>
                          <p>Date - {this.formatTimestamp(data.updated_on)}</p>
                        </div>
                        <div className='actions'>
                            <div className='editLink'><span className='' onClick={(e) => this.handleEditThreadClick(e,data.thread_id)}><img src={edit}/></span></div>
                            <div className='selectbox'>
                                <select defaultValue={data.status} onChange={(e) => this.handleSelectChange(e,data.thread_id,data.id)}>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </li>
                 )}
                 {this.state.amplifiuListing.length == 0 &&
                  
                  <li className='noResultMessage'>No Result Found</li>
                }
                </ol>

                
               
                </div>
            </div>



        </div>
        
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    learnerprofile : state.commonReducer.logindetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  const { amplifiuThreadCreation,amplifiuFetchThreadListing,amplifiuFetchThreadDocumentListing,amplifiuSelectActiveInactive} = actions;
  return { 
    amplifiuThreadCreation: () => dispatch(amplifiuThreadCreation()),
    amplifiuFetchThreadListing: (user_id,toc_id) => dispatch(amplifiuFetchThreadListing(user_id,toc_id)),
    amplifiuFetchThreadDocumentListing: (user_id,toc_id) => dispatch(amplifiuFetchThreadDocumentListing(user_id,toc_id)),
    amplifiuSelectActiveInactive: (id,status) => dispatch(amplifiuSelectActiveInactive(id,status)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(amplifiuLessonPlanner);