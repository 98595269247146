import { 
 WIDGET_LIST, 
 WIDGET_DATA
} from '../constants';

const initialState = {
  widgets:[],
  widgetsOnId:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_LIST:
      return {
        ...state,
        widgets: action.widgets
      }
    case WIDGET_DATA:
      return {
        ...state,
        widgetsOnId: action.widgetsOnId
      }
    default:
        return state;
  }
}