import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/rootReducer';

// in this file we are initializing the redux store by passing initial state and instance of reducer,
// we are applying thunk middleware to support async data flow.

const loggerMiddleware = createLogger();

const environment = process.env.NODE_ENV;

let middleware = [thunkMiddleware];

if (environment === 'development') {
  middleware = [...middleware, loggerMiddleware];
}

export default preloadedState => {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middleware)
  );
};
