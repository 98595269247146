import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import common from '../../common';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Profile from '../../public/icons/user-profile.svg';
import Invite from '../../public/icons/invite.svg';
import Invitew from '../../public/icons/invite-w.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class BranchAdmins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      id: null,
      image: null,
      imageURL: null,
      name: '',
      email: '',
      mobile: '',
      code: '',
      editcode: '',
      password: '',
      center_id: localStorage.getItem('center_id'),
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      branches: [],
      branch:localStorage.getItem('center_id'),
      admins: [],
      mail_type: 3,
      user_ids: [],
      profile: {},
      invite: false,
      currentAdmin:[]
    }
  }

  componentDidMount() {
    this.getCenterList();
    this.getProfile();
    this.getAdminList();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }


  show = () => this.setState({ open: true, center_id: localStorage.getItem('center_id'),
                                 name: '', email: '',  mobile: '' });

  close = () => this.setState({ open: false });

  show2 = (val) => {
    console.log(val)
    this.setState({ open2: true, id: val.id, name: val.name, email: val.email, code: val.employee_code,
                      mobile: val.mobile, image: val.profile_picture, imageURL: null, editcode: val.employee_code,branch:val.assigned_branch.id });
  }

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  bulkInvite = () => this.setState({ invite: true,
          user_ids: this.state.admins.map(function (obj) {return obj.id}) });

  closeInvite = () => this.setState({ invite: false, user_ids: [] });

  getProfile = async () => {
    const response = await this.props.getProfile(this.state.center_id);
    if(response !== undefined) {
      this.setState({ profile: response.data });
    }
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }


  getAdminList = async () => {
    const response = await this.props.getAdminList();
    if(response !== undefined) {
      this.setState({ admins: response.data, currentAdmin : response.data.filter(p => p.email == localStorage.getItem("username"))});
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  emailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  mobileChange = (e) => {
    const mobile = (e.target.validity.valid) ? e.target.value : this.state.mobile;
    this.setState({ mobile })
  }

  passwordChange = (e) => {
    this.setState({ password: e.target.value })
  }

  handleChange = (event) => {
    if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: this.props.pagewiseLanguageData.only_image_can_be_uploaded,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('img-input').value = "";
  }

  newAdmin = async () => {
    const { name, email, mobile, password, branch, code } = this.state;
    if(common.validateEmail(email)) {
      await this.props.createAdmin(name, email, mobile, password, branch, code,localStorage.getItem('lang'));
      this.setState({ open: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: this.props.pagewiseLanguageData.please_enter_valid_email,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  updateAdmin = async () => {
    const { id, name, email, mobile, password, branch, image, code } = this.state;
    if(common.validateEmail(email)) {
      await this.props.updateAdmin(id, name, email, mobile, password, branch, image, code);
      this.setState({ open2: false });
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: this.props.pagewiseLanguageData.please_enter_valid_email,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  deleteAdmin = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteAdmin(d_id, center_id);
    this.setState({ opend: false });
  }

  sendInvite = async (val) => {
    const { mail_type } = this.state;
    await this.props.sendInvite(mail_type, [val]);
  }

  sendBulkInvite = async () => {
    const { mail_type, user_ids } = this.state;
    await this.props.sendInvite(mail_type, user_ids);
    this.setState({ user_ids: [], invite: false })
  }

  changeIds = (val) => {
    if(this.state.user_ids.indexOf(val) !== -1) {
      const index = this.state.user_ids.indexOf(val);
      this.state.user_ids.splice(index, 1);
    }
    else {
      this.state.user_ids.push(val);
    }
  }

  branchChange = (e) =>{
    this.setState({branch:e.target.value})
  }

  render() {
    const isEnabled = this.state.name.length > 0 && this.state.email.length > 0 &&
              (this.state.mobile + '').length === 10 && this.state.password.length > 0
              && this.state.code.length > 0;
    const isEnabled2 = this.state.name.length > 0 && this.state.email.length > 0 &&
              (this.state.mobile + '').length === 10;
    const isEnabled3 = this.state.user_ids.length > 0;
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search admins"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.branch_admins}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.branch_admins}</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder="Search admins"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_admin}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.subadmin_name} type="text" onChange={this.nameChange}/>
              <input placeholder={this.props.pagewiseLanguageData.employee_code} type="text" onChange={this.codeChange}/>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.email_id} type="email" onChange={this.emailChange}/>
              <input placeholder={this.props.pagewiseLanguageData.mobile_number} type="text" onInput={this.mobileChange}
                maxLength={10} pattern="[0-9]*" value={this.state.mobile}/>
            </div>
            <div className="popup-inputs">
              <select value={this.state.branch} onChange={this.branchChange}>
                <option value="">--{this.props.pagewiseLanguageData.select_center}--</option>
                {this.state.branches.map(branch =>
                  <option value={branch.id}>{branch.name}</option>
                )}
              </select>
              <input placeholder={this.props.pagewiseLanguageData.set_password} type="password" onChange={this.passwordChange}/>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newAdmin}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteAdmin}>
              {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                {this.state.open2 ? <h1>{this.props.pagewiseLanguageData.edit_subadmin}</h1> : <h1>{this.props.pagewiseLanguageData.branch_admins}</h1>}
              </div>
            </div>
            <div className="heading-part2">
              {this.state.open2 === false ? <>
                {this.state.invite ? <>
                  <button id="button-reverse" onClick={this.closeInvite}>
                  {this.props.pagewiseLanguageData.cancel}
                  </button>
                  <button disabled={!isEnabled3} onClick={this.sendBulkInvite}>
                    {this.props.pagewiseLanguageData.send}
                  </button>
                </> : <>
                  <span onClick={this.bulkInvite}>
                    <img src={Invitew} alt="img"/>{this.props.pagewiseLanguageData.send_invite}
                  </span>
                  <span onClick={this.show}>
                    <img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
                  </span>
                  {!this.state.list ? <span onClick={() => this.changeView('false')}>
                    <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
                  </span> : <span onClick={() => this.changeView('true')}>
                    <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
                  </span>}
                </>}
              </> : null}
            </div>
          </div>
          {this.state.admins.length === 0 ? <Empty/> : <>
            {this.state.open2 ? <div className="edit-div">
              <div className="edit-div-1">
                {this.state.image === null || this.state.image === ''
                ? <div className="edit-dp" onClick={() => this.fileInput.click()}>
                  <img id="img-holder" src={ImgHolder} alt="DP"/>
                </div> : <div className="edit-dp" style={this.state.imageURL === null
                  ? {backgroundImage: "url("+common.addFileUrl(this.state.image)+")"}
                  : {backgroundImage: "url("+this.state.imageURL+")"}}>
                  <span onClick={this.removeImage}>&#x2715;</span>
                </div>}
                <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                <div className="edit-sub-div-1">
                  <input placeholder={this.props.pagewiseLanguageData.subadmin_name} value={this.state.name} type="text"
                    onChange={this.nameChange}/>
                  <input placeholder={this.props.pagewiseLanguageData.email_id} value={this.state.email} type="email"
                    onChange={this.emailChange}/>
                </div>
              </div>
              <div className="edit-div-2">
                <input placeholder={this.props.pagewiseLanguageData.mobile_number} value={this.state.mobile} type="text"
                  onInput={this.mobileChange} maxLength={10} pattern="[0-9]*"/>
                <input placeholder={this.props.pagewiseLanguageData.password} type="password" onChange={this.passwordChange}/>
              </div>

              <div className="edit-div-2">
                  <select value={this.state.branch} onChange={this.branchChange}>
                    <option value="">--{this.props.pagewiseLanguageData.select_center}--</option>
                    {this.state.branches.map(branch =>
                      <option value={branch.id}>{branch.name}</option>
                    )}
                  </select>
              </div>

              {/* {(this.state?.editcode?.length === 0 || this.state.editcode === null
                || this.state.editcode === undefined) ? <div className="edit-div-3">
                <input placeholder="Employee Code" type="text" value={this.state.code}
                  onChange={this.codeChange}/>
              </div> : null} */}
              <div className="edit-buttons">
                <button id="button-reverse" onClick={this.close2}>
                {this.props.pagewiseLanguageData.cancel}
                </button>
                <button disabled={!isEnabled2} onClick={this.updateAdmin}>
                {this.props.pagewiseLanguageData.save}
                </button>
              </div>
            </div> : <>
              {this.state.list ? <div className="list-head list-assessment">
                <div className="list-title">
                  {this.props.pagewiseLanguageData.name}
                </div>
                <div className="list-email">
                {this.props.pagewiseLanguageData.email}
                </div>
                <div className="list-section">
                {this.props.pagewiseLanguageData.code}
                </div>
                <div className="list-faculty">
                {this.props.pagewiseLanguageData.center}
                </div>
                <div className="list-action">
                {this.props.pagewiseLanguageData.actions}
                </div>
              </div> : null}
              {this.state.list ? <div className="the-list the-assessment-list">
                {this.state.admins.map((admin, index) =>
                  <div className="list-item" key={index}>
                    <div className="title-item3">
                      {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(admin.id)}
                        defaultChecked={this.state.user_ids.indexOf(admin.id) !== -1 ? true : false}/>}
                      {admin.profile_picture === null || admin.profile_picture === ''
                        ? <img src={Profile} alt="dp"/> : <div className="dp_div"
                        style={{backgroundImage: "url("+common.addFileUrl(admin.profile_picture)+")"}}>
                      </div>}
                      <div>
                        {admin.name}
                      </div>
                    </div>
                    <div className="email-item">
                      {admin.email}
                    </div>
                    <div className="section-item">
                      {admin?.employee_code}
                    </div>
                    <div className="faculty-item">
                      {admin?.assigned_branch?.name}
                    </div>
                    <div className="action-item">
                      <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(admin)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>
                      {this.state.profile.admin !== admin.id
                        && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(admin.id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                      </span>}
                      <div title="Invite" id="send-invite"
                        onClick={() => this.sendInvite(admin.id)}>
                        <img src={Invite} alt="invite"/>{this.props.pagewiseLanguageData.send_invite}
                      </div>
                    </div>
                  </div>
                )}
              </div> : <div className="the-grid">
                {this.state.admins.map((admin, index) =>
                  <div className="grid-item" key={index}>
                    <div className="grid-up grid-up-v1">
                      <div className="title-grid4">
                        {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(admin.id)}
                          defaultChecked={this.state.user_ids.indexOf(admin.id) !== -1 ? true : false}/>}
                        {admin.profile_picture === null || admin.profile_picture === ''
                          ? <img src={Profile} alt="dp"/> : <div className="dp_div"
                            style={{backgroundImage: "url("+common.addFileUrl(admin.profile_picture)+")"}}>
                        </div>}
                        <div>
                          {admin.name}
                        </div>
                      </div>
                      <div className="grid-actions">
                        <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(admin)}>
                          <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                        </span>
                        {this.state.profile.admin !==  admin.id
                          && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(admin.id)}>
                          <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                        </span>}
                      </div>
                    </div>
                    <div className="grid-up grid-up-v1">
                      <span id="grid-up-span">{admin.email}</span>
                      <div title="Invite" id="send-invite"
                        onClick={() => this.sendInvite(admin.id)}>
                        <img src={Invite} alt="invite"/>{this.props.pagewiseLanguageData.send_invite}
                      </div>
                    </div>
                  </div>
                )}
              </div>}
            </>}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAdminList, createAdmin,getCenterList, updateAdmin, deleteAdmin, sendInvite,
            getProfile,getPageLanguageWiseData } = actions;
  return {
    getCenterList: () => dispatch(getCenterList()),
    sendInvite: (mail_type, user_ids) => dispatch(sendInvite(mail_type, user_ids)),
    getAdminList: () => dispatch(getAdminList()),
    getProfile: (center_id) => dispatch(getProfile(center_id)),
    createAdmin: (name, email, mobile, password, center, employee_code,language) => dispatch(createAdmin(name, email, mobile, password, center, employee_code,language)),
    updateAdmin: (id, name, email, mobile, password, center, profile_picture, employee_code) => dispatch(updateAdmin(id, name, email, mobile, password, center, profile_picture, employee_code)),
    deleteAdmin: (id, center_id) => dispatch(deleteAdmin(id, center_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchAdmins);
