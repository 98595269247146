import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import common from '../../common';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Back from '../../public/icons/back.svg';

class StudyMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center_id: localStorage.getItem('center_id'),
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      filterClass: '',
      classes: [],
      filterClasses: [],
      filterBranch: localStorage.getItem('center_id'),
      branches: [],
      subjects: [],
      subjectsFilter: [],
      is_faculty:localStorage.getItem("is_faculty"),
      is_faculty_admin:false
    }
  }

  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getCenterList();
    this.getClassList();
    //this.getSubjectList(this.state.center_id);
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }


  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  getClassList = async () => {
		const response = await this.props.getClassList();
    this.setState({ classes: response.data,
      filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
  }

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }

  getSubjectList = async (center,filterClass) => {
    const response = await this.props.getSubjectList(center,filterClass);
    if(response !== undefined) {
      if(this.state.filterClass !== '') {
        var arr = response.data.filter(subject => subject.classs === parseInt(this.state.filterClass));
        this.setState({ subjects: response.data, subjectsFilter: arr });
      }
      else {
        this.setState({ subjects: response.data, subjectsFilter: response.data });
      }
    }
  }

  classChange = (e) => {
    console.log("e.target.value")
    console.log(e.target.value);
    if(e.target.value === '') {
      this.setState({ filterClass: null });
      this.setState({ subjectsFilter: this.state.subjects });
    }
    else {
      this.setState({ filterClass: e.target.value });
      var arr = this.state.subjects.filter(subject => subject.class_details.id === parseInt(e.target.value));
      this.setState({ subjectsFilter: arr });
      this.getSubjectList(this.state.center_id,e.target.value);
    }
  }

  centerChange = (e) => {
    if(e.target.value === '') {
      this.setState({ filterBranch: null, filterClasses: this.state.classes });
      this.getSubjectList(this.state.center_id);
    }
    else {
      this.setState({ filterBranch: e.target.value, filterClass: '',
        filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value)) });
      this.getSubjectList(e.target.value);
    }
  }

  changeTab = () =>{
    localStorage.setItem("is_faculty_admin","false")
  }

	render() {
    const { is_faculty } =this.state;

    if(this.props.loading) {
      return (
        <div className="admin-div">
         {!is_faculty && <Header placeholder={this.props.pagewiseLanguageData.search_subjects}/> }
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.study_material}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
		return (

			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.study_material}</title>
        </Helmet>
        <Header placeholder="Search subjects"/>
        <ReactNotifications/>
        <div className="main-part">
          <div className="sub-head">
						<div className="heading-part1">
							<div className="heading">

								<h1>{this.props.pagewiseLanguageData.study_material}</h1>
							</div>
						</div>
            <div className="heading-part2">
							{!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
						</div>
					</div>
          <div className="sub-filter">
            {!this.state.is_faculty_admin && <select value={parseInt(this.state.filterBranch)} onChange={this.centerChange}>
              {this.state.branches.map(branch =>
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              )}
            </select>}
            <select value={parseInt(this.state.filterClass)} onChange={this.classChange}>
              <option value={''}>{this.props.pagewiseLanguageData.filter_by_class}</option>
              {this.state.filterClasses.map(classs =>
                <option key={classs.id} value={classs.id}>{classs.label}</option>
              )}
            </select>
          </div>
          {this.state.subjectsFilter.length === 0 ? <><span id="no-data-admin">
               {this.props.pagewiseLanguageData.please_choose_the_class_to_view_subjects}
              </span><Empty/> </>: <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.class}
              </div>
              <div className="list-email">
              {this.props.pagewiseLanguageData.subject}
              </div>
              <div className="list-subject">

              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.center}
              </div>
              <div className="list-action">
              {this.props.pagewiseLanguageData.action}
              </div>
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.subjectsFilter.map((subject, index) =>
                <div className="list-item" key={subject.id}>
                  <div className="title-item">
                    {subject.class_details.label !== ''
                      ? `${subject.class_details.label}` : ``}
                  </div>
                  <div className="email-item">
                    <div className="title-image"
                      style={{backgroundImage: "url("+common.addFileUrl(subject.image_url)+")"}}>
                    </div>
                    {subject.label}
                  </div>
                  <div className="subject-item">

                  </div>
                  <div className="faculty-item">
                    {subject.branch.name}
                  </div>
                  <div className="action-item">
                    <Link id="section-button" to={{
                      pathname: `/admin/study-material/units`,
                      search: `?class=${subject.class_details.id}&subject=${subject.id}`,
                      state:{ classs: subject.class_details.label, subject: subject.label }}}>
                      {this.props.pagewiseLanguageData.lessons}
                    </Link>
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.subjectsFilter.map((subject, index) =>
                <div className="grid-item" key={subject.id}>
                  <div className="grid-up grid-up-v1">
                    <div className="title-grid" id="study-material-title">
                      {subject.class_details.label !== ''
                        ? `${subject.class_details.label}  |  ${subject.label}`
                        : subject.label}
                    </div>
                  </div>
                  <div className="grid-up grid-up-v1">
                    <div className="grid-image"
                      style={{backgroundImage: "url("+common.addFileUrl(subject.image_url)+")"}}>
                    </div>
                    <Link id="section-button" to={{
                      pathname: `/admin/study-material/units`,
                      search: `?class=${subject.class_details.id}&subject=${subject.id}`,
                      state:{ classs: subject.class_details.label, subject: subject.label }}}>
                      {this.props.pagewiseLanguageData.lessons}
                    </Link>
                  </div>
                </div>
              )}
            </div>}
          </>}
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
	const { getClassList, getCenterList, getSubjectList,getPageLanguageWiseData } = actions;
  return {
    getClassList: () => dispatch(getClassList()),
    getCenterList: () => dispatch(getCenterList()),
    getSubjectList: (subject_id,filterClass) => dispatch(getSubjectList(subject_id,filterClass)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyMaterial);
