import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Listing from '../../public/icons/listing.svg';
import More from '../../public/icons/more.png';
import LOOP from '../../public/icons/loop.png';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';


const levels =[0,1,2,3,4,5,6,7,8,9,10,11,12];

class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      open3: false,
      opend: false,
      class_id: null,
      filterClass: null,
      label: '',
      short_code: '',
      no_of_sem: '',
      level:'',
      description: '',
      branch: parseInt(localStorage.getItem('center_id')),
      branches: [],
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      classesFilter: [],
      programBranch:parseInt(localStorage.getItem('center_id')),
      is_faculty_admin:false,
      current_program_id:'',
      current_program_semester_count:0,
      selected_program_center_id:'',
      programs:{label:"",short_code:"",center_id:'',no_of_sem:0,sem_details:[]},
      sem:{label:"",short_code:"",description:""},
    }
  }
  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getCenterList();
    this.programList(this.state.branch);
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }
    

  changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
  }

  show = () => {
    let {programs} = this.state
    programs.sem_details =[];
    this.setState({ open: true,label: '', short_code: '', description: '',programs,current_program_semester_count:0});
  }
  close = () => this.setState({ open: false });

  show2 = (val) => {
    let {programs} = this.state

    //if(this.state.programs.sem_details?.length < 20) {
      let sem = val.classes.length && val.classes.map( cls =>(
        {
          id:cls.id,
          label:cls.label,
          short_code:cls.short_code,
          order:this.state.programs.sem_details.length + 1,
          description:cls.description,
          level:cls.level,
          order:cls.order
        }
        //programs.sem_details.push(sem);
      ))
      programs.sem_details = sem

      console.log(programs)
      this.setState({ programs })
    //}

    this.setState({ open2: true, label: val.label, short_code: val.short_code,
                      description: val.description,d_id:val.id,programBranch:val.center_id});
  }

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val.id,selected_program_center_id:val.center_id});

  closed = () => this.setState({ opend: false });

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }

  programList = async (center_id) => {
    const response = await this.props.programList(center_id);
    if(response !== undefined) {
      this.setState({ classes: response.data, classesFilter: response.data });
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  nameChange = (e) => {
    this.setState({ label: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ short_code: e.target.value })
  }

  descriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  orderChange = (e) => {
    const order = (e.target.validity.valid) ? e.target.value : this.state.order;
    this.setState({ order })
  }

  levelChange = (e) =>{
    const level = (e.target.validity.valid) ? e.target.value : this.state.level;

    this.setState({ level })
  }

  onBranchChange = (e) => {
    if(e.target.value === 'Select center') {
      this.setState({ branch: null })
    }
    else {
      this.setState({ branch: e.target.value });
    }
  }

  centerChange = (e) => {
    if(e.target.value === 'Filter by Center') {
      this.setState({ filterClass: null });
      this.setState({ clacenterChangessesFilter: this.state.classes });
    }
    else {
      this.setState({ filterClass: e.target.value });
      this.programList(e.target.value)
    }
  }

  newClass = async () => {
    const { label,short_code,programs,programBranch} = this.state;

    let no_of_sem=programs.sem_details.length;

    await this.props.programCreate(label,short_code,programBranch,no_of_sem,programs.sem_details);
    //this.setState({ open: false });
  }

  editProgram = async () => {
    const {d_id,label,short_code,programs,programBranch} = this.state;

    let no_of_sem=programs.sem_details.length;

    await this.props.programEdit(d_id,label,short_code,programBranch,no_of_sem,programs.sem_details);
    //this.setState({ open2: false });
  }

  deleteClass = async () => {
    const { d_id, selected_program_center_id } = this.state;
    await this.props.deleteClass(d_id, selected_program_center_id);
    this.setState({ opend: false });
  }

  addOption = () => {
    let {current_program_semester_count} = this.state
    console.log(current_program_semester_count)
    if(this.state.programs.sem_details?.length < 24) {
      var programs = this.state.programs;
      if(this.state.programs.sem_details?.length < 20) {
        var sem = {
          label:'',
          short_code:'',
          order:current_program_semester_count ? parseInt(current_program_semester_count) + 1 : this.state.programs.sem_details.length + 1,
          description:'',
          center:this.state.programBranch
        }

        current_program_semester_count +=1
        programs.sem_details.push(sem);

        console.log(programs)
        this.setState({ programs,current_program_semester_count})
      }
    }
  }

  handleChange = (e,index) =>{
    var programs = this.state.programs;
    programs.sem_details[index][e.target.name]= e.target.value
    this.setState({programs})
  }

  handleChangeOnEdit = (e,semvalue) =>{
    var sem = this.state.sem;
    sem[e.target.name]= e.target.value
    this.setState({sem})
  }

  removeOption = (index) => {
    var programs = this.state.programs;
    if(this.state.programs.sem_details.length > 0) {
      programs.sem_details.splice(index, 1);
      this.setState({ programs });
    }
  }

  updateSem = async (semvalue) => {
    let {sem,programBranch} = this.state

    let label = sem.label ? sem.label : semvalue.label;
    let short_code = sem.short_code ? sem.short_code : semvalue.short_code;
    let description = sem.description ? sem.description : semvalue.description;

    await this.props.updateClass(programBranch,semvalue.id,label,description,short_code,semvalue.order,semvalue.level);
    //this.setState({ open2: false });
  }

  addMoreSemesters = async (semvalue,order) =>{
    let {sem,branch,current_program_id,selected_program_center_id} = this.state

    let label = sem.label
    let short_code = sem.short_code
    let description = sem.description

    const response = await this.props.addMoreSemesters(current_program_id,label,order,short_code,description,selected_program_center_id)

  }

  addMore = (programss) =>{
    let {programs,current_program_semester_count,current_program_id,selected_program_center_id} = this.state

    current_program_semester_count = programss.classes.length
    current_program_id = programss.id
    selected_program_center_id=programss.center_id;
    programs.sem_details =[];
    this.setState({is_add_more:true,selected_program_center_id,current_program_semester_count,current_program_id,programs})
  }

  addMoreClose = () => {
    this.setState({is_add_more:false})
  }

  programBranchChange = (e) =>{
    this.setState({programBranch:e.target.value})
  }

  render() {

    const {selected_program_center_id} = this.state

    console.log(selected_program_center_id)

    const isEnabled = this.state.label.length > 0 && this.state.short_code.length > 0 && this.state.programs.sem_details?.length > 0;
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search classes"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.classes}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.classes}</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder="Search classes"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-add-program">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_program}
          </div>
          <hr/>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data-program">
            <div className="popup-inputs-programs">
              <input placeholder={this.props.pagewiseLanguageData.program_name} onChange={this.nameChange}/>
              <input placeholder={this.props.pagewiseLanguageData.program_short_code} onChange={this.codeChange}/>
              <select value={this.state.programBranch} onChange={this.programBranchChange}>
                {this.state.branches.map(branch =>
                  <option key={branch.id} value={branch.id}>{branch.name}</option>
                )}
              </select>

            </div>
            <div className="prog-sem">
              {!this.state.programs?.sem_details && <Empty/>}
              {this.state.programs?.sem_details ? this.state.programs?.sem_details.map((sem, index) =>
                <div key={index}>
                  <span>
                    {sem.order}
                  </span>
                  <input placeholder={this.props.pagewiseLanguageData.semester_label} type="text" onChange={(e) => this.handleChange(e,index)} name="label" />
                  <input placeholder={this.props.pagewiseLanguageData.short_code} type="text" onChange={(e) => this.handleChange(e,index)} name="short_code" />
                  <input placeholder={this.props.pagewiseLanguageData.description} type="textarea" onChange={(e) => this.handleChange(e,index)} name="description" />
                  <span onClick={() => this.removeOption(index)} id="remove-opt">
                              &#10005;
                  </span>
                </div>
              ):""}
            </div>
            <div>
              <button onClick={this.addOption}>{this.props.pagewiseLanguageData.add_semester}</button>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newClass}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>

        <Popup open={this.state.is_add_more} closeOnDocumentClick onClose={this.addMoreClose}
          className="popup-add-program">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_more_semesters}
          </div>
          <hr/>
          <span className="close" onClick={this.addMoreClose}>&#x2715;</span>
          <div className="popup-data-program">
            <div>
              <button onClick={this.addOption}>{this.props.pagewiseLanguageData.add_semester}</button>
            </div>
            <div className="prog-sem">
              {!this.state.programs?.sem_details && <Empty/>}
              {this.state.programs?.sem_details ? this.state.programs?.sem_details.map((sem, index) =>
                <div key={index}>
                  <span>
                    {sem.order}
                  </span>
                  <input placeholder={this.props.pagewiseLanguageData.semester_label} type="text" onChange={(e) => this.handleChangeOnEdit(e,sem)} name="label" />
                  <input placeholder={this.props.pagewiseLanguageData.short_code} type="text" onChange={(e) => this.handleChangeOnEdit(e,sem)} name="short_code" />
                  <input placeholder={this.props.pagewiseLanguageData.description} type="textarea" onChange={(e) => this.handleChangeOnEdit(e,sem)} name="description" />
                  <span onClick={() => this.addMoreSemesters(sem,sem.order)} id="remove-opt">
                    {this.props.pagewiseLanguageData.save}
                  </span>
                </div>
              ):""}
            </div>
          </div>
        </Popup>

        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-add-program">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_program}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data-program">
            <div className="popup-inputs-programs">
              <input value={this.state.label} placeholder={this.props.pagewiseLanguageData.program_name} onChange={this.nameChange}/>
              <input value={this.state.short_code} placeholder={this.props.pagewiseLanguageData.program_short_code} onChange={this.codeChange}/>
              <select value={this.state.programBranch} onChange={this.programBranchChange} disabled={true}>
                {this.state.branches.map(branch =>
                  <option key={branch.id} value={branch.id}>{branch.name+""+selected_program_center_id}</option>
                )}
              </select>
              <button disabled={false} onClick={this.editProgram}>Save</button>
            </div>
            <div className="prog-sem">
              {!this.state.programs?.sem_details && <Empty/>}
              {this.state.programs?.sem_details ? this.state.programs?.sem_details.map((sem, index) =>
                <div key={index}>
                  <span>
                    {sem.order}
                  </span>
                  <input defaultValue={sem.label} placeholder={this.props.pagewiseLanguageData.label} type="text" onChange={(e) => this.handleChangeOnEdit(e,sem)} name="label" />
                  <input defaultValue ={sem.short_code} placeholder={this.props.pagewiseLanguageData.short_code} type="text" onChange={(e) => this.handleChangeOnEdit(e,sem)} name="short_code" />
                  <input defaultValue={sem.description} placeholder={this.props.pagewiseLanguageData.description} type="textarea" onChange={(e) => this.handleChangeOnEdit(e,sem)} name="description" />
                  <span onClick={() => this.updateSem(sem)} id="remove-opt">
                    {this.props.pagewiseLanguageData.save}
                  </span>
                  {/*<span onClick={() => this.removeOption(index)} id="remove-opt">
                              &#10005;
                  </span>*/}
                </div>
              ) : ""}
            </div>
            {/*<div>
              <button onClick={this.addOption}>Add Sem</button>
            </div>*/}
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteClass}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <h1>{this.props.pagewiseLanguageData.programs}</h1>
              </div>
            </div>
            <div className="heading-part2">
              {!this.state.is_faculty_admin && <span onClick={this.show}>
                <img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
              </span>}
              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
            </div>
          </div>
          <div className="sub-filter">
            {!this.state.is_faculty_admin && <select value={this.state.filterClass} onChange={this.centerChange}>
              <option value={null}>{this.props.pagewiseLanguageData?.filter_by_center}</option>
              {this.state.branches.map(branch =>
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              )}
            </select>}
          </div>
          {this.state.classesFilter.length === 0 ? <><span id="no-data-admin">
               {this.props.pagewiseLanguageData.no_class_subject_is_mapped_yet}
              </span> <Empty/></> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.name}
              </div>
              <div className="list-section">
                {this.props.pagewiseLanguageData.code}
              </div>
              <div className="list-class">
                {this.props.pagewiseLanguageData.description}
              </div>
              <div className="list-faculty">
                {this.props.pagewiseLanguageData.center}
              </div>
              <div className="list-faculty">
                {this.props.pagewiseLanguageData.total_semesters}
              </div>
              {!this.state.is_faculty_admin &&<div className="list-action2">
                {this.props.pagewiseLanguageData.actions}
              </div>}
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.classesFilter.map((classs, index) =>
                <div className="list-item" key={classs.id}>
                  <div className="title-item">
                    {classs.label}
                  </div>
                  <div className="section-item">
                    {classs.short_code}
                  </div>
                  <div className="class-item">
                    {classs.description}
                  </div>
                  <div className="faculty-item">
                    {classs.classes[0]?.center_details?.name}
                  </div>
                  <div className="faculty-item">
                    {classs.classes?.length}
                  </div>
                  <div className="action-item2">
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(classs)}>
                      <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                    </span>}
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.add_more_semesters} onClick={() => this.addMore(classs)}>
                        <img src={More} alt={this.props.pagewiseLanguageData.add_more_semesters}/>
                      </span>}
                    {/*!this.state.is_faculty_admin && <span title="Delete" onClick={() => this.showd(classs)}>
                      <img src={Delete} alt="delete"/>
                    </span>*/}
                    {/*<Link id="section-button2" to={{ pathname: `/admin/subjects/${classs.id}`,
                      state: {class_label: classs.label, center_id: classs.center} }} onClick={this.changeTab}>
                      <img src={Listing} alt="list"/>
                      Subjects
                    </Link>
                    <Link id="section-button2" to={{ pathname: `/admin/class-sections/${classs.id}`,
                      state: {class_label: classs.label, center_id: classs.center} }}
                      onClick={this.changeTab}>
                      <img src={Listing} alt="list"/>
                      Sections
                    </Link>*/}
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.classesFilter.map((classs, index) =>
                <div className="grid-item" key={classs.id}>
                  <div className="grid-up grid-up-v1">
                    <div className="title-grid">
                      {classs.label}
                    </div>
                    <div className="grid-actions">
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(classs)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>}
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.add_more_semesters} onClick={() => this.addMore(classs)}>
                        <img src={More} alt={this.props.pagewiseLanguageData.add_more_semesters}/>
                      </span>}
                      {/*!this.state.is_faculty_admin && <span title="Delete" onClick={() => this.showd(classs)}>
                        <img src={Delete} alt="delete"/>
                      </span>*/}
                    </div>
                  </div>
                  <div className="grid-up grid-up-v1">
                    <div className="grid-sec">
                    {this.props.pagewiseLanguageData.code}
                      <span>{classs.short_code}</span>
                    </div>
                    <div className="grid-sec">
                    {this.props.pagewiseLanguageData.semesters}
                      <span>{classs.classes?.length}</span>
                    </div>
                    {/*<div className="grid-sec2">
                      <Link id="section-button2" to={{ pathname: `/admin/subjects/${classs.id}`,
                        state: {class_label: classs.label, center_id: classs.center} }} onClick={this.changeTab}>
                        <img src={Listing} alt="list"/>
                        Subjects
                      </Link>
                      <Link id="section-button2" to={{ pathname: `/admin/class-sections/${classs.id}`,
                        state: {class_label: classs.label, center_id: classs.center} }}
                        onClick={this.changeTab}>
                        <img src={Listing} alt="list"/>
                        Sections
                      </Link>
                    </div>*/}
                  </div>
                </div>
              )}
            </div>}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage,programEdit,addMoreSemesters,programCreate,programList,createSubject, createClass, updateClass, getClassList,
          deleteClass, getCenterList,getPageLanguageWiseData} = actions;
  return {
    addMoreSemesters: (program,label,order,short_code,description,center) => dispatch(addMoreSemesters(program,label,order,short_code,description,center)),
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    createClass: (label, description, short_code, center, order,level) => dispatch(createClass(label, description, short_code, center, order,level)),
    createSubject: (label, short_code, classs, master_subject, background_code, image, center_id) => dispatch(createSubject(label, short_code, classs, master_subject, background_code, image, center_id)),
    updateClass: (center, class_id, name, description, code, order,level) => dispatch(updateClass(center, class_id, name, description, code, order,level)),
    getClassList: () => dispatch(getClassList()),
    getCenterList: () => dispatch(getCenterList()),
    deleteClass: (id, center_id) => dispatch(deleteClass(id, center_id)),
    programList: (center_id) => dispatch(programList(center_id)),
    programEdit:(id,label,short_code,center_id,no_of_sem,sem_details) => dispatch(programEdit(id,label,short_code,center_id,no_of_sem,sem_details)),
    programCreate:(label,short_code,center_id,no_of_sem,sem_details) => dispatch(programCreate(label,short_code,center_id,no_of_sem,sem_details)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Programs);
