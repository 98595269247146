import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Listing from '../../public/icons/listing.svg';
import Trash from '../../public/icons/trash.svg';
import Stamp from '../../public/icons/stamp.svg';
import Calendar from '../../public/icons/calendar.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import './styles.css';
import Back from '../../public/icons/back.svg';

class Practice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      opend: false,
      openf: false,
      openp: false,
      center_id: localStorage.getItem('center_id'),
      filterCenter: localStorage.getItem('center_id'),
      filterClass: '',
      filterClassName: '',
      filterSubject: '',
      filterSubjectName: '',
      class_centers: {},
      class_center: '',
      centers: [],
			list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      assessments: [],
      filterAssessments: [],
      is_published: false,
      filterClasses: [],
      classes: [],
      subjects: [],
      subjects2: [],
      class_id: '',
      subject_id: '',
      is_faculty_admin:false,
      user_id: localStorage.getItem("user_id")
		}
  }
  
  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getCenterList();
    this.getAssessmentList(this.state.center_id);
    this.getClassList();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  show = () => this.setState({ open: true, class_id: '', subject_id: '', section_id: '' });
  
  close = () => this.setState({ open: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  showp = (val) => this.setState({ openp: true, d_id: val.id, is_published: !val.is_published });
  
  closep = () => this.setState({ openp: false });

  showf = (val) => this.setState({ openf: true });
  
  closef = () => this.setState({ openf: false });

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ centers: response.data });
    }
  }

  getAssessmentList = async (center) => {
    const response = await this.props.getAssessmentList(center);
    if(response !== undefined) {
      this.setState({ assessments: response.data.filter(assessment => assessment.type === 1),
        filterAssessments: response.data.filter(assessment => assessment.type === 1) });
    }
  }

  getClassList = async () => {
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes: response.data, class_names: response.data.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),
        class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ), 
        filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.center_id)) });
    }
  }

  getSubjectList = async (class_center, class_id) => {
    const response = await this.props.getSubjectList2(class_center,class_id);
    if(response !== undefined) {
      this.setState({ subjects: response.data.filter(subject => subject.class_details.id === parseInt(class_id)), 
        subject_names: response.data.reduce((obj, item) => Object.assign(obj, { [item.id]: item.label }), {}) });
    }
  }

  getSubjectList2 = async (class_center, class_id) => {
    const response = await this.props.getSubjectList2(class_center,class_id);
    if(response !== undefined) {
      this.setState({ subjects2: response.data.filter(subject => subject.class_details.id === parseInt(class_id)) });
    }
  }

	changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  filterCenterChange = (e) => {
    if(e.target.value === '') {
      this.setState({ filterCenter: '', filterClass: '', filterSubject: '',
                        filterClassName: '', filterSubjectName: '' });
      this.getAssessmentList(this.state.center_id);
    }
    else {
      this.setState({ filterCenter: e.target.value, filterClass: '', filterSubject: '',
                        filterClassName: '', filterSubjectName: '',
                        filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value)) });
      this.getAssessmentList(e.target.value);
    }
  }

  classChange2 = (e) => {
    if(e.target.value === '') {
      this.setState({ filterClass: '', filterSubject: '', filterClassName: '',
                        filterSubjectName: '' });
    }
    else {
      this.setState({ filterClass: e.target.value, filterSubject: '', filterSubjectName: '',
                        filterClassName: this.state.class_names[e.target.value],
                        class_center: this.state.class_centers[e.target.value] })
      this.getSubjectList(this.state.class_centers[e.target.value], e.target.value)
    }
  }

  subjectChange2 = (e) => {
    if(e.target.value === '') {
      this.setState({ filterSubject: '', filterSubjectName: '' })
    }
    else {
      this.setState({ filterSubject: e.target.value, 
          filterSubjectName: this.state.subject_names[e.target.value] });
    }
  }

  filterClassSubject = () => {
    if(this.state.filterClass !== '' && this.state.filterSubject !== '') {
      var assessments = this.state.assessments.filter(assessment => {
        return assessment?.classs === parseInt(this.state.filterClass) 
          && assessment?.subject_id === parseInt(this.state.filterSubject)
      });
      this.setState({ filterAssessments: assessments });
    }
    this.setState({ openf: false })
  }

  removeFilters = () => {
    this.setState({ filterClass: '', filterSubject: '', filterClassName: '',
              filterSubjectName: '', filterAssessments: this.state.assessments, openf: false });
  }

  classChange = (e) => {
    this.setState({ class_id: e.target.value, subject_id: '' })
    this.getSubjectList2(this.state.class_centers[e.target.value], e.target.value)
  }

  subjectChange = (e) => {
    this.setState({ subject_id: e.target.value })
  }

  deleteAssessment = async () => {
    const { d_id } = this.state;
    await this.props.deleteAssessment(d_id);
    this.setState({ opend: false });
  }

  publishAssessment = async () => {
    const { d_id, is_published } = this.state;
    await this.props.publishAssessment(d_id, is_published);
    this.setState({ openp: false });
  }

  changeTab = () =>{
    localStorage.setItem("is_faculty_admin","false")
    this.setState({is_faculty_admin:false})
  }

	render() {
    const isEnabled = this.state.class_id !== '' && this.state.subject_id !== '';
    const isEnabledf = this.state.filterClass !== '' && this.state.filterSubject !== '';
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search assessments"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>Practice Tests</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.practice_tests}</title>
        </Helmet>
				<Header placeholder="Search practice tests"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content5">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.select_class_and_subject}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select onChange={this.classChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.assign_class}
                </option>
                {this.state.filterClasses.map((classs, index) =>
                  <option value={classs.id} key={index}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select onChange={this.subjectChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.assign_subject}
                </option>
                {this.state.subjects2.map((subject, index) =>
                  <option value={subject.id} key={index}>
                    {subject.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <Link style={!isEnabled ? { pointerEvents: 'none', opacity: 0.8, cursor: 'not-allowed' } 
            : { }} className="popup-actions" to={{ pathname: '/admin/new-assessment', state: { type: 1, 
            url: this.props.location.pathname, subject_id: this.state.subject_id,
            class_id: this.state.class_id } }}>
            <button >{this.props.pagewiseLanguageData.next}</button>
          </Link>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteAssessment}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <Popup open={this.state.openp} closeOnDocumentClick onClose={this.closep}
          className="popup-content3">
          <div className="delete-up">
            <img src={Stamp} alt="publish"/>
            {this.props.pagewiseLanguageData.are_you_sure_you_want_to}
            <br/>{this.state.is_published ? this.props.pagewiseLanguageData.publish : this.props.pagewiseLanguageData.unpublish} {this.props.pagewiseLanguageData.this_assessment}
          </div>
          <div className="delete-down">
            <button onClick={this.closep}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.publishAssessment}>
              {this.state.is_published ? this.props.pagewiseLanguageData.publish : this.props.pagewiseLanguageData.unpublish}
            </button>
          </div>
        </Popup>
        <Popup open={this.state.openf} closeOnDocumentClick onClose={this.closef}
          className="popup-content5">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.filter_with_class_and_subject}
          </div>
          <span className="close" onClick={this.closef}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select value={this.state.filterClass} onChange={this.classChange2}>
                <option value={''}>
                {this.props.pagewiseLanguageData.select_class}
                </option>
                {this.state.filterClasses.map((classs, index) =>
                  <option value={classs.id} key={index}>
                    {classs.label}
                  </option>
                )}
              </select>
              <select value={this.state.filterSubject} onChange={this.subjectChange2}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_subject}
                </option>
                {this.state.subjects.map((subject, index) =>
                  <option value={subject.id} key={index}>
                    {subject.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="filter-buttons">
            <span style={this.state.filterClass === '' && this.state.filterSubject === '' 
              ? {pointerEvents: 'none'} : {}} onClick={this.removeFilters}>{this.props.pagewiseLanguageData.revome_filters}</span>
            <button onClick={this.filterClassSubject} disabled={!isEnabledf}>
              {this.props.pagewiseLanguageData.save_filter}
            </button>
          </div>
        </Popup>
				<div className="main-part">
					<div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
                
								<h1>{this.props.pagewiseLanguageData.practice_tests}</h1>
							</div>
						</div>
						<div className="heading-part2">
              <span onClick={this.show}>
								<img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
							</span>
              {/* <span>
								<img src={Calendar} alt="img"/>Schedule Existing
							</span> */}
              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt={this.props.pagewiseLanguageData.list_view}/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt={this.props.pagewiseLanguageData.grid_view}/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
						</div>
					</div>
          <div className="sub-filter">
            {!this.state.is_faculty_admin && <select defaultValue={parseInt(this.state.filterCenter)} 
              onChange={this.filterCenterChange}>
              {this.state.centers.map(center =>
                <option key={center.id} value={center.id}>{center.name}</option>
              )}
            </select>}
            {this.state.filterClass !== '' && this.state.filterSubject !== ''
              ? <div className="sub-filter-x" onClick={this.showf}>
              {this.state.filterClassName} | {this.state.filterSubjectName}
            </div> : <div className="sub-filter-x" onClick={this.showf}>
              {this.props.pagewiseLanguageData.filter_class_and_Subject}
            </div>}
          </div>
          {this.state.filterAssessments.length === 0 ? <Empty/> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.title}
              </div>
              <div className="list-email">
              {this.props.pagewiseLanguageData.class}
              </div>
              <div className="list-email">
              {this.props.pagewiseLanguageData.subjects}
              </div>
              <div className="list-action">
                {this.props.pagewiseLanguageData.actions}
              </div>
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.filterAssessments.map((assessment, index) =>
                <div className="list-item" key={assessment.id}>
                  <Link className="title-item"
                    to={{pathname: `/admin/assessment-preview/${assessment.id}`,
                    state: {modal: false}}}>
                    {assessment.title}
                    <span>{assessment.is_published ? this.props.pagewiseLanguageData.published : this.props.pagewiseLanguageData.draft }</span>
                  </Link>
                  <div className="email-item">
                    {assessment?.class_name}
                  </div>
                  <div className="email-item">
                    {assessment?.subject_name}
                  </div>
                  {assessment.is_published ? <div className="action-item">
                    <div id="section-button2" onClick={() => this.showp(assessment)} Style={assessment.created_by != this.state.user_id ? "display: none;":""}>
                      {this.props.pagewiseLanguageData.unpublish}
                    </div>
                    <Link id="section-button2" to={{pathname: `/admin/practice-tests/assign-sections`,
                      state: {
                        title: assessment.title,
                        center: this.state.filterCenter,
                        assessment_id: assessment.id,
                        class_id: assessment.classs,
                        subject_id: assessment.subject_id
                      }}}>
                      <img src={Listing} alt="list"/>
                      {this.props.pagewiseLanguageData.section}
                    </Link>
                  </div> : <div className="action-item">
                    <Link Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display: none;"} title={this.props.pagewiseLanguageData.edit} to={{ pathname: `/admin/assessment-settings/${assessment.id}`,
                      state: {
                        url: this.props.location.pathname,
                        toc_id: null,
                        class_id: assessment.classs_id,
                        subject_id: assessment.subject_id
                      }}
                    }>
                      <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                    </Link>
                    <span Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display: none;"} title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(assessment.id)}>
                      <img src={Delete} alt={this.props.pagewiseLanguageData.edit}/>
                    </span>
                    <button onClick={() => this.showp(assessment)} Style={assessment.created_by != this.state.user_id ? "display: none;":""}>
                    {this.props.pagewiseLanguageData.publish}
                    </button>
                  </div>}
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.filterAssessments.map((assessment, index) =>
                <div className="grid-item" key={assessment.id}>
                  <div className="grid-up">
                    <Link className="title-grid"
                      to={{pathname: `/admin/assessment-preview/${assessment.id}`,
                      state: {modal: false}}}>
                      {assessment.title}
                      <span>{assessment.is_published ? this.props.pagewiseLanguageData.unpublished : this.props.pagewiseLanguageData.draft}</span>
                    </Link>
                    {assessment.is_published ? <div className="grid-actions">
                      <div id="section-button2" onClick={() => this.showp(assessment)} Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display: none;"}>
                        {this.props.pagewiseLanguageData.unpublish}
                      </div>
                    </div> : <div className="grid-actions">
                     <Link  Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display: none;"} title={this.props.pagewiseLanguageData.edit} to={{ pathname: `/admin/assessment-settings/${assessment.id}`,
                        state: {
                          url: this.props.location.pathname,
                          toc_id: null,
                          class_id: assessment.classs_id,
                          subject_id: assessment.subject_id
                        }}
                      }>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </Link>
                      <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(assessment.id)} Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display: none;"}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                      </span>
                    </div>}
                  </div>
                  <div className="grid-down">
                    {assessment.is_published ? <div className="grid-actions">
                      <Link id="section-button2" to={{pathname: `/admin/practice-tests/assign-sections`,
                        state: {
                          title: assessment.title,
                          center: this.state.filterCenter,
                          assessment_id: assessment.id,
                          class_id: assessment.classs,
                          subject_id: assessment.subject_id
                        }}}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.sections}/>
                        {this.props.pagewiseLanguageData.sections}
                      </Link>
                    </div> : <div className="grid-actions">
                      <button onClick={() => this.showp(assessment)} Style={(assessment.created_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display: none;"}>
                      {this.props.pagewiseLanguageData.publish}
                      </button>
                    </div>}
                  </div>
                </div>
              )}
            </div>}
          </>}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentList, deleteAssessment, publishAssessment, getClassList2,
            getSubjectList2, getCenterList,getPageLanguageWiseData } = actions;
  return {
    getCenterList: () => dispatch(getCenterList()),
    getAssessmentList: (center) => dispatch(getAssessmentList(center)),
    deleteAssessment: (id) => dispatch(deleteAssessment(id)),
    publishAssessment: (id, is_published) => dispatch(publishAssessment(id, is_published)),
    getClassList2: () => dispatch(getClassList2()),
    getSubjectList2: (center_id,class_id) => dispatch(getSubjectList2(center_id,class_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Practice);