import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import VideoPlayer2 from '../../components/players/video/VideoPlayer2';
import PdfPlayer2 from '../../components/players/pdf/pdfPlayer2';
import actions from '../../redux/actions';
import common from '../../common';
import Pointer from '../../public/icons/content-point.svg';
import Back from '../../public/icons/back.svg';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Empty from '../../components/error/Empty';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import AssignmentPreview from '../../components/study-material/commonAssignment';

class ContentFilter extends Component {
  render() {
    return (
      <div className="content-table">
        <div className="content-table-head">{this.props.title}</div>
        <div className="content-table-data">
          {this.props?.content?.map(content =>
            <Link className="content-point" key={content.content_id} to={{
              pathname: `${content.content_subtype === 10 ? `/admin/assessment-preview/${content.assessment_id}` 
                : `/admin/content-view/${content.content_id}`}`,
              state: {
                modal: content.content_subtype === 10 ? true : false,
                class: this.props.class,
                subject: this.props.subject,
                unit: this.props.unit,
                chapter: this.props.chapter,
                topic: this.props.topic,
                subtopic: this.props.subtopic,
                title: this.props.title,
                content: this.props.content
              }
            }}>
              <img src={Pointer} alt="content"/>
              <div className={parseInt(this.props.id) === content.content_id 
                ? "selected_content" : ""}>
                <span>{content.title}</span>
                {content.is_published ? "Published" : "Draft"}
              </div>
            </Link>
          )}
        </div>
      </div>
    )
  }
}

class ContentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: this.props.match.params.id,
      class_label: this.props.location.state?.class,
      subject_label: this.props.location.state?.subject,
      unit_label: this.props.location.state?.unit,
      chapter_label: this.props.location.state?.chapter,
      topic_label: this.props.location.state?.topic,
      subtopic_label: this.props.location.state?.subtopic,
      content: this.props.location.state?.content,
      title: this.props.location.state?.title,
      content_detail: {},
      chapter_id: this.props.location.state?.chapter_id,
      topic_id: this.props.location.state?.topic_id,
      subtopic_id: this.props.location.state?.subtopic_id,
      back_path: this.props.location.state?.back_path,
      back_search: this.props.location.state?.back_search,
      subject_id:this.props.location.state?.subject_id,
      ref_id:this.props.location.state?.table_id,
      assignment_id:null,
      contentDetails:null
    }
  }
  
  componentDidMount() {

    if(!this.state.content){
      this.getContentData()
    }
    this.getContentDetails(this.props.match.params.id);
  }

  getContentData = async() => {
    const response = await this.props.getUnitTOC(this.state.subject_id)

    for(let i = 0; i < response.data.length; i++) {
      this.setState({unit_label:response.data[i].label})
      if(response.data[i].id == this.state.ref_id){
        this.setState({content: response.data[i].content})
      }else{
        
        for(let c = 0; c < response.data[i].chapters.length; c++) {
          this.setState({chapter_label:response.data[i].chapters[c].label})
          if(response.data[i].chapters[c].id == this.state.ref_id){
            this.setState({content: response.data[i].chapters[c].content})
          }
          else{
            
            for(let t = 0; t < response.data[i].chapters[c].topics.length; t++) {
              this.setState({topic_label:response.data[i].chapters[c].topics[t].label})
              if(response.data[i].chapters[c].topics[t].id == this.state.ref_id){
                this.setState({content: response.data[i].chapters[c].topics[t].content})
              }else{
                
                for(let l = 0; l < response.data[i].chapters[c].topics[t].subtopics.length; l++) {
                  this.setState({subtopic_label:response.data[i].chapters[c].topics[t].subtopics[l].label})
                  if(response.data[i].chapters[c].topics[t].subtopics[l].id == this.state.ref_id){
                    this.setState({content: response.data[i].chapters[c].topics[t].subtopics[l].content})
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps) {

    if(this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        loading: true,
        id: this.props.match.params.id,
        class_label: this.props.location.state?.class,
        subject_label: this.props.location.state?.subject,
        unit_label: this.props.location.state?.unit,
        chapter_label: this.props.location.state?.chapter,
        topic_label: this.props.location.state?.topic,
        subtopic_label: this.props.location.state?.subtopic,
        content: this.props.location.state?.content,
        title: this.props.location.state?.title,
        content_detail: {}
      })
      this.getContentDetails(this.props.match.params.id);
    }
  }

  getContentDetails = async (id) => {
    const response = await this.props.getContentDetails(id);
    if(response && response.data) {
      let contentIndex= this.state?.content?.findIndex(p => parseInt(p.content_id) === parseInt(this.props.match.params.id));
      
      this.setState({ content_detail: response.data, loading: false ,contentDetails:contentIndex ? this.state?.content[contentIndex] : ""})

      if(response.data?.content_subtype ==="Exercise"){
       await this.props.getContentPreview(id,1);
      }

      if(response.data?.content_subtype === "Assignment"){
        let assessmentIndex= this.state.content.findIndex(p => parseInt(p.content_id) === parseInt(this.props.match.params.id));
        
        this.setState({assignment_id:this.state?.content[assessmentIndex]?.assignment_id})
        await this.props.getContentPreview(this.state?.content[assessmentIndex]?.assignment_id,0);
        
      }
    }
  }

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if(label.toLowerCase() === 'review') {
      this.props.history.push({
        pathname: `/admin/assessment-preview/${contentPreview.assessment_id}`,
        //state: { modal: true }
      });
    }
  }

	render() {
    const { content_detail ,contentDetails} = this.state;
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.state.title}</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search content"/>
        <div className="main-part" id="content-part">
          <div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
                <Link id="trace-back" to={{ pathname: this.state.back_path?this.state.back_path:"/admin/study-material",
                  search: this.state.back_search,
                  state: {
                    classs: this.state.class_label,
                    subject: this.state.subject_label,
                    unit: this.state.unit_label,
                    chapter: this.state.chapter_label,
                    topic: this.state.topic_label,
                    subtopic: this.state.subtopic_label,
                    chapter_id: this.state.chapter_id,
                    topic_id: this.state.topic_id,
                    subtopic_id: this.state.subtopic_id
                  } 
                }}>
                  <img src={Back} alt="back"/>
                </Link>
								<h2>{this.state.class_label} | {this.state.subject_label} 
                  &nbsp;{this.state.unit_label ? `| ${this.state.unit_label}` : ''}
                  &nbsp;{this.state.chapter_label ? `| ${this.state.chapter_label}` : ''}
                  &nbsp;{this.state.topic_label ? `| ${this.state.topic_label}` : ''}
                  &nbsp;{this.state.subtopic_label ? `| ${this.state.subtopic_label}` : ''}</h2>
							</div>
						</div>
            <div className="heading-part2">
						</div>
					</div>
          <div className="content-view-div">
            <ContentFilter content={this.state.content} title={this.state.title}
              class={this.state.class_label} subject={this.state.subject_label}
              unit={this.state.unit_label} chapter={this.state.chapter_label}
              topic={this.state.topic_label} subtopic={this.state.subtopic_label}
              id={this.state.id} contentFunc={this.props.getContentPreview}/>
            {this.state.loading === false && <div className="content-view">
              {content_detail?.content_subtype === "Video" ? <VideoPlayer2 wKey={content_detail?.title}
                url={common.addFileUrl(content_detail?.content?.file_details[0]?.file_url)} content_id={this.props.match.params.id} subject_id={contentDetails.subject_id} ref_id={contentDetails?.ref_id} content_subtype={contentDetails?.content_subtype} content_type={contentDetails?.content_type} level={contentDetails?.level} current_pointer={contentDetails?.current_pointer}/>
                : <>
                {content_detail?.content_subtype ==="Notes"
                  ? <PdfPlayer2 url={common.addFileUrl(content_detail.content?.file_details[0]?.file_url)} content_id={this.props.match.params.id} subject_id={contentDetails.subject_id} ref_id={contentDetails?.ref_id} content_subtype={contentDetails?.content_subtype} content_type={contentDetails?.content_type} level={contentDetails?.level} current_pointer={contentDetails?.current_pointer}/>
                  : <>
                  {content_detail?.content_subtype ==="Exercise" && this.props.contentPreview?.title
                  ? <AssessmentPreview 
                      contentPreview={this.props.contentPreview}
                       attempt={this.attemptPaper}
                       showAttemptButton={true}
                    />
                  : <>
                  {content_detail?.content_subtype ==="Assignment"
                  ? <AssignmentPreview
                      contentPreview={this.props.contentPreview}
                      assignment_id={this.state.assignment_id}
                      subject_label={this.props.location.state?.subject}
                    /> 
                  : <Empty/>}
                  </>}
                  </>
                }
                </>
              }

            </div>}
          </div>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getContentDetails ,getContentPreview,getUnitTOC} = actions;
  return {
    getUnitTOC: (subject_id) => dispatch(getUnitTOC(subject_id)),
    getContentDetails: (id) => dispatch(getContentDetails(id)),
    getContentPreview: (contentId, content_subtype) => dispatch(getContentPreview(contentId, content_subtype))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentView);