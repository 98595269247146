import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';
import Carousel from 'react-elastic-carousel';
import axios from 'axios';
import actions from '../../redux/actions';
import Logo from '../../public/icons/favicon.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import LoginImage1 from '../../public/images/sow-login-banner1.png';
import LoginImage2 from '../../public/images/sow-login-banner2.png';
import LoginImage3 from '../../public/images/sow-login-banner3.png';
import LoginImage4 from '../../public/images/sow-login-banner4.png';
import ProgressBar from "./progress-bar";
import common from '../../common';
import queryString from 'query-string'
//import 'react-notifications-component/dist/theme.css';
import './styles.css';

const testData = [
  { bgcolor: "#6a1b9a", completed: 60 },
];

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      hide: true,
      completed:0,
      token: this.props.location.search.split('&')[0].split('=')[1],
    }
  }

  componentDidMount(){

    const value=queryString.parse(this.props.location.search);
    const string=this.props.location.search.replace("?d=", '');

    //let string="eyJjb250ZW50X2lkIjoxNDM3LCJrZXkiOiJ2aWRlbyIsImNvbnRlbnRfc3VidHlwZSI6NSwic3ViamVjdF9pZCI6MjAwLCJzdWJqZWN0X2xhYmVsIjoiIiwidG9jX2lkIjo2NDh9:1kjwmb:hTCKDpifUVIplNyCjGc-vWonHgQ";
    this.getParameters(string)


    this.timer = setInterval(()=>this.scheduledOn(1), 2000)
    //this.props.history.push({pathname:"learner/classroom/263"});
  }

  getParameters =async (string) =>{
    const response = await this.props.decrypt(string);

    if(response?.data && response.data?.key == "video"){
      localStorage.setItem("preview_content_id",response.data?.contentId)
      localStorage.setItem("content_table_id",response.data?.tocId)
      localStorage.setItem("content_subtype_name",response.data?.contentSubtype)
      this.props.history.push({pathname:`learner/classroom/${response.data?.subjectId}`});
    }

    if(response?.data && response.data?.key == "notes"){
      localStorage.setItem("preview_content_id",response.data?.contentId)
      localStorage.setItem("content_table_id",response.data?.tocId)
      localStorage.setItem("content_subtype_name",response.data?.contentSubtype)
      this.props.history.push({pathname:`learner/classroom/${response.data?.subjectId}`});
    }

    if(response?.data && response.data?.key == "sessions"){
      //this.props.history.push({pathname:`learner/sessions/?id=${response.data?.session_id}`});
      this.props.history.push(`/learner/sessions?id=${response.data?.sessionId}`)
    }

    if(response?.data && response.data?.key == "batch"){
      this.props.history.push({pathname:`learner/study-material/${response.data?.subjectId}`});
    }

    if(response?.data && response.data?.key == "course"){
      this.switchCourse(response.data?.batchId)
      this.props.history.push({pathname:`learner`});
    }

  }

  switchCourse = async (val) => {
    await this.props.switchCourse(val);
    this.setState({ open: false });
  }

  scheduledOn = (val) => {

    if(this.state.completed == 100) clearInterval(this.timer);
    else{
      let comp = this.state.completed ;
      this.setState({completed : comp+1})
    }
  }


	render() {
		return (
			<div className="login-div">
        <div className="login_left-map">
           <div className="App" Style="width: 50%">
              <div>Wait for couple of seconds!!</div>
              {testData.map((item, idx) => (
                <ProgressBar key={idx} bgcolor={item.bgcolor} completed={this.state.completed} />
              ))}
            </div>
        </div>
        
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { decrypt ,switchCourse} = actions;
  return {
    decrypt: (query) => dispatch(decrypt(query)),
    switchCourse: (batch_id) => dispatch(switchCourse(batch_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);