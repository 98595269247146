import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './mycoach.css';
import HeaderMyCoach from './HeaderMyCoach';
import config from '../../config';
import axios from 'axios';
import common from '../../common';

import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import thanksDialogCheckmark from '../../public/icons/thanksdialog-checkmark.png';
class MyCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      myCoursesList:[],
      profile: {},
      bannerID:'',
      username:'',
      isvisible:false, 
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
   }
  }
  
   componentDidMount() {
    localStorage.setItem("page","challenges");
    if(this.state.is_webview){
      localStorage.setItem('token', this.state.token);
      this.myCoursesList()
       
    }else{
      this.myCoursesList();
    }
  }

  myCoursesList = async() =>{
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.myCoursesList}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(res => {
          this.setState({ myCoursesList: res?.data.data})
          this.getLearnerProfile()
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.myCoursesList();
      this.setState({myCoursesList:response?.data})
    }
  }

  getLearnerProfile = async() =>{
    await axios.get(`${common.apiBase}/${common.userProfile}`,{headers: {
    'Authorization': 'token ' + this.state.token}
    }).then(res => {
      this.setState({username:res.data.data.username})
    }).catch(err => { console.error('error', err.message) });
  }

  /*myCoursesList = async() =>{
    const response = await this.props.myCoursesList();
    //this.setState({mycoachdata : response?.data})
    this.setState({myCoursesList:response?.data})
    //console.log("sdfsdf response" + JSON.stringify(this.state.myBannerdata));
  }*/


  ApplyBanner = async(slug,domain) =>{
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.wpUserToken}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
        const username = this.state.username || localStorage.getItem("username")
        window.open(`${domain}/1.php?token=${response.data.data?.token}&username=${username}&slug=${slug}`, "_self");
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.getwpUserToken();
      this.setState({token:response.data?response.data.token:null});
      const username = this.state.username || localStorage.getItem("username")
      
      if(slug)
        window.open(`${domain}/1.php?token=${response.data?.token}&username=${username}&slug=${slug}`,"_self");
    }
  }


  closeDialog(){
    this.setState({isvisible : false});
  }

  render() {
    const MAX_LENGTH = 55;
    return (
      <>
      <ReactNotifications/>
      {!this.state.is_webview && <HeaderMyCoach/>}
        <div className={"app-body "+(this.state.is_webview == 1 ? 'webview' : '')}>
          
          <div className="app-div mycoach-div">
            <Helmet>
              <title>Certification Courses - Learner</title>
            </Helmet>
            <div className="mycoach-wrapper">

                <div className="certification-academy-block">
                    <div className="topHeading">
                      {!this.state.is_webview && <a href="/mycoach"><img src={BackArrow} alt="backarrow" /></a>}<h3>Certification Courses</h3>
                    </div>
                    <p>Learn new skills and build your resume for the future with Certification Academy.</p>
                    <div className="design-block">
                      
                      <ol>
                      {this.state.myCoursesList?.length ? this.state.myCoursesList.map(item =>
                        <li>
                          <div className="thumb">
                            <img src={item?.wp_course_image} alt="wp_course_image"/>
                          </div>
                          <div className="hoverOverlay">
                              <div className="thumb-title">{item.classs?.label}</div>
                              <div className="thumb-content">{item.classs?.description}</div>
                              <div className="thumb-action">
                                <a href="javascript:void(0)"  onClick={()=>this.ApplyBanner(item.wp_course_slug,item.wp_domain)}>Enroll</a>
                              </div>
                            </div>
                          <div className="thumb-title">{item.classs?.label}</div>
                          <div className="thumb-content thumb-content-block">{`${item.classs?.description.substring(0, MAX_LENGTH)}...`}</div>
                         <div className="thumb-action">
                            {/* <div className="duedate-tag"><span>Due date</span></div>*/}
                            <a href="javascript:void(0)"  onClick={()=>this.ApplyBanner(item.wp_course_slug,item.wp_domain)}>Enroll</a>
                          </div>
                      </li>
                    ) : ""}       
                  </ol>
                    </div>
                </div>



              </div>
            </div>
          </div>

          <div className= {this.state.isvisible ? 'isvisible thanksApplyDialog':'thanksApplyDialog'}>
          <div className="thanksApplyDialog-inner">
              <div className="popup-header"><i><img src={thanksDialogCheckmark} alt="thanksDialogCheckmark"/></i>Thank you for Applying</div>
              <span className="close" onClick={()=>this.closeDialog()}>&#x2715;</span> 
              <div className="thanksApplyDialog-content">
                We will contact with you soon
              </div>
          </div>
        </div>
        
      </>

    
   
    )

  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage
   
  }
}

const mapDispatchToProps = (dispatch) => {
  const {myCoursesList,xpUserBannerCreation,getwpUserToken} = actions;
  return {
    getwpUserToken: () => dispatch(getwpUserToken()),
    myCoursesList: () => dispatch(myCoursesList()),
    xpUserBannerCreation: (banner_id) => dispatch(xpUserBannerCreation(banner_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCourses);