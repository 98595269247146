import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string'

import actions from '../../redux/actions';
import './styles.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: '0z1vfz8aau14nt5ipzdmc_',
      hide: true
    }
  }

  componentDidMount() {
    this.onSubmit();
  }

  onSubmit = async () => {
    
    const value=queryString.parse(this.props.location.search);
    const token=this.props.location.search.replace("?secret=", '');

    var referrer = document.referrer;
    console.log("referrer url",referrer);


    console.log("this.props.location")
    console.log(window.location.href)
  
    localStorage.setItem("secret",token);

    const response = await this.props.wpKeyDecrypt(token,referrer);

    
    localStorage.setItem('logindetails', JSON.stringify(response.data));
    localStorage.setItem('token', response.data?.auth_token);
    localStorage.setItem('name', response.data?.name);
    localStorage.setItem('preferredGrid', 'true');
    localStorage.setItem('username',  response.data.username);
    localStorage.setItem('user_id',  response.data.id);
    localStorage.setItem('dp',response.data.dashboard_type);
    localStorage.setItem('widget_alignment_size',response.data.widget_alignment.size);
    localStorage.setItem('widget_alignment_alignment',response.data.widget_alignment.alignment);

    if(response.data.center_details?.is_center_admin === true) {
      localStorage.setItem('is_admin', 'true');
      localStorage.setItem('username',response?.data?.username);
      localStorage.setItem('center_id', response.data?.center_details.center_id);
      //Service.updateToken();
      window.location.href = '/admin/institute';
    }
    else if(response.data?.is_faculty === true) {
      localStorage.setItem('is_faculty', 'true');
      localStorage.setItem('center_id', response.data?.center_details.center_id);
      localStorage.setItem('username',response?.data?.username);
      localStorage.setItem('is_faculty_admin',"false")
      //Service.updateToken();
      window.location.reload();
    }
    else {
      localStorage.setItem('is_faculty', 'false');
      localStorage.setItem('on_login', 1);
      localStorage.setItem('center_id', response.data?.center_id);
      if(localStorage.getItem("gmail_source") == "1"){
        window.location.href = '/view-mapping'+localStorage.getItem("search");
      }else{
        window.location.reload();
      }
    }
    //const { username, password } = this.state;
    //if(username) await this.props.userLogin(username, password);
  }

	render() {
		return (
			<div>
       
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userLogin,wpKeyDecrypt} = actions;
  return {
    userLogin: (username, password) => dispatch(userLogin(username, password)),
    wpKeyDecrypt :(key,referrer) => dispatch(wpKeyDecrypt(key,referrer))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);