import React from 'react';
import { Link } from 'react-router-dom';

import common from '../../common';
import Time from '../../public/icons/preview_time.svg';
import Sections from '../../public/icons/preview_sections.svg';
import Marking from '../../public/icons/preview_marking.svg';
import Difficulty from '../../public/icons/preview_difficulty.svg';
import Attempts from '../../public/icons/preview_attempts.svg';
import Solution from '../../public/icons/Component 58 – 13.svg';
import Passed from '../../public/icons/Group 922.svg';
import PassedText from '../../public/icons/Passed.svg';
import Evaluation from '../../public/icons/evaluation.svg';
import tick from '../../public/icons/admin-assignment-tick.png';
import tickdisabled from '../../public/icons/admin-assignment-tickdisabled.png';
import downarrow from  '../../public/icons/assignment-listing-arrow.png';
import clockicon from  '../../public/icons/assignment-clock-icon.png';
import defaultImage from '../../public/icons/maxresdefault.png';
import gradingflag from '../../public/icons/gradingflag.png';
import './styles.css';


const getUrl = (url) => {
   return url;
}

const dueOn1 = (due) => {
  let dt1 = new Date();
  var diff = due.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
  let hours = Math.round(diff/60);
  let days = Math.round(hours/24);

  if(days > 0)
    return days + ' days';
  if(hours > 0)
    return hours + ' HRS';
  else if(diff > 0)
    return diff + ' mins';
  else
    return '0 mins';
}


const AssignmentPreview = (props) => {

  let is_admin = localStorage.getItem("is_admin") == "true" ? true : false;
  let is_faculty = localStorage.getItem("is_faculty") == "true" ? true : false;

  var blockedTile = new Array("jpg", "jpeg", "png", "gif");
  let difficulty= ['easy', 'medium', 'hard', 'very-hard'] ;
  var ifpdf =null;

  if(blockedTile.indexOf(props.contentPreview?.file?.split(".").pop()) != -1)
  {  
    ifpdf = false
  }else{
    ifpdf = true
  }

  return (
    <div className="assignment-review-wrapper">
      <div className="assignment-review-container">
          
          <div className="assignment-review-top">
              <div className="assignment-title">{props.contentPreview.title} <span>{props.contentPreview.uid}</span></div>
              <div className="assignment-title-desc">{props?.contentPreview?.description}</div>
          </div>

          <div className="assignment-review-bottom">
              <div className="assignment-bottom-left">
                <div className="hours-block"><i><img src={clockicon}/></i>{dueOn1(new Date(props.contentPreview.due_date))} left</div>
                <div className="marks-block">Total Marks <span>{props.contentPreview.total_marks}</span></div>
                <div className="passing-block">Passing Marks <span>{props.contentPreview.passing_marks}</span></div>
              </div>
              <div className="assignment-bottom-right">
                <div className="tag-block"><span Style="text-transform: capitalize;">{difficulty[props.contentPreview.difficulty]}</span></div>
                <div className="attempts-block"><span>{props.contentPreview.attempts_allowed ? props.contentPreview.attempts_allowed : "Unlimited"} Attempts</span></div>
                <p>{props.contentPreview.closed_after_passing ? "Attempts will be closed on assignment pass":""}</p>
              </div>
          </div>

          {ifpdf ? <div className="imagepreview">
              <a href={props.contentPreview.file} alt="file" download>{props.contentPreview?.file?.split("/").pop()}</a>
            </div> :<div className="imagepreview">
              <img src={getUrl(props.contentPreview.file)}/>
            </div>
          }
            

          <div className="assignment-review-last-bottom">
            <div className="assignment-last-bottom-left">
              {/*<div className="submission-block"><span>20%</span> Submission</div>*/}
            </div>
            <div className="assignment-last-bottom-right">
              {props.contentPreview.is_graded && <div className="grading-block"><i><img src={gradingflag}/></i>Grading Inprogress</div>}
            </div>
          </div>
          <div className={is_admin ? "assignment-review-last-bottom-button" : "assignment-review-last-bottom-button1"}>
            {is_admin ? <Link to={{pathname: `/admin/assignment-preview/${props.assignment_id}`,
                        state: {modal: true,subject:props.subject_label}}}>
                       Review
            </Link> : 
            <> {is_faculty ? 
            <Link to={{pathname: `/faculty/assignment-review/${props.assignment_id}` , state:{action:"Review",subject:props.subject_label,id:""}}}>
                                    <button>
                                      Review
                                    </button>
                                  </Link> : 
            <>{props.contentPreview?.action?.map((actions, index) => {
              return  <Link to={{pathname: `/learner/assignment-review/${props.assignment_id}` , state:{action:actions.label,subject:props.subject_label}}}>
                        <button>
                          {actions.label}
                        </button>
                      </Link>
            })}</>
          } </>}
          </div>

      </div>       
    </div>
  )
} 

export default AssignmentPreview;