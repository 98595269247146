import React, { useEffect, useState } from 'react'
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import './UsageReport.css'
import Profile from '../../public/icons/profile.svg';
import { formatDistanceToNow } from 'date-fns';
import SortingComponent from './SortingComponent';
import moment from 'moment';
import 'moment-timezone';
const UsageReport = (props) => {
  const { organizationUsageReport, getOpenapiUserReport } = props;
  const [organizationUsageReportData, setOrganizationUsageReportData] = useState(null)
  const [userDataReport, setUserReportData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [userInput, setUserInput] = useState(null)
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('')

  const [sortingTable, setSortingTable] = useState({

    last_active: null,
    documents: null,
    credit_used: null
  })


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchUserUsageReport(pageNumber, userInput, orderBy, order);
  };

  const fetchOrganizationUsageReport = async () => {
    const response = await organizationUsageReport()
    setOrganizationUsageReportData(response?.data[0])
  }
  const fetchUserUsageReport = async (pageNumber, search_user, order_by, order) => {
    console.log("order by new ", pageNumber, search_user, order_by, order)
    const response = await getOpenapiUserReport(pageNumber, search_user == "" ? null : search_user, order_by, order)
    setUserReportData(response?.data)
  }


  useEffect(() => {
    fetchOrganizationUsageReport(currentPage)
    fetchUserUsageReport()
  }, [])

  useEffect(() => {

    const delayDebounceFn = setTimeout(() => {
      fetchUserUsageReport(1, userInput, orderBy, order);
    }, 1000); // 1000 milliseconds = 1 second

    return () => clearTimeout(delayDebounceFn);
  }, [userInput, orderBy, order]);



  const handleFilterToggle = (type) => {


    const getValue = (value) => {
      switch (value) {
        case null:
          return true;

        case true:
          return false;

        case false:
          return null;
      }
    }
    const getSortBy = (value) => {
      switch (value) {
        case null:
          return 'asc';

        case true:
          return 'desc';

        case false:
          return '';
      }
    }

    setOrderBy(type)
    setOrder(getSortBy(sortingTable[type]))

    setSortingTable({
      ...sortingTable,
      [type]: getValue(sortingTable[type])
    });
  }

  const formatTimestamp = (inputDate) => {
    const currentDate = new Date();

    const myDate = moment.tz(inputDate, 'Asia/Kolkata').utc().format('YYYY-MM-DD HH:mm:ss');
    const areaDate = moment.utc(myDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate();

    const timeDifference = currentDate - areaDate;

    // Convert milliseconds to seconds
    const seconds = Math.floor(timeDifference / 1000);
    // Convert seconds to minutes
    const minutes = Math.floor(seconds / 60);
    // Convert minutes to hours
    const hours = Math.floor(minutes / 60);
    // Convert hours to days
    const days = Math.floor(hours / 24);
    // Convert days to months (average 30 days per month)
    const months = Math.floor(days / 30);


    const formattedDate = `${areaDate.getDate()}/${areaDate.getMonth() + 1}/${areaDate.getFullYear()}`;
    let formattedHours = areaDate.getHours();
    const amPm = formattedHours < 12 ? 'AM' : 'PM';
    formattedHours = formattedHours % 12 || 12; // Convert 0 to 12
    const formattedTime = `${formattedHours}:${areaDate.getMinutes().toString().padStart(2, '0')} ${amPm}`;

    if (seconds < 60) {
      // return  `${formattedDate} ${formattedTime} (Just now)`;
      return `(Just now)`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
      // return `${formattedDate} ${formattedTime} (${minutes} min ago)`;
    } else if (hours < 24) {
      // return `${formattedDate} ${formattedTime} (${hours} hr ago)`;
      return `${hours} hr ago`;
    } else if (days === 1) {
      // return `${formattedDate} ${formattedTime} (${days} day ago)`;
      return `Yesterday`;
    } else if (days < 30) {
      // return `${formattedDate} ${formattedTime} (${days} day ago)`;
      return `${formattedDate} ${formattedTime}`;
    } else if (months < 12) {
      // return `${formattedDate} ${formattedTime} (${months} month ago)`;
      return `${formattedDate} ${formattedTime}`;
    } else {
      // return `${formattedDate} ${formattedTime} (${hours} hrs ago)`;
      return `${formattedDate} ${formattedTime}`;
    }
  }


  return (
    <div className='usage-report'>
      <div className="overview">
        {
          organizationUsageReportData && Object.keys(organizationUsageReportData).map((key) =>
            <OverViewCard
              key={key}
              data={organizationUsageReportData[key]}
              title={
                `${key}`.split('_')
                  .map((word => word.charAt(0)
                    .toUpperCase() + word.slice(1)))
                  .join(" ")
              }
            />
          )
        }
      </div>

      <div className="data-table">
        <div className="data-table-header">
          <h1 onClick={() => { fetchOrganizationUsageReport() }}>Members</h1>
          <input value={userInput} onChange={(e) => setUserInput(e.target.value)} type="text" placeholder='Search' />
          {/* <span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 11V17M14 11V17M4 7H20M6 7V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V7H6ZM15 7V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V7H15Z" stroke="#F20D0D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </span> */}
        </div>

        <div className='tableWrapper'>
          <p></p>
          <table>
            <tbody><tr>
              <th>Name </th>
              <th>
                Last Active
                <SortingComponent
                  sortState={sortingTable?.last_active}
                  onToggleSort={() => {
                    handleFilterToggle('last_active')
                  }}
                />
              </th>

              <th>
                Documents
                <SortingComponent
                  sortState={sortingTable?.documents}
                  onToggleSort={() => {
                    handleFilterToggle('documents')
                  }}
                />
              </th>
              <th>
                Credit Used
                <SortingComponent
                  sortState={sortingTable?.credit_used}
                  onToggleSort={() => {
                    handleFilterToggle('credit_used')
                  }}
                />
              </th>

            </tr>

              {
                userDataReport?.forums && [...userDataReport?.forums]
                  .sort((a, b) => {

                    if (sortingTable.byLastActive == null) {
                      return 0;
                    }
                    const dateA = Date.parse(a?.last_active) || 0;
                    const dateB = Date.parse(b?.last_active) || 0;
                    const result = dateA - dateB;
                    return sortingTable.byLastActive ? result : -result;

                  })


                  .map((data) =>
                    <tr>
                      <td>
                        <div className="center">
                          <img
                            src={
                              data?.profile_image_url == null || data?.profile_image_url == ""
                                ? Profile : data?.profile_image_url
                            }
                            alt=""
                          />
                          <p>{data.name}</p>
                        </div>
                      </td>
                      <td><p>{data?.last_active ? formatTimestamp(Date.parse(data?.last_active)) : ''}</p></td>
                      <td><p>{data?.documents ?? 0}</p></td>
                      <td><p>{data?.credit_used ?? 0}</p></td>
                      {/* <td>
                      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.3786 5.4496L7.46378 12.3645C7.18458 12.6437 6.82898 12.8341 6.44179 12.9115L3.50006 13.4998L4.08841 10.5581C4.16584 10.1709 4.35615 9.81533 4.63534 9.53613L11.5502 2.62117M14.3786 5.4496L15.7928 4.03538C16.1834 3.64486 16.1834 3.01169 15.7928 2.62117L14.3786 1.20696C13.9881 0.816431 13.3549 0.816431 12.9644 1.20696L11.5502 2.62117M14.3786 5.4496L11.5502 2.62117M1 16.9998H15" stroke="#141C24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </td> */}
                    </tr>
                  )
              }
            </tbody></table>


        </div>
      </div>




      <Pagination
        activePage={userDataReport?.pagination.current_page || 1}
        itemsCountPerPage={userDataReport?.pagination.page_size || 10}
        totalItemsCount={userDataReport?.pagination.count || 0}
        pageRangeDisplayed={userDataReport?.pagination.page_size || 5}
        onChange={handlePageChange}
      />
    </div>
  )
}


const mapStateToProps = (state) => {

}

const mapDispatchToProps = (dispatch) => {
  const { organizationUsageReport, getOpenapiUserReport } = actions;
  return {
    organizationUsageReport: () => dispatch(organizationUsageReport()),
    getOpenapiUserReport: (pageNumber, search_user, order_by, order) => dispatch(getOpenapiUserReport(pageNumber, search_user, order_by, order)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsageReport);

const OverViewCard = (props) => {
  const { title, data } = props
  const color = data?.increasing == 1 ? '#40BF7F' : '#F20D0D'
  return (
    <div className='overviewcard'>
      <p className='title'>{title}</p>
      <p className='value'>{data?.value}</p>
      <div className="percentage" style={{ color: color }}>
        <p>{data?.percentage}%</p>
        <>
          {data?.increasing == 1 ? <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 6.70711C-0.0675907 6.34662 -0.0953203 5.77939 0.209705 5.3871L0.292893 5.29289L5.29289 0.292893C5.65338 -0.0675907 6.22061 -0.0953203 6.6129 0.209705L6.70711 0.292893L11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711C11.3466 7.06759 10.7794 7.09532 10.3871 6.7903L10.2929 6.70711L6 2.415L1.70711 6.70711C1.34662 7.06759 0.779392 7.09532 0.387101 6.7903L0.292893 6.70711Z" fill="#40BF7F" />
          </svg> : <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C-0.0675907 0.653377 -0.0953203 1.22061 0.209705 1.6129L0.292893 1.70711L5.29289 6.70711C5.65338 7.06759 6.22061 7.09532 6.6129 6.7903L6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292893C11.3466 -0.0675907 10.7794 -0.0953203 10.3871 0.209705L10.2929 0.292893L6 4.585L1.70711 0.292893C1.34662 -0.0675907 0.779392 -0.0953203 0.387101 0.209705L0.292893 0.292893Z" fill="#F20D0D" />
          </svg>
          }
        </>
      </div>
    </div>
  );
}
