import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import basketIcon from '../../public/icons/lessonplanner-basket-icon.svg';
import communicationIcon from '../../public/icons/lessonplanner-communication-icon.svg';
import config from '../../config';
import blanklessonplanThumb from '../../public/icons/blanklessonplan.svg';
import lessonplanThumb from '../../public/icons/amplifiu-lesson-plan-icon.svg';
import lessonplanRubricThumb from '../../public/icons/lessonplan-rubric.svg';
import lessonplanAssessmentThumb from '../../public/icons/lessonplan-assessment.svg';
import lessonplanQuizThumb from '../../public/icons/lessonplan-quiz.svg';
import lessonplanTextlevellerThumb from '../../public/icons/lessonplan-textleveller.svg';
import lessonplanSummariserThumb from '../../public/icons/lessonplan-summariser.svg';
import lessonplanGroupactivityThumb from '../../public/icons/lessonplan-groupactivity.svg';
import lessonplanClassnewsletterThumb from '../../public/icons/lessonplan-classnewsletter.svg';
import lessonplanLoading from '../../public/icons/ajax-loader.gif';
import featureCoimingSoon from '../../public/icons/feature-coming-soon.svg';
import disclaimerIcon from '../../public/icons/disclaimer-icon.svg';
import previewIcon from '../../public/icons/amplifiu-preview-icon.svg';
import editIcon from '../../public/icons/amplifiu-edit-icon.svg';
import makeacopy from '../../public/icons/amplifiu-makeacopy-icon.svg';
import download from '../../public/icons/amplifiu-download-icon.svg';
import trashicon from '../../public/icons/trashicon.svg';
import versionhistoryicon from '../../public/icons/version-history-icon.svg';
import conceptNoteicon from '../../public/icons/amplifiu-concept-note-icon.svg';
import debateicon from '../../public/icons/amplifiu-debate-icon.svg';
import groupDiscussionicon from '../../public/icons/amplifiu-group-discussion-icon.svg';
import projecticon from '../../public/icons/amplifiu-project-icon.svg';
import roleplayicon from '../../public/icons/amplifiu-roleplay-icon.svg';
import unitPlanicon from '../../public/icons/amplifiu-unit-plan-icon.svg';
import collaborateIcon from '../../public/icons/amplifiu-collaborate-welcome.svg';
import collaborateIcon2 from '../../public/icons/amplifiu-collaborate-section2.svg';
import collaborateEventIcon from '../../public/icons/amplifiu-collaborate-events.svg';
import collaborateResourcesIcon from '../../public/icons/amplifiu-collaborate-resources.svg';
import collaborateGetStatedIcon from '../../public/icons/amplifiu-collaborate-get-started.svg';

import collaborateGuidelineIcon1 from '../../public/icons/amplifiu-collaborate-guidelines-icon.svg';
import collaborateGuidelineIcon2 from '../../public/icons/amplifiu-collaborate-guidelines-icon2.svg';
import collaborateGuidelineIcon3 from '../../public/icons/amplifiu-collaborate-guidelines-icon3.svg';
import collaborateGuidelineIcon4 from '../../public/icons/amplifiu-collaborate-guidelines-icon4.svg';

// import moment from 'moment';
// import 'moment-timezone';
import MobileNotReady from '../../components/error/MobileNotReady';


class Privacy extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      selected:'',
      token:localStorage.getItem('token'),
      user_id : localStorage.getItem('user_id'),
      lessonPlanDialogOpen:false,
      saveLessonPlanName:'',
      saveLessonPlanClass:'',
      saveLessonPlanSubject:'',
      saveLessonPlanTopic:'',
      saveLessonPlanBoard:'',
      AllListingData:[],
      OrignalAllListingData:{},
      dialogOpen:false,
      previewContent:'',
      loading:false,
      is_search:false,
      comingSoondialogOpen:false,
      iconsloaded:false,
      sortbyValue:'',
      page: 2,
      searchTerm:'',
      confitmationDialogOpen:false,
      saveIdForDelete:'',
      getIDofLastLi: '',
      timer: null,
      advanceSearchDialogOpen:false,
      versionHistoryDialogOpen:false,
      saveVersionhistryId:'',
      selectVersionHistoryID:0,
      toggleViewAll:true,
      saveAdvanceGrade:'',
      saveAdvanceSubject:'',
      saveAdvanceCurriculum:'',
      saveAdvanceDuration:'',
      advancedSearchStarted: false,
      saveclickIndex:'-1',
      popIndex: null,
      facultySidebarHidden_ids:true,
      center_id : '',
      isScrolled:false,
      nameColumnClass: '',
      typeColumnClass: '',
      gradeColumnClass: '',
      modifiedColumnClass: ''

    };
    
   
  }

  
  componentDidMount() {
   
    
  }


  componentWillUnmount() {
    
  }

   
  render() {
   
    return (
  
       
       <div className='termsPageWrapper'>
              <div className='termsPageContainer'> 
              {localStorage.getItem('logo_url') != 'undefined'? 
                  <span className='logo'><img src={localStorage.getItem('logo_url')} alt="Brand logo"/></span>
                : 
                <span className='logo'><img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span>
                }
               <h2>Privacy Statement – AmplifiU </h2>
               <p>Version 1.0 – June 2024</p>

              <h3>Introduction </h3>
              <p>AmplifiU ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use the AmplifiU platform ("Platform"). By accessing or using our Platform, you agree to this Privacy Policy. If you do not agree, please do not use the Platform. </p>
              <h3>Information Collection</h3>
              <p>We collect various types of information to provide and improve our services: </p>
              <ul>
                <li><strong>Personal Information:</strong>  This includes details such as your name, email address, billing information, and other data you provide during registration or purchase. </li>
                <li><strong>Usage Data:</strong> Information about how you use the Platform, including your IP address, browser type, access times, and pages visited. </li>
                <li><strong>Content:</strong> Any content you upload, create, or share on the Platform</li>
                <li><strong>Geolocation Data:</strong> IP addresses for security and fraud prevention.</li>
              </ul>
              <h3>Purpose of Collection</h3>
              <p>We use the collected information for various purposes: </p>
              <ul>
                <li><strong>To Provide and Maintain Our Service:</strong> Including account management, processing transactions, and providing customer support. </li>
                <li><strong>To Improve Our Services:</strong> Using analytics to understand how our Platform is used and to enhance user experience.</li>
                <li><strong>To Communicate with You:</strong>Sending updates, newsletters, and marketing materials (with your consent).</li>
                <li><strong>To Ensure Security:</strong>Monitoring and analysing activity to prevent fraud and ensure security.</li>
              </ul>
              <h3>User Responsibilities</h3>
              <ul>
                <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                <li><strong>Lawful Use:</strong>You agree to use the Platform only for lawful purposes and in compliance with all applicable laws. </li>
              </ul>
              <h3>Payment and Billing </h3>
              <p>When you make a purchase, we collect payment information which is processed securely by our third-party payment processor. We do not store your payment card details. The payment processor’s privacy policy governs the use of your payment information. </p>
              <h3>User Rights</h3>
              <p>At AmplifiU, we aim to grant the same data rights to all customers, irrespective of location: </p>
              <ul>
                <li><strong>Right to Access:</strong> You can request a copy of your personal data. </li>
                <li><strong>Right to Correction:</strong> You can request correction of inaccurate data. </li>
                <li><strong>Right to Deletion:</strong> You can request deletion of your data, subject to legal and contractual restrictions. </li>
                <li><strong>Right to Restrict Processing:</strong> You can request restrictions on the processing of your data.</li>
                <li><strong>Right to Object:</strong> You can object to certain types of data processing. </li>
                <li><strong>Right to Data Portability:</strong> You can request your data in a portable format. </li>
              </ul>
              <h3>Data Retention</h3>
              <p>We retain your data only as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce our agreements. Content you delete from your account will be permanently removed from our servers after a certain period. </p>
              <h3>Geolocation Data </h3>
              <p>We log IP addresses for security and fraud prevention. This data is retained as long as your account is active and is used to monitor access to the Platform. </p>
              <h3>Website Interactions </h3>
              <p>We collect browsing data to analyse and improve our services. This includes information about your device, browser, and interactions with our website. This data is used to optimize user experience and for security purposes. </p>
              <h3>Voluntary Correspondence </h3>
              <p>We store communications you send to us, such as emails and feedback. This helps us improve our services and provide better support. </p>
              <h3>Privacy, Security, and Safety </h3>
              <p>We respect user privacy and are committed to informing users about the data we collect and how it is used. We do not sell user data to third parties. We maintain stringent cybersecurity measures to protect user data from unauthorized access or breaches. In the event of a data breach, we will notify affected individuals and relevant authorities as required by law. </p>
              <h3>Responsible Use of AI </h3>
              <p>AmplifiU is committed to the ethical and responsible use of AI in education. We ensure that our AI technologies are designed to augment, not replace, human educators. We respect user privacy and data ownership and ensure that user data is not used for training unrelated AI models. </p>
              <h3>Data Location </h3>
              <p>Our operations are based in Australia. If you are outside Australia, please be aware that any information you provide will be transferred to and stored in Australia. By using our Platform or providing us with your personal information, you consent to this transfer. </p>
              <h3>Third-Party Services </h3>
              <p>We use third-party sub-processors, such as cloud computing providers and analytics services, to provide our services. The following are examples of sub-processors we use: </p>
              <ul>
                <li><strong>Microsoft Azure:</strong> Cloud services provider </li>
                <li><strong>OpenAI APIs:</strong> AI service provider </li>
                <li><strong>Google Analytics:</strong> Analytics service provider </li>
             </ul>
             <h3>Children's Privacy </h3>
             <p>Our Platform is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected such information, we will take steps to delete it as soon as possible. </p>
              <h3>Changes to This Privacy Policy </h3>
              <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of the Platform after such changes constitutes your acceptance of the new Privacy Policy. </p>
              <h3>Contact Us </h3>
              <p>If you have any questions or concerns about this Privacy Policy, please contact Paras Aggarwal at Paras@amplifiu.com or through our contact page. </p>
              <p>By using the Platform, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy. </p>


            </div>
          </div>
        
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,amplifiuThreadCreation,amplifiuFetchThreadDocumentListing,amplifiuSelectActiveInactive,htmlToPDF,amplifiuLessonPlanClone} = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    amplifiuThreadCreation: (user_id,form_json) => dispatch(amplifiuThreadCreation(user_id,form_json)),
    amplifiuFetchThreadDocumentListing: (limit,last_id,user_id,grade,subject,curriculum,duration) => dispatch(amplifiuFetchThreadDocumentListing(limit,last_id,user_id,grade,subject,curriculum,duration)),
    amplifiuSelectActiveInactive: (id,status) => dispatch(amplifiuSelectActiveInactive(id,status)),
    htmlToPDF: (html_data) => dispatch(htmlToPDF(html_data)),
    amplifiuLessonPlanClone: (id,thread_id,openai_userthread_id,user_id) => dispatch(amplifiuLessonPlanClone(id,thread_id,openai_userthread_id,user_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);