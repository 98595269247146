import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import DifficultyMeter from '../../public/icons/difficulty-meter.svg';
import Correct from '../../public/icons/correct.svg';
import Attempted from '../../public/icons/attempted.svg';
import AvgTime from '../../public/icons/avg-time.svg';
import Score from '../../public/icons/score.svg';
import Pin from '../../public/icons/pin.svg';
import CheckSymbol from '../../public/icons/check-symbol.svg';
import VideoPlayer2 from '../../components/players/video/VideoPlayer2';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import './styles.css';

const ques_types = {
  1: 'MCQ',
  2: 'Single Integer',
  3: 'Multiple Response',
  4: 'Fill in the blanks',
  5: 'True or False',
  6: 'Subjective',
  7: 'Matching'
}

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 10,
    slidesToSlide: 10
  },
  tablet: {
    breakpoint: { max: 720, min: 590 },
    items: 10,
    slidesToSlide: 10
  },
};

const questionColors = {
  answered: "#56affc", correct: "#66bb6a", not_visited: "#FFFFFF", skipped: "#f2e4c8", wrong: "#ff8080"
}

const SlideButtons = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const { carouselState: { totalItems } } = rest;
  const lastSlide = Math.floor(totalItems / 4) + (totalItems % 4) - 1;
  if(totalItems <= 10) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="slide-buttons-xy">
      <img src={Left} className='slide-button-xy' onClick={() => previous()} alt="img"/>
      <img src={Right} className='slide-button-xy' onClick={() => next()} alt="img"/>
    </div>
  );
};

class LearnerAssignmentReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: this.props.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      no_ques: false,
      showSolution: false,
      sections: [],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      options: []
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    // this.renderMath()
    window.location.reload();
    this.getReviewData()
  }

  // componentDidUpdate() {
  //   this.renderMath()
  // }

  // renderMath() {
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node2.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node3.current
  //   ])
  // }

  getReviewData = async () => {
    const { id } = this.props.props.match.params;
    const response =  await this.props.getAssessmentReviewData(id)
    if(response !== undefined){
      response.data.sections.map(section => {
        section.questions.map((question, index) => {
          question.question_number = "Q"+(index+1);
        })
      })
      this.setState({ title: response.data.title, code: response.data.uid, 
        sections: response.data.sections, 
        question: response.data.sections[0]?.questions,
        options: response.data.sections[0]?.questions[0]?.options,
        section_title: response.data.sections[0]?.title, exerciseAttempt: response.data,
        question_title: response.data.sections[0]?.questions[0]?.title });
      this.setCurrentSectionQ(response.data.sections);
    }
    else {
      this.setState({ no_ques: true, loading: false })
    }
  }

  setCurrentSectionQ = (arr) => {
    var cng = 0;
    for(let i = 0; i < arr.length; i ++) {
      if(arr[i].questions.length > 0) {
        this.setState({ currentSection: arr[i], currentQuestion: arr[i]?.questions[0] });
        cng = 1;
        break;
      }
    }
    if(cng === 0) {
      this.setState({ no_ques: true })
    }
    this.setState({ loading: false })
  }

  getQuestionColorStatus = (analytics, actionId) => {
    var check = null;
    if(analytics.is_correct === true) {
      check = true;
    }
    else if(analytics.is_correct === false) {
      check = false
    }
    if(check === null) {
      if(actionId === 1)
        return questionColors.attempted
      else if(actionId === 2)
        return questionColors.skipped
      else
        return questionColors.not_visited
    }
    else {
      if(check === true)
        return questionColors.correct
      else
        return questionColors.wrong
    }
  }

  changeCurrentQuestion = async (sectionId, quesionId) => {
    this.moveToNext(sectionId, quesionId);
  }

  moveToNext = (sectionId, questionId) => {
    let { currentQuestion, currentSection, exerciseAttempt } = this.state;
    if(currentSection.id === sectionId){
      const questionIndex = currentSection.questions.findIndex(p => p.id === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    } 
    else {
      const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === sectionId)
      currentSection = exerciseAttempt.sections[sectionIndex]
      const questionIndex = currentSection.questions.findIndex(p => p.id === questionId)
      currentQuestion = currentSection.questions[questionIndex]
    }
    this.setState({
      currentQuestion,
      currentSection,
      showSolution: false
    })
  }

  previousSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== 0) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex - 1].id, exerciseAttempt.sections[sectionIndex - 1]?.questions[0]?.id);
    }
  }

  nextSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== exerciseAttempt.sections.length-1) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1]?.questions[0]?.id);
    }
  }

  previous = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
    if(questionIndex === 0) {
      if(sectionIndex !== 0) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex-1].id, exerciseAttempt.sections[sectionIndex-1].questions[exerciseAttempt.sections[sectionIndex-1].questions.length-1].id)
      }
    } 
    else {
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex - 1].id)
    }
  }

  next = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions
                          .findIndex(p => p.id === currentQuestion.id)
    if(questionIndex === currentSection.questions.length - 1) {
      if(sectionIndex !== exerciseAttempt.sections.length-1) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1].questions[0].id)
      }
    } else{
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex+1].id)
    }
  }

  render() {
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      showSolution,
      loading
    } = this.state;
    if(this.props.loading || loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    else if(exerciseAttempt === '' || exerciseAttempt === null || exerciseAttempt === undefined
        || this.state.no_ques === true || exerciseAttempt.sections.length === 0) {
      return (
        <div className="assessment-div">
          <span id="no-questions">No questions!</span>
        </div>
      )
    }
    else {
      return (
        <div>
          <Helmet>
            <title>Review - {this.state.title}</title>
          </Helmet>
          <div className="assessment-head">
            <div className="assessment-head1">
              <span>{this.state.title}</span>
            </div>
            <div className="assessment-head2">
              <div className="assessment-section-buttons">
                <img src={MoveLeft} alt="img" onClick={this.previousSection}/>
                {currentSection.title}
                <img src={MoveRight} alt="img" onClick={this.nextSection}/>
              </div>
              <Carousel containerClass="section-layer-tray" responsive={responsive}
                keyBoardControl={false} arrows={false} swipeable={false} draggable={false}
                customButtonGroup={<SlideButtons/>} renderButtonGroupOutside={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}>
                {currentSection.questions !== undefined 
                  && currentSection.questions.map((question, index) => 
                  <div className="sec-ques-div" key={`${question.id}_${index}`}
                    onClick={()=>this.changeCurrentQuestion(currentSection.id, question.id)}
                    style={{backgroundColor: this.getQuestionColorStatus(question.analytics, question.analytics.action_id)}}>
                    {index !== 0 && <hr className="sec-line-one"/>}
                    {index + 1}
                    {(index !== (currentSection.questions.length - 1)) && <hr className="sec-line-two"/>}
                  </div>
                )}
              </Carousel>
            </div>
            <div className="assessment-head4">
              <Link to={{pathname: `/learner/scorecard/${this.state.id}`,
                state: {contentId: this.state.id}}}>
                <button>
                  Scorecard
                </button>
              </Link>
              <button id="button-reverse" onClick={() => this.props.props.history.goBack()}>
                Exit Review
              </button>
            </div>
          </div>
          {currentSection.questions.length > 0 ? <div className="assessment-main">
            <div className="assessment-ques">
              <div className="subject">{currentSection.title}</div>
              <div className="time-remaining">
                <span className="question_no">{currentQuestion?.question_number}</span>
                <div className="ques_type_prev">
                  {ques_types[currentQuestion?.question_type]}
                </div>
              </div>
              <p ref={this.node} 
                dangerouslySetInnerHTML={{__html: currentQuestion?.statement}} />
            </div>
            <div className="assessment-options">
              {currentQuestion.analytics.is_correct === true ? <>
                  {currentQuestion.marks !== 0 ?
                    <div className="correct-answer">
                      <div><img src={CheckSymbol} alt="correct-answer" /> Correct</div>
                      <div>{currentQuestion.analytics.score}/{currentQuestion.marks}</div>
                    </div> :
                    <div className="correct-answer">
                      <div><img src={CheckSymbol} alt="correct-answer" /> Correct</div>
                    </div>
                  }
                </> : currentQuestion.analytics.is_correct === false ? <>
                  { currentQuestion.marks !== 0 ?
                    <div className="incorrect-answer">
                      <div>X Incorrect</div>
                      <div>{currentQuestion.analytics.score}/{currentQuestion.marks}</div>
                    </div> :
                    <div className="incorrect-answer">
                      <div>X Incorrect</div>
                    </div>
                  }
                </> : null}
              {currentQuestion?.question_type_id === 6 ? <div className="subjective_sol">
                {currentQuestion?.statement}
              </div> : <>
                {currentQuestion?.options &&
                  currentQuestion?.options.map((option, index) =>
                  <div className="option-container" key={`option_${index}`}>
                    <div ref={this.node2} key={`option_${index}`}
                      className={ option.is_selected  ? "incorrect-option select-option" : "select-option"}
                      style={option.is_correct ? {borderColor: 'var(--primary)'} : {}}
                      dangerouslySetInnerHTML={{__html: option?.statement}}
                    />
                  </div>
                )}
              </>}
              {currentQuestion.solution && currentQuestion.solution.video_file !== undefined
                && !showSolution && <button onClick={()=>this.setState({showSolution: true})} 
                className="see-solution">See Solution</button>
              }
              {showSolution &&
                <div className="solution-content">
                  {currentQuestion.solution.explanation &&
                    <>
                      <span>Solution: </span><span ref={this.node3} dangerouslySetInnerHTML={{__html: currentQuestion.solution.explanation}} />
                    </>
                  }
                  {currentQuestion.solution.video_file &&
                    <VideoPlayer2 url={common.addFileUrl(currentQuestion?.solution?.video_file)}/>
                  }
                </div>
              }
            </div>
          </div> : <span id="no-questions">No questions!</span>}
          <div className="assessment-bottom2">
            <div className="assessment-bottom-left">
              {!isEmpty(this.state.currentQuestion.analytics) && <div className="dpp-details">
                <div className="dpp-difficulty">
                  <span>
                    <img src={DifficultyMeter} alt="img"/>
                    <img className={`${this.state.currentQuestion.analytics.difficulty_level}-pin`} src={Pin} alt="img"/>
                  </span>
                  <div className="dpp-difficult">
                    Difficulty:<p>{this.state.currentQuestion.analytics.difficulty_level}</p>
                  </div>
                </div>
                <div className="dpp_average">
                  <span className="dpp_avg_icon">
                    <img src={Correct} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{this.state.currentQuestion.analytics.batch_correct_percentage}%</span>
                    Answered this correctly
                  </div>
                </div>
                <div className="dpp_average">
                  <span className="dpp_avg_icon">
                    <img src={AvgTime} alt="img"/>
                  </span>
                  <div className="dpp_avg_numbers">
                    <span>{this.state.currentQuestion.analytics.batch_avg_time}s</span>
                    Avg time taken
                  </div>
                </div>
              </div>}
            </div>
            <div className="assessment-bottom-right">
              <span onClick={this.previous}>
                Previous
              </span>
              <span onClick={this.next}>
                Next
              </span>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentReviewData } = actions;
  return {
    getAssessmentReviewData: (assessmentId) => dispatch(getAssessmentReviewData(assessmentId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerAssignmentReview);