import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications, {store} from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import ImgHolder from '../../public/icons/doubt-image.svg';
import Profile from '../../public/icons/profile.svg';
import MonthTable from '../../components/temp';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class FacultyDoubtResponses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id),
      question: this.props.location.state?.question,
      responses: [],
      open: false,
      open2: false,
      description: '',
      image: null,
      imageURL: null,
      imgShow: '',
      descriptionShow: ''
    }
  }
  
  componentDidMount() {
    this.getResponses(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({ id: parseInt(this.props.match.params.id),
                      question: this.props.location.state?.question })
      this.getResponses(this.props.match.params.id);
    }
  }

  getResponses = async (id) => {
    const response = await this.props.getFacultyDoubtResponses(id);
    this.setState({ responses: response.data.forums })
  }

  show = (val) => this.setState({ open: true, imgShow: val });
  
  close = () => this.setState({ open: false });

  show2 = () => this.setState({ open2: true, description: '', image: null, imageURL: null });
  
  close2 = () => this.setState({ open2: false });

  shows = (val) => this.setState({ opens: true, descriptionShow: val });
  
  closes = () => this.setState({ opens: false });

  handleChange = (event) => {
		if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({ 
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `Only images can be uploaded here.`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('doubt_img').value = "";
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  }

  getDateTime = (val) => {
    var startDate = new Date(val);
		var currentDate = new Date(Date.now());
		var seconds = (currentDate.getTime() - startDate.getTime()) / 1000;
		var minut = seconds / 60;
    var hours = minut / 60;
    var minut2 = startDate.getMinutes();
    var hours2 = startDate.getHours(); 
		seconds = Math.floor(seconds);
		minut = Math.floor(minut);
		hours = Math.floor(hours);
		
		var date = startDate.getDate();
		var month = startDate.getMonth() + 1;
    var year = startDate.getFullYear();
    if(seconds < 60) {
      return `just now`;
    }
    else if(minut < 60) {
      if(minut <= 1) {
        return `${minut} min ago`;
      }
      else {
        return `${minut} mins ago`;
      }
    }
    else if(hours < 24) {
      if(hours <= 1) {
        return `${hours} hr ago`;
      }
      else {
        return `${hours} hrs ago`;
      }
    }
    else {
      if(minut2 < 10) {
        minut2 = '0' + minut2;
      }
      if(hours2 < 10) {
        hours2 = '0' + hours2;
      }
      return `${MonthTable[month]} ${date}, ${year}, ${hours2}:${minut2}`;
    }
  }

  postAnswer = async () => {
    var responses = this.state.responses
    const { description, image, id } = this.state;
    const response = await this.props.postDoubt(80, description, image, id);
    if(response !== undefined) {
      responses.unshift(response.data);
    }
    this.setState({ open2: false, responses });
  }

	render() {
    const isEnabled = this.state.description.length > 0;
		return (
      <>
        <ReactNotifications/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="image">
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="image-popup">
            <img src={common.addFileUrl(this.state.imgShow)} alt="zoom"/>
          </div>
        </Popup>
        <Popup open={this.state.opens} closeOnDocumentClick onClose={this.closes}
          className="image">
          <span className="close" onClick={this.closes}>&#x2715;</span>
          <div className="description-popup">
            {this.state.descriptionShow}
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-doubt2">
          <div className="popup-ask-doubt2">
            <div className="popup-header">
              Post your answer
            </div>
            <span className="close" onClick={this.close2}>&#x2715;</span>
            <div className="ask-doubt-content">
              <div className="doubt-content-div">
                <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}
                  id="doubt_img"/>
                <div className="image-upload">
                  <span>Add an image</span>
                  {this.state.imageURL === null
                    ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput.click()}/>
                    : <div className="post-doubt-img"
                      style={{backgroundImage: "url("+this.state.imageURL+")"}}>
                      <span className="close" onClick={this.removeImage}>&#x2715;</span>
                  </div>}
                </div>
                <textarea placeholder="Start your answer..." 
                  onChange={this.onDescriptionChange}/>
              </div>
            </div>
            <div className="ask-doubt-action">
              <button disabled={!isEnabled} onClick={this.postAnswer}>
                Post
              </button>
            </div>
          </div>
        </Popup>
        <div className="app-body">
          <div className="app-div">
            <Helmet>
              <title>Doubt responses - Learner</title>
            </Helmet>
            <ReactNotifications/>
            <div className="page-data">
              <div className="single-doubt2">
                <div className="single-doubt-left">
                  <div className="doubt-by">
                    <div className="doubt-by-div">
                      {this.state.question?.user?.profile_picture.length === 0 
                        ? <div className="doubt-by-profile">
                        <img src={Profile} alt="user"/>
                      </div> : <div className="doubt-by-profile" style={{backgroundImage: 
                        `url(${common.addFileUrl(this.state.question?.user?.profile_picture)})`}}>
                      </div>}
                      <div>
                        <span className="doubt-by-name">
                          {this.state.question.user.name}
                        </span>
                        <span className="doubt-by-time">
                          {this.getDateTime(this.state.question.added_on)}
                        </span>
                      </div>
                    </div>
                    <div className="single-doubt-info">
                      <span className="single-doubt-subject">{this.state.question.subject}</span>
                    </div>
                  </div>
                  <span className="single-doubt-description"
                    onClick={() => this.shows(this.state.question.description)}>
                    {this.state.question.description}
                  </span>
                </div>
                {this.state.question.description.length > 1685 
                  ? <span id="view_more" 
                      onClick={() => this.shows(this.state.question.description)}>
                      View more</span> : null}
                {this.state.question.media === null ? null 
                  : <div title="Zoom" className="single-doubt-right" 
                    onClick={() => this.show(this.state.question.media)}
                  style={{ backgroundImage: `url(${common.addFileUrl(this.state.question.media)})`}}>
                </div>}
              </div>
              <div className="response-header">
                <h2>Responses ({this.state.responses.length})</h2>
                <button onClick={this.show2}>
                  Post
                </button>
              </div>
              <div className="responses-data">
                {this.state.responses.length === 0 && <Empty/>}
                {this.state.responses.map(response =>
                  <div className="single-response">
                    <div className="single-response-header">
                      <div className="single-response-header1">
                        {response.user.profile_picture.length === 0 ? <div
                          className="single-response-dp">
                          <img src={Profile} alt="profile"/>
                        </div> : <div className="single-response-dp" style={{backgroundImage: 
                          `url(${common.addFileUrl(response?.user?.profile_picture)})`}}>
                        </div>}
                        <span>{response.user.name}</span>
                        responded {this.getDateTime(response.added_on)}
                      </div>
                      <div className="single-response-header2">
                        {response.user.id === this.state.question.user.id
                          ? <span className="is-author">Author</span> : null}
                      </div>
                    </div>
                    <div className="single-response-content">
                      <div className="single-response-content-left">
                        <span onClick={() => this.shows(response.description)}>
                          {response.description}
                        </span>
                        {response.description.length > 315 ? <span id="view_more2" 
                          onClick={() => this.shows(response.description)}>
                            View more</span> : null}
                      </div>
                      {response.media === null ? null 
                        : <div title="Zoom" className="single-response-content-right" 
                          onClick={() => this.show(response.media)}
                        style={{ backgroundImage: `url(${common.addFileUrl(response.media)})`}}>
                      </div>}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyDoubtResponses, postDoubt } = actions;
  return {
    getFacultyDoubtResponses: (doubt_forum_id) => dispatch(getFacultyDoubtResponses(doubt_forum_id)),
    postDoubt: (subject_id, description, media, doubt_forum_id) => dispatch(postDoubt(subject_id, description, media, doubt_forum_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyDoubtResponses);