import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
// import month from '../../public/icons/month.svg';
// import idea from '../../public/icons/idea.svg';
// import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import common from '../../common';
// import InfiniteScroll from "react-infinite-scroll-component";

const lec_modes = {
  2: 'Live',
  0: 'Classroom',
  1: 'Online'
}

class StartTimer extends Component{
  constructor(props){
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    //this.second = 1000;
    //this.minute = this.second * 60;
    //this.hour = this.minute * 60;
    //this.postQuestions = []
    this.state = {
      //currentTime: props.startTime,
      differenceTime:this.props.count
    }
  }
  
  componentDidMount(){
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {
          
    let end = new Date();
    this.distance =  this.startTime - end.getTime();
    //this.distance =  this.startTime - 10 * 60 * 1000;

    if(this.distance > 0)
      this.props.onChange(this.distance,this.state.differenceTime)
  } 

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken *1000);
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? {color: '#ec6972'} : {}}>
       
      </span>
    )
  }
}

class LearnerWebinar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lectures: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      id:0,
      is_recent:0,
      current_date_records:[],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.questionTimer = React.createRef();
  }
  
  componentDidMount() {
     
    document.addEventListener('mousedown', this.handleClickOutside);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date})
   this.getCurrentDate();
   this.getStudentLectureList(date,15,'forward',true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false })
    }
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
   this.setState({start_date:date,is_recent:0,loading:true,countTop:0,lectures:[]})

   this.getCurrentDate();
   this.getStudentLectureList(date,15,'forward',true);
  }

  getStudentLectureList = async (date,days,direction,if_loading) => {
    const response = await this.props.getStudentLectureList(date,days,direction,if_loading);

    if(response){
      this.setState({lectures:response.data.lectures ? response.data.lectures.filter(p => p.is_webinar === true ):"", loading: false ,countBottom:1,id:response.data.lectures?response.data?.lectures[0]?.id:"NA"})
      this.ifRecordsExists();

      if(!response.data.lectures.length){

        this.setState({id:"NA"});
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');
        
        this.getStudentLectureListOnBackward(currentDate,15,'backward',false);
      }
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getStudentLectureListOnForward = async (date,days,direction,if_loading) => {
    const response= await this.props.getStudentLectureList(date,days,direction,if_loading);
    if(response){
      this.setState({
        lectures: this.state.lectures?this.state.lectures.concat(response.data.lectures):response.data.lectures,
        loading: false,countBottom:1
      });
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getStudentLectureListOnBackward = async (date,days,direction,if_loading) => {
    const response= await this.props.getStudentLectureList(date,days,direction,if_loading);
    if(response){
      this.setState({
        lectures: response.data.lectures?response.data.lectures.concat(this.state.lectures).filter(p => p.is_webinar === true ):this.state.lectures,
        loading: false,countTop:1
      });
      this.ifRecordsExists();
      if(this.state.id)
        this.handleScrollPos();
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date(this.state.current_date.split(' ').join('-'));

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      this.state.mlist[d.getMonth()]
    ];
    return date;
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  onChange = value => {
    var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');


    this.setState({ hasMore: false,countTop:0,current_date:calendarDate,lectures:[]});
    this.setState({open:false})

    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });
    this.setState({start_date:date,loading:true,is_recent:1})
    
    this.getStudentLectureList(date,15,'forward',true);
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ current_date: date, date: currentDate});
  }


  openCalendar=()=>{
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();

    if(calendarMonth<currentMonth){
      document.getElementsByClassName("react-calendar__navigation__next-button")[0].click();
    }else if(calendarMonth>currentMonth){
      document.getElementsByClassName("react-calendar__navigation__prev-button")[0].click();
    }
    
    if(this.state.open===true)
      this.setState({open:false})
    if(this.state.open===false)
      this.setState({open:true})
  }

  handleScroll = (e) => { 

    var scrolltop=[];
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('list-div-x2');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.lectures && this.state.lectures.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){

          this.setState({current_date:this.getTime(test.start_date_time)[4]});
        }
      });
    }

    const top = e.target.scrollTop===0;
    if(top){
      if(this.state.countTop===0){
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getStudentLectureListOnBackward(currentDate,15,'backward',false);
      }
    }
  }

  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) +' HRS':minutes +' mins';
  }

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }

  saveQuestionTime = (time_taken,difference) => {
    console.log(Math.floor(time_taken/(1000*60)))
    /*setInterval(function() {
      if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) window.location.reload();
    }, 60000);  */
     if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) {
      setTimeout(function(){ window.location.reload(true) },60000);
     }
    //
  }

  ifRecordsExists = () =>{
    var val=this.state.start_date;
    const month=this.state.mlist;

    var d = new Date(val);
    var arr=[];
    var map = {};
    var temparray=new Array();

    for(let i=d.getMonth(); i<12;i++){

      let contentDate = [
        d.getFullYear(),
        ('0' + (i+1)).slice(-2)
      ].join('-');

      this.state.lectures && this.state.lectures.some(function(test,index) {
        
        if(i <= new Date(test.start_date_time).getMonth()){
          
          map[month[i]]=new Date(contentDate).getMonth() === new Date(test.start_date_time).getMonth()
                        && new Date(contentDate).getFullYear() === new Date(test.start_date_time).getFullYear()?1:0
          let obj ={"month":month[i],"status":map[month[i]],"year":"2020"}            
          temparray.push(obj);
          return true;
        }
      });
    }
    this.setState({current_date_records:temparray})
  }
 

	render() {
    const { value } = this.state.start_date;
  
		return (
			<div className="app-body" id="appBody">
        <ReactNotifications/>
        <div className="app-div app-webinar">
          <Helmet>
            <title>Webinar - Learner</title>
          </Helmet>
          {/* <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/> */}
            <div className="page-header-session-learner">

              <div className="react-calendar-date" onClick={this.openCalendar}
                ref={this.wrapperRef}>
                {this.state.current_date}
                <img src={Calendar2} alt="cal"/>
              </div>

              {
                this.state.is_recent?
                <div className="back-to-recent" onClick={this.switchToRecent}>
                    Today
                </div>:""
              }
              
            </div>
            
            <div className="react-calendar1" Style={this.state.open?"display:block":"display:none"}
              ref={this.wrapperRef}>
              <Calendar
                onChange={this.onChange}
                value={value}
              />
            </div>
            
            {!this.state.loading && <div className="list-sticky" onScroll={this.handleScroll}>
            { this.state.lectures && this.state.lectures.length <= 2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
                
              {!this.state.lectures.length && <Empty/>}
              <div className="list-content">
                {this.state.current_date_records.map((data,index)=>
                  <div key={index}>
                    { data.status==0 ? 
                      <div><div className="dash-test-first">{data.month} {data.year}</div><div className="dash-test-no-found">No Lectures Planned</div></div>:""}
                  </div>
                )}

               
                {this.state.lectures && this.state.lectures.map((lecture,index) =>

                  <div className="list-div-x2" id={`${lecture.id}_${index}`}>
                    {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime} 
                     count={0} ref={this.questionTimer}/>}
                    <div className="dash-test-first">
                      {index === 0 ? <>
                        <span>{this.getTime(lecture.start_date_time)[0]} {this.getTime(lecture.start_date_time)[5]}</span>
                        <span>{this.getTime(lecture.start_date_time)[1]}</span>
                      </> : this.checkDateTime(lecture.start_date_time, 
                        this.state.lectures[index - 1].start_date_time) ? null : <>
                        <span>{this.getTime(lecture.start_date_time)[0]} {this.getTime(lecture.start_date_time)[5]}</span>
                        <span>{this.getTime(lecture.start_date_time)[1]}</span>
                      </>}
                    </div>
                    <div className="dash-test" id={lecture.id==this.state.id?"conter":""} key={index} 
                      Style={this.state.date==this.getTime(lecture.start_date_time)[2] && this.getTime(lecture.start_date_time)[3]?"background-color:#fff6e4":""}>
                      <div>
                        <div Style="display: inline-block;">
                          <span Style="font-size:17px">{lecture.title}</span><br/><br/>
                          <span style={{color: '#777'}}>
                            {this.getTimeAMPMFormat(new Date(lecture.start_date_time))} | {lecture.duration_hrs} Hrs
                          </span>
                        </div>
                        <div className="lec-mode-dash">
                          {lec_modes[lecture.mode]}
                        </div>
                      </div>
                      <div className="dash-test-bottom">
                        <div>
                          <div className="fac_dp_img">
                            {lecture.profile_pic !== '' && lecture.profile_pic !== null 
                              ? <div className="fac_dp" style={{backgroundImage: 
                                `url(${common.addFileUrl(lecture?.profile_pic)})`}}>  
                              </div>
                            : null}
                            <span>{lecture.faculty}</span>
                          </div>
                          {lecture.mode === 0 ? <span>Location: {lecture.url}</span> : null}
                        </div>
                        {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label 
                          !== undefined ? lecture.action_label === "Join Class" 
                          ? <a href={lecture.url} target="_blank">
                          <button>{lecture.action_label}</button>
                        </a> : <span id="action-reverse">
                          {lecture.action_label}
                        </span> : null}
                      </div>
                    </div>
                  </div> 
                )} { this.state.lectures && this.state.lectures.length>2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
                </div>
            </div>}
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentLectureList } = actions;
  return {
    getStudentLectureList: (date,days,direction,if_loading) => dispatch(getStudentLectureList(date,days,direction,if_loading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerWebinar);