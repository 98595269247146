import {
  GET_TOC_LIST,
  TOGGLE_UNIT,
  TOGGLE_CHAPTER,
  TOGGLE_TOPIC,
  SELECTED_CONTENT_ID,
  EXERCISE_ATTEMPT,
  CONTENT_NOTES_DETAIL,
  CONTENT_PREVIEW,
  CONTENT_VIDEO_DETAIL,
  CONTENT_SOLVED_EXAMPLE,
  NOTIFICATION,
  LEARNER_PROFILE,
  PROFILE
} from '../constants';

import common from  '../../common'

const initialState = {
  lessons: {},
  toc_lists: [],
  selectedContentId: null,
  exerciseAttempt: {},
  contentPreview: {},
  contentNotesDetail: {},
  contentVideoDetail: {},
  highlightId: "",
  notifications:[]
};

export default function classroomReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOC_LIST:
      return {
        ...state,
        toc_lists: common.insertExpandedFlag(action.toc_list, true)
      }
    case TOGGLE_UNIT:
      return {
        ...state,
        toc_lists: [...common.toggleExpandedFlag(state.toc_lists, action.unitId)]
      }
    case TOGGLE_CHAPTER:
      return {
        ...state,
        toc_lists: [...common.toggleExpandedFlag(state.toc_lists, action.unitId, action.chapterId)]
      }
    case TOGGLE_TOPIC:
      return {
        ...state,
        toc_lists: [...common.toggleExpandedFlag(state.toc_lists, action.unitId, action.chapterId, action.topicId)]
      }
    case SELECTED_CONTENT_ID:
      return {
        ...state,
        selectedContentId: action.selectedContentId
      }
    case CONTENT_PREVIEW:
      return {
        ...state,
        contentPreview: action.contentPreview
      }
    case CONTENT_NOTES_DETAIL:
      return {
        ...state,
        contentNotesDetail: action.contentNotesDetail
      }
    case CONTENT_VIDEO_DETAIL:
      return {
        ...state,
        contentVideoDetail: action.contentVideoDetail
      }
    case CONTENT_SOLVED_EXAMPLE:
      return {
        ...state,
        contentSolvedExample: action.contentSolvedExample
      }
    case EXERCISE_ATTEMPT:
      return {
        ...state,
        exerciseAttempt: action.exerciseAttempt
      }
    case NOTIFICATION:
      return {
        ...state,
        notifications: action.notifications
      }
    case LEARNER_PROFILE:
      return {
        ...state,
        learnerprofile: action.learnerprofile
      }
    default:
      return state;
  }
}

