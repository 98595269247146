import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import queryString from 'query-string';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import common from '../../common';
import axios from 'axios';
// import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';
class StudentiFrameSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lectures: [],
      open5:false,
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      id:0,
      is_recent:0,
      is_view_recording:0,
      lecture_id: '',
      is_attendanceURL:false,
      currentLectureURL:'',
      encrypted_url:null,
      responseData:[],
      statetoken:''
    }
    
  }
    
  componentDidMount(){
    
    console.log(this.props.match.params)
    let urltoken = this.props.match.params.token;
    let urlid = this.props.match.params.id;
    console.log(urltoken) // "foo"
    console.log(urlid) // "foo"
    if(urltoken){
      //this.validatePage(urltoken,urlid);
      //this.callprofileAPI(urltoken);
      axios.get(`${common.apiBase}${common.callprofileApI}`,
      {headers: {
        'Authorization': 'token ' + urltoken,
        'Content-Type':'application/json'
      }
      }).then(res => {
        if(res.data.status == 1) {
          ///alert("dfdf");
          console.log("Response Data status", res);
          console.log("Response Data id", res.data.data.id);
          console.log("status", res.data.status);
          this.setState({userID: res.data.data.id});
          this.setState({statetoken:urltoken});
          this.zoomAttendance(urlid);
        }
      }).catch(err => {
        store.addNotification({
          title: 'Error',
          message: `${err.message}`,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        })
         window.location.href='/login';
      })
    }
        
  }

  
  getLectureDatabyID = async (urlid) =>{
    let {statetoken} = this.state
    if(statetoken){
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + statetoken,
      }
      var data = {
        'lecture_id':parseInt(urlid) ,
      }
      axios.post(`${common.apiBase}${common.getLectureDatabyID}`,data,
       {
        headers: headers,
       }).then(response => {
        console.log("dddd",response.data);
        console.log("status",response.data.status);
        if(response.data.status == 1) {
          console.log("getLectureDatabyID", response.data);
          this.setState({responseData:response.data.data});
          console.log("openURL", response.data.data.open_url);
          if(response.data.data.open_url !== null && response.data.data.open_url !== ''){
            console.log("if", response.data.data.open_url);
            this.setState({is_attendanceURL:response.data.data.open_url})
            this.setState({loading:false})
          }else{
            console.log("else", response.data.data.open_url)
            this.setState({loading:false});
            //alert("URL Not Found");
            ///this.props.history.push('/learner/sessions');
          }
        }
      }).catch(err => {
        store.addNotification({
          title: 'Error',
          message: `${err.message}`,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000
          }
        })
        window.location.href='/login';
      })
    }
    }
    


  zoomAttendance = (urlid) =>{
    console.log("dsfdsfdsfdsfsdsd")
    let urlID = urlid;
    let {lecture_id,userID} = this.state;
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    console.log("dateTime", dateTime)
    console.log("lecture_id", urlID)
    console.log("userID", userID);

    if(urlID,userID,dateTime){
      this.zoomAttendanceAPI(urlID,userID,dateTime);
    }
    
  }
 

  zoomAttendanceAPI = async (lecture_id,user_id,join_time) =>{
    const response = await this.props.callzoomAttendanceAPI(lecture_id,user_id,join_time);
    if(response !== undefined && response.status==1) {
      this.getLectureDatabyID(lecture_id)
    }

   }
  
   closeIframe = () =>{
    let {responseData,is_attendanceURL} = this.state;
    this.setState({is_attendanceURL:false});
    this.props.history.push('/learner/sessions')
   }


	render() {
  const {loading} =this.state;
    if(loading) {
      return (
        <div className="assessment-div zIndexchange">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
  
		return (
      <>
      <Helmet>
      <title>Lectures - Live Class</title>
    </Helmet>
      {this.state.is_attendanceURL?
      <div className={this.state.is_attendanceURL ? "IFrameDialogWrapper open" : 'IFrameDialogWrapper'}>
          <div className="IFrameDialogContainer">
          <a href="#" onClick={this.closeIframe} title="close" className="iframeClose">X</a>
          <iframe name="iframe_a" src={this.state.is_attendanceURL} sandbox="allow-same-origin allow-forms allow-scripts" allow="microphone; camera">

          </iframe>
        </div>
      </div>
      : <div class="ErrorMSG">URL Not Found Please try again<a onClick={this.closeIframe} href="#">Back</a></div>}
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const {encryption,callzoomAttendanceAPI,getLectureDatabyID,callprofileApI} = actions;
  return {
    encryption : (username) => dispatch(encryption(username)),
    callzoomAttendanceAPI: (lecture_id,user_id,join_time) => dispatch(callzoomAttendanceAPI(lecture_id,user_id,join_time)),
    getLectureDatabyID: (lecture_id) => dispatch(getLectureDatabyID(lecture_id)),
    callprofileApI: (token) => dispatch(callprofileApI(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentiFrameSessions);