import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import common from '../../common';
import axios from 'axios';
import { store } from 'react-notifications-component';
import qrcode from '../../public/images/common-footerQR.png';
import scancodebanner from '../../public/images/scancode-banner.png';
import googleplayicon from '../../public/images/googleplay-store.svgz'
import appleplayicon from '../../public/images/appleplay-store.png'
import activateUserBanner from '../../public/images/activate-user-banner.png'
class ActivateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data : [],
      user_id : '',
      password:'',
      confirmpassword:'',
      successresponse:[],
      errorMsg:false,
      successMsg:false,
      notFound:false,
      defaultLoader:true,
      is_disabled:false,
      nextDialog:false,

  }
    
  }
    
  componentDidMount(){
    console.log(this.props.match.params)
    let secret = this.props.match.params.secret;
    if(secret){
      this.createLeadUser(secret);
     }
  }


  createLeadUser = async (secret) =>{
    console.log("secret",secret)
    const response = await this.props.createLeadUser(secret);
    if(response !== undefined && response.status==1) {
       this.setState({data:response.data});
       this.setState({user_id:response.data.id});
    }else{
      this.setState({notFound:true});
      this.setState({defaultLoader:false});
    }
    
   }

   passwordChange = (e) => {
    this.setState({ password: e.target.value })
   }

   confirmPasswordChange = (e) => {
    this.setState({ confirmpassword: e.target.value })
   }

   resetPasswordActivateUser = async (user_id,password,update_status) =>{
    const response = await this.props.resetPasswordActivateUser(user_id,password,update_status);
    if(response !== undefined && response.status==1) {
      this.setState({successresponse:response});
      console.log(this.state.successresponse);
      this.setState({successMsg:true});
      this.setState({errorMsg:false});
      this.setState({nextDialog:true});
      //window.location.href='/login';
      
    }
   }

   submitData = () => {
    let {password,confirmpassword,user_id} = this.state;
     console.log("password", password)
     console.log("Confirm password", confirmpassword);
     if(password === confirmpassword){
       console.log("match");
       this.resetPasswordActivateUser(user_id,password,1);
    }else{
      console.log("not match");
      this.setState({errorMsg:true});
      this.setState({successMsg:false});
     }

   }

   

	render() {
    return (
      <>
      <Helmet>
      <title>Activate User - My Coach</title>
    </Helmet>
     {this.state.nextDialog &&
    <div className="nextDialogWrapper">
          <div className="nextDialogContainer">
           <div class="scancodeContainer">
             <div class="left-section">
               <h2>Your password has successful generated.</h2>
               <h3>Use your email id and password to login on ImaginXP App </h3>
               </div>
               <div class="right-section">
                <img src={activateUserBanner}/>
               </div>
               </div>
               <div className='scancode-section'>
                 <h2>Download App, Login and Start Participating</h2>
                 <div class="qr-code-section">
                     <div className='right-section'>
                      <div class="app-store-links">
					              <div class="playstore-btn">
						              <a href="https://play.google.com/store/apps/details?id=com.imaginxp&referrer=utm_source%3Dk12%26utm_medium%3Demail%26utm_campaign%3Dk12future_skills_test" target="_blank"><img src={googleplayicon} alt="google play" /></a>
					             </div>	
                       <div className='or-sep'>OR</div>
                       {/* <p>login on browser through this link: <a href="https://imaginxp.live/app/login" target="_blank">https://imaginxp.live/app/login</a></p> */}
                       <div class="playstore-btn">
						              <a href="https://apps.apple.com/in/app/imaginxp-mycoach/id1577929243?id=com.imaginxp&referrer=utm_source%3Dk12%26utm_medium%3Demail%26utm_campaign%3Dk12future_skills_test" target="_blank"><img src={appleplayicon} Style="max-width:145px" alt="Apple play store" /></a>
					             </div>	
			              	</div>
                      </div>
                      <div className='orsep'>OR</div>
                     <div className='left-section'>
				              <img src={qrcode}/>
                      <p>Scan this QR code to download the app</p>
                      </div>
			             </div>
               </div>
             </div>
        </div>
        }

    {this.state.notFound && !this.state.nextDialog &&
        <div className="notFoundDialogWrapper">
            <div className="notFoundDialogContainer">
               Your link is not valid 
            </div>
          </div>
      }
      {this.state.defaultLoader && !this.state.nextDialog && !this.state.user_id &&
        <div className="defaultDialogWrapper">
          <div className="defaultDialogContainer">
              <div className="admin-loader"></div>
              Please wait till your profile is activating... 
          </div>
        </div>
    }

      {this.state.data && this.state.user_id && !this.state.nextDialog &&
        <div className="passwordDialogWrapper">
          <div className="passwordDialogContainer">
                <h2>Create Your Password</h2>
                {this.state.errorMsg && <div className="errorMsg">Please correct your password</div>}
                {this.state.successMsg && <div className="successMsg">Congratulation!</div>}
                <div className="formBlock">
                  <div className="block"><input placeholder="Enter password" type="password" onChange={this.passwordChange}/></div>
                  <div className="block"><input placeholder="Enter confirm password" type="password" onChange={this.confirmPasswordChange}/></div>
                  <div className="actions"><button disabled={!this.state.password || !this.state.confirmpassword}  onClick={this.submitData}>Submit</button></div>
                </div>
          </div>
        </div>
        }
      
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const {createLeadUser,resetPasswordActivateUser} = actions;
  return {
    createLeadUser: (secret) => dispatch(createLeadUser(secret)),
    resetPasswordActivateUser: (user_id,password,update_status) => dispatch(resetPasswordActivateUser(user_id,password,update_status))
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUser);