import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';
import './styles.css';

class SettingsFaculty extends Component {
  constructor(props) {
    super(props);
    this.state = {
			mock: false
    }
	}

	render() {
		return (
			<div className="app-div">
        <Helmet>
          <title>Settings - Faculty</title>
        </Helmet>
        <ReactNotifications/>
        Lets go
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsFaculty);