import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Listing from '../../public/icons/listing.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';


const levels =[0,1,2,3,4,5,6,7,8,9,10,11,12];

class Classes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
			open: false,
      open2: false,
      open3: false,
      opend: false,
      class_id: null,
      filterClass: null,
      name: '',
      code: '',
      order: '',
      level:'',
      description: '',
      branch: parseInt(localStorage.getItem('center_id')),
      branches: [],
      center_id: localStorage.getItem('center_id'),
			list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      classesFilter: [],
      is_faculty_admin:false,
      classtag:''
    }
  }

  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getCenterList();
    this.getClassList();
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    this.getUrlfromCurrentPage();
  }


  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
  }

  show = () => this.setState({ open: true, center_id: localStorage.getItem('center_id'),
                              name: '', code: '', order: '', description: '', classtag:'', 
                              branch: parseInt(this.state.center_id),level:''});

  close = () => this.setState({ open: false });

  show2 = (val) => {
    this.setState({ open2: true, name: val.label, code: val.short_code, order: val.order,
                      description: val.description, center_id: val.center, class_id: val.id,
                      branch: val.center,level:val.level,classtag:val.tag});
  }

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  getCenterList = async () => {
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
    }
  }

	getClassList = async () => {
    const response = await this.props.getClassList();
    if(response !== undefined) {
      this.setState({ classes: response.data, classesFilter: response.data });
    }
  }

	changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  descriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  orderChange = (e) => {
    const order = (e.target.validity.valid) ? e.target.value : this.state.order;
    this.setState({ order })
  }

  levelChange = (e) =>{
    const level = (e.target.validity.valid) ? e.target.value : this.state.level;

    this.setState({ level })
  }

  onBranchChange = (e) => {
    if(e.target.value === 'Select center') {
      this.setState({ branch: null })
    }
    else {
      this.setState({ branch: e.target.value });
    }
  }

  centerChange = (e) => {
    if(e.target.value === 'Filter by Center') {
      this.setState({ filterClass: null });
      this.setState({ classesFilter: this.state.classes });
    }
    else {
      this.setState({ filterClass: e.target.value });
      var arr = this.state.classes.filter(classs => classs.center === parseInt(e.target.value));
      this.setState({ classesFilter: arr });
    }
  }

  newClass = async () => {
    const { name, description, code, branch, order,level,classtag} = this.state;
    await this.props.createClass(name, description, code, branch, order,level,classtag);
    this.setState({ open: false });
  }

  editCenter = async () => {
    const { branch, class_id, name, description, code, order,level,classtag} = this.state;
    await this.props.updateClass(branch, class_id, name, description, code, order,level,classtag);
    this.setState({ open2: false });
  }

  deleteClass = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteClass(d_id, center_id);
    this.setState({ opend: false });
  }

  classTagsChange = (e) =>{
    let classTagData = e.target.value;
    this.setState({classtag:classTagData})
    console.log(this.state.classtag);
  }

	render() {

    const isEnabled = this.state.name.length > 0 && this.state.code.length > 0 &&
            this.state.description.length > 0 && (this.state.order + '').length > 0
            && this.state.branch !== null && this.state.level != '' && this.state.classtag != '';
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search classes"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.classes}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.classes}</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search classes"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content">
          <div className="popup-header">
           {this.props.pagewiseLanguageData.add_new_class}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data newdesign">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.class_name} onChange={this.nameChange}/>
              <input placeholder={this.props.pagewiseLanguageData.class_code} onChange={this.codeChange}/>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.description} onChange={this.descriptionChange}/>
              <input type="text" pattern="[0-9]*" placeholder={this.props.pagewiseLanguageData.class_order}
                onInput={this.orderChange} value={this.state.order}/>
            </div>
            <div className="popup-inputs">
              <select onChange={this.onBranchChange} defaultValue={this.state.branch}>
                <option value={null}>
                  {this.props.pagewiseLanguageData.select_center}
                </option>
                {this.state.branches.map(branch =>
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                )}
              </select>
              <select onChange={this.levelChange} defaultValue={this.state.level}>
                <option value="">
                 {this.props.pagewiseLanguageData.select_level}
                </option>
                {levels.map(level =>
                  <option key={level} value={level}>
                    {level == 0 ? this.props.pagewiseLanguageData.other:level}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.class_tags} className="classtags" onChange={this.classTagsChange}/>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newClass}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="popup-content">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_class}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data newdesign">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.class_name} onChange={this.nameChange}
                value={this.state.name}/>
              <input placeholder={this.props.pagewiseLanguageData.class_code} onChange={this.codeChange}
                value={this.state.code}/>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.description} onChange={this.descriptionChange}
                value={this.state.description}/>
              <input type="text" placeholder={this.props.pagewiseLanguageData.class_order} onInput={this.orderChange}
                value={this.state.order} pattern="[0-9]*"/>
            </div>
            <div className="popup-inputs">
              <select defaultValue={this.state.branch}>
                <option value={null} disabled>
                  {this.props.pagewiseLanguageData.select_center}
                </option>
                {this.state.branches.map(branch =>
                  <option key={branch.id} value={branch.id}
                    style={branch.id !== this.state.branch ? {display: 'none'} : {}}>
                    {branch.name}
                  </option>
                )}
              </select>
              <select onChange={this.levelChange} defaultValue={this.state.level}>
                <option value="">
                  {this.props.pagewiseLanguageData.select_level}
                </option>
                {levels.map(level =>
                  <option key={level} value={level} selected={level == this.state.level ?"selected":""}>
                    {level}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
              <input placeholder="Class Tags" defaultValue={this.state.classtag} className="classtags" onChange={this.classTagsChange}/>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.editCenter}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.are_you_sure_you_want_to_delete_this_class}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteClass}>
              {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
					<div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
								<h1>{this.props.pagewiseLanguageData.classes}</h1>
							</div>
						</div>
						<div className="heading-part2">
							{!this.state.is_faculty_admin && <span onClick={this.show}>
								<img src={Add} alt={this.props.pagewiseLanguageData.list}/>{this.props.pagewiseLanguageData.add_new}
							</span>}
							{!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt={this.props.pagewiseLanguageData.list}/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt={this.props.pagewiseLanguageData.list}/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
						</div>
					</div>
          <div className="sub-filter">
            {!this.state.is_faculty_admin && <select value={this.state.filterClass} onChange={this.centerChange}>
              <option value={null}>{this.props.pagewiseLanguageData.filter_by_center}</option>
              {this.state.branches.map(branch =>
                <option key={branch.id} value={branch.id}>{branch.name}</option>
              )}
            </select>}
          </div>
          {this.state.classesFilter.length === 0 ? <><span id="no-data-admin">
               {this.props.pagewiseLanguageData.no_class_or_subject_is_mapped_yet}
              </span> <Empty/></> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
              {this.props.pagewiseLanguageData.name}
              </div>
              <div className="list-section">
              {this.props.pagewiseLanguageData.code}
              </div>
              <div className="list-class">
              {this.props.pagewiseLanguageData.description}
              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.center}
              </div>
              {!this.state.is_faculty_admin &&<div className="list-action2">
              {this.props.pagewiseLanguageData.actions}
              </div>}
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.classesFilter.map((classs, index) =>
                <div className="list-item" key={classs.id}>
                  <div className="title-item">
                    {classs.label}
                  </div>
                  <div className="section-item">
                    {classs.short_code}
                  </div>
                  <div className="class-item">
                    {classs.description}
                  </div>
                  <div className="faculty-item">
                    {classs.center_details.name}
                  </div>
                  <div className="action-item2">
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(classs)}>
                      <img src={Edit} alt= {this.props.pagewiseLanguageData.edit}/>
                    </span>}
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(classs.id)}>
                      <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                    </span>}
                    <Link id="section-button2" to={{ pathname: `/admin/subjects/${classs.id}`,
                      state: {class_label: classs.label, center_id: classs.center} }} onClick={this.changeTab}>
                      <img src={Listing} alt={this.props.pagewiseLanguageData.list}/>
                      {this.props.pagewiseLanguageData.subjects}
                    </Link>
                    <Link id="section-button2" to={{ pathname: `/admin/class-sections/${classs.id}`,
                      state: {class_label: classs.label, center_id: classs.center} }}
                      onClick={this.changeTab}>
                      <img src={Listing} alt={this.props.pagewiseLanguageData.list}/>
                      {this.props.pagewiseLanguageData.sections}
                    </Link>
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.classesFilter.map((classs, index) =>
                <div className="grid-item" key={classs.id}>
                  <div className="grid-up grid-up-v1">
                    <div className="title-grid">
                      {classs.label}
                    </div>
                    <div className="grid-actions">
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(classs)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>}
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(classs.id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                      </span>}
                    </div>
                  </div>
                  <div className="grid-up grid-up-v1">
                    <div className="grid-sec">
                    {this.props.pagewiseLanguageData.code}
                      <span>{classs.short_code}</span>
                    </div>
                    <div className="grid-sec2">
                      <Link id="section-button2" to={{ pathname: `/admin/subjects/${classs.id}`,
                        state: {class_label: classs.label, center_id: classs.center} }} onClick={this.changeTab}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.list}/>
                        {this.props.pagewiseLanguageData.subjects}
                      </Link>
                      <Link id="section-button2" to={{ pathname: `/admin/class-sections/${classs.id}`,
                        state: {class_label: classs.label, center_id: classs.center} }}
                        onClick={this.changeTab}>
                        <img src={Listing} alt={this.props.pagewiseLanguageData.list}/>
                        {this.props.pagewiseLanguageData.sections}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>}
          </>}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage, createSubject, createClass, updateClass, getClassList,
          deleteClass, getCenterList,getPageLanguageWiseData} = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    createClass: (label, description, short_code, center, order,level,tag) => dispatch(createClass(label, description, short_code, center, order,level,tag)),
    createSubject: (label, short_code, classs, master_subject, background_code, image, center_id) => dispatch(createSubject(label, short_code, classs, master_subject, background_code, image, center_id)),
    updateClass: (center, class_id, name, description, code, order,level,tag) => dispatch(updateClass(center, class_id, name, description, code, order,level,tag)),
    getClassList: () => dispatch(getClassList()),
    getCenterList: () => dispatch(getCenterList()),
    deleteClass: (id, center_id) => dispatch(deleteClass(id, center_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Classes);
