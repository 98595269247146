import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import queryString from 'query-string';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import common from '../../common';
// import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from 'react-router-dom';

class StartTimer extends Component{
  constructor(props){
    super(props)
    this.startTime = (new Date(this.props.startTime)).getTime();
    //this.second = 1000;
    //this.minute = this.second * 60;
    //this.hour = this.minute * 60;
    //this.postQuestions = []
    this.state = {
      //currentTime: props.startTime,
      differenceTime:this.props.count
    }
  }
  
  componentDidMount(){
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  getCurrentTime = () => {
          
    let end = new Date();
    this.distance =  this.startTime - end.getTime();
    //this.distance =  this.startTime - 10 * 60 * 1000;

    if(this.distance > 0)
      this.props.onChange(this.distance,this.state.differenceTime)
  } 

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken *1000);
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  render() {
    return (
      <span className="timer" style={this.props.notify > 0 ? {color: '#ec6972'} : {}}>
       
      </span>
    )
  }
}

class StudentSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lectures: [],
      open5:false,
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      center_id:localStorage.getItem('center_id'),
      id:0,
      is_recent:0,
      is_view_recording:0,
      current_date_records:[],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      username: localStorage.getItem("username"),
      userID: localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      is_attendanceURL:false,
      currentLectureURL:'',
      encrypted_url:null,
      recordingActionLabel:false
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.questionTimer = React.createRef();
  }
  
  componentDidMount() {

    document.addEventListener('mousedown', this.handleClickOutside);
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

   this.setState({start_date:date})
   this.getCurrentDate();
   this.getStudentLectureList(date,15,'forward',true);
  }

  componentWillUnmount() {

    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false })
    }
  }

  switchToRecent=()=>{
    var d = new Date();
    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');
   this.setState({start_date:date,is_recent:0,loading:true,countTop:0,lectures:[]})

   this.getCurrentDate();
   this.getStudentLectureList(date,15,'forward',true);
  }

  getStudentLectureList = async (date,days,direction,if_loading) => {
    const response = await this.props.getStudentLectureList(date,days,direction,if_loading);

    if(response){
      const searchId=queryString.parse(this.props.location.search);
      if(searchId.id){

        const index = response.data.lectures.findIndex(p => parseInt(p.id) === parseInt(searchId.id))
        if(response.data.lectures[index]) response.data.lectures[index].style= {border: "3px solid #49c749"}
       
      }
      this.setState({lectures:response.data.lectures ? response.data.lectures:"", loading: false ,countBottom:1,id:response.data.lectures?response.data?.lectures[0]?.id:"NA"})
      this.ifRecordsExists();

      if(!response.data.lectures.length){

        this.setState({id:"NA"});
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');
        
        this.getStudentLectureListOnBackward(currentDate,15,'backward',false);
      }else{
        this.handleScrollPos();
      }

    }
    else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getStudentLectureListOnForward = async (date,days,direction,if_loading) => {
    const response= await this.props.getStudentLectureList(date,days,direction,if_loading);
    if(response){
      this.setState({
        lectures: this.state.lectures?this.state.lectures.concat(response.data.lectures):response.data.lectures,
        loading: false,countBottom:1
      });
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getStudentLectureListOnBackward = async (date,days,direction,if_loading) => {
    const response= await this.props.getStudentLectureList(date,days,direction,if_loading);
    if(response){
      const searchId=queryString.parse(this.props.location.search);
      if(searchId.id){

        const index = response.data.lectures.concat(this.state.lectures).findIndex(p => parseInt(p.id) === parseInt(searchId.id))
        response.data.lectures.concat(this.state.lectures)[index].style= {border: "3px solid #49c749"}
       
      }
      this.setState({
        lectures: response.data.lectures?response.data.lectures.concat(this.state.lectures):this.state.lectures,
        loading: false,countTop:1
      });
      this.ifRecordsExists();
      if(this.state.id)
        this.handleScrollPos();
    }else{
      this.setState({hasMore:false,loading: false})
    }
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date(this.state.current_date.split(' ').join('-'));

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      this.state.mlist[d.getMonth()]
    ];
    return date;
  }

  checkDateTime = (d1, d2) => {
    var d1 = new Date(d1);
    var d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
          d1.getMonth() === d2.getMonth() &&
          d1.getDate() === d2.getDate();
  }

  getTimeAMPMFormat = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours; // appending zero in the start if hours less than 10
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  onChange = value => {
    var d = new Date(value);

    let date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let calendarDate = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date();
    let nowdate = [
      this.state.mlist[cd.getMonth()],
      cd.getFullYear()
    ].join('-');

    var dateselected = new Date(value);

    let selecteddate = [
      this.state.mlist[dateselected.getMonth()],
      dateselected.getFullYear()
    ].join('-');


    this.setState({ hasMore: false,countTop:0,current_date:calendarDate,lectures:[]});
    this.setState({open:false})

    this.setState({ is_recent: new Date().getDate() === new Date(selecteddate).getDate() 
                      && new Date().getMonth() === new Date(selecteddate).getMonth()
                      && new Date().getFullYear() === new Date(selecteddate).getFullYear() 
                        ? 0 : 1 });
    this.setState({start_date:date,loading:true,is_recent:1})
    
    this.getStudentLectureList(date,15,'forward',true);
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ current_date: date, date: currentDate});
  }


  openCalendar=()=>{
    var el = document.getElementsByClassName("app-div")[0];
    if(el){
      el.scrollTop = 0;
      el.scrollTop += 100;
    } 
    var node = document.getElementsByClassName('react-calendar__navigation__label__labelText--from')[0];
    var textContent = node.textContent.split(" ").join("-");
    
    var cd = new Date(this.state.current_date.split(" ").join("-"));
    var dateselected = new Date(textContent);

    var calendarMonth=dateselected.getMonth();
    var currentMonth=cd.getMonth();

    if(calendarMonth<currentMonth){
      document.getElementsByClassName("react-calendar__navigation__next-button")[0].click();
    }else if(calendarMonth>currentMonth){
      document.getElementsByClassName("react-calendar__navigation__prev-button")[0].click();
    }
    
    if(this.state.open===true)
      this.setState({open:false})
    if(this.state.open===false)
      this.setState({open:true})
  }

  handleScroll = (e) => { 

    var scrolltop=[];
    this.setState({is_recent:1})
    const items = document.getElementsByClassName('list-div-x2');
    const visible = [...items].filter((el) => {
      return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    });

    if(!isNaN(e.target.scrollTop)){
      this.state.lectures && this.state.lectures.map((test,index)=>{
        if(visible[index].offsetTop<=e.target.scrollTop){

          this.setState({current_date:this.getTime(test.start_date_time)[4]});
        }
      });
    }

    const top = e.target.scrollTop===0;
    if(top){
      if(this.state.countTop===0){
        const yesterday = new Date(this.state.start_date)
        yesterday.setDate(yesterday.getDate() - 1);

        var d = new Date(yesterday);

        let currentDate = [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-');

        this.getStudentLectureListOnBackward(currentDate,15,'backward',false);
      }
    }
  }

  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) +' HRS':minutes +' mins';
  }

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }

  saveQuestionTime = (time_taken,difference) => {
    console.log(Math.floor(time_taken/(1000*60)))
    /*setInterval(function() {
      if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) window.location.reload();
    }, 60000);  */
     if(Math.floor(time_taken/(1000*60)) * 60 * 1000 == 10 * 60 * 1000) {
      setTimeout(function(){ window.location.reload(true) },60000);
     }
    //
  }

  ifRecordsExists = () =>{
    var val=this.state.start_date;
    const month=this.state.mlist;

    var d = new Date(val);
    var arr=[];
    var map = {};
    var temparray=new Array();

    for(let i=d.getMonth(); i<12;i++){

      let contentDate = [
        d.getFullYear(),
        ('0' + (i+1)).slice(-2)
      ].join('-');

      this.state.lectures && this.state.lectures.some(function(test,index) {
        
        if(i <= new Date(test.start_date_time).getMonth()){
          
          map[month[i]]=new Date(contentDate).getMonth() === new Date(test.start_date_time).getMonth()
                        && new Date(contentDate).getFullYear() === new Date(test.start_date_time).getFullYear()?1:0
          let obj ={"month":month[i],"status":map[month[i]],"year":"2020"}            
          temparray.push(obj);
          return true;
        }
      });
    }
    this.setState({current_date_records:temparray})
  }


  createContent = async (id) => {
    this.setState({loading :true})
    const response = await this.props.encryption(this.state.username)
    
    if(response != undefined){
      this.setState({encrypted_url: response.data?.encrypted_text+'&class_id='+id,open5 :true ,loading:false})
      //document.getElementById("appBody").style.position="unset";
      document.getElementsByClassName("header2")[0].style.position="unset";
    }
    
  }

  close5 =() => {
    this.setState({open5 :false})
    document.getElementsByClassName("header2")[0].style.position="fixed";
  }

  onSchedule = (val,id) => {
    this.timer = setInterval(()=>this.scheduledOn(new Date(val),id), 1000)
  }

  scheduledOn = (val,due,id) =>{
    let dt1 = new Date();
    let currentTime='';
    var diff = val.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    diff = Math.round(diff);
    if(diff <= 0 ) {
      currentTime = val.getTime();
      this.timer = setInterval(()=>this.scheduledOn1(new Date() ,currentTime,id), 1000)
      //setTimeout(function(){  window.location.reload(true) },60000);
    }
    //console.log("onsched"+diff)
  }

  scheduledOn1 = (val1,currentTime,id) =>{
    var diff = val1.getTime()/(1000 * 60) - currentTime/(1000 * 60);
    diff = Math.round(diff);
    if(diff >= 15 ) {
      clearInterval(this.timer);
      this.setState({is_view_recording: 1});
      clearInterval(this.timer);
      //setTimeout(function(){  window.location.reload(true) },60000);
    }
    //console.log("onsched7878"+diff)
  }

  bbbJoinLiveClass = async (etc,session) =>{

    const {name,user_id} = this.state

    let etcObject = JSON.parse(etc);

   const response = await this.props.bbbJoinLiveClass(session,name,etcObject.moderatorPasswordBBB,user_id)
   console.log(response)
   if(response != undefined){
    window.open(response.data?.liveClassUrl, '_blank').focus();
   }
  }


  // zoomAttendance = (lectureURL) =>{
  //   let {id,userID,currentLectureURL} = this.state;
  //   this.setState({currentLectureURL:lectureURL});
  //   var today = new Date();
  //   var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  //   var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  //   var dateTime = date+' '+time;
  //   console.log("id", dateTime)
  //   console.log("userID", userID)
  //   console.log("dateTime", dateTime)
  //   console.log("currentLectureURL", currentLectureURL)
  //   if(id,userID,dateTime){
  //     this.zoomAttendanceAPI(id,userID,dateTime);
  //   }
    
  // }

  zoomAttendance = (lectureURL,lectureid) =>{
    console.log("lectureid")
    console.log(lectureid)
    let {id,userID,currentLectureURL} = this.state;
    this.setState({currentLectureURL:lectureURL});
    this.setState({id:lectureid});
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    console.log("id", dateTime)
    console.log("userID", userID)
    console.log("dateTime", dateTime)
    console.log("currentLectureURL", currentLectureURL)
    if(id,userID,dateTime){
      this.zoomAttendanceAPI(lectureid,userID,dateTime);
    }
    
  }
 

  // zoomAttendanceAPI = async (lecture_id,user_id,join_time) =>{
  //   const response = await this.props.callzoomAttendanceAPI(lecture_id,user_id,join_time);
   
  //     this.setState({is_attendanceURL:false});
  //     console.log("attendanceURL", this.state.is_attendanceURL);
  //     console.log("currentLectureURL", this.state.currentLectureURL);
  //     window.open(this.state.currentLectureURL,"_blank");
  //   }

  zoomAttendanceAPI = async (lecture_id,user_id,join_time) =>{
    let {recordingActionLabel} = this.state;
    
    const response = await this.props.callzoomAttendanceAPI(lecture_id,user_id,join_time, localStorage.getItem('viewrecordingButtoncLick') ? "recording" : 'attendance');
   
      this.setState({is_attendanceURL:false});
      console.log("attendanceURL", this.state.is_attendanceURL);
      console.log("currentLectureURL", this.state.currentLectureURL);
      console.log("responsesssssss",response)
      console.log("response.data.url",response?.data?.url)
      localStorage.removeItem('viewrecordingButtoncLick');
      if(response !== undefined || response?.status == 1){
        //window.open(response.data.url,"_blank");
      }
      //window.open(this.state.currentLectureURL,"_blank");
    }

    
  
   closeIframe = () =>{
    let {is_attendanceURL} = this.state;
    this.setState({is_attendanceURL:false})
   }

   recordingVideoOpen = (url,lecture_id,recordingLinkClick) =>{
    let recordingLinkClickValue = recordingLinkClick;
    let recordingUrl = url;
    this.setState({id:lecture_id})
    localStorage.setItem('recordingUrl',recordingUrl)
    localStorage.setItem('viewrecordingButtoncLick',true)
    this.zoomAttendance(url,lecture_id);
   }



	render() {
    const { value } = this.state.start_date;
    const {encrypted_url,loading} =this.state;
    const lec_modes = {
      2: this.props.saveLanguageWiseData.live,
      0: this.props.saveLanguageWiseData.classroom,
      1: 'Online'
    }
    
    if(loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
  
  
		return (
      <div id="appBody" className={this.state.is_attendanceURL ? " zIndexchange" : 'app-body'}>
        <div className={this.state.is_attendanceURL ? "IFrameDialogWrapper open" : 'IFrameDialogWrapper'}>
          <div className="IFrameDialogContainer">
            <a href="javascript:void(0)" onClick={this.closeIframe} title="close" className="iframeClose">X</a>
            <iframe name="iframe_a" src={this.state.currentLectureURL}>

            </iframe>
          </div>
        </div>
        <ReactNotifications/>
        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="content-create">
          <span className="close" onClick={this.close5}>&#x2715;</span>
          <iframe src={"https://learningoxygen.com/login?secret="+encrypted_url} name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe>
        </Popup>
        <div className={this.props.dashboard_type == 'dynamic' ? "app-div widget-app-div" : "app-div"} onLoad={this.loadEvent}>
          <Helmet>
            <title>Lectures - Learner</title>
          </Helmet>
          <div className="topHeading">
              <a href="/learner"><img src={BackArrow} /></a>
          </div>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
            <div className="page-header-session-learner">

              <div className="react-calendar-date" onClick={this.openCalendar}
                ref={this.wrapperRef}>
                {this.state.current_date}
                <img src={Calendar2} alt="cal"/>
              </div>

              {
                this.state.is_recent?
                <div className="back-to-recent" onClick={this.switchToRecent}>
                    {this.props.saveLanguageWiseData.today}
                </div>:""
              }
              
            </div>
            
            <div className="react-calendar1" Style={this.state.open?"display:block":"display:none"}
              ref={this.wrapperRef}>
              <Calendar
                onChange={this.onChange}
                value={value}
              />
            </div>
            
            {!this.state.loading && <div className="list-sticky notwidgetheader" onScroll={this.handleScroll}>
            { this.state.lectures && this.state.lectures.length <= 2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
                
              {!this.state.lectures.length && <Empty/>}
              <div className="list-content">
                {this.state.current_date_records.map((data,index)=>
                  <div key={index}>
                    { data.status==0 ? 
                      <div><div className="dash-test-first">{data.month} {data.year}</div><div className="dash-test-no-found">{this.props.saveLanguageWiseData.no_lectures_planned}</div></div>:""}
                  </div>
                )}

                {this.state.lectures && this.state.lectures.map((lecture,index) =>

                  <div className="list-div-x2" id={`${lecture.id}_${index}`}>
                    {lecture.start_date_time && <StartTimer startTime={lecture.start_date_time} onChange={this.saveQuestionTime} 
                     count={0} ref={this.questionTimer}/>}

                     {this.onSchedule(lecture.end_date_time,lecture.id)}
                    <div className="dash-test-first">
                      {index === 0 ? <>
                        <span>{this.getTime(lecture.start_date_time)[0]} {this.getTime(lecture.start_date_time)[5]}</span>
                        <span>{this.getTime(lecture.start_date_time)[1]}</span>
                      </> : this.checkDateTime(lecture.start_date_time, 
                        this.state.lectures[index - 1].start_date_time) ? null : <>
                        <span>{this.getTime(lecture.start_date_time)[0]} {this.getTime(lecture.start_date_time)[5]}</span>
                        <span>{this.getTime(lecture.start_date_time)[1]}</span>
                      </>}
                    </div>
                    <div className="dash-test" id={lecture.id==this.state.id?"conter":""} key={index} 
                      style={this.state.date==this.getTime(lecture.start_date_time)[2] && this.getTime(lecture.start_date_time)[3]?"backgroundColor:#fff6e4":""  ,lecture?.style}>
                      <div className="dash-test-wrapper">
                        <div Style="display: inline-block;" className="dash-test-inner">
                          <span Style="font-size:17px">{lecture.title}</span><br/><br/>
                          <span style={{color: '#777'}}>
                            {this.getTimeAMPMFormat(new Date(lecture.start_date_time))} | {lecture.duration_hrs} {this.props.saveLanguageWiseData.hrs}
                          </span>
                        </div>
                        <div className="lec-mode-dash">
                          {lec_modes[lecture.mode]}
                        </div>
                      </div>
                      <div className="dash-test-bottom">
                        <div>
                          <div className="fac_dp_img">
                            {lecture.profile_pic !== '' && lecture.profile_pic !== null 
                              ? <div className="fac_dp" style={{backgroundImage: 
                                `url(${common.addFileUrl(lecture?.profile_pic)})`}}>  
                              </div>
                            : null}
                            <span>{lecture.faculty}</span>
                          </div>
                          {lecture.mode === 0 ? <span>{this.props.saveLanguageWiseData.location}: {lecture.url}</span> : null}
                        </div>
                        {lecture.action_label !== null && lecture.action_label !== '' && lecture.action_label 
                          !== undefined ? lecture.action_label === "Join Class" 
                          ? <span Style="margin-left: 21px; justify-content:flex-end; display:flex;"> {lecture.liveClass_server == 2 ? <a href="#" onClick={() => this.bbbJoinLiveClass(lecture.etc,lecture.live_class_Session)}  target="_blank" >
                          <button className='Hello'>{lecture.action_label}</button>
                        </a> : lecture.liveClass_server == 3 ? <a onClick={() => this.zoomAttendance(lecture.open_url,lecture.id)} href={lecture.open_url} title="join classggggg" target="_blank" ><button>{lecture.action_label}</button>
                        </a>:<a href={lecture.url} target="_blank">
                          <button className='Hell222o' onClick={() => this.zoomAttendance(lecture.open_url,lecture.id)}>{lecture.action_label}</button>
                        </a> }</span> : <span id="action-reverse">
                          {lecture.action_label !="View Recording" ?lecture.action_label:""}
                          {/* {lecture.record_available && this.state.is_view_recording ?<span id="view-recording"><a href={lecture.record_url || lecture.url} rel="noreferrer noopener" target="_blank">
                            <button>{this.props.saveLanguageWiseData.view_recording}</button>
                          </a></span>:""} */}
                          {lecture.record_available && this.state.is_view_recording ?<span id="view-recording"><Link  onClick={() => this.recordingVideoOpen(lecture.record_url || lecture.url, lecture.id, "recordingLinkClick")} target="_blank" to={'/learner/view-recording/'+lecture.id}>
                          <button>{lecture.action_label}</button>
                          </Link></span>:""}

                          
                        </span> : null}


                        
                        {parseInt(this.props.learnerProfile?.institute_settings?.show_feedback_link) === 1 && <a href="https://docs.google.com/forms/d/e/1FAIpQLSfcnU5BbeSTX-fvsCxUIIyklU04quhtzD2K_yqT85sTbCsitw/viewform" target="_blank">
                          <button>{this.props.saveLanguageWiseData.feedback}</button>
                        </a>}
                        
                      </div>
                    </div>
                  </div> 
                )} { this.state.lectures && this.state.lectures.length>2 ?<div id={this.state.id=="NA"?"conter":""}></div> :""}
                </div>
            </div>}
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    dashboard_type : state.commonReducer.dashboard_type,
    learnerProfile: state.classroomReducer.learnerprofile,
    saveLanguageWiseData:state.commonReducer.languageData
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentLectureList,encryption,callzoomAttendanceAPI,bbbJoinLiveClass } = actions;
  return {
    encryption : (username) => dispatch(encryption(username)),
    getStudentLectureList: (date,days,direction,if_loading) => dispatch(getStudentLectureList(date,days,direction,if_loading)),
    callzoomAttendanceAPI: (lecture_id,user_id,join_time,join_type) => dispatch(callzoomAttendanceAPI(lecture_id,user_id,join_time,join_type)),
    bbbJoinLiveClass:(session,name,meetingPassword,userId) => dispatch(bbbJoinLiveClass(session,name,meetingPassword,userId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentSessions);