import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
//import Target from '../../public/icons/target.svg';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import Assignment from '../../public/icons/assignment.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class AssignmentMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      list: [],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
   this.getAssignmentMaterial(this.props.match.params.id);
  }

  getAssignmentMaterial = async (id) => {
    const response = await this.props.learnerAssignmentList(id);
    console.log(response.data)
    this.setState({ list: response.data,loading:false});
  }

  displayTime = (val) => {
    var hours = val.getHours();
    var minutes = val.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var timewithampm = hours + ':' + minutes + ' ' + ampm;
    return `${timewithampm}`.toUpperCase();
  }

  diff_hours = (val) => {
    let dt1 = new Date();
    //let mins ;
    var diff =(val.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    let hours = Math.abs(Math.round(diff));
    
    /*if(hours){
      diff /= (60);
      mins = Math.abs(Math.round(diff));
    }*/

    return hours;
  }

  onSchedule = (val,due,id) => {
    this.timer = setInterval(()=>this.scheduledOn(new Date(val) ,new Date(due),id), 1000)
  }

  scheduledOn = (val,due,id) =>{
    let dt1 = new Date();
    
    var diff = val.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    diff = Math.round(diff);
    if(diff === 0 ) {
      //this.setState({is_scheduled: 1})
      let element =document.getElementById(id);
      if (element) element.style.display="block";

      clearInterval(this.timer);
      //setTimeout(function(){  window.location.reload(true) },60000);
    }

    if(diff < 0){
      let element =document.getElementById(id);
      if (element) element.style.display="block";
    }

    console.log("onsched"+diff)
  }

  render() {
    if(this.state.loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    return (
      <div className="app-body" id="appBody">
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>Tests - Faculty</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>
          <div className="heading-part1">
            <div className="heading">
              <div id="trace-back" onClick={() => this.props.history.goBack()}>
                <img src={Back} alt="back"/>
              </div>
              <h2>{this.props.location.state.subjectName}</h2>
            </div>
          </div>
          <div className="page-header-assignment">
            {!this.state.loading && <div className="list-sticky-practice">
              {this.state.list && !this.state.list.length && <Empty/>}
              <div className="list-content">
                {this.state.list && this.state.list.map((test,index) =>
                  <div className="list-div-x2" id={test.id} ref={this.myRef}  Style="display:none">
                    {this.onSchedule(test.scheduled_date,test.due_date ,test.id)}
                    <div className="dash-test">
                        <div>
                          <div Style="display: inline-block;">
                            <Link to={{pathname: `/learner/assignment-review/${test.id}`}}>
                              <span Style="font-size:17px">{test.title}</span><br/><br/>
                            </Link>
                            <span Style="color:#a9a0a0">Score</span> : <span Style="color:var(--primary)">{test.score}</span>
                          </div>
                          <span className="dash-flex2-img"><img id="Assignment" src={Assignment} alt="Assignment"/></span>
                        </div>
                        <div className="dash-test-bottom">
                          <div>
                            <span>
                              {this.displayTime(new Date(test.scheduled_date))} |&nbsp;
                              {this.diff_hours(new Date(test.scheduled_date))} {this.diff_hours(new Date(test.scheduled_date)) === 1 ? 'Hr.' : 'Hrs.'}
                              {/*{lecture.duration_hrs} {lecture.duration_hrs === 1 ? 'Hr.' : 'Hrs.'}*/}
                            </span>
                          </div>
                          {test.action.map(action =>
                            <div className="faculty-dash-actions">
                              <Link to={{pathname: `/learner/assignment-review/${test.id}` , state:{action:action.label,subject:this.props.location.state.subjectName,id:this.props.location.state.subject}}}>
                                <button>
                                  {action.label}
                                </button>
                              </Link>
                            </div>
                          )}
                          
                        </div>
                    </div>
                  </div> 
                )}
                </div>
            </div>}

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getFacultyExerciseList,learnerAssignmentList } = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    learnerAssignmentList : (id) => dispatch(learnerAssignmentList(id)),
    getFacultyExerciseList: (subject_id,batch_id,date,days,direction,if_loading) => dispatch(getFacultyExerciseList(subject_id,batch_id,date,days,direction,if_loading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentMaterial);