import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import AssessmentPreview from '../../components/study-material/CommonComponents';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import Target from '../../public/icons/target.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import common from '../../common';
import './styles.css';


class FacultyStudyMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      practice: [],
      start_date:"",
      current_date:"",
      hasMore:false,
      open:false,
      date:"",
      contentDate:"",
      countTop:0,
      countBottom:0,
      showModal: false,
      content_id:0,
      is_recent:0,
      subjects:[],
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
  }
  
  componentDidMount() {
   this.getFacultyProfile();
  }

  showAttemptModal = () => this.setState({ showModal: true })

  closeAttemptModal = () => this.setState({ showModal: false })

  getFacultyProfile = async () => {
    const response = await this.props.getFacultyProfile();
    this.setState({ subjects: response.data.subjects });
  }


  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if(label.toLowerCase() == 'review' || label.toLowerCase() == 'result') {
      this.props.history.push(
        `/learner/assessment-review/${contentPreview.content_id}`,
        {modal: true}
      )
    }
    else {
      this.setState({showAttemptModal: false})
      this.props.history.push(
        `/learner/attempt/${contentPreview.content_id}`
      );
    }
  }

  attemptAndReview = async (label, content_id) => {
    this.setState({ content_id })
    if(label.toLowerCase() === 'review' || label.toLowerCase() === 'result') {
      this.props.history.push(
        `/learner/assessment-review/${content_id}`,
        {modal: true}
      )
    }
    else {
      await this.props.getAttemptPreview(content_id)
      this.setState({ showModal: !this.state.showModal })
    }
  }

  getExerciseList = async (date,days,direction,if_loading) => {
    const response = await this.props.getExerciseList(date,days,direction,if_loading);
    
    if(response){
      this.setState({practice:response.data.results?response.data.results:"", loading: false ,countBottom:1,content_id:response.data.results?response.data?.results[0]?.content_id:0})
      
    }
    else{
      this.setState({hasMore:false,loading: false})
    }
    
  }

  getTime = (val) => {
    var d = new Date(val?val:'2020-07-29T17:45:00');
    var if_time_equal=0;
    var g1 = new Date();
    if (g1.getTime() < d.getTime())
      if_time_equal=1

    let contentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    let dateOnScroll = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    var cd = new Date(this.state.current_date.split(' ').join('-'));

    let date = [
      d.getDate(),
      this.state.days[d.getDay()],
      contentDate,
      if_time_equal,
      dateOnScroll,
      cd.getMonth()+1,
      d.getMonth()+1
    ];
    return date;
  }

  getCurrentDate=()=>{
    var d = new Date();
    let date = [
      this.state.mlist[d.getMonth()],
      d.getFullYear()
    ].join(' ');

    let currentDate = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    this.setState({ current_date: date, date: currentDate});
  }


  convertedHour = (minutes) =>{    
    return minutes >=60?Math.floor(minutes / 60) +' HRS':minutes +' mins';
  }

  render() {
    return (
      <>
       
        <div className="app-body" id="appBody" >
          <ReactNotifications/>
          <div className="app-div">
            <Helmet>
              <title>Practice - Learner</title>
            </Helmet>
            <img id="path-b" src={PathB} alt="pathb"/>
            <img id="path-d" src={PathD} alt="pathd"/>
              <div className="page-header">
                <div className="page-header-left">
                  <h1>Study Material</h1>
                </div>
              </div>
              <div className="list-sticky-practice" onScroll={this.handleScroll}>
                {!this.state.subjects.length && <Empty/>}
                <div className="list-content">
                  {this.state.subjects && this.state.subjects.map((test,index) =>
                    <div className="list-div-x2" id={test.id} ref={this.myRef}>
                      <div className="dash-test">
                        <div className="dash-flex2">
                          <div Style="display: inline-block;">
                            <span className="dash-flex2-title">{test.label}</span>
                            
                          </div>
                          <div>
                            <span className="dash-flex2-img">
                              <img className="subjectImage" src={common.addFileUrl(test.thumbnail)} alt="Target"/>
                            </span>
                          </div>
                        </div>
                        <div className="facultysm">
                          <a href={"/faculty/study-material-create/units?class="+test.class_id+"&subject="+test.id} >Lessons</a>
                        </div>
                      </div>
                    </div> 
                  )}</div>
              </div>

          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentPreview: state.classroomReducer.contentPreview
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAttemptPreview, getExerciseList,getSubjectList,getFacultyProfile } = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getAttemptPreview: (contentId) => dispatch(getAttemptPreview(contentId)),
    getSubjectList: (subject_id) => dispatch(getSubjectList(subject_id)),
    getExerciseList: (date,days,direction,if_loading) => dispatch(getExerciseList(date,days,direction,if_loading))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacultyStudyMaterial);