import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import actions from '../../redux/actions';
import common from '../../common';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Profile from '../../public/icons/user-profile.svg';
import Invite from '../../public/icons/invite.svg';
import Password from '../../public/icons/password.svg';
import Invitew from '../../public/icons/invite-w.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Pagination from "react-js-pagination";
import Promote from '../../public/icons/Frame-10.svg'
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class StudentSemPromotion extends Component {
	constructor(props){
		super(props)
		this.state = {
			centers: [],
			branches: [],
			classes: [],
			sections: [],
			filterClass: '',
			filterCenter: localStorage.getItem('center_id'),
			filterSectionFrom: '',
			filterSectionTo: '',
			filterSectionName: '',
			filterClassName: '',
			filterClasses: [],
			class_names: {},
			section_names:{},
			class_centers: {},
			class_center: '',
			students: [],
			current_page:1,
			totalItems:0,
			filterStudents: [],
			loading:true,
			task_id:'',
			users_not_migrated:[],
			users_migrated:[],
			status:'',
		}
	}

	componentDidMount(){
		this.getCenterList();
		this.getClassList();
		this.setState({loading:false})
		
		if(localStorage.getItem("task_id")){
			this.timer = setInterval(() => {
				  this.setState({task_id:localStorage.getItem("task_id")})
		          this.callPromotionState(localStorage.getItem("task_id"),this.state.current_page)
		    },10000)	
		}
		this.getUrlfromCurrentPage();
	}


	getUrlfromCurrentPage = ()=>{
		let getUrl = this.props.location.pathname;
		let modifyURL = getUrl.split("/").pop();
		let language = localStorage.getItem('lang');
		if(modifyURL){
		  console.log("modifyURL", modifyURL);
		  console.log("languagessss",language);
		  this.fetchPageWiseData(language,modifyURL);
		}
		
	  }
	
	  fetchPageWiseData = async (language,modifyURL) =>{
		const response = await this.props.getPageLanguageWiseData(language,modifyURL);
		if(response !== undefined && response.status == 1) {
		  console.log("getPageLanguageWiseData");
		  console.log(response?.data);
		  localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
		  
		  
		}
	  }

	callPromotionState = async (task_id,page) => {
		const student = await this.getStudentList(this.state.filterCenter,this.state.filterClass,this.state.filterSectionFrom,page);

	 	const res = await this.props.fetchPromotionState(task_id)

	 	if(res.data?.state == "SUCCESS") clearInterval(this.timer)

	 	this.setState({status:res.data?.state,migrated_users:res.data.meta.users_migrated,users_not_migrated:res.data.meta.users_not_migrated})

		var students = student?.reduce((acc,student) => {
	
			if(res.data.meta.users_migrated.includes(student?.user?.email) && student.is_default == true){
				
				student['is_migrated'] = true;
				acc.push(student)
			}else if(res.data.meta.users_not_migrated.includes(student?.user?.email) && student.is_default == true){
				student['is_migrated'] = false;
				acc.push(student)
			}else{
				acc.push(student)
			}
			return acc
		},[]);
		this.setState({filterStudents:students})
	}

	getCenterList = async () => {
	    const response = await this.props.getCenterList();
	    if(response !== undefined) {
	      this.setState({ branches: response.data, centers: response.data });
	    }
	}

	getClassList = async () => {
		const response = await this.props.getClassList2();
		if(response !== undefined) {
			this.setState({class_centers: Object.fromEntries(
          response.data.map(e => [e.id, e.center])
        ),class_names: response.data.reduce(
        	(obj, item) => Object.assign(obj, { [item.id]: item.label }), {}),classes: response.data,filterClasses: response.data.filter(classs => classs.center === parseInt(this.state.filterCenter))});
		}
	}

	getSectionList = async (class_center, class_id) => {
		const response = await this.props.getSectionList2(class_center, class_id);
		if(response !== undefined) {
			this.setState({ sections: response.data, section_names: response.data.reduce(
			(obj, item) => Object.assign(obj, { [item.id]: item.label }), {}) });
		}
	}

	filterCenterChange = (e) => {
    	this.setState({loading:false,filterCenter: e.target.value, filterClass: '', filterSectionFrom: '',filterSectionTo: '',
                        filterClassName: '', filterSectionName: '',
                        filterClasses: this.state.classes.filter(classs => classs.center === parseInt(e.target.value)) });
      	//this.getStudentList(e.target.value,this.state.current_page);
  	}

  	classChange2 = (e) => {
		if(e.target.value === '') {
		  this.setState({ filterClass: '', filterSectionFrom: '',filterSectionTo: '', filterClassName: '',
		                    filterSectionName: '',sections:''});
		}
		else {
		  this.setState({ filterClass: e.target.value, filterSectionFrom: '',filterSectionTo: '', filterSectionName: '',
		                    filterClassName: this.state.class_names[e.target.value],
		                    class_center: this.state.class_centers[e.target.value] })
		  this.getSectionList(this.state.class_centers[e.target.value], e.target.value)
		}

		var students = this.state.students.filter(student => {
			return student?.class_details?.id === parseInt(e.target.value)
		});
		//this.setState({ filterStudents: students });
	}

	sectionChangeFrom = (e) => {

	    if(e.target.value === '') {
	      this.setState({ loading:true, filterSectionFrom: '',filterSectionTo: '', filterSectionName: ''})
	    }
	    else {
	      this.setState({loading:true,filterSectionFrom: e.target.value, 
	          filterSectionName: this.state.section_names[e.target.value] });
	    }
	    clearInterval(this.timer)
	    this.setState({ loading:false,current_page:1})
	    this.getStudentList(this.state.filterCenter,this.state.filterClass,e.target.value,1);
	 }

	 sectionChangeTo = (e) => {
	    if(e.target.value === 'To Section') {
	      this.setState({ filterSectionTo: ''})
	    }
	    else {
	      this.setState({ filterSectionTo: e.target.value});
	    }
	 }

	getStudentList = async (center,class_id,section_id,page) => {
		const response = await this.props.getStudentList(center,class_id,section_id,page);
		if(response !== undefined) {
			this.setState({ filterStudents: response.data.data });
			this.setState({ loading:false,students: response.data.data,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20 })
			
			return response.data.data
		}
	}

	handlePageChange(pageNumber) {
		this.show4(pageNumber);
		this.setState({current_page: pageNumber});
	}

	show4 = async(page) => {
		const {task_id} = this.state

		this.setState({current_page:page});

		if(task_id) this.callPromotionState(task_id,page)
		else{
			const response = await this.props.getStudentList(this.state.filterCenter,this.state.filterClass,this.state.filterSectionFrom,page);
			if(response !== undefined) {
				this.setState({ students: response.data.data, filterStudents: response.data.data})
			}
		}
	 }

	 confirm = () => this.setState({isConfirm:false})
	 promote = () =>{

	 	this.setState({isConfirm:true})
	 }

	 promoteStudent = async() =>{

	 	const response = await this.props.promoteStudentSem(this.state.filterSectionFrom)
	 	if(response != undefined){
	 		this.setState({task_id:response.data.task_id,isConfirm:false})
	 		let task_id =response.data.task_id

			localStorage.setItem("task_id",task_id)
			
			setTimeout(() => {
				this.callPromotionState(task_id,this.state.current_page)
			},1000)
	 	}
	 }

	render(){
		if(false) {
		      return (
		        <div className="assessment-div">
		          <div className="admin-div-loader">
		            <div className="admin-loader"></div>
		          </div>
		        </div>
		      )
    	}

		return(
			<div className="admin-div">
				<Helmet>
					<title>{this.props.pagewiseLanguageData.students}</title>
				</Helmet>
				<ReactNotifications/>
				<Header placeholder={this.props.pagewiseLanguageData.search_students}/>

				<Popup open={this.state.isConfirm} closeOnDocumentClick onClose={this.confirm}
		          className="popup-content3">
		          <div className="delete-up">
		            <img src={Promote} alt={this.props.pagewiseLanguageData.do_you_want_to_promote_the_students}/>
		            {this.props.pagewiseLanguageData.do_you_want_to_promote_the_students}
		          </div>
		          <div className="delete-down">
		            <button onClick={this.confirm}>
		              {this.props.pagewiseLanguageData.cancel}
		            </button>
		            <button onClick={this.promoteStudent}>
		              {this.props.pagewiseLanguageData.yes}
		            </button>
		          </div>
		        </Popup>

				<div className="main-part">
					{/*<div className="sub-head">
						<div className="heading-part1-ques">
							{/*!this.state.open3 && <div className="search-head">
								<div className="search-wrapper-admin">
									<div className="search-container">
										<input type="search" placeholder="Search students.." onChange = {this.show6} onFocus={this.handleUsernameFocus} value={this.state.text}/>
									</div>  
								</div>
							</div>
						</div>
						<div className="heading-part2">
							<button id="button-reverse" onClick={this.closeInvite}>
								Promote
							</button>
						</div>
					</div>*/}

					<div className="sub-filter">
						<select defaultValue={parseInt(this.state.filterCenter)} 
			              onChange={this.filterCenterChange}>
			              {this.state.centers.map(center =>
			                <option key={center.id} value={center.id}>{center.name}</option>
			              )}
			            </select>
			             <select value={this.state.filterClass} onChange={this.classChange2}>
			                <option value={''}>
			                  {this.props.pagewiseLanguageData.select_class}
			                </option>
			                {this.state.filterClasses.map((classs, index) =>
			                  <option value={classs.id} key={index}>
			                    {classs.label}
			                  </option>
			                )}
			              </select>
			              <select value={this.state.filterSectionFrom} onChange={this.sectionChangeFrom}>
			                <option value={''}>
			                 {this.props.pagewiseLanguageData.from_section}
			                </option>
			                {this.state.sections.length > 0 && this.state.sections.map((section, index) =>
			                  <option value={section.id} key={index}>
			                    {section.label}
			                  </option>
			                )}
			              </select>
			              {/*<select value={this.state.filterSectionTo} onChange={this.sectionChangeTo}>
			                <option value={''}>
			                  To Section
			                </option>
			                {this.state.sections.map((section, index) =>
			                  <option value={section.id} key={index}>
			                    {section.label}
			                  </option>
			                )}
			              </select>*/}
			              <button id="button-reverse" onClick={this.promote}>
								{this.props.pagewiseLanguageData.promote}
						  </button>
					</div>
					{this.state.loading && 
			          <div className="admin-div-loader">
			            <div className="admin-loader"></div>
			          </div>
				    }
					{!this.state.loading && <>
					<div className="list-head list-assessment">
	                <div className="list-title">
					{this.props.pagewiseLanguageData.name}
	                </div>
	                <div className="list-section">
					{this.props.pagewiseLanguageData.class}
	                </div>
	                <div className="list-class">
					{this.props.pagewiseLanguageData.section}
	                </div>
	                <div className="list-subject">
					{this.props.pagewiseLanguageData.roll_no}
	                </div>
	                <div className="list-faculty">
					{this.props.pagewiseLanguageData.center}
	                </div>
	                <div className="list-action">
					{this.props.pagewiseLanguageData.status}
	                </div>
	                {/*<div className="list-action">
	                  Actions
	                </div>*/}
	              </div>
					<div className="the-list the-assessment-list">
						{(this.state.filterSectionFrom || this.state.task_id) && this.state.filterStudents.map((student, index) =>
						  <div className="list-item student-list" key={student.id}>
						    <div className="title-item3">
						      {this.state.invite && <input type="checkbox" onClick={() => this.changeIds(student.id)}
						        defaultChecked={this.state.user_ids.indexOf(student.id) !== -1 ? true : false}/>}
						      {this.state.resend && <input type="checkbox" onClick={() => this.changeIds(student.user.id)}
						        defaultChecked={this.state.user_ids.indexOf(student.user.id) !== -1 ? true : false}/>}
						      {student?.user?.profile_picture === null || student?.user?.profile_picture === ''
						        ? <img src={Profile} alt="dp"/> : <div className="dp_div"
						        style={{backgroundImage: "url("+common.addFileUrl(student?.user?.profile_picture)+")"}}>
						      </div>}
						      <div>
						        {student.user?.name ? student.user?.name : student._source?.name}
						      </div>
						    </div>
						    <div className="section-item">
						      {student.class_details?.name}
						    </div>
						    <div className="class-item">
						      {student.section_details?.name}
						    </div>
						    <div className="subject-item">
						      {student?.roll_no ? student?.roll_no : student._source?.roll_no}
						    </div>
						    <div className="faculty-item">
						      {student.user?.branch.name}
						    </div>
						    <div className="action-item">
						      {student.is_migrated? this.props.pagewiseLanguageData.promoted : this.props.pagewiseLanguageData.pending}
						    </div>
						    {/*<div className="action-item">
						      <span title="Edit" onClick={() => this.show2(student)}>
						        <img src={Edit} alt="edit"/>
						      </span>
						      <span title="Delete" onClick={() => this.showd(student?.id ? student?.id : student?._source.student_id)}>
						        <img src={Delete} alt="delete"/>
						      </span>
						      <div className="invite-class">
						        <div title="Invite" id="send-invite" 
						          onClick={() => this.sendInvite(student?.id ? student?.id : student?._source.student_id)}>
						          <img src={Invite} alt="invite"/>Send invite
						        </div>
						        <div title="Invite" id="send-invite" 
						          onClick={() => this.resendPassword(student.user?.id ? student.user?.id : student?._source.student_id)}>
						          <img src={Password} alt="invite"/>Resend Password
						        </div>
						      </div>
						    </div>*/}
						  </div>
						)}
					</div>
					</>}
				</div>
				 {this.state.filterStudents.length > 1 && <Pagination
		          activePage={this.state.current_page}
		          itemsCountPerPage={20}
		          totalItemsCount={this.state.totalItems}
		          pageRangeDisplayed={10}
		          onChange={this.handlePageChange.bind(this)}
		        />}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
	pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { promoteStudentSem,fetchPromotionState,getStudentList,userSearchList, createStudent, updateStudent, getCenterList, sendInvite,
          getClassList2, getSectionList2,userdetail, classbatchmapping,deleteStudent,sendInvitePassword,getPageLanguageWiseData } = actions;
  return {
    sendInvite: (mail_type, user_ids) => dispatch(sendInvite(mail_type, user_ids)),
    sendInvitePassword: (user_ids) => dispatch(sendInvitePassword(user_ids)),
    createStudent: (id, name, email, mobile, password, center_id, roll_no) => dispatch(createStudent(id, name, email, mobile, password, center_id, roll_no)),
    updateStudent: (id, name, email, mobile, password, classs, profile_picture, section) => dispatch(updateStudent(id, name, email, mobile, password, classs, profile_picture, section)),
    getStudentList: (center_id,class_id,section_id,page) => dispatch(getStudentList(center_id,class_id,section_id,page)),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getCenterList: () => dispatch(getCenterList()),
    getClassList2: () => dispatch(getClassList2()),
    deleteStudent: (id, center_id) => dispatch(deleteStudent(id, center_id)),
    userSearchList:(key,page) => dispatch(userSearchList(key,page)),
    userdetail:(key) => dispatch(userdetail(key)),
    promoteStudentSem : (batch_id) => dispatch(promoteStudentSem(batch_id)),
    fetchPromotionState : (task_id) => dispatch(fetchPromotionState(task_id)),
    classbatchmapping : (center_id,user_id,class_batch_mapping) => dispatch(classbatchmapping(center_id,user_id,class_batch_mapping)),
	getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentSemPromotion);