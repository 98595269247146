import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Popup from 'reactjs-popup';
import Shimmer from 'react-js-loading-shimmer';
import ReactNotifications from 'react-notifications-component';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Listing from '../../public/icons/listing.svg';
import Back from '../../public/icons/back.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import common from '../../common';
import { store } from 'react-notifications-component';
import ImgHolder from '../../public/images/img-holder.svg';
import MultiSelect from "react-multi-select-component";
import './styles.css';

class ScheduledQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      open3:false,
      question_id:null,
      opend: false,
      openf: false,
      id: null,
      classs:[],
      section:[],
      class_id: '',
      subject_id: '',
      classes: [],
      subjects: [],
      subjects2: [],
      schedule_step: false,
      center_id: localStorage.getItem('center_id'),
      filterCenter: localStorage.getItem('center_id'),
      filterClass: '',
      filterClasses: [],
      filterClassName: '',
      filterSubject: '',
      filterSubjectName: '',
      class_centers: {},
      class_center: '',
      centers: [],
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      title: '',
      section_id: '',
      classListItems:[],
      centerListItems:[],
      sectionListItems:[],
      classes2: [],
      scheduled_date: '',
      solution_date: '',
      result_date: '',
      is_released: true,
      due_date: '',
      sections: [],
      assessments: [],
      qodList: [],
      is_faculty_admin:false,
      user_id: localStorage.getItem("user_id"),
      sectionClass:null,
      scheduleimage: null,
      scheduleimageURL:null,
    }
  }

  componentDidMount() {
    this.getQodList();
    this.getCenterList();
    this.getClassList();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  getClassList = async () => {
    const {classListItems} = this.state;
    const response = await this.props.getClassList2();
    if(response !== undefined) {
      this.setState({ classes2: response.data.filter(classs => classs.center === parseInt(this.state.center_id))});

      for(let i =0; i<response.data.length; i++){
        classListItems.push({value:response.data[i].id, checked:false,label:response.data[i].label})
      }
    }
  }

  getCenterList = async () => {
    const {centerListItems} = this.state;
    const response = await this.props.getCenterList();
    if(response !== undefined) {
      this.setState({ branches: response.data });
      for(let i =0; i<response.data.length; i++){
        centerListItems.push({value:response.data[i].id, checked:false,label:response.data[i].name})
      }
    }
  }

  getQodList = async () => {
    const response = await this.props.qodlist();
    if(response !== undefined) {
      this.setState({ qodList: response.data });
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  showd = (val) => this.setState({ opend: true, d_id: val });
  closed = () => this.setState({ opend: false });
  close3 = () => this.setState({ open3: false });

  deleteQod = async () => {
    const { d_id } = this.state;
    await this.props.deleteQod(d_id);
    this.setState({ opend: false });
  }

  schedule =async (qod) =>{
    let {classs,classes2,sections,section,sectionListItems} = this.state;
    var levelIds = qod.level_id.split(',');
    levelIds = levelIds.map(val => parseInt(val))

    classs =[];
    section=[];

    if(qod.level == "class"){
      for(let i =0; i<  classes2.length; i++){
        if(levelIds.includes(classes2[i].id)){
          classs.push({value:classes2[i].id, checked:false,label:classes2[i].label})
        }
      }
    }

    if(qod.level == "section"){
      this.setState({sectionClass:qod.class_id})

      const response = await this.props.getSectionList2(this.state.center_id, qod.class_id);
      if(response !== undefined) {
        this.setState({ sections: response.data });

        for(let i =0; i < response.data.length; i++){
          sectionListItems.push({value:response.data[i].id, checked:false,label:response.data[i].label})
        }

        for(let i =0; i<  response.data.length; i++){
          if(levelIds.includes(response.data[i].id)){
            section.push({value:response.data[i].id, checked:false,label:response.data[i].label})
          }
        }
      }
    }

    this.setState({open3 : true,title:qod.title,scheduleimage:qod.image,mode:qod.level,type:qod.type,question_schedule_date:qod.schedule_date,question_due_date:qod.due_date,question_id:qod.id,showResult:qod.show_result == "true" ? true:false,classs,section})
  }

  onClassChange = (e) => {
    this.setState({sectionClass :e.target.value })
    this.getSectionList(e.target.value);
  }

  changeScheduleImage = async (event) => {

    if(event.target.files[0].type.split('/')[0]) {
      this.setState({
        scheduleimage: event.target.files[0],
        scheduleimageURL: URL.createObjectURL(event.target.files[0])
      })

      //const response= await this.props.updateImage(event.target.files[0]);
      //this.setState({image:response.data.image});
    }
    else {
      this.setState({ scheduleimage: null, scheduleimageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  getSectionList = async (classs) => {
    const {sectionListItems} =this.state
    if(classs === '') {
      this.setState({ sections: [] });
    }
    else {
      const response = await this.props.getSectionList2(this.state.center_id, classs);
      if(response !== undefined) {
        this.setState({ sections: response.data });
      }

      for(let i =0; i<response.data.length; i++){
        sectionListItems.push({value:response.data[i].id, checked:false,label:response.data[i].label})
      }
    }
  }

  scheduleQuestion = async () => {
    let dateObj= new Date();
    let selected;
    let selectedCenter=[];

    const {question_id,question_schedule_date,question_due_date,title,mode,type,showResult,scheduleimage,center,classs,section,center_id,scheduleimageURL} = this.state

    console.log(classs)
    selected = mode == "institute" ? center_id : mode == "class" ? classs.map(val => val.value) : mode == "section" ? section.map(val => val.value) :"";

    console.log(selected)
    const response = await this.props.qodUpdate(question_id,question_schedule_date,question_due_date,title,mode,type,showResult,dateObj.toISOString(),scheduleimageURL ? scheduleimage :"",selected)

    this.setState({open3: false})
  }

  titleChange = (e) => {
    this.setState({title: e.target.value})
  }

  typeChange =(e) =>{
    this.setState({type:e.target.value})
  }

  modeChange = (e) =>{
    console.log(this.state.centerListItems)
    this.setState({mode:e.target.value})
  }

  showResult = (e) => {
    this.setState({showResult: !this.state.showResult})
  }

  onChangeTime1 = (e) => {
    this.setState({question_schedule_date : e.target.value})
  }
  onChangeTime2 = (e) => {
    this.setState({question_due_date : e.target.value})
  }

  render() {
    const {center,classs,section} =this.state
    const isEnabled = this.state.title.length > 0 && this.state.question_schedule_date && this.state.question_due_date && this.state.mode;

    return (
      <div className="admin-div">
        <Header placeholder={this.props.pagewiseLanguageData.search_assessments}/>
        <ReactNotifications/>
        <Popup open={this.state.open3} closeOnDocumentClick onClose={this.close3}
          className="popup-content4">
          <div className="popup-header2">
            {this.props.pagewiseLanguageData.scheduled_questions}
          </div>
          <span className="close" onClick={this.close3}>&#x2715;</span>
          <div className="popup-data4">
            <div className="popup-inputs">
              <input placeholder="Title" onChange={this.titleChange}
                value={this.state.title}/>
              <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
              onChange={this.changeScheduleImage} ref={fileInput2 => this.fileInput2 = fileInput2}/>
              {this.state.scheduleimage === '' || this.state.scheduleimage === null
              ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput2.click()}/>
              : <div className="schedule-img" onClick={() => this.fileInput2.click()}
              style={{backgroundImage: "url("+common.addFileUrl(this.state.scheduleimageURL)+")"}}></div>}

              {this.state.scheduleimage && !this.state.scheduleimageURL && <div className="schedule-img" onClick={() => this.fileInput2.click()}
              style={{backgroundImage: "url("+common.addFileUrl(this.state.scheduleimage)+")"}}></div>}


            </div>

            <div className="popupx-dropdowns">
              <select value={this.state.mode} onChange={this.modeChange}>
                <option value="">
                 {this.props.pagewiseLanguageData.schedule_question}
                </option>
                <option value="institute">
                 {this.props.pagewiseLanguageData.institute}
                </option>
                <option value="class">
                {this.props.pagewiseLanguageData.class}
                </option>
                <option value="section">
                {this.props.pagewiseLanguageData.section}
                </option>
              </select>

              {/*this.state.mode == "center" && <MultiSelect
                options={this.state.centerListItems}
                value={center}
                onChange={center => this.setState({center})}
                labelledBy={"Select"}
              />*/}
              {this.state.mode == "class" && <MultiSelect
                options={this.state.classListItems}
                value={classs}
                onChange={classs => this.setState({classs})}
                labelledBy={"Select"}
              />}
              {this.state.mode == "section" &&
              <><select value={this.state.sectionClass} onChange={this.onClassChange}>
                <option value={''}>
                  {this.props.pagewiseLanguageData.select_class}
                </option>
                {this.state.classes2.map(classs =>
                  <option key={classs.id} value={classs.id}>
                    {classs.label}
                  </option>
                )}
              </select>
              <MultiSelect
                options={this.state.sectionListItems}
                value={section}
                onChange={section => this.setState({section})}
                labelledBy={"Select"}
              /></>}
            </div>
          <div className="popupx-inputs">
            <input placeholder="type" onChange={this.typeChange}
              value={this.state.type}/>
            <input type="checkbox"  checked={this.state.showResult === true} onChange={this.showResult}/>{this.props.pagewiseLanguageData.show_result}
          </div>
          <div className="popupx3-inputs">
            <div>
              <span>{this.props.pagewiseLanguageData.schedule_date} :</span>
              <input type="datetime-local" placeholder="Scheduled Date & Time"
              onChange={this.onChangeTime1} value={this.state.question_schedule_date}/>
            </div>

            <div>
              <span>{this.props.pagewiseLanguageData.due_date} :</span>
              <input type="datetime-local" placeholder="due Date & Time"
              onChange={this.onChangeTime2} value={this.state.question_due_date}/>
            </div>
          </div>
        </div>
        <div className="popup-actions">
          <button disabled={!isEnabled} onClick={this.scheduleQuestion}>{this.props.pagewiseLanguageData.re_schedule}</button>
        </div>

        </Popup>
         <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteQod}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
          <div className="sub-head">
            <div className="heading-part1">
              <div className="heading">
                <Link id="trace-back" to={`/admin/question-bank`}>
                  <img src={Back} alt="back"/>
                </Link>
                <h2>{this.props.pagewiseLanguageData.scheduled_questions}</h2>
              </div>
            </div>
            <div className="heading-part2">
              {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt={this.props.pagewiseLanguageData.list_view}/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt={this.props.pagewiseLanguageData.grid_view}/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
            </div>
          </div>
          {/*<div className="sub-filter">
            {!this.state.is_faculty_admin && <select defaultValue={parseInt(this.state.filterCenter)}
              onChange={this.filterCenterChange}>
              {this.state.centers.map(center =>
                <option key={center.id} value={center.id}>{center.name}</option>
              )}
            </select>}
            {this.state.filterClass !== '' && this.state.filterSubject !== ''
              ? <div className="sub-filter-x" onClick={this.showf}>
              {this.state.filterClassName} | {this.state.filterSubjectName}
            </div> : <div className="sub-filter-x" onClick={this.showf}>
              Filter Class & Subject
            </div>}
          </div>*/}
          {this.state.qodList.length === 0 ? <Empty/> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
              {this.props.pagewiseLanguageData.title}
              </div>
              <div className="list-email">
                {this.props.pagewiseLanguageData.level}
              </div>
              <div className="list-email">
                {this.props.pagewiseLanguageData.schedule_date}
              </div>
              <div className="list-action">
              {this.props.pagewiseLanguageData.actions}
              </div>
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.qodList.map((qod, index) =>
                <div className="list-item" key={qod.id}>
                  <div className="title-item">
                    {qod.title}
                    <span>{qod.type}</span>
                  </div>
                  <div className="email-item">
                    {qod?.level}
                  </div>
                  <div className="email-item">
                    {qod?.schedule_date}
                  </div>
                  <div className="action-item">
                    <span onClick={() => this.schedule(qod)}>
                      <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                    </span>
                    <span Style={qod.created_by != this.state.user_id ? "display:none":""} title="Delete" onClick={() => this.showd(qod.id)}>
                      <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                    </span>
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.qodList.map((qod, index) =>
                <div className="grid-item" key={qod.id}>
                  <div className="grid-up grid-up-v1">
                    <div className="title-grid">
                      {qod.title}
                      <span>{qod.type}</span>
                    </div>
                    <div className="grid-actions">

                      <span onClick={() => this.schedule(qod)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>
                      <span Style={qod.created_by != this.state.user_id ? "display:none":""} title="Delete" onClick={() => this.showd(qod.id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                      </span>
                    </div>
                  </div>
                  <div className="grid-down">
                    <div className="grid-actions">

                    </div>
                  </div>
                </div>
              )}
            </div>}
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentList, deleteQod, getClassList2, getSubjectList2,
            createDPP, getSectionList2, getCenterList,qodlist,qodUpdate,getPageLanguageWiseData } = actions;
  return {
    qodlist: () => dispatch(qodlist()),
    getCenterList: () => dispatch(getCenterList()),
    qodUpdate :(question,schedule_date,due_date,title,level,type,show_result,created_on,image,level_id) => dispatch(qodUpdate(question,schedule_date,due_date,title,level,type,show_result,created_on,image,level_id)),
    createDPP: (assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date) => dispatch(createDPP(assessment, title, classs, subject, batch, scheduled_date, solution_date, result_date, due_date, is_released, release_date)),
    getAssessmentList: (center) => dispatch(getAssessmentList(center)),
    deleteQod: (id) => dispatch(deleteQod(id)),
    getClassList2: () => dispatch(getClassList2()),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getSubjectList2: (center_id) => dispatch(getSubjectList2(center_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledQuestions);
