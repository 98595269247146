import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Select from 'react-select'
import actions from '../../redux/actions';
import common from '../../common';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Profile from '../../public/icons/user-profile.svg';
import Pagination from "react-js-pagination";
import Back from '../../public/icons/back.svg';
class IncreaseAttempt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_faculty_admin:false,
      AllLists: [],
      UpdateList: [],
      defaultListSave: [],
      classes: [],
      center_id:localStorage.getItem('center_id'),
      sections: [],
      students: [],
      classidsave:'',
      batchidsave:'',
      searchText:'',
      classfilterData:[],
      batchFilterData:[],
      pageTotalItems:0,
      currentpage:1,
      assessmentIdsave:'',
      saveBatchInputValue:'',
      saveInputValue:'',
      dataDateSendAttemptValue:'',
      saveBatchInputDateValue:'',
      is_disabled:true,
      is_disabled2:true

    }
  }

  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    let fromAssessment = this.props.location?.state?.classID;
    let getAssessmentID = this.props.location?.state?.assessmentID;
    
    console.log("this.props.location?.state")
    console.log(this.props.location?.state);

    console.log("this.props.location?.state From DPP")
    console.log(this.props.location?.state.fromDPP);
  

    if(getAssessmentID){
      console.log("getAssessmentID",getAssessmentID);
      this.setState({ assessmentIdsave:getAssessmentID});
      
    }

    if(fromAssessment){
      this.setState({ classidsave:fromAssessment});
      this.getClassList();
      this.defaultClassChange(fromAssessment);
    }    
  
  }


  defaultClassChange = (classid) => {
    //this.getSectionList(e.target.value);
    //alert(classid);
    //alert(this.state.assessmentIdsave);
    if(classid){
      this.getSectionList(classid);
    }
    
  
}
getSectionList = async (classid) => {
  const response = await this.props.getSectionList(this.state.center_id, classid,1,this.state.page_url);
  if(response !== undefined) {
      console.log(response?.data);
      if(response?.data){
          var newdata = response?.data.map((o)=>({
            ...o,
            value:o.id
        }))
        this.setState({ sections:newdata});
      } 
  }
}
  // onClassChange = (e) => {
  //   this.setState({ classidsave: e.target.value });
  //   // alert(e.target.value)
  //   if(e.target.value){
  //     this.getSectionList(e.target.value);
  //   }
  // }


  onBatchChange = (e) => {
    this.setState({ batchidsave: e.target.value });
    console.log("ddddddd")
    console.log(e.target.value)
    let batchFilterData = this.state.sections.filter((word) => word.id == e.target.value);
    console.log("sections");
    console.log(this.state.sections);
    console.log(batchFilterData);
    if(e.target.value){
      this.setState({ batchFilterData: batchFilterData});
      this.getStudentListNotifications(e.target.value);
      this.setState({is_disabled: true});
      this.setState({is_disabled2: true});
    }
  }

  getStudentListNotifications = async (batch_id) => {
		const response = await this.props.getStudentListIncreaseAttemptAssessment(batch_id,this.state.assessmentIdsave,this.props.location?.state.fromDPP ? 1 : 0);
		if(response !== undefined) {
      console.log("response.data");
      console.log(response);
      this.setState({ AllLists: response.data});
      this.setState({ UpdateList: response.data});
      this.setState({ defaultListSave: response.data});
      this.setState({is_disabled: true});
      this.setState({is_disabled2: true});

    }
	}



  getClassList = async () => {
    const response = await this.props.getClassList();
    if(response !== undefined) {
      this.setState({ classes: response.data});
      let saveFilterData = response.data.filter((word) => word.id == this.state.classidsave);
      console.log("saveFilterData",saveFilterData);
      let saveLabel = saveFilterData[0].label;
      console.log("saveLabel",saveLabel);
      if(saveLabel){
        this.setState({ classfilterData: saveLabel});
      }
      
      
    }
  }




  searchChange = async (e) => {
    let  {AllLists,defaultListSave} = this.state;
    clearInterval(this.timer)
   this.setState({searchText:e.target.value})

    let textValue = e.target.value;
    console.log('e.target.value',e.target.value);
    this.timer = setTimeout(async () => {
      if(textValue){
        
        const SearchFileredData =  defaultListSave.filter((obj) =>
            JSON.stringify(obj).toLowerCase().includes(textValue.toString().toLowerCase())
          )
          console.log("SearchFileredData")
          console.log(SearchFileredData)
          console.log("AllLists")
          console.log(AllLists)
          console.log("defaultListSave")
          console.log(defaultListSave)
       
        this.setState({AllLists: SearchFileredData})
        this.setState({UpdateList: SearchFileredData})
      }else{
        const response = await this.props.getStudentListIncreaseAttemptAssessment(this.state.batchidsave, this.state.assessmentIdsave,this.props.location?.state.fromDPP ? 1 : null);
        if(response !== undefined) {
          this.setState({AllLists: response.data})
          this.setState({UpdateList: response.data})
        }
      }
    },1000);

  }

  onAttemptInputChange = (e,index) =>{
    console.log(e.target.value);
    let attemtValue  = e.target.value;
    let user_id = e.target.id;
    this.setState({dataSendAttemptValue: attemtValue});
    this.setState({dataSendUserId: user_id});
    this.state.UpdateList[index].assessment_attempt_count = attemtValue;
    this.setState({UpdateList: this.state.UpdateList});
    if(e.target.value === "" ||  e.target.value === '0'){
      document.getElementById("btn+"+user_id).classList.add("is_disabled");
    }else{
      document.getElementById("btn+"+user_id).classList.remove("is_disabled");
    }
    
    

  }

  onAttemptDateInputChange= (e,index) =>{
    let attemtDateValue  = e.target.value;
    let user_id = e.target.id;
    console.log("user_id",e.target.id);
    this.setState({dataDateSendAttemptValue: attemtDateValue});
    this.setState({dataSendUserId: user_id});
    this.state.UpdateList[index].end_date = attemtDateValue;
    this.setState({UpdateList: this.state.UpdateList});
    console.log("attemtDateValue",attemtDateValue)
    console.log("dataSendUserId",user_id);
    if(e.target.value === ""){
      document.getElementById("btn_"+user_id).classList.add("is_disabled");
    }else{
      document.getElementById("btn_"+user_id).classList.remove("is_disabled");
    }
  }

  onDateSaveButtonClick = () =>{
    console.log("dataDateSendAttemptValue",this.state.dataDateSendAttemptValue) 
    console.log("dataSendUserId",this.state.dataSendUserId)
    console.log("dataSendAssessmentID",this.state.assessmentIdsave);
    this.attemptValueDateSaveSend(this.state.dataSendUserId,this.state.assessmentIdsave,this.state.dataDateSendAttemptValue, this.state.batchidsave)
  }


  attemptValueDateSaveSend = async (user_id,saveAssessmentID,dueDate,batch_id) =>{
    const response = await this.props.increaseAssessmentDateAttemptCount(user_id,saveAssessmentID,dueDate,batch_id,this.props.location?.state.fromDPP ? 1 : null);
      if(response.status == 1) {
        console.log(response);
          setTimeout(function() { //Start the timer
            this.getStudentListNotifications(this.state.batchidsave);
        }.bind(this), 1000)
        
      }
  }


   onSaveButtonClick = () =>{
    console.log("dataSendAttemptValue",this.state.dataSendAttemptValue)
    console.log("dataSendUserId",this.state.dataSendUserId)
    console.log("dataSendAssessmentID",this.state.assessmentIdsave);
    console.log("Batch_id",this.state.batchidsave);
    this.attemptValueSaveSend(this.state.dataSendUserId,this.state.dataSendAttemptValue,this.state.assessmentIdsave,this.state.batchidsave)
  }

  attemptValueSaveSend = async (user_id,attemtValue,saveAssessmentID,batch_id) =>{
    const response = await this.props.increaseAssessmentAttemptCount(user_id,attemtValue,saveAssessmentID,'',batch_id,this.props.location?.state.fromDPP ? 1 : null);
      if(response.status == 1) {
        console.log(response);
          setTimeout(function() { //Start the timer
            this.getStudentListNotifications(this.state.batchidsave);
        }.bind(this), 1000)
        
      }
  }

  

  batchInputChange = (e) =>{
    //alert(e.target.value);
    let saveBatchInputValue  = e.target.value;
    this.setState({saveBatchInputValue: `${saveBatchInputValue}`});
  }

  batchInputDateChange = (e) =>{
    //alert(e.target.value);
    let saveBatchInputDateValue  = e.target.value;
    console.log("saveBatchInputDateValue",saveBatchInputDateValue)
    this.setState({saveBatchInputDateValue: `${saveBatchInputDateValue}`});
  }

  batchSaveClick = (e) =>{
    this.saveCompleteBatchSIncreaseAttempt(this.state.saveBatchInputValue);
  }

  batchDateSaveClick = (e) =>{
    this.saveCompleteBatchDateIncreaseAttempt(this.state.saveBatchInputDateValue);
  }

  saveCompleteBatchDateIncreaseAttempt = async () => {
    console.log("this.state.batchidsave",this.state.batchidsave)
    console.log("this.state.assessmentIdsave",this.state.assessmentIdsave)
    console.log("this.state.saveBatchInputDateValue",this.state.saveBatchInputDateValue)
   
    const response = await this.props.increaseBatchAssessmentDateAttemptCount(this.state.batchidsave,this.state.assessmentIdsave,this.state.saveBatchInputDateValue,this.props.location?.state.fromDPP ? 1 : null);
    if(response !== undefined) {
      this.setState({ classes: response.data});
      let saveFilterData = response.data.filter((word) => word.id == this.state.classidsave);
      console.log("saveFilterData",saveFilterData);
      let saveLabel = saveFilterData[0].label;
      console.log("saveLabel",saveLabel);
      if(saveLabel){
        this.setState({ classfilterData: saveLabel});
      }
      
      
    }
  }

  


  

  
  saveCompleteBatchSIncreaseAttempt = async () => {
    console.log("this.state.batchidsave",this.state.batchidsave)
    console.log("this.state.assessmentIdsave",this.state.assessmentIdsave)
    console.log("this.state.saveBatchInputValue",this.state.saveBatchInputValue)
   
    const response = await this.props.increaseBatchAssessmentAttemptCount(this.state.batchidsave,this.state.assessmentIdsave,this.state.saveBatchInputValue,this.props.location?.state.fromDPP ? 1 : null);
    if(response !== undefined) {
      this.setState({ classes: response.data});
      let saveFilterData = response.data.filter((word) => word.id == this.state.classidsave);
      console.log("saveFilterData",saveFilterData);
      let saveLabel = saveFilterData[0].label;
      console.log("saveLabel",saveLabel);
      if(saveLabel){
        this.setState({ classfilterData: saveLabel});
      }
      
      
    }
  }

  dateChanger = (d) => {
  return d.split('+')[0];
}

	render() {
    const isEnabled = this.state.dataSendAttemptValue === '';
    const isEnabled1 = this.state.dataDateSendAttemptValue === '';
      if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search classes"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>Increase Attempt</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>Increase Attempt</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search classes"/>
        <div className="main-part">
					<div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  
                </div>
              </div>
            </div>


            <div className='increaseAtmmeptWrapper'>
            <h1><div id="trace-back" onClick={() => this.props.history.goBack()}>
                <img src={Back} alt="back"/>
              </div>Increase Attempt</h1>
               <div className='filtersWrapper'>
                   <div className='filtersBlock'>
                     <div className='block'>
                      <label>Filter By Batch</label> 
                      {/* {this.state.classidsave} */}
                     {/* <select onChange={this.onClassChange}>
                        <option value={null}>
                          Select class
                        </option>
                        {this.state.classes.map(classed =>
                          <option key={classed.id} value={classed.id} selected={this.state.classidsave == classed.id}>
                            {classed.label}
                          </option>
                        )}
                      </select> */}
                      {this.state.sections && this.state.sections?.length > 0 && 
                      <select onChange={(e) => this.onBatchChange(e)} defaultValue={this.state.batchidsave}>
                        <option value=''>
                          Select Batch
                        </option>
                        {this.state.sections.map(sections =>
                          <option key={sections.id} value={sections.id}>
                            {sections.label}
                          </option>
                        )}
                      </select>
                      }
                      </div>
                    </div> 
                    <div className='searchWrapper'><label>Search</label>
                        <input type="search" placeholder="Students name" onChange = {this.searchChange} onFocus={this.handleUsernameFocus} value={this.state.searchText || ''}/>
                    </div>        
               </div>
               <div className='classNameDisplay'>Class Name  :- {this.state.classfilterData}</div>
               {this.state.batchFilterData.map((batchData,index) =>
                <div className='batchNameDisplay'  key={index}>
                  <div>Batch Name  :- {batchData.label}</div>
                  {this.state.AllLists.length > 0 &&
                  <div className='actions'>
                    <div className='increaseAttemptAction'>
                      <label>Update Attempts of Batch</label>
                      <div><input type="number" onChange = {this.batchInputChange} placeholder='Enter attempt'/><button className={this.state.saveBatchInputValue > 0 ? "" : 'disabled'} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onClick = {this.batchSaveClick}>Save</button></div>
                    </div>
                    <div className='dueDateAction'>
                    <label>Update Due Date of Batch</label>
                      <div><input type="datetime-local" onChange = {this.batchInputDateChange} placeholder='Choose Date'/><button className={this.state.saveBatchInputDateValue !== '' ? "" : 'disabled'} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onClick = {this.batchDateSaveClick}>Save</button></div>
                    </div>
                  </div>
                    }
                </div>
                )}
              
                <ol>
                {/* {JSON.stringify(this.state.AllLists)} */}
                {/* {JSON.stringify(this.state.UpdateList)} */}
                {/* {JSON.stringify(this.state.classfilterData)} */}
                
                {this.state.AllLists.map((AllList,index) =>
                  <li  id={AllList?.user?.id ? AllList?.user?.id : AllList?._source?.user_id}  key={index}>
                       <div className="listBlock">
                         <div className="thumb"><img src={AllList.user?.profile_picture ? common.addFileUrl(AllList.user?.profile_picture) : Profile}/></div>
                         <div className='thumbContent'>
                            <div>Name - {AllList?.user?.name ? AllList?.user?.name : AllList?._source?.name}</div>
                            <div>Email - {AllList?.user?.email ? AllList?.user?.email : AllList?._source?.email }</div>
                            <div>Mobile - {AllList?.user?.mobile ? AllList?.user?.mobile : AllList?._source?.mobile}</div>
                         </div>
                        </div>
                         {/* {JSON.stringify(AllList?.assessment_attempt_count)}
                         {JSON.stringify(this.state.UpdateList[index]?.assessment_attempt_count)} */}
                    <div className='actions'>
                      <div className='increaseAttemptAction'>
                      <label>Update Attempts</label>
                        <div>
                          <input type="number" placeholder={AllList?.assessment_attempt_count} value={this.state.UpdateList[index]?.assessment_attempt_count? this.state.UpdateList[index]?.assessment_attempt_count:''}  key={index} onChange={(e) => this.onAttemptInputChange(e,index)} id={AllList?.user?.id ? AllList?.user?.id : AllList?._source?.user_id} />
                          {/* <button className={this.state.UpdateList[index]?.assessment_attempt_count > 0 ? "" : 'disabled'} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}  onClick={this.onSaveButtonClick}>Save</button> */}
                          <button className="is_disabled" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}  onClick={this.onSaveButtonClick} id={AllList?.user?.id ? "btn+"+ AllList?.user?.id : "btn+"+AllList?._source?.user_id}>Save</button>
                        </div>
                      </div>
                      <div className='dueDateAction'>
                        <label>Update Due Date</label>
                        {/* {this.state.UpdateList[index]?.end_date} */}
                        <div>
                          <input type="datetime-local" min={this.state.UpdateList[index]?.old_end_date}  placeholder={AllList?.end_date} value={this.state.UpdateList[index]?.end_date ? this.dateChanger(this.state.UpdateList[index]?.end_date) :''}   key={index} onChange={(e) => this.onAttemptDateInputChange(e,index)} id={AllList?.user?.id ? AllList?.user?.id : AllList?._source?.user_id} />
                          {/* <button className={this.state.UpdateList[index]?.end_date !==  '' || this.state.UpdateList[index]?.end_date > 0  || this.state.is_disabled ? "" : 'disabled'} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}   onClick={this.onDateSaveButtonClick}>Save</button> */}
                          <button className="is_disabled" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}   onClick={this.onDateSaveButtonClick} id={AllList?.user?.id ? "btn_"+AllList?.user?.id : "btn_"+AllList?._source?.user_id}>Save</button>
                        </div>
                      </div>
                    </div>
                      
                      
                    </li>
                )}

                {!this.state.AllLists.length && <div className='noDataFound'>No Data Found</div> }


                    
                </ol>
                
            </div>

           


          </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage
  }
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage,scheduleFBNotifications,getClassList,getSectionList,getStudentListNotifications,increaseAssessmentAttemptCount,increaseAssessmentDateAttemptCount,userSearchList,increaseBatchAssessmentAttemptCount,getStudentListIncreaseAttemptAssessment,increaseBatchAssessmentDateAttemptCount} = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    scheduleFBNotifications: (title,body,batch_ids,image,scheduled_date,channel_id,class_id,is_schedule) => dispatch(scheduleFBNotifications(title,body,batch_ids,image,scheduled_date,channel_id,class_id,is_schedule)),
    getClassList: () => dispatch(getClassList()),
    getSectionList: (center_id, class_id,show_all,page) => dispatch(getSectionList(center_id, class_id,show_all,page)),
    getStudentListNotifications: (center_id,class_id,section_id,page,assessment_id) => dispatch(getStudentListNotifications(center_id,class_id,section_id,page,assessment_id)),
    increaseAssessmentAttemptCount: (user_id,attempt_count,assessment_id,end_date,batch_id,is_toc) => dispatch(increaseAssessmentAttemptCount(user_id,attempt_count,assessment_id,end_date,batch_id,is_toc)),
    increaseAssessmentDateAttemptCount: (user_id,assessment_id,end_date,batch_id,is_toc) => dispatch(increaseAssessmentDateAttemptCount(user_id,assessment_id,end_date,batch_id,is_toc)),
    userSearchList:(key,page) => dispatch(userSearchList(key,page)),
    increaseBatchAssessmentAttemptCount:(batch_id,assignment_id,attempt_count,is_toc) => dispatch(increaseBatchAssessmentAttemptCount(batch_id,assignment_id,attempt_count,is_toc)),
    increaseBatchAssessmentDateAttemptCount:(batch_id,assignment_id,end_date,is_toc) => dispatch(increaseBatchAssessmentDateAttemptCount(batch_id,assignment_id,end_date,is_toc)),
    getStudentListIncreaseAttemptAssessment:(batch_id,assessment_id,is_toc) => dispatch(getStudentListIncreaseAttemptAssessment(batch_id,assessment_id,is_toc)),
    
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(IncreaseAttempt);
