import React, { Component,useState } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import actions from '../../redux/actions';
import common from '../../common';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Pagination from "react-js-pagination";
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import See from '../../public/icons/see.svg';
import backArrow from '../../public/icons/back.svg';
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
     user_id:'', 
     tab1Click:true,
     tab2Click:false,
     tab3Click:false,
     tab4Click:false,
     tab5Click:false,
     tab1Content:true,
     tab2Content:false,
     tab3Content:false,
     tab4Content:false,
     tab5Content:false,
     batchHistoryData:[],
     userPortfolioData:[],
     columnData:[],
     isActive:false,
     activeId:'',
     previewDialog:false,
     previewThumbURL:'',
     dialogOpen:false,
     experienceFilterData:{},
     exactivity_type:'',
     exTitle:'',
     exDesc:'',
     exStartDate:'',
     exEndDate:'',
     exinstitute:'',
     exCertificate:'',
     exCertificateURL:'',
     exIndex:'',
     exEditProfessional:'',

     portfolioTitle:'',
     portfolioURL:'',
     portfolioCertificate:'',
     portfolioIndex:'',

     addEditDialog:false,
     yearsOfExperience:'',
     prefferedLanguage:'',
     userLocation:'',
     addEditLocation:false,
     
     typeYoe:false,
     typePL :false,
     selectedTab : '',
     profileName : ''

    }
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search)
    const user_id = queryParams.get("user_id");
    this.setState({user_id:user_id})
    console.log(user_id);
    this.getBatchHistory(user_id);

   let profileName = this.props.location.state.profileName;
   console.log(profileName);
   this.setState({profileName:profileName})
   
   
   if(localStorage.getItem("selectedTab") === null){
    localStorage.setItem("selectedTab", "1")
   }
   
  }


  tabsClick = (id) => {
    console.log(id);
    this.setState({selectedTab: id});
    localStorage.setItem("selectedTab", id)
  }

  getBatchHistory = async (user_id) => {
    const response = await this.props.getBatchHistory(user_id);
    if(response !== undefined) {
      this.setState({ batchHistoryData: response.data});
      console.log(this.state.batchHistoryData);
      const portfolioresponse = await this.props.getUserPortfolio(user_id);
      if(portfolioresponse?.data !== undefined || portfolioresponse?.status == 1) {
        this.setState({ userPortfolioData: portfolioresponse?.data});
        console.log(this.state.userPortfolioData);
        console.log("portfolioresponse.data.status")
        console.log(portfolioresponse?.status)
      }
      
    }
  }

  accordianItemClick = (id) =>{
    console.log(id)
    const myElement = document.getElementById("accordian" + id);
    console.log("myElement");
    if(myElement.classList.contains('isactive')){
      console.log("if")
      myElement.classList.remove("isactive");
    }else{
      //console.log("if")
      myElement.classList.add('isactive');
    }
    console.log(myElement);
    
  }

dateFormat(inputDate) {
  //var dateStr = inputDate;
  var date = inputDate.split('T');

  var dateVal = date[0]+" "+date[1].split('.')[0]
  
  return dateVal;
  
}


 splitDate(date){
  var exdate = date.split('-');
  var splittedDate = exdate;
  var year = splittedDate[0];
  var month = splittedDate[1];
  var day = splittedDate[2];
  var result  = day + '-' + month + "-" + year ;
  return result;
  
}

previewThumb = (url) =>{
  console.log(url);
  this.setState({previewDialog:true})
  this.setState({previewThumbURL:url});
}

dialogClose = () =>{
  this.setState({previewDialog:false})
}

addEditExperienceDialog = (index, data) => {
  console.log(data);
  this.setState({exIndex:index})
  this.setState({exactivity_type:(data?.Professional_activity_type) ? (data?.Professional_activity_type).toLowerCase() : ''});
  this.setState({exTitle:data.Professional_title});
  this.setState({exDesc:data.Professional_description});
  this.setState({exStartDate:data.Professional_start_date});
  this.setState({exEndDate:data.Professional_end_date});
  this.setState({exinstitute:data.Professional_institute});
  this.setState({exCertificate:data.Professional_certificate_url});
  this.setState({exEditProfessional:data.Professional_certificate_url});
  this.setState({dialogOpen:true})
}
experiencedialogClose = () =>{
  this.setState({dialogOpen:false})
}


exActivityChange = (e)=>{
  console.log(e.target.value);
  this.setState({exactivity_type:e.target.value});
}

exTitleChange = (e)=>{
  console.log(e.target.value);
  this.setState({exTitle:e.target.value});
}

exDescChange = (e)=>{
  console.log(e.target.value);
  this.setState({exDesc:e.target.value});
}
exStartDateChange = (e)=>{
  console.log(e.target.value);
  this.setState({exStartDate:e.target.value});
}

exEndDateChange = (e)=>{
  console.log(e.target.value);
  this.setState({exEndDate:e.target.value});
}

exInstituteChange = (e)=>{
  console.log(e.target.value);
  this.setState({exinstitute:e.target.value});
}

exCertificateChange = (e)=>{
  console.log(e.target.value);
  if(e.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        exCertificate: e.target.files[0],
        exCertificateURL: URL.createObjectURL(e.target.files[0])
      })
    }
}

exUpdate = ()=>{
  const { exactivity_type, exTitle, exDesc, exStartDate, exEndDate, exinstitute,exCertificate,exIndex,user_id,exEditProfessional} = this.state;
  const response =  this.props.addExperience(exactivity_type, exTitle, exDesc, exStartDate,exEndDate,exinstitute,exCertificate,"professional_"+exIndex,user_id,exEditProfessional);
  this.setState({dialogOpen:false});
  console.log(exactivity_type);
  console.log(exTitle);
  console.log(exDesc);
  console.log(exStartDate);
  console.log(exEndDate);
  console.log(exinstitute);
  console.log(exCertificate);
  console.log(user_id);
  console.log(exIndex);
  console.log(exEditProfessional);
}

exCreateNew = ()=>{
  const {exactivity_type, exTitle, exDesc, exStartDate, exEndDate, exinstitute,exCertificate,exIndex,user_id,exEditProfessional} = this.state;
  const response =  this.props.addExperience(exactivity_type, exTitle, exDesc, exStartDate,exEndDate,exinstitute,exCertificate,"new_professional",user_id,exEditProfessional);
  this.setState({dialogOpen:false});
  console.log(exactivity_type);
  console.log(exTitle);
  console.log(exDesc);
  console.log(exStartDate);
  console.log(exEndDate);
  console.log(exinstitute);
  console.log(exCertificate);
  console.log(user_id);
  console.log(exIndex);
  console.log(exEditProfessional);
}

addEditPortfolioDialog = (index, data) => {
  console.log(data);
  this.setState({exIndex:index})
  
}

addEducationLink = (type) => {
  console.log(type);
  this.setState({dialogOpen:true});
  this.setState({exactivity_type:type,exTitle:'',exDesc:'',exStartDate:'',exEndDate:'',exinstitute:'',exIndex:'',exEditProfessional:''})
} 

deletePortfolioLink = (index, data) => {
    const {user_id} = this.state;
    console.log(index);
    console.log(data);
    let exactivity_type = data.Professional_activity_type+ '_professional_journey';
    console.log(exactivity_type)
    console.log(index)
    const response =  this.props.deletePortfolio(exactivity_type, index,user_id);
  }

addExperience  = () => {
  this.setState({typeYoe:true});
  this.setState({addEditDialog:true});
}

addExperiencePL  = () => {
  this.setState({typeYoe:false});
  this.setState({typePL:true});
  this.setState({addEditDialog:true});
}

addEditClose  = () => {
  this.setState({addEditDialog:false});
}


addEditYearsChange = (e) => {
  const {yearsOfExperience} = this.state;
  this.setState({yearsOfExperience:e.target.value});
  console.log(this.state.yearsOfExperience)
}

addEditLanguageChange = (e) => {
  const {prefferedLanguage} = this.state;
  this.setState({prefferedLanguage:e.target.value});
  console.log(this.state.prefferedLanguage)
}

addEditAddLink = (e) => {
  const {user_id,yearsOfExperience,prefferedLanguage} = this.state;
  const response =  this.props.addlanguageYearsOFExperience(yearsOfExperience,prefferedLanguage,user_id);
  
}

editLanguageLink= () => {
  const {user_id,yearsOfExperience,prefferedLanguage,userPortfolioData,location} = this.state;
  this.setState({addEditDialog:true});
  this.setState({typeYoe:true});
  this.setState({typePL:false});
  this.setState({yearsOfExperience:userPortfolioData.no_of_exp});
  this.setState({prefferedLanguage:userPortfolioData.preferred_language});
  this.setState({userLocation:userPortfolioData.location});
}

editLanguageLinkPL= () => {
  const {user_id,yearsOfExperience,prefferedLanguage,userPortfolioData,location} = this.state;
  this.setState({addEditDialog:true});
  this.setState({typeYoe:false});
  this.setState({typePL:true});
  this.setState({yearsOfExperience:userPortfolioData.no_of_exp});
  this.setState({prefferedLanguage:userPortfolioData.preferred_language});
  this.setState({userLocation:userPortfolioData.location});
}

deleteLanguageLink= () => {
  const {user_id,yearsOfExperience,prefferedLanguage,userPortfolioData} = this.state;
  this.setState({addEditDialog:true});
  
}

addLocation= () => {
  const {user_id,userLocation,userPortfolioData} = this.state;
  this.setState({addEditLocation:true});
  
}

addEditLocation = (e) => {
  const {userLocation} = this.state;
  this.setState({userLocation:e.target.value});
  console.log(this.state.userLocation)
}
addEditLocationClose= () => {
  this.setState({addEditLocation:false});
}

addLocationButton= () => {
  const {user_id,yearsOfExperience,prefferedLanguage,userLocation} = this.state;
  const response =  this.props.addlanguageYearsOFExperience(yearsOfExperience,prefferedLanguage,user_id,userLocation);
}

editLocation= () => {
    const {user_id,yearsOfExperience,prefferedLanguage,userPortfolioData,location} = this.state;
    this.setState({addEditLocation:true});
    this.setState({typeYoe:false});
    this.setState({typePL:true});
    this.setState({yearsOfExperience:userPortfolioData.no_of_exp});
    this.setState({prefferedLanguage:userPortfolioData.preferred_language});
    this.setState({userLocation:userPortfolioData.location});
  
}

	render() {
   
    const isEnabled = this.state.exTitle.length > 0;
    if(this.props.loading) {
      return (
        <div className="admin-div">
           <Helmet>
            <title>User Profile</title>
          </Helmet>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>User Profile</title>
        </Helmet>
        <ReactNotifications/>

        <div className={this.state.addEditLocation ? "addEditLocationDialog open " : "addEditLocationDialog"}>
          <div className='addEditContainer'>
                <div className='addEditCloseLink' onClick={this.addEditLocationClose}>X</div>
                <h3>Enter Location</h3>
                <div className='formWrapper'>
                    <div className='formBlock'>
                        <label>Location</label>
                        <input type="text"  onChange={this.addEditLocation} defaultValue={this.state.userLocation} placeholder='Enter location'/>
                    </div>
                </div> 
                <div className='formActions'>
                  <a href='javascript:void(0)' onClick={this.addLocationButton}>Add</a>
                </div>   
          </div>
        </div>


        <div className={this.state.addEditDialog ? "addEditDialog open " : "addEditDialog"}>
          <div className='addEditContainer'>
                <div className='addEditCloseLink' onClick={this.addEditClose}>X</div>
                <h3>{this.state.typeYoe ? "Enter Year of experience" : "Enter Preferred Language" }</h3>
                <div className='formWrapper'>
                {this.state.typeYoe && 
                    <div className='formBlock'>
                        <label>Year of experience</label>
                        <input type="text"  onChange={this.addEditYearsChange}  defaultValue={this.state.yearsOfExperience} placeholder='Enter no of experience'/>
                    </div>
                    }
                    {!this.state.typeYoe && 
                    <div className='formBlock'>
                        <label>Preferred Language</label>
                        <input type="text"  onChange={this.addEditLanguageChange} defaultValue={this.state.prefferedLanguage} placeholder='Enter preferred language'/>
                    </div>
                    }
                </div> 
                <div className='formActions'>
                  <a href='javascript:void(0)' onClick={this.addEditAddLink}>Add</a>
                </div>   
          </div>
        </div>

        <div className={this.state.previewDialog ? "previewDialog open " : "previewDialog"}>
          <div className='previewContainer'>
                <div className='previewCloseLink' onClick={this.dialogClose}>X</div>
              <div className='previewThumb'> 
              <img src={this.state.previewThumbURL}/>
              </div> 
          </div>
        </div>

        <div className={this.state.portfolioDialog ? "portfolioDialog open " : "portfolioDialog"}>
          <div className='portfolioContainer'>
                <div className='portfolioCloseLink' onClick={this.portfolioDialogClose}>X</div>
                <h3>Add Portfoilo</h3>
                <div className='formWrapper'>
                    <div className='formBlock'>
                        <label>Add Title</label>
                        <input type="text" placeholder='Enter Title'/>
                    </div>
                    <div className='formBlock'>
                        <label>Add URL</label>
                        <input type="text"  placeholder='Enter Title'/>
                    </div>
                    <div className='formBlock'>
                      <label>Upload Certificate Image</label>
                       <input type="file" placholder="Enter certificate image"/>
                       <div className='noteText'>*Only png/jpeg files are supported</div>
                    </div>
                </div>
                <div className='formActions'>
                  <a href='javascript:void(0)'>Create</a>
                </div>
            </div>
        </div>

        <div className={this.state.dialogOpen ? "addEditExperienceDialog open " : "addEditExperienceDialog"}>
          <div className='addEditContainer'>
                <div className='addEditCloseLink' onClick={this.experiencedialogClose}>X</div>
                <h3>{this.state.exactivity_type == "education" ? "Add Education Journey" : "Add Professional Journey" }</h3>
                <div className='formWrapper'>
                    {/* <div className='formBlock'>
                      <label>Select activity type</label>
                      <select value={this.state.exactivity_type} disabled onChange={this.exActivityChange}>
                        <option value={''}>
                          Select activity type
                        </option>
                          <option value="education">Education</option>
                          <option value="experience">Experience</option>
                        
                      </select>
                      
                    </div> */}
                    <div className='formBlock'>
                        <label>Add Title<span>*</span></label>
                        <input type="text" onChange={this.exTitleChange} name='title' defaultValue={this.state.exTitle} placeholder='Enter Title'/>
                    </div>
                </div> 
                <div className='formWrapper'>
                    <div className='formBlock'>
                      <label>Description</label>
                       <input type="text" onChange={this.exDescChange} placholder="Enter description" defaultValue={this.state.exDesc}/>
                    </div>
                </div>
                <div className='formWrapper'>
                    <div className='formBlock'>
                      <label>Start date</label>
                       <input type="date" onChange={this.exStartDateChange} placholder="Enter date" max={this.state.exStartDate} defaultValue={this.state.exStartDate}/>
                    </div>
                    <div className='formBlock'>
                      <label>End date</label>
                       <input type="date" onChange={this.exEndDateChange} placholder="Enter date" min={this.state.exStartDate} defaultValue={this.state.exEndDate}/>
                    </div>
                </div> 
                <div className='formWrapper'>
                    <div className='formBlock'>
                      <label>{this.state.exactivity_type == "education" ? "Enter Institute" : "Enter Company"}</label>
                       <input type="text" onChange={this.exInstituteChange} placholder="Enter Institute / Company" defaultValue={this.state.exinstitute}/>
                    </div>
                    {/* <div className='formBlock'>
                      <label>Upload Certificate Image</label>
                       <input type="file" placholder="Enter certificate image" onChange={this.exCertificateChange}/>
                       <div className='noteText'>*Only png/jpeg files are supported</div>
                    </div> */}
                </div>
                <div className='formWrapper'>
                    <div className='formActions'>
                    
                      {this.state.exIndex !== '' ? <button disabled={!isEnabled} onClick={this.exUpdate}>Update</button> : <button  disabled={!isEnabled} onClick={this.exCreateNew}>Create new</button> }
                      
                      
                    </div>
                </div>
          </div>
        </div>


        
		<Header placeholder="User Profile"/>
       <div className='main-part'>
         <div className='batchHistoryWrapper'>
           <div className='batchHistoryContainer'>
             <div className='topHeader'><div className='profilename'>{this.state.profileName}</div></div>
              <div className='BackArrow'><a href="/app/admin/students"><img src={backArrow} alt="back"/>Back to students</a></div>
              <div className='tabsBlock'>
                <div className={parseInt(localStorage.getItem("selectedTab")) == 1 ? "tab1 active" : "tab1"} id="1" onClick={()=>this.tabsClick(1)}>Batch History</div>
                <span></span>
                <div className={parseInt(localStorage.getItem("selectedTab")) == 2  ? "tab2 active" : "tab2"} id="2" onClick={()=>this.tabsClick(2)}>Educational Background</div>
                <span></span>
                <div className={parseInt(localStorage.getItem("selectedTab")) == 3  ? "tab3 active" : "tab3"} id="3" onClick={()=>this.tabsClick(3)}>Professional Background</div>
                <span></span>
                <div className={parseInt(localStorage.getItem("selectedTab")) == 4 ? "tab4 active" : "tab4"} id="4" onClick={()=>this.tabsClick(4)}>Years of Experience</div>
                <span></span>
                <div className={parseInt(localStorage.getItem("selectedTab")) == 5  ? "tab5 active" : "tab5"} id="5" onClick={()=>this.tabsClick(5)}>Language Preferred</div>
                <span></span>
                <div className={parseInt(localStorage.getItem("selectedTab")) == 6  ? "tab5 active" : "tab5"} id="5" onClick={()=>this.tabsClick(6)}>Location</div>
              </div>
              <div className='TabsContent'>
              {parseInt(localStorage.getItem("selectedTab")) == 1 &&
              <div className='tab1Content tabcontent'>
                
                    {/* {JSON.stringify(this.state.batchHistoryData)} */}
                    {this.state.batchHistoryData ?
                    this.state.batchHistoryData  &&  this.state.batchHistoryData.map((data, index) =>  
                    <div id={'accordian'+ data.id} className="accordion" onClick={() => this.accordianItemClick(data.id)}>
                        {data.batch_label !== null ?
                        <div className="accordionItem">
                            <div>{data.batch_label}</div>
                        </div>
                         : <div className="accordionItem">
                              <div>Batch Not found</div>
                          </div>}
                        <div className="tableWrapper">
                        {data.batch_history && data.batch_history.length ? 
                            <table>   
                                <tr>
                                    <th>Batch No.</th>
                                    <th>Batch</th>
                                    <th>Joining Date</th>
                                    <th>Leaving Date</th>
                                  </tr>
                                 
                                 {data.batch_history.map((batchData, index) =>
                                    <tr>
                                        <td>{batchData.version}</td>
                                        <td>{batchData.batch_label}</td>
                                        <td>{this.dateFormat(batchData.added_on)}</td>
                                        <td>{index < data.batch_history.length ? data.batch_history[index-1]?.added_on ? this.dateFormat(data.batch_history[index-1]?.added_on) :"": ''}</td>
                                    </tr>
                                    )}
                             </table> 
                             :<div className='noDataFound'>No Data Found</div>}    
                        </div>
                     </div>
                    ) : <div className='noDataFound'>No Data Found</div>}    

              </div>
              }
              {parseInt(localStorage.getItem("selectedTab")) == 2 &&
              <div className='tab2Content tabcontent' >

                <div className='educationWrapper'>
                      
                        <h3>Education <div onClick={()=>this.addEducationLink("education")}>+Add Education</div></h3>
                        {this.state.userPortfolioData?.education_professional_journey?.length > 0 ?
                        <ol>
                        {this.state.userPortfolioData?.education_professional_journey.map((educationData, index) =>
                            <li>
                                <div className='timelineItem'>
                                      <div className="actions">
                                        <a href="javascript:void(0);" onClick={()=>this.addEditExperienceDialog(index, educationData)}><i className="fa fa-edit"></i>Edit</a>
                                        <a href="javascript:void(0);" onClick={()=>this.deletePortfolioLink(index, educationData)}><i className="fa fa-trash"></i>Delete</a>
                                        </div>
                                    {/* <div className='timelineImg'><img src={educationData.Professional_certificate_url}/></div> */}
                                    <div className='timelineContent'>
                                        <div className='timelineInner'>
                                            <div className='item'>
                                                {/* <div className='thumb'><div className="overlay" onClick={() => this.previewThumb(educationData.Professional_certificate_url)}><i className="fa fa-link"></i></div><img src={educationData.Professional_certificate_url}/></div> */}
                                                <div className='thumb-content'>
                                                    <div className='details'>
                                                        <div className="timelineType"><span>{educationData?.Professional_start_date && this.splitDate(educationData.Professional_start_date)}{educationData?.Professional_end_date && ' - '+ this.splitDate(educationData.Professional_end_date)}</span> </div>
                                                        <div className="title">{educationData.Professional_title}</div>
                                                        <div className="desc">{educationData.Professional_description}</div>
                                                        <div className="institute"> <b>Institute - </b> {educationData.Professional_institute}</div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                             )}
                        </ol>
                        :<div className='noDataFound'>No Data Found</div>}
                    </div>
                    

              </div>
              }
              {parseInt(localStorage.getItem("selectedTab")) == 3 &&
              <div className='tab3Content tabcontent' >
                  <div className='educationWrapper'>
                        <h3>Experience <div onClick={()=>this.addEducationLink("experience")}>+Add Experience</div></h3>
                        {this.state.userPortfolioData && this.state.userPortfolioData?.experience_professional_journey?.length > 0 ?
                        <ol>
                         {/* {JSON.stringify(this.state.userPortfolioData.experience_professional_journey)} */}
                        {this.state.userPortfolioData?.experience_professional_journey.map((professionalData, index) =>
                            <li>
                                <div className='timelineItem' id={index}>
                                    <div className="actions">
                                        <a href="javascript:void(0);" onClick={()=>this.addEditExperienceDialog(index, professionalData)}><i className="fa fa-edit"></i>Edit</a>
                                        <a href="javascript:void(0);" onClick={()=>this.deletePortfolioLink(index, professionalData)}><i className="fa fa-trash"></i>Delete</a>
                                        </div>
                                    {/* <div className='timelineImg'><img src={professionalData.Professional_certificate_url}/></div> */}
                                    <div className='timelineContent'>
                                        <div className='timelineInner'>
                                            <div className='item'>
                                                {/* <div className='thumb'><div className="overlay" onClick={() => this.previewThumb(professionalData.Professional_certificate_url)}><i className="fa fa-link"></i></div><img src={professionalData.Professional_certificate_url}/></div> */}
                                                <div className='thumb-content'>
                                                    <div className='details'>
                                                        <div className="timelineType"><span>{professionalData?.Professional_start_date && this.splitDate(professionalData?.Professional_start_date)}{professionalData?.Professional_end_date && ' - '+ this.splitDate(professionalData?.Professional_end_date)}</span></div>
                                                        <div className="title">{professionalData.Professional_title}</div>
                                                        <div className="desc">{professionalData.Professional_description}</div>
                                                        <div className="institute"> <b>Company - </b>{professionalData.Professional_institute}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            )}
                        </ol>
                         :<div className='noDataFound'>No Data Found</div>}
                    </div>
              </div>
              }


             {parseInt(localStorage.getItem("selectedTab")) == 4 &&
              <div className='tab4Content tabcontent'>
                  <div className='yearsofExperienceWrapper'>
                    {this.state.userPortfolioData?.no_of_exp == '' && 
                    <div className='addLink' onClick={this.addExperience}> + Add Years of experience</div>
                  }
                    {/* {JSON.stringify(this.state.userPortfolioData.no_of_exp)} */}
                    {this.state.userPortfolioData && this.state.userPortfolioData?.no_of_exp ?
                    <ol>
                        <li>
                        <div className="actions">
                            <a href="javascript:void(0);" onClick={this.editLanguageLink}><i className="fa fa-edit"></i>Edit</a>
                          </div>
                          <div>
                          Years of Experience <span>{this.state.userPortfolioData.no_of_exp}</span>
                          </div>
                          </li>
                    </ol>
                    :<div className='noDataFound'>No Data Found</div>}
                  </div>
                </div>
              }


              {parseInt(localStorage.getItem("selectedTab")) == 5 &&
              <div className='tab5Content tabcontent'>
                  <div className='languagePreferredWrapper'>
                  {this.state.userPortfolioData?.preferred_language == '' &&
                  <div className='addLink' onClick={this.addExperiencePL}> + Add Preferred Language</div>
                  }
                  
                  {/* {JSON.stringify(this.state.userPortfolioData.preferred_language)} */}
                  {this.state.userPortfolioData && this.state.userPortfolioData?.preferred_language ?
                    <ol>
                        <li>
                          <div className="actions">
                            <a href="javascript:void(0);" onClick={this.editLanguageLinkPL}><i className="fa fa-edit"></i>Edit</a>
                          </div>
                          <div>
                            Language Preferred <span>{this.state.userPortfolioData.preferred_language}</span>
                            </div>
                        </li>
                    </ol>
                    :<div className='noDataFound'>No Data Found</div>}
                  </div>
                </div>
                 }

              {parseInt(localStorage.getItem("selectedTab")) == 6 &&
              <div className='tab6Content tabcontent'>
                  <div className='languagePreferredWrapper'>
                  {this.state.userPortfolioData?.location == '' &&
                  <div className='addLink' onClick={this.addLocation}> + Add Location</div>
                  }
                  
                  {/* {JSON.stringify(this.state.userPortfolioData.preferred_language)} */}
                  {this.state.userPortfolioData && this.state.userPortfolioData?.location ?
                    <ol>
                        <li>
                          <div className="actions">
                            <a href="javascript:void(0);" onClick={this.editLocation}><i className="fa fa-edit"></i>Edit</a>
                          </div>
                          <div>
                            Location <span>{this.state.userPortfolioData.location}</span>
                            </div>
                        </li>
                    </ol>
                    :<div className='noDataFound'>No Data Found</div>}
                  </div>
                </div>
                 }

              </div>
           </div>
         </div>
       </div>
      </div>    
   
    )
	}
}
const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getBatchHistory,getUserPortfolio,addExperience,deletePortfolio,addlanguageYearsOFExperience} = actions;
  return {
    getBatchHistory: (user_id) => dispatch(getBatchHistory(user_id)),
    getUserPortfolio: (user_id) => dispatch(getUserPortfolio(user_id)),
    addExperience: (activity_type, title, description, start_date,end_date,institute,certificate,professional_key,user_id,edit_url_professional) => dispatch(addExperience(activity_type, title, description, start_date,end_date,institute,certificate,professional_key,user_id,edit_url_professional)),
    deletePortfolio: (key, index,user_id) => dispatch(deletePortfolio(key, index,user_id)),
    addlanguageYearsOFExperience: (no_of_exp, preferred_language,user_id,location) => dispatch(addlanguageYearsOFExperience(no_of_exp, preferred_language,user_id,location)),
   
    
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);