import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';
import Carousel from 'react-elastic-carousel';
import actions from '../../redux/actions';
import Logo from '../../public/icons/IXP-Logo_White-.png';
import LoginImage1 from '../../public/images/imaginxp-loginbanner-1.jpg';
import LoginImage2 from '../../public/images/imaginxp-loginbanner-2.jpg';
import LoginImage3 from '../../public/images/imaginxp-loginbanner-3.jpg';
import LoginImage4 from '../../public/images/imaginxp-loginbanner-4.jpg';
import LoginImage5 from '../../public/images/imaginxp-loginbanner-5.jpg';
//import LoginImage6 from '../../public/images/imaginxp-loginbanner-1.jpg';
//import 'react-notifications-component/dist/theme.css';
import './styles.css';



class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username:'',
      password:'',
      emailid:'',
      mobileno:'',
      emailotp:'',
      mobilenootp:'',
      steponeisdisable:true,
      steptwoisdisable:true,
      isvisible:true,
      completedStep:true,
      completedStep1:true,
      completedStep2:false,
      step1:true,
      step2:false,
      formErrors:{},
      status: 0,
      otpstatus:0,
      count:60,
      message:'',
      resendActive:false,
      resent_otp:1
    }
    this.initialState = this.state;   
   } 


   componentDidMount() {
    //alert("ddd")
    const localStorageEmail = localStorage.getItem('emailid');
    const localStorageMobile = localStorage.getItem('mobileno');
    if(localStorageEmail != '' && localStorageEmail != null && localStorageMobile !== '' && localStorageMobile !== null){
      console.log("if")
        this.setState({isvisible : false, emailid : localStorageEmail, mobileno : localStorageMobile});
        this.resendOTP()
    }else{
      console.log("elase")
      this.setState({isvisible : true});
    }
    console.log("localstorage" +  localStorageEmail, localStorageMobile)


  }


  resendOTP = async() =>{
    const {emailid,mobileno} = this.state; 
    const response = await this.props.xpUserSignUp('', emailid,mobileno,1);
    if(response !== undefined){
      this.setState({status : response?.error})
      console.log(response)
    }
    if(response?.status == 1 ){
      this.setState({isvisible : false});
      localStorage.setItem("emailid", emailid)
      localStorage.setItem("mobileno", mobileno)
      this.setState({status : response?.status})
      console.log("status"+ this.state.status)
      this.setState({count : 60})
      this.inter();
      console.log(response)
    }
  }


  inter = () =>{
   this.counter = setInterval(() => {
      if (this.state.count <= 0) {
        clearInterval(this.inter);
        this.setState({
          message: '',
          resendActive:true
        }); 
      } else {
        this.setState((prevState) => ({count: prevState.count - 1})); 
      }
    }, 1000);
  }

  componentWillUnmount(){
    clearInterval(this.counter)
  }
  


handleChange = (e) => {    
  const { name, value } = e.target; 

  if(name == "emailotp"){
    this.setState({steptwoisdisable:false})
  }   
  this.setState({ [name]: value });    
}

handleKeyPressStep1 = (e) => {
  if (e.key === "Enter") {
    this.handleSubmitStep1();
  }
}


handleKeyPressStep2 = (e) => {
  if (e.key === "Enter") {
    this.handleSubmitStep2();
  }
}

handleFormValidationStep1() {    
  //const { username, emailid, mobileno} = this.state; 
  const {mobileno,emailid} = this.state; 
  let formErrors = {};    
  let formIsValid = true;    

  // if (!username) {    
  //   formIsValid = false;    
  //   formErrors["usernameErr"] = "User Name is required.";    
  // }   

  // if (!password) {    
  //     formIsValid = false;    
  //     formErrors["passwordErr"] = "password is required.";    
  // }    


  //Email    
  if (!emailid) {    
    formIsValid = false;    
    formErrors["emailidErr"] = "Email id is required.";    
}    
else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailid))) {    

    formIsValid = false;    
    formErrors["emailidErr"] = "Invalid email id.";    
}    


//Phone number    
if (!mobileno) {    
  formIsValid = false;    
  formErrors["mobilenoErr"] = "Mobile number is required.";    
}    
else {    
  //var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;    
  // if (mobileno.length === 0) {    
  //     formIsValid = false;    
  //     formErrors["mobilenoErr"] = "Invalid mobile number.";    
  // }

  if (mobileno.length < 10) {    
    formIsValid = false;    
    formErrors["mobilenoErr"] = "Invalid mobile number";    
}  

}    


this.setState({ formErrors: formErrors }); 
return formIsValid;

}


handleFormValidationStep2() {    
  const { mobilenootp,emailotp} = this.state; 
  let formErrors = {};    
  let formIsValid = true;    

  /*if (!mobilenootp) {    
    formIsValid = false;    
    formErrors["mobilenootpErr"] = "Mobile no OTP is required.";    
  } */  

  if (!emailotp) {    
      formIsValid = false;    
      formErrors["emailotpErr"] = "Email OTP is required.";    
  } 
  
 

  this.setState({ formErrors: formErrors }); 
  return formIsValid;

}
    

handleSubmitStep1 = async () => {    

  const {username,emailid,mobileno,status,resent_otp} = this.state;
  if (this.handleFormValidationStep1()) {    
      console.log('You have been successfully Step One Completed.');
      this.setState({steponeisdisable : false});
      //this.setState({completedStep : false});
      //this.setState({completedStep1:false})
      //this.setState({completedStep2:false})
      //this.setState({isvisible : true});
      //console.log("Step 1 data -->" + this.state.username + this.state.emailid + this.state.mobileno + this.state.steponeisdisable, this.state.completedStep1) 
      console.log("Step 1 data -->" + this.state.mobileno + this.state.steponeisdisable, this.state.completedStep1) 
      //this.setState(this.initialState);   

      
      const response = await this.props.xpUserSignUp(username,emailid,mobileno,0);
      if(response !== undefined){
        //if(response?.status === 208) this.setState({isvisible : true});
        //else this.setState({isvisible : false});
        this.setState({status : response?.error})
        console.log(response)
      }
      if(response?.status == 1 ){
        //if(response?.status === 208) this.setState({isvisible : true});
        //else this.setState({isvisible : false});
        this.setState({isvisible : false});
        localStorage.setItem("emailid", emailid)
        localStorage.setItem("mobileno", mobileno)
        this.setState({status : response?.status})
        console.log("status"+ this.state.status)
        this.inter();
        console.log(response)
      }
  }    
}

/*handleSubmitStep2 = async () => { 
  const {emailid,emailotp,mobileno} = this.state  ;
  const response = await this.props.xpUserOTPVerification(emailid,emailotp);

  if(response?.status == 1 ){
    this.createStudent()
  }
  
}


createStudent = async() => {
  const {emailid,emailotp,mobileno} = this.state  ;
  console.log("createStudent")
  const response1 = await this.props.xpStudentCreation('', emailid,mobileno,emailid,emailid,0,0,0,0,1);
  console.log("amit"+response1)
}*/


handleSubmitStep2 = async () => {  
  const {emailid,emailotp,mobileno} = this.state  ;
  if (this.handleFormValidationStep2()) {    
      console.log('You have been successfully step 2registered.');
      this.setState({steptwoisdisable : false});
      console.log("Step 2 data -->") 
      //this.setState(this.initialState); 
      const response = await this.props.xpUserOTPVerification(emailid,emailotp,localStorage.getItem('lang'));
      if(response !== undefined){
        //if(response?.status === 208) this.setState({isvisible : true});
        //else this.setState({isvisible : false});
        //this.setState({otpstatus : response?.status})
        //console.log(response);
        if(response?.status == 1 ){
          //console.log(response);
          const response = await this.props.xpStudentCreation('XYZ', emailid,mobileno,emailid,emailid,0,0,0,0,1,localStorage.getItem('lang'));
          if(response?.status == 1 ){
            const response = await this.props.userLogin(emailid, emailid,1);
          }
        }
      }  
  }    
}






step1 = () => {
  this.setState({step1:true})
  this.setState({step2:false});
  this.setState({steponeisdisable:false})
  this.setState({isnotvisible:false})
  this.setState({isvisible:true})
  this.setState({completedStep1:true})
  this.setState({completedStep2:false})
  
}

step2 = () => {
  this.setState({step1:false})
  this.setState({step2:true})
  this.setState({isnotvisible:true})
  this.setState({isvisible:false})
  this.setState({completedStep1:false})
  this.setState({completedStep2:true})
  
}

  
	render() {
    const { usernameErr, emailidErr, mobilenoErr,mobilenootpErr,emailotpErr} = this.state.formErrors;
    return (
      <div className="login-div">
        <ReactNotifications />
        <div className="login_left">
          <Carousel enableAutoPlay autoPlaySpeed={20000}>
            <items>
              <div className="login-image">
                <img src={LoginImage1} alt="img"/>
              </div>
              {/* <div className="login-title">
              Today's action is tomorrow's future
                <span>ImaginXP empowers you with a skill-boosting guide to mine skills & pave an effective path for future journey</span>
              </div> */}
            </items>
            <items>
              <div className="login-image">
                <img src={LoginImage2} alt="img"/>
              </div>
              {/* <div className="login-title">
              One-stop solution for your career
                <span>Boost your confidence through collaborative live classes and workshops, Connect with industry experts, Daily bite-sized learning nuggets, and open discussion forums to connect with peers.</span>
              </div> */}
            </items>
            <items>
              <div className="login-image">
                <img src={LoginImage3} alt="img"/>
              </div>
              {/* <div className="login-title">
              Profile building is not a day job, it's a continuous activity along with learning
                <span>Boost profile while you learn. ImaginXP provides you a platform to bring yourself live experience learning activities in the real world. </span>
              </div> */}
            </items>
            <items>
              <div className="login-image">
                <img src={LoginImage4} alt="img"/>
              </div>
              {/* <div className="login-title">
              Boost up Digital Quotient
                <span>Digital quotient covers all of your skills to achieve any career objective. DQ enables skills improvement based on AI enabled guided automated tools.</span>
              </div> */}
            </items> 
            <items>
              <div className="login-image">
                <img src={LoginImage5} alt="img"/>
              </div>
              {/* <div className="login-title">
              DQ is omnipresent in NEO World 
                <span>Carry Profile booster DQ everywhere. It adds authenticity to your skills quotient and highlights your profile in all career endeavors.</span>
              </div> */}
            </items> 
            {/* <items>
              <div className="login-image">
                <img src={LoginImage6} alt="img"/>
              </div>
              <div className="login-title">
              Empower yourself with real-world experiences
                <span>You find us your way to fill any skill gap to overcome any industry hurdles.</span>
              </div>
            </items> */}
          </Carousel>
        </div>
        <div className="login_right">
          <div className="login-form-outer">
          <div className="login_header">
            <span><img src={Logo} alt="logo"/></span>
        	</div>
          {/* <div className="login_title">
            Register
        	</div>
          <div className="login-sub-title">
            Welcome to Schools On Web
        	</div> */}
          <div className="login-form">
            
            {/* <div className="register-steps">
                <ul>
                  <li><Link  className= {this.state.completedStep1 ? 'completed-step1':''} onClick={this.step1}><span>1</span></Link></li>
                  <li className={this.state.steptwoisdisable ? 'isdisable':''}><Link  className= {this.state.completedStep2 ? 'completed-step2':''}  onClick={this.step2}><span>2</span></Link></li>
                </ul>
            </div> */}
           {/*Step 1*/} 
          <div className= {this.state.isvisible ? 'isvisible register-step-one':'isnotvisible register-step-one'}>  
              <div class="form-heading">Register</div>
            {/* <div className="input_field">
              <input type="text" name="username" placeholder="Enter Name" 
                onKeyPress={this.handleKeyPressStep1} onChange={this.handleChange} onBlur={this.handleSubmitStep1}  value={this.state.username} className={usernameErr ? ' showError' : ''} />
                {usernameErr &&    
                  <div className="error-msg">{usernameErr}</div>    
                }    
            </div>*/}
            <div className="input_field">
                <input type="email" name="emailid" placeholder="Enter Email address" 
                  onKeyPress={this.handleKeyPressStep1} onChange={this.handleChange} onBlur={this.handleSubmitStep1}  value={this.state.emailid} className={emailidErr ? ' showError' : ''}/>
                  {emailidErr &&    
                  <div className="error-msg">{emailidErr}</div>    
                }  
            </div> 
            <div className="input_field">
              <input type="number" name="mobileno" placeholder="Enter Mobile Number" 
                onKeyPress={this.handleKeyPressStep1} onChange={this.handleChange}   value={this.state.mobileno}  className={mobilenoErr ? ' showError' : ''}/>
                {mobilenoErr &&    
                  <div className="error-msg">{mobilenoErr}</div>    
                }  
            </div> 
            {/* <div className="input_field">
              <input type="password" name="password" placeholder="Enter Password" 
                onKeyPress={this.handleKeyPress} onChange={this.handleChange} value={this.state.password}  className={passwordErr ? ' showError' : ''}/>
                {passwordErr &&    
                  <div className="error-msg">{passwordErr}</div>    
                }  
            </div>  */}
            <div className="next-btn"><button  onClick={()=>this.handleSubmitStep1()}>Send OTP</button></div>
          </div>
          {/*Step 2*/}
          <div className= {this.state.isvisible ? 'isnotvisible register-step-two':'isvisible register-step-two'}>  
              <div class="form-heading">Register</div>
            <div className="input_field">
              <input type="number" name="emailotp" placeholder="Enter OTP" 
                onKeyPress={this.handleKeyPressStep2} onChange={this.handleChange} onBlur={this.handleChange} value={this.state.emailotp} className={emailotpErr ? ' showError' : ''} />
                {emailotpErr &&    
                  <div className="error-msg">{emailotpErr}</div>    
                }    
            </div>
            <div className="otp-block">
              <div>Time left :  {this.state.message ? this.state.message : this.state.count}</div>
                <a href="javascript:void(0);"  onClick={()=>this.resendOTP()} className= {this.state.resendActive ? 'resendactive':''}>Resend OTP</a>
              </div>
            {/* <div className="input_field">
                <input type="text" name="mobilenootp" placeholder="Enter mobile no OTP" 
                  onKeyPress={this.handleKeyPressStep2} onChange={this.handleChange} onBlur={this.handleChange} value={this.state.mobilenootp} className={mobilenootpErr ? ' showError' : ''}/>
                  {mobilenootpErr &&    
                  <div className="error-msg">{mobilenootpErr}</div>    
                }  
            </div>
            <div className="otp-block">
                <div>Timer here</div>
                <Link>Resend OTP</Link>
              </div> */}
            <div className="next-btn step-2-btn"><button  onClick={()=>this.handleSubmitStep2()} className={this.state.steptwoisdisable ? 'isdisable':''}>Sign Up & Sign In</button></div>
          </div>
          {/*Step 2*/}
            <div className="register-block">Are you a member?<Link to={'/login'}> Login now</Link></div>
          </div> 
          </div>  
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { xpUserSignUp, xpUserOTPVerification,xpStudentCreation,userLogin } = actions;
  return {
    xpUserSignUp: (name,email,phone,resent_otp) => dispatch(xpUserSignUp(name,email,phone,resent_otp)),
    xpUserOTPVerification: (email,email_otp,language) => dispatch(xpUserOTPVerification(email,email_otp,language)),
    xpStudentCreation: (name, email, mobile, password, username,class_id, batch_id, center_id, wp_user_id ,is_xp_signup,language) => dispatch(xpStudentCreation(name, email, mobile, password, username,class_id, batch_id, center_id, wp_user_id ,is_xp_signup,language)),
    userLogin: (username, password,is_xp_signup) => dispatch(userLogin(username, password,is_xp_signup))
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (Register)