import React, { Component } from "react";

import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight
} from "./pdf-react";

import Service from "../../../utils/Service";
import axios from 'axios';
import common from '../../../common';
import config from '../../../config.js';

import Spinner from "./Spinner";
import "./pdfPlayer.css";
import { PdfViewerComponent, Toolbar, Magnification, Navigation, Inject } from '@syncfusion/ej2-react-pdfviewer';


import type {
  T_Highlight,
  T_NewHighlight
} from "../../pdf-react/types";

type T_ManuscriptHighlight = T_Highlight;

type Props = {};

type State = {
  highlights: Array <T_ManuscriptHighlight>
};

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

class PdfPlayer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      url: '',
     	token: this.props.location.search.split('&')[0].split('=')[1],
     	content_id: this.props.location.search.split('&')[1].split('=')[1],
      highlights: [],
     	loading: true
		}
	}

  resetHighlights = () => {
    this.setState({
      highlights: []
    });
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

	componentDidMount() {
    let fileUrl = "" ;
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );
		axios.get(`${common.apiBase}/${common.contentDetails}${this.state.content_id}/`,
			{headers: {
			  'Authorization': 'token ' + this.state.token}
			}).then(res => {
        fileUrl = res.data.data.content?.file_details[0]?.file_url;
        this.load(fileUrl)

      	this.setState({ url: res.data.data, loading: false })
    }).catch(err => { console.error('error', err.message) })

    this.load()
	}

  load = (fileUrl) => {
    var pdfdata;
    var staticUrl = config.apiDomain+fileUrl;
    console.log(staticUrl)
    var xhr = new XMLHttpRequest(); 
    xhr.open('GET', staticUrl, true); 
    xhr.responseType = 'blob'; 
    xhr.onload = function (e) { 
        if (this.status == 200) { 
            var myBlob = this.response; 
            var reader = new window.FileReader(); 
           
            reader.readAsDataURL(myBlob); 
            reader.onloadend = function () { 
              var base64data = reader.result;                   
              var pdfviewer = (document.getElementById('container1')).ej2_instances[0];       
         
              pdfviewer.load(base64data, null); 
            } 
        } 
    }
    xhr.send(); 
  }

  getHighlightById(id: string) {
    const { highlights } = this.state;

    return highlights.find(highlight => highlight.id === id);
  }

  addHighlight(highlight: T_NewHighlight) {
    const { highlights } = this.state;

    console.log("Saving highlight", highlight);

    this.setState({
      highlights: [{ ...highlight, id: getNextId() }, ...highlights]
    });
  }

  updateHighlight(highlightId: string, position: Object, content: Object) {
    console.log("Updating highlight", highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map(h => {
        return h.id === highlightId
          ? {
              ...h,
              position: { ...h.position, ...position },
              content: { ...h.content, ...content }
            }
          : h;
      })
    });
  }
	
	render() {
    const { highlights } = this.state;
		return (
        <div>
          <PdfViewerComponent id="container1"  enablePrint={false} toolbarSettings={{ showTooltip: true, toolbarItem: ['PageNavigationTool','MagnificationTool','DownloadOption','DownloadOption'] }}  serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer" style={{ 'height': '640px' }}>
              <Inject services={[Toolbar,Navigation,Magnification]}/>
          </PdfViewerComponent>
        </div>
    );
	}
}

export default PdfPlayer;