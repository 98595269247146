import React, { Component } from 'react';
import EmptyImg from '../../public/icons/amplifiu-mobile-not-ready.svg';
import ampLogo from '../../public/icons/amplifiu-logo.png';


import './styles.css';
import config from '../../config';
class MobileNotReady extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setLogoURL: ''
    }

  }
  render() {
  return (
    <div className="mobileNotReadyWrapper">
    <div className='waveBg'></div>
    <div className='mobileNotReadyContent'>
        <span className='logo'><img src={ampLogo}/></span>
         <h4>AmplifiU is not ready for mobiles yet.</h4>
      <p>Please bear with us and access it on a computer for best experience</p>
      <img src={EmptyImg} alt="no-data"/>
    </div>
    </div>
  ) 
}
}

export default MobileNotReady;