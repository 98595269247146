import React, { Component } from 'react';
// import MaintenanceImg from '../../public/icons/website-under-maintenance.svg';
import ampLogo from '../../public/icons/amplifiu-logo.png';

import './styles.css';
import config from '../../config';

class WebsiteUnderMaintenance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      setLogoURL: ''
    }
  }

  render() {
    return (
      <div className="maintenanceWrapper">
        <div className='waveBg'></div>
        <div className='maintenanceContent'>
          <span className='logo'><img src={ampLogo} alt="AmplifiU Logo" /></span>
          <h4>Our website is currently under maintenance.</h4>
          <p>We're working hard to improve your experience. Please check back later.</p>
          {/* <img src={MaintenanceImg} alt="Maintenance Mode" /> */}
        </div>
      </div>
    );
  }
}

export default WebsiteUnderMaintenance;
