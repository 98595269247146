import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import Back from '../../public/icons/back.svg';
import Delete from '../../public/icons/delete.svg';
// import List from '../../public/icons/list.svg';
// import Grid from '../../public/icons/grid.svg';
// import Edit from '../../public/icons/edit.svg';
// import Delete from '../../public/icons/delete.svg';
// import Listing from '../../public/icons/listing.svg';
import Trash from '../../public/icons/trash.svg';
// import Stamp from '../../public/icons/stamp.svg';
// import Calendar from '../../public/icons/calendar.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import './styles.css';

class PracticeSections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      opend: false,
      title: this.props.location?.state?.title,
      center_id: localStorage.getItem('center_id'),
      branch: this.props.location?.state?.center,
      assessment_id: this.props.location?.state?.assessment_id,
      class_id: this.props.location?.state?.class_id,
      subject_id: this.props.location?.state?.subject_id,
			list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      filterSections: [],
      filterData: [],
      sections: [],
      section_id: '',
      user_id: localStorage.getItem("user_id"),
		}
  }
  
  componentDidMount() {
    this.getSectionList();
    this.getPracticeSections(this.state.branch, this.state.assessment_id);
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  show = () => this.setState({ open: true, section_id: '' });
  
  close = () => this.setState({ open: false });

  showd = (val) => this.setState({ opend: true, d_id: val });
  
  closed = () => this.setState({ opend: false });

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  getSectionList = async () => {
    const response = await this.props.getSectionList(this.state.branch, this.state.class_id);
    if(response !== undefined) {
      this.setState({ filterSections: response.data });
      
    }
  }

  getPracticeSections = async (center, assessment_id) => {
    const response = await this.props.getPracticeSections(1, center, assessment_id);
    if(response !== undefined) {
      this.setState({ sections: response.data.batches });
      this.setState({ filterData: response.data.data });
    }
  }

  sectionChange = (e) => {
    this.setState({ section_id: e.target.value });
  }

   deleteSection = async () => {
    const { d_id } = this.state;
    await this.props.deleteDPP(d_id);
    this.setState({ opend: false });
  }

  assignSection = async () => {
    const { assessment_id, title, class_id, subject_id, section_id } = this.state;
    await this.props.assignSection(assessment_id, title, class_id, subject_id, section_id,
      new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0], true);
  }

	render() {
    const isEnabled = this.state.section_id !== '';
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search assessments"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h2>{this.props.pagewiseLanguageData.practice_test_add_sections}</h2>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.practice_test_add_sections}</title>
        </Helmet>
				<Header placeholder="Search"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content5">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.assign_new_section}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data5">
            <div className="popup-inputs">
              <select value={this.state.section_id} onChange={this.sectionChange}>
                <option value={''}>
                 {this.props.pagewiseLanguageData.select_section}
                </option>
                {this.state.filterSections.map((section, index) =>
                  <option value={section.id} key={index}>
                    {section.label}
                  </option>
                )}
              </select>
            </div>
            <div className="popup-inputs">
            </div>
          </div>
          <div className="filter-buttons">
            <button onClick={this.assignSection} disabled={!isEnabled}>
              {this.props.pagewiseLanguageData.assign}
            </button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
            {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteSection}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
				<div className="main-part">
					<div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
                <Link id="trace-back" to={`/admin/practice-tests`}>
                  <img src={Back} alt="back"/>
                </Link>
                <h2>{this.props.pagewiseLanguageData.practice_test_add_sections}</h2>
							</div>
						</div>
						<div className="heading-part2">
              <span onClick={this.show}>
								<img src={Add} alt={this.props.pagewiseLanguageData.assign_new}/>{this.props.pagewiseLanguageData.assign_new}
							</span>
              {/* {!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>List View
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>Grid View
              </span>} */}
						</div>
					</div>
          {this.state.sections.length === 0 ? <Empty/> : <>
            <div className="the-grid">
              {this.state.sections.map((section, index) =>
                <div className="grid-item" key={section.id}>
                  <div className="grid-up topActions">
                    <div className="title-grid">
                      <b>{section.label}</b>
                    </div>
                    <div className="grid-actions">
                    
                      {/* <span title="Edit" onClick={() => this.show2(section)}>
                        <img src={Edit} alt="edit"/>
                      </span>*/}
                      {this.state.filterData.map((filter, key) =>
                        <div>
                          {index == key && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(filter.assessment)} Style={(filter.assigned_by == this.state.user_id || localStorage.getItem('is_admin')) ? "":"display:none"}>
                            <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                          </span>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid-middle">
                      {this.props.pagewiseLanguageData.code}
                      <span><b>{section.short_code}</b></span>
                  </div>
                  <div className="grid-middle">
                    {this.props.pagewiseLanguageData.Class}
                    <span>{section.class_name}</span>
                  </div>
                </div>
              )}
            </div>
          </>}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getSectionList, getPracticeSections, assignSection,deleteDPP,getPageLanguageWiseData } = actions;
  return {
    deleteDPP: (id) => dispatch(deleteDPP(id)),
    getSectionList: (center_id, class_id) => dispatch(getSectionList(center_id, class_id)),
    getPracticeSections: (data_type, center, assessment_id) => dispatch(getPracticeSections(data_type, center, assessment_id)),
    assignSection: (assessment, title, classs, subject, batch, scheduled_date, is_released) => dispatch(assignSection(assessment, title, classs, subject, batch, scheduled_date, is_released)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticeSections);