import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import basketIcon from '../../public/icons/lessonplanner-basket-icon.svg';
import communicationIcon from '../../public/icons/lessonplanner-communication-icon.svg';
import config from '../../config';
import blanklessonplanThumb from '../../public/icons/blanklessonplan.svg';
import lessonplanThumb from '../../public/icons/amplifiu-lesson-plan-icon.svg';
import lessonplanRubricThumb from '../../public/icons/lessonplan-rubric.svg';
import lessonplanAssessmentThumb from '../../public/icons/lessonplan-assessment.svg';
import lessonplanQuizThumb from '../../public/icons/lessonplan-quiz.svg';
import lessonplanTextlevellerThumb from '../../public/icons/lessonplan-textleveller.svg';
import lessonplanSummariserThumb from '../../public/icons/lessonplan-summariser.svg';
import lessonplanGroupactivityThumb from '../../public/icons/lessonplan-groupactivity.svg';
import lessonplanClassnewsletterThumb from '../../public/icons/lessonplan-classnewsletter.svg';
import lessonplanLoading from '../../public/icons/ajax-loader.gif';
import featureCoimingSoon from '../../public/icons/feature-coming-soon.svg';
import disclaimerIcon from '../../public/icons/disclaimer-icon.svg';
import previewIcon from '../../public/icons/amplifiu-preview-icon.svg';
import editIcon from '../../public/icons/amplifiu-edit-icon.svg';
import makeacopy from '../../public/icons/amplifiu-makeacopy-icon.svg';
import download from '../../public/icons/amplifiu-download-icon.svg';
import trashicon from '../../public/icons/trashicon.svg';
import versionhistoryicon from '../../public/icons/version-history-icon.svg';
import conceptNoteicon from '../../public/icons/amplifiu-concept-note-icon.svg';
import debateicon from '../../public/icons/amplifiu-debate-icon.svg';
import groupDiscussionicon from '../../public/icons/amplifiu-group-discussion-icon.svg';
import projecticon from '../../public/icons/amplifiu-project-icon.svg';
import roleplayicon from '../../public/icons/amplifiu-roleplay-icon.svg';
import unitPlanicon from '../../public/icons/amplifiu-unit-plan-icon.svg';
import collaborateIcon from '../../public/icons/amplifiu-collaborate-welcome.svg';
import collaborateIcon2 from '../../public/icons/amplifiu-collaborate-section2.svg';
import collaborateEventIcon from '../../public/icons/amplifiu-collaborate-events.svg';
import collaborateResourcesIcon from '../../public/icons/amplifiu-collaborate-resources.svg';
import collaborateGetStatedIcon from '../../public/icons/amplifiu-collaborate-get-started.svg';

import collaborateGuidelineIcon1 from '../../public/icons/amplifiu-collaborate-guidelines-icon.svg';
import collaborateGuidelineIcon2 from '../../public/icons/amplifiu-collaborate-guidelines-icon2.svg';
import collaborateGuidelineIcon3 from '../../public/icons/amplifiu-collaborate-guidelines-icon3.svg';
import collaborateGuidelineIcon4 from '../../public/icons/amplifiu-collaborate-guidelines-icon4.svg';

import moment from 'moment';
import 'moment-timezone';
import MobileNotReady from '../../components/error/MobileNotReady';


class Terms extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      selected:'',
      token:localStorage.getItem('token'),
      user_id : localStorage.getItem('user_id'),
      lessonPlanDialogOpen:false,
      saveLessonPlanName:'',
      saveLessonPlanClass:'',
      saveLessonPlanSubject:'',
      saveLessonPlanTopic:'',
      saveLessonPlanBoard:'',
      AllListingData:[],
      OrignalAllListingData:{},
      dialogOpen:false,
      previewContent:'',
      loading:false,
      is_search:false,
      comingSoondialogOpen:false,
      iconsloaded:false,
      sortbyValue:'',
      page: 2,
      searchTerm:'',
      confitmationDialogOpen:false,
      saveIdForDelete:'',
      getIDofLastLi: '',
      timer: null,
      advanceSearchDialogOpen:false,
      versionHistoryDialogOpen:false,
      saveVersionhistryId:'',
      selectVersionHistoryID:0,
      toggleViewAll:true,
      saveAdvanceGrade:'',
      saveAdvanceSubject:'',
      saveAdvanceCurriculum:'',
      saveAdvanceDuration:'',
      advancedSearchStarted: false,
      saveclickIndex:'-1',
      popIndex: null,
      facultySidebarHidden_ids:true,
      center_id : '',
      isScrolled:false,
      nameColumnClass: '',
      typeColumnClass: '',
      gradeColumnClass: '',
      modifiedColumnClass: ''

    };
    
   
  }

  
  componentDidMount() {
   
    
  }


  componentWillUnmount() {
    
  }

   
  render() {
   
    return (
  
       
       <div className='termsPageWrapper'>
              <div className='termsPageContainer'> 
              {localStorage.getItem('logo_url') != 'undefined'? 
                  <span className='logo'><img src={localStorage.getItem('logo_url')} alt="Brand logo"/></span>
                : 
                <span className='logo'><img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/></span>
                }
               <h2>Terms of Service – AmplifiU</h2>
               <p>Version 1.0 – June 2024</p>

              <h3>Introduction </h3>
              <p>Welcome to AmplifiU! These Terms of Service ("Terms") govern your access to and use of the AmplifiU platform and its products and services ("Software"), provided by AI FutureCraft Pty Ltd ("Company," "we," or "us"). By accessing or using our Software, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the Software. </p>
              <h3>Definitions</h3>
              <ul>
                <li><strong>"Software"</strong> means the AmplifiU Software as a Service (SaaS) AI solution provided by AI FutureCraft Pty Ltd. </li>
                <li><strong>"User"</strong> or <strong>"you"</strong> means any individual or entity accessing or using the Software.</li>
                <li><strong>"Content"</strong> means any text, images, video, audio, or other materials uploaded, downloaded, or appearing on the Software.</li>
                <li><strong>"User Data"</strong> means any data, information, or material that you upload, submit, or otherwise transmit to the Software while using the Software. </li>
              </ul>
              <h3>Acceptance of Terms</h3>
              <p>By accessing and using the AmplifiU platform and its products, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the platform. </p>
              <h3>Modifications to Terms</h3>
              <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on the platform. Your continued use of the Software after such changes have been posted constitutes your acceptance of the new Terms. </p>
              <h3>User Responsibilities </h3>
              <ul>
                <li><strong>Account Security:</strong> You are responsible for maintaining the security and confidentiality of your account and password. You agree to notify us immediately of any unauthorized use of your account.</li>
                <li><strong>Lawful Use:</strong> You agree to use the platform only for lawful purposes and in compliance with all applicable laws and regulations.</li>
                <li><strong>User Conduct:</strong>You agree not to engage in any conduct that could damage, disable, overburden, or impair the platform, or interfere with any other party's use and enjoyment of the platform. </li>
              </ul>
              <h3>User Content</h3>
              <ul>
                <li><strong>Ownership:</strong> You retain all rights and ownership of your Content uploaded and or created on AmplifiU. We do not claim any ownership rights in your Content.</li>
                <li><strong>Content Responsibility:</strong>You are solely responsible for all Content that you upload, post, email, or otherwise transmit via the Software. We do not control the Content posted via the Software and, as such, do not guarantee the accuracy, integrity, or quality of such Content. </li>
              </ul>
              <h3>Intellectual Property Rights</h3>
              <p>All intellectual property rights in the AmplifiU platform are owned by AI FutureCraft Pty Ltd or its licensors. You acknowledge and agree that you do not acquire any ownership rights by using the platform. </p>
              <h3>Payment and Billing </h3>
              <p>The purchase of any paid products or services offered through the platform is governed by additional terms related to billing and payment processing. By making a purchase, you agree to those terms. </p>
              <h3>Service Availability</h3>
              <p>While we strive to provide constant availability of the AmplifiU platform, we do not guarantee that the service will be uninterrupted or error-free. We may also perform maintenance on the platform, which may result in temporary service disruptions. </p>
              <h3>Privacy</h3>
              <p>Your use of the Software is also governed by our Privacy Policy, which is incorporated by reference into these Terms. By using the Software, you consent to the collection and use of your data as outlined in the Privacy Policy. </p>
              <h3>Disclaimer of Warranties</h3>
              <p>The Software is provided "AS IS" and "AS AVAILABLE" without warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the Software will meet your requirements or that the operation of the Software will be uninterrupted or error-free. </p>
              <h3>Limitation of Liability</h3>
              <p>To the maximum extent permitted by law, AI FutureCraft Pty Ltd shall not be liable for any indirect, incidental, special, or consequential damages, or any loss of profits, revenue, data, or use, arising out of or in connection with your use of the AmplifiU platform. </p>
              <h3>Indemnification </h3>
              <p>You agree to indemnify, defend, and hold harmless the Company, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Software. </p>
              <h3>Governing Law </h3>
              <p>These Terms are governed by and construed in accordance with the laws of New South Wales, Australia. Any disputes arising out of or related to these Terms will be subject to the exclusive jurisdiction of the courts of New South Wales, Australia.</p>
              <h3>Dispute Resolution</h3>
              <p>Any disputes arising out of or related to these Terms or the Software shall be resolved through binding arbitration in accordance with the rules of the Australian Centre for International Commercial Arbitration (ACICA). The arbitration shall be conducted in Sydney, New South Wales, and the language of the arbitration shall be English. </p>
              <h3>Termination </h3>
              <p>We reserve the right to suspend or terminate your account and access to the Software if you violate these Terms or for any other reason at our sole discretion. You may terminate your account at any time by ceasing all use of the Software and notifying us. </p>
              <h3>Third-Party Services </h3>
              <p>The Software may contain links to third-party websites or services that are not owned or controlled by the Company. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services. </p>
              <h3>Miscellaneous </h3>
              <ul>
                <li><strong>Severability:</strong> If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect. </li>
                <li><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and the Company regarding the use of the Software and supersede all prior or contemporaneous understandings and agreements, whether written or oral, regarding such subject matter. </li>
                <li><strong>UNo Waiver:</strong>The failure of the Company to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. </li>
              </ul>
              <h3>Contact Information </h3>
              <p>If you have any questions or concerns about these Terms, please contact Paras Aggarwal (<a href="mailto:paras@amplifiu.com">Paras@amplifiu.com</a>), AI FutureCraft Pty Ltd, NSW, Australia. </p>
              <p>By using the Software, you acknowledge that you have read, understood, and agree to be bound by these Terms. </p>

            </div>
          </div>
        
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,amplifiuThreadCreation,amplifiuFetchThreadDocumentListing,amplifiuSelectActiveInactive,htmlToPDF,amplifiuLessonPlanClone} = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    amplifiuThreadCreation: (user_id,form_json) => dispatch(amplifiuThreadCreation(user_id,form_json)),
    amplifiuFetchThreadDocumentListing: (limit,last_id,user_id,grade,subject,curriculum,duration) => dispatch(amplifiuFetchThreadDocumentListing(limit,last_id,user_id,grade,subject,curriculum,duration)),
    amplifiuSelectActiveInactive: (id,status) => dispatch(amplifiuSelectActiveInactive(id,status)),
    htmlToPDF: (html_data) => dispatch(htmlToPDF(html_data)),
    amplifiuLessonPlanClone: (id,thread_id,openai_userthread_id,user_id) => dispatch(amplifiuLessonPlanClone(id,thread_id,openai_userthread_id,user_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms);