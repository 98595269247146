import React from 'react';

import EmptyImg from '../../public/images/nothing-to-show.png';
import './styles.css';

const EmptyBox = (props) => {
  return (
    <div className="empty-box-div">
      <img src={EmptyImg} alt="no-data"/>
      {props?.statement}
    </div>
  )
}

export default EmptyBox;