import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
// import month from '../../public/icons/month.svg';
import idea from '../../public/icons/idea.svg';
// import Target from '../../public/icons/target.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import MultiSelect from "react-multi-select-component";
// import InfiniteScroll from "react-infinite-scroll-component";
//import 'react-calendar/dist/Calendar.css';
import projectThumb from '../../public/icons/studio-project-icon.png';

import common from '../../common';

class StudioProjectListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected:'',
      loading: true,
      center_id:'',
      user_id:'',
      projectList: [],
      token:'',
      todayDate:''
      
    }
   
  }
  
  componentDidMount() {
    let getCenterID = localStorage.getItem("center_id");
    let getUserID = localStorage.getItem("user_id");
    let getToken = localStorage.getItem("token");
    var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      let todayDate = yyyy + '-' + mm + '-' + dd;
      
      
      this.getProjectList(todayDate);

      console.log("today", todayDate)
      
    if(getToken){
      this.setState({token : getToken});
    }
    if(getUserID){
      this.setState({user_id : getUserID});
    }
    if(getCenterID){
      this.setState({center_id : getCenterID});

    }

    
    
  }

  componentWillUnmount() {
    
  }

  
  getProjectList = async (todayDate) => {
    const response = await this.props.studioProjectListing(todayDate,'forward','15','15');
    if(response && response != undefined){
      console.log("projectList",response);
      this.setState({projectList : response.data})
      
    }
  }

  getFormattedDocumentDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }

  render() {
     
    return (
      <>
    
      <div className="app-body studioProjectLearner" id="appBody" onScroll={this.handleScroll}>
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>Studio Project - Learner</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>

            <div className='studioProjectWrapper'>
              <div className='studioProjectContainer'>
                {/* {JSON.stringify(this.state.projectList)} */}
              <h3>Studio Project Listing</h3>
              {this.state.projectList &&  this.state.projectList?.length > 0 &&  this.state.projectList.map(list =>
                  <div className='userListWrapper' id={list.id}>
                  <div className='userListLeft'>
                      <div className='userListThumb'><img src={projectThumb}/></div>
                      <div className='userListContent'>
                        <h3>{list.title}</h3>
                        <p>Score - <span>{list.score}</span></p>
                        <p>Start Time - <span>{this.getFormattedDocumentDate(list.start_date_time)}</span></p>
                        <p>End Time - <span>{this.getFormattedDocumentDate(list.end_date_time)}</span></p>
                        <p>Faculty Review - <span>{list.is_faculty_reviewed ? "Yes" : "No"}</span></p>
                      </div>
                   
                  </div>
                 
                  {!list.is_faculty_reviewed  && (new Date(list.end_date_time).getTime() > new Date().getTime()) && (new Date(list.start_date_time).getTime() < new Date().getTime()) &&
                   <div className='userListRightActions'>
                    <Link to={{pathname: `/learner/studio-project/${list.id}` , state:{ProjectID:list.id, projectTitle:list.title, projectScheduledDate:list?.start_date_time, projectDueDate:list?.end_date_time,fromDashboard: false }}}>
                    Attempt
                    </Link>
                  </div>
                  }
                </div>
              )}
              </div>  
            </div>
          
           
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { studioProjectListing} = actions;
  return {
    studioProjectListing: (date,days,direction,if_loading) => dispatch(studioProjectListing(date,days,direction,if_loading)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioProjectListing);