import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './mycoach.css';
import HeaderMyCoach from './HeaderMyCoach';
import axios from 'axios';
import common from '../../common';

class Challenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      mycoachdata:[],
      profile: {},
      center_id:'',
      type:'',
      challengesData:[],
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
   }
  }
  
   componentDidMount() {
    localStorage.setItem("page","challenges");
    if(this.state.is_webview){
      localStorage.setItem('token', this.state.token);
      this.getLearnerProfile();
      
    }else{
      this.getLearnerProfile();
    }
    //this.getAllAssignmentList();
  }


  getLearnerProfile = async () => {
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.userProfile}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(res => {

        console.log(res?.data.data.center_id)
          this.setState({center_id:res?.data.data.center_id})
          this.setState({type:'challenges'})
          this.getAllAssignmentList(res?.data.data.center_id);
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.getLearnerProfile();
    
      if(response?.status == 1){
        this.setState({center_id:response?.data?.center_id})
        this.setState({type:'challenges'})
        console.log("center_id" + this.state.center_id);
        console.log("type" + this.state.type);
        this.getAllAssignmentList();
        
      }
    }
  }

  getAllAssignmentList = async (center_id) => {
    if(this.state.is_webview){
      let type = this.state.type
      console.log(center_id)
      await axios.post(`${common.apiBase}/${common.xpGetAllChallengesAssignmentList}`,{center_id,type},{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
          this.setState({challengesData : response?.data.data});
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.xpGetAllChallengesAssignmentList(this.state.center_id,this.state.type);
      if(response?.status == 1){
        console.log(response);
        this.setState({challengesData : response?.data});
        console.log("data" + JSON.stringify(this.state.challengesData));
      }
    }
  }

  getDate = (date) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString([],options);
  }


	render() {
    const MAX_LENGTH = 100;
    const MAX_LENGTH_INNER = 250;
    return (
      <>
      <ReactNotifications/>
      {!this.state.is_webview && <HeaderMyCoach/>}
        <div className={"app-body "+(this.state.is_webview == 1 ? 'webview' : '')}>
          
          <div className="app-div mycoach-div">
            <Helmet>
              <title>Challenges - Learner</title>
            </Helmet>
            <div className="mycoach-wrapper">

                <div className="challenges-block">
                    <h3>Its not just about classrooms, participate in contests and challenges and win prize money</h3>
                    {/* <p>Its not just about classrooms, participate in contests and challenges and win prize money</p>   */}

                    <div className="design-block">
                      {/* <h3>Challenges (Design)</h3> */}
                      <ol>
                          {this.state.challengesData.map(item =>
                           <li key={item.id}>
                              {/* <div className="thumb"></div> */}
                              <div className="thumb-title">{item.title}</div>
                              <div className="hoverOverlay">
                                {/* <div className="thumb-title">{item.title}</div> */}
                                <div className="thumb-content">{`${item.description.substring(0, MAX_LENGTH_INNER)}...`}</div>
                                <div className="thumb-action">
                                {this.state.is_webview ? <Link className="" key={item.id} 
                                    to={{ pathname: `/learner/assignment-reviews/${item.id}?is_webview=1&token={this.state.token}`,
                                    state: {action:item?.action[0]?.label,subject:item.subject,id:item.subject_id}}}>
                                    Take It up
                                  </Link> :<Link className="" key={item.id} 
                                    to={{ pathname: `/learner/assignment-review/${item.id}`,
                                    state: {action:item?.action[0]?.label,subject:item.subject,id:item.subject_id}}}>
                                    Take It up
                                  </Link>}
                                
                                </div>
                              </div>
                              <div className="thumb-content">{`${item.description.substring(0, MAX_LENGTH)}...`}</div>
                              <div className="thumb-action">
                                {/* <div className="duedate-tag"><span>{this.getDate(item.due_date)}</span></div> */}
                                  {this.state.is_webview ?<Link className="" key={item.id} 
                                    to={{ pathname: `/learner/assignment-reviews/${item.id}?is_webview=1&token=${this.state.token}`,
                                    state: {action:item?.action[0]?.label,subject:item.subject,id:item.subject_id}}}>
                                    Take It up
                                  </Link> : <Link className="" key={item.id} 
                                    to={{ pathname: `/learner/assignment-review/${item.id}`,
                                    state: {action:item?.action[0]?.label,subject:item.subject,id:item.subject_id}}}>
                                    Take It up
                                  </Link>}
                                  
                              </div>
                         </li>
                        )}       
                       
                      </ol>
                    </div>
                </div>
               </div>
            </div>
          </div>
        
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage
   
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getLearnerProfile, xpGetAllChallengesAssignmentList} = actions;
  return {
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    xpGetAllChallengesAssignmentList: (center_id,type) => dispatch(xpGetAllChallengesAssignmentList(center_id,type)),
    
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);