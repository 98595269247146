import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';

import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './styles.css';


const response = {
   "data": [
       {
           "id": 1,
           "key": "institute_wise_free_courses",
           "label": "Institute Wise Free Courses",
           "options": {
               "type": "json"
           },
           "option_type": 0
       },
       {
           "id": 2,
           "key": "dashboard_type",
           "label": "Dashboard Type",
           "options": {
               "type": "string"
           },
           "option_type": 0
       },
       {
           "id": 3,
           "key": "menu",
           "label": "Menu",
           "options": {
               "type": "menu",
               "values": [
                   {
                       "label": "Dashboard",
                       "order": 4,
                       "url": "/api/dashboard",
                       "image": "////",
                       "help-text": "it is for help"
                   },
                   {
                       "label": "Events",
                       "order": 4,
                       "url": "/api/dashboard",
                       "image": "////",
                       "help-text": "it is for help"
                   }
               ]
           },
           "option_type": 0
       },
       {
           "id": 4,
           "key": "custom_header_text",
           "label": "header",
           "options": {
               "type": "json"
           },
           "option_type": 0
       }
   ],
   "error": [],
   "status": 1,
   "currentDateTime": "2021-06-29T14:28:25+05:30"
}


class AdminSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      opendes: false,
      openi: false,
      next_step: false,
      id: this.props.match.params.id,
      loading: false,
      center_id: localStorage.getItem('center_id'),
      title: '',
      code: '',
      timed_type: '',
      timed_duration_mins: '',
      sections: '',
      isGraded: false,
      attemptsClose: false,
      is_negative:false,
      isTimed :false,
      resultAfter: false,
      solutionAfter: false,
      endDateTime: new Date(),
      resultAfterDate: new Date(),
      solutionAfterDate: new Date(),
      closed_after_passing: null,
      time_bound_type:0,
      result_after: null,
      show_solution_after: null,
      total: '',
      passing: '',
      difficulty: 0,
      instruction: '',
      description: '',
      type: '',
      timeline: 1,
      attempts: 0,
      setting: [],
      settingList:[],
      csutomdata:[],
      menuVal:[],
      value:'',
      key:'',
      menuSetting:{},
      menuSettings:[],
      url: this.props.location.state?.url === undefined ? null : this.props.location.state?.url,
      breads: this.props.location?.state?.breads,
      toc_id: this.props.location.state?.toc_id === undefined 
                ? null : this.props.location.state?.toc_id,
      class_id: this.props.location.state?.class_id === undefined 
                  ? null : this.props.location.state?.class_id,
      subject_id: this.props.location.state?.subject_id === undefined 
                    ? null : this.props.location.state?.subject_id
    }
  }

  componentDidMount() {
    /*this.getAssessment(this.props.match.params.id);
    this.getSectionsList();*/
    //this.masterSettingList();
    this.settingList();
    this.getUrlfromCurrentPage();  
    
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
  }


  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"menu");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  masterSettingList = async () => {
    const response = await this.props.masterSettingList();
    this.setState({ settingList: response.data,csutomdata:response.data });
   

  }

  changeSetting = (e) =>{
    let {menuSetting} = this.state
    let btnId = 'btn_' + e.target.name;
    let btn = document.getElementById(btnId);
    btn.classList.add("btnActive");
    this.setState({key:e.target.name,value:e.target.value})
    
  }

  toggleSolutionAfterChange = async(opt) => {
    let {value,menuVal,settingList} = this.state;
    menuVal.push(opt);

  
    this.setState({key:opt.name,value:menuVal})
  }

  settingList = async () => {

    let {menuSetting,settingList,menuSettings} = this.state

    settingList = await this.props.masterSettingList();

    const response = await this.props.settingList();
    let custom =[];
    if(response != undefined){
      let setting = response.data.filter( f => parseInt(f.center_id) == parseInt(this.state.center_id))
      
      for(let i=0;i<setting.length;i++){
        menuSetting[setting[i].key] ={'value':setting[i].value,'id':setting[i].id}
      
      }

      var custom1 = {};

      for(let i=0;i<menuSetting?.menu?.value.length;i++){

        custom.push(menuSetting.menu?.value[i].label);
        custom1[menuSetting.menu?.value[i].label] = menuSetting.menu?.value[i].checked
      }

      menuSettings = setting.filter( item => item.key == "menu");
      
      menuSettings[0].options?.values?.length > 0 && menuSettings[0].options.values.map((item) =>{ 
          if(custom.includes(item.label)){ 
            if(custom1[item.label] == false){
              item.checked = false;
            }else{
              this.toggleSolutionAfterChange(item);
              item.checked = true;
            }
          }else{
            item.checked=false;
          }
        }
      )

      this.setState({menuSetting,settingList,menuSettings});

      this.setState({loading:false})
    }
    
  }

  getSectionsList = async () => {
    const response = await this.props.getAssessmentSectionList(this.props.match.params.id,true);
    this.setState({ sectionsList: response.data });
  }

  getAssessment = async (id) => {
    const response = await this.props.getAssessment(id);
    this.setState({ title: response.data.title, code: response.data.uid, 
                    timed_type: response.data.timed_type, duration: response.data.timed_duration_mins, 
                    sections: response.data.sections.length, type: response.data.type,
                    timed_duration_mins: response.data.timed_duration_mins,
                    instruction: response.data.instructions, description: response.data.description,
                    difficulty: response.data.difficulty, attempts: response.data.attempts_allowed,
                    isGraded: response.data.total_marks === 0 ? false : true,
                    passing: response.data.passing_marks + '', total: response.data.total_marks + '',
                    closed_after_passing: response.data.closed_after_passing === true ? 1 : 0,
                    time_bound_type: response.data?.time_bound_type,
                    attemptsClose: response.data.closed_after_passing == true || response.data.time_bound_type == 2? true :false,
                    resultAfter: response.data.result_after === 0 ? false :true,
                    result_after: response.data.result_after,
                    solutionAfter: response.data.show_solution_after === 0 ? false : true,
                    show_solution_after: response.data.show_solution_after,
                    is_negative: response.data.is_negative,
                    isTimed:response.data.timed_type ? true:false })

  }

  show = () => this.setState({ open: true });
  
  close = () => this.setState({ open: false });

  showdes = () => this.setState({ opendes: true });
  
  closedes = () => this.setState({ opendes: false });

  showi = () => this.setState({ openi: true });
  
  closei = () => this.setState({ openi: false });

  onTitleChange = (e) => {
    this.setState({ title: e.target.value })
  }

  onCodeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  onTypeChange = (val) => {
    this.setState({ timed_type: val ,duration : 5})
  }

  changeDuration = (e) => {
    const duration = (e.target.validity.valid) ? e.target.value : this.state.duration;
    this.setState({ duration });
  }

  totalChange = (e) => {
    const total = (e.target.validity.valid) ? e.target.value : this.state.total;
    this.setState({ total })
  }

  passingChange = (e) => {
    const passing = (e.target.validity.valid) ? e.target.value : this.state.passing;
    this.setState({ passing })
  }

  onDifficultChange = (val) => {
    this.setState({ difficulty: val })
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  onInstructionChange = (e) => {
    this.setState({ instruction: e.target.value })
  }
  
  minusAttempt = (e) => {
    if(this.state.attempts !== 0) {
      var attempt = this.state.attempts - 1;
      this.setState({ attempts: attempt })
    }
  }

  addAttempt = (e) => {
    var attempt = this.state.attempts + 1;
    this.setState({ attempts: attempt })
  }

  levelChange = (val) => {
    this.setState({ level: val });
  }

  timelineChange = (val) => {
    this.setState({ timeline: val });
  }

  toggleGraded = () => {
    if(this.state.isGraded === true) {
      this.setState({ passing: '', total: '' })
    }
    this.setState({ isGraded: !this.state.isGraded })
  }

  toggleAttemptClose = () => {

    this.setState({ attemptsClose:!this.state.attemptsClose});
  }

  toggleNegativeClose = () => {

    this.setState({ is_negative:!this.state.is_negative});
  }


  toggleResultAfter = () => {
    if(this.state.resultAfter === true) {
      this.setState({ result_after: 0 })
    }
    this.setState({ resultAfter: !this.state.resultAfter })
  }

  toggleTimedClose = () => {
    this.setState({ isTimed:!this.state.isTimed})
    if(!this.state.isTimed === false){
      this.setState({ timed_duration_mins:0,duration:0,timed_type:0})
    }
    if(this.state.isTimed){
      this.setState({duration:5})
    }
  }

  toggleSolutionAfter = async(e,opt) => {
    let btnId = 'btn_' + e.target.name;
    let btn = document.getElementById(btnId);
    btn.classList.add("btnActive");
    let {value,menuVal} = this.state;
     if(e.target.checked){
      opt.checked = true
      menuVal.push(opt);
     

    }else{
      
      opt.checked = false
      let label = opt.label
      menuVal.splice(value.findIndex(f => f.label == label),1)
    }
    console.log("menuVal1")
    console.log(menuVal)
    
    this.setState({key:e.target.name,value:menuVal})
  }


  changeAttemptAfter = (val) => {
    //this.setState({ closed_after_passing: val })
    if(val == 2){
      this.setState({ time_bound_type: 2 });
      this.setState({ closed_after_passing: 0 });
    }
    if(val == 1){
      this.setState({ closed_after_passing: 1 });
      this.setState({ time_bound_type: 0 });
    }
  }

  changeResultAfter = (val) => {
    this.setState({ result_after: val })
  }

  changeSolutionAfter = (val) => {
    this.setState({ show_solution_after: val })
  }

  changeEndDate = (date) => {
    this.setState({ endDateTime: date })
  }

  changeSolutionDate = (date) => {
    this.setState({ solutionAfterDate: date })
  }

  changeResultDate = (date) => {
    this.setState({ resultAfterDate: date })
  }

  editAssessmentBasic = async () => {
    this.setState({open:false})
    let duration_seconds = 0;
    let duration = this.state.duration;
    const { id, code, title, center_id, timed_type} = this.state;

    if(timed_type === 2){
      this.state.sectionsList.map((section,index1) => {
        section.questions.map((data,index) => {
          duration_seconds += data.question_data.duration_seconds;
        })
      })
      duration = Math.floor(duration_seconds / 60);
    }
    if(timed_type === 0){
      duration = 0;
    }

    await this.props.editAssessmentBasic(id, code, title, center_id, timed_type, duration);
  } 

  updateAssessment = async (id, options) => {
    this.setState({loading:true})
    const {key,value,center_id,menuSetting,settingList} = this.state;
    if(menuSetting[key]){
      const response = await this.props.submitSetting(menuSetting[key].id,key,value,null,center_id,options);
      //this.settingList()
    }else{
      const response = await this.props.submitSetting(null,key,value,null,center_id,options)
      //this.settingList()
    }
    
  }


	render() {
    const { isGraded, attemptsClose, resultAfter, solutionAfter, difficulty, next_step, 
              id,is_negative,isTimed,settingList,menuSettings} = this.state;
    const isEnabled = this.state.title.length > 0 && this.state.code.length > 0
    if(this.props.loading) {
      return (
        <div className="admin-div">
        </div>
      )
    }
    if(next_step) {
      return (
        <Redirect to={{ pathname: `/admin/assessment-questions/${id}`,
                        state: { toc_id: this.state?.toc_id, class_id: this.state?.class_id, 
                          subject_id: this.state?.subject_id, data: this.props.location.state?.data,
                          url: this.state.url } }}/>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.assessment_settings}</title>
        </Helmet>
        <ReactNotifications/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}
          className="popup-content2">
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_basic_details}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data2">
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.assessment_title} onChange={this.onTitleChange}
                value={this.state.title}/>
              <input placeholder={this.props.pagewiseLanguageData.test_code} onChange={this.onCodeChange}
                value={this.state.code}/>
            </div>
            <div className="base-settings">
              <div>
                
                <div className="asm-setting-div-popup">
                  <div className="asm-setting-option">
                    <span>{this.props.pagewiseLanguageData.is_timed}</span>
                    <>
                      <input
                        checked={isTimed}
                        onChange={this.toggleTimedClose}
                        className="react-switch-checkbox"
                        id={`react-switch-istimed`}
                        type="checkbox"
                      />
                      <label
                        style={{ background: isTimed && '#489198' }}
                        className="react-switch-label"
                        htmlFor={`react-switch-istimed`}
                      >
                        <span className={`react-switch-button`} />
                      </label>
                    </>
                  </div>
                  {isTimed && <div className="asm-setting-horizon">
                    <div>
                      <input name="time" type="radio" value={1} onClick={() => this.onTypeChange(1)}
                        checked={this.state.timed_type === 1}/>
                      <label htmlFor={1}>{this.props.pagewiseLanguageData.timed_on_total}</label>
                      {this.state.timed_type === 1 ? <div className="asm-setting-duration">
                        <div><input value={this.state.duration} onInput={this.changeDuration}
                          type="text" pattern="[0-9]*"/> {this.props.pagewiseLanguageData.mins}</div>
                      </div> : null}
                    </div> 
                    <div>
                      <input name="time" type="radio"  value={2} onClick={() => this.onTypeChange(2)}
                        checked={this.state.timed_type === 2}/>
                      <label htmlFor={2}>{this.props.pagewiseLanguageData.timed_on_individual_question}</label>
                    </div> 
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="popup-actions-onedit">
            <button disabled={!isEnabled} onClick={this.editAssessmentBasic}>Save</button>
          </div>
        </Popup>
        <Popup open={this.state.opendes} closeOnDocumentClick onClose={this.closedes}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.assessment_description}</span>
            <textarea value={this.state.description} onChange={this.onDescriptionChange}
              placeholder={this.props.pagewiseLanguageData.enter_description}/>
            <div>
              <button onClick={this.closedes}>
                {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <Popup open={this.state.openi} closeOnDocumentClick onClose={this.closei}
          className="popup-content6">
          <div className="assessment-descrip">
            <span>{this.props.pagewiseLanguageData.assessment_instruction}</span>
            <textarea value={this.state.instruction} onChange={this.onInstructionChange}
              placeholder={this.props.pagewiseLanguageData.enter_instructions}/>
            <div>
              <button onClick={this.closei}>
              {this.props.pagewiseLanguageData.save}
              </button>
            </div>
          </div>
        </Popup>
        <div className="asm-header">
          <div id="no_save"></div>
          <div>{this.props.pagewiseLanguageData.institute_settings}</div>
          
        </div>
        <div className="asm-container asm-setting-container">
          <div className="asm-ques">
            <div className="asm-info" id="asm-info2">
            {this.state.loading ? 
                    <div className="admin-setting-loader">
                      <div className="admin-setting-inner"></div>
                    </div>
                  :
              settingList.data?.length > 0 && settingList.data.map(stt => 
                <>{
                  stt.options.type === "menu" ? <div className="asm-setting-div inst-setting menu-setting">
                    <div className="asm-setting-option">
                      <span className="menuheading">
                        {stt.label}
                      </span>
                      <div className="menuwrapper">
                      {menuSettings[0].options?.values?.length > 0 && menuSettings[0].options?.values.map((opt) => 
                        <>
                        <div className="menublock"><input defaultChecked={opt.checked}
                          onChange={(e) => this.toggleSolutionAfter(e,opt)}
                          type="checkbox"
                          name={stt.key}
                        />{opt.label}</div>
                        </>
                      )}
                      </div>
                    </div>
                    <button id={'btn_'+stt.key}  onClick={() => this.updateAssessment(stt.id,stt.options)}>
                      {this.props.pagewiseLanguageData.save}
                    </button>
                  </div>:<div className="asm-setting-div inst-setting">
                    <div className="asm-setting-option">
                      <span>
                        {stt.label}
                      </span>
                      <>
                        <input defaultValue={typeof(this.state.menuSetting[stt.key]?.value) == "object" ?JSON.stringify(this.state.menuSetting[stt.key]?.value):this.state.menuSetting[stt.key]?.value} onInput={this.changeSetting}
                          type="text" name={stt.key}/>
                      </>
                    </div>
                    <button id={'btn_'+stt.key} onClick={() => this.updateAssessment(stt.id,stt.options)}>
                    {this.props.pagewiseLanguageData.save}
                    </button>
                  </div>
                }
                </>
              )}

            </div>
          </div>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessment,masterSettingList,settingList,submitSetting, updateAssessment , editAssessmentBasic,getAssessmentSectionList,getPageLanguageWiseData,getLanguageWiseData} = actions;
  return {
    settingList:() => dispatch(settingList()),
    submitSetting:(id,key,value,ext,center_id,options) =>dispatch(submitSetting(id,key,value,ext,center_id,options)),
    masterSettingList:() =>dispatch(masterSettingList()),
    getAssessment: (id) => dispatch(getAssessment(id)),
    getAssessmentSectionList: (id,ifloading) => dispatch(getAssessmentSectionList(id,ifloading)),
    updateAssessment: (id, difficulty, attempts_allowed, total_marks, passing_marks, closed_after_passing, result_after, show_solution_after, instructions, description,isGraded,time_bound_type,is_negative) => dispatch(updateAssessment(id, difficulty, attempts_allowed, total_marks, passing_marks, closed_after_passing, result_after, show_solution_after, instructions, description,isGraded,time_bound_type,is_negative)),
    editAssessmentBasic: (id, uid, title, center_id, timed_type, timed_duration_mins) => dispatch(editAssessmentBasic(id, uid, title, center_id, timed_type, timed_duration_mins)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSetting);