import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import { isEmpty } from 'lodash';
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import Clock from '../../public/icons/clock.svg';
import Success from '../../public/icons/success.svg';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import Zoom from '../../public/icons/full-screen.svg';
import download from '../../public/icons/down-arrow.svg';
import MFR from '../../public/icons/Icon-material-bookmark-border.svg';
import NMFR from '../../public/icons/Icon-material-bookmark.svg';
import Eraser from '../../public/icons/eraser.svg';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import './styles.css';

const ques_types = {
  1: 'MCQ',
  2: 'Single Integer',
  3: 'Multiple Response',
  4: 'Fill in the blanks',
  5: 'True or False',
  6: 'Subjective',
  7: 'Matching'
}



const abc = {
  1 : 'a',
  2 : 'b',
  3 : 'c',
  4 : 'd',
  5 : 'e',
  6 : 'f',
  7 : 'g',
  8 : 'h',
  9 : 'i',
  10 : 'j'
}

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 10,
    slidesToSlide: 10
  },
  tablet: {
    breakpoint: { max: 720, min: 590 },
    items: 10,
    slidesToSlide: 10
  },
};


const SlideButtons = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const { carouselState: { totalItems } } = rest;
  const lastSlide = Math.floor(totalItems / 4) + (totalItems % 4) - 1;
  if(totalItems <= 10) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="slide-buttons-xy">
      <img src={Left} className='slide-button-xy' onClick={() => previous()} alt="img" id="forward-arrow"/>
      <img src={Right} className='slide-button-xy' onClick={() => next()} alt="img" id="backward-arrow"/>
    </div>
  );
};

const AttemptModal = (props) => (
  <Popup className="attempt-modal" open={props.showAttemptModal} onClose={props.closeAttemptModal}
    closeOnDocumentClick>
    <div className="close-modal" onClick={props.closeAttemptModal}>{/*<img src={Cancel} alt='close-modal' />*/}</div>
    {/* <AssessmentPreview 
      contentPreview={props.contentPreview}
      attempt={props.attemptPaper}
      showAttemptbutton={false}
    /> */}
  </Popup>
)

class StartTimer extends Component{
  constructor(props){
    super(props)
    this.startTime = (new Date()).getTime();
    this.second = 1000;
    this.minute = this.second * 60;
    this.hour = this.minute * 60;
    this.postQuestions = []
    this.state = {
      currentTime: props.startTime,
      differenceTime:0
    }
  }
  
  componentDidMount(){
    
    this.timer = setInterval(()=>this.getCurrentTime(), 1000);

  }

  

  getCurrentTime = () => {
          
    let end = new Date();
    this.distance = end.getTime() - this.startTime
    
    this.props.onChange(this.distance)
    if(this.props.startTime === '00:00:00') {
      let initialTime = this.props.totalTime * 60 * 1000
      let difference = initialTime - this.distance
      if(difference > 0){
        let hours = Math.floor(difference /this.hour);
        let minutes = Math.floor((difference % this.hour) / this.minute);
        let seconds = Math.floor((difference % this.minute) /this.second);
        this.setState({currentTime: `${common.pad(hours)}:${common.pad(minutes)}:${common.pad(seconds)}`})
      }
    } else {
      let initialTime = this.props.totalTime * 1000
      
      let difference = initialTime - this.distance
      
      this.setState({differenceTime:difference})
      if(difference > 0){
        let minutes = Math.floor((difference / this.minute));
        let seconds = Math.floor((difference % this.minute) /this.second);
        this.setState({currentTime: `${common.pad(minutes)}:${common.pad(seconds)}`,differenceTime:difference})
      }
      if(difference <= 0){
        this.props.onChange(this.distance,difference,this.props.timed_type,this.props.quesId)
      }
    }
  } 

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  startTimerForNextQuestion = () => {

    this.startTime = (new Date()).getTime() - (this.props.time_taken *1000);
    this.timer = setInterval(()=>this.getCurrentTime(), 1000)
  }

  render() {
    return (
    <span className="timer" style={this.props.notify > 0 ? {color: '#ec6972'} : {}}>
      { this.props.totalTime !== 0 &&
        <><>
          {this.props.id == "totalTime" && this.state.currentTime}
        </>  
        <>
          {this.props.id == "questionTime" && this.props.timed_type === 2 && this.state.currentTime}{this.props.startTime==='00:00' && this.props.timed_type === 2 ?'s':''}  
        </></>
      }
      {/* {this.state.currentTime}{this.props.startTime==='00:00'?'s':''} */}
    </span>
    )
  }
}

class AttemptPaper extends Component{
  constructor(props){
    super(props)
    this.postQuestions = []
    this.state = {
      loading: true,
      id: this.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      no_ques: false,
      sections: [],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      options: [],
      five_min: false,
      ten_min: false,
      finish: false,
      finish2: false,
      success: false,
      success2: false,
      redirect: false,
      timer: 0,
      notify: 0,
      users_file: null,
      open5:false,
      selected_image:null,
      selected_image_on:null,
      file_extension:"",
      file:[],
      quesTimeArray:[],
      countOn : 1,
      onOptionSelect:0,
      currentSectionId:0,
      isAttemptAllowed:0
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
    this.questionTimer = React.createRef();
  }

  componentDidMount() {
    // this.renderMath()
    this.getAttemptData()
    //this.getAttemptPreview()
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
    
  }
  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"learner");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }

  
 

  getAttemptPreview = async () =>{
    const { assessmentId } = this.props.match.params
     const attemptReviewResponse = await this.props.getAttemptPreview(assessmentId);
     
      if(attemptReviewResponse != undefined){
        if(attemptReviewResponse.data.attempts_taken < attemptReviewResponse.data.attempts_allowed){
          this.getAttemptData()
        }else{
          this.setState({isAttemptAllowed:true})
        }
     }
  }


  getAttemptPreview = async () =>{
    const { assessmentId } = this.props.match.params
     const attemptReviewResponse = await this.props.getAttemptPreview(assessmentId);
     
      if(attemptReviewResponse != undefined){
        if(attemptReviewResponse.data.attempts_taken < attemptReviewResponse.data.attempts_allowed){
          this.getAttemptData()
        }else{
          this.setState({isAttemptAllowed:true})
        }
     }
  }



   /*componentDidUpdate() {
     this.saveQuestionStatus();
   }*/

  // renderMath() {
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node2.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node3.current
  //   ])
  // }

  getAttemptData = async () => {
    const { assessmentId } = this.props.match.params

    this.setState({id:assessmentId})
    await this.props.getAssessmentAttempt(assessmentId)
  }

  close5 = () => this.setState({ open5: false ,selected_image:null});

  zoomOn = (url) => {
    this.setState({open5:true,selected_image:url})
  }

  componentWillReceiveProps(nextProps) {

    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    if(nextProps.exerciseAttempt !== this.props.exerciseAttempt){
      let exerciseAttempt = JSON.parse(JSON.stringify(nextProps.exerciseAttempt))
      // let langOption = exerciseAttempt.languages.map(lang => {
      //   return {key: lang.id, text: lang.name, value: lang.id}
      // })
      this.postQuestions = []
      exerciseAttempt.sections.map(section => { 
        var questionArray = section.questions.map((question, index) => {
          question.question_number = "Q"+(index+1);
          if(question.question_type === 'Match The Following') {
            return {
              question_id: question.id,
              time_taken: 0,
              match_solution: question.analytics.match_solution === undefined 
                                ? {} : question.analytics.match_solution,
              is_answered: 0,
              is_visited: 0,
              action:0,
              is_completed :0,
              section_id:section.id
            }
          }
          else if(question.question_type === 'Subjective') {
            return {
              question_id: question.id,
              time_taken: 0,
              solution: question.analytics.solution === undefined 
                          ? "" : question.analytics.solution,
              is_answered: 0,
              is_visited: 0,
              action:0,
              users_file:this.state.users_file,
              is_completed :0,
              section_id:section.id
            }
          }
          else {
            return {
              question_id: question.id,
              time_taken: 0,
              selected_option_id: question.analytics.selected_option === undefined 
                                    ? [] : question.analytics.selected_option,
              is_answered: 0,
              is_visited: 0,
              action:0,
              is_completed :0,
              section_id:section.id
            }
          }
        })
        this.postQuestions = [...this.postQuestions, ...questionArray]
      })
      exerciseAttempt.sections.map(section => {
        section.showQuestions = false;
        section.questions.map(question => {
          question.options = question.question_type === 'Match The Following' ? this.matchData(question) : question.options
          question.status = question.analytics.is_visited ? question.analytics.selected_option.length > 0 
                              ? "Answered" : "Not Answered" : "Not Visited"
          question.selected_option_id = question.analytics.selected_option === undefined 
                                          ? [] : question.analytics.selected_option
          question.solution = question.solution
          question.match_solution = isEmpty(question.match_solution) ? this.matchDataSolution(question) 
                                      : question.match_solution
          question.time_taken = 0
        })
      })

      let currentQuestion = this.getQuestion(exerciseAttempt);

      exerciseAttempt.sections[this.getSection(exerciseAttempt)].showQuestions = true;
      

      if(currentQuestion) {
        currentQuestion.status = currentQuestion.status;
        currentQuestion.action = 1;

        if(exerciseAttempt.sections[0].questions[0].status == "Not Visited"){
          exerciseAttempt.sections[0].questions[0].status= "Not Answered";
          this.postQuestions[0].action = 1;
        }
        //currentQuestion.status = "Not Answered";
       
        if(currentQuestion.image){
          //console.log(currentSection.questions[index].image.split('/').pop())
          if(blockedTile.indexOf(currentQuestion.image.split('.').pop()) != -1)
          {  
            this.setState({file_extension:1,selected_image_on:currentQuestion.image.split('/').pop()}); //1 is used for image file
          }else{
            this.setState({file_extension:2,selected_image_on:currentQuestion.image.split('/').pop()}); //2 is used for content file
          }
        }

        this.setState({
          exerciseAttempt: exerciseAttempt, 
          currentQuestion, 
          match_solution: currentQuestion.match_solution,
          selected_option_id: currentQuestion.selected_option_id,
          currentSection: exerciseAttempt.sections[this.getSection(exerciseAttempt)]
        });
      }
      else{
        this.setState({ noQuestionAvailable: true, exerciseAttempt })
      }
    }
  }

  getQuestion = (exerciseAttempt) => {
    for(var i = 0; i < exerciseAttempt.sections.length; i++) {
      if((exerciseAttempt.sections[i].questions.findIndex(p => p.id === exerciseAttempt.last_attempted_question_id)) !== -1) {
        const questionIndex = exerciseAttempt.sections[i].questions.findIndex(p => p.id === exerciseAttempt.last_attempted_question_id)
        return exerciseAttempt.sections[i].questions[questionIndex];
      }
    }
    return exerciseAttempt.sections[0].questions[0];
  }

  getSection = (exerciseAttempt) => {
    for(var i = 0; i < exerciseAttempt.sections.length; i++) {
      if((exerciseAttempt.sections[i].questions.findIndex(p => p.id === exerciseAttempt.last_attempted_question_id)) !== -1) {
        return i;
      }
    }
    return 0;
  }

  matchData = (data) => {
    var org_data = data.options;
    var obj1 = {};
    var obj2 = {};
    var obj3 = {};
    var left_arr = [];
    var right_arr = [];
    for(var i = 0; i < org_data.length; i++) {
      if(org_data[i].side === "left") {
        left_arr.push(org_data[i].id)
      }
      else {
        right_arr.push(org_data[i].id) 
      }
    }
    for(var i = 0; i < left_arr.length; i++) {
      obj2[left_arr[i]] = right_arr[i];
    }
    obj1 = org_data.reduce((obj, item) => Object.assign(obj, { [item.id]: item.statement }), {});
    obj3 = org_data.filter(option => {
      if(option.side === "left") {
        return option
      }
    });
    obj3 = obj3.reduce((obj, item) => Object.assign(obj, { [item.id]: null }), {});
    right_arr = org_data.filter(option => {
      if(option.side === "right") {
        return option
      }
    })
    return ({
      left_side: left_arr,
      right_side: right_arr,
      ids: obj1,
      options: obj2,
      match: obj3
    })
  }

  matchDataSolution = (data) => {
    return data.options.match
  }

  match_change = (e, left_id) => {
    const { match_solution } = this.state;
    match_solution[left_id] = e.target.value === "Select your answer" ? null : e.target.value;
    this.setState({ match_solution });
  }

  openFinish = () => {
    if(this.isAnsweredSaved() === true ) return true;
    this.setState({ finish: true })
  }

  closeFinish = () => {
    this.setState({ finish: false })
  }

  openFinish2 = () => {
    const { currentQuestion } = this.state ;

    console.log(this.postQuestions)

    this.setState({ finish2: true })
  }

  closeFinish2 = () => {
    this.setState({ finish2: false })
  }

  openSuccess = () => {
    this.setState({ success: true })
  }

  closeSuccess = () => {
    this.setState({ success: false })
  }

  openSuccess2 = () => {
    this.setState({ success2: true })
  }

  closeSuccess2 = () => {
    this.setState({ success2: false ,redirect:true })
  }

  checkMatchQuestion = (match) => {
    for(var key in match) {
      if(match[key] !== null)
        return true;
    }
    return false;
  }


  saveQuestionStatus = () => {
    const { currentQuestion, currentSection, solution, 
              match_solution,users_file } = this.state;
    let selected_option_id = this.state.selected_option_id;

    const currentIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
    const questionIndex = this.postQuestions.findIndex(p => p.question_id === currentQuestion.id)
    if(currentQuestion.question_type === 'Match The Following') {
      this.postQuestions[questionIndex].match_solution = match_solution
      if(this.checkMatchQuestion(match_solution)){
        this.postQuestions[questionIndex].is_answered = 1
        if(currentQuestion.status !== "Answered and marked for review")
          currentSection.questions[currentIndex].status = "Answered";
        currentSection.questions[currentIndex].match_solution = match_solution;
      }
      else {
        this.postQuestions[questionIndex].is_visited = 1
        this.postQuestions[questionIndex].action = 1
        if(currentQuestion.status !== "To be reviewed")
          currentSection.questions[currentIndex].status = "Not Answered";
        currentSection.questions[currentIndex].match_solution = match_solution;
      }
    }
    else if(currentQuestion.question_type === 'Subjective') {
      this.postQuestions[questionIndex].solution = solution
      if(!isEmpty(solution)){
        this.postQuestions[questionIndex].is_answered = 1
        this.postQuestions[questionIndex].users_file = users_file;
        if(currentQuestion.status !== "Answered and marked for review")
          currentSection.questions[currentIndex].status = "Answered";
        currentSection.questions[currentIndex].solution = solution;
        currentSection.questions[currentIndex].users_file = users_file;
      }
      else {
        this.postQuestions[questionIndex].is_visited = 1
        this.postQuestions[questionIndex].action = 1
        this.postQuestions[questionIndex].users_file = users_file;
        if(currentQuestion.status !== "To be reviewed")
          currentSection.questions[currentIndex].status = "Not Answered";
        currentSection.questions[currentIndex].solution = solution; 
        currentSection.questions[currentIndex].users_file = users_file; 
      }
    }
    else if(currentQuestion.question_type === 'Multiple Response Questions') {
      this.postQuestions[questionIndex].selected_option_id = selected_option_id
      if(!isEmpty(selected_option_id)){
        this.postQuestions[questionIndex].is_answered = 1
        if(currentQuestion.status !== "Answered and marked for review")
          currentSection.questions[currentIndex].status = "Answered";
        currentSection.questions[currentIndex].selected_option_id = selected_option_id;
      }
      else {
        this.postQuestions[questionIndex].is_visited = 1
        this.postQuestions[questionIndex].action = 1
        if(currentQuestion.status !== "To be reviewed")
          currentSection.questions[currentIndex].status = "Not Answered";
        currentSection.questions[currentIndex].selected_option_id = selected_option_id; 
      }
    }
    else {
      selected_option_id = selected_option_id.slice(-1);
      this.postQuestions[questionIndex].selected_option_id = selected_option_id
      if(!isEmpty(selected_option_id)){
        this.postQuestions[questionIndex].is_answered = 1
        if(currentQuestion.status !== "Answered and marked for review")
          currentSection.questions[currentIndex].status = "Answered";
        currentSection.questions[currentIndex].selected_option_id = selected_option_id;
      }
      else {
        this.postQuestions[questionIndex].is_visited = 1
        this.postQuestions[questionIndex].action = 1
        if(currentQuestion.status !== "To be reviewed")
          currentSection.questions[currentIndex].status = "Not Answered";
        currentSection.questions[currentIndex].selected_option_id = selected_option_id; 
      }
    }
    this.postQuestions[questionIndex].is_visited = 1
    this.postQuestions[questionIndex].action = 1
  }

  saveQuestionTime = (time_taken,difference,timed_type,quesId) => {
    let { currentQuestion,currentSection,exerciseAttempt} = this.state

    currentQuestion.time_taken = Math.floor(time_taken/1000)
    const questionIndex = this.postQuestions.findIndex(p => p.question_id === currentQuestion.id)
    
    if(this.postQuestions[questionIndex].is_completed  === 1){
      clearInterval(this.questionTimer.current.timer);
    }

    this.postQuestions[questionIndex].time_taken = currentQuestion.time_taken > currentQuestion.duration_seconds ?currentQuestion.duration_seconds :currentQuestion.time_taken;
    this.postQuestions[questionIndex].is_completed = currentQuestion.time_taken >=  currentQuestion.duration_seconds ? 1 :0;
 
    this.setState({ currentQuestion})
    
    if(difference <= 0 && timed_type === 2){
      let timeRemainedQuestion = this.postQuestions.filter(p => p.is_completed === 0)
      
      console.log(timeRemainedQuestion)

      let onLoop=0;
      let questionIndex = currentSection.questions.findIndex(p => p.id === currentQuestion?.id)
        if(!this.state.reProcess){
          if(questionIndex+1 >= currentSection.questions.length){
          let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
          if(sectionIndex+1 >= exerciseAttempt.sections.length){
            this.setState({onOptionSelect : 0})
            clearInterval(this.questionTimer.current.timer);
            if(timeRemainedQuestion.length > 0) {
              sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === timeRemainedQuestion[0].section_id)

              currentSection = exerciseAttempt.sections[sectionIndex]
              questionIndex = currentSection.questions.findIndex(p => p.id === timeRemainedQuestion[0].question_id)
              currentQuestion = currentSection.questions[questionIndex]

              this.setState({reProcess:1})

              console.log(this.state.currentSectionId)

              if(this.state.currentSectionId == timeRemainedQuestion[0].section_id){
                if(questionIndex === 10 || questionIndex=== 20 || questionIndex === 30 || questionIndex === 40){
                  let backward=document.getElementById('backward-arrow'); 
                  if(backward) backward.click();
                  this.setState({countOn:1})
                }
              }else{
                let forward=document.getElementById('forward-arrow');
                if(forward) forward.click();
              }

              this.moveToNext(currentSection.id, currentQuestion.id)
            }
            if(timeRemainedQuestion.length === 0) this.submitTest2();
            
          } else {
            currentSection = exerciseAttempt.sections[sectionIndex+1]
            currentQuestion = currentSection.questions[0]
            this.setState({onOptionSelect : 0})
            let forward=document.getElementById('forward-arrow');
            if(forward) forward.click();
            
            this.moveToNext(currentSection.id, currentQuestion.id)
          }
        } else {
          currentQuestion = currentSection.questions[questionIndex+1]
          this.setState({onOptionSelect : 0})
          if(questionIndex+1 === 10 || questionIndex+1 === 20 || questionIndex+1 === 30 || questionIndex+1 === 40){
            let backward=document.getElementById('backward-arrow');
            if(backward) backward.click();
            this.setState({countOn:1})
          }
          this.setState({countOn: this.state.countOn + 1})
          this.moveToNext(currentSection.id, currentQuestion.id)
        }
      }
      else{
        if(timeRemainedQuestion.length === 0) {
          clearInterval(this.questionTimer.current.timer);
          this.submitTest2();
        }
        else {
          const sectionIndex1 = exerciseAttempt.sections.findIndex(p => p.id === timeRemainedQuestion[0].section_id)
          const currentSection1 = exerciseAttempt.sections[sectionIndex1]

          const questionIndex1 = currentSection1.questions.findIndex(p => p.id === timeRemainedQuestion[0].question_id)
          //const currentQuestion1 = currentSection1.questions[questionIndex1]
          
          if(this.state.currentSectionId == timeRemainedQuestion[0].section_id){
            if(questionIndex1 === 10 || questionIndex1=== 20 || questionIndex1 === 30 || questionIndex1 === 40){
              let backward=document.getElementById('backward-arrow'); 
              if(backward) backward.click();
              this.setState({countOn:1})
            }
          }else{
            let forward=document.getElementById('forward-arrow');
            if(forward) forward.click();
          }

          this.moveToNext(timeRemainedQuestion[0].section_id, timeRemainedQuestion[0].question_id)
        }
      }
      //if(currentSection.questions[index+1]?.id) this.moveToNext(currentSection?.id, currentSection.questions[index+1]?.id)
    }
  }

  delay = (ms) => new Promise(res => setTimeout(res, ms));

  saveTestTime = async (timeTaken) => {
    if(this.state.exerciseAttempt.time_bound_type === 2) {
      if(((this.getTime() * 60 * 1000) - timeTaken) <= (10 * 60 * 1000) 
          && this.state.notify === 0 && this.state.exerciseAttempt.duration_mins >= 10) {
        this.setState({ ten_min: true, notify: 1 })
        await this.delay(15000);
        this.setState({ ten_min: false })
      }
      if(((this.getTime() * 60 * 1000) - timeTaken) <= (5 * 60 * 1000) 
          && this.state.notify === 1 && this.state.exerciseAttempt.duration_mins >= 5) {
        this.setState({ five_min: true, notify: 2 })
        await this.delay(15000);
        this.setState({ five_min: false })
      }
    }
    else {
      if(((this.state.exerciseAttempt.duration_mins * 60 * 1000) - timeTaken) <= (10 * 60 * 1000) 
          && this.state.notify === 0 && this.state.exerciseAttempt.duration_mins >= 10) {
        this.setState({ ten_min: true, notify: 1 })
        await this.delay(15000);
        this.setState({ ten_min: false })
      }
      if(((this.state.exerciseAttempt.duration_mins * 60 * 1000) - timeTaken) <= (5 * 60 * 1000) 
          && this.state.notify === 1 && this.state.exerciseAttempt.duration_mins >= 5) {
        this.setState({ five_min: true, notify: 2 })
        await this.delay(15000);
        this.setState({ five_min: false })
      }
    }
    //if(this.state.exerciseAttempt.time_bound_type !== 0) {
    if(this.state.exerciseAttempt.timed_type !=0){
      if(this.state.exerciseAttempt.time_bound_type === 1 || this.state.exerciseAttempt.time_bound_type === 0) {
        if(timeTaken >= (this.state.exerciseAttempt.duration_mins * 60 * 1000)) {
          this.submitTest2();
        }
      }
      else {
        if(timeTaken >= (this.getTime() * 60 * 1000)) {
          this.submitTest2();
        }
      }
    }
  }

  changeCurrentQuestion = async (sectionId, quesionId) => {
    
    let { exerciseAttempt, selected_option_id, solution, match_solution } = this.state;
    if(!exerciseAttempt.criteria.response_individual_question){
    // if(false){
      this.moveToNext(sectionId, quesionId);
    } else {
      let { currentQuestion } = this.state
      
      if(currentQuestion.question_type === 'Match The Following') {
        if(!currentQuestion.solution && !isEmpty(match_solution)){
          this.moveToNext(sectionId, quesionId)
        } else {
          this.moveToNext(sectionId, quesionId)
        }
      }
      else if(currentQuestion.question_type === 'Subjective') {
        
        if(!currentQuestion.solution && !isEmpty(solution)){
          this.moveToNext(sectionId, quesionId)
        } else {
          this.moveToNext(sectionId, quesionId)
        }
      }
      else {
        if(!currentQuestion.solution && !isEmpty(selected_option_id)){
          this.moveToNext(sectionId, quesionId)
        } else {
          this.moveToNext(sectionId, quesionId)
        }
      }
    }
  }

  markForReview = () => {
    let { currentQuestion, currentSection, selected_option_id, solution,
          match_solution } = this.state;
    const questionIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
    if(currentQuestion.question_type === 'Match The Following') {
      if(match_solution !== {}){
        if(currentQuestion.status !== "Answered and marked for review") {
          currentSection.questions[questionIndex].status = "Answered and marked for review" 
          currentQuestion.status = "Answered and marked for review"
        } else{
          currentSection.questions[questionIndex].status = "Answered" 
          currentQuestion.status = "Answered"
        }
      } else{
        if(currentQuestion.status !== "To be reviewed") {
          currentSection.questions[questionIndex].status = "To be reviewed" 
          currentQuestion.status = "To be reviewed"
        } else{
          currentSection.questions[questionIndex].status = "Not Answered" 
          currentQuestion.status = "Not Answered"
        }
      }
    }
    else if(currentQuestion.question_type === 'Subjective') {
      if(solution?.length > 0){
        if(currentQuestion.status !== "Answered and marked for review") {
          currentSection.questions[questionIndex].status = "Answered and marked for review" 
          currentQuestion.status = "Answered and marked for review"
        } else{
          currentSection.questions[questionIndex].status = "Answered" 
          currentQuestion.status = "Answered"
        }
      } else{
        if(currentQuestion.status !== "To be reviewed") {
          currentSection.questions[questionIndex].status = "To be reviewed" 
          currentQuestion.status = "To be reviewed"
        } else{
          currentSection.questions[questionIndex].status = "Not Answered" 
          currentQuestion.status = "Not Answered"
        }
      }
    }
    else {
      if(selected_option_id.length > 0){
        if(currentQuestion.status !== "Answered and marked for review") {
          currentSection.questions[questionIndex].status = "Answered and marked for review" 
          currentQuestion.status = "Answered and marked for review"
        } else{
          currentSection.questions[questionIndex].status = "Answered" 
          currentQuestion.status = "Answered"
        }
      } else{
        if(currentQuestion.status !== "To be reviewed") {
          currentSection.questions[questionIndex].status = "To be reviewed" 
          currentQuestion.status = "To be reviewed"
        } else{
          currentSection.questions[questionIndex].status = "Not Answered" 
          currentQuestion.status = "Not Answered"
        }
      }
    }
    this.setState({ currentQuestion, currentSection })
    if(this.isAnsweredSaved() === true ) return true;
    this.nextQuestion()
  }

  submitTest = async () => {
    const { exerciseAttempt ,users_file } = this.state;

    var result = this.postQuestions.map((question, index) => {
      if(question.match_solution !== undefined) {
        if(question.is_answered === 0) {
          return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            match_solution: Object.keys(question.match_solution).forEach(function(index) {
              question.match_solution[index] = null;
            }),
            is_answered: question.is_answered,
            is_visited: question.is_visited,
          }
        }
        else {
          return question  
        }
      }
      else if(question.solution !== 'Subjective') {
        if(question.is_answered === 0) {
          return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            solution: "",
            is_answered: question.is_answered,
            is_visited: question.is_visited,
            users_file:users_file
          }
        }
        else {
           return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            solution: question.solution,
            is_answered: question.is_answered,
            is_visited: question.is_visited,
            users_file:users_file
          }
        }
        this.postQuestions[index].users_file = users_file;

      }
      else {
        if(question.is_answered === 0) {
          return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            selected_option_id: [],
            is_answered: question.is_answered,
            is_visited: question.is_visited,
          }
        }
        else {
          return question
        }
      }
    })

    //console.log(this.postQuestions)
    //return false;
    await this.props.saveAttemptSubmit(exerciseAttempt.attempt_id, this.postQuestions.filter(p => p.action == 1))
    this.setState({ success: true });
    await this.delay(3200);
    this.setState({ redirect: true });
  }

  submitTest2 = async () => {
    const { exerciseAttempt,users_file } = this.state;
    var result = this.postQuestions.map((question, index) => {
      if(question.match_solution !== undefined) {
        if(question.is_answered === 0) {
          return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            match_solution: Object.keys(question.match_solution).forEach(function(index) {
              question.match_solution[index] = null;
            }),
            is_answered: question.is_answered,
            is_visited: question.is_visited,
          }
        }
        else {
          return question  
        }
      }
      else if(question.solution !== 'Subjective') {

        if(question.is_answered === 0) {
          return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            solution: "",
            is_answered: question.is_answered,
            is_visited: question.is_visited,
            users_file:users_file
          }
        }
        else {
           return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            solution: question.solution,
            is_answered: question.is_answered,
            is_visited: question.is_visited,
            users_file:users_file
          }
        }
        this.postQuestions[index].users_file = users_file;

        /*if(question.is_answered === 0) {
          return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            solution: "",
            is_answered: question.is_answered,
            is_visited: question.is_visited,
          }
        }
        else {
          return question
        }*/
      }
      else {
        if(question.is_answered === 0) {
          return {
            question_id: question.question_id,
            time_taken: question.time_taken,
            selected_option_id: [],
            is_answered: question.is_answered,
            is_visited: question.is_visited,
          }
        }
        else {
          return question
        }
      }
    })
    await this.props.saveAttemptSubmit(exerciseAttempt.attempt_id, this.postQuestions.filter(p => p.action == 1))
    this.setState({ success2: true });
    //await this.delay(3200);
    //this.setState({ redirect: true });
  }

  nextQuestion = () => {
    let { currentQuestion, currentSection, exerciseAttempt } = this.state;
    const questionIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
    

    if(questionIndex+1 >= currentSection.questions.length){
      const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
      if(sectionIndex+1 >= exerciseAttempt.sections.length){
        
      } else {
        currentSection = exerciseAttempt.sections[sectionIndex+1]
        currentQuestion = currentSection.questions[0]

        let forward=document.getElementById('forward-arrow');
        if(forward) forward.click();

        this.moveToNext(currentSection.id, currentQuestion.id)
      }
    } else {
      currentQuestion = currentSection.questions[questionIndex+1]

      if(questionIndex+1 === 10 || questionIndex+1 === 20 || questionIndex+1 === 30 || questionIndex+1 === 40){
        let backward=document.getElementById('backward-arrow'); 
        if(backward) backward.click();
        this.setState({countOn:1})
      }

      this.moveToNext(currentSection.id, currentQuestion.id)
    }
  }

  saveQuestionInstant = async () => {
    let { currentQuestion, currentSection, exerciseAttempt, selected_option_id, solution,
            match_solution,users_file } = this.state;
    this.setState({onOptionSelect : 0})
    this.saveQuestionStatus();
    const question_id = currentQuestion.id;
    const time_taken = currentQuestion.time_taken;
    const action = currentQuestion.action;
    let questionData = {};
    if(currentQuestion.question_type === "Subjective") {
      questionData = { question_id, time_taken, solution ,users_file,action}
    }
    else if(currentQuestion.question_type === "Match The Following") {
      questionData = { question_id, time_taken, match_solution,action}  
    }
    else {
      questionData = { question_id, time_taken, selected_option_id,action}
    }

    console.log(questionData)
    

    let timeRemainedQuestion = this.postQuestions.filter(p => p.is_completed === 0)

    await this.props.saveQuestionSubmit(exerciseAttempt.attempt_id, questionData);
    const questionIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
    
    if(!this.state.reProcess){
      if(questionIndex+1 >= currentSection.questions.length){
        const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
        if(sectionIndex+1 >= exerciseAttempt.sections.length){
          this.openFinish2();
        } else {
          currentSection = exerciseAttempt.sections[sectionIndex+1]
          currentQuestion = currentSection.questions[0]

          let forward=document.getElementById('forward-arrow');
          if(forward) forward.click();
          this.moveToNext(currentSection.id, currentQuestion.id)
        }
      } else {
        currentQuestion = currentSection.questions[questionIndex+1]

        if(questionIndex+1 === 10 || questionIndex+1 === 20 || questionIndex+1 === 30 || questionIndex+1 === 40){
          let backward=document.getElementById('backward-arrow'); 
          if(backward) backward.click();
          this.setState({countOn:1})
        }
        this.setState({countOn: this.state.countOn + 1})
        this.moveToNext(currentSection.id, currentQuestion.id)
      }
    }else{
        if(timeRemainedQuestion.length === 0) {
          clearInterval(this.questionTimer.current.timer);
          this.openFinish2()
        }
        else {
          const sectionIndex1 = exerciseAttempt.sections.findIndex(p => p.id === timeRemainedQuestion[0].section_id)
          const currentSection1 = exerciseAttempt.sections[sectionIndex1]

          const questionIndex1 = currentSection1.questions.findIndex(p => p.id === timeRemainedQuestion[0].question_id)
          //const currentQuestion1 = currentSection1.questions[questionIndex1]
          this.setState({currentSectionId:timeRemainedQuestion[0].section_id})

          if(this.state.currentSectionId == timeRemainedQuestion[0].section_id){
            if(questionIndex1 === 10 || questionIndex1=== 20 || questionIndex1 === 30 || questionIndex1 === 40){
              let backward=document.getElementById('backward-arrow'); 
              if(backward) backward.click();
              this.setState({countOn:1})
            }
          }else{
            let forward=document.getElementById('forward-arrow');
            if(forward) forward.click();
          }
          this.moveToNext(timeRemainedQuestion[0].section_id, timeRemainedQuestion[0].question_id)
        }
    }
    // currentQuestion.selected_option_id = response.selected_option_id
    // currentQuestion.match_solution = response.match_solution
    // currentQuestion.solution = response.solution
    // currentQuestion.correct_option_id = response.correct_option_id
    // currentQuestion.is_correct = true
    // currentQuestion.status = "Answered";
    // this.setState({ currentQuestion })
  }

  clearResponse = () => {
    let { currentQuestion, match_solution ,currentSection,selected_option_id} = this.state;
    const questionIndex = this.postQuestions.findIndex(p => p.question_id === currentQuestion.id)
    selected_option_id = [] ;
    currentQuestion.selected_option_id = []
    currentQuestion.solution = "";
    const currentIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)

    if(currentQuestion.question_type === "Match The Following") {
      for(var i = 0; i < currentQuestion.options.left_side.length ; i++) {
        document.getElementById(`match_id_${i}`).value = "Select your answer"
      }
      Object.keys(match_solution).forEach(function(index) {
        match_solution[index] = null;
      });
    }

    this.postQuestions[questionIndex].selected_option_id = selected_option_id
    this.postQuestions[questionIndex].solution ="";
    this.postQuestions[questionIndex].users_file ="";
    currentSection.questions[currentIndex].selected_option_id = selected_option_id;
    currentSection.questions[currentIndex].solution = "";
    currentSection.questions[currentIndex].users_file = "";

    currentQuestion.file = "";
    console.log(this.postQuestions[questionIndex])
    this.setState({ currentQuestion, match_solution, selected_option_id: [], solution: "" ,onOptionSelect:0,users_file:null})
  }

  saveAndNext = () => {
    let { currentQuestion, currentSection, exerciseAttempt } = this.state;
    if(!exerciseAttempt.criteria.response_individual_question) {
      // if(false) {
      this.saveQuestionStatus();
      const questionIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
      if(questionIndex+1 >= currentSection.questions.length){
        const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
        if(sectionIndex+1 >= exerciseAttempt.sections.length){
          this.openFinish2();
        } else {
          currentSection = exerciseAttempt.sections[sectionIndex+1]
          currentQuestion = currentSection.questions[0]
          this.moveToNext(currentSection.id, currentQuestion.id)
        }
      } else {
        currentQuestion = currentSection.questions[questionIndex+1]
        this.moveToNext(currentSection.id, currentQuestion.id)
      }
    } else {
      this.saveQuestionInstant()
    }
  }

  isAnsweredSaved = () =>{
    if(this.state.onOptionSelect){
     store.addNotification({
        title: '',
        message: `${this.props.saveLanguageWiseData.save_the_answer_of_your_previous_ques}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 500
        }
      }) 
      return true;
    }
  }

  moveToNext = (sectionId, questionId) => {

    var blockedTile = new Array("jpg", "jpeg", "png", "gif");
    let { currentQuestion, currentSection, exerciseAttempt } = this.state;
  
    if(this.isAnsweredSaved() === true ) return true;

    if(currentQuestion.duration_seconds) {
      clearInterval(this.questionTimer.current.timer) 
    }

    if(currentSection.id === sectionId) {

      const questionIndex = currentSection.questions.findIndex(p => p.id == questionId)
      
      let postindex=this.postQuestions.findIndex(p => p.question_id === questionId);
     
      currentQuestion = currentSection.questions[questionIndex]
      if(currentQuestion.status !== 'Answered' && currentQuestion.status !== "Answered and marked for review" && currentQuestion.status !== "To be reviewed")
        currentQuestion.status = "Not Answered";
      
      this.setState({currentSectionId:currentSection.id})
      if(postindex) this.postQuestions[postindex].action = 1;
    } 
    else {
      const sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === sectionId)
      currentSection = exerciseAttempt.sections[sectionIndex]
      const questionIndex = currentSection.questions.findIndex(p => p.id == questionId)

      let postindex=this.postQuestions.findIndex(p => p.question_id === questionId);

      currentQuestion = currentSection.questions[questionIndex]
      if(currentQuestion.status !== 'Answered' && currentQuestion.status !== "Answered and marked for review" && currentQuestion.status !== "To be reviewed")
        currentQuestion.status = "Not Answered"

      this.setState({currentSectionId:currentSection.id})
      if(postindex) this.postQuestions[postindex].action = 1;
    }

    let index=currentSection.questions.findIndex(p => p.id === questionId);

    if(currentSection?.questions[index]?.image){
      
      if(blockedTile.indexOf(currentSection.questions[index].image.split('.').pop()) != -1)
      {  
        this.setState({file_extension:1,selected_image_on:currentSection.questions[index].image.split('/').pop()}); //1 is used for image file
      }else{
        this.setState({file_extension:2,selected_image_on:currentSection.questions[index].image.split('/').pop()}); //2 is used for content file
      }
      //console.log(this.state.)
    }

    currentQuestion.action =1;

    this.setState({
      currentQuestion,
      currentSection,
      showSolution: false,
      selected_option_id: currentQuestion.selected_option_id,
      solution: currentQuestion.solution == undefined ? "" : currentQuestion.solution ,
      match_solution: currentQuestion.match_solution,
    }, () => {
      if(currentQuestion.duration_seconds) this.questionTimer.current.startTimerForNextQuestion()
    })
  }

  previousSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    if(this.isAnsweredSaved() === true ) return true;
    let forward=document.getElementById('forward-arrow');
    if(forward) forward.click();

    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== 0) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex - 1].id, exerciseAttempt.sections[sectionIndex - 1]?.questions[0]?.id);
    }
  }

  nextSection = () => {
    let { currentSection, exerciseAttempt } = this.state;
    if(this.isAnsweredSaved() === true ) return true;
    let forward=document.getElementById('forward-arrow');
    if(forward) forward.click();

    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id);
    if(sectionIndex !== exerciseAttempt.sections.length - 1) {
      this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1]?.questions[0]?.id);
    }
  }

  setSelectedOption = (index) => {
    let { currentQuestion, selected_option_id, solution, match_solution,currentSection } = this.state;
    const currentIndex = currentSection.questions.findIndex(p => p.id === currentQuestion.id)
    const questionIndex = this.postQuestions.findIndex(p => p.question_id === currentQuestion.id)

    if(currentQuestion.question_type === "Match The Following") {
      currentQuestion.match_solution = match_solution
    }
    else if(currentQuestion.question_type === "Subjective") {
      currentQuestion.solution = solution
    }
    else {
      selected_option_id.push(index)
      if(currentQuestion.question_type !== "Multiple Response Questions"){
        selected_option_id = selected_option_id.slice(-1)
        if(this.postQuestions[questionIndex].selected_option_id.length > 1){
          this.postQuestions[questionIndex].selected_option_id = [this.postQuestions[questionIndex].selected_option_id[0]]
        }
      }
      //selected_option_id = selected_option_id.slice(-1)
      currentQuestion.selected_option_id = selected_option_id
    }

    this.postQuestions[questionIndex].is_completed =1;
    console.log(this.postQuestions[questionIndex])
    this.setState({ selected_option_id, currentQuestion, match_solution, solution ,onOptionSelect:1})
  }

  closeAttemptModal = () => {
    this.setState({showAttemptModal: false})
  }

  writeAnswer = (e) => {
    let { currentQuestion } = this.state;
    
    currentQuestion.solution=e.target.value;

    this.setState({
      solution: e.target.value,
      currentQuestion,
      onOptionSelect:1
    })
  }

  onImageChange = (e) => {
    this.setState({ 
      image: e.target.files[0],
      imageURL: URL.createObjectURL(e.target.files[0]) 
    })
  }

  removeImage = () => {
    const {currentQuestion} = this.state;

    this.setState({users_file:null})
    currentQuestion.file = "";

    this.setState({currentQuestion})
  }

  collapseAction = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  getTime = () => {
    var dt1 = new Date(this.state.exerciseAttempt.server_time);
    var dt2 = new Date(this.state.exerciseAttempt.end_date_time);
    if(dt2 < dt1) {
      return 0;
    }
    else {
      var diff =(dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    }
  }

  handleFileChange = async(event,id) => {
    const {currentQuestion} =this.state;

    if(event?.target?.files[0] === undefined) {
      this.setState({ users_file: null })
      //document.getElementById('doc-input').value = "";
    }else{
      const response= await this.props.updateImage(event.target.files[0]);
      currentQuestion.file=response.data.image.split("/").pop();
      this.setState({users_file:response.data.image,currentQuestion});
    }
  }


  render(){
    
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      selected_option_id,
      solution,
      match_solution,
      noQuestionAvailable,
      id
    } = this.state;

    console.log(exerciseAttempt.attempts_allowed)
    console.log(exerciseAttempt.attempts_taken)

    if(this.state.redirect === true) {
      if(localStorage.getItem("page") == "dpp")
        return <Redirect to={'/learner/tests?id='+id}/>;
      if(localStorage.getItem("page") == "practice")
        return <Redirect to={'/learner/practice?id='+id}/>;
      if(localStorage.getItem("page") == "dashboard")
        return <Redirect to={'/learner'}/>;
      if(localStorage.getItem("page") == "mycoach")
        return <Redirect to={'/mycoach'}/>;
      else
        return <Redirect to={'/mycoach'}/>;
    }

    if(this.state.isAttemptAllowed){
      return(
          <Popup className="success-modal" open={true} onClose={this.closeSuccess}
          closeOnDocumentClick>
            <div className="success-div">
              <img src={Success}/>
              <span>{this.props.saveLanguageWiseData.allowed_attempt_limit_ended}</span>
              <br/>
              <button onClick={() => this.props.history.push('/learner')}>{this.props.saveLanguageWiseData.back_to_home}</button>
            </div>
        </Popup>
      );
    }

    return (
      <div className="assessment-div">
        <ReactNotifications/>
        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="zoom-image-on">
          <div className="zoom-image">
            <img src={this.state.selected_image} />
          </div>
        </Popup>
        {isEmpty(exerciseAttempt) ? <div className="assessment-div">
            <div className="admin-div-loader">
              <div className="admin-loader"></div>
            </div>
          </div> : <>
          {noQuestionAvailable ? <div className="assessment-div">
              <span id="no-questions">No questions!</span>
            </div> : <>

            <Popup className="finish-modal" open={this.state.finish} onClose={this.closeFinish}
              closeOnDocumentClick>
              <div className="finish-div">
                <div className="finish-div1">
                  <img src={Clock}/>
                  {this.props.saveLanguageWiseData.manual_submit_texttwo}
                </div>
                <div className="finish-div2">
                  <button onClick={this.closeFinish}>{this.props.saveLanguageWiseData.cancel}</button>
                  <button onClick={this.submitTest}>{this.props.saveLanguageWiseData.finish_test}</button>
                </div>
              </div>
            </Popup>

            <Popup className="finish-modal" open={this.state.finish2} onClose={this.closeFinish2}
              closeOnDocumentClick>
              <div className="finish-div">
                <div className="finish-div1">
                  <img src={Clock}/>
                  {this.props.saveLanguageWiseData.manual_submit_textone}
                </div>
                <div className="finish-div2">
                  <button onClick={this.submitTest}>{this.props.saveLanguageWiseData.finish_test}</button>
                  <button onClick={this.closeFinish2}>{this.props.saveLanguageWiseData.not_yet}</button>
                </div>
              </div>
            </Popup>

            <Popup className="success-modal" open={this.state.success} onClose={this.closeSuccess}
              closeOnDocumentClick>
              <div className="success-div">
                <img src={Success}/>
                <span>{this.props.saveLanguageWiseData.manual_submit_message}</span>
              </div>
            </Popup>

            <Popup className="success-modal" open={this.state.success2} onClose={this.closeSuccess2}
              closeOnDocumentClick>
              <span className="close" onClick={this.closeSuccess2}>&#x2715;</span>
              <div className="success-div">
                <img src={Success}/>
                <span>{this.props.saveLanguageWiseData.auto_submit_message}</span>
              </div>
            </Popup>

            <div className="assessment-head">
              <div className="assessment-head1">
                {/* {JSON.stringify(exerciseAttempt)} */}
                {/* <span>{exerciseAttempt.title}</span> */}
                <span title={exerciseAttempt.title}>
                {exerciseAttempt.title.length > 50 ?
                    `${exerciseAttempt.title.substring(0, 50)}...` : exerciseAttempt.title
                  }
                </span>
                <span className="timerClass">
                  {this.state.ten_min ? <div className="five_minutes">
                    {this.props.saveLanguageWiseData.ten_min_left}
                  </div> : <>{this.state.five_min && <div className="five_minutes">
                  {this.props.saveLanguageWiseData.five_min_left}
                  </div>}</>}
                  
                  {exerciseAttempt.time_bound_type !== 0 &&
                    <span id="time_left">{this.props.saveLanguageWiseData.time_left} :</span>}
                  {exerciseAttempt.timed_type != 2 && <>{exerciseAttempt.time_bound_type === 2
                    ? <StartTimer startTime='00:00:00' onChange={this.saveTestTime} 
                    notify={this.state.notify} totalTime={this.getTime()} id='totalTime'/>
                    : <StartTimer startTime='00:00:00' onChange={this.saveTestTime} 
                    notify={this.state.notify} totalTime={exerciseAttempt.duration_mins} id='totalTime'/>}</>}
                </span>
              </div>
              <div className="assessment-head2">
                {/* {JSON.stringify(currentSection)} */}
                <div className="assessment-section-buttons">
                  <img src={MoveLeft} alt="img" className={exerciseAttempt.back_tracking == false ? "" : "is_disabled"} onClick={this.previousSection}/>
                  {currentSection.title}
                  <img src={MoveRight} alt="img" className={exerciseAttempt.back_tracking == false ? "" : "is_disabled"} onClick={this.nextSection}/>
                </div>
                <Carousel  containerClass={exerciseAttempt.back_tracking == false ? "section-layer-tray" : "is_disabled section-layer-tray"} responsive={responsive} 
                keyBoardControl={false} arrows={false} swipeable={false} draggable={false}
                customButtonGroup={<SlideButtons/>} renderButtonGroupOutside={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}>
                  {currentSection.questions !== undefined 
                    && currentSection.questions.map((question, index) => 
                    <div key={question.id}
                      style={{backgroundColor: common.getQuestionColor(question.status,this.props.saveLanguageWiseData)}}
                      className={common.getQuestionColor(question.status) == '#FFFFFF' ? "bg-white sec-ques-div" : 'sec-ques-div'}
                      onClick={()=>this.changeCurrentQuestion(currentSection.id, question.id)}>
                      {index !== 0 && <hr className="sec-line-one"/>}
                      {index + 1}
                      {(index !== (currentSection.questions.length - 1)) && <hr className="sec-line-two"/>}
                    </div>
                  
                  )}
                </Carousel>
              </div>
              <div className="assessment-head4">
                <button onClick={() => this.openFinish()}>
                 {this.props.saveLanguageWiseData.finish_test}
                </button>
              </div>
            </div>

            <div className="assessment-main">
                  <div className="assessment-ques">
                    {currentQuestion.duration_seconds &&  <div className="subject"><StartTimer
                        startTime='00:00'
                        totalTime={currentQuestion.duration_seconds}
                        onChange={this.saveQuestionTime}
                        time_taken={currentQuestion.time_taken}
                        quesId={currentQuestion.id}
                        timed_type ={exerciseAttempt.timed_type}
                        ref={this.questionTimer}
                        id='questionTime'
                      />{/*currentSection.title*/}
                    </div>}

                    <div className="time-remaining">
                      <span className="question_no">{currentQuestion.question_number}</span>
                      <div style={{display: 'flex'}}>
                        { currentQuestion.marks > 0 &&
                          <div className="marks-status">
                            <span Style={!currentQuestion.negative_marking && "border-radius:15px"}>+{currentQuestion.marks}</span>
                            {currentQuestion.negative_marking ?<span>-{currentQuestion.negative_marking}</span> :<span Style="display:none">-{currentQuestion.negative_marking}</span>}
                          </div>
                        }
                      </div>
                    </div>
                    {currentQuestion.question_type === "Match The Following" ?
                      <>
                        <p ref={this.node} dangerouslySetInnerHTML={{__html: currentQuestion.statement}}/>
                        <div className="matching">
                          {currentQuestion.options.left_side.map((option, index) => 
                            <div className="match_left" key={`options_${index}`}>
                              <div className="left_match_option">
                                <div>{`${index + 1} )`}</div>
                                <div className="option_statement"
                                  ref={this.node2}
                                  dangerouslySetInnerHTML={{__html: currentQuestion.options.ids[option]}}>
                                </div>
                              </div>
                              <div className="right_match_option">
                                <div>{`${abc[index + 1]} )`}</div>
                                <div className="option_statement"
                                  ref={this.node2}
                                  dangerouslySetInnerHTML={{__html: currentQuestion.options.ids[currentQuestion.options.options[option]]}}>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </> :
                      <div className="questionImage">
                        <p ref={this.node} dangerouslySetInnerHTML={{__html: currentQuestion.statement}} />
                        {currentQuestion.question_type_id == 6 ? <> { this.state.file_extension ==2 ? <div className="download-file-attempt" Style={!currentQuestion.image?"display:none":""}><a href={common.addFileUrl(currentQuestion.image)} target="_blank" rel="" download>
                          {/* <i className="fas fa-download"/> */}
                          <img src={download} alt="download"/> {this.props.saveLanguageWiseData.download} {this.state.selected_image_on}
                          </a></div> :<span onClick={()=>this.zoomOn(common.addFileUrl(currentQuestion.image))} Style={!currentQuestion.image?"display:none":""}><img src={common.addFileUrl(currentQuestion.image)} /></span>} </>:
                         <span onClick={()=>this.zoomOn(common.addFileUrl(currentQuestion.image))} Style={!currentQuestion.image?"display:none":""}><img src={common.addFileUrl(currentQuestion.image)} /></span>}
                      </div>
                    }
                  </div>
                  <div className="assessment-options">
                    <div className="choose-option">
                      <div>{common.getQuestionInstruction(currentQuestion.question_type,this.props.saveLanguageWiseData)}</div>
                      <div className="mark-for-review-icon">
                        { !common.checkForLastQuestion(exerciseAttempt, currentQuestion) ? <> {currentQuestion.status === "Answered and marked for review" || currentQuestion.status === "To be reviewed" ?
                            <div onClick={this.markForReview}><img src={NMFR} alt="mark for review"/></div>:
                            <div onClick={this.markForReview} className={exerciseAttempt.back_tracking == false ? '' : 'is_disabled'}><img src={MFR} alt="unmark for review"/></div>
                          }</> : <>{currentQuestion.status === "Answered and marked for review" || currentQuestion.status === "To be reviewed" ?
                              <div onClick={this.markForReview}><img src={NMFR} alt="mark for review"/></div>:
                              <div onClick={this.markForReview} className={exerciseAttempt.back_tracking == false ? '' : 'is_disabled'}><img src={MFR} alt="unmark for review"/></div>
                            }</>
                        }
                        
                        <Tooltip title="Clear Response">
                          <div><img src={Eraser} alt="mark for review" onClick={this.clearResponse}/></div>
                        </Tooltip>
                        
                      </div>
                    </div>
                    <div>
                      { currentQuestion.question_type === "Match The Following" ?
                        <div className="matching">
                          {currentQuestion.options.left_side.map((option, index) => 
                            <div className="match_left" key={`options_${index}`}>
                              <div className="left_match_option">
                                <div>{`${index + 1} )`}</div>
                                <div className="option_statement"
                                  ref={this.node2}
                                  dangerouslySetInnerHTML={{__html: currentQuestion.options.ids[option]}}>
                                </div>
                              </div>
                              <select id={`match_id_${index}`} onChange={(e) => this.match_change(e,option)} 
                                className="right_match_option" value={match_solution[option]}>
                                <option value={null} className="right_match_option">
                                  {this.props.saveLanguageWiseData.select_your_answer}
                                </option>
                                {currentQuestion.options.right_side.map((item, index) =>
                                  <option className="right_match_option"
                                    key={item.id}
                                    value={item.id}>
                                    {`Option ${abc[index + 1]}`}
                                  </option>
                                )}
                              </select>
                            </div>
                          )}
                        </div> : <>
                        {currentQuestion.question_type !== "Subjective" ?
                          <>
                            { currentQuestion.options &&
                              currentQuestion?.options.map((option, index) =>
                              <div className="option-container" key={`options_${index}`}>
                                <div
                                  ref={this.node2}
                                  key={`options_${index}`}
                                  style={{border: common.getSelectedOptionColor(selected_option_id, option.id)}}
                                  className="select-option"
                                  onClick={()=>this.setSelectedOption(option.id)}
                                  dangerouslySetInnerHTML={{__html: option.statement}} ></div>
                                {option.image && <><span className="option-image" onClick={()=>this.zoomOn(common.addFileUrl(option.image))}><img style={{border: common.getSelectedOptionColor(selected_option_id, option.id)}}  src={common.addFileUrl(option.image)}/><span>{this.props.saveLanguageWiseData.click_image_zoom}</span></span>
                                </>}
                    
                              </div>
                            )}
                          </> :
                          <div><br/>
                            <textarea name="message" rows="7" placeholder="Write here..." 
                              onChange={this.writeAnswer} value={solution} Style="width:100%"/><br/><br/>
                            {currentSection.questions !== undefined 
                              && currentSection.questions.map((question, index) => 
                              <>
                              <>

                              {question.id === currentQuestion.id && !currentQuestion.file && <div className="popup-inputs">
                                  <input id="doc-input" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/pdf" 
                                  onChange={(e)=>this.handleFileChange(e,currentQuestion.id)}/>
                                </div>
                              }
                              
                              </>

                              <>
                              {question.id === currentQuestion.id && currentQuestion.file && 
                                <div className="img-prev-statement ques-statement">
                                  <span onClick={this.removeImage}>&#x2715;</span>
                                  {this.props.saveLanguageWiseData.uploaded} {currentQuestion.file}
                                </div>  
                              }
                              </>
                              </>
                            )}
                          </div>
                        }
                      </>}
                    </div>
                  </div>
                  { !common.checkForLastQuestion(exerciseAttempt, currentQuestion) ?
                    <div className="assessment-bottom">
                      {/*<button className="clear-response" onClick={this.clearResponse}>Clear Response</button>*}
                      {/*<button className="mark-for-review" onClick={this.markForReview}
                        style={currentQuestion.status === "Answered and marked for review" || currentQuestion.status === "To be reviewed" ?
                        {backgroundColor: "#6236ff", color: "#ffffff"} : {}}>
                        {currentQuestion.status === "Answered and marked for review" || currentQuestion.status === "To be reviewed" ?
                          "Unmark from Review and Next" :
                          "Mark for Review and Next"}
                      </button>*/}
                      { currentQuestion.is_correct == null ?
                        // <button className="save-and-next" onClick={this.saveQuestionInstant}>{exerciseAttempt.criteria.save_button_label}</button> :
                        <button className="save-and-next" onClick={this.saveQuestionInstant}>{this.props.saveLanguageWiseData.save_next}</button> :
                        <button className="save-and-next" onClick={this.nextQuestion} >{this.props.saveLanguageWiseData.next}</button>
                      }
                    </div> :
                    <div className="assessment-bottom">
                      {/*<button className="clear-response" onClick={this.clearResponse}>Clear Response</button>
                      <button className="mark-for-review" onClick={this.markForReview}
                        style={currentQuestion.status === "Answered and marked for review" || currentQuestion.status === "To be reviewed" ?
                        {backgroundColor: "#6236ff", color: "#ffffff"} : {}}>
                        {currentQuestion.status === "Answered and marked for review" || currentQuestion.status === "To be reviewed" ?
                          "Unmark from Review" :
                          "Mark for Review"}
                      </button>*/}
                      { currentQuestion.is_correct == null ?
                        <button className="save-and-next" onClick={this.saveQuestionInstant}>{this.props.saveLanguageWiseData.save}</button> :
                        <button className="save-and-next" onClick={this.nextQuestion}>{this.props.saveLanguageWiseData.next}</button>
                      }
                      
                    </div>
                  }
            </div>
          </>
          }
          </>
        }
        {this.state.showAttemptModal && !isEmpty(this.props.contentPreview) &&
          <AttemptModal
            showAttemptModal={this.state.showAttemptModal}
            closeAttemptModal={this.closeAttemptModal}
            attemptPaper={this.attemptPaper}
            contentPreview={this.props.contentPreview}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    exerciseAttempt: state.classroomReducer.exerciseAttempt,
    contentPreview: state.classroomReducer.contentPreview,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentAttempt, saveAttemptSubmit, saveQuestionSubmit ,updateImage,getAttemptPreview,getLanguageWiseData} = actions;
  return {
    getAssessmentAttempt: (assessmentId) => dispatch(getAssessmentAttempt(assessmentId)),
    saveAttemptSubmit: (attempt_id, questions) => dispatch(saveAttemptSubmit(attempt_id, questions)),
    saveQuestionSubmit: (attempt_id, question) => dispatch(saveQuestionSubmit(attempt_id, question)),
    updateImage : (file) => dispatch(updateImage(file)),
    getAttemptPreview:(id) => dispatch(getAttemptPreview(id)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AttemptPaper);