import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';

import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
			mock: false
    }
	}

	render() {
		return (
			<div className="admin-div">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search"/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);