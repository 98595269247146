import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import ReactNotifications from 'react-notifications-component';
import actions from '../../redux/actions';
import Calendar from 'react-calendar';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import Calendar2 from '../../public/icons/calendar2.svg';
import attendanceIcon from '../../public/icons/attendance-report-video-icon.svg';
import attendanceRecordingIcon from '../../public/icons/attendance-report-recording-icon.svg';
//import Pagination from "react-js-pagination";
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import absentIcon from '../../public/icons/attendance-report-absent.svg';
import notViewedIcon from '../../public/icons/attendance-report-not-viewed.svg';
import presentIcon from '../../public/icons/attendance-report-present.svg';
import viewedIcon from '../../public/icons/attendance-report-viewed-video.svg';

class ViewAttendanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batch_id:'',
      current_date_records:[],
      current_date:new Date(),
      showCalendar:false,
      saveMonthNumber:'',
      saveMonthName:'',
      saveYear:'',
      attendanceList:[],
      token:localStorage.getItem('token'),
      getUserAllData:[],
      getUserAllClassData:[]
     

    }
    this.wrapperRef = React.createRef();
  }

  componentDidMount(){
    this.getDate(new Date());
    console.log("this.state.saveMonthNumber",this.state.saveMonthNumber);
   

    document.addEventListener('mousedown', this.handleClickOutside);

    console.log("token",this.state.token);

    this.getAllDataForFilters(this.state.token)  
    
  }

  getAllDataForFilters = async () =>{
    let {token} = this.state;
    console.log("token",token);
    if(token){
      const response = await this.props.getLearnerAttendanceReportFilters(token)
      console.log(response);
      console.log(response.status);
      if(response.status == 1){
        this.setState({getUserAllData:response?.data})
      }

   }
}
  
onProgramChange = (e) =>{
  const {getUserAllData} = this.state;
  let saveprogramId = e.target.value;
  console.log("saveprogramId",saveprogramId);
  if(saveprogramId){
    this.setState({getUserAllClassData:''})
   let getFilterData = getUserAllData.filter(data => data.id == saveprogramId); 
   console.log("getFilterData",getFilterData);
   setTimeout(() => {
    this.setState({getUserAllClassData:getFilterData})
  }, 200);
  
  }
}

onClassChange = (e) =>{
  let savebatchId = e.target.value;
  console.log("savebatchId",savebatchId);
  this.setState({batch_id:savebatchId})
  if(savebatchId){
    this.AttendanceReport(savebatchId);
  }
}

  
  onClickMonth =  (value,event) => {
    console.log(value)
    console.log(event)
    //this.getMonthNumberFromName();
    this.getDate(value);
    this.setState({showCalendar:false})
    this.setState({current_date:value})
     
   }

  AttendanceReport = async (savebatchId) =>{
    let {batch_id,saveMonthNumber,saveYear} = this.state;
    console.log("batch_id",savebatchId);
    console.log("saveMonthNumber",saveMonthNumber);
    if(savebatchId){
      const response = await this.props.getLearnerAttendanceReport(savebatchId,saveMonthNumber,saveYear)
      console.log(response);
      
      let responsesort = response?.data.sort((a, b) => {
        const dateA = new Date(a.start_date_time);
        const dateB = new Date(b.start_date_time);
        return dateA - dateB;
      });
      console.log(responsesort);
    let tempArr = [responsesort[0]]
    let finallyObj = []
    for (let index = 1; index < responsesort.length; index++) {
        if(responsesort[index].start_date_time.split('T')[0] == responsesort[index-1].start_date_time.split('T')[0]){
          tempArr.push(responsesort[index])
        }
        else{
          finallyObj.push(tempArr)
          tempArr = []
          tempArr.push(responsesort[index])
        }
     }
    if(tempArr.length != 0){
      finallyObj.push(tempArr)
    }
    
     console.log("finallyObj",finallyObj);
     if(responsesort[0] == null){
      finallyObj = [];
     }
     this.setState({attendanceList:finallyObj})
    
  }
}

  convertStartTime = (value) => {
    const today = value;
    const indexOfT = today.indexOf('T');
    const dateonly = today.substring(0, indexOfT);
    const timeonly = today.substring(indexOfT+1, today.length).substring(0,5);
    console.log("dateonly",dateonly);
    console.log("timeonly",timeonly);
    return timeonly;
  }

  convertEndTime = (value) => {
    const today = value;
    const indexOfT = today.indexOf('T');
    const timeonly = today.substring(indexOfT+1, today.length).substring(0,5);
    return timeonly;
  }


  getFormatDate(value) {
    const today = new Date(value);
    const monthName = today.toLocaleString('default', { month: 'short' });
    const dateNumber = today.toLocaleString('default', { day: 'numeric' });
    return `${dateNumber} ${monthName}`;
  }

  getFormatDate1(value) {
    const today = new Date(value);
    const weekday = today.toLocaleString('default', { weekday: 'short' });
    return `${weekday} `;
  }

  getDate(value) {
    const today = value;
    const monthName = today.toLocaleString('default', { month: 'short' });
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    this.setState({saveMonthNumber:month});
    this.setState({saveMonthName:monthName});
    this.setState({saveYear:year});
    console.log("date",date)
    console.log("Month",month)
    console.log("year",year)
    console.log("batch_id",this.state.batch_id);
    setTimeout(() =>{
      this.AttendanceReport(this.state.batch_id)
      }, 500);
    //setTimeout(this.AttendanceReport(this.state.batch_id), 3000);
    return `${month}/${date}/${year}`;
  }
  

  openCalendar  = ()=>{
    this.setState({showCalendar:true});
  }



  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showCalendar: false })
    }
  }
	render() {

    
    //let subjectid = parseInt(CID); 

    return (
      <>
      
        <div className= "app-body attendance-report-page">
          
          <div className="app-div widget-app-div">
            <Helmet>
              <title>Attendance Report</title>
            </Helmet>
            <ReactNotifications/>
           
            
            
            <div className="viewAttendanceReportsWrapper">
              <div className="viewAttendanceReportsContainer">

                {/* {JSON.stringify(this.state.getUserAllData)} */}
              <div className="header-op">
              
                  <div className='calenderBlock'>
                    <div className='currentDate'>
                      <div>
                        <div id="trace-back" onClick={() => this.props.history.goBack()}>
                            <img src={BackArrow} alt="back"/>
                          </div>
                  {this.state.saveMonthName} {this.state.saveYear}
                    <div className="react-calendar-date" onClick={this.openCalendar}
                          ref={this.wrapperRef}>
                          <span>
                            <img src={Calendar2} alt="cal"/>
                          </span>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className='filterBlock'>
                      <label>Filters</label>
                      <select onChange={this.onProgramChange}>
                        <option value=''>Select Program</option>
                        {this.state.getUserAllData != null && this.state.getUserAllData.map((programs) => (
                          <option value={programs.id}>{programs.label}</option>
                        ))}
                      </select>
                      {this.state.getUserAllClassData.length > 0 &&
                      <select onChange={this.onClassChange}>
                        <option value=''>Select Class</option>
                        {this.state.getUserAllClassData && this.state.getUserAllClassData[0]?.batches.map((classes) => (
                          <option value={classes.id}>{classes.class_name}</option>
                        ))}
                      </select>
                      }
                      
                    </div>
                  </div>
                  
                <div ref={this.wrapperRef} className={this.state.showCalendar ? "calendarView" : "calendarView hide"} >
                <Calendar onClickMonth={this.onClickMonth}  nextLabel={null} next2Label={null}  prev2Label={null}  prevLabel={null} value={this.state.current_date} defaultView='year' maxDetail="year"/>
                </div> 

                <div className="attendanceReportListing">
                  <ol>
                  {this.state.attendanceList != null && this.state.attendanceList && this.state.attendanceList.length > 0 && this.state.attendanceList.map((items) => (
                    <li key = {items.id}>
                        <div className='attendanceDate'>
                          <div className='date'>{this.getFormatDate(items[0]?.start_date_time)}</div>
                          <div className='date'>{this.getFormatDate1(items[0]?.start_date_time)}</div>
                        </div>
                        <div className='attendanceContent'>
                          {items != null && items.map((itemss) => (
                          <div className='attendanceActionsMain'> 
                          <div className='attendanceTop'>
                          <div className='title'>{itemss?.title}</div>
                          <div className='displayDate'>Time - {itemss?.start_date_time ? this.convertStartTime(itemss?.start_date_time) : ''} - {itemss?.end_date_time ? this.convertEndTime(itemss?.end_date_time) : ''}</div>
                          <div className='displayDate'>Duration - {itemss?.duration_hrs} hrs</div>
                          </div> 
                          <div className='attendanceActions'>
                              <div className={itemss?.attendance == "Present" ? "classWise present" : "classWise absent"}>
                                  <label>
                                  {itemss?.attendance == "Present" &&
                                    <img src={presentIcon}/>
                                  }
                                  {itemss?.attendance == "Absent" &&
                                    <img src={absentIcon}/>
                                  }
                                  </label>
                                   {itemss?.attendance == "Present" &&
                                    <span className='present'>Present</span>
                                    }
                                    {itemss?.attendance == "Absent" &&
                                    <span className='absent'>Absent</span>
                                    }
                                </div>
                              <div className={itemss?.recording == "Viewed" ? "liveClasswise present" : "liveClasswise absent"}>
                                  <label>
                                  {itemss?.recording == "Viewed" &&
                                    <img src={viewedIcon}/>
                                  }
                                  {itemss?.recording == "Not Viewed" &&
                                    <img src={notViewedIcon}/>
                                  }
                                  </label>
                                  {itemss?.recording == "Viewed" &&
                                    <span className='present'>Viewed</span>
                                    }
                                    {itemss?.recording == "Not Viewed" &&
                                    <span className='absent'>Not Viewed</span>
                                    }
                                  {/* {itemss?.recording} */}
                              </div>
                          </div>
                          </div>
                          ))}
                        </div>
                    </li>
                   ))}


                   {this.state.attendanceList.length == 0 && <div className='noDataFound'>No Attendance Found</div>}
                   </ol>
                </div>

              </div>
            </div>

            </div>
           
          </div>
        
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    currentpage :state.commonReducer.currentpage,
    loading: state.commonReducer.loading
	}
}

const mapDispatchToProps = (dispatch) => {
  const {setCurrentPage,getLearnerAttendanceReport,getLearnerAttendanceReportFilters } = actions;
  return {
    
    getLearnerAttendanceReport: (batch_id,month,year) => dispatch(getLearnerAttendanceReport(batch_id,month,year)),
    getLearnerAttendanceReportFilters: (token) => dispatch(getLearnerAttendanceReportFilters(token)),
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAttendanceReport);