import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import InputColor from 'react-input-color';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import ReactNotifications, { store } from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import Back from '../../public/icons/back.svg';
import actions from '../../redux/actions';
import common from '../../common';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class ClassSubjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      subject_id: null,
      master_subject: '',
      label: '',
      image: null,
      icon: '',
      imageURL: null,
      short_code: '',
      classs: this.props.match.params?.id,
      class_label: this.props.location.state?.class_label,
      background_code: '#489198',
      center_id: this.props.location.state?.center_id,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      subjects: [],
      subjectsFilter: [],
      filterClass: this.props.location.state?.id,
      master_subjects: [],
      master_keys: {},
      is_faculty_admin:false
    }
  }

  componentDidMount() {
    this.setState({is_faculty_admin:localStorage.getItem("is_faculty_admin")})
    this.getMasterSubjects();
    this.getSubjectList();
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL1 = getUrl.split("/");
    let modifyURL = modifyURL1[2];
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  show = () => this.setState({ open: true, label: '', short_code: '', image: null, imageURL: null,
                                master_subject: null });

  close = () => this.setState({ open: false });

  show2 = (val) => {
    this.setState({ open2: true, subject_id: val.id, label: val.label, short_code: val.short_code,
                      background_code: val.background_code, image: val.thumbnail, imageURL: val.thumbnail,
                      master_subject: val.master_subject, icon: val.image_url  });
  }

  close2 = () => this.setState({ open2: false });

  showd = (val) => this.setState({ opend: true, d_id: val });

  closed = () => this.setState({ opend: false });

  getMasterSubjects = async () => {
    const response = await this.props.getMasterSubjects();
    if(response !== undefined) {
      this.setState({ master_subjects: response.data,
          master_keys: Object.fromEntries(response.data.map(e => [e.id, e])) });
    }
  }

  getSubjectList = async () => {
    const response = await this.props.getSubjectList(this.state.center_id,this.state.classs);
    if(response !== undefined) {
      this.setState({ subjects: response.data, subjectsFilter: response.data });
    }
    if(this.state.classs !== undefined && response !== undefined) {
      var arr = response.data.filter(subject => subject.class_details.id === parseInt(this.state.classs));
      this.setState({ subjectsFilter: arr });
    }
  }

  changeView = async (val) => {
    localStorage.setItem('preferredGrid', val);
    this.setState({ list: !this.state.list });
  }

  handleChange = (event) => {
		if(event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: this.props.pagewiseLanguageData.only_image_can_uploaded,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('img-input').value = "";
  }

  chooseSubject = (e) => {
    this.setState({ master_subject: e.target.value });
    this.setState({ background_code: this.state.master_keys[e.target.value].background_code })
    this.setState({ icon: this.state.master_keys[e.target.value].image });
    this.setState({ label: this.state.master_keys[e.target.value].label })
    this.setState({ short_code: this.state.master_keys[e.target.value].short_code })
  }

  nameChange = (e) => {
    this.setState({ label: e.target.value })
  }

  codeChange = (e) => {
    this.setState({ short_code: e.target.value })
  }

  onColorChange = (color) => {
    this.setState({ background_code: color.hex });
  };

  newSubject = async () => {
    const { label, short_code, classs, master_subject, background_code, icon, image, center_id } = this.state;
    await this.props.createSubject(label, short_code, classs, master_subject, background_code, icon, image, center_id);
    this.setState({ open3: false });
  }

  editSubject = async () => {
    const { subject_id, label, short_code, classs, master_subject, background_code, icon, image, center_id } = this.state;
    await this.props.updateSubject(subject_id, label, short_code, classs, master_subject, background_code, icon, image, center_id);
    this.setState({ open2: false });
  }

  deleteSubject = async () => {
    const { d_id, center_id } = this.state;
    await this.props.deleteSubject(d_id, center_id);
    this.setState({ opend: false });
  }

	render() {
    const isEnabled = this.state.label.length > 0 && this.state.short_code.length > 0 &&
                this.state.master_subject !== null;
    if(this.props.loading) {
      return (
        <div className="admin-div">
          <Header placeholder="Search subjects"/>
          <div className="main-part">
            <div className="sub-head">
              <div className="heading-part1">
                <div className="heading">
                  <h1>{this.props.pagewiseLanguageData.subjects}</h1>
                </div>
              </div>
              <div className="heading-part2">
              </div>
            </div>
            {this.state.list ? <div className="the-list">
              <div className="list-headx">
              </div>
              <Shimmer className={"list-item"}/>
              <Shimmer className={"list-item"}/>
            </div> : <div className="the-grid">
              <Shimmer className={"grid-item"}/>
              <Shimmer className={"grid-item"}/>
            </div> }
          </div>
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>{`${this.state.class_label} | ${this.props.pagewiseLanguageData.subjects}`}</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search subjects"/>
        <Popup open={this.state.open} closeOnDocumentClick onClose={this.close}>
          <div className="popup-header">
            {this.props.pagewiseLanguageData.add_new_subjects}
          </div>
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <select onChange={this.chooseSubject}>
                <option value={null}>
                  {this.props.pagewiseLanguageData.select_master_subject}
                </option>
                {this.state.master_subjects.map(subject =>
                  <option key={subject.id} value={subject.id}>
                    {subject.label}
                  </option>
                )}
              </select>
              <input placeholder={this.props.pagewiseLanguageData.subject_code} value={this.state.short_code}
                onChange={this.codeChange}/>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.subject_title} value={this.state.label}
                onChange={this.nameChange}/>
              <div className="popup-color">
                <InputColor initialValue={this.state.background_code}
                  onChange={this.onColorChange}/>
                <span id="back_name">{this.state.background_code}</span>
              </div>
            </div>
            <div className="popup-admin">
              <div className="popup-admin-child">
                {this.state.image === '' || this.state.image === null
                  ? <div className="img-prev-subject" onClick={() => this.fileInput.click()}>
                  <img src={ImgHolder} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div> : <div className="img-prev-subject">
                  <span onClick={this.removeImage}>&#x2715;</span>
                  <img src={common.addFileUrl(this.state.imageURL)} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div>}
                <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                <span>{this.props.pagewiseLanguageData.add_a_thumbnail}</span>
              </div>
              <div className="popup-admin-child">
                {this.state.icon === '' || this.state.icon === null
                  ? <div className="img-prev-subject">
                  <img src={ImgHolder} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div> : <div className="img-prev-subject">
                  {/* <span onClick={this.removeImage}>&#x2715;</span> */}
                  <img src={common.addFileUrl(this.state.icon)} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div>}
                {/* <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/> */}
                <span>{this.props.pagewiseLanguageData.subject_icon}</span>
              </div>
            </div>
          </div>
          <div className="popup-actions">
            <button disabled={!isEnabled} onClick={this.newSubject}>{this.props.pagewiseLanguageData.add_new}</button>
          </div>
        </Popup>
        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}>
          <div className="popup-header">
            {this.props.pagewiseLanguageData.edit_subject}
          </div>
          <span className="close" onClick={this.close2}>&#x2715;</span>
          <div className="popup-data">
            <div className="popup-inputs">
              <select onChange={this.chooseSubject} value={this.state.master_subject}>
                <option value={null} disabled>
                 {this.props.pagewiseLanguageData.select_master_subject}
                </option>
                {this.state.master_subjects.map(subject =>
                  <option key={subject.id} value={subject.id}>
                    {subject.label}
                  </option>
                )}
              </select>
              <input placeholder={this.props.pagewiseLanguageData.subject_code} onChange={this.codeChange}
                value={this.state.short_code}/>
            </div>
            <div className="popup-inputs">
              <input placeholder={this.props.pagewiseLanguageData.subject_title} onChange={this.nameChange}
                value={this.state.label}/>
              <div className="popup-color">
                <InputColor initialValue={this.state.background_code}
                  onChange={this.onColorChange}/>
                <span id="back_name">{this.state.background_code}</span>
              </div>
            </div>
            <div className="popup-admin">
              <div className="popup-admin-child">
                {this.state.image === '' || this.state.image === null
                  ? <div className="img-prev-subject" onClick={() => this.fileInput.click()}>
                  <img src={ImgHolder} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div> : <div className="img-prev-subject">
                  <span onClick={this.removeImage}>&#x2715;</span>
                  <img src={common.addFileUrl(this.state.imageURL)} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div>}
                <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
                <span>{this.props.pagewiseLanguageData.add_a_thumbnail}</span>
              </div>
              <div className="popup-admin-child">
                {this.state.icon === '' || this.state.icon === null
                  ? <div className="img-prev-subject">
                  <img src={ImgHolder} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div> : <div className="img-prev-subject">
                  {/* <span onClick={this.removeImage}>&#x2715;</span> */}
                  <img src={common.addFileUrl(this.state.icon)} alt={this.props.pagewiseLanguageData.subject_icon}/>
                </div>}
                {/* <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
                  onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/> */}
                <span>{this.props.pagewiseLanguageData.subject_icon}</span>
              </div>
            </div>
          </div>
          <div className="popup-actions">
            <button onClick={this.editSubject}>{this.props.pagewiseLanguageData.save}</button>
          </div>
        </Popup>
        <Popup open={this.state.opend} closeOnDocumentClick onClose={this.closed}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt={this.props.pagewiseLanguageData.delete}/>
            {this.props.pagewiseLanguageData.are_you_sure_you_want_to_delete_this_subject}
          </div>
          <div className="delete-down">
            <button onClick={this.closed}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteSubject}>
              {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>
        <div className="main-part">
					<div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
                <Link id="trace-back" to={`/admin/classes`}>
                  <img src={Back} alt="back"/>
                </Link>
								<h1>{this.state.class_label} | {this.props.pagewiseLanguageData.subjects}</h1>
							</div>
						</div>
						<div className="heading-part2">
              {!this.state.is_faculty_admin && <span onClick={this.show}>
								<img src={Add} alt="img"/>{this.props.pagewiseLanguageData.add_new}
							</span>}
							{!this.state.list ? <span onClick={() => this.changeView('false')}>
                <img src={List} alt="img"/>{this.props.pagewiseLanguageData.list_view}
              </span> : <span onClick={() => this.changeView('true')}>
                <img src={Grid} alt="img"/>{this.props.pagewiseLanguageData.grid_view}
              </span>}
						</div>
					</div>
          {this.state.subjectsFilter.length === 0 ? <><span id="no-data-admin">
               {this.props.pagewiseLanguageData.no_class_subject_is_mapped_yet}
              </span> <Empty/></> : <>
            {this.state.list ? <div className="list-head list-assessment">
              <div className="list-title">
                {this.props.pagewiseLanguageData.name}
              </div>
              <div className="list-email">
              {this.props.pagewiseLanguageData.code}
              </div>
              <div className="list-subject">
              {this.props.pagewiseLanguageData.class}
              </div>
              <div className="list-faculty">
              {this.props.pagewiseLanguageData.center}
              </div>
              {!this.state.is_faculty_admin &&<div className="list-action">
              {this.props.pagewiseLanguageData.actions}
              </div>}
            </div> : null}
            {this.state.list ? <div className="the-list the-assessment-list">
              {this.state.subjectsFilter.map((subject, index) =>
                <div className="list-item" key={subject.id}>
                  <Link className="title-item2" to={{
                    pathname: `/admin/study-material/units`,
                    search: `?class=${subject.class_details.id}&subject=${subject.id}`,
                    state:{ classs: subject.class_details.label, subject: subject.label }}}>
                    <div className="title-image" style={{backgroundImage: "url("+common.addFileUrl(subject.image_url)+")"}}>
                    </div>
                    {subject.label}
                  </Link>
                  <div className="email-item">
                    <div style={{backgroundColor: subject.background_code}}></div>
                    {subject.short_code}
                  </div>
                  <div className="subject-item">
                    {subject.class_details.label}
                  </div>
                  <div className="faculty-item">
                    {subject.branch.name}
                  </div>
                  <div className="action-item">
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(subject)}>
                      <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                    </span>}
                    {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(subject.id)}>
                      <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                    </span>}
                  </div>
                </div>
              )}
            </div> : <div className="the-grid">
              {this.state.subjectsFilter.map((subject, index) =>
                <div className="grid-item" key={subject.id}>
                  <div className="grid-up grid-up-v1">
                    <Link className="title-grid2" to={{
                      pathname: `/admin/study-material/units`,
                      search: `?class=${subject.class_details.id}&subject=${subject.id}`,
                      state:{ classs: subject.class_details.label, subject: subject.label }}}>
                      <div style={{backgroundColor: subject.background_code}}></div>
                      {subject.label}
                    </Link>
                    <div className="grid-actions">
                      {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.show2(subject)}>
                        <img src={Edit} alt={this.props.pagewiseLanguageData.edit}/>
                      </span>}
                       {!this.state.is_faculty_admin && <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.showd(subject.id)}>
                        <img src={Delete} alt={this.props.pagewiseLanguageData.delete}/>
                      </span>}
                    </div>
                  </div>
                  <div className="grid-up grid-up-v1">
                    <div className="grid-sec">
                    {this.props.pagewiseLanguageData.code}
                      <span>{subject.short_code}</span>
                    </div>
                    <div className="grid-image" style={{backgroundImage: "url("+common.addFileUrl(subject.image_url)+")"}}>
                    </div>
                  </div>
                </div>
              )}
            </div>}
          </>}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getSubjectList, createSubject, updateSubject, getMasterSubjects,
            deleteSubject,getPageLanguageWiseData } = actions;
  return {
    createSubject: (label, short_code, classs, master_subject, background_code, image, thumbnail, center_id) => dispatch(createSubject(label, short_code, classs, master_subject, background_code, image, thumbnail, center_id)),
    updateSubject: (subject_id, label, short_code, classs, master_subject, background_code, image, thumbnail, center_id) => dispatch(updateSubject(subject_id, label, short_code, classs, master_subject, background_code, image, thumbnail, center_id)),
    getMasterSubjects: () => dispatch(getMasterSubjects()),
    getSubjectList: (center_id,class_id) => dispatch(getSubjectList(center_id,class_id)),
    deleteSubject: (id, center_id) => dispatch(deleteSubject(id, center_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassSubjects);
