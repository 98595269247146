import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import axios from 'axios';
import common from '../../common';
import searchIcon from '../../public/icons/search-icon-new.png'
import scancodebanner from '../../public/images/scancode-banner.png'
import IXPandroidscancode from '../../public/images/IXP-android-scan-code.png'
import googleplay from '../../public/images/googleplay-store.svgz'

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validMobileRegex = /^[0-9\b]+$/;

class AicteSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      token:null,
      mycoachdata:[],
      profile: {},
      errors:{},
      center_id:'',
      type:'',
      searchEmail:'',
      isExist:0,
      user_id:'',
      challengesData:[],
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
      isChecked: true,
      search_response:[],
      email_send_status:'',
      registerUserStatus:'',
      nameError:false,
      emailError:false,
      mobileError:false
   }
  }
  
  componentDidMount() {
   
  }

  search = async() =>{
    if(this.state.searchEmail){
      this.setState({isExist:0})
      this.setState({email_send_status:''});
     const response =  await this.props.searchUser(this.state.searchEmail)
     this.setState({search_response:response.data});
     if(response){
      if(response.status == 1){
        this.setState({user_id:response.data});
        //await this.props.wpUserLink(response.data[0].id,5);
        this.setState({isExist:1})
      }else{
        this.setState({isExist:2})
      }
     }
    }else{
      this.setState({isExist:3})
    }
  }

  handleChange = (e) =>{
    let errors = this.state.errors;
    const name = e.target.name
    const value = e.target.value 

    switch (name) {
      case 'email': 
        if(!validEmailRegex.test(value)){
          errors[name] = 'email is not valid!';
        }else{
          delete errors[name];
        }
        break;
      case 'mobile': 
        if(!validMobileRegex.test(value) ||  value.length > 10 || value.length <10){
          errors[name] = 'Mobile no. is not valid!';
        }else{
          delete errors[name];
        }
        break;
      default:
        break;
    }

    this.setState({[name]:value,errors})

  }

  registerUser = async()=>{
    const response = await this.props.userSignup(this.state.name,this.state.email,this.state.mobile,5,localStorage.getItem('lang'));
    if(response){
      if(response.status == 1){
        this.setState({registerUserStatus:response.status});
       
      }
    }else{
      console.log("dsaasdasdads");
      console.log(response?.error);
   }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }


  resetPassword = async(index) =>{
    this.setState({email_send_status:''});
    let data = this.state.user_id;
    let userid = data[index].id;
    const response = await this.props.wpUserLink(userid,5);
    if(response){
      if(response.status == 1){
        this.setState({email_send_status:response.status});
      }
    }
  }

	render() {
    //const emailSendStatus = this.state.email_send_status;
    const isEnabled = this.state.name && this.state.email && this.state.mobile && !Object.keys(this.state.errors).length

    return (
      <>
      <ReactNotifications/>
      
        <div className="app-body webview">
          
          <div className="app-div mycoach-div">
            <Helmet>
              <title>Signup - Learner</title>
            </Helmet>
            
              {/*aicteSignup Start*/}
              <div className="aicteSignupWrapper">
                  <div className="aicteSignupContainer">

                  <div className="headerWrapper">
                    <div className="headerContainer">
                        <div className="searchWrapper">
                            <h1>Student Innovation Ambassador (IA) Program Login Self-Help</h1>
                            <p>If you are facing any login related issue, please start by searching</p>
                            <div className="searchBlock">
                                <div className="formblock">
                                  <input type="search" value={this.state.searchEmail} onChange={this.handleChange} name="searchEmail" placeholder="check if your Email id is registered or not "/>
                                  {/*<span className="errorMsg">Please enter your correct email id</span>*/}
                                </div>
                                <button onClick={this.search}>Search</button>
                            </div>
                        </div>
                      </div>
                  </div>

                    <div className="bottomListingWrapper">
                      <div className="bottomListingContainer">

                        <div className="SearchDefaultContent">
                            <strong>Note:</strong>
                            <p>If you are facing any login related issue, please follow the process to access the app. </p>
                            <ol>
                              <li>Search your Email Id here </li>
                              <li>If you find the Email Id, you will receive a link to reset password on your email id. </li>
                              <li>In case you don’t find the email id, you will be asked to sign-up here. </li>
                            </ol>
                        </div>

                          
                      
                        {this.state.isExist == 3 && <div className="SearchEmailNotExist">

                           {/*Search Listing Start*/}
                          <div className="searchResultWrapper">
                              <div className="searchResultTableWrapper">
                              <p>No email Id found to check</p>
                                
                                <div className="SearchEmailExist">
                                <div className="scancode-section">
                                  <h2>Download the Mobile app now and start learning IA Program </h2>
                                  <div className="scancode-container">
                                    <div className="left-section">
                                      <div className="scan-section-banner">
                                        <img src={scancodebanner}/>
                                      </div>
                                    </div>    
                                    <div className="right-section">
                                      <div className="qr-code-section">
                                        <p>Scan this QR code on your camera app to download the app</p>
                                        <img src={IXPandroidscancode}/>
                                        <div className="app-store-links">
                                          <div className="playstore-btn">
                                            <a href="https://play.google.com/store/apps/details?id=com.imaginxp" target="_blank"><img src={googleplay} alt="google play"/></a>
                                          </div>  
                                        </div>
                                      </div>
                                    </div>    
                                  </div>
                                </div>
                              </div>  
                              
                            </div>
                          </div>
                             {/*Search Listing Start*/}
                       </div>}

                        {this.state.isExist == 1 && <div className="SearchEmailExist">

                           {/*Search Listing Start*/}
                          <div className="searchResultWrapper">
                              <div className="searchResultTableWrapper">
                              <p>Your email id is already registered with us</p>
                                <ol>
                                  {this.state.search_response?.map((items, index) => (
                                   <li key={index}>
                                        <div className="list-data">
                                          <div className="listname"><strong>Name:-</strong> <span>{items.name}</span></div>
                                          <div className="listemail"><strong>Email :-</strong> <span>{items.email}</span></div>
                                          <div className="listmobile"><strong>Mobile :-</strong> <span>{items.mobile}</span></div>
                                        </div>
                                      <div className="listaction"><button onClick={() => this.resetPassword(index)}>Click here if you want to Reset the Password?</button></div>
                                    </li>
                                  ))}
                                </ol>
                                { this.state.email_send_status && 
                                <div className="SearchEmailExist">
                                <p>We have sent a confirmation link on your email id to Reset your Password </p>
                                <div className="scancode-section">
                                  <h2>Download the Mobile app now and start learning IA Program </h2>
                                  <div className="scancode-container">
                                    <div className="left-section">
                                      <div className="scan-section-banner">
                                        <img src={scancodebanner}/>
                                      </div>
                                    </div>		
                                    <div className="right-section">
                                      <div className="qr-code-section">
                                        <p>Scan this QR code on your camera app to download the app</p>
                                        <img src={IXPandroidscancode}/>
                                        <div className="app-store-links">
                                          <div className="playstore-btn">
                                            <a href="https://play.google.com/store/apps/details?id=com.imaginxp" target="_blank"><img src={googleplay} alt="google play"/></a>
                                          </div>	
                                        </div>
                                      </div>
                                    </div>		
                                  </div>
                                </div>
                              </div>  
                                }  
                            </div>
                          </div>
                             {/*Search Listing Start*/}
                       </div>}

                          {this.state.isExist == 2 && this.state.registerUserStatus == '' &&<div className="SearchEmailNotExist">
                            <p>Your email id is not registered with us, please sign-up to start learning</p>
                            {/*<div className="actions"><button>Sign-up</button></div>*/}
                            <div className="signupFormWrapper">
                              {/*<h2>Join us</h2>
                              <div className="seperator"></div>*/}
                              <div className="formblock">
                                <input type="text" placeholder="enter your name" value={this.state.name} required  onChange={this.handleChange} name="name"/>
                                {!this.state.name && <div className="errorMsg">Please enter your name</div>}
                              </div>
                              <div className="formblock">
                                <input type="email" placeholder="enter your email" value={this.state.email}  required  onChange={this.handleChange} name="email"/>
                                {!this.state.email && <div className="errorMsg">enter valid emailid</div> || (this.state.errors &&  this.state.errors['email']) && <div className="errorMsg">enter valid emailid</div>}
                              </div>
                              <div className="formblock">
                                <input type="tel" placeholder="enter your mobile" value={this.state.mobile} required  onChange={this.handleChange} name="mobile"/>
                                {!this.state.mobile && <div className="errorMsg">enter valid mobile no.</div> || (this.state.errors && this.state.errors['mobile']) && <div className="errorMsg">enter valid mobile no.</div>}
                              </div>
                              <div className="actions"><button onClick={this.registerUser} disabled={!isEnabled}>Sign-up</button></div>
                              <div className="termsBlock"><label><input type="checkbox" defaultChecked={this.state.isChecked} onChange={this.toggleChange} />I am here by signing up to learn IA Program and agree to the Terms and conditions</label></div>
                            </div>
                          </div>
                          }

                          
                          {this.state.registerUserStatus &&       
                          <div className="SearchEmailNotExistAfterSignUp">
                              <p>Your username and password are sent on your<br/> email Id.</p>
                              <div className="scancode-section">
                              <h2>Download the Mobile app now and start learning IA Program </h2>
                              <div className="scancode-container">
                                <div className="left-section">
                                  <div className="scan-section-banner">
                                    <img src={scancodebanner}/>
                                  </div>
                                </div>		
                                <div className="right-section">
                                  <div className="qr-code-section">
                                    <p>Scan this QR code on your camera app to download the app</p>
                                    <img src={IXPandroidscancode}/>
                                    <div className="app-store-links">
                                      <div className="playstore-btn">
                                        <a href="https://play.google.com/store/apps/details?id=com.imaginxp" target="_blank"><img src={googleplay} alt="google play"/></a>
                                      </div>	
                                    </div>
                                  </div>
                                </div>		
                              </div>
                            </div>
                          </div>}
                         
                      </div>
                     </div>

                  </div>
              </div>
              {/*aicteSignup ends*/}

            </div>
          </div>
        
      </>
		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage
   
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getLearnerProfile, searchUser,wpUserLink,userSignup,xpGetAllChallengesAssignmentList} = actions;
  return {
    userSignup:(name,email,mobile,center_id,language) => dispatch(userSignup(name,email,mobile,center_id,language)),
    searchUser:(email) => dispatch(searchUser(email)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    xpGetAllChallengesAssignmentList: (center_id,type) => dispatch(xpGetAllChallengesAssignmentList(center_id,type)),
    wpUserLink:(user_id,center_id) =>dispatch(wpUserLink(user_id,center_id)),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AicteSignup);