import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import ReactNotifications, { store } from 'react-notifications-component';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import config from '../../config.js';
import common from '../../common';
import actions from '../../redux/actions';
import './styles.css';
import { Link, useLocation } from "react-router-dom";

let changeN = 0;

class ViewRecording extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lectures: [],
      username: localStorage.getItem("username"),
      userID: localStorage.getItem("user_id"),
      token: localStorage.getItem("token"),
      recordingUrl : localStorage.getItem("recordingUrl"),
    }
   
  }
  
  componentDidMount() {
    var url = window.location.pathname;
    var getid = url.substring(url.lastIndexOf('/') + 1);
    console.log("getid",getid);
    console.log("recordingUrl",this.state.recordingUrl);

    //alert(this.state.recordingUrl);
    


document.addEventListener('contextmenu', (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      )
        return false;
    };

    
  }



	render() {
    return (
			<div className="viewRecordingWrapper">
        <Helmet>
          <title>View Recording</title>
        </Helmet>
        <ReactNotifications/>
				
          {this.state.recordingUrl != '' ? <iframe allowfullscreen="1"  src={this.state.recordingUrl}></iframe> : <div className='unauthorizedAccessDialog'><div className='unauthorizedAccessContainer'>Unauthorized access <br/>You do not have the required permissions.</div></div>}
           

         
        
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getStudentLectureList} = actions;
  return {
    getStudentLectureList: () => dispatch(getStudentLectureList()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRecording);