import React  from 'react';
import { connect } from 'react-redux';

import common from '../../common';
import actions from '../../redux/actions';
import Open from '../../public/images/open-arrow.svg';
import Menu from '../../public/icons/menu.svg';
import Close from '../../public/images/close-arrow.svg';
import './styles.css';

class ContentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mock: false
    }
  }

  onClick = (assignment_id,content_id, content_subtype) => {
    this.props.directContentPreview(assignment_id,content_id, content_subtype);
    this.props.changeTocTable(this.props.id);
  }

  render() {
    return (
      <>
        {this.props.content.length > 0 &&
          <li className="has-sub has-sub2">
            <ul>
              <li>
                {this.props.content.map((file, index) => 

                  

                  <div key={`content_${index}`} className={`has-descrip_${file.content_id}`}
                    onClick={() => this.onClick(file.assignment_id,file.content_id, file.content_subtype)}>
                      <div className="has-title2" id={file.assignment_id ? file.assignment_id === this.props.selectedContentId ? "content-selec" : "" :file.content_id === this.props.selectedContentId ? "content-selec" : "" 
                        }>
                        <div>
                          <i><img src={common.getContentIcon(file.content_subtype)} alt="img"/></i>
                          {file.title}
                        </div>
                        {file.description === null || file.description === '' ? null
                          : <>
                          {/*file.assignment_id ? <p className="content-descriptionx"
                            style={file.assignment_id === this.props.selectedContentId 
                            ? {color: '#0693E3', fontWeight: 'bold'} : {}}>{file.description}</p> : <p className="content-descriptionx"
                            style={file.content_id === this.props.selectedContentId 
                            ? {color: '#0693E3', fontWeight: 'bold'} : {}}>{file.description}</p>*/}
                            
                          </>}
                      </div>
                  </div>
                )}
              </li>
            </ul>
          </li>
        }
      </>
    )
  }
}


const ChapterList = (props) => (
  <>
    {props.chapters.length > 0 &&
      props.chapters.map((chapter, index) => 
        <li className="has-sub has-sub3" key={`chapter_${index}`}>
          <div className="has-title" onClick={() => props.toggleChapter(props.unitId, chapter.id)}>
            {chapter.label}
						<img src={chapter.expanded ? Open : Close} alt="toggle"/>
          </div>
          <div style={chapter.expanded ? {} : {display: 'none'}} animation='scale' duration={500}>
            <ul>
              <ContentList
                id={chapter.id}
                content={chapter.content}
                getContentPreview={props.getContentPreview}
                selectedContentId={props.selectedContentId}
                changeTocTable={props.changeTocTable}
                directContentPreview={props.directContentPreview}
              />
              <TopicList
                topicList={chapter.topics}
                chapterId={chapter.id}
                getContentPreview={props.getContentPreview}
                {...props}
              />
            </ul>
          </div>
        </li> 
      )
    }
  </>
)

const TopicList = (props) => (
  <>
    {props.topicList.length > 0 &&
      props.topicList.map((topic, index) => 
        <li className="has-sub has-sub4" key={`topic${index}`}>
          <div className="has-title" onClick={()=>props.toggleTopic(props.unitId, props.chapterId, topic.id)}>
            {topic.label}
						<img src={topic.expanded ? Open : Close} alt="toggle"/>
          </div>
          <div style={topic.expanded ? {} : {display: 'none'}} animation='scale' duration={500}>
            <ul>
              <ContentList
                id={topic.id}
                content={topic.content}
                getContentPreview={props.getContentPreview}
                selectedContentId={props.selectedContentId}
                changeTocTable={props.changeTocTable}
                directContentPreview={props.directContentPreview}
              />
             	<SubTopicList
                subtopicsList={topic.subtopics}
                getContentPreview={props.getContentPreview}
                {...props}
              />
            </ul>
          </div>
        </li> 
      )
    }
  </>
)

const SubTopicList = (props) => (
  <>
    {props.subtopicsList.length > 0 &&
      props.subtopicsList.map((subtopic, index) => 
        <li className="has-sub has-sub5" key={`subtopic${index}`}>
          <div className="has-title">
            {subtopic.label}
          </div>
          <ul>
            <ContentList
              id={subtopic.id}
              content={subtopic.content}
              getContentPreview={props.getContentPreview}
              selectedContentId={props.selectedContentId}
              changeTocTable={props.changeTocTable}
              directContentPreview={props.directContentPreview}
            />
          </ul>
        </li> 
      )
    }
  </>
)

class ContentFilter extends React.Component {
  constructor(props) {
    super(props);
    this.lastChapter = false
  }

  componentDidMount() {
    this.props.toc_units.map(unit => {
      unit.chapters.map(chapter => {
        if(chapter.id === this.props.highlightId && !unit.expanded) {
          this.props.toggleUnit(unit.id);
          this.props.toggleChapter(unit.id, chapter.id)
          chapter.topics.map(topic => {
            this.props.toggleTopic(unit.id, chapter.id, topic.id)
          })
        }
      })
    })
  }

  componentWillReceiveProps(nextProps, nextState) {
    if(nextProps.toc_units.length !== this.props.toc_units.length) {
      nextProps.toc_units.map(unit => {
        unit.chapters.map(chapter => {
          if(chapter.id === nextProps.highlightId) {
            this.props.toggleUnit(unit.id);
            this.props.toggleChapter(unit.id, chapter.id)
            chapter.topics.map(topic => {
              this.props.toggleTopic(unit.id, chapter.id, topic.id)
            })
          }
        })
      })
    }
		else {
      if(nextProps.toc_units.length !== this.props.toc_units.length || 
        nextProps.toc_units[0].id !== this.props.toc_units[0].id) {
          this.lastChapter = false;
          nextProps.toc_units.map(unit => {
            unit.chapters.map(chapter => {
              if(chapter.id === nextProps.highlightId){
                this.lastChapter = true
                this.props.toggleUnit(unit.id);
                this.props.toggleChapter(unit.id, chapter.id)
                chapter.topics.map(topic => {
                  this.props.toggleTopic(unit.id, chapter.id, topic.id)
                })
              }
              if(!this.lastChapter &&
                	nextProps.toc_units[nextProps.toc_units.length - 1] === unit &&
                  unit.chapters[unit.chapters.length - 1] === chapter) {
                    this.props.toggleUnit(unit.id);
                    this.props.toggleChapter(unit.id, chapter.id)
                    chapter.topics.map(topic => {
                      this.props.toggleTopic(unit.id, chapter.id, topic.id)
                    })
              }
            })
        	})
      }
    }
  }

  render() {
    return (
      <div className="content-filter-section" style={this.props.wChange ? {width: '44px', height: '40px', 
        overflow: 'hidden', padding: '0px 0px', backgroundColor: 'white', borderRadius: '0'} : {}}>
        <div className="content-filter-toggle">
          <span onClick={this.props.onWChange}>
            <img src={Menu} alt="menu"/>
          </span>
        </div>
        {
					this.props.toc_units === null ?	<div>Toc Units are Empty</div> 
						:	<>
                <div className="dd-wrapper">
                  <ul>
                    {this.props.toc_units.map((unit, index) => 
                      <li className="has-sub" key={`unit_${index}`}>
												<div className="main-title" onClick={() => { this.props.toggleUnit(unit.id) }}>
													{unit.label}<img src={unit.expanded ? Open : Close}/>
												</div>
												<div style={unit.expanded ? {} : {display: 'none'}} animation='scale' duration={500}>
													<ul className="red">
														<ContentList
                              id={unit.id}
															content={unit.content}
															getContentPreview={this.props.getContentPreview}
                              selectedContentId={this.props.selectedContentId}
                              changeTocTable={this.props.changeTocTable}
                              directContentPreview={this.props.directContentPreview}
														/>
														<ChapterList
															chapters={unit.chapters}
															unitId={unit.id}
															getContentPreview={this.props.getContentPreview}
															{...this.props}
														/>
													</ul>
												</div>
                    	</li>
                  	)}
                  </ul>  
                </div>
              </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
		loading: state.commonReducer.loading,
		toc_units: state.classroomReducer.toc_lists,
		highlightId: state.classroomReducer.highlightId,
    selectedContentId: state.classroomReducer.selectedContentId
  }
}

const mapDispatchToProps = (dispatch) => {
  const { toggleUnit, getTocList, toggleChapter, toggleTopic, getContentPreview } = actions;
  return {
    getTocList: (subjectId) => dispatch(getTocList(subjectId)),
    toggleUnit: (unitId) => dispatch(toggleUnit(unitId)),
    toggleChapter: (unitId, chapterId) => dispatch(toggleChapter(unitId, chapterId)),
    toggleTopic: (unitId, chapterId, topicId) => dispatch(toggleTopic(unitId, chapterId, topicId)),
    getContentPreview: (contentId, content_subtype) => dispatch(getContentPreview(contentId, content_subtype)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentFilter);