import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// import { Tab } from "semantic-ui-react";

import actions from '../../redux/actions';
import './styles.css';
import './circle.css';

function getData(value, data) {
  if(data.batch > data.you) {
    if(value === data.batch) {
      if(data.you < 0) {
        return `${((data.batch - data.you)/maxmin(data))*100}%`
      }
      else {
        return `${(data.batch/maxmin(data))*100}%`
      }
    }
    else {
      if(value <= 0 && data.you > 0) {
        return '6px';
      }
      else {
        return `${(value/maxmin(data))*100}%`;
      }
    }
  }
  else if(data.batch < data.you) {
    if(value === data.you) {
      if(data.batch < 0) {
        return `${((data.you - data.batch)/maxmin(data))*100}%`
      }
      else {
        return `${(data.you/maxmin(data))*100}%`
      }
    }
    else {
      if(value <= 0 && data.batch > 0) {
        return '6px';
      }
      else {
        return `${(value/maxmin(data))*100}%`;
      }
    }
  }
  else {
    return `${(value/maxmin(data))*100}%`;
  }
}

function getData3(value, data) {
  console.log(value)
  let prec = (100*value)/360;  
  return 'linear-gradient(180deg, transparent '+`${value}`+'%, #A2ECFB 0%),linear-gradient(90deg, #A2ECFB 0%, transparent 0%)';
}

function getData4(value,data){
  let prec = (100*value);  
  return 'linear-gradient(100deg, transparent '+`${prec}` +'%, rgb(30, 40, 41) 0%,rgb var(--primary), linear-gradient(90deg, rgb(226, 236, 239) 0%, transparent 0%)';
}


function getData2(value, data) {
  if(data.batch_average > data.you) {
    if(value === data.batch_average) {
      if(data.you < 0) {
        return `${((data.batch_average - data.you)/maxmin2(data))*100}%`
      }
      else {
        return `${(data.batch_average/maxmin2(data))*100}%`
      }
    }
    else {
      if(value <= 0 && data.batch_average > 0) {
        return '6px';
      }
      else {
        return `${(value/maxmin2(data))*100}%`;
      }
    }
  }
  else if(data.batch_average < data.you) {
    if(value === data.you) {
      if(data.batch_average < 0) {
        return `${((data.you - data.batch_average)/maxmin2(data))*100}%`
      }
      else {
        return `${(data.you/maxmin2(data))*100}%`
      }
    }
    else {
      if(value <= 0 && data.you > 0) {
        return '6px';
      }
      else {
        return `${(value/maxmin2(data))*100}%`;
      }
    }
  }
  else {
    return `${(value/maxmin2(data))*100}%`;
  }
}

function maxmin(data) {
  if(data.batch > data.you) {
    if(data.you < 0) {
      return (data.batch - data.you)
    }
    return data.batch
  }
  else if(data.batch < data.you){
    if(data.batch < 0) {
      return (data.you - data.batch)
    }
    return data.you
  }
  else {
    return data.batch;
  }
}

function maxmin2(data) {
  if(data.batch_average > data.you) {
    if(data.you < 0) {
      return (data.batch_average - data.you)
    }
    return data.batch_average
  }
  else if(data.batch_average < data.you){
    if(data.batch_average < 0) {
      return (data.you - data.batch_average)
    }
    return data.you
  }
  else {
    return data.batch_average;
  }
}

const Questions = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.ques_attemped}</span>
      <div className="scorecard_cardx">
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.batch * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_high"
            style={{width: getData(props.data.batch, props.data)}}>
          </div>
        </div>
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.you * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_avg"
            style={{width: getData(props.data.you, props.data)}}>
          </div>
        </div>
      </div>
      <div className="scorecard_colors">
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_high">
          </div>
          <span>{props.saveLanguageWiseData.batch_average}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_avg">
          </div>
          <span>{props.saveLanguageWiseData.you}</span>
        </div>
      </div>
    </div>
  )
}

const MaxScore = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.data.label}</span>
      <div className="scorecard_cardx">
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.batch * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_high"
            style={{width: getData(props.data.batch, props.data)}}>
          </div>
        </div>
        <div className="scorecard_questions_bar">
          <span>{(Math.round(props.data.you * 100) / 100)}</span>
          <div className="scorecard_bar_ques score_avg"
            style={{width: getData(props.data.you, props.data)}}>
          </div>
        </div>
      </div>
      <div className="scorecard_colors">
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_high">
          </div>
          <span>{props.saveLanguageWiseData.batch_average}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_avg">
          </div>
          <span>{props.saveLanguageWiseData.you}</span>
        </div>
      </div>
    </div>
  )
}

const CorrectAnswers = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.correct_answers}</span>
      <div className="scorecard_card3">
        <div className="scorecard_times">
          
          <div id="activeBorder" class="active-border1" style={{backgroundImage: getData4((Math.round(props.data.batch * 100) / 100), props.data)}}>
            <div id="circle" class="circle1">
              <span class="prec 270" id="prec">{(Math.round(props.data.batch * 100) / 100)}</span>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.avg_correct_answers}</span>
        </div>
        <div className="scorecard_times">
          <div id="activeBorder" class="active-border1" style={{backgroundImage: getData4((Math.round(props.data.you * 100) / 100), props.data)}}>
            <div id="circle" class="circle1">
              <span class="prec 270" id="prec">{(Math.round(props.data.you * 100) / 100)}</span>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.your_correct_answers}</span>
        </div>
      </div>
      <div className="scoreNotify">{props.saveLanguageWiseData.subjective_scorecard}</div>
    </div>
  )
}

const AvgTime = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.avg_time_ques}</span>
      <div className="scorecard_card2">
        <div className="scorecard_times">
          <div className="scorecard_time score_high">
            {(Math.round(props.data.batch * 100) / 100)}s
          </div>
          <span>{props.saveLanguageWiseData.batch_avg_time}</span>
        </div>
        <div className="scorecard_times">
          <div className="scorecard_time score_avg">
            {(Math.round(props.data.you * 100) / 100)}s
          </div>
          <span>{props.saveLanguageWiseData.your_avg_time}</span>
        </div>
      </div>
    </div>
  )
}

const Accuracy = (props) => {
  
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.accuracy}</span>
      <div className="scorecard_card2">
        <div className="scorecard_times">
          <div class={"c100 p"+(Math.round((props.data.batch * 100) / 100))}>
            <span>{(Math.round((props.data.batch * 100) / 100))}%</span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.batch_avg_accuracy}</span>
        </div>
        <div className="scorecard_times">
          <div class={"c100 p"+(Math.round((props.data.you * 100) / 100))}>
            <span>{(Math.round((props.data.you * 100) / 100))}%</span>
            <div class="slice">
              <div class="bar"></div>
              <div class="fill"></div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.Your_accuracy}</span>
        </div>
      </div>

      <div className="scoreNotify">{props.saveLanguageWiseData.subjective_scorecard}</div>
    </div>
  )
}

const CorrectPattern = (props) => {
  return (
    <div className="scorecard-overall-cards">
      <span>{props.saveLanguageWiseData.scoring_pattern}</span>
      <div className="scorecard_card2">
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{(Math.round(props.data.easy.batch_average * 100) / 100)}</span>
              <div className="high_pattern score_high"
                style={{height: getData2(props.data.easy.batch_average, props.data.easy)}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{(Math.round(props.data.easy.you * 100) / 100)}</span>
              <div className="high_pattern score_avg"
                style={{height: getData2(props.data.easy.you, props.data.easy)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.easy}</span>
        </div>
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{(Math.round(props.data.medium.batch_average * 100) / 100)}</span>
              <div className="high_pattern score_high"
                style={{height: getData2(props.data.medium.batch_average, props.data.medium)}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{(Math.round(props.data.medium.you * 100) / 100)}</span>
              <div className="high_pattern score_avg"
                style={{height: getData2(props.data.medium.you, props.data.medium)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.medium}</span>
        </div>
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{(Math.round(props.data.hard.batch_average * 100) / 100)}</span>
              <div className="high_pattern score_high"
                style={{height: getData2(props.data.hard.batch_average, props.data.hard)}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{(Math.round(props.data.hard.you * 100) / 100)}</span>
              <div className="high_pattern score_avg"
                style={{height: getData2(props.data.hard.you, props.data.hard)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.hard}</span>
        </div>
        <div className="scorecard_patterns">
          <div className="scorecard_pattern">
            <div className="answer_pattern">
              <span>{(Math.round(props.data.very_hard.batch_average * 100) / 100)}</span>
              <div className="high_pattern score_high"
                style={{height: getData2(props.data.very_hard.batch_average, props.data.very_hard)}}>
              </div>
            </div>
            <div className="answer_pattern">
              <span>{(Math.round(props.data.very_hard.you * 100) / 100)}</span>
              <div className="high_pattern score_avg"
                style={{height: getData2(props.data.very_hard.you, props.data.very_hard)}}>
              </div>
            </div>
          </div>
          <span>{props.saveLanguageWiseData.difficult}</span>
        </div>
      </div>
      <div className="scorecard_colors">
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_high">
          </div>
          <span>{props.saveLanguageWiseData.batch_average}</span>
        </div>
        <div className="scorecard_single_color">
          <div className="scorecard_square_color score_avg">
          </div>
          <span>{props.saveLanguageWiseData.you}</span>
        </div>
      </div>
    </div>
  )
}

class StudentScorecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overall: [],
      questions: [],
      avg_time: [],
      correct_pattern: [],
      max_score: [],
      correct_answers: [],
      accuracy: [],
      content: this.props.location.state.contentId,
      loading: true
    }
  }

  componentDidMount() {
    this.getScoreCard(this.props.location.state.contentId);
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
  }

  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"learner");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
      
      
    }
  }

  getScoreCard = async (contentId) => {
    const response = await this.props.getScorecard(contentId);
    this.setState({
      overall: response.summary,
      questions: response.overall.attempted,
      max_score: response.overall.score,
      correct_answers: response.overall.correct_answers,
      avg_time: response.overall.avg_time_per_question,
      accuracy: response.overall.accuracy,
      correct_pattern: response.overall.scoring_pattern,
      loading: false
    })
  }

  render() {
    if(this.props.loading || this.state.loading) {
      return (<div></div>)
    }
    else {
      return (
        <div>
          <Helmet>
            <title>{this.props.saveLanguageWiseData.scorecard}</title>
          </Helmet>
          <div className="scorecard-header">
            <a className="close" onClick={() => this.props.history.goBack()}>&times;</a>
            <div className="scorecard-header-left">
            </div>
            <div className="scorecard-header-right">
            </div>
            <div className="overall_scorehead">
              {this.props.saveLanguageWiseData.scorecard}
            </div>
            <div className="overall_scorehead_display">
              <div className="overall_scorehead_div">
                <div className="single_scorecard">
                  {this.state.overall.attempted.value}
                </div>
                <span>{this.props.saveLanguageWiseData.attempted}</span>
              </div>
              <div className="overall_scorehead_div">
                <div className="single_scorecard">
                  {(Math.round(this.state.overall.correct.value * 100) / 100)}
                </div>
                <span>{this.props.saveLanguageWiseData.correct}</span>
              </div>
              <div className="overall_scorehead_div">
                <div className="single_scorecard">
                  {(Math.round(this.state.overall.score.value * 100) / 100)}
                </div>
                <span>{this.props.saveLanguageWiseData.score}</span>
              </div>
            </div>
            <div className="overall_avg_time">
              {this.props.saveLanguageWiseData.time}: {(Math.round(this.state.overall.avg_time_taken.value * 100) / 100)}s
            </div>
          </div>
          <div className="scorecard-main">
            <div className="scorecard-overall">
              <Questions data={this.state.questions} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
              <MaxScore data={this.state.max_score} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
              <CorrectAnswers data={this.state.correct_answers} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
              <AvgTime data={this.state.avg_time} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
              <Accuracy data={this.state.accuracy} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
              <CorrectPattern data={this.state.correct_pattern} saveLanguageWiseData={this.props.saveLanguageWiseData}/>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  };
}

const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage, getScorecard,getLanguageWiseData } = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getScorecard: (content_id) => dispatch(getScorecard(content_id)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentScorecard);