import React ,{ useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import common from './common';
import axios from 'axios';
import ErrorBoundary from './ErrorHandling/ErrorBoundary';
import config from '../src/config';
//import { firebaseAnalytics } from "./firebase";


//firebaseAnalytics.logEvent("homepage_visited");

if((localStorage.getItem('is_faculty') === 'true' || localStorage.getItem('is_admin') === 'true') && localStorage.getItem('token')){
  document.addEventListener("click", function(e){
    console.log("click detected");
    clearTimeout(window.timeoutLogin);
    window.timeoutLogin = setTimeout(this.testTimeout, config.sessionTimeOutTime);
  });
}



document.addEventListener("visibilitychange", function() {
  if (document.hidden){
      console.log("Browser tab is hidden")
  } else {
    if(localStorage.getItem('token')){
      console.log("Browser tab is not hidden")
      localStorage.setItem("is_error_found",0)
      localStorage.setItem("is_error_found_login",0)
      axios.get(`${common.apiBase}/${common.authTokenValidate}`,
      {headers: {
        'Authorization': 'token ' + localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
      }).then(res => {
        console.log(res)
        if(res.status == 0 || res.data.status == 0){
          console.log(res.status )
          console.log(res.data.status )
          window.location.href='/login';
        }
      }).catch(err => {
        console.log(err)
        if(err.message == 'Request failed with status code 403'){
          console.log('error message from if condition 183')
          localStorage.setItem("is_error_found",1)
          localStorage.setItem("error_message","Your session expired! Please login again")
          localStorage.setItem("error_code",403)
          let getCenterID = localStorage.getItem("center_id");
          let getUserRole = localStorage.getItem("is_admin");
          console.log(getCenterID);
          console.log(getUserRole);
          if(getCenterID == 5 && !getUserRole){
            window.location.href= 'https://sia.mic.gov.in';
          }else{
            window.location.href='/login';
          }
         
        }
        if(err.status =='Network Error'){
          localStorage.setItem("is_error_found",1)
        }
        localStorage.setItem("is_error_found_login",1)
      })
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    
      <App />
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
