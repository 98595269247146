import localConfig from './config-local';
import verandaConfig from './config-veranda';
import skyedConfig from './config-skyed';
import vapiConfig from './config-vapi';
import vstageapiConfig from './config-vstageapi.js';
import auapiConfig from './config-auapi';
 
 
 
var client = 'skyed'
var cfg = ''    
 
 
if(client == 'local'){
    cfg = localConfig;
}else if(client == 'veranda'){
    cfg = verandaConfig;
}else if(client == 'skyed'){
    cfg = skyedConfig;
}else if(client == 'vapi'){
    cfg = vapiConfig;
}
else if(client == 'vstageapi'){
    cfg = vstageapiConfig;
}
else if(client == 'auapi'){
    cfg = auapiConfig;
}
 
export default cfg;


// // const apiDomain = "https://api.schoolsonweb.com";
// // const apiMediaDomain = 'https://api.schoolsonweb.com';
// // const wpDomain = 'https://schoolsonweb.com/wp-json/wc/v3/';
// // const logoutPage='https://schoolsonweb.com/2.php';
// // const homePage='https://schoolsonweb.com/2.php?page=home';
// // const wpUsername='admin';
// // const wpPassword='p@*(CN0lqkRNt!4GAlJx#I7O';


// const apiDomain = "https://skyedapi.schoolsonweb.com";
// const apiMediaDomain = 'https://skyedapi.schoolsonweb.com';
// const wpDomain = 'https://schoolsonweb.com/wp-json/wc/v3/';
// const logoutPage='https://schoolsonweb.com/2.php';
// const homePage='https://schoolsonweb.com/2.php?page=home';
// const wpUsername='admin';
// const wpPassword='p@*(CN0lqkRNt!4GAlJx#I7O';

// export default {
// 	apiDomain,
// 	apiMediaDomain,
// 	wpDomain,
// 	logoutPage,
// 	homePage,
// 	wpUsername,
// 	wpPassword
// }

// // const apiDomain = "http://beta.schoolsonweb.com";
// // const apiMediaDomain = 'http://beta.schoolsonweb.com';
// // const wpDomain = 'http://sow.learningoxygen.com/wp-json/wc/v3/';
// // const logoutPage='http://sow.learningoxygen.com/2.php';
// // const homePage='http://sow.learningoxygen.com/2.php?page=home';
// // const wpUsername='admin';
// // const wpPassword='A&J9%SQg^#Tf)RF#Y^JWI)OR';

// // export default {
// // 	apiDomain,
// // 	apiMediaDomain,
// // 	wpDomain,
// // 	logoutPage,
// // 	homePage,
// // 	wpUsername,
// // 	wpPassword
// // }

