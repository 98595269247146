import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import actions from '../../redux/actions';
import { Link } from 'react-router-dom';
// import Search from '../../public/icons/search.svg';
// import Notification from '../../public/icons/notification.svg';
import './styles.css';
import ForgotImage from '../../public/icons/Group 270.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import Profile from '../../public/icons/profile.svg';
import Note from '../../public/icons/notes.svg';
import Video from '../../public/icons/video.svg';
import Practise from '../../public/icons/practise.svg';
import common from '../../common';
import axios from 'axios';
import OOPS from '../../public/icons/WiredMobile.png';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdisplay: false,
      password: '',
      new_password:'',
      is_password_correct:0,
      confirm_password:'',
      searchList:[],
      uname:"",
      hide:true,
      hide1:true,
      hide2:true,
      currentAdmin:[],
      current_page:window.location.pathname.split('/').pop(),
      is_faculty:localStorage.getItem("is_faculty"),
      is_error_found:false,
      is_error_found_login:false,
      error_message:'',
      saveInstituteLanguages:localStorage.getItem('institute_languages')
    }
    this.mounted = true;
  }

  componentDidMount() {
    this.validatePage()
    console.log(window.location.pathname.split('/')[1])
    this.setState({uname:localStorage.getItem('username')});
    //this.getAdminList();
    document.addEventListener('mousedown', this.handleClickOutside);
    if(localStorage.getItem('lang')){
      let language = localStorage.getItem('lang');
      console.log("language",language);
      this.fetchLanguageWiseData(language);
    }
  }

  validatePage = () => {
    if(localStorage.getItem('token')){
      localStorage.setItem("is_error_found",0)
      localStorage.setItem("is_error_found_login",0)
      axios.get(`${common.apiBase}${common.authTokenValidate}`,
      {headers: {
        'Authorization': 'token ' + localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
      }).then(res => {
        if(res.status == 0) window.location.href='/login';
      }).catch(err => {
        // console.log('------Error', JSON.stringify(err));
        // console.log('error', err.status)
        if(err.message == 'Request failed with status code 403'){
          localStorage.setItem("is_error_found",1)
          localStorage.setItem("error_message","Your session expired! Please login again")
          localStorage.setItem("error_code",403)
          this.setState({is_error_found:true ,error_message:"Your session expired! Please login again",error_code:403,is_error_found_login:true});
        }else if(err.status =='Network Error'){
          this.setState({is_error_found:true ,error_message:"Your network connection is lost! Please try after some time",error_code:402})
        }else{
          let getCenterID = localStorage.getItem("center_id");
          let getUserRole = localStorage.getItem("is_admin");
          console.log(getCenterID);
          console.log(getUserRole);
          if(getCenterID == 5 && !getUserRole){
            localStorage.clear()
            window.location.href= 'https://sia.mic.gov.in';
          }else{
            localStorage.clear()
            window.location.href='/login';
          }
        }
        localStorage.setItem("is_error_found_login",1)
        clearTimeout(window.timeoutLogin);
      })
    }
  }

  onResourceLoad = () =>{
    if(this.state.is_error_found_login){
      localStorage.clear()
      window.location.href='/login';
    }else{
      window.location.reload()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.mounted = false;
  }

  getAdminList = async () => {
    const response = await this.props.getAdminList();
    if(response !== undefined) {
      this.setState({currentAdmin : response.data.filter(p => p.email == localStorage.getItem("username"))});
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }
  
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdisplay: false })
    }
  }

  changePassword = async (username,password,new_password) => {
    const response = await this.props.changePassword(username,password,new_password,localStorage.getItem('lang'));
    
    this.setState({open2:false});
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  hideChange1 = () => this.setState({ hide1: !this.state.hide1 })

  hideChange2 = () => this.setState({ hide2: !this.state.hide2 })


  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  handleChangeNewPassword = (e) => {
    //e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
    this.setState({ new_password: e.target.value ,confirm_password:'',is_password_correct:0})
  }

  handleChangeConfirmPassword = (e) => {
    this.setState({confirm_password:e.target.value});

    e.target.value===this.state.new_password?this.setState({is_password_correct:1}):this.setState({is_password_correct:0});
  }

  show4 = () => {
    this.setState({open2: true,password:'',new_password:'',confirm_password:'',is_password_correct:0});
  }

  close2 = () => this.setState({ open2: false });

  dropchange = () => {
    this.getAdminList();

		this.setState({
			dropdisplay: !this.state.dropdisplay
		});
  }

  show6 = async (e) => {
    //if(!e.target.value) document.getElementsByClassName("tab-content")[0].style.display="none";
    this.setState({text:e.target.value})

    if(this.state.current_page == "study-material"){
      let response = await this.props.adminSearchList(e.target.value,'StudyMaterial');
      this.setState({searchList :response?.data});

      if(response?.data && response?.data != undefined){
        var element = document.getElementById("myDIV");
        element.classList.add("open");
        document.getElementsByClassName("tab-content")[0].style.display="block";
      }else{
        var element = document.getElementById("myDIV");
        element.classList.remove("open");
        document.getElementsByClassName("tab-content")[0].style.display="none";
      }
    }else{
      const response = await this.props.questionBankSearch(e.target.value);

    }
    
  }

  getColor = (content) => {
    if(content != undefined){
      if(content === 4 || content === 5) {
        return {}
      }
      else {
        return {backgroundColor: '#e2f2ff'};
      }
    }
  }


  fetchLanguageWiseData = async (language) =>{
    const response = await this.props.getLanguageWiseData(language,"menu");
    if(response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData',JSON.stringify(response?.data))
    }
  }

  languageUpdateInUserProfile = async (language) =>{
    const response = await this.props.updateProfile('','','','','',language);
    if(response !== undefined && response.status == 1) {
      console.log("languageUpdateInUserProfile");
      console.log(response?.data);
      window.location.reload();
    }
  }
  


  languageChange = (option) => {
    localStorage.setItem('lang',option.target.value);
    this.languageUpdateInUserProfile(option.target.value);
    
  }

	render() {

    const { is_faculty,current_page } =this.state;

    const dropstyle = this.state.dropdisplay ? {display: 'block'} : {display: 'none'};
    const isEnabled = !this.state.password || !this.state.new_password || !this.state.confirm_password || !this.state.is_password_correct;
		return (
			<div className="admin-header">
				<div className="search-head">
					{/*<img src={Search} alt="search"/>
					<input placeholder={this.props.placeholder}/>*/}
          {current_page == "user-profile" ?<span className="question-head">{this.props.saveLanguageWiseData.user_profile}</span> :""}
          {current_page == "question-bank" ?<span className="question-head">{this.props.saveLanguageWiseData.questions}</span> :""}
          {current_page == "students" ?<span className="question-head">{this.props.saveLanguageWiseData.students}</span> :""}
          {current_page == "student-sem-promotion" ?<span className="question-head">{this.props.saveLanguageWiseData.student_promotion_panel}</span> :""}
          {current_page == "banner" ?<span className="question-head">{this.props.saveLanguageWiseData.banner}</span> :""}
          {current_page == "form" ?<span className="question-head">{this.props.saveLanguageWiseData.form}</span> :""}
          {current_page == "study-material" ? <div className="search-wrapper-admin">
        <div className="search-container">
          <input type="search" placeholder={current_page == 'study-material'? this.props.saveLanguageWiseData.search_study_material:this.props.saveLanguageWiseData.search_questions} onChange = {this.show6} onFocus={this.handleUsernameFocus}/>
          <div className="search-overlay-wrapper" id="myDIV">
            <div className="search-overlay-container">
              {/*<div className="tabs" id="tabs">
                <a href="javascript:void(0);" id="tab1">All</a>
                <a href="javascript:void(0);" id="tab2">Notes</a>
                <a href="javascript:void(0);" id="tab3">Video</a>
                <a href="javascript:void(0);" id="tab4">Pratice</a>
              </div>*/}
              
              <div class="tab-content" id="tab1C">
                <ul>
                {this.state?.searchList?.length ? this.state?.searchList?.map((data,index) =>
                  <li>
                    <a href=""><i><img src={data._source.type == "Notes" ? Note:data._source.type == "Video"?Video:data._source.type == "Assessment"?Practise:""}/></i><Link className="" style={this.getColor(4)}
                        to={{ pathname: `/admin/content-view/${data._id}`,
                        state: {table_id: data._source.ref_id,subject_id:data._source.subject_id,class_id:data._source.class_id} }} >
                        <div className="">
                          <span>{data._source.title}</span>
                        </div>
                      </Link></a>
                  </li> 
                ) : ""
              }
                </ul>
              </div>
            </div>  
          </div>
        </div>  
      </div>:""}
				</div>
        
				<div className="profile">
        {localStorage.getItem("is_admin") &&
          // <div className="languageTranslator admin">
          //   <div className='block'>
          //   <label>{this.props.saveLanguageWiseData.choose_language}</label>
          //     <select onChange={this.languageChange} value={localStorage.getItem('lang')}>
          //       <option value="en">English</option>
          //       <option value="ar">عربي</option>
          //       <option value="gu">ગુજરાતી</option>
          //       <option value="hi">हिंदी</option>
          //     </select>
          //   </div>
          // </div>
          this.state.saveInstituteLanguages && this.state.saveInstituteLanguages !== 'undefined' && JSON.parse(this.state.saveInstituteLanguages).length > 1 &&
            <div className="languageTranslator admin">
              <div className='block'>
              <label>{this.state.saveLanguageWiseData.choose_language}</label>
              <select onChange={this.languageChange} value={localStorage.getItem('lang')}>
                {this.state.saveInstituteLanguages.length && JSON.parse(this.state.saveInstituteLanguages).map((languageOption) => ( 
                  <option value={languageOption.code} key={languageOption.code}>{languageOption.label}</option>
                ))}
                </select>
              </div>
          </div>
            }    
          <div className="profile-option">
            {/*<img src={Notification} alt="img"/>*/}
          </div>
					<div className="profile-option">
						{/*<img src={Notification} alt="img"/>*/}
					</div>
          {!is_faculty && <div id="dropper" ref={this.setWrapperRef}>
            <div className="profile-option" onClick={this.dropchange}>
              <img src={Profile} alt="img"/>
              Admin
            </div>
            

            <div className="dropdown-content2" style={dropstyle}>
              <div className="drop-profile">
                {this.state?.currentAdmin[0]?.profile_picture === '' 
                  || this.state?.currentAdmin[0]?.profile_picture === null
                ? <img src={Profile} alt="img"/>
                : <div className="dpp-prof-img adminProfile"
                  style={{backgroundImage: `url(${common.addFileUrl(this.state?.currentAdmin[0]?.profile_picture)})`}}></div>}
                <div className="drop-profile-creds">
                  <span>{this.state?.profile?.name}</span>
                  <div>{this.state?.currentAdmin[0]?.name}</div>
                </div>
              </div>
              {(this.state?.currentAdmin[0]?.mobile.length !== 0
                && this.state?.currentAdmin[0]?.mobile !== null) && <div className="prof-options-div">
                <span>{this.props.saveLanguageWiseData.mobile_number}</span>
                {this.state?.currentAdmin[0]?.mobile}
              </div>}
              {(this.state?.currentAdmin[0]?.email.length !== 0
                && this.state?.currentAdmin[0]?.email !== null) && <div className="prof-options-div">
                <span>{this.props.saveLanguageWiseData.email_address}</span>
                {this.state?.currentAdmin[0]?.email}
              </div>}

              {(this.state?.currentAdmin[0]?.assigned_branch?.name.length !== 0
                && this.state?.currentAdmin[0]?.assigned_branch?.name !== null) && <div className="prof-options-div">
                <span>{this.props.saveLanguageWiseData.center}</span>
                {this.state?.currentAdmin[0]?.assigned_branch?.name}
              </div>}
              <div className="click-prof" onClick={this.show4}>
                {this.props.saveLanguageWiseData.change_password}
              </div><br/>
              <div className="click-prof" onClick={this.props.userLogout}>
                {this.props.saveLanguageWiseData.logout}
              </div>
            </div>
          </div>}
				</div>


        <Popup className="iserrorfound" open={this.state.is_error_found} closeOnDocumentClick={false}>
          <div><img src={OOPS} alt="oops" width="200px" height="124px"/></div>
          {/* <div className="ohno">Oops!</div> */}
          <div className="connection_failed">{this.state.error_message}</div>
          <div style={{marginTop:"20px"}}>
            <button onClick={this.onResourceLoad}>Try Again Now!</button>
          </div>
        </Popup>

        <Popup open={this.state.open2} closeOnDocumentClick onClose={this.close2}
          className="change-password" ref={this.myRef}>
           <div Style="display: flex;width: 100%;" className="changePasswordWrapper">
            <div Style="width: 60%"  className="leftImage"> 
              <img src={ForgotImage} alt="change-password" Style="width: 106%;height: 100%;"/>
            </div>
            <div Style="padding-top: 81px;"  className="RightCnt">
              {<div className="fw_input fw_input2">
                
                <div className="input_field">
                   <input type={this.state.hide ? "password" : "text"}  placeholder={this.props.saveLanguageWiseData.current_password} name='password'
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword}/>
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                <div className="input_field">
                   <input type={this.state.hide1 ? "password" : "text"} placeholder={this.props.saveLanguageWiseData.new_password} name='new_password'
                    value={this.state.new_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeNewPassword}/>
                    <div className="togglePassword" onClick={this.hideChange1}>
                    {this.state.hide1 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>

                <div className="input_field" Style={this.state.is_password_correct?"border-bottom: 1px solid green":"border-bottom: 1px solid var(--primary)"}>
                   <input type={this.state.hide2 ? "password" : "text"}  placeholder={this.props.saveLanguageWiseData.confirm_password} name='confirm_password'
                    value={this.state.confirm_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeConfirmPassword}/>
                    <div className="togglePassword" onClick={this.hideChange2}>
                    {this.state.hide2 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                
                <div className="button-class">
                  <button onClick={() => this.changePassword(this.state.uname,this.state.password,this.state.new_password)} disabled={isEnabled ? "disabled":""}>
                    {this.props.saveLanguageWiseData.change_password}
                  </button>
                </div>
              </div>}
            </div>
           </div>
        </Popup>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { userLogout,changePassword,getAdminList,adminSearchList,questionBankSearch,getLanguageWiseData,updateProfile} = actions;
  return {
    adminSearchList: (text,context) => dispatch(adminSearchList(text,context)),
    getAdminList: () => dispatch(getAdminList()),
    questionBankSearch : (key) => dispatch(questionBankSearch(key)),
    userLogout: () => dispatch(userLogout()),
    changePassword: (username,otp,password,language) => dispatch(changePassword(username,otp,password,language)),
    getLanguageWiseData: (language,page) => dispatch(getLanguageWiseData(language,page)),
    updateProfile: (name,email,mobile,photo,countrycode,locale) => dispatch(updateProfile(name,email,mobile,photo,countrycode,locale))
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);