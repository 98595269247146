import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Listing from '../../public/icons/listing.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import common from '../../common';
import './styles.css';

class LearnerFreeCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
			open: false,
      open2: false,
      open3: false,
      opend: false,
      class_id: null,
      filterClass: null,
      name: '',
      code: '',
      order: '',
      level:'',
      description: '',
      branch: parseInt(localStorage.getItem('center_id')),
      branches: [],
      center_id: localStorage.getItem('center_id'),
			list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      classesFilter: [],
      is_faculty_admin:false,
      open_course_list:[],
      open_course_menu_list:[]
    }
  }
  
  componentDidMount() {
    let level ='';
    this.getFreeCourses(level);
    this.getOpenCourseLevels();
  }

  getOpenCourseLevels = async () =>{
    const response = await this.props.openCourseLevels();

    if(response != undefined){
      this.setState({open_course_menu_list:response.data})
    }
  }

  getFreeCourses = async (level) => {
    const response = await this.props.getLearnerProfile()

    if(response != undefined){
      this.setState({level:response.data.classs?.level})
      const openCourseResponse = await this.props.openCourseSubjects(!level ? response.data.classs?.level : level)

      if(openCourseResponse != undefined){
        this.setState({open_course_list:openCourseResponse.data})
      }
    }
  }

  onCourseChange = (level) =>{
    let el = document.getElementById("selected-dsubject");
    if(el) el.setAttribute("id","")

    var element = document.getElementsByClassName(level+"_level")[0];
    if(element) element.setAttribute('id', 'selected-dsubject');
    this.getFreeCourses(level)
  }


	render() {
    const isEnabled = this.state.name.length > 0 && this.state.code.length > 0 &&
            this.state.description.length > 0 && (this.state.order + '').length > 0
            && this.state.branch !== null;
		return (
    <div className="app-body" id="appBody" >
			<div className="app-div">
        <Helmet>
          <title>Free Courses</title>
        </Helmet>
        <img id="path-b" src={PathB} alt="pathb"/>
        <img id="path-d" src={PathD} alt="pathd"/>
        <ReactNotifications/>
				
        <div className="">
					<div className="sub-head">
						<div className="heading-part1">
							<div className="heading">
								<h1>Subjects</h1>
							</div>
						</div>
					</div>
          <div className="page-doubt-subjects">
              {this.state.open_course_menu_list.map(course =>
                <div key={course.id} className={course.level+"_level"} onClick={() => this.onCourseChange(course.level)}
                  id={this.state.level === course.level ? 'selected-dsubject' : 'selected-subject'}>
                  {course.label}
                </div>
              )}
          </div>

          {this.state.open_course_list.length === 0 ? <><span id="no-data-admin">
              </span> <Empty/></> : <>
             <div className="list-sticky list-sticky-free-course">
              {this.state.open_course_list?.map((classs, index) =>
                <div className="grid-item" key={classs.id} Style={"background-color:"+classs.background_code}>
                  <div className="grid-up">
                    <div className="title-grid">
                      <Link className="" key={classs.id} 
                        to={{ pathname: `/learner/study-material/${classs.id}`,
                        state: {subject_label: classs.label}}}
                        style={{backgroundColor: classs.background_code}}>
                        <span>{classs.label}</span>
                      </Link>
                    </div>
                    <div className="grid-actions">
                      <img src={common.addFileUrl(classs.image_url)} />
                    </div>
                  </div>
                  <div className="grid-up">
                    <div className="grid-sec">
                      Code
                      <span>{classs.short_code}</span>
                    </div>
                    <div className="grid-sec2">
                      
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>}
				</div>
			</div>
    </div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    learnerProfile: state.classroomReducer.learnerprofile

  }
}

const mapDispatchToProps = (dispatch) => {
  const {openCourseSubjects,getLearnerProfile,openCourseLevels} = actions;
  return {
    openCourseSubjects: (level) => dispatch(openCourseSubjects(level)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    openCourseLevels: () =>dispatch(openCourseLevels())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerFreeCourse);