import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';

import actions from '../../redux/actions';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Loader from '../../public/icons/loader.png';
import Header from '../../components/navigation/Header';
import Trash from '../../public/icons/trash.svg';
import Download from '../../public/icons/down-arrow.svg';

import 'react-notifications-component/dist/theme.css';
import './styles.css';


class curriculums extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      label: '',
      branches: [],
      programFilter: [],
      sessionsList: [],
      centerID: parseInt(localStorage.getItem('center_id')),
      is_faculty_admin:false,
      dialogOpen:false,
      editdialogOpen:false,
      titleSave:'',
      descSave:'',
      uploadFilePath:'',
      uploadFileURL:'',
      sessionID:'',
      programID:'',
      curriculumList:[],
      editID:'',
      deleteID:'',
      edittitleSave:'',
      editdescSave:'',
      edituploadfileSave:'',
      loader:false,
      addloader:false,
      editloader:false,
      openDeleteCurriculumDialog:false
      
    }
  }
  componentDidMount() {
    this.sessionList(this.state.centerID);
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    this.getUrlfromCurrentPage();     
    
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }


  sessionList = async (center_id) => {
    const response = await this.props.sessionList(center_id);
    if(response !== undefined) {
      console.log(response.data)
      this.setState({ sessionsList: response.data});
    }
  }

  

  onSessionsChange = (e) => {
    const {sessionsList } = this.state;
    this.setState({sessionID: e.target.value});
    console.log(e.target.value);
    if(e.target.value){
      let filtered  = sessionsList.filter(data => data.id == e.target.value);
      if(filtered){
        let filtersProgram = filtered[0]?.programs_mapped;
        this.setState({ programFilter: filtersProgram});
      }
    }

    if(e.target.value === ''){
      this.setState({curriculumList: ""});
      this.setState({ programFilter: ""});
    }
    
    
   
   }

   onProgramChange = async (e) => {
    const {sessionsList,programID,sessionID } = this.state;
    console.log("programID");
    console.log(e.target.value);
    if(e.target.value !== ''){
     this.setState({programID: e.target.value});
     let response = await this.props.curriculumList(e.target.value,sessionID);
     if(response !== undefined) {
       this.setState({loader: true});
        console.log(response?.data);
        this.setState({curriculumList: response.data});
        this.setState({loader: false});
       
      }
    }else{
      this.setState({curriculumList: ""});
      this.setState({programID: ""});
    }
    
    
   }


  onClickCancel = () =>{
    this.setState({ dialogOpen: false});
    this.setState({ editdialogOpen: false});
  }

  openCreateDialog = () => {
    const {sessionID,programID,titleSave,descSave,uploadFilePath,uploadFileURL } = this.state;
    this.setState({dialogOpen: true});
    this.setState({titleSave: ''});
    this.setState({descSave: ''});
    this.setState({uploadFilePath: ''});
    this.setState({uploadFileURL: ''});
    
    }

  editCreateDialog = (id) => {
    const {sessionID,programID,titleSave,descSave,uploadFilePath,uploadFileURL,editID,curriculumList } = this.state;
    this.setState({editID: id});
    this.setState({editdialogOpen: true});
    if(id){
      this.setState({editloader: true});
      let filtered  = curriculumList.filter(data => data.id == id);
      console.log("filtered Edit")
      console.log(filtered);
      setTimeout(() => {
        this.setState({edittitleSave: filtered[0].title});
        this.setState({editdescSave: filtered[0].description});
        this.setState({edituploadfileSave: filtered[0].file.substring(filtered[0].file.lastIndexOf("/") + 1, filtered[0].file.length)});
      }, 100);
      this.setState({editloader: false});
    }
   
  }

  onChangeTitle = (e) => {
    console.log(e.target.value);
    this.setState({titleSave: e.target.value});
  }
  onChangeDescription = (e) => {
    console.log(e.target.value);
    this.setState({descSave: e.target.value});
  }

  uploadHandleChange =(e) => {
    console.log(e.target.files[0].name);
    console.log(e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".")));
    
    if(e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".")) == ".pdf" || e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".")) == ".csv" || e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".")) == ".xlsx" || e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".")) == ".docx" || e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".")) == ".doc" || e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf(".")) == ".xls"){
      this.setState({uploadFilePath: e.target.files[0]})
      this.setState({uploadFilePathURL: e.target.files[0]});
      console.log("Right ext Uploaded");
    }else{
      alert("Please select correct. Allowed file types are  pdf,csv,xlsx,xls,doc,docx");
    }
  }

  saveCurriculum  =  async() => {
    this.setState({addloader: true});
    const {sessionID,programID,titleSave,descSave,uploadFilePath,uploadFileURL } = this.state;
    const response = await this.props.curriculumCreate(programID,sessionID,uploadFilePath,titleSave,descSave);
    if(response !== undefined) {
      this.setState({dialogOpen: false});
      console.log(response.data);
      let response1 = await this.props.curriculumList(programID,sessionID);
      if(response1 !== undefined) {
         this.setState({addloader:true});
          console.log(response1?.data);
          this.setState({curriculumList: response1?.data});
          this.setState({addloader:false});
       }
    }
  }


  editSaveCurriculum  =  async() => {
    this.setState({editloader: true});
    const {editID,sessionID,programID,titleSave,descSave,uploadFilePath,uploadFileURL } = this.state;
    const response = await this.props.curriculumUpdate(editID,uploadFilePath,titleSave,descSave);
    if(response !== undefined) {
      this.setState({editdialogOpen: false});
      console.log(response.data);
      let response1 = await this.props.curriculumList(programID,sessionID);
      if(response1 !== undefined) {
        this.setState({edittitleSave: ''});
        this.setState({editdescSave: ''});
        this.setState({edituploadfileSave: ''});
        this.setState({editloader: true});
        this.setState({curriculumList: response1?.data});
        console.log(response1?.data);
        this.setState({editloader: false});
       }
    }
  }

  deleteCurriculum = (id) => {
    const {deleteID} = this.state;
    this.setState({deleteID: id});
    this.setState({openDeleteCurriculumDialog: true});
  }

  cancelCurriculumActions = () => {
    this.setState({openDeleteCurriculumDialog: false});
  }

  deleteCurriculumActions = async() => {
    const {deleteID,programID,sessionID} = this.state;
    console.log(deleteID);
    const response = await this.props.curriculumDelete(deleteID);
    if(response !== undefined) {
      console.log(response);
      this.setState({openDeleteCurriculumDialog: false});
      let response1 = await this.props.curriculumList(programID,sessionID);
      if(response1 !== undefined) {
        this.setState({curriculumList: response1?.data});
        console.log(response1?.data);
       }
    }
  }
  
  render() {

    const {selected_program_center_id} = this.state

    const isEnabled = this.state.sessionID != '' && this.state.programID != '';
    console.log(isEnabled);
    const isEnabled1 = this.state.titleSave !== '' && this.state.descSave !== '' && this.state.uploadFilePath !== '';
    return (
      <div className="admin-div">
        <Helmet>
          <title>{this.props.pagewiseLanguageData.classes}</title>
        </Helmet>
        <ReactNotifications/>
        <Header placeholder={this.props.pagewiseLanguageData.search_classes}/>

        <Popup open={this.state.openDeleteCurriculumDialog} closeOnDocumentClick onClose={this.cancelCurriculumActions}
          className="popup-content3">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {this.props.pagewiseLanguageData.delete_message}
          </div>
          <div className="delete-down">
            <button onClick={this.cancelCurriculumActions}>
              {this.props.pagewiseLanguageData.cancel}
            </button>
            <button onClick={this.deleteCurriculumActions}>
            {this.props.pagewiseLanguageData.delete}
            </button>
          </div>
        </Popup>


        {!this.state.addloader ?
        <div  className={this.state.dialogOpen ? 'addCurriculumsDialog open' : 'addCurriculumsDialog'}>
          <div className='addCurriculumsContainer'>
            <h1>{this.props.pagewiseLanguageData.add_curriculum}</h1> 
              <div class="formWrapper">
                <div className='formGroup'>
                  <label>{this.props.pagewiseLanguageData.title}</label>
                  <input type="text"  onChange={this.onChangeTitle} placeholder={this.props.pagewiseLanguageData.enter_curriculum_title}/>
                </div>
                <div className='formGroup'>
                  <label>{this.props.pagewiseLanguageData.description}</label>
                  <textarea  onChange={this.onChangeDescription} placeholder={this.props.pagewiseLanguageData.enter_curriculum_description}></textarea>
                </div>
                <div className='formGroup'>
                  <label>{this.props.pagewiseLanguageData.upload_file}</label>
                  <input type="file" accept=".pdf,.csv,.xlsx,.xls,.doc,.docx" onChange={this.uploadHandleChange} placeholder={this.props.pagewiseLanguageData.upload_file}/>
                  <span class="note">{this.props.pagewiseLanguageData.allowed_file_types}</span>
                </div>
              </div>
              <div className='formActions'>
                  <button onClick={this.onClickCancel}>{this.props.pagewiseLanguageData.cancel}</button>
                  <button disabled={!isEnabled1}  onClick={this.saveCurriculum}>{this.props.pagewiseLanguageData.save}</button>
                </div>
          </div>
        </div>
        : <div class="curriculumsLoader"><img src={Loader}/></div>}

        {!this.state.editloader ?
        <div  className={this.state.editdialogOpen ? 'editCurriculumsDialog open' : 'editCurriculumsDialog'}>
          <div className='editCurriculumsContainer'>
            <h1>{this.props.pagewiseLanguageData.edit_curriculum}</h1> 
              <div class="formWrapper">
                <div className='formGroup'>
                  <label>{this.props.pagewiseLanguageData.title}</label>
                  <input type="text" defaultValue={this.state.edittitleSave ? this.state.edittitleSave : ''}  onChange={this.onChangeTitle} placeholder={this.props.pagewiseLanguageData.enter_curriculum_title}/>
                </div>
                <div className='formGroup'>
                  <label>{this.props.pagewiseLanguageData.description}</label>
                  <textarea defaultValue={this.state.editdescSave ? this.state.editdescSave :''} onChange={this.onChangeDescription} placeholder={this.props.pagewiseLanguageData.enter_curriculum_description}></textarea>
                </div>
                <div className='formGroup'>
                  <label>{this.props.pagewiseLanguageData.upload_file}</label>
                  <input type="file"  defaultValue={this.state.edituploadfileSave ? this.state.edituploadfileSave : ''} accept=".pdf,.csv,.xlsx,.xls,.doc,.docx" onChange={this.uploadHandleChange} placeholder={this.props.pagewiseLanguageData.upload_file}/>
                  <span class="note">{this.props.pagewiseLanguageData.allowed_file_types}</span>
                  {this.state.edituploadfileSave && <div>{this.state.edituploadfileSave}</div> }
                </div>
              </div>
              <div className='formActions'>
                  <button onClick={this.onClickCancel}>{this.props.pagewiseLanguageData.cancel}</button>
                  <button onClick={this.editSaveCurriculum}>{this.props.pagewiseLanguageData.update}</button>
                </div>
          </div>
        </div>
        : <div class="curriculumsLoader"><img src={Loader}/></div>}

        
        <div className="main-part">
         <div className='curriculumsFilterBlock'>
            <h1>{this.props.pagewiseLanguageData.curriculums}</h1>
            <div>
              <select onChange={this.onSessionsChange}>
              <option value = "">{this.props.pagewiseLanguageData.select_session}</option>
              {this.state.sessionsList && this.state.sessionsList.map(sessions =>
                <option key={sessions.id} value={sessions.id}>{sessions.label}</option>
              )}
             </select>
             <select onChange={this.onProgramChange}>
              <option value="">{this.props.pagewiseLanguageData.select_program}</option>
              {this.state.programFilter && this.state.programFilter.map(programs =>
                <option key={programs.id} value={programs.id}>{programs.label}</option>
              )}
             </select>
             <button disabled={!isEnabled} onClick={this.openCreateDialog}><img src="/static/media/add.39d14924.svg" alt={this.props.pagewiseLanguageData.add_new}/>{this.props.pagewiseLanguageData.add_new}</button>
            </div>
         </div>
         {!this.state.loader ?         
         <div className="curriculumsWrapper">
            <ol>
              {this.state.curriculumList && this.state.curriculumList.length > 0 && this.state.curriculumList.map( data =>
                <li key={data.id}>
                  <h3>{data.title}</h3>
                  <p>{data.description}</p>
                  <div class="actions">
                    <span title={this.props.pagewiseLanguageData.edit} onClick={() => this.editCreateDialog(data.id)}><img src={Edit} alt={this.props.pagewiseLanguageData.edit}/></span>  
                    <span title={this.props.pagewiseLanguageData.delete} onClick={() => this.deleteCurriculum(data.id)}><img src={Delete} alt={this.props.pagewiseLanguageData.delete}/></span>  
                    <a href={data.file} target={data.file.substring(data.file.lastIndexOf(".")) == ".pdf" ? "_blank" : ''} download><img src={Download} alt={this.props.pagewiseLanguageData.download}/></a>  
                  </div>
                </li>
                )}

                {this.state.curriculumList.length == 0 &&
                  <div className='nodatafound'>{this.props.pagewiseLanguageData.no_curriculum_found} <span>{this.props.pagewiseLanguageData.please_select_session_and_program_first}</span></div> 
                }
              
            </ol>
          </div>
        : <div class="curriculumsLoader"><img src={Loader}/></div>}
          
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage: state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const {programList,setCurrentPage,curriculumCreate,sessionList,curriculumList,curriculumUpdate,curriculumDelete,getPageLanguageWiseData} = actions;
  return {
    programList: (center_id) => dispatch(programList(center_id)),
    sessionList: (center_id) => dispatch(sessionList(center_id)),
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    curriculumCreate: (program_id,session_id,file,titile,description) => dispatch(curriculumCreate(program_id,session_id,file,titile,description)),
    curriculumList: (program_id,session_id) => dispatch(curriculumList(program_id,session_id)),
    curriculumUpdate: (id,file,titile,description) => dispatch(curriculumUpdate(id,file,titile,description)),
    curriculumDelete:(curriculum_id)=> dispatch(curriculumDelete(curriculum_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(curriculums);
