import React, { Component } from 'react';
import { connect } from 'react-redux';
// import MathJax from 'react-mathjax';
import './styles.css';

class SolvedExample extends Component{
  constructor(props){
    super(props);
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    // this.renderMath()
  }

  componentDidUpdate() {
    // this.renderMath()
  }

  // renderMath() {
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node2.current
  //   ])
  //   window.MathJax.Hub.Queue([
  //     "Typeset",
  //     window.MathJax.Hub,
  //     this.node3.current
  //   ])
  // }

  render() {
    const { contentSolvedExample } = this.props;
    return(
      <div>
        <div className="solved_problems">Solved examples</div>
        {contentSolvedExample.sections.map((section, index) =>
          <div key={`section_${index}`}>
            <div className="solved_section">{section.title}</div>
            {section.questions.map((question, innerIndex) => 
              <div className="solved_question" key={`question${innerIndex}`}>
                <div className="question_main">
                  <span id="question_number">{question.uid}</span>
                  <div className="solved_question_title" ref={this.node}
                    dangerouslySetInnerHTML={{__html: question.statement}} />
                </div>
                <div className="solved_options">
                  {question.options.map((option, optionIndex) => 
                    <span ref={this.node2} dangerouslySetInnerHTML={{__html: option.statement}} />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    contentSolvedExample: state.classroomReducer.contentSolvedExample
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SolvedExample);