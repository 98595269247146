import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import ReactNotifications from 'react-notifications-component';

import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

const AssessmentInfo = (props) => {
  return (
    <div className="asm-info">
      <span id="asm-titles2">Base Details</span>
      <div className="asm-title">
        <input value={props.title} placeholder="Assessment Title" type="text"
          onChange={props.onTitleChange}/>
        <input value={props.code} placeholder="Test Code" type="text"
          onChange={props.onCodeChange}/>
      </div>
      <div className="base-settings">
        <div>
          <div className="asm-setting-div-popup">
            <div className="asm-setting-option">
              <span>Is Timed</span>
              <>
                <input
                  checked={props.isTimed}
                  onChange={props.toggleTimedClose}
                  className="react-switch-checkbox"
                  id={`react-switch-istimed`}
                  type="checkbox"
                />
                <label
                  style={{ background: props.isTimed && '#489198' }}
                  className="react-switch-label"
                  htmlFor={`react-switch-istimed`}
                >
                  <span className={`react-switch-button`} />
                </label>
              </>
            </div>
            {props.isTimed && <div className="asm-setting-horizon">
              <div>
                <input name="time" type="radio" value={1} onClick={() => props.onTypeChange(1)}
                  checked={props.timed_type === 1}/>
                <label htmlFor={1}>Timed on Total</label>
                {props.timed_type === 1 ? <div className="asm-setting-duration">
                  <div><input value={props.duration} onInput={props.changeDuration}
                    type="text" pattern="[0-9]*"/> {props.pagewiseLanguageData.mins}</div>
                </div> : null}
              </div> 
              <div>
                <input name="time" type="radio"  value={2} onClick={() => props.onTypeChange(2)}
                  checked={props.timed_type === 2}/>
                <label htmlFor={2}>Timed on Individual Question</label>
              </div> 
            </div>}
          </div>
        </div>
        <div>
          <span id="asm-titles">Sections</span>
          <div className="asm-setting-duration">
            <span onClick={props.minusSection}>&#45;</span>
            <div>{props.sections}</div>
            <span onClick={props.addSection}>&#43;</span>
          </div>
        </div>
      </div>
    </div>
  )
}

class NewAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next_step: false,
      assessment_id: null,
      data: null,
      loading: false,
      center_id: localStorage.getItem('center_id'),
      title: '',
      code: '',
      sections: 0,
      timed_type: 0,
      isTimed:false,
      duration: 5,
      is_published: this.props.location?.state?.type === 2 ? true : false,
      url: this.props.location?.state?.url === undefined ? null : this.props.location.state.url,
      toc_url: this.props.location?.state?.toc_url,
      breads: this.props.location?.state?.breads,
      type: this.props.location?.state?.type === undefined ? 1 : this.props.location.state.type,
      toc_id: this.props.location?.state?.toc_id === undefined 
                ? null : this.props.location?.state?.toc_id,
      class_id: this.props.location?.state?.class_id === undefined 
                  ? null : this.props.location?.state?.class_id,
      subject_id: this.props.location?.state?.subject_id === undefined 
                    ? null : this.props.location?.state?.subject_id
    }
  }

  componentDidMount() {
    this.getUrlfromCurrentPage();
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
      
      
    }
  }

  onTitleChange = (e) => {
    this.setState({ title: e.target.value })
  }

  onCodeChange = (e) => {
    this.setState({ code: e.target.value })
  }

  onTypeChange = (val) => {
    this.setState({ timed_type: val })
  }

  changeDuration = (e) => {
    const duration = (e.target.validity.valid) ? e.target.value : this.state.duration;
    this.setState({ duration });
  }

  minusSection = () => {
    if(this.state.sections !== 0) {
      var sections = this.state.sections - 1;
      this.setState({ sections: sections })
    }
  }

  toggleTimedClose = () => {
    this.setState({ isTimed:!this.state.isTimed})
    if(!this.state.isTimed === false){
      this.setState({duration:0,timed_type:0})
    }
    if(this.state.isTimed){
      this.setState({duration:5})
    }
  }

  addSection = () => {
    var sections = this.state.sections + 1;
    this.setState({ sections: sections })
  }

  newAssessment = async () => {
    const { type, code, sections, title, center_id, timed_type, duration, toc_id, class_id, subject_id, is_published } = this.state;
    const response = await this.props.createAssessment(type, code, sections, title, 
                      center_id, timed_type, duration.length === 0 ? 0 : timed_type ==0 ?0:duration, toc_id, subject_id, class_id, is_published, '', '');
    if(response !== undefined) {
      this.setState({ next_step: true, assessment_id: response.data.id });
    }
  }



	render() {
    const { assessment_id, next_step, title, type, code, sections, timed_type, 
              duration,isTimed } = this.state;
    const isEnabled = this.state.title.length > 0 && this.state.code.length > 0 &&
                        this.state.sections > 0
    if(next_step && assessment_id !== null) {
      return (
        <Redirect to={{ pathname: `/admin/assessment-settings/${assessment_id}`,
                        state: { toc_id: this.state.toc_id, class_id: this.state.class_id, 
                          subject_id: this.state.subject_id, data: this.props.location.state,
                          url: this.state.url ,assessment_id} }}/>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>New assessment</title>
        </Helmet>
        <ReactNotifications/>
        {this.state.loading ? <div className="admin-div-loader">
          <div className="admin-loader"></div>
        </div> : null}
        <div className="asm-header">
          <button id="button-reverse" onClick={() => this.props.history.goBack()}>
            Back
          </button>
          <div>Step 1 : New Assessment</div>
          <button onClick={this.newAssessment} disabled={!isEnabled}>
            Next
          </button>
        </div>
        <div className="asm-container">
          <div className="asm-ques">
            <AssessmentInfo title={title} type={type} code={code} sections={sections}
              timed_type={timed_type} duration={duration} onTitleChange={this.onTitleChange} 
              onTypeChange={this.onTypeChange} onCodeChange={this.onCodeChange} 
              onSectionChange={this.onSectionChange} changeDuration={this.changeDuration} 
              minusSection={this.minusSection} toggleTimedClose ={this.toggleTimedClose} addSection={this.addSection} isTimed ={isTimed} pagewiseLanguageData = {this.props.pagewiseLanguageData} />
          </div>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
	const { createAssessment,getPageLanguageWiseData } = actions;
  return {
    createAssessment: (type, uid, num_of_sections, title, center, timed_type, timed_duration_mins, toc_id, subject_id, class_id, is_published, instruction, description) => dispatch(createAssessment(type, uid, num_of_sections, title, center, timed_type, timed_duration_mins, toc_id, subject_id, class_id, is_published, instruction, description)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAssessment);