import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import common from '../../common';
import actions from '../../redux/actions';
import 'react-notifications-component/dist/theme.css';
import './mycoach.css';
import HeaderMyCoach from './HeaderMyCoach';
import 'react-circular-progressbar/dist/styles.css';
import BackArrow from '../../public/icons/mycoach-backarrow.svg';
import thanksDialogCheckmark from '../../public/icons/thanksdialog-checkmark.png';
import axios from 'axios';

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validMobileRegex = /^[0-9\b]+$/;

class MyGoal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      no_data: false,
      isEmpty:true,
      token:null,
      questiondata:[],
      profile: {},
      isvisible:false,
      answers:[],
      values:[],
      errors:{},
      fields:{},
      isEnabled:false, 
      is_webview: this.props.location.search.split('&')[0]?.split('=')[1],
      token: this.props.location.search.split('&')[1]?.split('=')[1],
      widget_id:this.props.match.params.id,
      widget_form_type:'',
      widget_form_data:[],
      isFormVisible:"block",
      totalRequired:0,
      QuestionID:{},
      stateLanguageData:[],
      mobileViewLanguageData:[]
   }
  }
  
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const getMobileTokenFromURL = urlParams.get('token')
    
    localStorage.setItem("page","mygoal");
    if(this.state.is_webview){
      localStorage.setItem('token', this.state.token);
      this.getGoalQuestionListCall()
      console.log(getMobileTokenFromURL);
      this.allProfileApi(getMobileTokenFromURL);
    }else{
      this.getGoalQuestionListCall()
    }
  }

  allProfileApi = async (getMobileTokenFromURL) =>{
    await axios.get(`${common.apiBase}${common.callprofileApI}`,{headers: {
      'Authorization': 'token ' + getMobileTokenFromURL}
  }).then(res => {
      this.setState({ stateLanguageData: res?.data.data});
      console.log("stateLanguageData");
      console.log(this.state.stateLanguageData);
      this.getLanguagePagewiseData();

  }).catch(err => { console.error('error', err.message) });

  }

  getLanguagePagewiseData = async () =>{
    let filterlanguage = this.state.stateLanguageData.language;
    console.log("filterlanguage");
    console.log(filterlanguage);
    const response = await this.props.getPageLanguageWiseData(filterlanguage, 'learner');
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      this.setState({mobileViewLanguageData: response?.data});
      
      
    }
  } 

  // getGoalQuestionListCall = async() =>{
  //   const {widget_id} = this.state
  //   if(this.state.is_webview){
  //     await axios.get(`${common.apiBase}/${common.getWidgetDataById}?widget_id=${widget_id}`,{headers: {
  //         'Authorization': 'token ' + this.state.token}
  //     }).then(response => {
  //         this.setState({questiondata:response?.data.data,widget_form_data:response?.data.data?.questions,loading:false,widget_form_type:response?.data.data?.type,totalRequired:response?.data.data?.questions.filter( ques => ques.attributes.required == true).length})
  //     }).catch(err => { console.error('error', err.message) });
  //   }else{

  //     const response = await this.props.getWidgetDataById(widget_id)
  //     if(response != undefined){
  //       this.setState({questiondata:response?.data,widget_form_data:response?.data?.questions,loading:false,widget_form_type:response.data?.type,totalRequired:response?.data?.questions.filter( ques => ques.attributes.required == true).length})
  //     }
  //   }
  // }

  getGoalQuestionListCall = async() =>{
    
    let {QuestionID} = this.state

    const {widget_id} = this.state
    if(this.state.is_webview){
      await axios.get(`${common.apiBase}/${common.getWidgetDataById}?widget_id=${widget_id}`,{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
          this.setState({questiondata:response?.data.data,widget_form_data:response?.data.data?.questions,loading:false,widget_form_type:response?.data.data?.type,totalRequired:response?.data.data?.questions.filter( ques => ques.attributes.required == true).length})
          for(var i=0; i< response?.data?.data?.questions.length ; i++){
            QuestionID[response?.data?.data?.questions[i].id] = response?.data?.data?.questions[i].options[0];
          }
          this.setState({QuestionID})
      }).catch(err => { console.error('error', err.message) });
    }else{

      const response = await this.props.getWidgetDataById(widget_id)
      if(response != undefined){
        this.setState({questiondata:response?.data,widget_form_data:response?.data?.questions,loading:false,widget_form_type:response.data?.type,totalRequired:response?.data?.questions.filter( ques => ques.attributes.required == true).length})
        console.log("questiondata",this.state.questiondata)

        for(var i=0; i< response?.data?.questions.length ; i++){
          QuestionID[response?.data?.questions[i].id] = response?.data?.questions[i].options[0];
        }
        this.setState({QuestionID})
       

      }
    }
  }



  /*getGoalQuestionListCall = async() =>{
    console.log("function all");
    var answers = this.state.answers;
    var option ;
    const response = await this.props.getGoalQuestionList();
    //this.setState({mycoachdata : response?.data})
    this.setState({questiondata:response?.data})
    this.setState({answers:response?.data})


    for(let i=0 ; i< response.data.length; i++){
      option = {
        goal_question_id:response.data[i].id,
        answer :response.data[i].answer
      }
      answers.push(option)
    }
    
    this.setState({ answers })
    console.log("Question Data response" + JSON.stringify(this.state.questiondata));
  }*/

  openDialog(){
    //this.setState({isvisible : true});
    if(!this.state.is_webview)
      window.open("/learner/dashboard","_self");
  }

  closeDialog(){    
    this.setState({fields:{},isvisible:false,isFormVisible:"block",loading:true})

    setTimeout(() => this.setState({loading:false}), 1000);
    window.location.reload()
  }

  onHandleChange= (e,id) => {
   
    const {answers}= this.state

    let index = answers.findIndex(p => p.goal_question_id === id);

    answers[index].answer = e.target.value;
    answers[index].goal_question_id = id;
    this.setState({answers});
    this.setState({isEnabled:true})
  }

  postAnswers = async () => {

    const {widget_form_data} = this.state

    let {questions} = this.state

    questions =[]

    for(let i=0 ; i < widget_form_data.length; i++){
      let optionVal = {}

      if(widget_form_data[i].options.length >0){
        let selected_option_id = widget_form_data[i].options.filter((option) => option.is_select == true)
        optionVal ={"question_id": widget_form_data[i].id,"selected_option_id":selected_option_id.map(l => l.id),"user_solution":""}
      }else{
        optionVal ={"question_id": widget_form_data[i].id,"selected_option_id":[],"user_solution":widget_form_data[i].user_solution}
      }
      
      questions.push(optionVal)
    }

    this.setState({questions})

    if(this.state.is_webview){
      await axios.post(`${common.apiBase}/${common.widgetQuestionSubmit}`,{questions},{headers: {
          'Authorization': 'token ' + this.state.token}
      }).then(response => {
          if(response.data?.status == 1){
            console.log("statue 1");
            this.setState({isvisible:true,isFormVisible:"none"})
          }else{
            console.log("statue 0")
            this.setState({isvisible:false,isFormVisible:"block"})
          }
      }).catch(err => { console.error('error', err.message) });
    }else{
      const response = await this.props.widgetQuestionSubmit(questions)
      if(response?.status == 1){
        console.log("statue 1");
        this.setState({isvisible:true,isFormVisible:"none"})
      }else{
        console.log("statue 0")
        this.setState({isvisible:false,isFormVisible:"block"})
      }
    }
  }

  
  /*postAnswers = async () =>{
    const {answers}= this.state
    console.log(answers);
    const response = await this.props.xpSubmitGoalQuestionList(answers);
    if(response?.status == 1){
      console.log("statue 1");
      this.setState({isvisible:true})
    }
  }*/

  handleInputChange = (event,id,o_index,attributes) =>{
    const {QuestionID} = this.state
    const {name} = event.target;
    let {fields,isEnabled} = this.state
    let errors = this.state.errors;
    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    fields[name] = value

    let { widget_form_data } = this.state

    if(attributes.required == true){
      /*errors[name] = 
          value.length ==0
            ? 'name must not be blank!!'
            : '';*/
      if(value.length == 0){
        errors[name] = 'Name must not be blank!!';
      }else{
        delete errors[name];
      }
    }

    switch (attributes.validation) {
      case 'name': 
        if(value.length == 0){
          errors[name] = 'Name must not be blank!!';
        }else{
          delete errors[name];
        }
        break;
      case 'email': 
        if(validEmailRegex.test(value)){
          delete errors[name];
        }else{
          errors[name] = 'email is not valid!';
        }
        break;
      case 'mobile': 
        if(!validMobileRegex.test(value) ||  value.length > 10 || value.length <10){
          errors[name] = 'Mobile no. is not valid!';
        }else{
          delete errors[name];
        }
        break;
      default:
        break;
    }


    let widget_form_index = widget_form_data.findIndex( p => parseInt(p.id) === parseInt(id))


    if(target.type === 'checkbox' )
      widget_form_data[widget_form_index].options[o_index].is_select = event.target.checked;
    else
      widget_form_data[widget_form_index].user_solution = value

    /*if(widget_form_data.length == Object.keys(errors).length){
      for (const [key,errs] of Object.entries(errors)) {
        
        if(errs){
          isEnabled = false;
          break;
        }else{
          isEnabled = true;
        }
      }
    }*/

    QuestionID[id]= target.value
    this.setState({isEmpty : false})
    for(let item of Object.entries(QuestionID)){
      if(item[1] == undefined || item[1] == ''){
        this.setState({isEmpty : true})
        break;
      }
    }

    console.log(fields)
    console.log(errors)
    this.setState({widget_form_data,errors,fields,QuestionID})
  }
  
	render() {
    const {fields,errors,totalRequired,isEmpty} = this.state

    //let enabled = Object.keys(fields).length>= totalRequired && !Object.keys(errors).length ? false : true;
    let enabled = isEmpty;
    return (
      <>
      <ReactNotifications/>
      {!this.state.is_webview && <HeaderMyCoach/>}
        <div className={"app-body "+(this.state.is_webview == 1 ? 'webview' : '')}>
          
          <div className="app-div mycoach-div">
            <Helmet>
              <title>Form - Learner</title>
            </Helmet>
            <div className="mycoach-wrapper">

                <div className="mygoal-block">
                   
                    {!this.state.is_webview && <div className="topHeading">
                      <a href="/mycoach"><img src={BackArrow} /></a><h3>{this.state.questiondata?.title}</h3>
                    </div>}
                    {this.state.is_webview && <div className="topHeading">
                     <h3>{this.state.questiondata?.title}</h3>
                    </div>}
                    {this.state.is_webview &&
                    <p>{this.props.saveLanguageWiseData.connect_with_us}</p>
                    }
                    {/*<h6>Let us help you insetting your goal</h6>*/}
                    {this.state.loading && 
                      <div className="admin-div-loader">
                        <div className="admin-loader"></div>
                      </div>
                    }
                    {!this.state.loading && <div className="design-block" style= {{display:this.state.isFormVisible}}>
                      <ol>

                      {this.state.questiondata?.questions?.length > 0 && this.state.questiondata?.questions.map((item,id) =>
                          <li key={id}>
                            <div className="question-title">{id+1}  -  {item.statement}</div>
                            <div className="question-value">
                              {item.question_type == 6 ?
                                <textarea name={item.statement.toLowerCase().split(" ").join("_")} onChange={(e) => this.handleInputChange(e,item.id,"null",item.attributes)}  placeholder="">{fields[item.statement.toLowerCase().split(" ").join("_")]}</textarea>
                              : <input name={item.statement.toLowerCase().split(" ").join("_")} type="text" value={fields[item.statement.toLowerCase().split(" ").join("_")]} onChange={(e) => this.handleInputChange(e,item.id,"null",item.attributes)}  placeholder="" />
                              }
                              {errors[item.statement.toLowerCase().split(" ").join("_")]?.length > 0 && 
                                <span className='error'>{errors[item.statement.toLowerCase().split(" ").join("_")]}</span>}
                            </div>
                        </li>
                      )}       

                      
                     </ol>
                     <div className="question-action">
                        {this.state.is_webview && 
                        <button  disabled={enabled}  onClick={()=>this.postAnswers()}>{this.state.mobileViewLanguageData.submit}</button>
                        }
                        {!this.state.is_webview && 
                        <button  disabled={enabled}  onClick={()=>this.postAnswers()}>{this.props.saveLanguageWiseData.submit}</button>
                        }
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>


          <div className= {this.state.isvisible ? 'isvisible thanksDialog':'thanksDialog'}>
            <div className="thanksDialog-inner">
                <div className="popup-header">{this.state.questiondata?.success_message || "Thank you for Submitting the answers"}</div>
                {this.state.widget_form_type !="refer" && <span className="close" onClick={()=>this.closeDialog()}>&#x2715;</span>} 
                <div className="thanksDialog-content">
                      <div class="thanks-tick">
                          <i><img src={thanksDialogCheckmark} /></i>
                          {this.state.widget_form_type =="refer" && "Do you want to Refer more?"}
                      </div>
                      {this.state.widget_form_type =="refer" && <div className="refer-more">
                          <button onClick={()=>this.closeDialog()}>Yes</button>
                          {!this.state.is_webview && <button onClick={()=>this.openDialog()}>No</button>}
                        </div>}
                        
                </div>
            </div>
          </div>
        
      </>




		)
	}
}

const mapStateToProps = (state) => {
	return {
    loading: state.commonReducer.loading,
    currentpage :state.commonReducer.currentpage,
    saveLanguageWiseData:state.commonReducer.languageData
   
	}
}

const mapDispatchToProps = (dispatch) => {
  const { getGoalQuestionList,xpSubmitGoalQuestionList,widgetQuestionSubmit,getWidgetDataById,getPageLanguageWiseData} = actions;
  return {
    getWidgetDataById : (id) => dispatch(getWidgetDataById(id)),
    widgetQuestionSubmit : (questions) => dispatch(widgetQuestionSubmit(questions)),
    getGoalQuestionList: () => dispatch(getGoalQuestionList()),
    xpSubmitGoalQuestionList: (answers) => dispatch(xpSubmitGoalQuestionList(answers)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyGoal);