import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import ReactNotifications from 'react-notifications-component';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import PathD from '../../public/icons/path-d.svg';
import PathB from '../../public/icons/path-b.svg';
import idea from '../../public/icons/idea.svg';
import Calendar2 from '../../public/icons/calendar2.svg';
import Empty from '../../components/error/Empty';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import downloadFile from '../../public/icons/file-download-icon.png';
import Pagination from "react-js-pagination";
import common from '../../common';

class StudioProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected:'',
      loading: true,
      classes:'',
      batches: [],
      currentBatch:'',
      projectId:"",
      projectUserList: [],
      current_page:'',
      totalItems:'',
      markSave:'',
      sendMarksData:[],
      marks_obtained:'',
      token:''
    }
   
  }
  
  componentDidMount() {
    
    let getCenterID = localStorage.getItem("center_id");
    let getUserID = localStorage.getItem("user_id");
    console.log("Project Data");
    console.log(this.props.location.state.projectData);
    let getToken = localStorage.getItem("token");

    if(getToken){
      this.setState({token : getToken});
    }
    let getProjectId =this.props.location.state.projectData.id;
    if(getProjectId){
      this.setState({projectId : getProjectId});
    }
    let getClassName =this.props.location.state.projectData.classs;
    console.log(getClassName);
    if(getClassName){
      this.setState({classes : getClassName});
    }
    let getBatchIds =this.props.location.state.projectData.batch_ids;
    console.log(getBatchIds);
    let getDefaultBatchId =this.props.location.state.projectData.batch_ids[0].id;
    console.log("getDefaultBatchId");
    console.log(getDefaultBatchId);

    if(getBatchIds){
      this.setState({batches : getBatchIds});
    }

    if(getUserID){
      this.setState({user_id : getUserID});
    }
    if(getCenterID){
      this.setState({center_id : getCenterID});
      
    }
    if(getProjectId,getDefaultBatchId){
    this.getFacultyStudentProjectList(getProjectId,getDefaultBatchId)
    }
    
  }

  componentWillUnmount() {
    
  }



   onBatchChange= (e) => {
     const {currentBatch,projectId} = this.state;
     this.setState({currentBatch : e.target.value});
     this.getFacultyStudentProjectList(projectId,e.target.value);
     //alert(e.target.value);
    
  }


  getFacultyStudentProjectList= async (projectId,batchid) => {
    const {current_page} = this.state;
    const response = await this.props.getFacultyStudentProjectResponse(projectId,current_page?current_page:'1',batchid);
    if(response && response != undefined){
      let saveResponse = response.data;
      let current_page = response.data?.pagination?.current_page;
      let totalItems = response.data?.pagination?.total_page * 20;
      console.log("saveResponse");
      console.log(saveResponse);
      console.log(current_page);
      this.setState({projectUserList:saveResponse});
      this.setState({current_page:current_page})
      this.setState({totalItems:totalItems})
    }
    
  }

  handlePageChange(pageNumber) {
    const {current_page,projectId,currentBatch} = this.state;
    console.log(`active page is ${pageNumber}`);
    this.setState({current_page: pageNumber});
    this.getFacultyStudentProjectList(projectId,current_page,currentBatch)
  }

  getFormattedDocumentDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleString();
  }

  onMarksChange = (e) =>{
    let saveMarks = e.target.value;
    console.log("saveMarks");
    console.log(saveMarks);
    if(saveMarks !== ''){
      this.setState({markSave:saveMarks});
    }
    
   
  }

  marksSend(id){
    const {markSave} = this.state;
    console.log(markSave)
    console.log(id)
    let array = []
    array.push({"id": id , "marks" : JSON.parse(markSave)});
    console.log("JSON.stringify(array)");
    console.log(JSON.stringify(array));
    //this.setState({sendMarksData:array});
    this.sendMarksData(array);
  }

  sendMarksData(array){
    console.log(array)
    const response = this.props.facultyUploadStudioProjectMarks(array);
  }

    
  bulkDownload = (files) => {
    console.log("files")
    console.log(files)
    let ids =[];
    let idlist =[];
    files.forEach(async function (url){
      ids = files.map(value => value.id)
      idlist.push(ids)
    });

    console.log(idlist.toString())
    const url = `${common.apiBase}${common.studioPojectZipDownload}?attempt_ids=${idlist.toString()}&token=${this.state.token}`;

    console.log(url)
    window.open(url)
  }

  render() {
    return (
      <>
    <div className="app-body" id="appBody" onScroll={this.handleScroll}>
        <ReactNotifications/>
        <div className="app-div">
          <Helmet>
            <title>Studio Project Details - Faculty</title>
          </Helmet>
          <img id="path-b" src={PathB} alt="pathb"/>
          <img id="path-d" src={PathD} alt="pathd"/>

            <div className='studioProjectWrapper'>
              <div className='studioProjectContainer'>

              <div className='filtersBlock'>
                    <h1> Studio Project Details</h1>
                    <div>
                    <select>
                        <option value={parseInt(this.state.classes.id)} key={this.state.classes.id}>
                            {this.state.classes.name}
                        </option>
                    </select>
                    {/* {JSON.stringify(this.state.batches[0]?.name)} */}
                    <select onChange={this.onBatchChange}>
                        <option value={parseInt(0)}>
                          Select Batch
                        </option>
                        {this.state.batches.length && this.state.batches.map(batch => 
                          <option value={parseInt(batch.id)} key={batch.id} selected={this.state.batches[0].id == batch.id  ? 'selected': ''}>
                            {batch.name}
                          </option>
                        )}
                      </select>
                      {this.state.projectUserList?.data?.length > 0 && <button onClick={() => this.bulkDownload(this.state.projectUserList?.data)}>Bulk Download</button>}
                    </div>

                </div>

                          {/* {JSON.stringify(this.state.projectUserList)} */}
                    {this.state.projectUserList?.data &&  this.state.projectUserList?.data?.length > 0 &&  this.state.projectUserList?.data.map(list =>
                      <div className='userListWrapper' key={list.user.id}>
                      <div className='userListLeft'>
                          <div className='userListThumb'><img src={list.user.profile_picture} /></div>
                          <div className='userListContent'>
                            <h3>{list.user.name}</h3>
                            <p>Email - <span>{list.user.email}</span></p>
                            <p>Mobile - <span>{list.user.mobile}</span></p>
                            <p>Submit on - <span>{this.getFormattedDocumentDate(list.submit_date)}</span></p>
                            <p>Uploaded by - <span>{this.getFormattedDocumentDate(list.updated_on)}</span></p>
                          </div>
                      </div>
                      <div className='userListRight'>
                        <div className='userFile'>{list.users_file.split("/").pop()}<a href={list.users_file} target="_blank" download><i><img src={downloadFile}/></i></a></div>
                        <div className='marks'><span>Marks</span><input type="number" onChange={this.onMarksChange} defaultValue={list.marks_obtained} placeholder="enter marks"/> <button onClick={() => this.marksSend(list.id)}>Go</button></div>
                      </div>
                    </div>
                    )}    
                    
                    {this.state.projectUserList?.data?.length == 0 &&
                     <div className='nodatafound'>No Project Found</div>
                    }
                    
                  
                    <Pagination
                      activePage={this.state.current_page}
                      itemsCountPerPage={20}
                      totalItemsCount={this.state.totalItems}
                      pageRangeDisplayed={10}
                      onChange={this.handlePageChange.bind(this)}
                    />
                
                 

              </div>  
            </div>
          
           
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getFacultyProfile,getFacultyList,getFacultyStudentProjectResponse,facultyUploadStudioProjectMarks} = actions;
  return {
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getFacultyList: (center_id,show_ext_dt) => dispatch(getFacultyList(center_id,show_ext_dt)),
    getFacultyStudentProjectResponse: (project_id,page,batch_id) => dispatch(getFacultyStudentProjectResponse(project_id,page,batch_id)),
    facultyUploadStudioProjectMarks: (data) => dispatch(facultyUploadStudioProjectMarks(data)),
    
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudioProjectDetail);