import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import { Helmet } from "react-helmet";
import { isEmpty } from 'lodash';
import Popup from 'reactjs-popup';
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import DifficultyMeter from '../../public/icons/difficulty-meter.svg';
import Correct from '../../public/icons/correct.svg';
import Attempted from '../../public/icons/attempted.svg';
import AvgTime from '../../public/icons/avg-time.svg';
import Score from '../../public/icons/score.svg';
import Pin from '../../public/icons/pin.svg';
import CheckSymbol from '../../public/icons/check-symbol.svg';
import VideoPlayer2 from '../../components/players/video/VideoPlayer2';
import 'react-notifications-component/dist/theme.css';
import "react-multi-carousel/lib/styles.css";
import Zoom from '../../public/icons/full-screen.svg';
import ZoomIn from '../../public/icons/zoom-in.svg';
import download from '../../public/icons/down-arrow.svg';
import clockicon from  '../../public/icons/assignment-clock-icon.png';
import defaultImage from '../../public/icons/maxresdefault.png';
import gradingflag from '../../public/icons/gradingflag.png';
import Submission from '../../public/icons/submission.svg';
import tick from '../../public/icons/admin-assignment-tick.png';
import tickdisabled from '../../public/icons/admin-assignment-tickdisabled.png';
import downarrow from  '../../public/icons/assignment-listing-arrow.png';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import Pagination from "react-js-pagination";
import JSZip from 'jszip';
import axios from 'axios';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import './styles.css';

const ques_types = {
  1: 'MCQ',
  2: 'Single Integer',
  3: 'Multiple Response',
  4: 'Fill in the blanks',
  5: 'True or False',
  6: 'Subjective',
  7: 'Matching'
}

const testData = [
  { bgcolor: "#6a1b9a", completed: 60 },
];

const responsive = {
  desktop: {
    breakpoint: { max: 4240, min: 900 },
    items: 10,
    slidesToSlide: 10
  },
  tablet: {
    breakpoint: { max: 720, min: 590 },
    items: 10,
    slidesToSlide: 10
  },
};

const questionColors = {
  answered: "#56affc", correct: "#66bb6a", not_visited: "#FFFFFF", skipped: "#f2e4c8", wrong: "#ff8080"
}




const SlideButtons = ({ next, previous, goToSlide, ...rest }) => {
  const { carouselState: { currentSlide } } = rest;
  const { carouselState: { totalItems } } = rest;
  const lastSlide = Math.floor(totalItems / 4) + (totalItems % 4) - 1;
  if(totalItems <= 10) {
    return (
      <div>
      </div>
    )
  }
  return (
    <div className="slide-buttons-xy">
      <img src={Left} className='slide-button-xy' onClick={() => previous()} alt="img"/>
      <img src={Right} className='slide-button-xy' onClick={() => next()} alt="img"/>
    </div>
  );
};

class AssignmentPreview2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: this.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      token: localStorage.getItem('token'),
      no_ques: false,
      showSolution: false,
      sections: [],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      options: [],
      values:[],
      disable:[],
      batches:[],
      students:[],
      files:[],
      open5:false,
      selected_image:null,
      selected_image_on:null,
      uploaded_selected_image_on:null,
      file_extension:"",
      uploaded_file_extension:"",
      assignment:"",
      image:null,
      current_page:0,
      answers:[],
      totalItems:0,
      imageURL: null,
      image1:null,
      imageURL1: null,
      userProfile:"",
      batchIndex :null,
      is_scheduled :0,
      objFile:{},
      objMarks:{},
      onDueEnd:0,
      completed:0,
      batch_id:0,
      firstId:0,
      onLoad :false,
      is_faculty:localStorage.getItem("is_faculty") == "true" ? true : false,
      mlist :[ "Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ],
      days:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      difficulty:['easy', 'medium', 'hard', 'very-hard']
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    // this.renderMath()
    this.getFacultyProfile();
    this.getAssignment(this.props.match.params.id,1);
    this.setState({difficulty:[this.props.saveLanguageWiseData.easy, this.props.saveLanguageWiseData.medium,this.props.saveLanguageWiseData.hard, this.props.saveLanguageWiseData.very_hard]});
      
  }

  getFacultyProfile = async () => {
    
    const response = await this.props.getFacultyProfile();

    this.setState({batches: response.data.batches})
  }

  getAssignment = async (id,page) => {
    var option ;
    var blockedTile = new Array("jpg", "jpeg", "png", "gif","svg");
    let  {objFile,objMarks} = this.state;
    const response = await this.props.assignmentReview(id,page,this.state.batch_id);
    this.setState({ loading: false })
    if(response !== undefined) {
      /*response.data.sections.map(section => {
        section.questions.map((question, index) => {
          question.question_number = "Q"+(index+1);
        })
      })*/
      if(response.data.data.file){
        if(blockedTile.indexOf(response.data.data.file.split('.').pop()) != -1)
        {  
          this.setState({file_extension:1,selected_image_on:response.data.data.file.split('/').pop()}); //1 is used for image file
        }else{
          this.setState({file_extension:2,selected_image_on:response.data.data.file.split('/').pop()}); //2 is used for content file
        }
      }

      this.timer = setInterval(()=>this.scheduledOn(new Date(response.data.data?.scheduled_date) ,new Date(response.data.data?.due_date)), 1000)
      this.timer1 = setInterval(()=>this.dueOn(new Date(response.data.data?.scheduled_date) ,new Date(response.data.data?.due_date)), 1000)
      

      this.setState({ assignment:response.data.data, students:response.data.data?.students , title: response.data.data.title, code: response.data.data.uid ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,firstId:response.data.data?.students[0]?.id });


      for(let i=0 ; i<response.data.data?.students.length; i++){ 
        objFile[response.data.data?.students[i].id] = response.data.data?.students[i].faculty_file; 

      } 

      for(let i=0 ; i<response.data.data?.students.length; i++){ 
        objMarks[response.data.data?.students[i].id] = response.data.data?.students[i].marks_obtained; 

      } 

      this.setState({ objFile ,objMarks })
      //this.setCurrentSectionQ(response.data.sections);
    }
  }

  onBatchChange = async (e) =>{
    let  {objFile,objMarks} = this.state;

    this.setState({batch_id:e.target.value,onLoad:true})
    const response = await this.props.assignmentReview(this.props.match.params.id,1,e.target.value);

    
    if(response !== undefined) {
      this.setState({ assignment:response.data.data, students:response.data.data?.students , title: response.data.data.title, code: response.data.data.uid ,current_page :response.data?.pagination?.current_page,totalItems: response.data?.pagination?.total_page * 20,firstId:response.data.data?.students[0]?.id});
      

      for(let i=0 ; i<response.data.data?.students.length; i++){ 
          objFile[response.data.data?.students[i].id] = response.data.data?.students[i].faculty_file; 

      } 

      for(let i=0 ; i<response.data.data?.students.length; i++){ 
        objMarks[response.data.data?.students[i].id] = response.data.data?.students[i].marks_obtained; 

      } 

      this.setState({ objFile ,objMarks,onLoad:false })
    }

    var items =  document.getElementsByClassName("listing-bottom");
    for (var i=0; i < items.length; i++) {
      items[i].style.display = "none";
    }

    var element1 = document.getElementsByClassName("arrow");
    for (var i=0; i < element1.length; i++) {
      element1[i].classList.remove("open");
    }

  }
  
  close5 = () => this.setState({ open5: false ,selected_image:null});

  scheduledOn = (val,due) => {
    let dt1 = new Date();
    var diff = val.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    diff = Math.round(diff);
    if(diff === 0 ) {
      this.setState({is_scheduled: 1})
      clearInterval(this.timer);
      //setTimeout(function(){  window.location.reload(true) },60000);
    }
    if(diff < 0 && dt1.getTime() <= due.getTime()){
      this.setState({is_scheduled: 1})
    }
    //console.log("onsched"+diff)
  }



  dueOn = (val,due) => {
    let dt1 = new Date();
    var diff = due.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    diff = Math.round(diff);
    if(diff === 0 ) {
      this.setState({is_scheduled: 0})
      clearInterval(this.timer1);
      //setTimeout(function(){  window.location.reload(true) },60000);
    }else if(dt1.getTime() > due.getTime()){
      this.setState({onDueEnd :1})
    }
    //console.log("ondue"+diff)
  }

  next = () => {
    let {exerciseAttempt, currentSection, currentQuestion} = this.state;
    let sectionIndex = exerciseAttempt.sections.findIndex(p => p.id === currentSection.id)
    let questionIndex = currentSection.questions
                          .findIndex(p => p.id === currentQuestion.id)
    if(questionIndex === currentSection.questions.length - 1) {
      if(sectionIndex !== exerciseAttempt.sections.length-1) {
        this.moveToNext(exerciseAttempt.sections[sectionIndex+1].id, exerciseAttempt.sections[sectionIndex+1].questions[0].id)
      }
    } else{
      this.moveToNext(currentSection.id, currentSection.questions[questionIndex+1].id)
    }
  }

  zoomOn = (url) => {
    this.setState({open5:true,selected_image:url})
  }

  removeImage = (index) => {
    const {answers} = this.state;
    answers[index].files="";
    this.setState({answers})
    document.getElementById("img-input"+index).value="";
  }

  displayTime = (val) => {
    var hours = val.getHours();
    var minutes = val.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var timewithampm = hours + ':' + minutes + ' ' + ampm;
    return `${timewithampm}`.toUpperCase();
  }

  fileInput2 = (e,index) => {
    document.getElementById("img-input"+index).click();
  }

  handleChange1 = async (event,index,attempt_id) => {
    if(event.target.files[0].type.split('/')[0]) {
      this.setState({ 
        image1: event.target.files[0],
        imageURL1: URL.createObjectURL(event.target.files[0])
      })

      await this.props.facultyUploadMarks(attempt_id,event.target.files[0],"");
     
      const response = await this.props.assignmentReview(this.props.match.params.id,this.state.current_page,this.state.batch_id);
      this.setState({ assignment:response.data.data,students:response.data.data?.students , title: response.data.data.title, code: response.data.data.uid });
      
    }
    else {
      this.setState({ image1: null, imageURL1: null });
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.saveLanguageWiseData.images_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  addMarks = async (e,attempt_id,index) => {

    if(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(e.target.value)){
      if(e.target.value <= this.state.assignment.total_marks){
      this.setState({
        values: {[attempt_id]: e.target.value },
        disable:{[attempt_id]: true }
      });
      }else{
         store.addNotification({
          title: 'Error',
          message: `${this.props.saveLanguageWiseData.marks_must_be_in_range_of_total_marks}`,
          type: 'danger',
          container: 'top-right',
          dismiss: {
            duration: 3000,
          }
        })
      }
    }else{
      this.setState({
        values: {[attempt_id]:''},
        disable:{[attempt_id]: false }
      });
    }
  }

  addMarks1 = async (e,id,index) => {

    await this.props.facultyUploadMarks(id,"",this.state?.values[id]);
      
    const response = await this.props.assignmentReview(this.props.match.params.id,this.state.current_page,this.state.batch_id);
    if(response != undefined){
      this.setState({
        values: {[id]: '' }
      });
      this.setState({ assignment:response.data.data,students:response.data.data?.students , title: response.data.data.title, code: response.data.data.uid });
    }
    
    return true;
  }

  openToggle = (id) => {
    var element = document.getElementById("arrow"+id);
    if(element) {
      

      var element1 = document.getElementsByClassName("open"+id);
      if(element1[0]){
        element1[0].classList.remove("open");
        element1[0].classList.remove("open"+id);
        document.getElementById("listing"+id).style.display="none";
      }else{
        element.classList.add("open");
        element.classList.add("open"+id);
        document.getElementById("listing"+id).style.display="flex";
      }
    }
  }

  dueOn1 = (due) => {
    let dt1 = new Date();
    var diff = due.getTime()/(1000 * 60) - dt1.getTime()/(1000 * 60);
    let hours = Math.round(diff/60);
    let days = Math.round(hours/24);
    
    if(days > 0)
      return days + ' days left';
    if(hours > 0)
      return hours + ' HRS left';
    else if(diff > 0)
      return diff + ' mins left';
  }

  
  handlePageChange = (pageNumber) =>{
    console.log(`active page is ${pageNumber}`);
    this.setState({onLoad:true})
    this.show4(pageNumber);

    this.setState({current_page: pageNumber});
  }

  show4 = async(page) => {
    let  {objFile,objMarks} = this.state;
    this.setState({ values:[] });
    
    const response = await this.props.assignmentReview(this.props.match.params.id,page,this.state.batch_id);

    if(response !== undefined) {
      this.setState({ assignment:response.data.data ,students:response.data.data?.students,title: response.data.data.title, code: response.data.data.uid,firstId:response.data.data?.students[0]?.id});
    }


    for(let i=0 ; i<response.data.data?.students.length; i++){ 
        objFile[response.data.data?.students[i].id] = response.data.data?.students[i].faculty_file; 

    } 

    for(let i=0 ; i<response.data.data?.students.length; i++){ 
      objMarks[response.data.data?.students[i].id] = response.data.data?.students[i].marks_obtained; 

    } 

    this.setState({ objFile ,objMarks })

    var items =  document.getElementsByClassName("listing-bottom");
    for (var i=0; i < items.length; i++) {
      items[i].style.display = "none";
    }

    var element1 = document.getElementsByClassName("arrow");
    for (var i=0; i < element1.length; i++) {
      element1[i].classList.remove("open");
    }

    this.setState({onLoad:false})
    this.handleScrollPos()

  }

  handleScrollPos =() => {
    var elmnt = document.getElementById("conter");
    if(elmnt) elmnt.scrollIntoView();
  }

  downloadFile = (e,name,rollno,batch) => {
    e.preventDefault();
    let fileName =  e.target.href;
    //https://cors-anywhere.herokuapp.com/
    fetch(fileName, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/csv,application/zip',
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        name+'_'+rollno+'_'+batch+'.'+fileName.split('.').pop(),
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }

 

  bulkDownload = (files) => {
    let attempt_ids =[];
    files.forEach(async function (url){
      attempt_ids = url.history.map(value => value.id)
    });

    console.log(attempt_ids.toString())
    const url = `${common.apiBase}${common.zipDownload}?attempt_ids=${attempt_ids.toString()}&token=${this.state.token}`;

    window.open(url)
  }

  render() {
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      showSolution,
      loading,assignment,userProfile,batchIndex,is_scheduled,students,onDueEnd
    } = this.state;
    if(this.props.loading || loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
   
    else {
      return (
         <div className="assignment-div">
        <Helmet>
          <title>Assignment Review - {this.state.title}</title>
        </Helmet>
        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close5} className="zoom-image-on">
          <div className="zoom-image">
            <img src={this.state.selected_image} />
          </div>
        </Popup>
        
         <ReactNotifications/>
         {/* New Design Start */}       

         <div className="assignment-review-wrapper">
            <div className="assignment-review-container">
                <button id="trace-back" onClick={() => this.props.history.goBack()}>
                  <img src={Back} alt="back"/>
                </button>
                <div className="assignment-review-top">
                    <div className="assignment-title">{assignment.title} <span>{assignment.uid}</span></div>
                    <div className="assignment-title-desc">{assignment?.description}</div>
                </div>

                <div className="assignment-review-bottom">
                    <div className="assignment-bottom-left">
                        <div className="hours-block"><i><img src={clockicon}/></i>{this.dueOn1(new Date(assignment.due_date))}</div>
                        <div className="marks-block">{this.props.saveLanguageWiseData.total_marks} <span>{assignment.total_marks}</span></div>
                        <div className="passing-block">{this.props.saveLanguageWiseData.passing_marks} <span>{assignment.passing_marks}</span></div>
                      </div>
                    <div className="assignment-bottom-right">
                        <div className="tag-block"><span Style="text-transform: capitalize;">{this.state.difficulty[assignment.difficulty]}</span></div>
                        <div className="attempts-block"><span>{assignment.attempts_allowed ?assignment.attempts_allowed:this.props.saveLanguageWiseData.unlimited_attempts } {this.props.saveLanguageWiseData.attempts}</span></div>
                        <p>{assignment.closed_after_passing ? this.props.saveLanguageWiseData.attempt_closed_after_passing:""}</p>
                    </div>
                </div>

                <div  Style="display:flex;">
                  {this.state.file_extension == 2 ? <div className="download-file-attempt"><a href={assignment.file} target="_blank" rel="">
                  {/* <i className="fas fa-download"/> */}
                  <img src={download} alt="download"/> {this.props.saveLanguageWiseData.download} {this.state.selected_image_on}
                  </a></div> :<div className="imagepreview"><img src={assignment.file}/>
                  <span onClick={()=>this.zoomOn(assignment.file)}><img src={ZoomIn} alt="zoom"/></span>
                  </div> }
                </div>

                <div className="assignment-review-last-bottom">
                    <div className="assignment-last-bottom-left">
                        <div className="submission-block"><span>Total Students:</span>{students?.length}</div>
                    </div>
                    <div className="assignment-last-bottom-right">
                        {/* assignment.is_graded && <div className="grading-block"><i><img src={gradingflag}/></i>Grading Inprogress</div> */}
                        
                        <div><button onClick={() => this.bulkDownload(assignment?.students)}>Bulk Download</button></div>
                    </div>
                </div>

                <div className="assignment-review-listing">
                  {this.state.onLoad && 
                      <div className="assessment-div">
                        <div className="admin-div-loader">
                          <div className="admin-loader"></div>
                        </div>
                      </div>
                    
                  }
                  <ol>
                  {!this.state.onLoad && students?.length ? students?.map((assg,index)=>
                    <li id={assg.id == this.state.firstId ?"conter":""}>
                      <div className="listing-top">
                        <div className="listing-top-left">
                            <div className="thumb"><img src={common.addFileUrl(assg?.profile_picture)} alt="file"/></div>
                            <div className="thumb-content">
                              <div className="title">{assg.name}</div>
                              <div className="title-email">{assg.email}</div>
                              <div className="title-phone">+91-{assg.mobile}</div>
                            </div>
                            <div className="class-content">
                              <div className="title">{this.props.saveLanguageWiseData.class}</div>
                              <div className="title-value">{assg.classs}</div>
                            </div>
                            <div className="section-content">
                              <div className="title">{this.props.saveLanguageWiseData.section}</div>
                              <div className="title-value">{assg.batch}</div>
                            </div>
                            <div className="subject-content">
                              <div className="title">{this.props.saveLanguageWiseData.subject}</div>
                              <div className="title-value">{assg?.subject}</div>
                            </div>
                        </div> 
                        <div className="listing-top-right">
                            <div className="date">{this.props.saveLanguageWiseData.last_submitted_on} : {assg?.submit_date ? (new Date(assg?.submit_date)).getDate() :""} {assg?.submit_date ? this.state.mlist[(new Date(assg?.submit_date)).getMonth()] :""} ,{assg?.submit_date ? (new Date(assg?.submit_date)).getFullYear() :""}<span></span><span Style="margin-left:10px">{assg?.submit_date ? this.displayTime(new Date(assg?.submit_date)) :""}</span></div>
                            {/* <div className="checked"><span>Checked</span></div> */}
                            <div className="arrow" id={"arrow"+assg.id} onClick={() => this.openToggle(assg.id)}>
                              <span>{assg.marks_obtained ? this.props.saveLanguageWiseData.reviewed :this.props.saveLanguageWiseData.submitted}</span>
                              <img src={downarrow}/>
                            </div>
                        </div>
                      </div>

                      <div className="listing-bottom" id={"listing"+assg.id}>
                        
                      {assg?.history.length && assg?.history.map((assgVal,assgIndex)=>
                          <div className="list-view newDesign">
                            <div className="view-content">{assg?.subject}</div>
                            <div className="view-content">{this.props.saveLanguageWiseData.submitted_on}: {(new Date(assg.submit_date)).getDate()} {this.state.mlist[(new Date(assg.submit_date)).getMonth()]} ,{(new Date(assg.submit_date)).getFullYear()} </div>
                            {/* <div className="view-content">Student Notes : NA</div> */}
                            <div className="review-content">
                              <span>{assg?.is_faculty_reviewed ? this.props.saveLanguageWiseData.reviewed:""}</span>
                              {assgVal?.users_file.length > 0 && assgVal?.users_file.map((file,index)=>
                              <a href={common.addFileUrl(file)} alt={file} onClick={(e) => this.downloadFile(e,assg.name,assg.roll_no,assg.batch)}>{assg.name+'_'+assg.roll_no+'_'+assg.batch+'.'+file.split('.').pop()}</a>
                              )}
                              {assgVal?.length && assgVal?.marks_obtained == 0 &&
                              <span Style={(assignment?.closed_after_passing && assg.marks_obtained >= assignment.passing_marks) || ((!is_scheduled || (!onDueEnd && !assignment?.closed_after_passing)) && !assignment.is_graded)? "display:none" : ""}>
                                <button onClick={(e) => this.fileInput2(e,index)}>{this.props.saveLanguageWiseData.upload_reviewed_file}</button>
                              </span>
                                }
                              {this.state.objFile[assgVal?.id] && assgVal?.faculty_file.length > 0 && assgVal?.faculty_file.map((file,index)=>
                              <div className="img-prev-statement-assignment ques-statement-assignment">
                                <a href={file} alt="Prev" download >{file?.split('/').pop()}</a>
                              </div>)}
                              <div className="input_field" Style="display:none">
                               <input type="file" id={"img-input"+index} onChange={(e) => this.handleChange1(e,index,assg.id)} ref={fileInput1 => this.fileInput1 = fileInput1}/>
                              </div>
                            </div>
                            <div className="marks-block" Style={(assignment?.closed_after_passing && assg.marks_obtained >= assignment.passing_marks) || ((!is_scheduled || (!onDueEnd && !assignment?.closed_after_passing)) && !assignment.is_graded)? "display:none" : ""}>
                              <span><input type="text" defaultValue={this.state.objMarks[assg.id]} name="marks" onChange={(e) => this.addMarks(e,assg.id,index)}/> {this.props.saveLanguageWiseData.marks}</span>
                              {(this.state?.values[assg.id]) ?<div><button onClick={(e) => this.addMarks1(e,assg.id,index)}>Add</button></div> :""}
                              <i>{(assg.marks_obtained && !this.state?.values[assg.id]) ? <img src={tick}/> :""}</i>
                            </div>
                          </div>
                      )}
                       
                        
                        {/*<div className="list-view">
                            <div className="view-content">Algebra Questions PDF</div>
                            <div className="view-content">Submitted On : 12 Aug, 2020</div>
                            <div className="view-content">Student Notes : This is my Submission</div>
                            <div className="review-content"><button>Upload Reviewed File</button></div>
                            <div className="marks-block">
                              <input type="text" placeholder="20 Marks"/>
                              <i><img src={tickdisabled}/></i>
                            </div>
                        </div>*/}
                        
                      </div>

                    </li>

                     ) : <li className='noResultFound'>No Results Found</li>}
                    
                  </ol>

                </div>
            </div>       
            {students?.length ? <div className="pagination-list fixed-pagination">
              <Pagination
                activePage={this.state.current_page}
                itemsCountPerPage={20}
                totalItemsCount={this.state.totalItems}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>:''}
        </div>

        {/* New Design Start */}    

      </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessmentReviewData,facultyUploadMarks,getFacultyProfile,assignmentReview,uploadUserAssigFile,getLearnerProfile } = actions;
  return {
    assignmentReview : (id,page,batch_id) => dispatch(assignmentReview(id,page,batch_id)),
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    facultyUploadMarks: (attempt_id,faculty_file,marks_obtained) => dispatch(facultyUploadMarks(attempt_id,faculty_file,marks_obtained)),
    uploadUserAssigFile: (assignment_id,users_file) => dispatch(uploadUserAssigFile(assignment_id,users_file)),
    getAssessmentReviewData: (assessmentId) => dispatch(getAssessmentReviewData(assessmentId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentPreview2);